import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Box, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { DTIcon } from '../../static';
import { loadaiPuckProps } from '../ganttchart.config';
import {
  EVENT_STATUS,
  EVENT_TYPES,
  SELECT_TRIP_RESPONSIVE_SIZES,
} from '../../constants/puck.constant';
import { eventToolTipBody, gapToolTipBody } from '../ganttchart.seeds';
import { ColumnTooltipType } from '../types';
import {
  getEventBackgroundColor,
  getPuckContentFromEventRecord,
  LOADPUCK_PROPS,
  puckResponsiveRenderedData,
} from './puck.service';
import { tooltipBodyContentStyles } from '../../styles';
import { Puck } from '../../components';

const label = {
  inputProps: { 'aria-label': 'checkbox-event' },
};
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatDate = (date: string | Date) => {
  const d = new Date(date);
  const year = d?.getFullYear();
  const month = d.getMonth();
  const day = d?.getDay();
  const hour = d?.getHours();
  const min = d?.getMinutes();
  const hourMin = `${hour < 10 ? 0 : ''}${hour}:${min < 10 ? 0 : ''}${min}`;
  return `${year}/${month}/${day} ${hourMin}`;
};

export const isAllowedToDragAndResize = (
  status: string,
  statuses: Array<string>
): boolean => {
  if (statuses?.includes(status)) {
    return false;
  }
  return true;
};

export const isAllowedToEditEvent = (status: string): boolean => {
  if (status === EVENT_STATUS.GAP) {
    return true;
  }
  return false;
};

export const isAllowedForAction = (status: string): boolean =>
  status === EVENT_STATUS.GAP;

export const resourceIdExists = (resourceArr: Array<any>, resourceId: string) =>
  resourceArr?.find((load: any) => load?.resourceId === resourceId);

export const findResourceIndexFromStore = (store: Array<any>, id: string) =>
  store?.findIndex((resource) => resource?.data?.id === id);

export const getHoursAndMin = (date: any) => {
  const hours = new Date(date).getHours();
  const min = new Date(date).getMinutes();
  return `${hours < 10 ? 0 : ''}${hours}:${min < 10 ? 0 : ''}${min}`;
};

export const getDateFormatForTime = (date: any) => {
  if (!date) return date;
  const d = new Date(date);
  const time = getHoursAndMin(d);
  return `${time}`;
};

export const eventBodyTemplate = (data: any) => {
  let props = {
    ...loadaiPuckProps,
    ...getPuckContentFromEventRecord(data),
  };
  props.puckContainerColor =
    data.data?.puckContainerColor || getEventBackgroundColor(data.data?.type);
  switch (data.data?.type) {
    case EVENT_TYPES.CHECKBOX:
      return (
        <Checkbox
          {...label}
          style={{ marginLeft: 'auto' }}
          defaultChecked={false}
        />
      );
    case EVENT_TYPES.EMPTYLOAD:
    case EVENT_TYPES.PLANNED_DISAPPROVE:
      props = {
        ...props,
        ...LOADPUCK_PROPS?.(data?.data)?.[data.data?.type],
        withDownVote: data.data?.withDownVote ?? true,
        withDownVoteText: data.data?.withDownVoteText,
      };
      return (
        <Puck
          {...props}
          innerContentStyles={{
            fontFamily: data?.fontFamily,
            ...props.innerContentStyles,
          }}
        />
      );
    case EVENT_TYPES.GAP:
    case EVENT_TYPES.EMPTY:
      props = {
        ...LOADPUCK_PROPS?.(props)?.[data.data?.type],
        ...props,
        showPuckOnHover: data?.data?.showPuckOnHover ?? true,
        // puckInnerElement: data.data?.puckInnerElement,
      };
      return (
        <Puck
          {...props}
          innerContentStyles={{
            fontFamily: data?.fontFamily,
            ...props.innerContentStyles,
          }}
        />
      );
    case EVENT_TYPES.RELAY_START:
    case EVENT_TYPES.RELAY_END:
      props = {
        ...props,
        icon: data?.data?.icon || <SwapHorizIcon />,
        iconBackgroundColor:
          data?.data?.iconBackgroundColor ||
          getEventBackgroundColor(data.data?.type),
        ...LOADPUCK_PROPS?.(props)?.[data.data?.type],
      };
      return (
        <Puck
          {...props}
          innerContentStyles={{
            fontFamily: data?.fontFamily,
            ...props.innerContentStyles,
          }}
        />
      );
    case EVENT_TYPES.EMPTY_HIDDEN:
      return <Box sx={{ width: '100%', height: '100%' }} />;
  }

  if (data.data?.status) {
    props.puckContainerColor =
      data.data?.puckContainerColor || data?.data?.isDelayed
        ? getEventBackgroundColor(EVENT_STATUS.DETAINED)
        : getEventBackgroundColor(data.data?.status);
  }

  switch (data.data?.status) {
    case EVENT_STATUS.PLANNED:
    case EVENT_STATUS.EMPTY:
    case EVENT_STATUS.PLANNED_DISAPPROVE:
    case EVENT_STATUS.COMPLETED:
      props = {
        ...props,
        ...LOADPUCK_PROPS?.(props)?.[data.data?.status],
      };
      break;
    case EVENT_STATUS.RELAY_START:
    case EVENT_STATUS.RELAY_END:
      props = {
        ...props,
        icon: data?.data?.icon || <SwapHorizIcon />,
        iconBackgroundColor:
          data?.data?.iconBackgroundColor ||
          getEventBackgroundColor(data.data?.status),
        ...LOADPUCK_PROPS?.(props)?.[data.data?.status],
      };
      break;
    case EVENT_STATUS.DETAINED:
      props = {
        ...props,
        ...LOADPUCK_PROPS?.(props)?.[data.data?.status],
        icon: data?.data?.icon || <DTIcon />,
        puckBackgroundColor:
          data?.data?.puckBackgroundColor ??
          getEventBackgroundColor(data?.data?.status),
      };
      break;
    // case EVENT_STATUS.ASSIGNED:
    //   props["puckBackgroundColor"] =
    //     data.data?.eventBackgroundColor ||
    //     getEventBackgroundColor(data.data?.status);
    //   break;
    case EVENT_STATUS.EMPTY_HIDDEN:
      return <Box sx={{ width: '100%', height: '100%' }} />;
    default:
      props = {
        ...props,
        ...LOADPUCK_PROPS?.(props)?.[data.data?.status],
      };
  }
  return (
    <Puck
      {...props}
      innerContentStyles={{
        fontFamily: data?.fontFamily,
        ...props.innerContentStyles,
      }}
    />
  );
};

export const isTooltipAllowed = (status: string): boolean => {
  if (status === EVENT_TYPES.CHECKBOX) {
    return false;
  }
  return true;
};

export const disableTooltipExists = (types: string[], type: string) => {
  if (types.includes(type)) return true;
  return false;
};

export const ifDatesCollapse = (first: any, second: any): boolean => {
  if (
    (new Date(first?.startDate).getTime() >=
      new Date(second?.startDate).getTime() &&
      new Date(first?.startDate).getTime() <=
        new Date(second?.endDate).getTime()) ||
    (new Date(first?.endDate).getTime() >=
      new Date(second?.startDate).getTime() &&
      new Date(first?.endDate).getTime() <= new Date(second?.endDate).getTime())
  ) {
    return true;
  }
  return false;
};

export const getToolTipProps = (
  data: any,
  eventTooltipContent: ColumnTooltipType | undefined
) => {
  switch (data.type) {
    case EVENT_TYPES.GAP:
      return gapToolTipBody(data, eventTooltipContent);
    default:
      return eventToolTipBody(data, eventTooltipContent);
  }
};

export const validateEventDropAndResize = (params: {
  newResource: any;
  startDate: Date;
  endDate: Date;
}): boolean => {
  const { newResource, startDate, endDate } = params;
  const eventStartTime = startDate;
  const eventEndTime = endDate;
  let allowDrag = true;
  for (let i = 0; i < newResource?.timeRanges?.length; i++) {
    const range = newResource?.timeRanges[i];
    const startTime = range?.data?.startDate?.getTime();
    const endTime = range?.data?.endDate?.getTime();
    if (
      (eventStartTime >= startTime && eventStartTime < endTime) ||
      (eventEndTime >= startTime && eventEndTime < endTime) ||
      (eventStartTime <= startTime && eventEndTime >= endTime)
    ) {
      allowDrag = false;
      break;
    }
  }
  return !!allowDrag;
};

export const handleEventRender = ({
  eventRecord,
  renderData,
  allowEventContentResponsiveness,
}: any) => ({
  data: !allowEventContentResponsiveness
    ? eventRecord.data
    : puckResponsiveRenderedData({ eventRecord, renderData }),
});

export const formatDateToDayAndMonth = (
  date: Date | string,
  isMonthRequired = true
) => {
  const d = new Date(date);
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  const day = weekDays[d.getDay()];
  if (!isMonthRequired) return `${day} ${dd}`;

  return `${day} ${mm}/${dd}`;
};

export const formatAMPM = (d: Date | string, inUpperCase = false) => {
  const date = new Date(d);
  let hours = date.getHours();
  let minutes = date.getMinutes() as any;
  let ampm = hours >= 12 ? ' pm' : ' am';
  ampm = inUpperCase ? ampm.toUpperCase() : ` ${ampm}`;
  hours %= 12;
  hours = hours || 12;
  minutes = minutes.toString().padStart(2, '0');
  const strTime = `${hours}:${minutes}${ampm}`;
  return strTime;
};

export const dateDiffInHrsMin = (
  dt1: string | Date,
  dt2: string | Date
): string => {
  let diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
  diff /= 60;
  const totalMinutes = Math.abs(Math.round(diff));
  const hours = Math.floor(totalMinutes / 60);
  const min = totalMinutes % 60;
  const minString = min > 0 ? `${min}m` : '';
  const hrsString = hours > 0 ? `${hours}h` : '';
  return `${hrsString} ${minString}`;
};

export const allowedToUnassign = (status: string, events: Array<string>) => {
  if (events?.includes(status)) return true;
  return false;
};

export const gridContent = (contents: Array<any>) =>
  contents?.map((content: any) =>
    content.element ? (
      <Grid item xs={12}>
        {content.element}
      </Grid>
    ) : (
      <>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ ...tooltipBodyContentStyles }}>
            {content?.key}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="end" display="block">
          <Typography
            variant="subtitle2"
            sx={{ mt: 0, ...tooltipBodyContentStyles }}
            fontWeight="bold"
          >
            {content.value}
          </Typography>
          {content.subValue && (
            <Typography variant="caption" sx={{ ...tooltipBodyContentStyles }}>
              {content.subValue}
            </Typography>
          )}
        </Grid>
      </>
    )
  );

export const formDateForTimeOffTooltip = (date: string | Date) => {
  const d = new Date(date);
  return `${months[d.getMonth()]} ${d.getDate()} @ ${formatAMPM(date)}`;
};

export const getTripSelectPuckContent = (puckWidth: number, eventData: any) => {
  const tripSelectConfig = eventData.tripSelectConfig ?? {};
  if (puckWidth < SELECT_TRIP_RESPONSIVE_SIZES.SIZE_1) {
    tripSelectConfig['cancelIconButtonStyles'] = {
      margin: 0,
      width: '100%',
      height: '32px',
    };
    tripSelectConfig['containerStyles'] = { padding: 0 };
    tripSelectConfig['showClearIcon'] = true;
  }
  if (puckWidth < SELECT_TRIP_RESPONSIVE_SIZES.SIZE_2) {
    tripSelectConfig['allowOnlyCancel'] = true;
    tripSelectConfig['showClearIcon'] = true;
  } else if (puckWidth < SELECT_TRIP_RESPONSIVE_SIZES.SIZE_3) {
    tripSelectConfig['text'] = '';
    tripSelectConfig['showClearIcon'] = false;
  }
  return { ...eventData, tripSelectConfig };
};
