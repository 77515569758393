import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import ThreeDotMenuIcon from '@/ui-kit/components/ThreeDotMenuIcon';
import { AlertActions } from '@/common/LoadTabPanel/tabs/Alerts/constants';
import React, { FC } from 'react';
import { useLoadAlertItem } from '@/common/LoadTabPanel/tabs/Alerts/hooks';
import DeletePopup from '@/common/DeletePopup';
import { AddEditLoadAlertForm } from '@/common/LoadTabPanel/tabs/Alerts/components/AddEditLoadAlertForm';
import { AddEditLoadAlertFormValidationSchema } from '@/common/LoadTabPanel/tabs/Alerts/constants/LoadAlertsConstants';
import FormDialog from '@/common/Ui/FormDialog';
import { LoadAlert, LoadAlertFlagType } from '@/models';
import { LoadAlertInfo } from '@/common/LoadTabPanel/tabs/Alerts/components/LoadAlertInfo';

interface Props {
  alert: LoadAlert;
  flagTypes: LoadAlertFlagType[];
  updateAlertsList: () => void;
}

export const LoadAlertItem: FC<Props> = ({
  alert,
  flagTypes,
  updateAlertsList,
}) => {
  const {
    anchorEl,
    showDeleteAlert,
    showEditModal,
    httpErrors,
    handleMenuClose,
    handleMenuClick,
    handleMenuItemClick,
    handleDeleteModalClose,
    handleDeleteAlert,
    handleEditAlertDialogClose,
    handleAlertEdit,
  } = useLoadAlertItem(alert, updateAlertsList);

  return (
    <>
      <Box
        sx={{
          margin: '10px 0px',
          marginBottom: '30px',
          background: '#f5f5f5!important',
          padding: '16px',
          borderRadius: '10px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          marginBottom={'12px'}
        >
          <Typography
            variant="h7"
            sx={{
              color: 'primary.main',
            }}
          >
            {alert.name}
          </Typography>
          <Stack
            spacing={{ xs: 1, sm: 1 }}
            direction="column"
            useFlexGap
            sx={{ flexWrap: 'wrap', fontSize: '12px' }}
          >
            <ThreeDotMenuIcon
              menuOptions={AlertActions}
              open={!!anchorEl}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Stack>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <LoadAlertInfo label="Email" value={alert.email} />
          {!!alert.phoneData && (
            <LoadAlertInfo label="Phone" value={alert.phoneData.phone} />
          )}
          <LoadAlertInfo
            label="Notification"
            value={(alert.loadNotifications ?? [])
              .map((notification) => notification.value)
              .join(', ')}
          />
        </Box>
      </Box>

      {showDeleteAlert && (
        <DeletePopup
          open={showDeleteAlert}
          onClose={handleDeleteModalClose}
          onAction={handleDeleteAlert}
          title="Delete Alert"
          body="Are you sure you want to delete this alert?"
        />
      )}
      {showEditModal && (
        <FormDialog
          data={alert}
          open={showEditModal}
          titleText="Edit Alert"
          onAction={handleAlertEdit}
          handleClose={handleEditAlertDialogClose}
          actionLabel="Save Alert"
          contentRenderer={() => <AddEditLoadAlertForm flagTypes={flagTypes} />}
          validationSchema={AddEditLoadAlertFormValidationSchema}
          httpErrors={httpErrors}
        />
      )}
    </>
  );
};
