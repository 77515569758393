import { Matrix } from '@/models';
import { DeleteOutline } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { AUTOMATE_UNIT_VAL } from '../../constants/contracts';
import { getDateFromDateObject } from '../../utils/contract_payload_formatter';
import {
  CustomCellEdit,
  CustomGridCell,
  EditableAutocompleteCell,
  GridSwitch,
} from '../custom_table_components';
import { RequiredFieldsHeader, getCityStateInfo } from './utils';

export const LANE_GRID_DELETE_COL: GridColDef[] = [
  {
    field: 'remove',
    headerName: '',
    maxWidth: 50,
    align: 'center',
    flex: 1,
    isHyperLink: true,
    renderCell: () => {
      return <DeleteOutline sx={{ color: 'blue', transform: 'scale(0.8)' }} />;
    },
  },
];

const NUMBER_INPUT = { type: 'numeric' };
const COMMON_TABLE_HEADERS = [
  {
    field: 'totalMiles',
    headerName: 'Total Miles',
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    editable: true,
    type: 'numeric',
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => {
      return <CustomCellEdit {...params} />;
    },
  },
  {
    field: 'weight',
    headerName: 'Weight (lbs)',
    type: 'numeric',
    flex: 1,
    minWidth: 120,
    editable: true,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },

    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => {
      params = { ...params, ...NUMBER_INPUT };
      return <CustomCellEdit {...params} />;
    },
  },
  {
    field: 'equipmentType',
    headerName: 'Equipment Type',
    flex: 1,
    minWidth: 120,
    maxWidth: 180,
    editable: true,
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.formattedValue}
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'mode',
    headerName: 'Mode',
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    editable: true,
    type: 'singleSelect',
    valueOptions: [
      { value: 'FTL', label: 'FTL' },
      { label: 'Partial', value: 'PARTIAL' },
      { label: 'FTL/Partial', value: 'FTL_PARTIAL' },
    ],
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.formattedValue}
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'rateType',
    headerName: 'Rate Type',
    flex: 1,
    minWidth: 120,
    maxWidth: 180,
    editable: true,
    // type: 'singleSelect',
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={
            params?.formattedValue
              ? params?.formattedValue
              : params?.value?.value
          }
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'rate',
    headerName: 'Rate ($)',
    flex: 1,
    minWidth: 100,
    type: 'numeric',
    maxWidth: 100,
    editable: true,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => {
      params = { ...params, ...NUMBER_INPUT };
      return <CustomCellEdit {...params} />;
    },
  },
  {
    field: 'minimumAmount',
    headerName: 'Minimum Amount ($)',
    flex: 1,
    minWidth: 100,
    type: 'numeric',
    maxWidth: 100,
    editable: true,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => {
      params = { ...params, ...NUMBER_INPUT };
      return <CustomCellEdit {...params} />;
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 120,
    maxWidth: 180,
    editable: true,
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => <CustomCellEdit {...params} />,
  },
];
export const LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_MILEAGE: GridColDef[] =
  [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'minMiles',
      renderHeader: () => RequiredFieldsHeader('Min Miles'),
      flex: 1,
      minWidth: 120,
      maxWidth: 180,
      type: 'numeric',
      editable: true,
      cellClassName: (params) => {
        if (params?.value?.isInvalid) return 'error';
        return '';
      },

      renderCell: (params) => {
        return (
          <CustomGridCell
            {...params}
            value={params?.value?.value}
            err={params?.value?.message}
          />
        );
      },
      renderEditCell: (params) => {
        params = { ...params, ...NUMBER_INPUT };
        return <CustomCellEdit {...params} />;
      },
    },
    {
      field: 'maxMiles',
      renderHeader: () => RequiredFieldsHeader('Max Miles'),
      flex: 1,
      minWidth: 120,
      type: 'numeric',
      maxWidth: 180,
      editable: true,
      cellClassName: (params) => {
        if (params?.value?.isInvalid) return 'error';
        return '';
      },

      renderCell: (params) => {
        return (
          <CustomGridCell
            {...params}
            value={params?.value?.value}
            err={params?.value?.message}
          />
        );
      },
      renderEditCell: (params) => {
        params = { ...params, ...NUMBER_INPUT };
        return <CustomCellEdit {...params} />;
      },
    },
    ...COMMON_TABLE_HEADERS,
  ];

export const LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_CITY: GridColDef[] = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'origin',
    renderHeader: () => RequiredFieldsHeader('Origin'),
    flex: 1,
    minWidth: 180,
    maxWidth: 200,
    editable: true,
    renderEditCell: (params) => <EditableAutocompleteCell params={params} />,
    valueSetter: (params) => ({
      ...params.row,
      origin: getCityStateInfo(params?.value?.value),
    }),
    valueFormatter: (params) => getCityStateInfo(params?.value?.value),
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.formattedValue}
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'destination',
    renderHeader: () => RequiredFieldsHeader('Destination'),
    flex: 1,
    minWidth: 180,
    maxWidth: 200,
    editable: true,
    renderEditCell: (params) => <EditableAutocompleteCell params={params} />,
    valueFormatter: (params) => getCityStateInfo(params?.value?.value),
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.formattedValue}
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'region',
    headerName: 'Fuel Region',
    flex: 1,
    minWidth: 100,
    maxWidth: 200,
    editable: true,
    type: 'singleSelect',
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    valueOptions: () => {
      return Object.keys(Matrix).map((matrixKey) => ({
        value: matrixKey,
        label: Matrix[matrixKey as keyof typeof Matrix],
      }));
    },
    valueFormatter: (params) => {
      return params.value;
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={
            Matrix[params?.formattedValue?.value as keyof typeof Matrix] ?? ''
          }
          err={params?.value?.message}
        />
      );
    },
  },
  ...COMMON_TABLE_HEADERS,
];

export const LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_ZIP: GridColDef[] = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'zipStartOrigin',
    renderHeader: () => RequiredFieldsHeader('Zip Start Origin'),
    flex: 1,
    minWidth: 120,
    maxWidth: 180,
    editable: true,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => <CustomCellEdit {...params} />,
  },
  {
    field: 'zipEndOrigin',
    headerName: 'Zip End Origin',
    flex: 1,
    minWidth: 120,
    maxWidth: 180,
    editable: true,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => <CustomCellEdit {...params} />,
  },
  {
    field: 'destinationStartOrigin',
    renderHeader: () => RequiredFieldsHeader('Zip Start Destination'),
    flex: 1,
    minWidth: 160,
    maxWidth: 180,
    editable: true,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },

    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => <CustomCellEdit {...params} />,
  },
  {
    field: 'destinationEndOrigin',
    headerName: 'Zip End Destination',
    flex: 1,
    minWidth: 120,
    editable: true,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },

    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => <CustomCellEdit {...params} />,
  },
  {
    field: 'region',
    headerName: 'Fuel Region',
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    editable: true,
    type: 'singleSelect',
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    valueOptions: () => {
      return Object.keys(Matrix).map((matrixKey) => ({
        value: matrixKey,
        label: Matrix[matrixKey as keyof typeof Matrix],
      }));
    },
    valueFormatter: (params) => {
      return params.value;
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={
            Matrix[params?.formattedValue?.value as keyof typeof Matrix] ?? ''
          }
          err={params?.value?.message}
        />
      );
    },
  },
  ...COMMON_TABLE_HEADERS,
];

export const MODIFIED_DATE_COLUMN: GridColDef[] = [
  {
    field: 'modifiedDate',
    headerName: 'Modified Date',
    flex: 1,
    minWidth: 160,
    maxWidth: 180,
    editable: false,
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={getDateFromDateObject(params?.value?.value)}
          err={params?.value?.message}
        />
      );
    },
  },
];
export const ACCESSORIAL_ITEMS_COL_DEF: GridColDef[] = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'rateType',
    renderHeader: () => RequiredFieldsHeader('Item'),
    flex: 2,
    editable: true,
    minWidth: 150,
    maxWidth: 250,
    // renderEditCell: (params) => <DropDownCellEdit params={params} />,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.formattedValue}
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'unit',
    headerName: 'Unit',
    flex: 1,
    minWidth: 150,
    maxWidth: 180,
    // editable: false,
    disableColumnMenu: true,
    type: 'singleSelect',
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.formattedValue}
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'fuelSurchargeName',
    headerName: 'Matrix Name',
    flex: 1,
    minWidth: 100,
    maxWidth: 200,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={
            params?.formattedValue ? params.formattedValue : params.value.value
          }
          err={params?.value?.message}
        />
      );
    },
  },
  {
    field: 'rate',
    renderHeader: () => RequiredFieldsHeader('Rate'),
    flex: 2,
    type: 'numeric',
    editable: true,
    minWidth: 120,
    maxWidth: 180,
    cellClassName: (params) => {
      if (params?.value?.isInvalid) return 'error';
      return '';
    },
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => {
      params = { ...params, ...NUMBER_INPUT };
      const disabled =
        params.row.unit.value === 'PERCENT_MATRIX' ||
        params.row.unit.value === 'PRICE_PER_MILE_MATRIX';
      return <CustomCellEdit disabled={disabled} {...params} />;
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 4,
    editable: true,
    minWidth: 120,
    maxWidth: 180,
    renderCell: (params) => {
      return (
        <CustomGridCell
          {...params}
          value={params?.value?.value}
          err={params?.value?.message}
        />
      );
    },
    renderEditCell: (params) => <CustomCellEdit {...params} />,
  },
  {
    field: 'autoPopulate',
    headerName: 'Automate',
    align: 'center',
    flex: 1,
    renderCell: (params) => {
      const isEnabled = Boolean(
        params?.row?.unit &&
          AUTOMATE_UNIT_VAL.includes(params?.row?.unit?.value)
      );
      return <GridSwitch disabled={!isEnabled} params={params} />;
    },
  },
  {
    field: 'remove',
    headerName: '',
    flex: 1,
    isHyperLink: true,
    align: 'center',
    maxWidth: 80,
    renderCell: () => {
      return <DeleteOutline sx={{ color: 'blue', transform: 'scale(0.8)' }} />;
    },
  },
];

export const GRID_SELECT_TYPE_COLUMNS = [
  'rateType',
  'equipmentType',
  'unit',
  'mode',
  'region',
];

export const REQUIRED_FIELDS = [
  'origin',
  'destination',
  'zipStartOrigin',
  'destinationStartOrigin',
  'minMiles',
  'maxMiles',
  'region',
];

export const ACCESSORIAL_REQUIRED_FIELDS = [
  'rateType',
  'fuelSurchargeName',
  'rate',
];
