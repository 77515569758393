import React from 'react';
import { CircularProgress } from '@mui/material';
import { EVENT_STATUS } from '../constants/puck.constant';

export enum TOOLTIP_TYPES {
  BREAK = 'BREAK',
  PUCK = 'PUCK',
}

export const PUCK_COLOR_BY_STATUS = {
  [EVENT_STATUS.COMPLETED]: '#B6BDC7',
  [EVENT_STATUS.IN_PROGRESS]: '#0C6BB9',
  [EVENT_STATUS.IN_TRANSIT]: '#0C6BB9',
  [EVENT_STATUS.ASSIGNED]: '#2F3B58',
  [EVENT_STATUS.PLANNED]: '#5C667D',
  [EVENT_STATUS.NEED_LOAD]: '#C8372C',
  [EVENT_STATUS.DEADHEAD]: '#2B64CB',
  [EVENT_STATUS.DISPATCHED]: '#008280',
  [EVENT_STATUS.GAP]: '#E7F0F7',
  [EVENT_STATUS.ENROUTE_TO_PICKUP]: '#0C6BB9',
  [EVENT_STATUS.RECOMMENDED]: '#3B873E',
  [EVENT_STATUS.DETAINED]: '#F76E64',
  [EVENT_STATUS.AVAILABLE]: '#2196F3',
  [EVENT_STATUS.CANCELLED]: 'rgba(0, 17, 34, 0.12)',
  [EVENT_STATUS.INVOICED]: '#82C784',
  [EVENT_STATUS.PAID]: '#4CAF50',
  [EVENT_STATUS.RELAY_START]: '#6B92DB',
};

export const PUCK_HOVER_VISIBILITY_CLASSNAME = 'puck-onhover-visibility';
export const NEED_LOAD_HOVER_CLASSNAME = 'need-load-puck';
export const NEED_LOAD_TEXT_HOVER_CLASSNAME = 'need-load-puck-text';
export const NEED_LOAD_CONTENT_CLASSNAME = 'need-load-content-container';

export const defaultLoaderConfig = {
  status: false,
  icon: <CircularProgress size={20} />,
};

export const GANTT_TOOLTIP_POPUP_ID = 'gantt-custom-toolti';
