import React from 'react';

const DelayIcon = () => {
  return (
    <svg
      width="31"
      height="23"
      viewBox="0 0 31 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.355 9.00516C16.895 9.54516 17.165 10.2952 17.165 11.2702C17.165 12.2452 16.895 12.9952 16.355 13.5202C15.815 14.0452 15.05 14.3152 14.09 14.3152H12.875V8.19516H14.09C15.05 8.19516 15.815 8.46516 16.355 9.00516ZM30.5 3.03516V19.5352C30.5 21.1852 29.15 22.5352 27.5 22.5352H3.5C1.85 22.5352 0.5 21.1852 0.5 19.5352V3.03516C0.5 1.38516 1.85 0.0351562 3.5 0.0351562H27.5C29.15 0.0351562 30.5 1.38516 30.5 3.03516ZM5.03 5.63016L5.33 12.8152H7.505L7.805 5.63016H5.03ZM7.97 15.2752C7.97 14.8702 7.82 14.5402 7.535 14.2702C7.25 14.0002 6.89 13.8652 6.455 13.8652C6.005 13.8652 5.63 14.0002 5.345 14.2702C5.06 14.5402 4.925 14.8702 4.925 15.2752C4.925 15.6652 5.06 15.9952 5.345 16.2652C5.63 16.5202 6.005 16.6552 6.455 16.6552C6.89 16.6552 7.25 16.5202 7.535 16.2652C7.82 15.9952 7.97 15.6652 7.97 15.2752ZM19.775 11.2702C19.775 10.2352 19.55 9.32016 19.085 8.52516C18.635 7.73016 17.99 7.10016 17.165 6.66516C16.34 6.23016 15.365 6.00516 14.255 6.00516H10.31V16.5352H14.255C15.35 16.5352 16.325 16.3102 17.15 15.8752C17.99 15.4402 18.635 14.8102 19.085 14.0152C19.535 13.2202 19.775 12.3052 19.775 11.2702ZM27.125 14.5552H23.765V6.00516H21.2V16.5352H27.125V14.5552Z"
        fill="white"
      />
    </svg>
  );
};

export default DelayIcon;
