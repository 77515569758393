import { Service } from '@/api/interfaces/Service';
import { ServiceError } from '@/api/interfaces/helperTypes';
import { RuleItem } from '@/models/DTOs/Rules/Rules';
import {
  CreateRuleRequest,
  DeleteRuleRequest,
  GetRulesRequest,
  UpdateRuleRequest,
} from '@/models/DTOs/Rules/Requests';

export abstract class IRulesService extends Service {
  abstract getRulesList(
    params: GetRulesRequest
  ): Promise<RuleItem[] | ServiceError>;

  abstract deleteRule(params: DeleteRuleRequest): Promise<void | ServiceError>;

  abstract updateRule(params: UpdateRuleRequest): Promise<void | ServiceError>;

  abstract createRule(params: CreateRuleRequest): Promise<void | ServiceError>;
}
