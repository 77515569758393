import React, { useEffect, useState } from 'react';
import Sidedrawer from '../Sidedrawer';
import ProviderHero from '../../../../ui-kit/components/ProviderHero';
import ProviderDescription from '../../../../ui-kit/components/ProviderDescription';
import CustomerFeatures from '../../../../ui-kit/components/CustomerFeatures';
import IntegrationActivationPanel from '../../../../ui-kit/components/IntegrationActivationPanel';
import AccountsSection from '../../../../ui-kit/components/IntegrationAccountCards/AccountsSection';
import {
  ICardData,
  IcustomerFeaturesProps,
  IcustomerListWithLogo,
  IeldCustomerFeatures,
} from '../../../../types/ELDTypes';
import { Box, Grid, Stack } from '@mui/material';
import { eldService } from '../../../../api';
import {
  ActivateCustomerRequest,
  EditAccountRequest,
  GetDriverNamesRequest,
  LinkDriverRequest,
  LinkTractorRequest,
  TractorNamesRequest,
  UnlinkDriverRequest,
  UnlinkTractorRequest,
} from '../../../../models/DTOs/ELD/Requests';
import { ManageEldIntegration } from '../../../../common/ManageEldIntegration';
import { IRowDetails } from '../../../../types/ELDManageTypes';
import { ServiceError } from '../../../../api/interfaces';
import TerminalController from '../../../../views/settings/terminals/TerminalController';
import {
  getDriverDefaultRequestData,
  getTractorDefaultRequestData,
} from '../../constants/defaultRequestData';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import { getHasPermission } from '../../../../common/Permission/utils/helperUtils';
import { gridPageSize } from '../../../../utils';

export default function SelectedCustomer({
  currentCustomerData,
  providerData,
  getProviderDetails,
  refreshAccountData,
  customerListWithLogo,
}: IcustomerFeaturesProps) {
  const [open, setOpen] = useState(false);
  const [features, setFeatures] = useState([]);
  const [isDeletePopupOpen, openDeletePopup] = useState(false);
  const [selectedCardForDeletion, setCardForDeletion] = useState({});
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [activationErrMessage, setErrMessage] = useState('');
  const [formErr, setFormErr] = useState({});
  const [selectedTab, setSelectedTab] = useState('');
  const [allDriverData, setDriverData] = useState([]);
  const [allTractorData, setTractorData] = useState([]);
  const [cardData, setAllCardData] = useState({});
  const [checkedTractors, setCheckedTractors] = useState<string[]>([]);
  const [checkedDrivers, setCheckedDrivers] = useState<string[]>([]);
  const [isMappedTractor, setIsMappedTractor] = useState(false);
  const [isMappedDriver, setIsMappedDriver] = useState(false);
  const [tractorNameFilterData, setTractorNameFilterData] = useState<string[]>(
    []
  );
  const [tractorStatusFilterData, setTractorStatusFilterData] =
    useState<{} | null>(null);
  const [driverNameFilterData, setDriverNameFilterData] = useState<string[]>(
    []
  );
  const [driverStatusFilterData, setDriverStatusFilterData] =
    useState<{} | null>(null);

  const [driverIdsFilterData, setDriverIdsFilterData] = useState([]);
  const [tractorIdsFilterData, setTractorIdsFilterData] = useState([]);
  const [tractorRequestData, setTractorRequestData] = useState(
    getTractorDefaultRequestData
  );
  const [driverRequestData, setDriverRequestData] = useState(
    getDriverDefaultRequestData
  );
  const [areFiltersApplied, setFiltersApplied] = useState(false);
  const [totalDataInTable, setTableTotalData] = useState<number>(0);
  const [renderDriver, setRenderDriver] = useState(0);
  const [renderTractor, setRenderTractor] = useState(0);

  const tractorDefaultFilterValue = {
    tractorNameFilter: tractorNameFilterData,
    tractorStatusFilter: tractorStatusFilterData,
  };

  const driverDefaultFilterValue = {
    driverNameFilter: driverNameFilterData,
    driverStatusFilter: driverStatusFilterData,
  };

  const { terminals } = TerminalController.instance();

  const resetRequestData = () => {
    setTractorRequestData(getTractorDefaultRequestData);
    setDriverRequestData(getDriverDefaultRequestData);
    getDriverDetails(cardData);
    getTractorDetails(cardData);
  };

  const resetFilters = () => {
    setTractorNameFilterData([]);
    setTractorStatusFilterData({ label: 'All', value: 'ALL' });
    setDriverNameFilterData([]);
    setDriverStatusFilterData({ label: 'All', value: 'ALL' });
    setTractorIdsFilterData([]);
    setDriverIdsFilterData([]);
  };

  useEffect(() => {
    getCustomerFeatures();
  }, [providerData, currentCustomerData]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    if (!newOpen) {
      setErrMessage('');
      setFormErr({});
      setDataToUpdate({});
    }
  };

  const validateFormCreds = async (payload: EditAccountRequest) => {
    const res = await eldService.validateAccount(payload);
    if (res.credentialsUnique && res.displayNameUnique) {
      if (currentCustomerData.providerId === 4) {
        authenticateOAuth();
      }
      if (Object.keys(dataToUpdate).length > 0) {
        updateAccountCard(payload);
      } else activateProvider(payload);
    } else {
      setFormErr(res);
    }
  };

  const activateProvider = async (payload: ActivateCustomerRequest) => {
    await eldService.activateProvider(payload);
    setOpen(false);
    getProviderDetails();
    setFormErr({});
  };

  const updateAccountCard = async (payload: EditAccountRequest) => {
    const res = await eldService.editAccount(payload);
    if (Object.keys(res).length > 0) {
      setOpen(false);
      getProviderDetails();
    }
  };

  const getCustomerFeatures = (): void => {
    const currentCustomerFeatures = providerData?.providerFeatures?.filter(
      (item: IeldCustomerFeatures) => {
        if (item.providerId === currentCustomerData.providerId) return item;
      }
    );
    currentCustomerFeatures &&
      currentCustomerFeatures.length > 0 &&
      setFeatures(currentCustomerFeatures[0]?.supportedFeatures);
  };

  const openConfirmationPopup = (cardData: ICardData) => {
    openDeletePopup(true);
    setCardForDeletion(cardData);
  };
  const closeConfirmationPopup = () => {
    openDeletePopup(false);
  };
  const deactivateAccount = async (cardData: ICardData) => {
    const payload: { id: string; providerId: number; organizationId: number } =
      {
        id: cardData.id,
        providerId: cardData.providerId,
        organizationId: cardData.organizationId,
      };

    const res = await eldService.deleteAccount(payload);
    if (res !== null) {
      closeConfirmationPopup();
      getProviderDetails();
    } else {
      closeConfirmationPopup();
    }
  };

  const editAccount = async (cardData: ICardData) => {
    setOpen(true);
    setDataToUpdate(cardData);
  };

  const reauthenticate = (cardData: ICardData) => {
    let dp = '';
    const terminals = cardData.terminalIds;
    cardData.data.forEach((el: { name: string; value: string }) => {
      if (el.name === 'displayName') dp = el.value;
    });
    const payload = {
      data: [
        { name: 'displayName', value: dp },
        { name: 'code', value: '' },
      ],
      integrationType: 'ELD',
      isValid: true,
      organizationId: cardData.organizationId,
      providerId: cardData.providerId,
      terminalIds: terminals,
    };
    sessionStorage.setItem('KeepTruckingCreds', JSON.stringify(payload));
    authenticateOAuth();
  };

  const authenticateOAuth = () => {
    const url =
      'https://api.gomotive.com/oauth/authorize?' +
      `client_id=${window.env.KEEPTRUCKIN_CLIENT_ID}` +
      `&redirect_uri=${window.location.origin}/` +
      'settings/integrations?keeptruckin=1' +
      '&response_type=code' +
      `&scope=${process.env.KEEPTRUCKIN_AUTH_SCOPE}`;
    window.location = url as unknown as Location;
  };

  const getDriverDetails = async (accountData: any, scrolling = false) => {
    const request = new GetDriverNamesRequest();
    request.credentialId = accountData.id;
    request.pageNumber = driverRequestData.pageNumber;
    request.pageSize = driverRequestData.pageSize;
    request.linkedFilter = driverRequestData.linkedFilter;
    request.driverIds = driverRequestData.driverIds;
    areFiltersApplied && setDriverData([]);
    const res = await eldService.getAllDrivers(request);
    if (Object.keys(res).length > 0) {
      setTableTotalData(res?.totalElements);
      if (scrolling) {
        setFiltersApplied(false);
        setDriverData((oldData) => [...oldData, ...res.content]);
      } else {
        setDriverData(res?.content);
      }
    }
  };

  const getTractorDetails = async (accountData: any, scrolling = false) => {
    const request = new TractorNamesRequest();
    request.credentialId = accountData.id;
    request.pageNumber = tractorRequestData.pageNumber;
    request.pageSize = tractorRequestData.pageSize;
    request.linkedFilter = tractorRequestData.linkedFilter;
    request.tractorIds = tractorRequestData.tractorIds;
    areFiltersApplied && setTractorData([]);
    const res = await eldService.getAllTractors(request);
    if (Object.keys(res).length > 0) {
      setTableTotalData(res?.totalElements);
      if (scrolling) {
        setFiltersApplied(false);
        setTractorData((oldData) => [...oldData, ...res.content]);
      } else {
        setTractorData(res?.content);
      }
    }
  };

  const clickedParamToManage = (data: string, accountData: any) => {
    setAllCardData(accountData);
    if (data === 'Drivers') {
      setSelectedTab('Driver');
      setDriverData([]);
      getDriverDetails(accountData);
    } else {
      setSelectedTab('Tractor');
      setTractorData([]);
      getTractorDetails(accountData);
    }
  };

  const unlinkSelectedDriver = async (driverToUnlink: Array<IRowDetails>) => {
    const request = new UnlinkDriverRequest();
    const ids = driverToUnlink.map((data: IRowDetails) => {
      return data.id;
    });
    request.eldDriverIds = ids;
    request.credentialId = cardData.id;
    const res = await eldService.unlinkDriver(request);
    const allDriverDataCopy = allDriverData;
    if (res && res.drivers && res.drivers.length > 0) {
      setCheckedDrivers([]);
      allDriverData.map((el: any, index: number) => {
        res?.drivers.map((item: any) => {
          if (el.id === item.id) {
            allDriverDataCopy[index] = item;
          }
        });
      });
      getProviderDetails();
      setDriverData(allDriverDataCopy);
      setIsMappedDriver(false);
      setRenderDriver((prev) => prev + 1);
    }
  };

  const onManageTabChanged = (elmId: string) => {
    setSelectedTab(elmId);
    if (elmId === 'Driver') {
      setDriverData([]);
      getDriverDetails(cardData);
    } else {
      setTractorData([]);
      getTractorDetails(cardData);
    }
  };
  const linkDriver = async (eldId: string, driverId: string) => {
    const request = new LinkDriverRequest();
    request.driverToEldDriverIds = {
      [driverId]: eldId,
    };
    request.credentialId = cardData.id;
    const res = await eldService.linkDriver(request);
    const allDriverDataCopy = allDriverData;
    if (res && res.linkedDrivers && res.linkedDrivers.length > 0) {
      setCheckedDrivers([]);
      allDriverData.map((el: any, index: number) => {
        res?.linkedDrivers.map((item: any) => {
          if (el.id === item.id) {
            allDriverDataCopy[index] = item;
          }
        });
        if (res?.unLinkedDrivers?.length > 0) {
          res?.unLinkedDrivers.map((item: any) => {
            if (el.id === item.id) {
              allDriverDataCopy[index] = item;
            }
          });
        }
      });
      getProviderDetails();

      setRenderDriver((prev) => prev + 1);
      setDriverData(allDriverDataCopy);
    }
  };

  const linkTractor = async (eldId: string, axeleTractorId: string) => {
    const request = new LinkTractorRequest();
    request.entityToEldEntityIds = {
      [axeleTractorId]: eldId,
    };
    request.credentialId = cardData.id;
    const allTractorDataCopy = allTractorData;
    const res = await eldService.linkTractor(request);
    setCheckedTractors([]);
    allTractorDataCopy.map((el: any, index: number) => {
      res?.linkedTractors.map((item: any) => {
        if (el.id === item.id) {
          allTractorDataCopy[index] = item;
        }
      });
      if (res?.unLinkedTractors?.length > 0) {
        res?.unLinkedTractors.map((item: any) => {
          if (el.id === item.id) {
            allTractorDataCopy[index] = item;
          }
        });
      }
    });
    getProviderDetails();
    setTractorData(allTractorDataCopy);
    setRenderTractor((prev) => prev + 1);
  };

  const updateDriverList = async () => {
    await refreshAccountData(cardData, '/driver');
    resetFilters();
    setDriverData([]);
    JSON.stringify(driverRequestData) ===
    JSON.stringify(getDriverDefaultRequestData)
      ? getDriverDetails(cardData)
      : setDriverRequestData(getDriverDefaultRequestData);
  };

  const updateTractorList = async () => {
    await refreshAccountData(cardData, '/tractor');
    resetFilters();
    setTractorData([]);
    JSON.stringify(tractorRequestData) ===
    JSON.stringify(getTractorDefaultRequestData)
      ? getTractorDetails(cardData)
      : setTractorRequestData(getTractorDefaultRequestData);
  };

  const unlinkSelectedTractor = async (tractorToUnlink: Array<IRowDetails>) => {
    const request = new UnlinkTractorRequest();
    const ids = tractorToUnlink.map((data: IRowDetails) => {
      return data.id;
    });
    request.eldTractorIds = ids;
    request.credentialId = cardData.id;
    const allTractorDataCopy = allTractorData;
    const res = await eldService.unlinkTractor(request);
    setCheckedTractors([]);
    allTractorData.map((el: any, index: number) => {
      res?.tractors.map((item: any) => {
        if (el.id === item.id) {
          allTractorDataCopy[index] = item;
        }
      });
    });
    getProviderDetails();
    setTractorData(allTractorDataCopy);
    setIsMappedTractor(false);
    setRenderTractor((prev) => prev + 1);
  };

  let currentCustomerActualData = {};
  customerListWithLogo.map((item: IcustomerListWithLogo) => {
    if (item.providerId === currentCustomerData.providerId) {
      currentCustomerActualData = item;
    }
  });

  const getDriverNamesList = async (name: string, pageNumber: number) => {
    const request = new GetDriverNamesRequest();
    request.credentialId = cardData?.id;
    request.pageNumber = pageNumber;
    request.pageSize = 25;
    request.pattern = name;
    const response = await eldService.getAllDrivers(request);
    if (response instanceof ServiceError) {
      return [];
    } else {
      return response;
    }
  };

  const getTractorsNamesList = async (name: string, pageNumber: number) => {
    const request = new TractorNamesRequest();
    request.credentialId = cardData?.id;
    request.pageNumber = pageNumber;
    request.pageSize = 25;
    request.pattern = name;
    const response = await eldService.getAllTractors(request);
    if (response instanceof ServiceError) {
      return [];
    } else {
      return response;
    }
  };

  const tractorsFiltersFormConfig = [
    {
      key: 1,
      name: 'tractorNameFilter',
      fieldName: 'tractorName',
      getData: getTractorsNamesList,
      label: 'Name',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: 'tractorStatusFilter',
      fieldName: 'label',
      options: [
        { label: 'All', value: 'ALL' },
        { label: 'Linked', value: 'LINKED' },
        { label: 'Non Linked', value: 'NON_LINKED' },
      ],
      label: 'Status',
      default: true,
      type: 'SingleAutocomplete',
      customStyles: {
        width: '200px',
        '.MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
      },
    },
  ];

  const driverFiltersFormConfig = [
    {
      key: 1,
      name: 'driverNameFilter',
      fieldName: 'fullname',
      getData: getDriverNamesList,
      label: 'Name',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: 'driverStatusFilter',
      fieldName: 'label',
      options: [
        { label: 'All', value: 'ALL' },
        { label: 'Linked', value: 'LINKED' },
        { label: 'Non Linked', value: 'NON_LINKED' },
      ],
      label: 'Status',
      default: true,
      type: 'SingleAutocomplete',
      customStyles: {
        width: '200px',
        '.MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
      },
    },
  ];

  useEffect(() => {
    getTractorDetails(cardData, true);
  }, [tractorRequestData]);
  useEffect(() => {
    getDriverDetails(cardData, true);
  }, [driverRequestData]);

  const handleOnChangeTractorCb = (newData: any, name: string) => {
    if (name === 'tractorNameFilter') {
      const searchParam = newData.map((item: any) => item.eldId);
      setTractorNameFilterData(newData);
      setTractorIdsFilterData(searchParam);
      setFiltersApplied(true);
      setTractorRequestData({
        pageNumber: 1,
        pageSize: gridPageSize,
        linkedFilter: tractorStatusFilterData?.value,
        tractorIds: searchParam,
      });
    }
    if (name === 'tractorStatusFilter') {
      setTractorStatusFilterData(newData);
      setFiltersApplied(true);
      setTractorRequestData({
        pageNumber: 1,
        pageSize: gridPageSize,
        linkedFilter: newData?.value,
        tractorIds: tractorIdsFilterData,
      });
    }
  };

  const handleOnChangeDriverCb = (newData: any, name: string) => {
    if (name === 'driverNameFilter') {
      const searchParam = newData.map((item: any) => item.eldId);
      setDriverNameFilterData(newData);
      setDriverIdsFilterData(searchParam);
      setFiltersApplied(true);
      setDriverRequestData({
        pageNumber: 1,
        pageSize: gridPageSize,
        linkedFilter: driverStatusFilterData?.value,
        driverIds: searchParam,
      });
    }
    if (name === 'driverStatusFilter') {
      setDriverStatusFilterData(newData);
      setFiltersApplied(true);
      setDriverRequestData({
        pageNumber: 1,
        pageSize: gridPageSize,
        linkedFilter: newData.value,
        driverIds: driverIdsFilterData,
      });
    }
  };

  const handleTractorScroll = (event: any) => {
    const updatedRequestData = {
      ...tractorRequestData,
      pageNumber: tractorRequestData.pageNumber + 1,
    };
    setTractorRequestData(updatedRequestData);
  };

  const handleDriverScroll = (event: any) => {
    const updatedRequestData = {
      ...driverRequestData,
      pageNumber: driverRequestData.pageNumber + 1,
    };
    setDriverRequestData(updatedRequestData);
  };

  const axelePermissions = {
    showAddBtn: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_ELD_ADD],
    }),
    showEdit: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_ELD_EDIT],
    }),
    showRemove: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_ELD_REMOVE],
    }),
    showAddDrivers: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.USER_PROFILE_ADD],
    }),
    showEditDrivers: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.USER_PROFILE_EDIT],
    }),
    showRemoveDrivers: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.USER_PROFILE_REMOVE],
    }),
    showAddTractors: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.EQUIPMENT_ADD],
    }),
    showEditTractors: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.EQUIPMENT_EDIT],
    }),
    showRemoveTractors: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.EQUIPMENT_REMOVE],
    }),
  };

  return (
    <Box className="selected-customer-block">
      <ProviderHero currentCustomerData={currentCustomerActualData} />
      <Stack sx={{ p: '50px 20px 0 30px' }}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={9}>
            <ProviderDescription currentCustomerData={currentCustomerData} />
          </Grid>
          <Grid item xs={2}>
            <IntegrationActivationPanel
              toggleDrawer={toggleDrawer(true)}
              status={currentCustomerData.status}
              showActivationBtn={
                providerData?.integrationsDTO[currentCustomerData.providerId]
                  ?.length > 0
                  ? false
                  : true
              }
              axelePermissions={axelePermissions}
            />
          </Grid>
        </Grid>
        {providerData?.integrationsDTO[currentCustomerData.providerId]?.length >
          0 && (
          <Stack sx={{ pt: '48px' }}>
            <AccountsSection
              openDrawer={toggleDrawer(true)}
              currentCustomerData={currentCustomerData}
              providerData={providerData}
              allTerminals={terminals}
              getProviderDetails={getProviderDetails}
              isDeletePopupOpen={isDeletePopupOpen}
              closeConfirmationPopup={closeConfirmationPopup}
              deactivateAccount={deactivateAccount}
              openConfirmationPopup={openConfirmationPopup}
              refreshAccountData={refreshAccountData}
              editAccount={editAccount}
              reauthenticate={reauthenticate}
              selectedCardForDeletion={selectedCardForDeletion}
              integrationType={'ELD'}
              clickedParamToManage={clickedParamToManage}
              resetFilters={resetFilters}
              resetRequestData={resetRequestData}
              axelePermissions={axelePermissions}
              componentToRenderInsideModal={
                <ManageEldIntegration
                  allDriverData={allDriverData}
                  allTractorData={allTractorData}
                  onManageTabChanged={onManageTabChanged}
                  selectedTab={selectedTab}
                  unlinkSelectedDriver={unlinkSelectedDriver}
                  linkDriver={linkDriver}
                  updateDriverList={updateDriverList}
                  linkTractor={linkTractor}
                  updateTractorList={updateTractorList}
                  unlinkSelectedTractor={unlinkSelectedTractor}
                  setCheckedTractors={setCheckedTractors}
                  checkedTractors={checkedTractors}
                  setCheckedDrivers={setCheckedDrivers}
                  checkedDrivers={checkedDrivers}
                  isMappedTractor={isMappedTractor}
                  setIsMappedTractor={setIsMappedTractor}
                  isMappedDriver={isMappedDriver}
                  setIsMappedDriver={setIsMappedDriver}
                  getDriverDetails={getDriverDetails}
                  cardData={cardData}
                  getTractorDetails={getTractorDetails}
                  tractorsFiltersFormConfig={tractorsFiltersFormConfig}
                  tractorDefaultFilterValue={tractorDefaultFilterValue}
                  handleOnChangeTractorCb={handleOnChangeTractorCb}
                  driverFiltersFormConfig={driverFiltersFormConfig}
                  driverDefaultFilterValue={driverDefaultFilterValue}
                  handleOnChangeDriverCb={handleOnChangeDriverCb}
                  handleTractorScroll={handleTractorScroll}
                  handleDriverScroll={handleDriverScroll}
                  totalDataInTable={totalDataInTable}
                  setDriverData={setDriverData}
                  setTractorData={setTractorData}
                  renderDriver={renderDriver}
                  setRenderDriver={setRenderDriver}
                  renderTractor={renderTractor}
                  setRenderTractor={setRenderTractor}
                  axelePermissions={axelePermissions}
                  getProviderDetails={getProviderDetails}
                  showTractorsTab={features.includes('TRACTOR_TRACKING')}
                />
              }
            />
          </Stack>
        )}
        <Stack sx={{ pt: '48px' }}>
          <CustomerFeatures features={features} />
        </Stack>
        <Sidedrawer
          currentCustomerData={currentCustomerData}
          open={open}
          toggleDrawer={toggleDrawer}
          providerData={providerData}
          validateFormCreds={validateFormCreds}
          dataToUpdate={dataToUpdate}
          activationErrMessage={activationErrMessage}
          updateAccountCard={updateAccountCard}
          formErr={formErr}
          authenticateOAuth={authenticateOAuth}
          integrationType={'ELD'}
        />
      </Stack>
    </Box>
  );
}
