import { Delete, SearchOutlined } from '@mui/icons-material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  Box,
  ButtonGroup,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Modal,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridApi, GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { customerService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { httpClient } from '../../../axios/axiosInstance';
import { DirtyDetailsPanelManager } from '../../../common/DetailsPanel/utils';
import { LaneItemsTable } from '../../../common/LaneItemsTable';
import FileUploadUpdated from '../../../common/Ui/FileUpload/indexUpdated';
import FormDatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';
import { SingleAutocompleteForm } from '../../../common/Ui/SingleAutocomplete/SingleAutocompleteForm';
import { useContractPermission } from '../../../common/hooks/useContractPermission';
import { ImportButton } from '../../../common/importButton';
import {
  LANE_INFO_COLUMN_CITY,
  LANE_INFO_COLUMN_MILEAGE,
  LANE_INFO_COLUMN_ZIP,
} from '../../../constants/contracts';
import { IMPORT_CONTRACT_RATE_LINE_ITEM_NAME } from '../../../contexts/ImportContext';
import {
  CreateCustomerRequest,
  CustomerSummaryDTO,
  FuelMatrix,
  PaginatedLoadListQueryParams,
} from '../../../models';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { useStores } from '../../../store/root.context';
import { createContractStyles } from '../../../styles';
import { getCustomerList } from '../../../subPages/loadsList/LoadDetailsPanel/utils/customer';
import AxeleAccordion from '../../../ui-kit/components/Accordion';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import { contractRequestFormatter } from '../../../utils';
import {
  getDateFromDateObject,
  prepareForLaneTableView,
  prepareForTableView,
} from '../../../utils/contract_payload_formatter';
import {
  ACCESSORIAL_REQUIRED_FIELDS,
  LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_CITY,
  LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_MILEAGE,
  LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_ZIP,
  LANE_GRID_DELETE_COL,
  MODIFIED_DATE_COLUMN,
  REQUIRED_FIELDS,
} from '../config';
import {
  ContractPanelType,
  LaneInfoEnum,
  LaneInfoPlaceholderEnum,
  LaneInfoType,
  PanelType,
} from '../types';
import {
  generateAccessorialItems,
  generateLaneItems,
  getCityStateInfo,
} from '../utils';
import {
  accessoriaValidator,
  laneItemValidator,
  tableErrStatus,
} from '../validators';

interface IContractForm {
  onClose: () => void;
  onSubmit: (
    values: any,
    attachedDocument?: Array<any>,
    oldDocumnet?: any
  ) => void;
  title?: string | null | undefined;
  isSaving?: boolean;
  setStatus?: any;
  contractData?: any;
  id?: string;
  formError?: { type: string; message: string };
  isCreate?: boolean;
  handleOnDelete?: (id?: string) => void | undefined;
  setTitle?: (value: string) => void;
  error?: any;
  errorIndex: any[];
  panelType?: PanelType;
  isDuplicate?: boolean;
  matrixData: FuelMatrix[];
}

const CreateFormContent = (props: IContractForm) => {
  const {
    control,
    getValues,
    reset,
    setValue,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: { customerName: { name: '' }, contractName: '' },
  });
  const { hasContractEditPermission, hasContractDeletePermission } =
    useContractPermission();
  const { contractStore } = useStores();
  const { financeDictionary, equipmentType } = contractStore;
  const [newCustomer, setNewCustomer] = useState({});
  const [laneInfoType, setLaneInfoType] = useState<LaneInfoType>(
    LaneInfoEnum.city
  );
  const [tmpLaneType, setTmpLaneType] = useState('');
  const [isSwitchDirty, setIsSwitchDirty] = useState(false);
  const [laneItemRows, setlaneItemRows] = useState([]);
  const [updatedLaneItemRows, setUpdatedLaneItemRows] = useState([]);
  const [accessorialRows, setAccessorialRows] = useState([]);
  const [laneSearchText, setLaneSearchText] = useState<string>('');
  const {
    onSubmit,
    isSaving,
    id,
    formError,
    isCreate = false,
    handleOnDelete,
    setTitle,
    setStatus,
    onClose,
    errorIndex,
    error,
    isContractDetails,
    panelType,
    onUpdate,
    isDuplicate,
    matrixData,
  } = props;
  const accesorialTableRef = useRef<GridApi>();
  const laneItemTableRef = useRef<GridApi>();
  const [loading, setLoading] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [uploadedDocument, setUploadedDocument] = useState([]);
  const [dateRangeError, setDateRangeErr] = useState('');
  const [accessorialItemsErrStatus, setAccessorialItemsErrStatus] =
    useState<boolean>(false);
  const laneInfoStaticData = useMemo(() => {
    return financeDictionary.filter(
      (item: any) => item?.itemType === 'BASE' && item
    );
  }, [financeDictionary]);
  const [isTableDirty, setIsTableDirty] = useState(false);
  const accessorialItemStaticData = useMemo(() => {
    return financeDictionary.filter(
      (item: any) => item?.itemType === 'ACCESSORIAL' && item
    );
  }, [financeDictionary]);

  const laneItemColumnHeaders: GridColDef[] = useMemo(() => {
    let colHeaders = [] as any;
    colHeaders = LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_CITY;
    if (laneInfoType === LaneInfoEnum.mileage)
      colHeaders = LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_MILEAGE;
    if (laneInfoType === LaneInfoEnum.zip)
      colHeaders = LANE_BASED_CREATE_CONTRACT_LANE_INFO_HEADER_ZIP;
    if (id)
      colHeaders = [
        ...colHeaders,
        ...MODIFIED_DATE_COLUMN,
        ...LANE_GRID_DELETE_COL,
      ];
    else colHeaders = [...colHeaders, ...LANE_GRID_DELETE_COL];
    colHeaders = generateLaneItems(
      colHeaders,
      laneInfoStaticData,
      equipmentType
    );
    return colHeaders;
  }, [equipmentType, id, laneInfoStaticData, laneInfoType]);

  const accessorialItemsHeaders = useMemo(() => {
    return generateAccessorialItems(accessorialItemStaticData, matrixData);
  }, [accessorialItemStaticData]);

  const createCustomer = async (text: string) => {
    const customerName = typeof text === 'string' ? text?.trim() : '';
    if (customerName) {
      const requestData: CreateCustomerRequest = new CreateCustomerRequest({
        name: customerName,
      });
      const response = await customerService.createCustomer(requestData);
      if (response instanceof CustomerSummaryDTO) {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'customerCreate',
        });
        setNewCustomer(response);
        setValue('customer', response);
        setValue('customerName', response);
      } else if (response instanceof ServiceError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'customerCreate',
        });
      }
    }
  };

  useEffect(() => {
    DirtyDetailsPanelManager.panelName =
      isDirty || isTableDirty ? 'Contract' : null;
    DirtyDetailsPanelManager.isDirty = isDirty || isTableDirty;
  }, [isDirty, isTableDirty]);

  useEffect(() => {
    if (error && error.message) {
      const laneRowModel: GridValidRowModel =
        laneItemTableRef?.current?.getRowModels();
      const entries = Array.from(laneRowModel?.values());
      entries.forEach((row, index) => {
        const keys = Object.keys(row);
        keys.forEach((key) => {
          if (REQUIRED_FIELDS.includes(key) && errorIndex?.includes(index)) {
            laneItemTableRef.current?.updateRows([
              {
                id: row.id,
                [key]: {
                  isInvalid: true,
                  message: error?.message,
                  value: row[key]?.value,
                },
              },
            ]);
          }
        });
      });
      laneItemTableRef.current?.forceUpdate();
    }
  }, [error, errorIndex]);
  const handleOnSubimt = async (data) => {
    try {
      const laneRowModel: GridValidRowModel =
        laneItemTableRef?.current?.getRowModels();
      const accRowModel = accesorialTableRef?.current?.getRowModels();
      let hasErr;
      await laneRowModel.forEach((row) => {
        const keys = Object.keys(row);
        keys.forEach((key, index) => {
          if (REQUIRED_FIELDS.includes(key)) {
            const errMsg = laneItemValidator(key, row[key]?.value);
            if (errMsg) {
              laneItemTableRef.current?.updateRows([
                {
                  id: row.id,
                  ...row,
                  [key]: {
                    isInvalid: true,
                    message: errMsg,
                    value: row[key]?.value,
                  },
                },
              ]);
              hasErr = true;
            }
          }
          if (row[key]?.isInvalid) hasErr = true;
        });
      });
      await accRowModel.forEach((row) => {
        const keys = Object.keys(row);
        keys.forEach((key) => {
          if (ACCESSORIAL_REQUIRED_FIELDS.includes(key)) {
            const currentRow = accesorialTableRef.current?.getRow(row.id);
            let errMsg = accessoriaValidator(key, row[key]?.value, currentRow);
            if (
              key === 'fuelSurchargeName' &&
              !['PRICE_PER_MILE_MATRIX', 'PERCENT_MATRIX'].includes(
                currentRow.unit.value
              )
            ) {
              errMsg = '';
            }
            if (
              key === 'rate' &&
              ['PRICE_PER_MILE_MATRIX', 'PERCENT_MATRIX'].includes(
                currentRow.unit.value
              )
            ) {
              errMsg = '';
            }
            if (errMsg) {
              accesorialTableRef.current?.updateRows([
                {
                  id: row.id,
                  [key]: {
                    isInvalid: true,
                    message: errMsg,
                    value: row[key]?.value,
                  },
                },
              ]);
              hasErr = true;
            }
          }
          if (row[key]?.isInvalid) hasErr = true;
        });
      });
      accesorialTableRef.current?.forceUpdate();
      laneItemTableRef.current?.forceUpdate();
      if (hasErr) {
        return;
      }
      const accessorialStatus = await tableErrStatus(accessorialRows);
      const laneItemStatus = await tableErrStatus(laneItemRows);
      setAccessorialItemsErrStatus(accessorialStatus?.hasErrCells);
      if (accessorialStatus || laneItemStatus) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: 'Please validate and try again.',
        });
        return;
      }
      const updatedIds = new Set(updatedLaneItemRows?.map((item) => item.id));

      const notInUpdated = updatedLaneItemRows?.length
        ? laneItemRows?.filter((item) => !updatedIds.has(item.id))
        : [];
      const laneItems = contractRequestFormatter.createLaneItems(
        laneRowModel,
        laneInfoType,
        notInUpdated
      );
      const accessorialItems = contractRequestFormatter.createAccessorialItems(
        accRowModel,
        accessorialItemStaticData
      );
      const attachedDocument = data?.assignedDocuments;
      if (!data?.customerName?.id) {
        data.customerName = {
          id: contractData?.customerId,
          name: contractData?.customerName,
        };
      }
      let requestObj: any =
        contractRequestFormatter.getCreateContractRequestObject(
          data,
          laneInfoType,
          laneItems,
          accessorialItems
        );
      if (id && contractData?.createdDate) {
        requestObj = { ...requestObj, createdDate: contractData.createdDate };
      }
      const saveStatus = await onSubmit(
        requestObj,
        attachedDocument,
        uploadedDocument
      );
      if (saveStatus) {
        reset();
        setContractData(null);
      }
      setIsTableDirty(false);
      setLaneSearchText('');
      await onUpdate?.({ id: contractData?.id });
    } catch (e) {
      console.log(e);
    }
  };

  const setFormValues = useCallback(() => {
    setValue('contractName', contractData?.name);
    setValue('customerName', { name: contractData?.customerName || '' });
    setValue('endDate', contractData?.expDate);
    setValue('startDate', contractData?.effectiveDate);
    setValue('description', contractData?.notes);
    setValue('assignedDocuments', contractData?.assignedDocuments);
    setValue('notes', contractData?.notes || '');
  }, [contractData, setValue]);

  const setLaneAndAccessorialData = async (response) => {
    if (response) {
      const laneRateItems = response?.laneRateItems || [];
      const rateMethod = response?.rateMethod;
      const accessorialItems = response?.accessorialItems || [];

      const formattedLaneRate = prepareForLaneTableView(
        laneRateItems,
        rateMethod
      );
      setlaneItemRows(formattedLaneRate);
      const formatedAccessorial = await prepareForTableView(
        accessorialItems,
        accessorialItemStaticData
      );
      setAccessorialRows(formatedAccessorial);
      setLaneInfoType(rateMethod);
    }
  };
  useEffect(() => {
    const fetchContractData = async () => {
      setLoading(true);
      try {
        const queryParams = new PaginatedLoadListQueryParams();
        const response: any = await httpClient.get(
          `/web/customer/api/v2/contracts/get?id=${id}`,
          queryParams,
          false
        );
        setLaneAndAccessorialData(response);
        const assignedDocuments = response?.assignedDocuments;
        if (id !== contractData?.id) {
          setContractData(response);
        }
        if (setTitle) {
          setTitle(contractData?.name);
          setStatus(contractData?.status);
        }
        if (assignedDocuments && assignedDocuments?.length && !isDuplicate) {
          setUploadedDocument(response?.assignedDocuments[0]);
        }

        setFormValues();
        setLoading(false);
      } catch (e) {
        RootStoreInstence.setNotificationType({
          type: 'FALIURE',
          message: 'Failed to fetch record',
        });
        setLoading(false);
      }
    };
    if (id) {
      fetchContractData();
    } else if (props?.contractData) {
      setContractData(props?.contractData);
      setValue('customerName', { name: props?.contractData?.name || '' });
      setFormValues();
    }
  }, [JSON.stringify(contractData), id]);

  const handleSwitchLaneType = (laneType) => {
    if (laneItemRows.length) {
      setIsSwitchDirty(true);
      setTmpLaneType(laneType);
      return;
    }
    setLaneInfoType(laneType);
  };

  const uploadType = useMemo(() => {
    if (laneInfoType === LaneInfoEnum.city) {
      return 'City Based';
    }
    if (laneInfoType === LaneInfoEnum.zip) {
      return 'Zip Based';
    }
    if (laneInfoType === LaneInfoEnum.mileage) {
      return 'Mileage Based';
    }
  }, [laneInfoType]);

  const handleLaneSearch = (text: string) => {
    text = text.toLocaleLowerCase();
    setLaneSearchText(text);
    const _updatedLaneInfo = cloneDeep([...laneItemRows]);
    const accRowModel = accesorialTableRef?.current?.getRowModels();
    if (text) {
      if (laneInfoType === LaneInfoEnum.city) {
        const result = _updatedLaneInfo.filter((obj) =>
          Object.entries(obj).some(([key, objData]) => {
            const val = objData?.value;
            if (val && LANE_INFO_COLUMN_CITY.includes(key)) {
              if (val?.city || val?.state) {
                const data = getCityStateInfo(val);
                return data?.toLowerCase()?.includes(text);
              } else {
                return val && val?.toString()?.toLowerCase()?.includes(text);
              }
            }
          })
        );
        setUpdatedLaneItemRows([...result]);
      } else if (laneInfoType === LaneInfoEnum.zip) {
        const result = _updatedLaneInfo.filter((obj) =>
          Object.entries(obj).some(([key, objData]) => {
            const val = objData?.value;
            if (val && LANE_INFO_COLUMN_ZIP.includes(key)) {
              if (
                val?.zipStartOrigin ||
                val?.zipEndOrigin ||
                val?.destinationStartOrigin ||
                val?.destinationEndOrigin
              ) {
                const value =
                  val?.zipStartOrigin ||
                  val?.zipEndOrigin ||
                  val?.destinationStartOrigin ||
                  val?.destinationEndOrigin;
                return value?.zipcode?.toLowerCase()?.includes(text);
              } else {
                return val && val?.toString()?.toLowerCase()?.includes(text);
              }
            }
          })
        );
        setUpdatedLaneItemRows([...result]);
      } else if (laneInfoType === LaneInfoEnum.mileage) {
        const result = _updatedLaneInfo.filter((obj) =>
          Object.entries(obj).some(([key, objData]) => {
            const val = objData?.value;
            if (val && LANE_INFO_COLUMN_MILEAGE.includes(key)) {
              if (val?.city || val?.state) {
                const data = getCityStateInfo(val);
                return data?.toLowerCase()?.includes(text);
              } else {
                return val && val?.toString()?.toLowerCase()?.includes(text);
              }
            }
          })
        );
        setUpdatedLaneItemRows([...result]);
      }
    } else {
      setUpdatedLaneItemRows([...updatedLaneItemRows]);
    }
  };

  const handleImportLaneItems = (data) => {
    if (data?.laneRateItems) {
      const formattedLaneRate = prepareForLaneTableView(
        data.laneRateItems,
        laneInfoType
      );
      setlaneItemRows(formattedLaneRate);
    }
  };

  const effectiveDate = watch('startDate', null);
  const expiryDate = watch('endDate', null);
  const notes = (
    <Controller
      rules={{
        maxLength: 1024,
      }}
      name="description"
      defaultValue={''}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          sx={{ width: '100%' }}
          id="standard-basic"
          inputProps={{ sx: { fontSize: '12px' } }}
          label="Notes"
          name={'description'}
          variant="standard"
          error={Boolean(errors.description)}
          multiline
          placeholder="Description Text"
          size="small"
          focused={true}
          disabled={!hasContractEditPermission}
        />
      )}
    />
  );
  const fileUploader = (
    <FileUploadUpdated
      name={`assignedDocuments`}
      label="Upload"
      fileId={(uploadedDocument && uploadedDocument?.documentId) || ''}
      control={control}
      disabled={false}
      uploadBtnStyle={{
        width: '100%',
        margin: 0,
        marginTop: '10px',
        height: '40px',
        borderRadius: '14px',
      }}
      uploadBtnText={
        <>
          <PostAddIcon sx={{ paddingRight: '2px' }} />
          Add Document
        </>
      }
    />
  );
  const expiryDateStatus = expiryDate && expiryDate?.diff?.(effectiveDate) < 0;
  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={createContractStyles.container}>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', pb: 5 }}>
          <AxeleAccordion
            expanded={true}
            borderColor="transparent"
            titleRenderer={() => (
              <Typography variant="subtitle1" fontSize={'16px'}>
                General Details
              </Typography>
            )}
            subTitleRenderer={() => <></>}
          >
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1}>
                  {loading ? (
                    <>
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                    </>
                  ) : (
                    <>
                      <Box sx={{ width: '50%' }}>
                        <Controller
                          rules={{
                            required: true,
                            maxLength: 255,
                          }}
                          control={control}
                          name="contractName"
                          render={({ field }) => (
                            <TextField
                              sx={{ width: '100%' }}
                              {...field}
                              id="standard-basic"
                              label="Contract Name"
                              variant="standard"
                              size="small"
                              required
                              disabled={!hasContractEditPermission && !isCreate}
                              error={
                                Boolean(errors?.contractName) ||
                                Boolean(
                                  formError?.type === 'NON_UNIQUE_NAME'
                                ) ||
                                Boolean(
                                  errors?.contractName?.type === 'maxLength'
                                )
                              }
                              helperText={
                                (Boolean(
                                  errors?.contractName?.type === 'maxLength'
                                ) &&
                                  'Max 255 characters allowed!') ||
                                (Boolean(errors?.contractName) &&
                                  'Contract name is mandatory') ||
                                (Boolean(
                                  formError?.type === 'NON_UNIQUE_NAME'
                                ) &&
                                  formError?.message)
                              }
                            />
                          )}
                        />
                      </Box>
                      <Box sx={{ width: '50%' }}>
                        <FormDatePicker
                          size="small"
                          styleProps={{ width: '100%' }}
                          name="startDate"
                          rules={{
                            required: true,
                          }}
                          disabled={!hasContractEditPermission}
                          label="Effective Date"
                          required
                          control={control}
                          helperText="Please select an effective date"
                        />
                      </Box>
                    </>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1}>
                  {loading ? (
                    <>
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                    </>
                  ) : (
                    <>
                      <Box sx={{ width: '50%' }}>
                        <SingleAutocompleteForm
                          fieldName="name"
                          id={'CustomerName'}
                          control={control}
                          name={'customerName'}
                          label="Customer Name"
                          disabled={
                            (contractData?.customerName &&
                              !isContractDetails &&
                              !isDuplicate) ||
                            !hasContractEditPermission
                          }
                          newOption={newCustomer}
                          freeSolo
                          addNewOption={createCustomer}
                          getOptions={(name, pageNumber) => {
                            return getCustomerList(name, pageNumber);
                          }}
                        />
                      </Box>
                      <Box sx={{ width: '50%' }}>
                        <FormDatePicker
                          size="small"
                          error={expiryDateStatus}
                          helperText={
                            expiryDateStatus &&
                            'Expiry date cannot be lesser than effective date'
                          }
                          control={control}
                          minDate={
                            effectiveDate
                              ? effectiveDate?.startOf?.('days')?.toDate()
                              : null
                          }
                          styleProps={{ width: '100%' }}
                          name={`endDate`}
                          label="Expiration Date"
                          disabled={
                            !hasContractEditPermission ||
                            !getValues('startDate')
                          }
                        />
                      </Box>
                    </>
                  )}
                </Stack>
              </Grid>
              {id && !isDuplicate ? (
                <>
                  <Grid item xs={6}>
                    {loading ? (
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                    ) : (
                      <TextField
                        id="standard-basic"
                        label="Creation Date"
                        sx={{ input: { color: '#2B64CB' } }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        value={
                          contractData?.createdDate
                            ? getDateFromDateObject(contractData?.createdDate)
                            : '-'
                        }
                        variant="standard"
                        size="small"
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {loading ? (
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                    ) : (
                      <>{notes}</>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {loading ? (
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                    ) : (
                      fileUploader
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  {loading ? (
                    <>
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                      <Skeleton variant="text" width={'100%'} height="3rem" />
                    </>
                  ) : (
                    <>
                      {notes}
                      {fileUploader}
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </AxeleAccordion>
          <Divider />
          <Grid container padding={0} margin={0}>
            <Grid item xs={12}>
              <Stack direction={'column'} sx={{ pl: 2, pr: 2, pb: 2 }}>
                {isCreate && (
                  <ButtonGroup size="small" sx={{ width: '100%' }}>
                    <ButtonImproved
                      styleProps={{
                        flex: 1,
                        backgroundColor:
                          laneInfoType === LaneInfoEnum.city
                            ? '#2B63CB36'
                            : 'transparent',
                      }}
                      label="City Based"
                      onClick={() => handleSwitchLaneType(LaneInfoEnum.city)}
                    />
                    <ButtonImproved
                      styleProps={{
                        flex: 1,
                        backgroundColor:
                          laneInfoType === LaneInfoEnum.zip
                            ? '#2B63CB36'
                            : 'transparent',
                      }}
                      label="Zip Code Based"
                      onClick={() => handleSwitchLaneType(LaneInfoEnum.zip)}
                    />
                    <ButtonImproved
                      styleProps={{
                        flex: 1,
                        backgroundColor:
                          laneInfoType === LaneInfoEnum.mileage
                            ? '#2B63CB36'
                            : 'transparent',
                      }}
                      label="Mileage Based"
                      onClick={() => handleSwitchLaneType(LaneInfoEnum.mileage)}
                    />
                  </ButtonGroup>
                )}
                <Stack direction="row" mt={1} alignItems={'center'}>
                  <Typography
                    variant="subtitle2"
                    color={'primary'}
                    fontWeight={600}
                  >
                    {`${
                      laneInfoType === LaneInfoEnum.mileage ? 'Mileage' : 'Lane'
                    } Information`}
                  </Typography>
                  <Box flex={1}></Box>
                  <ImportButton
                    defaultUploadType={uploadType}
                    finishImportCb={(data) => handleImportLaneItems(data)}
                    entity={IMPORT_CONTRACT_RATE_LINE_ITEM_NAME}
                    buttonLabel={'Import'}
                  />
                </Stack>
                {id && (
                  <TextField
                    id="standard-select-currency-native"
                    sx={{
                      width: '40%',
                      border: '1px solid #2B64CB !important',
                      borderRadius: '6px',
                      fontSize: '13px',
                      '.MuiOutlinedInput-input': {
                        height: '10px !important',
                      },
                    }}
                    placeholder={`Search ${
                      laneInfoType in LaneInfoPlaceholderEnum
                        ? LaneInfoPlaceholderEnum[laneInfoType]
                        : ''
                    }`}
                    InputProps={{
                      startAdornment: (
                        <SearchOutlined fontSize="small" color="primary" />
                      ),
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleLaneSearch(event.target.value);
                    }}
                    size="small"
                    variant="outlined"
                  />
                )}
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height="5rem"
                    width="100%"
                    sx={{ borderRadius: '5px', mt: 1 }}
                  />
                ) : (
                  <LaneItemsTable
                    validator={laneItemValidator}
                    staticData={laneInfoStaticData}
                    itemType="Rate"
                    columns={laneItemColumnHeaders}
                    rows={laneSearchText ? updatedLaneItemRows : laneItemRows}
                    laneInfoType={laneInfoType}
                    setRows={
                      laneSearchText ? setUpdatedLaneItemRows : setlaneItemRows
                    }
                    tableRef={laneItemTableRef}
                    isTableDirty={isTableDirty}
                    setIsTableDirty={setIsTableDirty}
                  />
                )}

                <Stack direction="row" mt={1} alignItems={'center'}>
                  <Typography
                    variant="subtitle2"
                    color={'primary'}
                    fontWeight={600}
                  >
                    Accessorial
                  </Typography>
                </Stack>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height="5rem"
                    width="100%"
                    sx={{ borderRadius: '5px', mt: 1 }}
                  />
                ) : (
                  <LaneItemsTable
                    hasErr={accessorialItemsErrStatus}
                    isAccessorialTable={true}
                    tableRef={accesorialTableRef}
                    validator={accessoriaValidator}
                    staticData={accessorialItemStaticData}
                    itemType="Accesorial Item"
                    columns={accessorialItemsHeaders}
                    setRows={setAccessorialRows}
                    rows={accessorialRows}
                    isTableDirty={isTableDirty}
                    setIsTableDirty={setIsTableDirty}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Stack>

        {Boolean(isSwitchDirty) && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isSwitchDirty}
            onClose={() => setIsSwitchDirty(false)}
            closeAfterTransition
          >
            <Fade in={isSwitchDirty}>
              <Box
                sx={{
                  width: '30rem',
                  height: 'auto',
                  backgroundColor: '#ffffff',
                  padding: 3,
                  borderRadius: 5,
                  display: 'block',
                  ml: 'auto',
                  mr: 'auto',
                  marginTop: '30%',
                  transform: 'translateY(-50%)',
                }}
              >
                <Stack direction={'column'} spacing={2}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you sure?
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    Changing the rate method will delete all line items from
                    this contract. Do you want to continue?
                  </Typography>
                  <Stack direction={'row'} spacing={1}>
                    <Box flexGrow={1} />
                    <ButtonImproved
                      sx={{ width: '6rem' }}
                      variant="outlined"
                      label="Cancel"
                      onClick={() => setIsSwitchDirty(false)}
                      startIcon={
                        isSaving ? (
                          <CircularProgress size={14} color="primary" />
                        ) : null
                      }
                    />
                    <ButtonImproved
                      sx={{ width: '6rem' }}
                      variant="contained"
                      label="Continue"
                      onClick={() => {
                        setLaneInfoType(tmpLaneType);
                        setlaneItemRows([]);
                        setIsSwitchDirty(false);
                      }}
                      startIcon={
                        isSaving ? <CircularProgress size={4} /> : null
                      }
                    />
                  </Stack>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        )}
      </Box>
      {(isDirty ||
        isTableDirty ||
        isDuplicate ||
        panelType === ContractPanelType.customer) && (
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: '#ffffff',
            bottom: 0,
            width: '100%',
            pt: 1,
            pr: 1,
            borderRadius: '0px 0px 16px 16px',
          }}
        >
          <Stack
            sx={{
              pl: 2,
              pr: 2,
              pb: 1,
            }}
            spacing={1}
            width={'100%'}
            direction={'row'}
          >
            {handleOnDelete && hasContractDeletePermission && (
              <ButtonImproved
                color="error"
                label="Delete"
                startIcon={<Delete />}
                onClick={() => handleOnDelete()}
              />
            )}
            <Box flexGrow={1}></Box>
            <ButtonImproved
              styleProps={{ pl: 5, pr: 5 }}
              variant="outlined"
              label="Cancel"
              onClick={() => {
                if (isCreate) {
                  onClose();
                  return;
                }
                if (isDirty || isTableDirty) {
                  if (contractData) {
                    reset({
                      customerName: contractData?.customerName || '',
                      contractName: contractData?.name,
                      endDate: contractData?.expDate,
                      startDate: contractData?.effectiveDate,
                      description: contractData?.notes,
                      assignedDocuments: contractData?.assignedDocuments,
                    });
                    setIsTableDirty(false);
                    setLaneAndAccessorialData(contractData);
                    return;
                  }
                  reset();
                  return;
                }
                reset();
                return;
              }}
            />
            <ButtonImproved
              styleProps={{ pl: 5, pr: 5 }}
              variant="contained"
              label={id && !isDuplicate ? 'Save Changes' : 'Add Contract'}
              onClick={handleSubmit(handleOnSubimt)}
              disabled={
                isSaving ||
                expiryDateStatus ||
                (!(isDirty || isTableDirty) && !isDuplicate) ||
                (!isCreate && !hasContractEditPermission)
              }
              startIcon={isSaving && <CircularProgress size={'small'} />}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default observer(CreateFormContent);
