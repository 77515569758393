import { TagCreateResponseDTO } from '@/models';
import { Type } from 'class-transformer';
import { TerminalShort } from '../../../../models/common/modelsShort';
import { LoadPriorityEnum } from '../../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import {
  Commodity,
  EquipmentType,
} from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
class InvoiceTerminal {
  id!: string;
  companyName!: string;
  primary!: boolean;
}
export class Trip {
  id!: string;
  seqNumber!: string;
  dispatcher!: any;
  driverGroup!: any;
  tractorDetails!: any;
  trailerDetails!: any;
  terminal!: TerminalShort | null;
}

export class LoadManifest {
  customerName!: string;
  dropoffCityState!: string;
  id!: string;
  loadStatus!: string;
  pickupCityState!: string;
  refNumber!: string;
  seqNumber!: string;
  loadPriority?: LoadPriorityEnum;
  tags!: Array<any>;
}

export class LoadMetaDataDetails {
  mode?: string;
  serviceType?: string;
  steamShipLine?: string;
  yardLocation?: string;
  containerTerminal?: string;
  holdCategory?: string;
  holdType?: string;
  perdiemStartDate?: string;
  fullOutgateDate?: string;
  lastFreeDay?: string;
  inPin?: string;
  outPin?: string;
  chassisProvider?: string;
  containerSize?: string;
  erd?: string;
}
export class LoadDetails {
  referenceId!: string;
  @Type(() => EquipmentType)
  trailerTypes?: EquipmentType[] | null;
  @Type(() => Commodity)
  commodityData?: Commodity[] | null;
  totalWeight!: number;
  useFactoring!: boolean;
  notes!: string;
  factoringId!: string;
  factoringStatus?: string;
  factoringDocumentStatus!: string;
  noteDetails!: string;
  factoringInvoiceUpdateDate!: string;
  factoringChargePercent?: number;
  canBeBrokered!: boolean;
  loadPriority?: LoadPriorityEnum;
  equipmentAdditionalDetails?: {
    sealNumber: string;
    containerNumber: string;
    chassisNumber: string;
    bookingNumber?: string;
    reeferMode?: string;
    appointmentNumber?: string;
    loadDirection?: string;
    lowerLimitTemperature: string;
    upperLimitTemperature: string;
    temperature?: number;
    variance?: number;
  };
  metaDataDetails!: LoadMetaDataDetails;
}

class Customer {
  id!: number;
  name!: string;
  factoringId!: string | null;
  factoringName!: string;
  primaryContact!: {
    id: number;
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
    countryCode: string;
  };
}

export class LoadOverviewDetails {
  id: string;
  seqNumber: string;
  referenceId: string | null;
  emptyMiles: number | null;
  loadedMiles: number | null;
  revenue: number | null;
  dispatchRevenue: number | null;
  tripMiles: number | null;
  pickupCity: string;
  pickupState: string;
  dropOffCity: string;
  dropOffState: string;
  customer: Customer | null;
  factoringDetails: { factoringName: string } | null;
  invoiceTerminal: InvoiceTerminal;
  @Type(() => Trip)
  trips: Trip[] | [];
  loadStatus: string;
  equipmentTypes: string;
  bolNumber: string;
  purchaseOrderNumber: string;
  loads: LoadManifest;
  loadPriority?: LoadPriorityEnum;
  canBeBrokered?: boolean;
  tags?: Array<TagCreateResponseDTO> | null;
  detentionTime: number | null;
  onHold: boolean | null;
  hashKey: string;
  loadType: string;
  signatureCollectionEnabled: string;
  loadDetails: LoadDetails;

  constructor(data: any) {
    const {
      id,
      seqNumber,
      referenceId,
      emptyMiles,
      loadedMiles,
      revenue,
      dispatchRevenue,
      pickupCity,
      pickupState,
      dropOffCity,
      dropOffState,
      customer,
      invoiceTerminal,
      trips,
      loadStatus,
      factoringDetails,
      loads,
      equipmentTypes,
      tripMiles,
      loadPriority,
      canBeBrokered,
      tags,
      purchaseOrderNumber,
      bolNumber,
      detentionTime,
      onHold,
      hashKey,
      loadType,
      signatureCollectionEnabled,
      loadDetails,
    } = data;

    this.id = id;
    this.seqNumber = seqNumber;
    this.referenceId = referenceId;
    this.emptyMiles = emptyMiles;
    this.loadedMiles = loadedMiles;
    this.revenue = revenue;
    this.dispatchRevenue = dispatchRevenue;
    this.pickupCity = pickupCity;
    this.pickupState = pickupState;
    this.dropOffCity = dropOffCity;
    this.dropOffState = dropOffState;
    this.customer = customer;
    this.invoiceTerminal = invoiceTerminal;
    this.factoringDetails = factoringDetails;
    this.trips = trips;
    this.loadStatus = loadStatus;
    this.loads = loads;
    this.equipmentTypes = equipmentTypes;
    this.tripMiles = tripMiles;
    this.loadPriority = loadPriority;
    this.canBeBrokered = canBeBrokered;
    this.bolNumber = bolNumber;
    this.purchaseOrderNumber = purchaseOrderNumber;
    this.tags = tags || [];
    this.detentionTime = detentionTime;
    this.onHold = onHold;
    this.hashKey = hashKey;
    this.loadType = loadType;
    this.signatureCollectionEnabled = signatureCollectionEnabled;
    this.loadDetails = loadDetails;
    // this.metaDataDetails = metaDataDetails;
  }
}
