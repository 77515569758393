import { statesinCountry } from '../../../common/Ui/AddressField/constants/states';
import { StaticDataType } from '../../../contexts/StaticDataContext';
import { calculatePaymentsTotalAmount } from '../../../subPages/payStatement/utils/helpers';
import { FileType, PaginatedList, objectType } from '../../../types';
import {
  ExpenseEntityType,
  LoadAppointmentDetailsDTO,
} from '../Expenses/Expenses';

export class PayStatementListDataCount {
  pendingCount!: number | null;
  inReviewCount!: number | null;
}

export class PayStatementData {
  id: number;
  payStatementType: PayStatementsTypeEnum;
  payStatementId: number;
  payStatementName?: string;
  amount: number;
  deductionAmount?: number;
  reimbursementAmount?: number;
  tripIncomeAmount?: number;
  terminal?: objectType;
  status?: string;
  startDate?: string;
  endDate?: string;
  createDate?: string;
  seqNumber?: number;
  email?: string;
  entity: PayStatementsEntityTypeEnum;
  paymentDate?: Date | string;
  toStatus?: string;
  constructor(data?: any) {
    this.id = data?.id;
    this.amount = data?.amount || 0;
    this.deductionAmount = data?.deductionAmount || 0;
    this.reimbursementAmount = data?.reimbursementAmount || 0;
    this.tripIncomeAmount = data?.tripIncomeAmount || 0;
    this.seqNumber = data?.seqNumber;
    this.terminal = data?.terminal;
    this.status = data?.status;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.createDate = data?.createDate;
    this.payStatementId = data?.entityId;
    this.payStatementType = data?.entity;
    this.payStatementName = data?.entityName;
    this.email = data?.email;
    this.entity = PayStatementsEntityTypeMap[data?.status];
    this.paymentDate = data?.paymentDate;
  }
}
export class ReopenedPayStatement {
  id: number;
  entity?: string;
  entityId?: number;
  entityName?: string;
  amount?: number;
  terminal?: objectType;
  status?: string;
  startDate?: string;
  endDate?: string;
  seqNumber?: number;
  entityType?: string;
  organizationId?: number;
  payItemIds?: Array<any>;
  paymentDate?: string | Date;
  constructor(data: any) {
    this.id = data.id;
    this.amount = data.amount;
    this.terminal = data.terminal;
    this.status = data.status;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.seqNumber = data.seqNumber;
    this.entityId = data?.entityId;
    this.entityName = data?.entityName;
    this.entityType = data?.entityType;
    this.entity = data?.entityType;
    this.organizationId = data?.organizationId;
    this.payItemIds = data?.payItemIds;
    this.paymentDate = data?.paymentDate;
  }
}

export type PayStatementType = {
  id: number;
  entity: string;
  entityId: number;
  entityName?: string;
  amount?: number;
  terminal?: objectType;
  status?: string;
  startDate?: string;
  endDate?: string;
  seqNumber?: number;
  email?: string;
  toStatus?: string;
  paymentDate?: Date;
  entityType?: string;
  payToEntityId?: number;
  payToEntityType?: string;
};

export type PayStatementDatesType = {
  startDate?: string;
  endDate?: string;
};

export type ConnectedLoadType = {
  id: string;
  loadStatus: string;
  seqNumber: string;
  terminalId: string;
  revenue: number;
  referenceId: string | null;
  customerId: number;
  customerName: string;
  tags: Array<any> | null;
};

export interface PayStatementTerminal {
  id: string;
  companyName: string;
}

export class PaginatedPayStatementList extends PaginatedList {
  content!: PayStatementType[];
  totalTripIncomeAmount!: number;
  totalReimbursementAmount!: number;
  totalDeductionAmount!: number;
  totalPaymentAmount!: number;
  totalAmount!: number;
  totalNonTaxableAmount!: number;
  nonTaxableAmount!: number;
}

export type PayStatementTotalsType = {
  totalTripIncomeAmount: number;
  totalReimbursementAmount: number;
  totalDeductionAmount: number;
  totalPaymentAmount: number;
  totalAmount: number;
  totalNonTaxableAmount: number;
};

export class CombinedNames {
  entity: string;
  entityId: number;
  entityName: string;
  entityNameToShow?: string;
  constructor(data: any) {
    this.entity = data.entity;
    this.entityId = data.entityId;
    this.entityName = data.entityName;
    this.entityNameToShow = `${data.entityName} (${data.entity})`;
  }
}

export class PaginatedCombinedNamesList extends PaginatedList {
  content?: CombinedNames[];
}

export class PayItemDTO {
  id?: number;
  organizationId?: number;
  entityType?: ExpenseEntityType;
  entityId?: number | null;
  settlementId?: number;
  entityName?: string;
  payDate?: Date;
  payCategory?: string;
  expenseCategoryKey?: string;
  expenseCategoryId?: string;
  payCategoryName?: string;
  description?: string;
  amount?: number;
  loadId?: string;
  isManuallyAdded?: boolean;
  fuelType?: string;
  fuelQuantity?: number;
  expenseId?: string;
  expenseSeqNumber?: number;
  truckStop?: string;
  state?: objectType | null | string;
  tripDetails?: LoadAppointmentDetailsDTO;
  assignedDocuments: FileList;
  settlementSeqNumber?: number;
  settlementStatus?: string;
  payingEntityObj?: { id?: number; name?: string };
  payToEntityId?: number;
  payToEntityName?: string;
  payToEntityType?: string;
  constructor(data: any, organizationId?: number) {
    this.amount =
      data?.amount && data.payCategory === 'DEDUCTION'
        ? -Math.abs(data?.amount)
        : data?.amount;
    if (data?.isAmountRequiredInAbs) this.amount = Math.abs(this.amount!);
    this.description = data.description;
    this.expenseCategoryId = data.expenseCategoryId;
    this.expenseCategoryKey = data.expenseCategoryKey;
    this.expenseId = data.expenseId;
    this.expenseSeqNumber = data.expenseSeqNumber;
    this.fuelQuantity = data.fuelQuantity;
    this.fuelType = data.fuelType;
    this.loadId = data.loadId;
    this.payCategory = data.payCategory;
    this.payCategoryName = data.payCategoryName;
    this.payDate = new Date(data.payDate);
    this.settlementId = data.settlementId;
    this.settlementSeqNumber = data.settlementSeqNumber;
    this.state = data.state;
    this.truckStop = data.truckStop;
    this.entityId = data?.entityId ?? (data?.payingEntityObj?.id as number);
    this.entityType = data?.entityType ?? data?.paymentType;
    this.entityName = data?.entityName;
    this.isManuallyAdded = data?.isManuallyAdded ?? true;
    this.organizationId = organizationId;
    this.tripDetails = data?.tripDetails;
    this.assignedDocuments = data?.assignedDocuments;
    this.payingEntityObj = { id: data?.entityId, name: data?.entityName };
    this.settlementStatus = data?.settlementStatus;
    this.id = data?.id;
    this.payToEntityId = data?.payToEntityId;
    this.payToEntityName = data?.payToEntityName;
    this.payToEntityType = data?.payToEntityType;
  }
}

export class CreatePayItemDTO extends PayItemDTO {
  payingEntityObj?: { id?: number; name?: string };
  constructor(data: any, organizationId?: number) {
    if (data) {
      data.entityId = data?.payingEntityObj?.id;
      data.entityName = data?.payingEntityObj?.name;
    }
    super(data, organizationId);
    this.payingEntityObj = undefined;
    this.settlementStatus = undefined;
  }
}

export class UpdatePayItemDTO extends PayItemDTO {
  payingEntityObj?: { id?: number; name?: string };
  constructor(data: any, organizationId?: number) {
    if (data) {
      data.entityId = data?.payingEntityObj?.id;
      data.entityName = data?.payingEntityObj?.name;
    }
    super(data, organizationId);
    this.payingEntityObj = undefined;
  }
}

export class PaymentDetails extends PayItemDTO {
  id: number;
  amount: number;
  amountToShow?: number;
  driverId: number;
  driverName: string;
  payDate: Date;
  dropoffTimeZone: string;
  expenseId: string;
  expenseSeqNumber: number;
  fuelQuantity: number;
  payCategory: string;
  payCategoryType?: string;
  payCategoryName: string;
  settlementId: number;
  settlementSeqNumber?: number;
  truckStop?: string;
  description?: string;
  assignedDocuments: FileList;
  expenseCategoryKey?: string;
  state?: objectType | null | string;
  expenseCategoryObj?: objectType | null;
  fuelTypeObj?: objectType | null;
  isChecked?: boolean;
  loadId?: string;
  connectedLoads?: ConnectedLoadType[];
  entityId?: number | null;
  entityType?: ExpenseEntityType;
  entityName?: string;
  expenseCategoryId?: string;
  fuelType?: string;
  isManuallyAdded?: boolean;
  organizationId?: number;
  settlementStatus?: string;
  tripDetails?: LoadAppointmentDetailsDTO;
  payToEntityId?: number;
  payToEntityName?: string;
  payToEntityType?: ExpenseEntityType;
  tags?: Array<any> | null;
  payToDetails?: {
    entityId?: number;
    entityName?: string;
    entity?: string;
  } | null;
  constructor(data: any, staticData: StaticDataType, isChecked = false) {
    super(data);
    this.id = data.id;
    this.amount = data.amount;
    this.amountToShow = Math.abs(data.amount) || undefined;
    this.description = data.description;
    this.driverId = data.driverId;
    this.driverName = data.driverName;
    this.payDate = data.payDate;
    this.dropoffTimeZone = data.dropoffTimeZone;
    this.expenseId = data.expenseId;
    this.expenseSeqNumber = data.expenseSeqNumber;
    this.fuelQuantity = data.fuelQuantity;
    this.payCategory = data.payCategory;
    this.connectedLoads = data.tripDetails?.connectedLoads;
    this.entityId = data?.entityId;
    this.entityType = data?.entityType;
    this.entityName = data?.entityName;
    this.expenseCategoryId = data?.expenseCategoryId;
    this.fuelType = data?.fuelType;
    this.isManuallyAdded = true;
    this.organizationId = data?.organizationId;
    this.settlementStatus = data?.settlementStatus;
    this.tripDetails = data?.tripDetails;
    this.payToEntityId = data?.payToEntityId;
    this.payToEntityName = data?.payToEntityName;
    this.tags = data?.tags;
    this.payToEntityType = data?.payToEntityType;
    this.payToDetails = data?.payToEntityId
      ? {
          entityId: data?.entityId,
          entityName: data?.entityName,
          entity: data?.entityType,
        }
      : null;

    switch (data.payCategoryName) {
      case 'Scheduled Deduction':
        this.payCategoryType = 'DEDUCTION';
        break;

      case 'Deduction':
        this.payCategoryType = 'DEDUCTION';
        break;

      case 'Payment':
        this.payCategoryType = 'PAYMENT';
        break;

      case 'Reimbursement':
        this.payCategoryType = 'REIMBURSEMENT';
        break;
      case 'Non-Taxable Payment':
        this.payCategoryType = 'NON_TAXABLE_PAYMENT';
        break;

      default:
        break;
    }

    this.payCategoryName = data.payCategoryName;
    this.settlementId = data.settlementId;
    this.settlementSeqNumber = data.settlementSeqNumber;

    this.truckStop = data.truckStop;
    this.description = data.description;
    this.state = data.state
      ? statesinCountry?.find((item) => item.value === data.state)
      : null;
    this.fuelTypeObj = data.fuelType
      ? staticData.fuelTypes?.find(
          (item) => item.key === data.fuelType || item.value === data.fuelType
        )
      : null;

    this.expenseCategoryKey = data.expenseCategoryKey;
    this.expenseCategoryObj = data.expenseCategoryId
      ? staticData.expenseCategories?.find(
          (item) => item.id === data.expenseCategoryId
        )
      : staticData.expenseCategories?.find(
          (item) => item.itemCode === data.expenseCategoryKey
        );

    this.assignedDocuments = data?.assignedDocuments
      ? data?.assignedDocuments?.map((item: FileType) => {
          return { ...item, name: item.fileName };
        })
      : [];
    this.isChecked = isChecked;
    this.loadId = data.loadId;
  }
}

export class PaymentList {
  content?: PaymentDetails[];
  totalAmount?: number;
  constructor(data: any, staticData: StaticDataType) {
    this.content = data?.content?.map(
      (item: PaymentDetails) => new PaymentDetails(item, staticData)
    );
    this.totalAmount = data.totalAmount || 0;
  }
}

export type NonTripPaymentListType = {
  fuelPayStatementList: PaymentList;
  otherPayStatementList: PaymentList;
};

export class FuelPaymentList extends PaymentList {
  constructor(data: any, staticData: StaticDataType) {
    super(data, staticData);
    const content = data?.payItemDTOs?.filter?.(
      (item: PaymentDetails) => item?.expenseCategoryKey === 'FUEL'
    );

    this.content = content?.map(
      (item: PaymentDetails) => new PaymentDetails(item, staticData)
    );
    this.totalAmount = !!this.content?.length
      ? calculatePaymentsTotalAmount(this.content)
      : 0;
  }
}
export class OtherPaymentList extends PaymentList {
  constructor(data: any, staticData: StaticDataType) {
    super(data, staticData);

    const content = data?.payItemDTOs?.filter?.(
      (item: PaymentDetails) => item?.expenseCategoryKey !== 'FUEL'
    );

    this.content = content?.map(
      (item: PaymentDetails) => new PaymentDetails(item, staticData)
    );

    this.totalAmount = !!this.content?.length
      ? calculatePaymentsTotalAmount(this.content)
      : 0;
  }
}

export type SelectedPaymentsType = {
  [key: string]: PaymentDetails[];
};

export class PayStatementDetails {
  id?: number;
  totalAmount?: number;
  grossYearToDate?: number;
  tripPaymentsList?: PaymentList;
  fuelNonTripPaymentsList?: PaymentList;
  otherNonTripPaymentsList?: PaymentList;
  constructor(data: any) {
    this.id = data.id;
    this.totalAmount = data.totalAmount;
    this.grossYearToDate = data.grossYearToDate;
    this.tripPaymentsList = data.tripPaymentsList;
    this.fuelNonTripPaymentsList = data.fuelNonTripPaymentsList;
    this.otherNonTripPaymentsList = data.otherNonTripPaymentsList;
  }
}

export enum PayStatementsEntityTypeEnum {
  pendingPayment = 'pendingPayment',
  inReviewStatement = 'inReviewStatement',
  reviewedStatement = 'reviewedStatement',
  closedStatement = 'closedStatement',
}

export const PayStatementsEntityTypeMap: {
  [key: string]: PayStatementsEntityTypeEnum;
} = {
  PENDING: PayStatementsEntityTypeEnum.pendingPayment,
  IN_REVIEW: PayStatementsEntityTypeEnum.inReviewStatement,
  CLOSED: PayStatementsEntityTypeEnum.closedStatement,
  REVIEWED: PayStatementsEntityTypeEnum.reviewedStatement,
};

export enum PayStatementsListTypeEnum {
  TRIP_PAYMENT = 'tripPaymentsList',
  FUEL_PAYMENT = 'fuelNonTripPaymentsList',
  OTHER_PAYMENT = 'otherNonTripPaymentsList',
}

export enum PayStatementsTypeEnum {
  driver = 'DRIVER',
  tractor = 'TRACTOR',
  dispatcher = 'DISPATCHER',
}

export enum PayStatementsTypeTextEnum {
  driver = 'Driver',
  tractor = 'Tractor',
  dispatcher = 'Dispatcher',
}

export enum PaymentStatusEnum {
  CLOSED = 'Closed',
  PENDING = 'Pending',
  REVIEWED = 'Reviewed',
  IN_REVIEW = 'In Review',
}

export const SETTLEMENT_CREATED_ENUMS = [
  PaymentStatusEnum.IN_REVIEW,
  PaymentStatusEnum.REVIEWED,
  PaymentStatusEnum.CLOSED,
];
