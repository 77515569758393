import React, { memo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReadOnlyField from '../../../../common/ReadOnlyField';
import Upload from '../../../../common/Ui/FileUpload/Upload';
import FileUploadUpdated from '../../../../common/Ui/FileUpload/indexUpdated';
import DatePicker from '../../../../common/Ui/FormDatePickerWithoutTimeZone/FormDatePickerWithoutTimeZone';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import {
  PayStatementData,
  PayStatementsEntityTypeEnum,
  PayStatementsTypeEnum,
  PaymentDetails,
} from '../../../../models';
import {
  amountFormat,
  fromIsoToMoment,
  getFormattedDateForGrid,
  isHasPermission,
} from '../../../../utils/index';
import {
  deleteFile,
  getExpenseCategoriesList,
  getFuelTypesList,
  saveFile,
} from '../../utils/api.utils';

import { Box, Checkbox, Grid, Typography } from '@mui/material';

import StorageManager from '../../../../StorageManager/StorageManager';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { statesinCountry } from '../../../../common/Ui/AddressField/constants/states';
import { ExpenseCategory } from '../../../../common/Ui/StatusComponent/StatusComponent';
import { StaticDataType } from '../../../../contexts/StaticDataContext';

import { t } from 'i18next';
import { TractorIcon } from '../../../../static';
import { FileType } from '../../../../types';
import AxeleAccordion from '../../../../ui-kit/components/Accordion';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';
import {
  deletePopupConfig,
  fuelPaymentsFormValidationSchema,
  otherPaymentsFormValidationSchema,
  paymentDeleteAction,
} from '../../constants';
import AddNewPaymentForm from '@/subPages/payStatement/components/Form/AddNewPaymentForm';
import FormDialog from '@/common/Ui/FormDialog';

type propsType = {
  id: number;
  isFuelPayment?: boolean;
  staticData: StaticDataType;
  entityType: PayStatementsEntityTypeEnum;
  isInEditMode?: boolean;
  showCheckbox?: boolean;
  disabledCheckbox?: boolean;
  onDeleteNonTripPayment?: (
    payment: PaymentDetails,
    isFuelPayment: boolean,
    documentIds: string[]
  ) => void;
  onRemoveInReviewPayments?: (
    payment: PaymentDetails,
    isFuelPayment?: boolean
  ) => void;

  onCheckboxChange: (
    checked: boolean,
    ids: number[],
    payment?: PaymentDetails,
    paymentListNmae?: string
  ) => void;
  payStatementData?: PayStatementData;
  editNonTripPayment?: (payment: PaymentDetails) => void;
};

function NonTripPaymentForm({
  id,
  isFuelPayment = false,
  staticData,
  entityType,
  isInEditMode,
  showCheckbox,
  onDeleteNonTripPayment,
  onRemoveInReviewPayments,
  onCheckboxChange,
  disabledCheckbox,
  payStatementData,
  editNonTripPayment,
}: propsType) {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const paymentsListNmaeRef = useRef(
    isFuelPayment ? 'fuelNonTripPaymentsList' : 'otherNonTripPaymentsList'
  );
  const [assignedDocumentList, setAssignedDocumentList] = useState(
    watch(`${paymentsListNmaeRef.current}.content.${id}.assignedDocuments`) ||
      null
  );

  const [
    payment,
    amount,
    payCategoryName,
    expenseCategoryObj,
    assignedDocuments,
    truckStop,
    fuelTypeObj,
    fuelQuantity,
    state,
    payDate,
    amountToShow,
    description,
    isChecked,
    fuelNonTripPayToEntityId,
    fuelNonTripEntityName,
    otherNonTripPayToEntityId,
    otherNonTripEntityName,
    entity,
  ] = watch([
    `${paymentsListNmaeRef.current}.content.${id}`,
    `${paymentsListNmaeRef.current}.content.${id}.amount`,
    `${paymentsListNmaeRef.current}.content.${id}.payCategoryName`,
    `${paymentsListNmaeRef.current}.content.${id}.expenseCategoryObj`,
    `${paymentsListNmaeRef.current}.content.${id}.assignedDocuments`,
    `${paymentsListNmaeRef.current}.content.${id}.truckStop`,
    `${paymentsListNmaeRef.current}.content.${id}.fuelTypeObj`,
    `${paymentsListNmaeRef.current}.content.${id}.fuelQuantity`,
    `${paymentsListNmaeRef.current}.content.${id}.state`,
    `${paymentsListNmaeRef.current}.content.${id}.payDate`,
    `${paymentsListNmaeRef.current}.content.${id}.amountToShow`,
    `${paymentsListNmaeRef.current}.content.${id}.description`,
    `${paymentsListNmaeRef.current}.content.${id}.isChecked`,
    `fuelNonTripPaymentsList.content.${id}.payToEntityId`,
    `fuelNonTripPaymentsList.content.${id}.entityName`,
    `otherNonTripPaymentsList.content.${id}.payToEntityId`,
    `otherNonTripPaymentsList.content.${id}.entityName`,
    `otherNonTripPaymentsList.content.${id}.entityType`,
  ]);

  const fileId = assignedDocuments?.[0]?.documentId;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorElement(null);
  };

  const userStorageData = StorageManager.getUser();

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    data: any,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    if (action?.label === 'Edit') {
      setIsEditModalOpen(true);
      setAnchorElement(null);
      return;
    } else {
      if (
        (entityType === PayStatementsEntityTypeEnum.pendingPayment &&
          action?.label !== 'Delete') ||
        (entityType === PayStatementsEntityTypeEnum.inReviewStatement &&
          action?.label !== 'Remove')
      )
        return;
      setIsDeletePopupOpen(true);

      setAnchorElement(null);
    }
  };

  const deletePayment = async (): Promise<void> => {
    setIsDeletePopupOpen(false);
    let ids;
    if (!!assignedDocumentList.length) {
      ids = assignedDocumentList.map((item: FileType) => item?.documentId);
    } else {
      ids = [assignedDocumentList?.documentId];
    }

    if (entityType === PayStatementsEntityTypeEnum.pendingPayment) {
      onDeleteNonTripPayment &&
        onDeleteNonTripPayment(payment, isFuelPayment, ids);
    } else {
      onRemoveInReviewPayments &&
        onRemoveInReviewPayments(payment, isFuelPayment);
    }
  };

  const handleAssignedDocumentsChange = async (file?: FileList) => {
    if (file) {
      const document = await saveFile(
        payment,
        file,
        staticData,
        assignedDocuments
      );
      if (document) setAssignedDocumentList(document);
    } else {
      let ids;
      if (!!assignedDocumentList.length) {
        ids = assignedDocumentList.map((item: FileType) => item?.documentId);
      } else {
        ids = [assignedDocumentList?.documentId];
      }
      setAssignedDocumentList(null);
      deleteFile(ids);
    }
  };
  const noPermissionForEditPayStatement = !isHasPermission([
    AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_EDIT,
  ]);

  const paymentsSummaryRenderer = () => {
    return (
      <Grid
        container
        alignItems={'center'}
        spacing={0}
        direction="row"
        justifyContent={'space-between'}
        sx={{ height: '40px' }}
      >
        <Grid container alignItems={'center'} direction="row" spacing={0}>
          <Grid>
            <Typography variant="h7" sx={{ color: 'primary.main' }}>
              {amountFormat(amount)}
            </Typography>
          </Grid>
          {isInEditMode && !noPermissionForEditPayStatement && (
            <Grid>
              <ThreeDotMenuIcon
                menuOptions={[
                  ...(noPermissionForEditPayStatement
                    ? []
                    : [
                        {
                          label: 'Edit',
                          iconName: 'EditOutlined',
                          source: 'MUIcons',
                        },
                      ]),
                  { id, ...paymentDeleteAction[entityType] },
                ]}
                open={!!anchorElement}
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                anchorEl={anchorElement}
                handleMenuItemClick={handleMenuItemClick}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const getMappedEntity = () => {
    let payToEntityId = null;
    let entityName = null;
    if (isFuelPayment && fuelNonTripPayToEntityId) {
      payToEntityId = fuelNonTripPayToEntityId;
      entityName = fuelNonTripEntityName;
    } else if (!isFuelPayment && otherNonTripPayToEntityId) {
      payToEntityId = otherNonTripPayToEntityId;
      entityName = otherNonTripEntityName;
    }
    return { payToEntityId, entityName };
  };

  const renderPaymentRecipient = () => {
    const { payToEntityId, entityName } = getMappedEntity();
    if (!payToEntityId) return <></>;
    return (
      <Grid item xs={12}>
        <Box
          sx={{
            background: '#C5E2FC',
            padding: '8px',
            display: 'flex',
            borderRadius: '6px',
          }}
        >
          <Typography fontSize={12}>
            Tractor:{' '}
            <Box display={'inline-block'} position={'relative'} top="5px">
              <TractorIcon />
            </Box>{' '}
            <b style={{ textDecoration: 'underline', fontSize: 12 }}>
              {entityName ?? ''}
            </b>
          </Typography>
        </Box>
      </Grid>
    );
  };

  const titleRenderer = () => {
    return (
      <>
        <Grid container>
          {isFuelPayment
            ? `${fuelTypeObj?.value || '--'} ${fuelTypeObj?.value ? '@' : ''}${
                state?.value || ''
              }`
            : expenseCategoryObj?.itemName}
        </Grid>

        <Grid xs={12}>
          <Typography variant="h8" sx={{ color: 'text.primary' }}>
            Date:
          </Typography>
          <Typography
            variant="body3"
            sx={{ color: 'text.secondary', marginLeft: '5px' }}
          >
            {getFormattedDateForGrid(fromIsoToMoment(payDate))}
          </Typography>
        </Grid>
        <Grid sx={{ marginTop: '5px' }}>
          <ExpenseCategory category={payCategoryName} />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container sx={{ marginTop: '15px' }}>
        {showCheckbox && (
          <Grid item xs={1.3} sx={{ marginTop: '10px' }}>
            <Checkbox
              checked={isChecked}
              disabled={disabledCheckbox}
              onChange={(e) => {
                e.stopPropagation();
                setValue(
                  `${paymentsListNmaeRef.current}.content.${id}.isChecked`,
                  e.target.checked
                );

                return onCheckboxChange(
                  e.target.checked,
                  [payment.id],
                  payment,
                  paymentsListNmaeRef.current
                );
              }}
            />
          </Grid>
        )}
        <Grid item xs={showCheckbox ? 10.7 : 12}>
          {isInEditMode ? (
            <AxeleAccordion
              errors={errors[paymentsListNmaeRef.current]?.content[id]}
              id={id.toString()}
              titleRenderer={titleRenderer}
              renderLabel={() => paymentsSummaryRenderer()}
              renderCaption={renderPaymentRecipient}
            >
              <Grid
                container
                spacing={0}
                justifyContent="space-between"
                sx={{ marginTop: isFuelPayment ? '-15px' : '-10px' }}
              >
                {payStatementData?.payStatementType ===
                  PayStatementsTypeEnum.driver &&
                  getMappedEntity()?.entityName && (
                    <Box marginTop={'10px'}>
                      <ReadOnlyField
                        text={getMappedEntity()?.entityName ?? 'N/A'}
                        title="Tractor"
                        xs={6}
                      />
                    </Box>
                  )}
                {isFuelPayment && (
                  <>
                    <TextField
                      disabled
                      control={control}
                      name={`${paymentsListNmaeRef.current}.content.${id}.truckStop`}
                      label="Truck Stop"
                      sizes={{ xs: 5.7 }}
                    />
                    <ReadOnlyField
                      text={expenseCategoryObj?.itemName}
                      title={t('expenseCategory')}
                    />
                    <SingleAutocompleteForm
                      disabled
                      name={`${paymentsListNmaeRef.current}.content.${id}.fuelTypeObj`}
                      label="Fuel Type"
                      fieldName="value"
                      getOptions={getFuelTypesList}
                      control={control}
                      groupBy="categoryName"
                      sizes={{ xs: 5.7 }}
                      required
                    />

                    <TextField
                      disabled
                      control={control}
                      name={`${paymentsListNmaeRef.current}.content.${id}.fuelQuantity`}
                      label={t('fuelQuantity')}
                      required
                      sizes={{
                        xs: 5.7,
                      }}
                    />

                    <SingleAutocompleteForm
                      disabled
                      name={`${paymentsListNmaeRef.current}.content.${id}.state`}
                      label="State"
                      fieldName="name"
                      options={statesinCountry}
                      control={control}
                      sizes={{ xs: 5.7 }}
                      required
                    />
                  </>
                )}

                {!isFuelPayment && (
                  <SingleAutocompleteForm
                    disabled
                    name={`${paymentsListNmaeRef.current}.content.${id}.expenseCategoryObj`}
                    label={t('expenseCategory')}
                    fieldName="itemName"
                    getOptions={getExpenseCategoriesList}
                    control={control}
                    sizes={{ xs: 12 }}
                    wrapperStyles={{ marginBottom: '8px' }}
                    required
                  />
                )}
                <DatePicker
                  disabled
                  control={control}
                  disabledText
                  name={`${paymentsListNmaeRef.current}.content.${id}.payDate`}
                  label="Date"
                  sizes={{ xs: 5.7 }}
                  required
                />
                <TextField
                  disabled
                  control={control}
                  name={`${paymentsListNmaeRef.current}.content.${id}.amountToShow`}
                  label="Amount ($)"
                  required
                  sizes={{
                    xs: 5.7,
                  }}
                />
                <TextField
                  disabled
                  control={control}
                  name={`${paymentsListNmaeRef.current}.content.${id}.description`}
                  label="Description"
                  sizes={{
                    xs: 12,
                  }}
                />
                {!!payment?.assignedDocuments?.length && (
                  <Grid item xs={12}>
                    <Permission
                      includes={[
                        AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD,
                      ]}
                    >
                      <FileUploadUpdated
                        name={`assignedDocuments.${0}`}
                        label="Upload"
                        fileId={fileId}
                        control={control}
                        removable={false}
                      />
                    </Permission>
                  </Grid>
                )}
              </Grid>
            </AxeleAccordion>
          ) : (
            <AxeleAccordion
              titleRenderer={titleRenderer}
              renderLabel={() => paymentsSummaryRenderer()}
              renderCaption={renderPaymentRecipient}
            >
              <Grid
                container
                spacing={0}
                justifyContent="space-between"
                sx={{ marginTop: '-15px' }}
              >
                {isFuelPayment && (
                  <>
                    <ReadOnlyField
                      text={truckStop}
                      title="Truck Stop"
                      xs={6}
                      customStyles={{ text: { marginBottom: '15px' } }}
                    />
                    <ReadOnlyField
                      text={expenseCategoryObj?.itemName}
                      title={t('expenseCategory')}
                      xs={6}
                      customStyles={{ text: { marginBottom: '15px' } }}
                    />
                    <ReadOnlyField
                      text={fuelQuantity}
                      title={t('fuelQuantity')}
                      xs={6}
                      customStyles={{ text: { marginBottom: '15px' } }}
                    />
                  </>
                )}
                <ReadOnlyField
                  text={amountToShow}
                  title="Amount ($)"
                  xs={6}
                  customStyles={{ text: { marginBottom: '15px' } }}
                />
                <ReadOnlyField
                  text={description}
                  title="Description"
                  xs={isFuelPayment ? 12 : 6}
                />
                <Grid item xs={12}>
                  <Permission
                    includes={[
                      AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD,
                    ]}
                  >
                    <Upload
                      disabled
                      label={t('uploadReceipt')}
                      onChange={handleAssignedDocumentsChange}
                      value={assignedDocuments}
                    />
                  </Permission>
                </Grid>
              </Grid>
            </AxeleAccordion>
          )}
        </Grid>
      </Grid>
      {isDeletePopupOpen && (
        <DeletePopup
          open={isDeletePopupOpen}
          title={deletePopupConfig[entityType].title}
          body={deletePopupConfig[entityType].body}
          subtitle={deletePopupConfig[entityType].subtitle}
          onAction={deletePayment}
          onClose={() => setIsDeletePopupOpen(false)}
          buttonText={deletePopupConfig[entityType].buttonText}
          width={'500px'}
        />
      )}
      {isEditModalOpen && (
        <FormDialog
          data={{ ...payment, entityType }}
          titleText={
            payment.expenseCategoryKey === 'FUEL'
              ? 'Edit Fuel Payments'
              : 'Edit Other Payments'
          }
          actionLabel="Save"
          actionLabelId="EditButton"
          open={isEditModalOpen}
          onAction={(data) => {
            editNonTripPayment && editNonTripPayment(data);
            setIsEditModalOpen(false);
          }}
          handleClose={() => setIsEditModalOpen(false)}
          contentRenderer={() => (
            <AddNewPaymentForm
              isFuelPayment={isFuelPayment}
              entityType={payStatementData?.payStatementType}
              entityId={payStatementData?.payStatementId}
              fileId={payment.assignedDocuments[0]?.documentId}
              isEdit
            />
          )}
          validationSchema={
            payment.expenseCategoryKey === 'FUEL'
              ? fuelPaymentsFormValidationSchema
              : otherPaymentsFormValidationSchema
          }
          width={payment.expenseCategoryKey === 'FUEL' ? undefined : 650}
        />
      )}
    </>
  );
}

export default memo(NonTripPaymentForm);
