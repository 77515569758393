export interface IDispatchDriverTimeline {
  driverGroupId: string;
  driverGroupName: string;
  dispatcherId: any;
  dispatcherName: any;
  organizationId: number;
  currentTrip: IDispatchDriverTimelineTrip[];
  assignedTrips: IDispatchDriverTimelineTrip[];
  futureTrips: IDispatchDriverTimelineTrip[];
}

export enum EDispatchDriverTimelineTripType {
  GAP = 'GAP',
  TRIP = 'TRIP',
  TIMEOFF = 'TIMEOFF',
}
export interface IDispatchDriverTimelineTrip {
  type: EDispatchDriverTimelineTripType;
  previousTripId: any;
  id: any;
  nextTripId: string;
  violations: any;
  status: any;
  applicableActions: any;
  seqNumber: any;
  emptyMiles: number;
  loadedMiles: any;
  driveTimeMins: any;
  derivedStatus?: string;
  dutyTimeMins: any;
  loadDetails: { loadId: string; seqNumber: string }[];
  startTime: string;
  endTime: string;
  stops: DispatchDriverTimelineTripStop[];
  onHold?: boolean;
}

export interface DispatchDriverTimelineTripStop {
  id: number;
  refNumber: any;
  loadDetails: LoadDetail[];
  appointmentStartDate: string;
  appointmentEndDate: string;
  estimatedTimeOfArrival: any;
  estimatedTimeOfCompletion: any;
  actualTimeOfArrival: any;
  actualTimeOfCompletion: any;
  violation: number;
  arrivalDelay: number;
  dwellTime: any;
  detentionTime: any;
  totalDriveTime: number;
  totalMiles: number;
  location: TripLocation;
  locationId: any;
  type: string;
  status: string;
  estimatedActivityDuration: number;
  isDriverAssistRequired: boolean;
  fixedAppointment: boolean;
  activityType: string;
  notes: any;
  noteDetails: any;
  contact?: Contact;
  stopTpSolution: any;
}

interface LoadDetail {
  id: string;
  seqNumber?: string;
}

interface TripLocation {
  id?: number;
  organizationId?: number;
  axeleId: any;
  locationName?: string;
  fullAddress?: string;
  address: string;
  streetAddress: string;
  streetAddress2: any;
  city: string;
  zipcode: string;
  state: string;
  lat: number;
  lng: number;
  contacts: Contact[];
  center: Center;
  timezone: string;
  notes: any;
  avgHandlingTime?: number;
  recordDate: any;
  country?: string;
}

interface Center {
  lat: number;
  lng: number;
}

interface Contact {
  firstname: any;
  lastname: any;
  email: string;
  countryCode: any;
  phone: any;
  extension: any;
}
