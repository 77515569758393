import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { customerService, factoringService, utilsService } from '../../../api';
import { FormAddress } from '../../../common/Ui/AddressField';
import { AutoCompleteAsyncForm } from '../../../common/Ui/AutoCompleteDeprecated';
import FormDialog from '../../../common/Ui/FormDialog/index';
import Select from '../../../common/Ui/Select';
import { SingleAutocompleteForm } from '../../../common/Ui/SingleAutocomplete';
import TextField from '../../../common/Ui/TextField/TextField';
import { DislikeIcon, LikeIcon } from '../../../ui-kit/components/Assets';
import ButtonGroup_Like_Dislike from '../../../ui-kit/components/ButtonGroup_Like_Dislike';

import { Box, Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../common/Accordion';
import { customerPage } from '../../../common/PendoClassIDs/constants';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import Checkbox from '../../../common/Ui/Checkbox';
import FormPhoneField from '../../../common/Ui/FormPhoneField';
import { customerTypeOptions } from '../../../constants/contacts';
import {
  CommodityType,
  CommodityTypesListRequest,
  CreateCustomerContactRequest,
  FactoringListRequest,
} from '../../../models';
import { isHasPermission } from '../../../utils';
import { currencyDisplay } from '../../../utils/grid.utils';
import { contactValidationSchema } from '../../../validator/validationSchemas';
import { getCreatePaymentPanelStyles } from '../../../views/finance/paymentManagement/components/Form/styles';
import { invoiceTermsOptions } from '../../settings/Preferences/constants';
import { ContactsSummary } from '../../shared/Contacts';
import { contactDefaultData } from '../constants';
import {
  ContactSummary,
  CustomerSummaryTableRow,
  FactoringDetails,
} from '../models/customer';
import AddContactForm from './AddContactForm';
import ContactListForm from './ContactListForm';

const preferredProhibitedOptions = [
  {
    key: 'PREFERRED',
    name: 'Preferred',
    iconRenderer: (selected: boolean) => (
      <LikeIcon height={15} width={15} color={selected ? '#fff' : '#2B64CB'} />
    ),
    getButtonStyle: (selected: boolean) => ({
      backgroundColor: selected ? '#2B64CB' : '#fff',
      borderRadius: '6px',
      borderColor: '#2B64CB',
      marginRight: 5,
    }),
  },
  {
    key: 'PROHIBITED',
    name: 'Prohibited',
    iconRenderer: (selected: boolean) => (
      <DislikeIcon
        height={15}
        width={15}
        color={selected ? '#fff' : '#F44336'}
      />
    ),
    getButtonStyle: (selected: boolean) => ({
      backgroundColor: selected ? '#F44336' : '#fff',
      borderColor: '#F44336',
      borderRadius: '6px',
      p: 0,
    }),
  },
];

export default function CustomerDetailsForm({
  isCreatePanelOpen,
  updateCustomerSummary,
  data,
}: {
  isCreatePanelOpen: boolean;
  data: any;
  updateCustomerSummary: (data: CustomerSummaryTableRow) => void;
}) {
  const styles = getCreatePaymentPanelStyles({ theme: useTheme() });

  const [factoringOptions, setFactoringOptions] = useState<FactoringDetails[]>(
    []
  );
  const [addContactDialogOpen, setAddContactDialog] = useState(false);
  const { control, getValues, setValue, watch } = useFormContext();
  const [customerId, factoring, contacts] = getValues([
    'id',
    'factoring',
    'contacts',
  ]);
  const numberOfContacts = contacts?.length;
  const primaryContact =
    numberOfContacts &&
    contacts.find((contact: ContactSummary) => contact.isPrimary);
  const primaryContactName = primaryContact?.fullName;
  const primaryContactPhone = primaryContact?.phoneData;
  const primaryContactEmail = primaryContact?.email;
  useEffect(() => {
    setValue('name', data?.name);
    setValue('factoring', data?.factoring);
    setValue('contacts', data?.contacts);
    setValue('type', data?.type);
    setValue('customerPreference', data?.customerPreference);
    setValue('mc', data?.mc);
    setValue('commodityTypes', data?.commodityTypes);
    setValue('ediCustomerId', data?.ediCustomerId);
    setValue('factoringName', data?.factoringName);
    setValue('notes', data?.notes);
    setValue('useSameBillTo', data?.useSameBillTo);
  }, [data, setValue]);

  const formValues = watch();
  const handleBillToCheckChange = (value: boolean) => {
    if (value) {
      setValue(`billToAddress`, formValues.addressDTO);
      setValue(`billToName`, formValues.name);
      setValue('billingNotes', formValues.notes);
    }
    setValue('useSameBillTo', value);
    setValue('useDifferentBillTo', !value);
  };
  useEffect(() => {
    if (formValues.useSameBillTo) {
      setValue(`billToAddress`, formValues.addressDTO);
      setValue(`billToName`, formValues.name);
      setValue('billingNotes', formValues.notes);
      setValue('billingPhoneData', primaryContactPhone);
    }
  }, [formValues.addressDTO, formValues.name, formValues.notes]);
  useEffect(() => {
    // @TODO: Nvard - improve to not fetch data often
    const fetchFactoringList = async (): Promise<void> => {
      try {
        const response = await factoringService.getFactoringList(
          new FactoringListRequest()
        );
        let factoringOptions: FactoringDetails[] = [];
        if (response?.length) {
          factoringOptions = response.map((item) => new FactoringDetails(item));
        }

        setFactoringOptions(factoringOptions);
      } catch (error) {
        throw error;
      }
    };
    fetchFactoringList();
  }, []);

  useEffect(() => {
    setValue('contact', primaryContactName);
  }, [primaryContactName, setValue]);

  useEffect(() => {
    setValue('email', primaryContactEmail);
  }, [primaryContactEmail, setValue]);

  useEffect(() => {
    if (formValues.useSameBillTo)
      setValue('billingPhoneData', primaryContactPhone);
  }, [primaryContactPhone, setValue]);

  const onCloseAddContact = () => {
    setAddContactDialog(false);
  };
  const isInvoiceTermCustom = watch('invoiceTerm') === 'CUSTOM';

  const onAddContact = async (contact: ContactSummary) => {
    let shouldDirty = true;
    if (!isCreatePanelOpen) {
      const contactRequest = new CreateCustomerContactRequest({
        ...contact,
        customerId,
      });
      await customerService.createContact(contactRequest);
      shouldDirty = false;
    }
    setAddContactDialog(false);
    if (contact.isPrimary) {
      contacts.forEach((contact: ContactSummary, index: number) => {
        setValue(`contacts.${index}.isPrimary`, false);
      });
    }

    contacts.push(contact);
    setValue('contacts', contacts, { shouldDirty });
    if (!isCreatePanelOpen) {
      const customerSummary = getValues();
      updateCustomerSummary(
        new CustomerSummaryTableRow({ ...customerSummary, contacts })
      );
    }
  };

  const getCommodityTypes = async (
    type: string
  ): Promise<CommodityType[] | []> => {
    const request = new CommodityTypesListRequest({ type });

    const response = await utilsService.getCommodityTypes(request);
    if (response) {
      const commodityTypes: CommodityType[] = response.map((ct) => {
        const commodityType = new CommodityType();
        commodityType.commodityTypeId = ct.id;
        commodityType.commodityType = ct.itemName;
        return commodityType;
      });
      return commodityTypes;
    }
    return [];
  };
  const handleOpenAddContactModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAddContactDialog(true);
  };

  const contactsSummaryRenderer = () => {
    return (
      <ContactsSummary
        addClickHandler={handleOpenAddContactModal}
        numberOfContacts={numberOfContacts}
      />
    );
  };

  const noPermissionForEditCustomer = !isHasPermission([
    AXELE_PERMISSION_TYPE.CUSTOMER_EDIT,
  ]);
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box sx={{ width: '100%', pt: 1 }}>
        <Accordion
          summaryRenderer={() => (
            // @ISSUE: Vasil
            <Typography variant="h7" sx={{ color: 'primary.main' }}>
              {t('customerDetails')}
            </Typography>
          )}
        >
          <TextField
            id={customerPage + 'CustomerName'}
            disabled={noPermissionForEditCustomer}
            control={control}
            name="name"
            label={t('customerName')}
            sizes={{ xs: 9 }}
            required
            size={'small'}
          />
          <ButtonGroup_Like_Dislike
            disabled={noPermissionForEditCustomer}
            control={control}
            options={preferredProhibitedOptions}
            name="customerPreference"
            sizes={{ xs: 3 }}
            style={{
              justifyContent: 'flex-end',
              alignSelf: 'start',
              marginTop: '20px',
            }}
          />
          <TextField
            id={customerPage + 'MC'}
            disabled={noPermissionForEditCustomer}
            control={control}
            name="mc"
            label={t('mcNum')}
            sizes={{ xs: 6 }}
            size={'small'}
          />
          <Select
            id={customerPage + 'Type'}
            disabled={noPermissionForEditCustomer}
            control={control}
            name="type"
            label={t('type')}
            options={customerTypeOptions}
            fieldName="name"
            fieldValue="id"
            sizes={{ xs: 6 }}
            size={'small'}
          />
          <SingleAutocompleteForm
            id={customerPage + 'FactoringCompany'}
            control={control}
            name="factoring"
            fieldName="factoringName"
            label={t('factoringCompany')}
            options={factoringOptions}
            defaultValue={factoring?.factoringName || ''}
            sizes={{ xs: 6 }}
            size="small"
          />
          <Grid xs={6} item>
            <Box>
              <Typography sx={styles.cardLabel}>Open Credits</Typography>
              <Typography sx={styles.cardValue}>
                {currencyDisplay(data.openCreditAmount, '0', 2)}
              </Typography>
            </Box>
          </Grid>
          <TextField
            id={customerPage + 'ediCustomerId'}
            disabled={noPermissionForEditCustomer}
            control={control}
            name="ediCustomerId"
            label={t('EDICustomerID')}
            sizes={{ xs: 12 }}
            size="small"
          />
          <AutoCompleteAsyncForm
            disabled={noPermissionForEditCustomer}
            control={control}
            name="commodityTypes"
            fieldName={'commodityType'}
            getData={getCommodityTypes}
            label={t('commodity')}
            defaultValue={[]}
            size="small"
          />
          <FormAddress
            control={control}
            name="addressDTO"
            sizes={{ xs: 12 }}
            isManual
            disabled={noPermissionForEditCustomer}
          />
          <TextField
            id={customerPage + 'Notes'}
            disabled={noPermissionForEditCustomer}
            control={control}
            name="notes"
            label={t('notes')}
            sizes={{ xs: 12 }}
            size="small"
          />
          <TextField
            id={customerPage + 'message'}
            disabled={noPermissionForEditCustomer}
            control={control}
            name="messageOnInvoice"
            label={t('messageOnInvoice')}
            sizes={{ xs: 12 }}
            size="small"
          />
          <SingleAutocompleteForm
            control={control}
            fieldName="value"
            fieldValue="key"
            name="invoiceTerm"
            label="Invoice Terms"
            options={invoiceTermsOptions}
            disableClear
            sizes={{ xs: isInvoiceTermCustom ? 6 : 12 }}
          />
          {isInvoiceTermCustom && (
            <TextField
              control={control}
              name="invoiceTermValue"
              label="Number of days"
              required
              onlyNumbers
              sizes={{ xs: 6 }}
            />
          )}
        </Accordion>
        <Accordion
          summaryRenderer={contactsSummaryRenderer}
          expanded={!isCreatePanelOpen}
        >
          {numberOfContacts
            ? contacts.map((_contact: ContactSummary, index: number) => (
                <ContactListForm key={`${index}`} id={index} />
              ))
            : null}
        </Accordion>
        <Accordion
          summaryRenderer={() => (
            <Typography variant="h7" sx={{ color: 'primary.main' }}>
              {t('billingDetails')}
            </Typography>
          )}
        >
          <Checkbox
            // id={addLoadPageStopInfo + 'preLoaded'}
            name={`useSameBillTo`}
            control={control}
            onChangeCb={handleBillToCheckChange}
            labelPlacement={'end'}
            label={t('sameAsCustomerInfo')}
          />
          {!formValues.useSameBillTo && (
            <>
              <TextField
                id={customerPage + 'billToName'}
                disabled={noPermissionForEditCustomer}
                control={control}
                name="billToName"
                label={'Billing Contact Name'}
                sizes={{ xs: 12 }}
                size="small"
              />
              <FormPhoneField
                control={control}
                name="billingPhoneData"
                label={t('phone')}
                sizes={{ xs: 12 }}
              />
              <FormAddress
                control={control}
                name="billToAddress"
                sizes={{ xs: 12 }}
                isManual
                disabled={noPermissionForEditCustomer}
              />
              <TextField
                id={customerPage + 'Notes'}
                disabled={noPermissionForEditCustomer}
                control={control}
                name="billingNotes"
                label={t('notes')}
                sizes={{ xs: 12 }}
                size="small"
              />
            </>
          )}
        </Accordion>
        {addContactDialogOpen && (
          <FormDialog
            data={{ ...contactDefaultData, isPrimary: !contacts?.length }}
            titleText={t('addNewContact')}
            actionLabel={t('addContact')}
            actionLabelId="addContactButton"
            open={addContactDialogOpen}
            onAction={onAddContact}
            disableActionBtnByDefault
            handleClose={onCloseAddContact}
            validationSchema={contactValidationSchema}
            contentRenderer={() => <AddContactForm />}
          ></FormDialog>
        )}
      </Box>
    );
  }
}
