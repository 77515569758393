import {
  documentService,
  staticService,
  terminalService,
  userService,
} from '../../api';
import { ServiceError } from '../../api/interfaces';
import { AXELE_PERMISSION_TYPE } from '../../common/Permission';
import {
  AssignDocumentRequest,
  AssignDocumentResponseType,
  AssociatedTerminalType,
  CreateDocumentOnlyRequest,
  CreateUserRequest,
  DeleteDocumentRequest,
  DeleteUserRequest,
  DocumentAssigments,
  DocumentSummaryResponse,
  GetDocumentTypeListRequest,
  GetStatesRequest,
  GetUserAssociatedTerminalsRequest,
  GetUserByIdRequest,
  PaginatedAllUserListRequest,
  QueryParams,
  resetOtherUserPasswordRequest,
  resetPasswordRequest,
  ValidateUserRequest,
} from '../../models';
import {
  resetPasswordType,
  User,
  UserDetails,
} from '../../models/DTOs/user/User';
import StorageManager from '../../StorageManager/StorageManager';
import { AssignedDocuments, PaginatedResult, ViewMetaData } from '../../types';
import {
  isHasPermission,
  prepareFiltersFromList,
  removeExtraSpacesFromString,
} from '../../utils';
import TerminalController from '../../views/settings/terminals/TerminalController';
import {
  emailFilter,
  employmentType,
  nameFilter,
  roleCodeList,
  tagIds,
  userStatus,
  userTypes,
} from '../../views/settings/users/constants';
import { getEldIntegrationDriversByCredentialId } from '../drivers/utils';

export const getFilteredRequest = (
  viewMetaData: ViewMetaData,
  request: PaginatedAllUserListRequest,
  terminalsIds?: string[]
): PaginatedAllUserListRequest => {
  request.nameFilter = prepareFiltersFromList(
    viewMetaData[nameFilter],
    'nameToFilter'
  );
  request.userStatus = prepareFiltersFromList(viewMetaData[userStatus], 'key');
  request.userTypes = prepareFiltersFromList(viewMetaData[userTypes], 'value');
  request.roleCodeList = prepareFiltersFromList(
    viewMetaData[roleCodeList],
    'roleCode'
  );
  request.employmentType = viewMetaData[employmentType]?.key;
  request.emailFilter = prepareFiltersFromList(
    viewMetaData[emailFilter],
    'email'
  );
  request.terminalIds = terminalsIds;
  request.tagIds = prepareFiltersFromList(viewMetaData[tagIds], 'id');

  return request;
};

export const fetchUsers = async (
  queryParams: ViewMetaData,
  terminalsIds?: string[],
  fetchingType = 'GET_DATA'
): Promise<PaginatedResult<User> | undefined> => {
  const request = new PaginatedAllUserListRequest(queryParams);

  const response = await userService.getPaginatedAllUserList(
    getFilteredRequest(queryParams, request, terminalsIds),
    fetchingType
  );
  if (response instanceof ServiceError) {
    return;
  } else {
    return response;
  }
};

export const createUser = async (
  newUserData: UserDetails
): Promise<UserDetails | undefined> => {
  const requestData = prepareUserRequest(newUserData);
  const request = new CreateUserRequest();
  const response = await userService.createUser(request, requestData);
  if (response instanceof ServiceError) {
    return;
  } else {
    const { webUser, driver } = response;
    const currentUser = webUser?.id ? webUser : driver;
    return currentUser || undefined;
  }
};

export const updateUser = async (
  item: UserDetails
): Promise<
  { currentUser: UserDetails | undefined; currentId: null | string } | undefined
> => {
  const requestData = prepareUserRequest(item, 'update');
  const request = new CreateUserRequest();
  const response = await userService.updateUser(request, requestData);
  if (response instanceof ServiceError) {
    return;
  } else {
    const { webUser, driver } = response;

    return { currentUser: !webUser.id ? driver : webUser, currentId: item.id };
  }
};

export const deleteUser = async (
  userId: string
): Promise<string | undefined> => {
  const request = new DeleteUserRequest(false);
  request.id = userId;
  const response = await userService.deleteUser(request);
  if (response instanceof ServiceError) {
    return;
  }
  return response;
};

export const validateUserDeletion = async (
  userId: string
): Promise<string | undefined> => {
  const request = new DeleteUserRequest(false);
  request.id = userId;
  const response = await userService.validateUserDeletion(request);
  if (response instanceof ServiceError) {
    return;
  }
  return response;
};

export const validateResetPassword = async (
  password: resetPasswordType,
  passwordType = 'OLD_PASSWORD'
): Promise<string | ServiceError | undefined> => {
  const request = new resetPasswordRequest(password);
  request.addUserIdToParams();
  const response = await userService.validateResetPassword(
    request,
    passwordType
  );

  if (response instanceof ServiceError) {
    return response as ServiceError;
  }
  return response;
};

export const resetPassword = async (
  password: resetPasswordType
): Promise<string | undefined> => {
  const request = new resetPasswordRequest(password);
  request.addUserIdToParams();
  return userService.resetPassword(request);
};

export const resetOtherUserPassword = async (
  password: resetPasswordType,
  accountId?: string
): Promise<string | undefined> => {
  const request = new resetOtherUserPasswordRequest(password, accountId);
  return userService.resetOtherUserPassword(request);
};

export const getUserById = async (
  userId: string
): Promise<UserDetails | undefined> => {
  const request = new GetUserByIdRequest();
  request.id = userId;
  request.loggedIn = 1;
  const result = await userService.getUserById(request);
  if (result instanceof ServiceError) {
    return;
  } else {
    const assignedDocuments = await getCommercialDriverLicense(result.id);

    const associatedTerminalsList = result?.associatedTerminalsList?.map(
      (terminal) => {
        return TerminalController.instance().terminalById(terminal?.id);
      }
    );

    const data = { ...result, associatedTerminalsList, assignedDocuments };

    const userDetails = new UserDetails(data);

    return userDetails;
  }
};

/* @TODO clean up this function and add terminal support conditions */
export const prepareUserRequest = (data: UserDetails, mode = 'create') => {
  const requestData: any = {
    firstname: removeExtraSpacesFromString(data.firstName),
    lastname: removeExtraSpacesFromString(data.lastName),
    tags: data.tags,
    phoneData: data.phoneData,
    email: data.email,
    addressData: data.address || null,
    associatedTerminalsList: data.associatedTerminalsList?.map((t) => ({
      id: t.id,
      companyName: t.companyName,
    })),
    terminalId: data.terminalId,
    terminal: null,
    employmentType: data.employmentType || null,
    operationType: data.operationType || null,
    sendInvitation: data.sendInvitation,
    dispatcherId: data.dispatcher?.id,
  };
  requestData.emergencyContact = {
    firstName: data.emergencyContact.firstName,
    lastName: data.emergencyContact.lastName,
    phoneData: data.emergencyContact.phoneData,
    email: data.emergencyContact.email,
    addressData: data.emergencyContact.addressData || null,
  };
  let driveStatus = 0;
  let roleCode = data.roleCode;
  if (data.isDriver && data.isUser) {
    driveStatus = 2;
  }
  if (data.isDriver && !data.isUser) {
    driveStatus = 1;
    roleCode = 'DR';
  }
  requestData.startingYTD = data.startingYTD;

  if (data.isDriver) {
    requestData.driverRefId = data.driverRefId;
    requestData.effectiveStartDate = data.effectiveStartDate;
    requestData.orgDriverLicense = {
      ...requestData.orgDriverLicense,
      ...data.driverLicense,
      category: data.driverLicense?.category?.value,
    };

    requestData.eldDriverId = data.eldDriver?.id;
    requestData.fuelCardNumber = data.fuelCardNumber;
    requestData.fuelCardProvider = data.fuelCardProvider;
    requestData.trackingSourceData = data.trackingSource;
    requestData.timezone = data.timezone;
    requestData.allowDriverAcceptRejectLoad = data.allowDriverAcceptRejectLoad;

    requestData.eldCredentialId = null;
    requestData.eldDriverFirstName = null;
    requestData.eldDriverLastName = null;
    requestData.eldProviderId = null;
    requestData.eldProviderName = null;
    requestData.hidePendingPaymentsFromMobile =
      data.hidePendingPaymentsFromMobile;
    requestData.canUpdateCommodity = data.canUpdateCommodity;
    requestData.allowOutOfOrderDispatch = data.allowOutOfOrderDispatch;
  }
  requestData.roleCode = roleCode;
  requestData.driveStatus = driveStatus;
  requestData.invoiceAccessType = data.invoiceAccessType;
  if (mode === 'create') {
    if (data.userType) {
      requestData.userType = data.userType;
    }
    return requestData;
  }
  if (data.userType) {
    data.beRes.userType = data.userType;
  }

  return { ...data.beRes, ...requestData };
};

/* @TODO davits fix return type */
export const validateUser = async (
  userId: string | null,
  email: string,
  roleCode: string,
  driverRefId: string
): Promise<any> => {
  const user = StorageManager.getUser();
  if (user) {
    const request = new ValidateUserRequest();
    request.id = userId;
    request.email = email;
    request.dot = user.dot;
    request.roleCode = roleCode;
    request.driverRefId = driverRefId;
    const response = await userService.validateUser(request);
    if (response instanceof ServiceError) {
      return response;
    }
    return response;
  }
};

export const noPermissionForAddRemoveEditUser = () => {
  return !isHasPermission([AXELE_PERMISSION_TYPE.USER_PROFILE_EDIT]);
};

export const noAddUserPermission = () => {
  return !isHasPermission([AXELE_PERMISSION_TYPE.USER_PROFILE_ADD]);
};

export const getByRoleIsFieldDisabled = (
  loggedInUserType: string,
  selectedUserType: string,
  fieldName?: string
): boolean => {
  if (
    loggedInUserType !== 'OWNER_ADMIN' &&
    selectedUserType === 'OWNER_ADMIN'
  ) {
    return true;
  }

  if (!fieldName) return false;

  if (
    loggedInUserType === 'OWNER_ADMIN' &&
    selectedUserType === 'OWNER_ADMIN' &&
    fieldName === 'roleCode'
  ) {
    return true;
  }

  return (
    !(loggedInUserType === 'OWNER_ADMIN' || loggedInUserType === 'ADMIN') &&
    fieldName === 'roleCode'
  );
};

export const getStates = async () => {
  const request = new GetStatesRequest();
  const response = await staticService.getStates(request);

  if (response instanceof ServiceError) {
    return;
  }
  return response.states;
};

export const getEldDrivers = (pattern: string, eldProviderId: number) => {
  const props = {
    credentialId: eldProviderId,
    pageNumber: 1,
    pageSize: 25,
    pattern,
    linkedFilter: 'NON_LINKED',
    sort: '+firstname +lastname',
  };
  return getEldIntegrationDriversByCredentialId(props);
};

//#region assignDocument

export const uploadDocument = async (assignedDocument: File) => {
  const queryParams = new QueryParams();
  queryParams.addUserIdToParams();

  const requestBody = new CreateDocumentOnlyRequest({
    data: assignedDocument,
    ownerId: queryParams.userId,
  });

  const response = await documentService.createDocumentOnly(requestBody);
  return response instanceof ServiceError ? null : response;
};

export const getDocumentTypes = async () => {
  const documentTypesList = await documentService.getDocumentTypeList(
    new GetDocumentTypeListRequest()
  );
  return documentTypesList;
};

export const assignDocument = async (
  file: DocumentSummaryResponse,
  driver: UserDetails
) => {
  const user = StorageManager.getUser() || {};

  const documentTypesList = await getDocumentTypes();

  if (!documentTypesList) return;
  const documentType = documentTypesList?.find(
    (item) => item.itemCode === 'COMMERCIAL_DRIVER_LICENSE'
  );

  const assignDocumentRequest = new AssignDocumentRequest({
    description: '',
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: 'COMMERCIAL_DRIVER_LICENSE',
    documentTypeId: documentType?.id || '',
    documentId: file.id,
    fileName: file.fileName,
    fileSize: file.fileSize,
    uploadDate: file.uploadDate,
    ownerId: user.id,
    attachedEntities: [
      {
        properties: [
          {
            id: driver?.id.toString() || '',
            title: driver?.firstName + ' ' + driver.lastName,
          },
        ],
        type: 'DRIVER',
      },
    ],
  });
  const response = documentService.assignDocument(assignDocumentRequest);
  return response instanceof ServiceError ? null : response;
};

export const saveFile = async (
  driver: any,
  file: FileList
): Promise<AssignDocumentResponseType | undefined> => {
  if (driver && !!file.length) {
    const uploadedFile = await uploadDocument(file[0]);

    if (uploadedFile) {
      const document = await assignDocument(uploadedFile, driver);

      if (document) return document;
    }
    return;
  }
};

export const deleteFile = async (
  ids: string[],
  userId: string
): Promise<AssignedDocuments[] | null> => {
  if (!ids) return null;
  const response = await documentService.deleteDocument(
    new DeleteDocumentRequest({
      documentIds: ids,
    })
  );

  if (!response) return null;

  return await getCommercialDriverLicense(userId);
};

export const getUserAssociatedTerminals = async (
  userId: number
): Promise<{ [key: string]: AssociatedTerminalType }> => {
  const request = new GetUserAssociatedTerminalsRequest();
  request.userId = userId;
  const response = await terminalService.getUserAssociatedTerminalsList(
    request
  );
  if (!(response instanceof ServiceError)) {
    return response.reduce((accr, terminal) => {
      accr[terminal.id] = terminal;
      return accr;
    }, {} as { [key: string]: AssociatedTerminalType });
  }
  return {};
};

const getCommercialDriverLicense = async (
  userId: string
): Promise<AssignedDocuments[] | null> => {
  const documentTypesList = await getDocumentTypes();

  if (!documentTypesList) return null;
  const documentTypeItem = documentTypesList?.find(
    (item) => item.itemCode === 'COMMERCIAL_DRIVER_LICENSE'
  );

  if (!documentTypeItem?.id) return null;

  const documentRequest = new DocumentAssigments({
    objectTypesAndIds: `DRIVER:${userId}`,
    documentTypeIds: [documentTypeItem?.id],
    pageNumber: 1,
    pageSize: 1,
  });

  const documentResult = await documentService.getDocumentAssigments(
    documentRequest
  );

  if (!documentResult?.content?.length) return null;

  return documentResult.content.map((item) => {
    return new AssignedDocuments(item);
  });
};

//#endregion
