import {
  DispatchDestination,
  DispatchOrigin,
} from '../../../models/DTOs/Dispatch/Dispatch';
import useFilterStorage from '../../../services/storage';
import { ISelectedDriverDetails } from '../../../views/loadboards/constants/types';
import { ILoadboardFilters } from '../../../views/loadboards/loadTypes';
import { MenuItemData } from '../../NestedDropDown/definitions';

import {
  ERelativeDateRangeKey,
  IRelativeDateRangeOption,
} from '../../../ui-kit/components/RelativeDateRange';
import { defaultLoadboardGridFilters } from '../../../views/loadboards/LoadboardDataGrid/grid.config';
import { ELocationLevel, TLocationSelectValue } from '../../Ui/LocationSelect';
import { RelativeDateRangeUtils } from '../../Ui/RelativeDateRange/RelativeDateRange.utils';

const component = 'Loadsearch';
const stripId = 'Dispatch';

export interface IUseDispatchFindLoadData {
  origin: DispatchOrigin | null;
  destination: DispatchDestination | null;
  driverGroupId: string;
  startTime: string;
  endTime: string;
  driverId: string;
  groupName: string;
}

export const useDispatchFindLoad = () => {
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};

  const getFindLoadData = (): IUseDispatchFindLoadData | null => {
    return storageData[stripId];
  };
  const setFindLoadData = (filtersData: IUseDispatchFindLoadData | null) => {
    LStorageHook.updateStorage(stripId, filtersData);
  };

  const getPopulateFindLoadFilter = ({
    clearData,
    menuItemsData,
  }: {
    clearData: boolean;
    menuItemsData: MenuItemData;
  }): ISelectedDriverDetails | null => {
    const findLoadData: IUseDispatchFindLoadData | null = getFindLoadData();
    if (findLoadData?.driverGroupId) {
      if (clearData) {
        setFindLoadData(null);
      }
      const currentGroupMenu = menuItemsData?.items?.find(
        (item: MenuItemData) => item.uid === findLoadData.driverGroupId
      );
      const defaultWeeklyRevenue =
        currentGroupMenu?.items?.[0]?.value?.subValue?.defaultWeeklyRevenue ||
        0;
      const driverGoal =
        currentGroupMenu?.items?.[0]?.value?.value?.driverGoal || 0;

      const pickupLocation = findLoadData?.origin
        ? [findLoadData?.origin?.city, findLoadData?.origin?.state]
            .filter((str) => !!str)
            .join(', ')
        : 'Anywhere';
      const dropoffLocation = findLoadData?.destination
        ? [findLoadData?.destination?.city, findLoadData?.destination?.state]
            .filter((str) => !!str)
            .join(', ')
        : 'Anywhere';
      const gapData = {
        originCity: findLoadData?.origin?.city,
        originState: findLoadData?.origin?.state,
        originLat: findLoadData?.origin?.lat ?? findLoadData?.origin?.latitude,
        originLng: findLoadData?.origin?.lon ?? findLoadData?.origin?.longitude,
        destinationCity: findLoadData?.destination?.city,
        destinationState: findLoadData?.destination?.state,
        destinationLat:
          findLoadData?.destination?.lat ?? findLoadData?.destination?.latitude,
        destinationLon:
          findLoadData?.destination?.lon ??
          findLoadData?.destination?.longitude,
        gapStartTime: findLoadData?.startTime
          ? new Date(findLoadData?.startTime)
          : new Date(),
        gapEndTime: findLoadData?.endTime
          ? new Date(findLoadData?.endTime)
          : null,
        previousLoadId: null,
        nextLoadId: null,
        defaultWeeklyRevenue,
      };

      const driverDetails: ISelectedDriverDetails = {
        label: [pickupLocation, dropoffLocation].join(' -> '),
        value: {
          value: {
            driverName: findLoadData.groupName,
            driverGroupId: findLoadData.driverGroupId,
            driverId: [findLoadData.driverId],
            gapData: [gapData],
            defaultWeeklyRevenue,
            driverGoal,
          },
          subValue: gapData,
        },
        selectedLabel: findLoadData.groupName,
      };
      return driverDetails;
    }
    return null;
  };

  function createLocationSelectValue(
    city: string,
    state: string,
    lat: number | string,
    lng: number | string
  ): TLocationSelectValue {
    return {
      locationLevel: ELocationLevel.City,
      data: {
        address: [city, state].filter(Boolean).join(', '),
        city,
        center: {
          lat,
          lng,
        },
        state,
      },
    };
  }
  const toLoadboardDataGridFilter = (
    driverDetails: ISelectedDriverDetails
  ): Pick<
    ILoadboardFilters,
    'origins' | 'destinations' | 'pickupDateRanges'
  > => {
    const defaultFilter = {
      origins: defaultLoadboardGridFilters.origins,
      destinations: defaultLoadboardGridFilters.destinations,
      pickupDateRanges: null,
    };
    if (!driverDetails) return defaultFilter;
    const subValue = driverDetails?.value?.subValue;
    if (!subValue) return defaultFilter;
    const destinations = subValue.destinationState
      ? createLocationSelectValue(
          subValue.destinationCity,
          subValue.destinationState,
          subValue.destinationLat,
          subValue.destinationLon
        )
      : defaultFilter.destinations;

    const origins = subValue.originState
      ? createLocationSelectValue(
          subValue.originCity,
          subValue.originState,
          subValue.originLat,
          subValue.originLng
        )
      : defaultFilter.origins;

    const pickupDateRanges: IRelativeDateRangeOption =
      RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
        ERelativeDateRangeKey.PeriodTimeCustom
      );
    pickupDateRanges.dateRange = [
      subValue.gapStartTime ? new Date(subValue.gapStartTime) : null,
      subValue.gapEndTime ? new Date(subValue.gapEndTime) : null,
    ];
    return {
      origins: origins,
      destinations: destinations,
      pickupDateRanges: pickupDateRanges,
    };
  };

  return {
    getFindLoadData: getFindLoadData,
    setFindLoadData: setFindLoadData,
    getPopulateFindLoadFilter: getPopulateFindLoadFilter,
    toLoadboardDataGridFilter: toLoadboardDataGridFilter,
  };
};
