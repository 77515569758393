export const GanttBottomBarStyles = {
  background: 'rgba(0, 17, 34, 0.04)',
  padding: '6px 10px',
  borderTop: '1px solid rgba(0, 17, 34, 0.23)',
  borderRadius: '0 0 8px 8px',
};

export const GanttLegendButtonStyles = {
  height: '24px',
  border: '1px solid rgba(43, 100, 203, 0.5)',
  borderRadius: '6px',
  fontSize: '13px',
  textTransform: 'capitalize',
};

export const LegendPopupContainerStyles = {
  boxShadow:
    ' 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
  borderRadius: '12px',
  '.MuiPaper-root': {
    width: '200px',
    borderRadius: '12px',
  },
};

export const GanttLegendTitleStyles = {
  color: 'rgba(0, 17, 34, 0.6)',
  fontSize: '14px',
  fontWeight: '700',
};

export const GanttLegendHeaderContainer = {
  borderBottom: '1px solid rgba(0, 17, 34, 0.23)',
  padding: '16px',
};

export const GanttLegendBoxStyles = {
  width: '24px',
  height: '23px',
  borderRadius: '3px',
};

export const GanttLoaderStyles = {
  fontSize: '12px',
  marginLeft: '8px',
};
