import { GridColDefSelf } from '@/types';
import HyperLink from '@/common/Ui/HyperLink/HyperLink';
import { SecondaryPanelType } from '@/models/DTOs/Dispatch/Dispatch';
import { ESecondaryDetailsPanelType } from '@/views/dispatch2/constants/types';
import { EMyLoadDetailsPanelTabs } from '@/common/LoadTabPanel/constants/constants';
import { renderDateTime } from '@/utils/NameAndDateTimeGridField';
import { TimelineFormatter } from '@/ui-kit/TripPlan/utils/timelineV3/DetailedDataSection';
import { numberWithThousandSeparator } from '@/utils';

const getOriginOrDestinationData = (
  time: string | null,
  tz = '',
  nextStopDelay = 0
) => {
  if (!time) {
    return '';
  }
  if (nextStopDelay === 0) {
    return renderDateTime(time, tz);
  }
  return (
    <span style={{ color: 'red' }}>
      {renderDateTime(time, tz)} (
      {TimelineFormatter.minutesToTime(nextStopDelay)}
      {' Delay)'}
    </span>
  );
};

export const getColumns = (
  onLoadView: ((data: SecondaryPanelType) => void) | undefined,
  onTripView: ((data: SecondaryPanelType) => void) | undefined,
  onCustomerView: ((data: SecondaryPanelType) => void) | undefined
): GridColDefSelf[] => {
  return [
    {
      field: 'driverGroup',
      headerName: 'Driver',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        return params.value.name ?? '';
      },
      sortable: true,
    },
    {
      field: 'drivingStatus',
      headerName: 'Driving Status',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => params.value ?? '',
      sortable: true,
    },
    {
      field: 'tractor',
      headerName: 'Tractor',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => params.value?.name ?? '',
      sortable: true,
    },
    {
      field: 'trailer',
      headerName: 'Trailer',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => params.value?.name ?? '',
      sortable: true,
    },
    {
      field: 'driverLastLocation',
      headerName: 'Last known Location of Driver',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        return `${params.value.city}, ${params.value.state}`;
      },
      sortable: false,
    },
    {
      field: 'loads',
      headerName: 'Curr Load',
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        (params.value as { seqNumber: string; id: string }[])?.map(
          ({ seqNumber, id }, index) => (
            <>
              <HyperLink
                key={seqNumber}
                value={seqNumber}
                onClick={(event) => {
                  event?.stopPropagation();
                  onLoadView?.({
                    type: ESecondaryDetailsPanelType.LOAD,
                    id: id,
                    isGlobal: false,
                    defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
                  });
                }}
              />
              {index !== params.value.length - 1 && ', '}
            </>
          )
        ) ?? '',
      sortable: false,
    },
    {
      field: 'loadReferenceNumbers',
      headerName: 'Reference Number',
      flex: 1,
      minWidth: 120,
      sortable: false,
    },
    {
      field: 'customers',
      headerName: 'Curr Customer',
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        (params.value as { name: string; id: string }[])?.map(
          ({ name, id }, index) => (
            <>
              <HyperLink
                key={index}
                value={name}
                onClick={(event) => {
                  event?.stopPropagation();
                  onCustomerView?.({
                    type: ESecondaryDetailsPanelType.CUSTOMER,
                    id: id,
                    isGlobal: false,
                  });
                }}
              />
              {index !== params.value.length - 1 && ', '}
            </>
          )
        ) ?? '',
      sortable: false,
    },
    {
      field: 'trip',
      headerName: 'Curr Trip',
      flex: 1,
      minWidth: 120,
      isHyperLink: true,
      renderCell: (params) => {
        if (params.value?.seqNumber) {
          return (
            <HyperLink
              value={params.value.seqNumber.toString()}
              onClick={(event) => {
                event?.stopPropagation();
                onTripView?.({
                  type: ESecondaryDetailsPanelType.TRIP,
                  tripPrimaryId: params.value.id,
                  isGlobal: false,
                  id: '',
                });
              }}
            />
          );
        }
        return '';
      },
      sortable: true,
    },
    {
      field: 'origin',
      headerName: 'Curr Orig',
      flex: 1,
      minWidth: 120,
      sortable: true,
    },
    {
      field: 'originAppt',
      headerName: 'Curr Orig Appt',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const { originApointmentTime, originTimezone } = params.row;
        return getOriginOrDestinationData(originApointmentTime, originTimezone);
      },
      sortable: true,
    },
    {
      field: 'destination',
      headerName: 'Curr Dest',
      flex: 1,
      minWidth: 120,
      sortable: true,
    },
    {
      field: 'destinationAppt',
      headerName: 'Curr Dest Appt',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const { destinationApointmentTime, destinationTimezone } = params.row;
        return getOriginOrDestinationData(
          destinationApointmentTime,
          destinationTimezone
        );
      },
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Curr Trip Status',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => params.value ?? '',
      sortable: true,
    },
    {
      field: 'etaNextStop',
      headerName: 'ETA to next stop',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return getOriginOrDestinationData(
          params.value ?? '',
          '',
          params.row.nextStopDelay
        );
      },
      sortable: true,
    },
    {
      field: 'distanceToNextStop',
      headerName: 'Dist to Next Stop (mi)',
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        params.value ? numberWithThousandSeparator(params.value) : '',
      sortable: true,
    },
    {
      field: 'nextLoads',
      headerName: 'Next Load',
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        (params.value as { seqNumber: string; id: string }[])?.map(
          ({ seqNumber, id }, index) => (
            <>
              <HyperLink
                key={seqNumber}
                value={seqNumber}
                onClick={(event) => {
                  event?.stopPropagation();
                  onLoadView?.({
                    type: ESecondaryDetailsPanelType.LOAD,
                    id: id,
                    isGlobal: false,
                    defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
                  });
                }}
              />
              {index !== params.value.length - 1 && ', '}
            </>
          )
        ) ?? '',
      sortable: false,
    },
    {
      field: 'nextCustomers',
      headerName: 'Next Customer',
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        (params.value as { name: string; id: string }[])?.map(
          ({ name, id }, index) => (
            <>
              <HyperLink
                key={index}
                value={name}
                onClick={(event) => {
                  event?.stopPropagation();
                  onCustomerView?.({
                    type: ESecondaryDetailsPanelType.CUSTOMER,
                    id: id,
                    isGlobal: false,
                  });
                }}
              />
              {index !== params.value.length - 1 && ', '}
            </>
          )
        ) ?? '',
      sortable: false,
    },
    {
      field: 'nextTrip',
      headerName: 'Next Trip',
      flex: 1,
      minWidth: 120,
      isHyperLink: true,
      renderCell: (params) => {
        if (params.value?.seqNumber) {
          return (
            <HyperLink
              value={params.value.seqNumber}
              onClick={(event) => {
                event?.stopPropagation();
                onTripView?.({
                  type: ESecondaryDetailsPanelType.TRIP,
                  tripPrimaryId: params.value.id,
                  isGlobal: false,
                  id: '',
                });
              }}
            />
          );
        }
        return '';
      },
      sortable: true,
    },
    {
      field: 'nextOrigin',
      headerName: 'Next Origin',
      flex: 1,
      minWidth: 120,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: 'nextOriginAppt',
      headerName: 'Next Origin Appt',
      flex: 1,
      minWidth: 120,
      maxWidth: 150,
      renderCell: (params) => {
        const { nextOriginAppointmentTime, nextOriginTimezone } = params.row;
        return getOriginOrDestinationData(
          nextOriginAppointmentTime,
          nextOriginTimezone
        );
      },
      sortable: true,
    },
    {
      field: 'nextDestination',
      headerName: 'Next Destination',
      flex: 1,
      minWidth: 120,
      sortable: true,
    },
    {
      field: 'nextDestinationAppt',
      headerName: 'Next Destination Appt',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const { nextDestinationAppointmentTime, nextDestinationTimezone } =
          params.row;
        return getOriginOrDestinationData(
          nextDestinationAppointmentTime,
          nextDestinationTimezone
        );
      },
      sortable: true,
    },
  ];
};
