import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
  phoneNumberValidation,
} from '@/utils';
import * as yup from 'yup';
import { emailRegExp } from '@/validator/validationSchemas';

export const AlertActions = [
  {
    label: 'Edit',
    icon: EditIcon,
  },
  {
    label: 'Delete',
    icon: DeleteIcon,
  },
];

export const AddLoadAlertDefaultFormValues = {
  name: '',
  email: '',
  phoneData: null,
  loadNotifications: undefined,
  sendViaPhone: false,
  sendViaEmail: false,
};

export const AddEditLoadAlertFormValidationSchema = yup.object().shape({
  name: nullableStringYup().required('Name is required.'),
  description: nullableStringMaxValidation(50),
  loadNotifications: yup
    .array()
    .required('You must pick at least one option.')
    .min(1, 'You must pick at least one option.'),
  email: nullableStringMaxValidation(50)
    .email('Email is not valid.')
    .matches(emailRegExp, 'Email is not valid.')
    .when('sendViaEmail', {
      is: true, // When sendViaEmail is true, make email required
      then: (schema) => schema.required('Email is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  phoneData: yup
    .object()
    .when(['sendViaPhone'], {
      is: true,
      then: (schema) =>
        schema
          .required('Phone is required')
          .test('phone-required', 'Phone is required', function (value) {
            const { sendViaPhone } = this.parent;
            if (sendViaPhone && !value?.phone) {
              return this.createError({
                path: 'phoneData.phone',
                message: 'Phone is required',
              });
            }
            return true;
          }),
      otherwise: (schema) => schema.notRequired(),
    })
    .shape(
      {
        phone: phoneNumberValidation,
        extension: nullableNumberYup().max(
          99999,
          'Extension should have 5 or less characters.'
        ),
      },
      [['phone', 'phone']]
    )
    .nullable(true),
});
