import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import { tooltipBodyContentStyles } from '../styles';
import { ColumnTooltipType } from './types';
import { EVENT_STATUS } from '../constants/puck.constant';
import {
  dateDiffInHrsMin,
  formatAMPM,
  formatDateToDayAndMonth,
} from './services/ganttchart.service';
import { formatDateToHHMM, getHoursIn24Format } from '../services';
import { DelayIcon } from '../static';

export function RedDTIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      fill="none"
      viewBox="0 0 20 15"
    >
      <path
        fill="#D32F2F"
        d="M9.57 5.98c.36.36.54.86.54 1.51s-.18 1.15-.54 1.5c-.36.35-.86.53-1.51.53h-.81V5.44h.81c.65 0 1.15.18 1.51.54zM20 2v11c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h16c1.11 0 2 .89 2 2zM2.02 3.73l.2 4.79h1.45l.2-4.79H2.02zm1.96 6.43c0-.27-.1-.49-.29-.67-.19-.18-.43-.27-.72-.27-.3 0-.55.09-.74.27-.19.18-.28.4-.28.67 0 .26.09.48.28.66.19.17.44.26.74.26.29 0 .53-.09.72-.26.2-.18.29-.4.29-.66zm7.87-2.67c0-.69-.15-1.3-.46-1.83-.3-.53-.73-.95-1.28-1.24-.55-.29-1.2-.44-1.94-.44H5.54V11h2.63c.73 0 1.38-.15 1.93-.44.56-.29.99-.71 1.29-1.24.31-.54.46-1.15.46-1.83zm6-3.51h-5.43v1.37h1.86V11h1.71V5.35h1.86V3.98z"
      />
    </svg>
  );
}

export const presets = {
  id: 'dayAndWeek',
  name: 'Day and week view',
  tickWidth: 250,
  timeResolution: {
    unit: 'day',
    increment: 1,
  },
  headers: [
    {
      unit: 'day',
      dateFormat: 'dd M/D',
      renderer: (startDate: any, endDate: any, headerConfig: any) => {
        headerConfig.headerCellCls = 'gantt-preset-header';
        return `${formatDateToDayAndMonth(startDate)}`;
      },
    },
  ],
};

export const checkBoxEvent = {
  id: 'checkbox-select',
  name: 'Add Load',
  iconCls: 'b-fa b-fa-code',
  cls: 'checkbox',
  status: 'CHECKBOX',
};

const renderTooltipContentTemplate = (
  data: any,
  tooltipContent: ColumnTooltipType | undefined
) =>
  tooltipContent?.content?.length ? (
    tooltipContent?.content?.map((content) =>
      content.element ? (
        <Grid item xs={12} ml={1}>
          {content.element}
        </Grid>
      ) : (
        <>
          <Grid item xs={5} mt={1} sx={content.containerStyles}>
            <Typography
              variant="subtitle2"
              sx={{
                ml: 1,
                ...tooltipBodyContentStyles,
                ...content.keyStyles,
              }}
            >
              {content?.key}
            </Typography>
          </Grid>
          <Grid
            mt={1}
            item
            xs={7}
            textAlign="end"
            display="block"
            sx={content.containerStyles}
          >
            <Typography
              variant="subtitle2"
              sx={{
                ...tooltipBodyContentStyles,
                mt: 0,
                ...content.valueStyles,
              }}
              fontWeight="bold"
            >
              {content.value}
            </Typography>
            {content.subValue && (
              <Typography
                variant="caption"
                sx={{
                  ...tooltipBodyContentStyles,
                  ...content.subValueStyles,
                }}
              >
                {content.subValue}
              </Typography>
            )}
          </Grid>
        </>
      )
    )
  ) : (
    <>
      <Grid item xs={6}>
        <Typography
          variant="subtitle2"
          sx={{ ml: 1, ...tooltipBodyContentStyles }}
        >
          Origin
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="end" pr={1}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={tooltipBodyContentStyles}
        >
          {data?.originText}
        </Typography>
        <Typography variant="caption" sx={{ ...tooltipBodyContentStyles }}>
          {`${formatDateToDayAndMonth(data.startDate, false)}, ${formatAMPM(
            data.startDate
          )}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant="subtitle2"
          sx={{ ml: 1, mt: 0.5, ...tooltipBodyContentStyles }}
        >
          Destination
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="end" mt={0.5} pr={1}>
        <Typography
          variant="subtitle2"
          sx={tooltipBodyContentStyles}
          fontWeight="bold"
        >
          {data?.destinationText}
        </Typography>
        <Typography variant="caption" sx={{ ...tooltipBodyContentStyles }}>
          {`${formatDateToDayAndMonth(data.endDate, false)}, ${formatAMPM(
            data.endDate
          )}`}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={0.5}>
        <Typography
          variant="subtitle2"
          sx={{ ml: 1, ...tooltipBodyContentStyles }}
        >
          Trip Time
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="end" pr={1} mt={0.5}>
        <Typography
          variant="subtitle2"
          sx={{ ...tooltipBodyContentStyles }}
          fontWeight="bold"
        >
          {`${dateDiffInHrsMin(data?.startDate, data?.endDate)}`}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={0.5}>
        <Typography
          variant="subtitle2"
          sx={{ ml: 1, ...tooltipBodyContentStyles }}
        >
          Total Miles
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="end" mt={0.5} pr={1}>
        <Typography
          variant="subtitle2"
          sx={{ ...tooltipBodyContentStyles }}
          fontWeight="bold"
        >
          {data.miles ? `${data.miles} mi` : ''}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={1}>
        <Typography
          variant="subtitle2"
          sx={{ ml: 1, ...tooltipBodyContentStyles }}
        >
          Revenue
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="end" mt={0.5} pr={1}>
        <Typography
          variant="subtitle2"
          sx={{ mr: 1, ...tooltipBodyContentStyles }}
          fontWeight="bold"
        >
          {data.revenue ? `$ ${data.revenue}` : ''}
        </Typography>
      </Grid>
    </>
  );

export const eventToolTipBody = (
  data: any,
  eventTooltipContent: ColumnTooltipType | undefined
) => ({
  popupContent: eventTooltipContent?.bodyTemplate || (
    <Grid
      container
      sx={{
        width: '100%',
        pb: 2,
        pt: 1,
        maxWidth: '340px',
        ...eventTooltipContent?.containerStyles,
      }}
    >
      {renderTooltipContentTemplate(data, eventTooltipContent)}
    </Grid>
  ),
  openOnHover: true,
  headerIcon: eventTooltipContent?.headerIcon || (
    <ArrowUpwardIcon fontSize="large" />
  ),
  headerTitle:
    eventTooltipContent?.headerTitle ?? data?.headerTitle ?? data?.originText,
  headerSubheading:
    eventTooltipContent?.headerSubheading ??
    data?.headerSubheading ??
    data?.status,
  isFooterRequired: data?.status === EVENT_STATUS.DETAINED,
  footerIcon: eventTooltipContent?.footerIcon ?? <RedDTIcon />,
  headerTemplate: eventTooltipContent?.headerTemplate,
  headerContainerStyles: eventTooltipContent?.headerContainerStyles,
  footerContent:
    data?.status === EVENT_STATUS.DETAINED
      ? eventTooltipContent?.footerContent ?? 'Detention Detected!'
      : '',
  headerTitleStyles: eventTooltipContent?.headerTitleStyles,
  headerSubheadingStyles: eventTooltipContent?.headerSubheadingStyles,
  contentContainerStyles: eventTooltipContent?.contentContainerStyles,
  footerContainerStyles: eventTooltipContent?.footerContainerStyles,
});

export const gapToolTipBody = (
  data: any,
  eventTooltipContent: ColumnTooltipType | undefined
) => ({
  popupContent: eventTooltipContent?.bodyTemplate || (
    <Grid
      container
      sx={{
        width: '100%',
        pb: 2,
        pt: 1,
        maxWidth: '340px',
        ...eventTooltipContent?.containerStyles,
      }}
    >
      {renderTooltipContentTemplate(data, eventTooltipContent)}
    </Grid>
  ),
  openOnHover: true,
  headerIcon: eventTooltipContent?.headerIcon || (
    <AccessTimeIcon fontSize="large" />
  ),
  headerTitle: eventTooltipContent?.headerTitle || data?.name,
  headerSubheading: eventTooltipContent?.headerSubheading || data?.originText,
  headerContainerStyles: eventTooltipContent?.headerContainerStyles || {
    background: '#1F2E3D',
  },
  headerTemplate: eventTooltipContent?.headerTemplate,
  isFooterRequired: false,
  headerTitleStyles: eventTooltipContent?.headerTitleStyles,
  headerSubheadingStyles: eventTooltipContent?.headerSubheadingStyles,
  contentContainerStyles: eventTooltipContent?.contentContainerStyles,
});

export const getColumnToolTipProps = (
  data: any,
  columnTooltipContent: ColumnTooltipType | undefined
) => ({
  popupContent: columnTooltipContent?.bodyTemplate || (
    <Grid
      container
      sx={{
        minWidth: '200px',
        width: '100%',
        pb: 2,
        pt: 1,
        maxWidth: '340px',
        ...columnTooltipContent?.containerStyles,
      }}
    >
      {columnTooltipContent?.content?.map((content) =>
        content.element ? (
          <Grid item xs={12} ml={1}>
            {content.element}
          </Grid>
        ) : (
          <>
            <Grid item xs={5}>
              <Typography
                variant="subtitle2"
                sx={{
                  ml: 1,
                  ...tooltipBodyContentStyles,
                  ...content.keyStyles,
                }}
              >
                {content?.key}
              </Typography>
            </Grid>
            <Grid item xs={7} textAlign="end" display="block">
              <Typography
                variant="subtitle2"
                sx={{ mt: 0, ...content.valueStyles }}
                fontWeight="bold"
              >
                {content.value}
              </Typography>
              {content.subValue && (
                <Typography
                  variant="caption"
                  sx={{
                    ...tooltipBodyContentStyles,
                    ...content.subValueStyles,
                  }}
                >
                  {content.subValue}
                </Typography>
              )}
            </Grid>
          </>
        )
      )}
    </Grid>
  ),
  headerIcon: columnTooltipContent?.headerIcon || (
    <Avatar sx={{}}>{data?.name?.split('')[0]}</Avatar>
  ),
  headerTitle: columnTooltipContent?.headerTitle || data?.name,
  headerSubheading: columnTooltipContent?.headerSubheading || data?.subHeading,
  isFooterRequired: false,
  headerContainerStyles: columnTooltipContent?.headerContainerStyles || {
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #2196F3',
  },
  headerTemplate: columnTooltipContent?.headerTemplate,
  headerTitleStyles: columnTooltipContent?.headerTitleStyles,
  headerSubheadingStyles: columnTooltipContent?.headerSubheadingStyles,
  contentContainerStyles: columnTooltipContent?.contentContainerStyles,
  footerContainerStyles: columnTooltipContent?.footerContainerStyles,
});

export const timeoffToolTipBody = (
  data: any,
  timeoffToolTipContent: ColumnTooltipType | undefined
) => ({
  popupContent: timeoffToolTipContent?.bodyTemplate || (
    <Grid
      container
      spacing={2}
      sx={{
        width: '100%',
        pb: 2,
        pr: 1,
        ...timeoffToolTipContent?.containerStyles,
      }}
    >
      {renderTooltipContentTemplate(data, timeoffToolTipContent)}
    </Grid>
  ),
  openOnHover: true,
  headerIcon: timeoffToolTipContent?.headerIcon || (
    <BedOutlinedIcon fontSize="large" />
  ),
  headerTitle: timeoffToolTipContent?.headerTitle || data?.name,
  headerSubheading: timeoffToolTipContent?.headerSubheading || '',
  isFooterRequired: timeoffToolTipContent?.isFooterRequired || false,
  headerContainerStyles: timeoffToolTipContent?.headerContainerStyles || {
    background: '#1F2E3D',
  },
  headerTemplate: timeoffToolTipContent?.headerTemplate,
  headerTitleStyles: timeoffToolTipContent?.headerTitleStyles,
  headerSubheadingStyles: timeoffToolTipContent?.headerSubheadingStyles,
  contentContainerStyles: timeoffToolTipContent?.contentContainerStyles,
  footerIcon: timeoffToolTipContent?.footerIcon,
  footerContent: timeoffToolTipContent?.footerContent || '',
  footerContainerStyles: timeoffToolTipContent?.footerContainerStyles,
});
export const timeRangeStyle = `
background-color: #BDBDBD;
color: #001122;
opacity: 0.8;  
border: 2px solid #BDBDBD;
text-align: center;
align-items: center;
font-size: 16px;
background: #BDBDBD;
border: none!important;
background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 4px,
    transparent 1px,
    #BDBDBD 7px
  ),
  linear-gradient(
    to bottom,
    transparent,
    transparent
  )
`;

export const events = [
  {
    id: 1,
    resourceId: 'r1',
    name: 'LoadId: GR1',
    startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
    status: 'Enroute to 1/2 stop',
    isDelayed: true,
    isTripSelect: true,
    // puckContainerColor: 'red',
    // puckBackgroundColor: 'darkgrey',
    showTextOnHover: true,
    showPuckOnHover: false,
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    puckInnerElement: null,
    delayedIcon: <DelayIcon />,
    warningsCount: 2,
    // middleText: '400 mi | 1d 15h',
    miles: '300',
    travelTimeDisplay: '1d 2h',
    origin: {
      city: 'Clinton city',
      state: 'MS',
    },
    destination: {
      city: 'Elkhart',
      state: 'IN',
    },
  },
  {
    id: 2,
    resourceId: 'r1',
    name: 'Add Load',
    startDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
    cls: 'add-load',
    status: 'NEED_LOAD',
    withDownVote: false,
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    // isDottedPuck: true,
    // middleText: '400 mi | 1d 15h',
    miles: null,
    travelTimeDisplay: '1d 2h',
    eventColor: 'inherit',
    origin: {
      city: 'Clinton city',
      state: 'MS',
    },
    destination: {
      city: 'Elkhart',
      state: 'IN',
    },
  },
  {
    id: 3,
    resourceId: 'r2',
    name: 'Conference call',
    puckBackgroundColor: '#0c693e',
    status: 'COMPLETED',
    startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    textColor: '#FFF',
    // onHold: true,
    // warningsCount: 2,
    // isDelayed: true,
    iconCls: 'b-fa b-fa-phone',
    // status: 'COMPLETED',
    origin: {
      city: 'Clinton city',
      state: 'MS',
    },
    destination: {
      city: 'Elkhart',
      state: 'IN',
    },
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    puckInnerElement: null,
    middleText: '400 mi | 1d 15h',
  },
  {
    id: 4,
    resourceId: 'r2',
    name: 'Conference call',

    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-phone',
    status: 'DEADHEAD',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    middleText: '400 mi | 1d 15h',
    outerBoxStyle: {
      marginTop: '8px',
    },
    origin: {
      city: 'Clinton city',
      state: 'MS',
    },
    destination: {
      city: 'Elkhart',
      state: 'IN',
    },
  },
  {
    id: 5,
    resourceId: 'r5',
    name: 'Empty load',

    startDate: new Date(new Date().getTime() - 4 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    iconCls: 'b-fa b-fa-phone',
    status: 'PLANNED',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    middleText: '400 mi | 1d 15h',
    origin: {
      city: 'Pekwachnamaykoskwaskwaypinwanik Lake',
      state: 'MS',
    },
    destination: {
      city: 'Pekwachnamaykoskwaskwaypinwanik Lake',
      state: 'IN',
    },
  },
  {
    id: 6,
    resourceId: 'r4',
    name: 'Mgl',

    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 0.2 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-phone',
    status: 'upcoming',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    middleText: '400 mi | 1d 15h',
    origin: {
      city: 'Clinton city ',
      state: 'MS',
    },
    destination: {
      city: 'Elkhart city ',
      state: 'IN',
    },
  },
  {
    id: 7,
    resourceId: 'r3',
    name: 'Add Load',

    startDate: new Date(new Date().getTime() - 0.5 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    cls: 'add-load',
    // status: null,
    type: 'EMPTY',
    showPuckOnHover: true,
  },
  {
    id: 8,
    resourceId: 'r3',
    name: 'Meeting',

    startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() - 1 * 22 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-calendar',
    status: 'ASSIGNED',
    origin: {
      city: 'Clinton city city',
      state: 'MS',
    },
    destination: {
      city: 'Elkhart',
      state: 'IN',
    },
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    middleText: '400 mi | 1d 15h',
  },
  {
    id: 9,
    resourceId: 'r3',
    name: 'Scrum',

    startDate: new Date(new Date().getTime() + 1 * 1 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 2 * 19.7 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-comments',
    status: 'upcoming',
    origin: {
      city: 'Clinton city city',
      state: 'MS',
    },
    destination: {
      city: 'Elkhart city city',
      state: 'IN',
    },
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    middleText: '400 mi | 1d 15h 24 min',
  },
  {
    id: 10,
    resourceId: 'r4',
    name: 'Use cases',

    startDate: new Date(new Date().getTime() - 2.1 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() - 1 * 1 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-users',
    status: 'RELAY_START',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    middleText: '400 mi | 1d 15h',
    origin: {
      city: 'Kleinfeltersville',
      state: 'MS',
    },
    destination: {
      city: 'Kleinfeltersville',
      state: 'IN',
    },
  },
  {
    id: 101,
    resourceId: 'r6',
    name: 'Lisa 1',
    startDate: new Date(new Date().getTime() - 2.7 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() - 1 * 1 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-users',
    status: 'RELAY_START',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    middleText: '400 mi | 1d 15h',
    origin: {
      city: 'Kleinfeltersville',
      state: 'MS',
    },
    destination: {
      city: 'Kleinfeltersville',
      state: 'IN',
    },
  },
];
export const events2 = [
  {
    id: 11,
    resourceId: 'r5',
    timeRangeColor: 'red',
    name: 'Golf',
    startDate: new Date(new Date().getTime() + 1 * 1 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 2 * 12 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-golf-ball',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
  },
  {
    id: 12,
    resourceId: 'r6',
    name: 'Conference call',
    startDate: new Date(new Date().getTime() - 1 * 23 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-phone',
    status: 'DETAINED',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
  },
  {
    id: 13,
    resourceId: 'r1',
    name: 'Conference call',
    startDate: new Date(new Date().getTime() + 2 * 25 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-phone',
    status: 'upcoming',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
  },
  {
    id: 14,
    resourceId: 'r2',
    name: 'Conference call',
    originText: 'Clinton, MS',
    destinationText: 'Elkhart, IN',
    startDate: new Date(new Date().getTime() + 1 * 25 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-phone',
    status: 'upcoming',
    originTimelineContainerStyles: {
      position: 'fixed',
      top: '-14px',
    },
    outerBoxStyle: {
      marginTop: '8px',
    },
    // puckStyles: {
    // 	marginTop: '8px',
    // },
  },
  {
    id: 15,
    resourceId: 'r4',
    name: 'LoadId: GR1',
    originText: 'Clinton, MS',
    destinationText: 'Elkhart, IN',
    startDate: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-code',
    status: 'COMPLETED',
  },
  {
    id: 16,
    resourceId: 'r15',
    name: 'LoadId: GR1',
    originText: 'Clinton, MS',
    destinationText: 'Elkhart, IN',
    startDate: new Date(2022, 6, 26, 10),
    endDate: new Date(2022, 6, 27, 12),
    iconCls: 'b-fa b-fa-code',
  },
];
export const resources = [
  {
    id: 'r1',
    name: 'Arcady Joe Johnso & William Ray Jonas Jones',
    role: 'test1',
    origin: {
      city: 'Elkhart, IN',
    },
    miles: '100 mi',
    tractorId: '54328',
  },
  {
    id: 'r2',
    name: 'Aa',
    role: 'test2',
    origin: {
      city: 'Elkhart, IN',
    },
    miles: '100 mi',
    tractorId: '54328',
  },
  {
    id: 'r3',
    name: 'Henrik',
    role: 'test3',
    miles: '100 mi',
    tractorId: '543281',
  },
  {
    id: 'r4',
    name: 'Linda',
    role: 'Gulf relay',
    miles: '100 mi',
    tractorId: '54322',
  },
  { id: 'r5', name: 'Celia', role: 'LTL', miles: '100 mi', tractorId: '54322' },
  { id: 'r6', name: 'Lisa', role: 'LTL', miles: '100 mi', tractorId: '543284' },
];
export const resources1 = [
  {
    id: 'r7',
    name: 'Driver1',
    role: 'LTL',
    val: 'ss',
  },
  {
    id: '8',
    name: 'Driver2',
    role: 'LTL',
    val: 'ss',
  },
  // { id: 'r9', name: 'Driver3', role: 'Gulf relay' },
  // { id: 'r10', name: 'Driver14', role: 'Gulf relay' },
  // { id: 'r11', name: 'Driver5', role: 'LTL' },
  // { id: 'r12', name: 'Driver6', role: 'LTL' },
];

export const resourceTimeRanges = [
  {
    id: 1,
    startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
    name: 'Vacation/Time Off',
    style: timeRangeStyle,
    resourceId: 'r2',
  },
  // {
  //   id: 2,
  //   startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
  //   endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
  //   name: 'Impicit Break',
  //   style: timeRangeStyle,
  //   resourceId: 'r1',
  // },
  // {
  //   id: 3,
  //   startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
  //   endDate: new Date(new Date().getTime() - 1 * 23 * 60 * 60 * 1000),
  //   name: 'Impicit Break',
  //   style: timeRangeStyle,
  //   resourceId: 'r6',
  // },
];
export const unAssignedLoads1 = [
  {
    id: 'unassigned1001',
    originId: 2,
    resourceId: '',
    name: 'Unassigned load1',
    originText: 'Clinton, MS',
    destinationText: 'Elkhart, IN',
    startDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-code',
    status: 'AVAILABLE',
  },
  {
    id: 'unassigned1010',
    originId: 2,
    resourceId: '',
    name: 'Unassigned Load2',
    originText: 'Clinton, MS',
    destinationText: 'Elkhart, IN',
    startDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
    endDate: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
    iconCls: 'b-fa b-fa-code',
    status: 'AVAILABLE',
  },
];
export const unAssignedLoads2 = [
  {
    id: 'unassigned1011',
    originId: 7,
    resourceId: '',
    name: 'Unassigned load1',
    originText: 'Clinton, MS',
    destinationText: 'Elkhart, IN',
    startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    iconCls: 'b-fa b-fa-code',
    status: 'AVAILABLE',
  },
  {
    id: 'unassigned1111',
    originId: 7,
    resourceId: '',
    originText: 'Clinton, MS',
    destinationText: 'Elkhart, IN',
    name: 'Unassigned Load2',
    startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    iconCls: 'b-fa b-fa-code',
    status: 'AVAILABLE',
  },
];

const ganttHeaderHourRenderer = ({ startDate, headerConfig }: any) => {
  headerConfig.headerCellCls = 'dispatch-gantt-day-header';
  if (
    new Date(startDate).getMinutes() == 0 &&
    new Date(startDate).getHours() == 0
  ) {
    return `${formatDateToDayAndMonth(startDate)}`;
  }
  return `${formatDateToHHMM(startDate)}`;
};

export const defaultHourAndDayPresets = [
  // {
  //   id: 'hourAndDay4',
  //   name: 'Hour and day view',
  //   tickWidth: 47,
  //   timeResolution: {
  //     unit: 'day',
  //     increment: 1,
  //   },
  //   headers: [
  //     {
  //       unit: 'day',
  //       increment: 1,
  //       renderer: (
  //         startDate: any,
  //         endDate: any,
  //         headerConfig: any,
  //         cellIdx: any
  //       ) => ganttHeaderHourRenderer({ startDate, headerConfig }),
  //     },
  //     {
  //       unit: 'hours',
  //       increment: 12,
  //       renderer: (
  //         startDate: any,
  //         endDate: any,
  //         headerConfig: any,
  //         cellIdx: any
  //       ) => {
  //         headerConfig.headerCellCls = 'dispatch-gantt-hour-header';
  //         return getHoursIn24Format(startDate);
  //       },
  //     },
  //   ],
  // },
  {
    id: 'hourAndDay3',
    name: 'Hour and day view',
    tickWidth: 50,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'day',
        increment: 1,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeaderHourRenderer({ startDate, headerConfig }),
      },
      {
        unit: 'hours',
        increment: 8,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => {
          headerConfig.headerCellCls = 'dispatch-gantt-hour-header';
          return getHoursIn24Format(startDate);
        },
      },
    ],
  },
  {
    id: 'hourAndDay2',
    name: 'Hour and day view',
    tickWidth: 55,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'day',
        increment: 1,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeaderHourRenderer({ startDate, headerConfig }),
      },
      {
        unit: 'hours',
        increment: 6,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => {
          headerConfig.headerCellCls = 'dispatch-gantt-hour-header';
          return getHoursIn24Format(startDate);
        },
      },
    ],
  },
  {
    id: 'hourAndDay',
    name: 'Hour and day view',
    tickWidth: 55,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'day',
        increment: 1,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeaderHourRenderer({ startDate, headerConfig }),
      },
      {
        unit: 'hours',
        increment: 4,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => {
          headerConfig.headerCellCls = 'dispatch-gantt-hour-header';
          return getHoursIn24Format(startDate);
        },
      },
    ],
  },
];
