import { QueryParams } from '../commonMixed/QueryParams';

export enum NOTE_TYPE {
  INTERNAL = 'INTERNAL',
  ONHOLD = 'ONHOLD',
  RELEASE = 'RELEASE',
}

export const NOTE_TYPE_DISPLAY = {
  INTERNAL: 'Internal',
  ONHOLD: 'Hold',
  RELEASE: 'Release',
};

export class GetNotesDTO extends QueryParams {
  loadId!: string;
  organizationId!: number;
  constructor() {
    super();
  }
}

export class CreateNotesDTO extends QueryParams {
  loadId!: string;
  noteDesc!: string;
  notesType!: NOTE_TYPE;
  organizationId!: number;
  shareWithDriver!: boolean;
}

export class DeleteNotesDTO extends QueryParams {
  organizationId!: number;
  noteId!: string;
  documentId!: string;
}

export class EditNotesDTO extends QueryParams {
  noteId!: string;
  userId!: number;
  loadId!: string;
  organizationId!: number;
  notesType!: string;
  noteDesc!: string;
  editable!: boolean;
  deletable!: boolean;
  stopId!: number;
  shareWithDriver!: boolean;
  deleteDocument!: boolean;
}

export class NotesListRes extends QueryParams {
  noteId!: string;
  userId!: number;
  loadId!: string;
  organizationId!: number;
  roleCode!: string;
  notesType!: string;
  noteDesc!: string;
  attachments!: any;
  createdBy!: string;
  createDate!: string;
  source!: string;
  updatedBy!: string;
  updateDate!: string;
  editMode!: boolean;
  editable!: boolean;
  deletable!: boolean;
  shareWithDriver!: boolean;
  deleteDocument!: boolean;
  stopId!: number;
}

export interface EntityNoteItem {
  id: string;
  entityType: string;
  entityId: string;
  attachments: { documentId: string; fileName: string }[] | null;
  roleCode: string;
  note: string;
  updateDate: string;
  createDate: string;
  editable: boolean;
  deletable: boolean;
  creatorId: string;
  creatorName: string;
}
