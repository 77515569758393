const commonTextStyles = {
  lineHeight: '140%',
  letterSpacing: '0.15px',
  fontFamily: 'Poppins',
};

export const PuckTooltipConatinerStyles = {
  background: '#FFF',
  borderRadius: '8px 8px 0px 0px',
  width: '330px',
  // 'overflow-y': 'auto',
  // maxHeight: '620px',
};

export const tooltipTitleStyle = {
  fontWeight: 700,
  fontSize: '16px',
  color: '#000000',
  maxWidth: '180px',
  'word-break': 'break-word',
  ...commonTextStyles,
};

export const headerContainerStyles = {
  padding: '16px 16px 0px',
};

export const subtitleTextStyles = {
  color: '#5AC7F8',
  fontWeight: '400',
  fontSize: '10px',
  ...commonTextStyles,
};

export const subtitleContainerStyle = {
  alignItems: 'center',
};

export const textDividerLineStyle = {
  border: '1px solid rgba(102, 158, 255, 0.5)',
  margin: '4px 0',
};

export const tooltipBodyContainerStyle = {
  background: 'rgba(43, 100, 203, 0.08)',
  // borderRadius: '8px 8px 0px 0px',
  gap: '8px',
  alignItems: 'center',
  padding: '16px',
};

export const tooltipTripTextStyles = {
  ...commonTextStyles,
  fontWeight: 700,
  fontSize: '10px',
  marginTop: '2px',
  color: 'rgba(0, 17, 34, 0.6)',
  'word-break': 'break-word',
};

export const tooltipTripBoldTextStyles = {
  ...tooltipTripTextStyles,
  color: 'rgba(4, 0, 34, 0.75)',
  fontWeight: '700',
  fontSize: '12px',
  marginTop: 0,
  ...commonTextStyles,
};

export const tooltipTripKeyStyles = {
  ...commonTextStyles,
  fontWeight: 400,
  fontSize: '10px',
  marginTop: '2px',
  color: 'rgba(0, 17, 34, 0.6)',
  paddingRight: '3px',
  width: 'max-content',
};

export const tooltipTripContainerStyles = {
  alignItems: 'center',
};

export const tooltipHighlightedContainerStyles = {
  // background:
  //   'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
  borderRadius: '8px',
  gap: '15px',
  padding: '16px',
  display: 'block',
  justifyContent: 'center',
};

export const highlightedDetailsPrimaryTextStyles = {
  ...commonTextStyles,
  fontWeight: '700',
  fontSize: '14px',
  color: '#2B64CB',
  'word-break': 'break-word',
};
export const highlightedDetailsSecondaryTextStyles = {
  ...commonTextStyles,
  fontSize: '10px',
  fontWeight: 400,
  color: 'rgba(4, 0, 34, 0.75)',
  marginTop: '3px',
  'word-break': 'break-word',
};

export const tooltipFooterContainerStyles = {
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FFA726',
  gap: '8px',
  padding: '10px 5px 5px 12px',
  borderRadius: '0 0 8px 8px',
  // maxHeight: '120px',
};

export const footerTextStyles = {
  ...commonTextStyles,
  fontWeight: 400,
  fontSize: '10px',
  color: 'rgba(4, 0, 34, 0.75)',
  marginLeft: '5px',
};

export const footerHyperLinkStyles = {
  ...footerTextStyles,
  color: 'rgb(43, 100, 203)',
  textDecoration: 'underline',
  marginLeft: 'auto',
  paddingRight: '8px',
  cursor: 'pointer',
  width: '65px',
  textAlign: 'end',
};

export const TripPathIconContainerStyles = {
  position: 'absolute',
  top: '-12px',
  left: '10px',
};

export const footerShowMoreTextStyles = {
  ...commonTextStyles,
  fontSize: '10px',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  maxWidth: '80px',
  margin: 'auto',
  justifyContent: 'center',
  color: '#2B64CB',
};

export const subTitleKeyStyles = {
  ...commonTextStyles,
  color: 'rgba(4, 0, 34, 0.75)',
  fontSize: '10px',
  paddingTop: '2px',
  width: 'max-content',
};

export const subtitleValueStyles = {
  ...commonTextStyles,
  fontSize: '12px',
  color: '#2B64CB',
  'text-decoration-line': 'underline',
  fontWeight: 700,
  cursor: 'pointer',
  paddingLeft: '1px',
};

export const subtitleAdditionalTextStyles = {
  ...commonTextStyles,
  color: 'rgba(4, 0, 34, 0.75)',
  fontSize: '12px',
  fontWeight: 700,
};

export const subtitlesContainerStyles = {
  display: 'flex',
  gap: '4px',
  // alignItems: 'center',
  marginTop: '8px',
  // marginBottom: '6px',
};

export const tripKeyStyles = {
  ...commonTextStyles,
  fontSize: '10px',
  color: 'rgba(4, 0, 34, 0.75)',
  hyphens: 'auto',
  marginTop: '2px',
};

export const tooltipTitleContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // padding: '16px',
  gap: '2px',
};

export const subtitleSubValueTextStyles = {
  ...commonTextStyles,
  fontSize: '10px',
  color: 'rgba(0, 17, 34, 0.6)',
};

export const HOSTitleStyles = {
  ...commonTextStyles,
  color: 'rgba(4, 0, 34, 0.75)',
  fontSize: '10px',
  paddingBottom: '8px',
};

export const TooltipActionContainerStyles = {
  padding: '8px 16px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
};

export const TooltipActionStyles = {
  borderRadius: '6px',
  height: '28px',
  fontWeight: 500,
  textTransform: 'capitalize',
  '> p': {
    fontSize: '13px',
    textTransform: 'capitalize',
    lineHeight: '160%',
    fontFamily: 'Poppins',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 500,
  },
};

export const weekTextStyles = {
  ...commonTextStyles,
  color: 'rgba(0, 17, 34, 0.35)',
  fontSize: '10px',
};

export const TooltipHosKpiBlock = {
  width: 160,
};
export const ScrollableContainerStyles = {
  maxHeight: '580px',
  overflowY: 'auto',
};

export const TooltipStatusTextStyles = {
  fontSize: '13px',
  'text-transform': 'capitalize',
  letterSpacing: '0.16px',
  fontWeight: 400,
  lineHeight: '140%',
};
