import Grid from '@mui/material/Grid';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactFormModule } from '../../../common/PendoClassIDs/constants';
import FormPhoneField from '../../../common/Ui/FormPhoneField';
import Switch from '../../../common/Ui/Switcher';
import TextField from '../../../common/Ui/TextField/TextField';

import Checkbox from '../../../common/Ui/Checkbox';
import { MultipleAutocompleteForm } from '../../../common/Ui/MultipleAutocomplete';
import { notificationsOption } from '../constants';

export default function AddContactForm() {
  const { control, getValues, watch, setValue } = useFormContext();
  const isPrimary: boolean = useMemo(() => getValues('isPrimary'), []);
  const { t, ready } = useTranslation();
  const [alerts] = watch([`alerts`]);
  const handleNotificationChange = (data) => {
    const valueArray = data.map((item) => item.name);
    setValue(`alerts`, valueArray);
  };
  if (ready) {
    return (
      <Grid spacing={1} container>
        <TextField
          id={contactFormModule + 'Name'}
          control={control}
          name="fullName"
          label={t('name')}
          sizes={{
            xs: 12,
          }}
          required
        />
        <FormPhoneField
          control={control}
          name="phoneData"
          label={t('phone')}
          sizes={{ xs: 12 }}
        />
        <TextField
          id={contactFormModule + 'Email'}
          control={control}
          name="email"
          label={t('email')}
          sizes={{ xs: 12 }}
        />
        <TextField
          id={contactFormModule + 'Description'}
          control={control}
          name="description"
          label={t('description')}
          sizes={{ xs: 12 }}
        />
        <span style={{ marginLeft: 10 }}>
          <Switch
            id={contactFormModule + 'Primary'}
            control={control}
            name="isPrimary"
            label={t('primary')}
            sizes={{ xs: 12 }}
            disabled={isPrimary}
          />
        </span>
        <MultipleAutocompleteForm
          control={control}
          name="alerts"
          label="Load Notifications"
          selectedValues={notificationsOption.filter((notification) =>
            alerts?.includes(notification.name)
          )}
          options={notificationsOption}
          fieldName="value"
          variant="standard"
          onChangeCb={handleNotificationChange}
          sizes={{ xs: 12 }}
        />
        <Checkbox
          name={`sendPhoneAlert`}
          control={control}
          labelPlacement={'end'}
          label={'Notify via Phone'}
        />
        <Checkbox
          name={`sendEmailAlert`}
          control={control}
          labelPlacement={'end'}
          label={'Notify via Email'}
        />
      </Grid>
    );
  }
}
