import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { CreateLoadRequest } from '../Loads/Requests';
import { QueryParams } from '../commonMixed';

export class ELDRequest extends QueryParams {
  terminalIds!: Array<string> | undefined;
  integrationType!: string;
}

export class GetEDITendersRequest {
  status!: string;
  pickupState!: string[];
  pickupCity!: string;
  pickupZip!: string[];
  dropOffState!: string[];
  dropOffCity!: string;
  dropOffZip!: string[];
  // pickupLocation!: string;
  // dropoffLocation!: string;
  customer!: string;
  shipmentId!: string[];
  respondBy!: Date;

  constructor(dto: any) {
    Object.assign(this, dto);
  }
}
export class PaginatedTenderListQueryParams {
  pageSize!: number;
  pageNumber!: number;
  // Method to return the query parameters as an object or URLSearchParams
  getParamsFromRequest(): { [key: string]: number } {
    return {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    };
  }
}

export class GetEDITenderResponseDTO extends Array<EDITenderDTO> {
  //
}

export class ExportEDITendersRequest extends GetEDITendersRequest {
  columns!: string[];
  constructor(dto: any) {
    super(dto);
  }
}

export class EDITenderDTO {
  id!: string;
  providerId!: any;
  organizationId!: number;
  type!: string;
  typeDescription!: string;
  transactionId!: string;
  customer!: {
    name: string;
    phone: string;
    email: string;
  };
  customerIsa!: string;
  equipment!: string;
  respondBy!: string;
  billTo!: {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    type: string;
    code: string;
  };
  quotes!: Array<{
    amount: number | string;
    createdBy: { id: string; name: string };
    deleted: boolean;
    id: string;
    lastUpdatedOn: string;
  }>;
  shipmentId!: string;
  stops!: Array<{
    rowId: string;
    stopName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    stopNum: string;
    appointmentStartDate: string;
    appointmentEndDate: string;
    timezone: string;
    weight: string;
    type: string;
    code: string;
    notes: string;
    contact: {
      name: string;
      email: string;
      phone: string;
    };
    stopType: any;
  }>;
  totalStops!: number;
  loadedMiles!: number;
  rateType!: string;
  rate!: number;
  action!: EEDITenderStatus;
  actionDateTime!: string;
  declineReason!: any;
  weight!: number;
  length!: number;
  updatedAt!: any;
  notes!: any;
  scac!: string;
  loadId!: string;
  seqNumber!: string;
  loadStatus?: ELoadStatus | null;
  source!: 'string';
}

export class GetFilterListRequest extends QueryParams {}

export enum EEDITenderStatus {
  New = 'New',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Expired = 'Expired',
}

export class UpdateShipmentStatusDTO {
  loadAction!: string | EEDITenderStatus;
  rejectReason?: string;
  shipmentId!: string;
  tenderId!: string;
  load?: CreateLoadRequest;
}

export class UpdateShipmentStatusBody extends UpdateShipmentStatusDTO {
  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export class UpdateShipmentStatusParams extends QueryParams {
  isTender?: boolean;
}

export class UpdateShipmentStatusErrorResponse {
  headers: {};
  body: Array<{
    field: string;
    searchField: any;
    searchValue: any;
    message: string;
  }>;
  statusCodeValue: number;
  statusCode: string;
}

export class UpdateShipmentStatusResponse {
  message: string;
}

export class DeleteTenderResponse {
  //
}

export class LoadMappingResponse {
  organizationId: number;
  loadAxeleId: any;
  seqNumber: any;
  dispatcherId: any;
  loadStatus: string;
  loadType: string;
  completeDate: any;
  loadCreatorUserId: any;
  stops: Array<{
    relayStop: any;
    loadStop: {
      id: number;
      stopStatus: string;
      refNumber: string;
      sequenceNumber: any;
      location: {
        id: any;
        organizationId: any;
        axeleId: any;
        locationName: string;
        fullAddress: string;
        address: string;
        streetAddress: any;
        streetAddress2: any;
        city: string;
        zipcode: string;
        state: string;
        lat: number;
        lng: number;
        contacts: Array<any>;
        center: {
          lat: number;
          lng: number;
        };
        timezone: string;
        notes: string;
        avgHandlingTime: any;
        recordDate: any;
        country: any;
      };
      contact: {
        firstname: string;
        lastname: string;
        email: string;
        countryCode: any;
        phone: string;
        extension: any;
      };
      appointmentStartDate: any;
      appointmentEndDate: any;
      estimatedActivityDuration: number;
      isDriverAssistRequired: any;
      notes: string;
      noteDetails: {};
      stopCategory: any;
      timezone: string;
      activityType: string;
      fixedAppointment: boolean;
      stopDetentionTime: any;
    };
  }>;
  loadDetails: {
    trailerTypes: Array<any>;
    noteDetails: {};
    factoringNotes: string;
    equipmentAdditionalDetails: {
      chassisNumber: any;
      containerNumber: any;
      lowerLimitTemperature: any;
      upperLimitTemperature: any;
      sealNumber: any;
    };
  };
  customerId: any;
  customerContactId: any;
  loadCategory: string;
  revenue: any;
  operationType: string;
  totalShipments: any;
  shipments: Array<any>;
  proofOfDeliveryMandatory: boolean;
  billOfLadingMandatory: boolean;
  nonInvoiceable: boolean;
  invoice: {
    paid: number;
    total: number;
    amountDue: number;
    lineItems: Array<{
      itemId: string;
      description: string;
      quantity: number;
    }>;
    contentType: string;
    noteDetails: {};
    invoiceTerm: string;
    factoringAmount: number;
    factoringCharge: number;
    netRevenue: number;
    loadedMiles: number;
    emptyMiles: number;
  };
  terminalId: any;
  loadboardSource: any;
  visibilityProvidersIds: {};
  ediDetails: {
    tenderId: string;
    scac: string;
    customer: string;
    customerIsa: string;
    shipmentId: string;
  };

  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export class EDIProviderDTO {
  id: string;
  scac: string;
  organizationId: number;
  providerId: string;
  providerName: any;
  providerType: any;
}
export class GetProvidersResponse extends Array<EDIProviderDTO> {
  //
}
