import { useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ediService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import DetailsPanel from '../../../../common/DetailsPanel';
import { PanelFSProps } from '../../../../common/DetailsPanel/Panel';
import {
  EDITenderDTO,
  GetEDITendersRequest,
  LoadMappingResponse,
  PaginatedTenderListQueryParams,
} from '../../../../models/DTOs/EDI/Requests';
import { EEDITenderPanelTabs } from '../../constants/detailsPanel';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';
import EEDITenderDetailsForm from './EDITenderDetailsForm';

interface EDITenderDetailsPanelProps {
  data: EDITenderDTO | { id: string };
  onClose: () => void;
  onUpdated?: (data: any) => void;
  onDeleted?: (data: any) => void;
  onCreated?: (data: any) => void;
  defaultTab?: EEDITenderPanelTabs;
  isGlobal?: boolean;
  autoExpanded?: boolean;
}

export const EDITenderDetailsPanel = ({
  data,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  defaultTab = EEDITenderPanelTabs.TRIP_PLAN,
  isGlobal,
  autoExpanded,
}: EDITenderDetailsPanelProps) => {
  const entity = 'EDI Tender';
  const theme: any = useTheme();
  const panelWrapperCustomStyles = {
    display: 'flex',
    width: '50%',
    padding: '0 5px 5px 0',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette.leftMenuCollapsed.canvas,
    boxShadow: 'unset',
  };
  const [tenderDTO, setTenderDTO] = useState<
    (Partial<EDITenderDTO> & Pick<EDITenderDTO, 'id'>) | null
  >(data);
  const [tripPlanData, setTripPlanData] = useState<LoadMappingResponse | null>(
    null
  );

  const [selectedTab, setSelectedTab] =
    useState<EEDITenderPanelTabs>(defaultTab);

  const tabStripRenderer = () => {
    const keys: EEDITenderPanelTabs[] = [
      EEDITenderPanelTabs.TRIP_PLAN,
      EEDITenderPanelTabs.DETAILS,
    ];
    return (
      <DetailsTabStrips
        defaultTab={selectedTab}
        onChange={handleTabStripChange}
        keys={keys}
      />
    );
  };

  const handleTabStripChange = (selected: EEDITenderPanelTabs): void => {
    setSelectedTab(selected);
  };

  const isEdit = !!tenderDTO?.id;
  const onFullscreen = (isFullscreen: boolean): void => {
    //set default tab when fullscreen
    const getDetaulTabFS = () => {
      if (isFullscreen) {
        if (isEdit) {
          return EEDITenderPanelTabs.TRIP_PLAN;
        }
        return EEDITenderPanelTabs.DETAILS;
      }
      return defaultTab;
    };
    setSelectedTab(getDetaulTabFS());
  };

  const CurrentContentRenderer = useCallback(
    () => (
      <EEDITenderDetailsForm
        selectedTab={selectedTab}
        tenderDTO={tenderDTO}
        tripPlanData={tripPlanData}
      />
    ),
    [selectedTab, tenderDTO, tripPlanData]
  );

  const TripPlanContentRenderer = useCallback(() => {
    return (
      <EEDITenderDetailsForm
        selectedTab={EEDITenderPanelTabs.TRIP_PLAN}
        tenderDTO={tenderDTO}
        tripPlanData={tripPlanData}
      />
    );
  }, [tenderDTO, tripPlanData]);

  const DetailsContentRenderer = useCallback(() => {
    return (
      <EEDITenderDetailsForm
        selectedTab={EEDITenderPanelTabs.DETAILS}
        tenderDTO={tenderDTO}
        tripPlanData={tripPlanData}
      />
    );
  }, [tenderDTO, tripPlanData]);

  const onCloseHandler = (): void => {
    onClose?.();
  };

  const panelPropListFS: PanelFSProps[] = useMemo(() => {
    const panelPropList: PanelFSProps[] = [];
    panelPropList.push({
      data: tenderDTO,
      entity: entity,
      panelTitle,
      contentRenderer: TripPlanContentRenderer,
      panelWrapperCustomStyles: {
        ...panelWrapperCustomStyles,
        borderRadius: '18px 0 0 18px', //override border radius
      },
      tabStripRenderer: () =>
        tabStripRendererByKeyFS(EEDITenderPanelTabs.TRIP_PLAN),
    });
    panelPropList.push({
      data: tenderDTO,
      entity: entity,
      contentRenderer: DetailsContentRenderer,
      panelWrapperCustomStyles: {
        ...panelWrapperCustomStyles,
        borderRadius: '0 18px 18px 0',
      },
      panelTitle: '',
      tabStripRenderer: () =>
        tabStripRendererByKeyFS(EEDITenderPanelTabs.DETAILS),
    });
    return panelPropList;
  }, [tenderDTO, TripPlanContentRenderer, DetailsContentRenderer]);

  const fetchDataById = async (id: string) => {
    const queryParams = new PaginatedTenderListQueryParams();
    queryParams.pageNumber = 1;
    queryParams.pageSize = 1;
    if (id) {
      const response = await ediService.getEDITenders(
        queryParams,
        new GetEDITendersRequest({ tenderId: [id] })
      );
      if (response instanceof ServiceError || response === null) {
        setTenderDTO(null);
      } else {
        response?.content[0] && setTenderDTO(response?.content[0]);
      }
    }
  };

  const fetchTripPlanData = async (id: string) => {
    const loadMappingData = await ediService.loadMapping(id);
    if (!(loadMappingData instanceof ServiceError)) {
      setTripPlanData(loadMappingData);
    }
  };

  //Side effect begin
  useEffect(() => {
    if (!data?.id) return;
    if (data instanceof EDITenderDTO) {
      setTenderDTO(data);
    } else {
      fetchDataById(data.id);
    }
    fetchTripPlanData(data.id);
  }, [data?.id]);
  //Side effect end

  const panelTitle: string = useMemo((): string => {
    const tmpStr = [];
    if (tripPlanData) {
      tmpStr.push(tripPlanData.stops[0].loadStop.location?.city || '-');
      tmpStr.push('>');
      tmpStr.push(
        tripPlanData.stops[tripPlanData?.stops?.length - 1].loadStop?.location
          ?.city || '-'
      );
      tmpStr.push(' | ');
      tmpStr.push(tripPlanData?.ediDetails?.customer || '-');
    }
    return tmpStr.join(' ');
  }, [tripPlanData]);

  return (
    <>
      <DetailsPanel
        isGlobal={isGlobal}
        autoExpanded={autoExpanded}
        data={tenderDTO}
        entity={entity}
        panelTitle={panelTitle}
        onClose={onCloseHandler}
        onDelete={onDeleted}
        onCreate={onCreated}
        contentRenderer={CurrentContentRenderer}
        tabStripRenderer={() => tabStripRenderer()}
        footerRenderer={() => undefined}
        onFullscreen={onFullscreen}
        {...(isEdit && {
          fullscreen: {
            panelPropList: panelPropListFS,
          },
        })}
      />
    </>
  );
};
