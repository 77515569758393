import * as yup from 'yup';
export const RegisterBrokerValidationSchema = yup.object().shape({
  broker: yup.object().nullable().required('Broker is required.'),
  username: yup.string().required('User Name is required'),
  password: yup.string().required('Password is required'),
  activationName: yup.string().required('Activation Name is required'),
  clientId: yup.string().required('Client id is required'),
  clientSecret: yup.string().required('Client secret is required'),
  carrierId: yup.string().required('Carrier id is required'),
  turvoApiKey: yup.string().required('Turvo API Key is required'),
  customer: yup
    .object()
    .nullable()
    .when('fixedCustomer', {
      is: true || 'true', // Condition: when fixedCustomer is true
      then: yup.object().required('Customer is required').nullable(), // Allows customer to be null but only if fixedCustomer is false
      otherwise: yup.object().nullable(),
    }),
});
