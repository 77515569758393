import { Box, Typography } from '@mui/material';
import {
  tooltipHighlightedContainerStyles,
  highlightedDetailsPrimaryTextStyles,
  highlightedDetailsSecondaryTextStyles,
  TooltipHosKpiBlock,
  weekTextStyles,
} from '../styles';
import { ITooltipKpis, IWeekInformation } from '../types';
import React from 'react';

interface IKpiSection {
  kpis?: ITooltipKpis;
  weekInformation?: IWeekInformation[];
}

const KpiSection: React.FC<IKpiSection> = (props) => {
  const { kpis, weekInformation } = props;
  if (!kpis) return <></>;
  return (
    <Box
      id="highlight-details"
      style={{
        ...tooltipHighlightedContainerStyles,
        ...kpis?.containerStyles,
      }}
    >
      <Box display="flex">
        {kpis?.content?.map((details) => (
          <Box
            key={details?.primaryText}
            textAlign={'center'}
            style={{ ...TooltipHosKpiBlock, ...details.containerStyles }}
          >
            <Typography
              style={{
                ...highlightedDetailsPrimaryTextStyles,
                ...details.primaryTextStyles,
              }}
            >
              {details.primaryText}
            </Typography>
            <Typography
              style={{
                ...highlightedDetailsSecondaryTextStyles,
                ...details.secondaryTextStyles,
              }}
            >
              {details.secondaryText}
            </Typography>
          </Box>
        ))}
      </Box>

      {Boolean(weekInformation?.length) && (
        <Box sx={{ margin: '8px 0 0 0 ' }}>
          {weekInformation?.map((week: IWeekInformation) => (
            <Box
              width={'100%'}
              justifyContent={'center'}
              display={'flex'}
              key={week?.key}
            >
              <Typography sx={{ ...weekTextStyles, ...week?.keyStyles }}>
                {week?.key}:
              </Typography>
              <Typography
                sx={{
                  ...weekTextStyles,
                  paddingLeft: '3px',
                  ...week?.valueStyles,
                }}
              >
                {week?.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default KpiSection;
