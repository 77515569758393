import { TagItem } from '@/models/DTOs/ManageTypes/Requests';
import { FC, MouseEvent, useState } from 'react';
import { Tag } from '@/common/Tag/components/Tag';
import { Box, Link, Popover, Typography } from '@mui/material';

interface Props {
  tags: TagItem[] | null;
  showAll?: boolean;
  small?: boolean;
  maxCount?: number;
}

export const TagList: FC<Props> = ({
  tags,
  showAll = false,
  small = false,
  maxCount = 1,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'tags-popover' : undefined;

  if (!tags || tags.length === 0) {
    return null;
  }

  if (showAll) {
    return (
      <Box sx={{ display: 'flex', gap: 0.5 }}>
        {tags.map((tag) => (
          <Tag
            color={tag.textColor}
            key={tag.id}
            title={tag.name}
            bgColor={tag.color}
          />
        ))}
      </Box>
    );
  }

  if (tags.length === 1) {
    return (
      <Tag
        color={tags[0].textColor}
        small={small}
        title={tags[0].name}
        bgColor={tags[0].color}
      />
    );
  }
  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
    >
      {tags.slice(0, maxCount).map((tag) => (
        <Tag
          color={tag.textColor}
          small={small}
          key={tag.id}
          title={tag.name}
          bgColor={tag.color}
        />
      ))}
      <Link
        style={{ cursor: 'pointer' }}
        aria-describedby={id}
        onClick={handleClick}
        underline="none"
      >
        + {tags.length - maxCount}
      </Link>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1 }}
        >
          {tags.slice(maxCount).map((tag) => (
            <Tag
              small={small}
              key={tag.id}
              title={tag.name}
              bgColor={tag.color}
              color={tag.textColor}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};
