import { Box, Typography } from '@mui/material';
import {
  tooltipBodyContainerStyle,
  tooltipTripBoldTextStyles,
  tooltipTripContainerStyles,
  tooltipTripKeyContainerStyles,
  tooltipTripKeyStyles,
  tooltipTripTextStyles,
  tripKeyStyles,
} from '../styles';
import { TripType } from '../types';
import React from 'react';

const TooltipTripTemplate: React.FC<TripType> = (props) => {
  const { tripData = [], element, tripContainerStyles, fontFamily } = props;
  if (!tripData?.length && !element) return <></>;

  const getTripTextStylesByIndex = (index: number) =>
    index === 0 ? tooltipTripBoldTextStyles : tooltipTripTextStyles;

  return (
    <Box
      id="body-container"
      style={{
        ...tooltipBodyContainerStyle,
        ...tripContainerStyles,
      }}
    >
      {tripData?.map((trip, i: number) => (
        <Box
          key={JSON.stringify(trip)}
          paddingTop={i !== 0 ? '8px' : 0}
          style={{ ...tooltipTripContainerStyles, ...trip.containerStyles }}
          position="relative"
        >
          <Box display={'flex'}>
            <Box
              height={'auto'}
              style={{
                ...tooltipTripKeyContainerStyles,
                ...trip.keyContainerStyles,
              }}
            >
              <Typography
                style={{
                  ...tripKeyStyles,
                  fontFamily: fontFamily || 'Poppins',
                  ...(trip?.keyStyles as any),
                }}
              >
                {trip?.key || i === 0
                  ? trip?.key || 'Pickup:'
                  : i === tripData?.length - 1
                  ? trip?.key || 'Drop off:'
                  : ''}
              </Typography>
            </Box>
            <Box marginLeft={'4px'}>
              {trip?.trip?.map((tripContent, index: number) => (
                <Box
                  display="flex"
                  style={{ width: '-webkit-fill-available' }}
                  key={JSON.stringify(tripContent)}
                >
                  {tripContent?.key && (
                    <Box>
                      <Typography
                        style={{
                          ...tooltipTripKeyStyles,
                          ...tripContent.keyStyles,
                        }}
                      >
                        {tripContent.key}
                      </Typography>
                    </Box>
                  )}
                  <Typography
                    style={{
                      ...getTripTextStylesByIndex(index),
                      ...tripContent.textStyles,
                    }}
                  >
                    {tripContent.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TooltipTripTemplate;
