import React from 'react';
import { EVENT_STATUS, EVENT_TYPES } from '../constants/puck.constant';
import { ColumnTooltipType } from './types';
import Tooltip from '../atoms/tooltip';
import { getColumnToolTipProps } from './ganttchart.seeds';
import { UnAssignedResourceDetail } from '../atoms';

export const GAP_PUCK_BORDER_COLOR = '#2B64CB';

export const eventEditItems = {
  startDateField: null,
  endDateField: null,
  startTimeField: null,
  endTimeField: null,
  nameField: null,
  resourceField: null,
};

export const eventEditorConfig = {
  bbar: {
    items: {
      deleteButton: null,
    },
  },
};

export const schedulerConfig: any = {};
export const eventMenuItems = {
  deleteEvent: false,
  editEvent: false,
  copyEvent: false,
  cutEvent: false,
};

export const schedulerConfigFeatures = {
  resourceTimeRanges: {
    enableMouseEvents: true,
  },
  headerMenuFeature: false,
  contextMenu: false,
  columnLines: true,
  cellMenu: false,
  stripe: false,
  eventMenu: false,
  scheduleMenu: false,
  scheduleContextMenuFeature: false,
  timeAxisHeaderMenu: {
    disabled: true,
  },
  timeRanges: {
    showCurrentTimeLine: true,
  },
  sort: {
    disabled: true,
  },
  eventEdit: {
    items: eventEditItems,
    editorConfig: eventEditorConfig,
  },
  cellEdit: {
    disabled: true,
    editNextOnEnterPress: false,
  },
};

export const defaultPuckProps = {
  originTimeline: '',
  destinationTimeline: '',
  containerWidth: '100%',
  containerHeight: '100%',
  middleText: '500mi ',
  outerBoxStyle: { m: 0 },
  withThumbsIcon: false,
};

export const eventMenu = {
  processItems({ eventRecord, items }: any) {
    if (eventRecord?.data?.type !== EVENT_TYPES.GAP) {
      return false;
    }
    items.showSessionItem = {
      text: 'Show sessions',
    };
  },
  items: {
    copyEvent: false,
    cutEvent: false,
    deleteEvent: false,
  },
};

export const timeRangeStyle = `
cursor:pointer;
background-color: #BDBDBD;
color: #001122;
opacity: 0.8;  
border: 2px solid #BDBDBD;
text-align: center;
align-items: center;
font-size: 16px;
background: #BDBDBD;
border: none!important;
background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 4px,
    transparent 1px,
    #BDBDBD 7px
  ),
  linear-gradient(
    to bottom,
    transparent,
    transparent
  )
`;

export const puckContainerStyles = `
background-color: inherit;
box-shadow: none;
height: 100%;
padding: 0 !important;
border-radius: 3px;
`;

export const disabledDragDropEvents = [
  EVENT_STATUS.GAP,
  EVENT_STATUS.COMPLETED,
  EVENT_STATUS.DETAINED,
  EVENT_STATUS.COMPLETED,
];

export const loadaiPuckProps = {
  containerWidth: '100%',
  containerHeight: '100%',
  outerBoxStyle: { m: 0, height: '100%' },
  withThumbsIcon: false,
  timelineStyles: {
    fontSize: '12px',
  },
  badgeContainerStyles: {
    width: '100%',
    innerHeight: '100%',
    outerHeight: '100%',
  },

  originDestinationStyles: {
    fontSize: '13px',
  },
  // originTimelineContainerStyles: {
  // 	position: 'fixed',
  // 	marginTop: '17px',
  // },
};

export const resourceInfoTooltip = (
  data: any,
  columnTooltipContent: ColumnTooltipType | undefined
) => (
  <Tooltip
    {...getColumnToolTipProps(data.record?.data, columnTooltipContent)}
  />
);

export const getDefaultGanttColumns = ({
  resourceInfoHeaderCls,
  unassignedRowBackgroundColor,
  resourceInfoTemplate,
  unassignedResourceTemplate,
  columnWidth,
}: any) => [
  {
    type: 'resourceInfo',
    text: '',
    width: columnWidth,
    headerRenderer: (data: any) => {
      data.headerElement.classList.add(`${resourceInfoHeaderCls}`);
    },
    renderer: (data: any) => {
      if (data?.record?.data?.status === EVENT_STATUS.AVAILABLE) {
        data?.row.eachElement(
          (el: any) => (el.style.background = unassignedRowBackgroundColor)
        );
        return unassignedResourceTemplate ? (
          unassignedResourceTemplate(data)
        ) : (
          <UnAssignedResourceDetail name={data?.record?.data?.name} />
        );
      }
      data?.row.eachElement((el: any) => (el.style.background = 'inherit'));
      return resourceInfoTemplate ? (
        resourceInfoTemplate(data)
      ) : (
        // <DetailsCard
        //   mainHeading={data?.record?.data?.name}
        //   {...data.record.data}
        // />
        <></>
      );
    },
  },
];

export const GanttControlsDefaultConfig = {
  hideNavigationControl: false,
  hideZoomControl: false,
  hideReset: false,
  disableReset: false,
  allowDefaultNavigationControl: true,
  allowDefaultZoomControl: true,
  showControlActions: true,
};

export enum PUCK_TEXT_COLOR_BY_TYPE {
  COMPLETED = '#000000',
  OTHERS = '#FFFFFF',
}
