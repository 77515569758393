import { numberWithThousandSeparator } from '../../../../utils';

export type tabTypes = 'Load' | 'Driver' | 'Tractor' | 'Trailer';

export type showHideAddAlert = () => void;

export interface alertTabData {
  label: tabTypes;
  iconName: string;
  source: 'AxeleIcon' | 'MUIcons';
  key: tabTypes;
}

export const updateText = new Date().toISOString();

export const setOptions = (input: any) =>
  input.map((item: any) => ({ label: item, key: item }));

export const setDriverOptions = (input: any) =>
  input.map((item: any) => ({ label: item.itemName, key: item.itemCode }));

export const setStaticOptions = (input: any) =>
  input.map((item: any) => ({ label: item.value, key: item.key }));

export const titleCase = (str: any) =>
  str
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b(\w)/g, (s: string) => s.toUpperCase());

export const frequencyText = (data: any) => {
  if (data.tab === 'Tractor') {
    if (data.frequencyByMiles && data.frequencyByMiles !== 0)
      return `${numberWithThousandSeparator(data.frequencyByMiles)} mi`;
    else
      return data.frequency === 'CUSTOM' ? `${data.frequencyValue} day(s)` : '';
  } else {
    return data.frequency === 'CUSTOM' ? `${data.frequencyValue} day(s)` : '';
  }
};

export const warningText = (data: any) => {
  if (data.tab === 'Tractor' && data.alertByMiles && data.alertByMiles !== 0)
    return `${numberWithThousandSeparator(data.alertByMiles)} mi Before`;
  else return '';
};

export const setLoadUsers = (sendAlertsTo: string[], options: any) => {
  let temp = options;
  sendAlertsTo.map((item: any) => {
    temp = [...temp, { label: item, key: item }];
  });
  return temp;
};

export const deletePopOver = {
  title: `Delete ?`,
  body: `Are you sure you want to delete ?`,
};

export const titleComma = (str: any) => {
  const temp = str
    .replace(/_/g, ',')
    .toLowerCase()
    .replace(/\b(\w)/g, (s: string) => s.toUpperCase());
  return temp.replace(/,/g, ', ');
};

export const editDataSet = async (row: any, tab: string) => {
  let tempValues = {};
  if (tab === 'Load') {
    tempValues = {
      loadAlertsType: row.name,
      loadUserRoles: row.sendAlertsTo,
      icon: row.iconKey,
      itemId: row.id ? row.id : '',
      Notification: row.alertReceiverStates?.shouldSendNotification,
      ['Notify via Phone']: row.alertReceiverStates?.shouldSendSms,
      ['Notify via Email']: row.alertReceiverStates?.shouldSendEmail,
    };
  } else {
    tempValues = {
      warning: row.warningDuration,
      loadAlertsType: tab === 'Driver' ? row.documentType : row.maintenanceType,
      frequency: row.frequency,
      loadUserRoles: row.recipient,
      icon: row.iconPicker,
      ['If Expired, Prevent Dispatch']:
        row.dispatchAllowedIfCertificationExpires,
      customFrequencyEvery: row.frequency === 'CUSTOM' ? row.frequencyValue : 0,
      itemId: row.id ? row.id : '',
      ['Notify via Phone']: row.alertReceiverStates?.shouldSendSms,
      ['Notify via Email']: row.alertReceiverStates?.shouldSendEmail,
    };
    if (tab === 'Tractor') {
      tempValues = {
        ...tempValues,
        ['Time-Based Frequency/Warning']: true,
        ['Miles-Based Frequency/Warning']:
          (row.alertByMiles && row.alertByMiles !== 0) ||
          (row.frequencyByMiles && row.frequencyByMiles !== 0),
        milesBasedFrequency: row.frequencyByMiles ? row.frequencyByMiles : null,
        milesBeforeWarning: row.alertByMiles ? row.alertByMiles : null,
      };
    }
    if (row.documentType == 'USERDEFINED' && tab == 'Driver')
      tempValues.uerdefinedLabel = row.name;
    if (row.maintenanceType == 'USERDEFINED' && tab == 'Trailer')
      tempValues.uerdefinedLabel = row.name;
    if (row.maintenanceType == 'USERDEFINED' && tab == 'Tractor')
      tempValues.uerdefinedLabel = row.name;
  }
  return tempValues;
};

export const validateLoad = async (data: any) => {
  return {
    loadAlertsType: data.loadAlertsType ? false : true,
  };
};

export const filterNameDriver = (values: any, list: any) => {
  const temp = list.filter((item: any) => item.id === values.documentTypeId);
  return temp[0].itemName;
};

export const validateDT = async (data: any) => {
  return {
    loadAlertsType: data.loadAlertsType ? false : true,
    frequency: data.frequency ? false : true,
    warning: data.warning ? false : true,
  };
};

export const validateTractor = async (data: any) => {
  let temp = {};
  if (
    !data['Miles-Based Frequency/Warning'] &&
    !data['Time-Based Frequency/Warning']
  ) {
    temp = {
      ...temp,
      checkBoxError: true,
    };
  }
  if (data['Miles-Based Frequency/Warning']) {
    temp = {
      ...temp,
      milesBasedFrequency: data.milesBasedFrequency ? false : true,
      milesBeforeWarning: data.milesBeforeWarning ? false : true,
    };
  }
  if (data['Time-Based Frequency/Warning']) {
    temp = {
      ...temp,
      frequency: data.frequency ? false : true,
      warning: data.warning ? false : true,
    };
  }
  temp = {
    ...temp,
    loadAlertsType: data.loadAlertsType ? false : true,
  };
  return temp;
};

export const filterValidation = async (data: any) => {
  return Object.fromEntries(
    Object.entries(data).filter(([_, v]) => v !== false)
  );
};

export const filterDuplication = async (options: any, list: any) =>
  list && list.length > 0
    ? options.filter((optionItem: any) =>
        list.every((listItem: any) => listItem.documentTypeId !== optionItem.id)
      )
    : options;

export const filterTTDuplication = async (options: any, list: any) =>
  list && list.length > 0
    ? options.filter((optionItem: any) =>
        list.every(
          (listItem: any) => listItem.maintenanceTypeId !== optionItem.id
        )
      )
    : options;

export const filterForName = (values: any, list: any) => {
  const temp = list.filter((item: any) => item.id === values.maintenanceTypeId);
  return temp[0].itemName;
};
