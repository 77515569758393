import { ManageTypeActions } from '@/subPages/settings/ManageTypes/ManageTypeActions';
import { useEffect, useState } from 'react';
import { TagColor } from '@/models/DTOs/ManageTypes/Requests';

export const useTagForm = () => {
  const [colors, setColors] = useState<TagColor[]>([]);
  const getTagColors = async () => {
    const result = await ManageTypeActions.getTagColors();
    setColors(result);
  };

  useEffect(() => {
    getTagColors();
  }, []);

  return {
    colors,
  };
};
