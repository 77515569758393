import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Typography } from '../../../../../components_v2/ui-kit/typography';

interface IProps {
  label: string;
  value?: string | number | null;
  contentRenderer?: () => ReactNode;
  styles?: {};
}

export const SingleFieldInfo = ({
  label = '',
  value = '',
  contentRenderer,
  styles,
}: IProps) => {
  const content =
    typeof contentRenderer === 'function' ? (
      contentRenderer()
    ) : (
      <Typography
        sx={{ color: 'text.primary', fontSize: '14px', fontWeight: 400 }}
        variant="body4"
      >
        {value}
      </Typography>
    );
  return (
    <Box display={'inline-block'}>
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '14px',
          color: 'text.secondary',
          // pl: isInvoiceCreate ? '5px' : '0PX',
          ...styles,
        }}
        variant="body4"
      >
        {label} {': '}
      </Typography>
      {content ? (
        content
      ) : (
        <span style={{ fontSize: '14px', color: 'text.secondary' }}>
          {' '}
          {value}{' '}
        </span>
      )}
    </Box>
  );
};
