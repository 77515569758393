import { Box } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { NavigateToTypes } from '@/common/Gantt/types';
import { observer } from 'mobx-react';
import moment from 'moment';
import { RelativeDateRangeUtils } from '../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { GanttSkeleton } from '../../skeleton';
import { IPanelName } from '../../store/pageStores';
import { useStores } from '../../store/root.context';
import { ERelativeDateRangeKey } from '../../ui-kit/components/RelativeDateRange';
import { GanttChart, GanttHeader } from './components';
import GanttChartMap from './components/gantt/GanttChartMap/GanttChartMap';
import { GanttChartGrid } from './components/gantt/GanttChartGrid';
import { defaultDispatch2Filters } from './constants/dispatch';
import {
  DispatchFiltersName,
  EGanttTabPanel,
  IDispatchFilters,
} from './constants/types';
import { useDispatchStorage } from './hooks/useDispatchStorage';
import { getDayStartHours } from './services';
import {
  ContainerStyles,
  DisplayNoneBlock,
  FullViewDisplayBlock,
  GanttChartStyles,
} from './styles';
import { IGanttSubTitle, TRIP_TYPE } from './types';
import { SecondaryPanelType } from '@/models/DTOs/Dispatch/Dispatch';

interface IDispatch2 {
  onTripView?: (tripData: TRIP_TYPE) => void;
  onLoadView?: (loadData: object) => void;
  onCustomerView?: ((customerData: SecondaryPanelType) => void) | undefined;
  onDriverDetailView: ((driverGroupId: string) => void) | undefined;
  onSubTitleClick?: (data: IGanttSubTitle) => void;
}

const GanttLayout: React.FC<IDispatch2> = (props) => {
  const { getCurrentStorage, updateStorage } = useDispatchStorage();
  const {
    dispatch2TripsStore: { fullscreenPanel, setFullscreen },
    dispatch2GlobalStore: { displayMode, setDisplayMode, filters, setFilters },
  } = useStores();
  const {
    onTripView,
    onLoadView,
    onDriverDetailView,
    onCustomerView,
    onSubTitleClick,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    });
    setFilters(getCurrentStorage() || defaultDispatch2Filters);
    setDisplayMode(
      getCurrentStorage()?.filters?.[DispatchFiltersName.displayMode] ??
        EGanttTabPanel.Gantt
    );
  }, []);

  const onTabChange = (tab: EGanttTabPanel) => {
    setDisplayMode(tab);
    updateStorage({
      ...getCurrentStorage(),
      filters: {
        ...getCurrentStorage().filters,
        displayMode: tab,
      },
    });
  };

  const handleGanttDateChange = (window: number, navTo: NavigateToTypes) => {
    const currStartDate =
      filters.filters?.[DispatchFiltersName.startDate]?.dateRange?.[0];
    const newStartDate: Date =
      navTo === 'RIGHT'
        ? moment(currStartDate)?.add?.(window, 'days')?.toDate?.()
        : moment(currStartDate)?.subtract?.(window, 'days')?.toDate?.();
    const newFilters: IDispatchFilters = {
      ...filters,
      filters: {
        ...filters.filters,
        [DispatchFiltersName.startDate]: {
          ...RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
            ERelativeDateRangeKey.PeriodTimeCustom
          ),
          dateRange: [newStartDate, newStartDate],
        },
      },
    };
    setFilters(newFilters);
  };

  const currentTabComponentStyle = useMemo(
    () => ({
      [EGanttTabPanel.Gantt]:
        displayMode === EGanttTabPanel.Gantt
          ? FullViewDisplayBlock
          : DisplayNoneBlock,
      [EGanttTabPanel.Map]:
        displayMode === EGanttTabPanel.Map
          ? FullViewDisplayBlock
          : DisplayNoneBlock,
      [EGanttTabPanel.Grid]:
        displayMode === EGanttTabPanel.Grid
          ? FullViewDisplayBlock
          : DisplayNoneBlock,
    }),
    [displayMode]
  );

  const getGanttStartDate = (): Date | undefined => {
    const dateFilter = (filters?.filters as any)?.[
      DispatchFiltersName.startDate
    ]?.dateRange?.[0];
    if (!dateFilter) return undefined;
    const startDate =
      dateFilter ||
      defaultDispatch2Filters.filters[DispatchFiltersName.startDate]
        ?.dateRange?.[0];
    return getDayStartHours(startDate);
  };

  return (
    <Box id="gantt-wrapper" sx={ContainerStyles}>
      <GanttHeader onFilterChange={setFilters} onTabChange={onTabChange} />
      {isLoading ? (
        <GanttSkeleton />
      ) : (
        <Box
          sx={{
            ...GanttChartStyles,
            display: fullscreenPanel === IPanelName.TRIP ? 'none' : undefined,
          }}
        >
          <Box
            id="ganttChartContainer"
            sx={{ height: '100%' }}
            sx={currentTabComponentStyle[EGanttTabPanel.Gantt]}
          >
            <GanttChart
              ganttStartDate={getGanttStartDate()}
              onTripView={onTripView}
              onLoadView={onLoadView}
              onDriverDetailView={onDriverDetailView}
              onStartDateChange={handleGanttDateChange}
              onSubTitleClick={onSubTitleClick}
              onAssignTrip={() => setFullscreen(null)}
            />
          </Box>

          <Box
            id="ganttChartMapContainer"
            sx={
              displayMode === EGanttTabPanel.Map ||
              displayMode === EGanttTabPanel.Grid
                ? FullViewDisplayBlock
                : DisplayNoneBlock
            }
            position={'relative'}
          >
            {displayMode === EGanttTabPanel.Map && (
              <GanttChartMap onDriverDetailView={onDriverDetailView} />
            )}
            {displayMode === EGanttTabPanel.Grid && (
              <GanttChartGrid
                onLoadView={onLoadView}
                onCustomerView={onCustomerView}
                onTripView={onTripView}
                onDriverView={onDriverDetailView}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(observer(GanttLayout));
