import Grid from '@mui/material/Grid';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteField from '../Autocomplete';
import TextInput from '../TextField';

export interface Props {
  label?: string;
  id?: string;
  required?: boolean;
  error?: boolean;
  helperText?: any;
  countryCodestyleProps?: any;
  phoneFieldStyleProps?: any;
  extnStyleProps?: any;
  componentStyleProps?: any;
  phoneNumberStyleProps?: any;
  onChange?: any;
  countryCode?: any;
  number?: any;
  extn?: any;
  countryCodeOptions: any;
  disabled?: boolean;
  variant?: string;
}

// const US = <Grid sx={{ mr: 1, p: 0 }}><img src={require('../Assets/CountryImages/flag.png')} /></Grid>;

const PhoneField: FunctionComponent<Props> = ({
  variant = 'standard',
  label,
  id,
  required,
  error = false,
  helperText,
  countryCodeOptions = [],
  countryCodestyleProps = { width: '150px' },
  phoneFieldStyleProps = { width: '150px' },
  extnStyleProps = { width: '70px' },
  componentStyleProps = { width: 'auto' },
  phoneNumberStyleProps = {},
  onChange,
  countryCode,
  number,
  extn,
  disabled,
}: Props) => {
  const [tempError, setTempError] = useState<boolean>();
  const [phoneField, setPhoneField] = useState({
    countryCode,
    number,
    extn,
  });

  useEffect(() => {
    setPhoneField({
      countryCode,
      number,
      extn,
    });
  }, [countryCode, number, extn]);

  const tempNumber = phoneField;
  const handlecountryCodeChange = (
    event: any,
    newValue: any,
    countryCodeError: boolean
  ) => {
    tempNumber.countryCode = newValue.key;
    setTempError(!Boolean(tempNumber.number) || countryCodeError);
    setPhoneField(tempNumber);
    onChange(tempNumber, !Boolean(tempNumber.number) || countryCodeError, id);
  };

  const handlePhoneChange = (event: any, phoneError: boolean) => {
    tempNumber.number = event.target.value;
    setTempError(!Boolean(tempNumber.countryCode) || phoneError);
    setPhoneField(tempNumber);
    onChange(tempNumber, !Boolean(tempNumber.countryCode) || phoneError, id);
  };

  const handleExtnChange = (event: any) => {
    tempNumber.extn = event.target.value;
    setPhoneField(tempNumber);
    onChange(tempNumber, tempError, id);
  };

  const getValue = (countryCode: string) => {
    if (
      countryCode === null ||
      countryCode === undefined ||
      countryCode === ''
    ) {
      return -1;
    } else {
      return countryCodeOptions.findIndex(
        (country: any) => country.key === countryCode
      );
    }
  };
  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <Grid container sx={componentStyleProps} spacing={2}>
        <Grid item id="countryCode">
          <AutocompleteField
            id="countryCode"
            label={label}
            variant={variant}
            styleProps={countryCodestyleProps}
            disableClearable={true}
            // startAdornment={true}
            options={countryCodeOptions}
            defaultValue={countryCodeOptions[getValue(phoneField.countryCode)]}
            error={error}
            onChange={handlecountryCodeChange}
            required={required}
            disabled={disabled}
          />
        </Grid>
        <Grid item id="Phone" sx={{ flexGrow: 1, ...phoneNumberStyleProps }}>
          <TextInput
            id={id}
            label={t('TripAddNewLoadOption3Phone')}
            variant={variant}
            styleProps={phoneFieldStyleProps}
            required={required}
            error={error}
            InputProps={{
              maxLength: 10,
            }}
            helperText={error && helperText}
            onChange={handlePhoneChange}
            defaultValue={phoneField.number}
            disabled={disabled}
          />
        </Grid>
        <Grid item id="Extn">
          <TextInput
            id="countryCode"
            label={t('TripAddNewLoadOption3Ext')}
            InputProps={{
              maxLength: 5,
            }}
            variant={variant}
            styleProps={extnStyleProps}
            error={error}
            onChange={handleExtnChange}
            defaultValue={phoneField.extn}
            disabled={disabled}
            helperText={error}
          />
        </Grid>
      </Grid>
    );
  }
};

export default PhoneField;
