import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, Skeleton, Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { ServiceError } from '../../../../../api/interfaces';
import { tripLoadCustomTitle } from '../../../../../common/UiUtils/uiUtils';
import { CommonConstants } from '../../../../../locales/en/common/index';
import {
  GetRemoveLoadManifestDataResp,
  LoadOverviewRemoveLoads,
} from '../../../../../models';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import { LoadPriorityEnumVals } from '../../../../../subPages/loadsList/CreateLoad/constants/fieldValues';
import { defaultEquipmentTypeOptions } from '../../../../../subPages/settings/Preferences/constants';
import { ComponentType } from '../../../../../types';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import ThreeDotMenuIcon from '../../../../../ui-kit/components/ThreeDotMenuIcon';
import { navigationLinks } from '../../../../../utils/commonNavigation/constants/constants';
import { entityTypes } from '../../../../../utils/entityTypesData';
import { ESecondaryDetailsPanelType } from '../../../../../views/dispatch2/constants/types';
import TerminalController from '../../../../../views/settings/terminals/TerminalController';
import DeletePopup from '../../../../DeletePopup';
import SecondaryDetailsPanel from '../../../../SecondaryDetailsPanel/SecondaryDetailsPanel';
import { getOrganizationId } from '../../../../TimeoffDialog/utils';
import FormDialog from '../../../../Ui/FormDialog';
import { useCarrierPermission } from '../../../../hooks/useCarrierPermission';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import { ELoadStatus } from '../../../constants/constants';
import ReferenceDetailsForm from '../forms/ReferenceDetailsForm';
import { LoadManifest, LoadOverviewDetails } from '../models';
import { removeLoadFromManifest, updateLoadReferenceDetails } from '../utils';
import { KPICards } from './KPICards';
import { MenuOptions } from './LoadDetails';
import { TagList } from '@/common/Tag/components/TagList';

export const loadIdStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
  display: 'inline-flex',
};
export const loadCustomerStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 17, 34, 0.6)',
  display: 'inline-flex',
};
export const loadLabelStyles = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  display: 'inline-flex',
  color: 'rgba(0, 17, 34, 0.60)',
};
export const loadIconStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 17, 34, 0.6)',
  display: 'inline-flex',
  margin: '3px 5px 3px 0px',
};
export const OverViewloadRemoveStyle = {
  padding: '0px 16px 13px 13px',
  background: '#F5F5F5',
  borderRadius: '8px',
  margin: '15px 0px',
};
export const OverViewloadRemoveTitleStyle = {
  color: '#2B64CB',
  fontWeight: '600',
  paddingRight: '5px ',
  paddingLeft: '5px',
  cursor: 'pointer',
};

export const ManifestDetails = observer(
  ({
    loadStatus,
    loadType,
    manifestDeleteForLoad,
    isLoading,
    onHold,
    onUpdated,
  }: {
    loadStatus: string;
    loadType: string;
    isLoading?: boolean;
    manifestDeleteForLoad?: (
      id: GetRemoveLoadManifestDataResp,
      status: boolean
    ) => void;
    onHold: boolean;
    onUpdated: () => void;
  }) => {
    const theme = useTheme();
    const location = useLocation();
    const { hasLoadEditPermission } = useLoadPermission();
    const [secondaryPanelData, setSecondaryPanelData] = useState<{
      type: keyof ComponentType;
      id: string | number;
    } | null>(null);
    const { getIsTerminalEnabled } = useRootStore();
    const { terminals } = TerminalController.instance();
    const { watch, setValue, control } = useFormContext();
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openWarning, setOpenWarning] = useState<boolean>(false);
    const [selectedLoad, setSelectedLoad] = useState<LoadManifest>();
    const [reqLoadRemove, setReqLoadRemove] = useState({
      open: false,
      message: null,
    });
    const { hasCarrierViewPermission } = useCarrierPermission();

    const {
      myLoadStore: { setUpdatedLoadId },
      invoiceStore: { setPaymentUpdated },
      tripsStore,
    } = useStores();
    const data = watch();
    const {
      id,
      customer,
      pickupCity,
      pickupState,
      dropOffCity,
      dropOffState,
      invoiceTerminal,
      loadedMiles,
      emptyMiles,
      revenue,
      trips,
      factoringDetails,
      loads,
      tripMiles,
      tags,
      bolNumber,
      purchaseOrderNumber,
    } = data;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (
      action: { icon: string; label: string },
      accountData: any,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      handleMenuClose();
      if (action?.label == 'Delete') return;
      setEditDialogOpen(true);
      // handleDelete(alert);
    };
    const handleUpdtaeManifest = async (data: LoadOverviewDetails) => {
      const response = await updateLoadReferenceDetails(data);
      setEditDialogOpen(false);
      onUpdated();
    };

    const closeSecondaryDetailsPanel = () => {
      setSecondaryPanelData(null);
    };

    const handleSecondaryDetailsPanelSave = (data) => {
      setUpdatedLoadId && setUpdatedLoadId(id);
      const { type } = secondaryPanelData;
      if (type === 'CUSTOMER') {
        setValue('customer.name', data.name);
      }
      closeSecondaryDetailsPanel();
    };
    const removeLoad = async () => {
      const requestData = new LoadOverviewRemoveLoads({
        loadId: selectedLoad?.id,
        organizationId: getOrganizationId(),
      });
      const response = await removeLoadFromManifest(requestData);
      setOpenDelete(false);

      if (!(response instanceof ServiceError)) {
        const filteredItems = loads?.filter(
          (paymentTerm: { id: string }) => paymentTerm.id !== selectedLoad?.id
        );
        setValue('loads', filteredItems);
        manifestDeleteForLoad?.(response, Boolean(filteredItems.length));
        if (
          location?.pathname === navigationLinks[entityTypes.TRIPS] &&
          (response.affectedTripIds?.length > 0 ||
            response.removedTripIds?.length > 0)
        ) {
          tripsStore.getMultipleTripData(response);
        }
      } else {
        setReqLoadRemove({
          open: true,
          message: response.composedError.message.response.data.message,
        });
      }
    };
    const getDeleteBody = () => {
      return `${loads?.length === 1 ? t('subTile1') : t('subTitle2')}`;
    };
    return (
      <>
        <Box sx={{ pt: 1 }}>
          {isLoading ? (
            <Stack direction="row" spacing={1}>
              <Skeleton
                variant="rectangular"
                height={'4rem'}
                width={'100%'}
                sx={{
                  borderRadius: '5px',
                }}
              />
              <Skeleton
                variant="rectangular"
                height={'4rem'}
                width={'100%'}
                sx={{
                  borderRadius: '5px',
                }}
              />
            </Stack>
          ) : (
            <KPICards
              kpis={{ loadedMiles, emptyMiles, revenue, tripMiles }}
              loadType={loadType}
            />
          )}

          {isLoading ? (
            <Skeleton
              variant="rectangular"
              height={'6rem'}
              width={'100%'}
              sx={{
                borderRadius: '5px',
                marginTop: '10px',
              }}
            />
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={OverViewloadRemoveStyle}
              className="OverViewloadRemoveStyle"
            >
              <Box sx={{ display: 'inline-grid' }} width={'100%'}>
                <Box
                  sx={{
                    display: 'flex',
                    marginY: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="h7"
                    sx={{
                      color: 'primary.main',
                    }}
                  >
                    {CommonConstants.manifestDetails}
                  </Typography>
                  <ThreeDotMenuIcon
                    styleProps={{
                      fontSize: '12px',
                      padding: '0px',
                      marginTop: '2px',
                    }}
                    menuOptions={MenuOptions}
                    open={Boolean(anchorEl)}
                    handleMenuClick={handleMenuClick}
                    handleMenuClose={handleMenuClose}
                    anchorEl={anchorEl}
                    handleMenuItemClick={handleMenuItemClick}
                  />
                </Box>
                <Box sx={{ display: 'inline-flex' }} alignItems={'center'}>
                  <Typography sx={loadLabelStyles}>
                    {t('manifestID')}:
                    <Typography
                      sx={{
                        ...loadCustomerStyle,
                        fontSize: '12px',
                        pr: '10px',
                        pl: '5px',
                      }}
                    >
                      {data.seqNumber}
                    </Typography>
                  </Typography>

                  <Divider
                    orientation="vertical"
                    sx={{
                      m: '0 6px',
                      width: '2px',
                      height: '12px',
                      background: 'rgb(85 91 97 / 60%)',
                    }}
                  />
                  <Typography sx={loadLabelStyles}>
                    {t('defaultEquipment')}:
                    <Typography
                      sx={{
                        ...loadCustomerStyle,
                        fontSize: '12px',
                        pl: '5px',
                      }}
                    >
                      {defaultEquipmentTypeOptions.filter(
                        (equipmentType) =>
                          equipmentType.key == data.equipmentTypes
                      )?.[0]?.value ?? data.equipmentTypes}
                    </Typography>
                  </Typography>
                </Box>
                <Box sx={{ display: 'inline-flex' }} alignItems={'center'}>
                  <Typography sx={loadLabelStyles}>
                    PO#:
                    <Typography
                      sx={{
                        ...loadCustomerStyle,
                        fontSize: '12px',
                        pl: '5px',
                      }}
                    >
                      {purchaseOrderNumber ?? '-'}
                    </Typography>
                  </Typography>
                  <Divider
                    orientation="vertical"
                    sx={{
                      m: '0 6px',
                      width: '2px',
                      height: '12px',
                      background: 'rgb(85 91 97 / 60%)',
                    }}
                  />
                  <Typography sx={loadLabelStyles}>
                    BOL#:
                    <Typography
                      sx={{
                        ...loadCustomerStyle,
                        fontSize: '12px',
                        pl: '5px',
                      }}
                    >
                      {bolNumber ?? '-'}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Stack>
          )}

          <Box sx={{ mt: 2 }}>
            <Typography
              variant="h7"
              sx={{
                color: 'primary.main',
              }}
            >
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                `Loads (${loads?.length} Loads)`
              )}
            </Typography>
          </Box>
          <Box>
            {isLoading ? (
              <Stack direction="column" mt={1} spacing={1}>
                <Skeleton
                  variant="rectangular"
                  height={'6rem'}
                  width={'100%'}
                  sx={{
                    borderRadius: '5px',
                  }}
                />
                <Skeleton
                  variant="rectangular"
                  height={'6rem'}
                  width={'100%'}
                  sx={{
                    borderRadius: '5px',
                  }}
                />
              </Stack>
            ) : (
              <>
                {loads?.map((load: LoadManifest, index: number) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      key={index}
                      spacing={2}
                      style={OverViewloadRemoveStyle}
                      className="OverViewloadRemoveStyle"
                    >
                      <Box sx={{ display: 'inline-grid' }}>
                        <Box
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography sx={loadIdStyle}>
                            {t('loadID')}
                            <Typography
                              sx={OverViewloadRemoveTitleStyle}
                              onClick={() => {
                                setSecondaryPanelData({
                                  id: load.id,
                                  type: ESecondaryDetailsPanelType.LOAD,
                                });
                              }}
                            >
                              {load.seqNumber}
                            </Typography>
                          </Typography>
                          {tripLoadCustomTitle({
                            status: load.loadStatus,
                            lastUpdatedStop: load.lastUpdatedStop,
                            nonInvoiceable: false,
                            onHold: !!load?.onHold,
                          })}
                        </Box>
                        {load.tags?.length > 0 && (
                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            gap={1}
                            sx={{ mt: '-5px', mb: '5px' }}
                          >
                            <TagList tags={load.tags} />
                          </Stack>
                        )}
                        <Box sx={{ display: 'inline-flex' }}>
                          <Typography sx={loadIconStyle}>
                            {load.pickupCityState}
                            <ArrowForwardIcon sx={loadIconStyle} />
                            {load.dropoffCityState}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: 'inline-flex' }}
                          alignItems={'center'}
                        >
                          <Typography sx={loadLabelStyles}>
                            {t('customer')}:
                            <Typography
                              sx={{
                                ...loadCustomerStyle,
                                fontSize: '12px',
                                pr: '10px',
                                pl: '5px',
                              }}
                            >
                              {load.customerName}
                            </Typography>
                          </Typography>

                          <Divider
                            orientation="vertical"
                            sx={{
                              m: '0 6px',
                              width: '2px',
                              height: '12px',
                              background: 'rgb(85 91 97 / 60%)',
                            }}
                          />
                          <Typography sx={loadLabelStyles}>
                            {t('ref')}:
                            <Typography
                              sx={{
                                ...loadCustomerStyle,
                                fontSize: '12px',
                                pl: '5px',
                              }}
                            >
                              {load.refNumber}
                            </Typography>
                          </Typography>

                          <Divider
                            orientation="vertical"
                            sx={{
                              m: '0 6px',
                              width: '2px',
                              height: '12px',
                              background: 'rgb(85 91 97 / 60%)',
                            }}
                          />

                          <Typography sx={loadLabelStyles}>
                            {'Priority'}:
                            <Typography
                              sx={{
                                ...loadCustomerStyle,
                                fontSize: '12px',
                                pl: '5px',
                              }}
                              fontWeight={600}
                            >
                              {LoadPriorityEnumVals[load?.loadPriority!] ?? ''}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>

                      {!onHold && (
                        <ButtonImproved
                          label={t('remove')}
                          variant={'outlined'}
                          disabled={!hasLoadEditPermission}
                          onClick={() => {
                            if (load.loadStatus == ELoadStatus.LOAD_COMPLETED) {
                              setOpenWarning(true);
                            } else {
                              setOpenDelete(true);
                              setSelectedLoad(load);
                            }
                          }}
                        ></ButtonImproved>
                      )}
                    </Stack>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
        {secondaryPanelData && (
          <SecondaryDetailsPanel
            isGlobal={true}
            type={secondaryPanelData.type}
            id={secondaryPanelData.id}
            onClose={() => setSecondaryPanelData(null)}
            onUpdated={(data) => handleSecondaryDetailsPanelSave(data)}
          />
        )}
        {editDialogOpen && (
          <FormDialog
            data={{
              ...data,
            }}
            titleText={'Edit'}
            actionLabel={'Save'}
            disableActionBtnByDefault={true}
            open={editDialogOpen}
            onAction={(data) => {
              handleUpdtaeManifest(data);
            }}
            handleClose={() => {
              setEditDialogOpen(false);
              handleMenuClose();
            }}
            contentRenderer={() => <ReferenceDetailsForm loadType={loadType} />}
          ></FormDialog>
        )}
        <DeletePopup
          title={t('removeLoad')}
          body={getDeleteBody()}
          subtitle={`${loads?.length === 1 ? t('subTile1') : t('subTitle2')}`}
          open={openDelete}
          onAction={async () => {
            removeLoad();
          }}
          okText={'Remove'}
          onClose={() => setOpenDelete(false)}
        />
        <DeletePopup
          title={openWarning ? t('warning') : ''}
          body={`Load cannot be removed as the 1st stop of load is completed.`}
          open={openWarning}
          hideDelete={true}
          backgroundColor={theme.palette.primary.main}
          onClose={() => setOpenWarning(false)}
        />
        <DeletePopup
          open={reqLoadRemove.open}
          onClose={() => {
            setReqLoadRemove({
              open: false,
              message: null,
            });
          }}
          title={'Info'}
          body={reqLoadRemove.message}
          hideDelete={true}
          cancelText={'Okay'}
          backgroundColor={theme.palette.primary.main}
          width={'440px'}
        />
      </>
    );
  }
);
