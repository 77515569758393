import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Grow,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { loadaiService } from '../../../../api';
import {
  allowDecimalNumbers,
  allowOnlyNumbers,
  allowPercentageOnly,
} from '../../../../services/validation.service';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../store/root.context';
import {
  IDriverPreferenceForm,
  PreferenceFormFieldType,
} from '../../../../types/DispatchTypes';
import {
  PreferenceFormDefaultState,
  preferenceFormConfig,
} from '../../config/preferences.config';
import {
  getEnginePreferenceRequest,
  savePreferenceRequest,
} from '../../network/recommendTrips.request';
import {
  PreferenceFormContainerStyles,
  PreferenceHeadingStyles,
  PreferenceModalContainerStyles,
  PreferenceModalInnerContainerStyle,
  PreferenceRadioInputStylesByIndex,
  PreferenceSettingsContainerStyles,
  PreferncelabelStyles,
} from '../../styles/preferenceSettings.style';
import Header from '../Header';
import PreferenceFooter from './PreferenceFooter';

interface PreferenceSettingsPopupProps {
  onClose?: () => void;
  onPreferenceUpdate?: () => void;
  sandboxId?: string;
  showCloseIcon?: boolean;
}

export type PreferenceStateType = {
  maxOriginDeadhead: number | string;
  maxDestinationDeadhead: number | string;
  maxTourDuration: number | string;
  maxTourMileage: number | string;
  maxLayoverTime: number | string;
  maxTripCountInATour: number | string;
  applySeniority?: boolean;
  defaultPaymentPerLoadedMile: number | string;
  defaultPaymentPerEmptyMile: number | string;
  defaultPaymentPerHour: number | string;
  defaultRevenueShare: number | string;
  maxDelayPerStop: number | string;
};

const labelWithProgressBar = (label: string, isLoading: boolean) => {
  if (isLoading)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {label}
        <CircularProgress
          size={12}
          sx={{ width: '12px', height: '12px', ml: 1 }}
        />
      </Box>
    );
  return label;
};

const PreferenceSettingsPopup: React.FC<PreferenceSettingsPopupProps> = (
  props
) => {
  const {
    dispatch2GlobalStore: { getBulkOptymizationInputs },
  } = useStores();
  const [formControl, setFormControl] = useState<PreferenceStateType>({
    ...PreferenceFormDefaultState,
  });
  const [defaultPreferencevalues, setDefaultPreferenceValues] =
    useState<PreferenceStateType>({
      ...PreferenceFormDefaultState,
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);

  useEffect(() => {
    if (!getBulkOptymizationInputs.openPreferenceSettings) return;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
    fetchDefaultPreferences();
  }, [getBulkOptymizationInputs.openPreferenceSettings]);

  const fetchDefaultPreferences = async () => {
    try {
      const preferenceRes = await getEnginePreferenceRequest({
        params: { sandboxId: props?.sandboxId },
      });
      const defaultParams = updatePreferenceFormVals(preferenceRes);
      setDefaultPreferenceValues({ ...defaultParams });
      setIsLoading(false);
    } catch (error) {
      handlePreferenceRequestFailure();
    }
  };

  const updatePreferenceFormVals = (preferences: any) => {
    const {
      maxOriginDeadhead,
      maxDestinationDeadhead,
      maxTourDuration,
      maxTourMileage,
      maxLayoverTime,
      maxTripCountInATour,
      applySeniority,
      defaultPaymentPerLoadedMile,
      defaultPaymentPerEmptyMile,
      defaultPaymentPerHour,
      defaultRevenueShare,
      maxDelayPerStop,
    } = preferences;
    const formVals = {
      maxOriginDeadhead,
      maxDestinationDeadhead,
      maxTourDuration,
      maxTourMileage,
      maxLayoverTime,
      maxTripCountInATour,
      applySeniority: Boolean(applySeniority),
      defaultPaymentPerLoadedMile,
      defaultPaymentPerEmptyMile,
      defaultPaymentPerHour,
      defaultRevenueShare,
      maxDelayPerStop: maxDelayPerStop ?? '',
    };
    setFormControl(formVals);
    return formVals;
  };

  const handlePreferenceRequestFailure = () => {
    setIsLoading(false);
    setIsReset(false);
  };

  const updateFormStateByKeyVal = (key: string, val: any) => {
    setFormControl({
      ...formControl,
      [key]: val,
    });
  };

  const updateFormStateWithCheckValues = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormControl({
      ...formControl,
      [event?.target?.id]: event?.target?.checked,
    });
  };
  const disableSaveBtn = useMemo(() => {
    if (
      !String(formControl?.maxTourDuration)?.length ||
      !String(formControl?.maxTourMileage)?.length ||
      !String(formControl?.maxLayoverTime)?.length ||
      !String(formControl?.maxTripCountInATour)?.length ||
      !String(formControl?.defaultPaymentPerLoadedMile)?.length ||
      !String(formControl?.defaultPaymentPerEmptyMile)?.length ||
      !String(formControl?.defaultPaymentPerHour)?.length ||
      !String(formControl?.defaultRevenueShare)?.length ||
      !String(formControl?.maxDelayPerStop)?.length
    )
      return true;
  }, [formControl]);

  const handleSave = async () => {
    try {
      setIsSave(true);
      await savePreferenceRequest({
        data: formControl,
        params: { sandboxId: props?.sandboxId },
      });
      setDefaultPreferenceValues({ ...formControl });
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'enginePreferenceSaved',
      });
      setIsSave(false);
      props?.onPreferenceUpdate?.();
    } catch (error: any) {
      console.log('error: ', error);
      setIsSave(false);
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'enginePreferenceFailure',
      });
    }
  };

  const isErrorField = (key: keyof typeof formControl, required: boolean) => {
    if (required && !isLoading) {
      if (!String(formControl[key])?.length) return true;
      return false;
    }
  };

  const handleReset = async () => {
    setIsReset(true);
    await resetPreferences();
    setIsReset(false);
  };

  const resetPreferences = async () => {
    try {
      const preferences = await loadaiService.getDriverDefaultParameters();
      updatePreferenceFormVals(preferences);
    } catch (error) {
      setIsReset(false);
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'enginePreferenceResetFailure',
      });
    }
  };

  const isFormStateChanged = (): boolean => {
    const formControlKeys = Object?.keys(formControl);
    for (const key of formControlKeys) {
      if (
        formControl?.[key as keyof typeof PreferenceFormDefaultState] !=
        defaultPreferencevalues?.[
          key as keyof typeof PreferenceFormDefaultState
        ]
      )
        return true;
    }
    return false;
  };

  const validateAndUpdateForm = (
    field: PreferenceFormFieldType,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const [key, value] = [event?.target?.id, event?.target?.value];
    if (field?.type === 'number' && !allowOnlyNumbers(value)) return;
    if (field?.type === 'decimal' && !allowDecimalNumbers(value)) return;
    if (field?.type === 'percentage' && !allowPercentageOnly(value)) return;
    updateFormStateByKeyVal(key, value);
  };

  const renderInputField = (field: PreferenceFormFieldType) => {
    switch (field?.type) {
      case 'number':
      case 'decimal':
      case 'percentage':
        return (
          <TextField
            id={field?.id}
            {...field?.inputConfig}
            fullWidth
            label={labelWithProgressBar(field.label!, isLoading)}
            variant="standard"
            value={formControl[field?.id as keyof typeof formControl]}
            error={isErrorField(
              field?.id as keyof typeof formControl,
              Boolean(field?.inputConfig?.required)
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              validateAndUpdateForm(field, event)
            }
            sx={{
              '.MuiFormLabel-asterisk': { color: '#F44336' },
              '.MuiFormLabel-root': { display: 'flex', fontSize: 12 },
              ...field?.inputConfig?.sx,
            }}
          />
        );
      case 'radioButton': {
        return (
          <Box display={'flex'} flexDirection={'column'} gap="8px">
            <Typography variant="caption" sx={PreferncelabelStyles}>
              {field.label}
            </Typography>
            <Stack direction={'row'}>
              {field?.options?.map((option, index: number) => (
                <Button
                  key={option?.value ?? option?.label}
                  variant={
                    formControl[field?.id as keyof typeof formControl] ==
                    option.value
                      ? 'contained'
                      : 'outlined'
                  }
                  sx={PreferenceRadioInputStylesByIndex({
                    index,
                    totalCount: field?.options?.length,
                  })}
                  onClick={() =>
                    updateFormStateByKeyVal(field?.id!, option?.value)
                  }
                >
                  {option?.label}
                </Button>
              ))}
            </Stack>
          </Box>
        );
      }
      default:
        return <></>;
    }
  };

  if (!getBulkOptymizationInputs.openPreferenceSettings) return <></>;

  return (
    <Modal
      open={true}
      aria-labelledby="bulk-optymization"
      aria-describedby="bulk-optymization-modal"
      sx={PreferenceModalContainerStyles}
    >
      <Grow in={true}>
        <Box sx={PreferenceModalInnerContainerStyle}>
          <Header
            title={'LoadAi Preferences'}
            showCloseIcon={props?.showCloseIcon}
            onClose={props?.onClose}
            titleStyles={{
              fontWeight: 400,
              fontSize: 16,
              width: '100%',
              justifyContent: 'center',
            }}
          />
          <Box sx={PreferenceSettingsContainerStyles}>
            <Box sx={PreferenceFormContainerStyles}>
              <Grid container>
                {preferenceFormConfig?.map(
                  (preference: Array<IDriverPreferenceForm>, i: number) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      key={i}
                      gap="20px"
                      display="flex"
                      flexDirection={'column'}
                      padding={'0 10px'}
                      sx={{
                        borderInlineEnd: i % 2 == 0 ? '1px solid #EEE' : '',
                      }}
                    >
                      {preference?.map((pref: IDriverPreferenceForm) => (
                        <Box
                          key={pref?.id}
                          gap="16px"
                          display="flex"
                          flexDirection={'column'}
                        >
                          <Typography
                            color={'primary'}
                            variant="h5"
                            sx={PreferenceHeadingStyles}
                          >
                            {pref?.heading}
                          </Typography>
                          {pref?.fields?.map((field) =>
                            renderInputField(field)
                          )}
                        </Box>
                      ))}
                    </Grid>
                  )
                )}
              </Grid>
              <PreferenceFooter
                onCancel={props?.onClose}
                onSave={handleSave}
                disableSubmit={disableSaveBtn || !isFormStateChanged()}
                onReset={handleReset}
                isResetLoader={isReset}
                isSaveLoader={isSave}
              />
            </Box>
          </Box>
        </Box>
      </Grow>
    </Modal>
  );
};

export default observer(PreferenceSettingsPopup);
