import { DocumentScannerOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { ReactChild, ReactFragment, ReactPortal } from 'react';
import { RelativeDateRangeUtils } from '../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { BaseStatusChip } from '../common/Ui/StatusComponent/StatusComponent';
import { LaneInfoEnum } from '../components/contract';
import { formatRateMethod } from '../components/contract/utils';
import { getCustomerNamesList } from '../subPages/invoices/utils';
import { ViewMap } from '../types';
import {
  getContractNamesList,
  getDateFromDateObject,
} from '../utils/contract_payload_formatter';

export const EXCEL_COLUMNS_HEADER = {
  contractName: 'name',
  customer: 'customerName',
  rates: 'rateCount',
  expirationDate: 'expDate',
} as any;
const ALL_CONTRACT_COLUMN_VISIBILITY = {
  contractName: true,
  status: true,
  customer: true,
  rates: true,
  rateMethod: true,
  effectiveDate: true,
  expirationDate: true,
  createdDate: true,
};
const COLUMN_ORDER = [
  'contractName',
  'status',
  'customer',
  'rates',
  'rateMethod',
  'effectiveDate',
  'expirationDate',
  'createdDate',
];

export const CONTRACT_STATUS_FILTERS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
};

const DEFAULT_CONTRACTS_FILTERS = {
  rateMethodFilter: [],
  contractName: [],
  customerName: [],
  status: [],
  effectiveFrom: '',
  effectiveTo: '',
  expireFrom: '',
  expireTo: '',
  createdFrom: '',
  createdTo: '',
};

export const TABS_CONSTANT = [
  {
    key: 'overview',
    label: 'Overview',
    iconName: 'InfoOutlined',
    source: 'MUIcons',
  },
  {
    key: 'contracts',
    label: 'Contracts',
    iconName: 'InfoOutlined',
    source: 'MUIcons',
  },
];

export const DEFAULT_CONTRACT_COLUMNS: (GridColDef & any)[] = [
  {
    field: 'contractName',
    headerName: 'Contract Name',
    minWidth: 140,
    hide: false,
    permanent: true,
    flex: 1,
    renderCell: (params: {
      value:
        | boolean
        | ReactChild
        | ReactFragment
        | ReactPortal
        | null
        | undefined;
    }) => {
      return <Typography sx={{ fontSize: 14 }}>{params.value}</Typography>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
    renderCell: (params: any) => (
      <BaseStatusChip
        value={params.value}
        config={{
          color: '#ffffff',
          backgroundColor:
            params.value == CONTRACT_STATUS_FILTERS.ACTIVE
              ? '#0C6BB9'
              : '#5C667D',
          label: (
            <Typography variant="caption" textTransform={'capitalize'}>
              {params.value?.toLowerCase()}
            </Typography>
          ),
        }}
      />
    ),
  },
  {
    field: 'customer',
    headerName: 'Customer',
    minWidth: 140,
    flex: 1,
    sortable: false,
    isHyperLink: true,
    renderCell: (params: {
      value:
        | boolean
        | ReactChild
        | ReactFragment
        | ReactPortal
        | null
        | undefined;
    }) => {
      return (
        <Typography sx={{ fontSize: 14, color: '#2352A6' }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'rates',
    headerName: '#Rates',
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'rateMethod',
    headerName: 'Rate Method',
    minWidth: 140,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatRateMethod(params?.value?.split('_')[0]);
    },
  },
  {
    field: 'effectiveDate',
    headerName: 'Effective Date',
    minWidth: 140,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      return getDateFromDateObject(params?.value);
    },
  },
  {
    field: 'expirationDate',
    headerName: 'Expiration Date',
    flex: 1,
    minWidth: 140,
    valueFormatter: (params: GridValueFormatterParams) => {
      return getDateFromDateObject(params?.value);
    },
  },
  {
    field: 'createdDate',
    headerName: 'Creation Date',
    flex: 1,
    minWidth: 140,
    valueFormatter: (params: GridValueFormatterParams) => {
      return getDateFromDateObject(params?.value);
    },
  },
];

export const CONTRACT_FILTERS = [
  {
    key: 1,
    name: 'contractName',
    fieldName: 'name',
    getData: getContractNamesList,
    label: 'Contract Name',
    isMultiLineOption: true,
    subFieldName: 'customerName',
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 26,
    name: 'status',
    options: [
      {
        value: CONTRACT_STATUS_FILTERS.ACTIVE,
        key: CONTRACT_STATUS_FILTERS.ACTIVE,
      },
      {
        value: CONTRACT_STATUS_FILTERS.EXPIRED,
        key: CONTRACT_STATUS_FILTERS.EXPIRED,
      },
    ],
    label: 'Status',
    type: 'ButtonGroup',
    multiple: true,
    fieldName: 'key',
    default: true,
  },
  {
    key: 3,
    name: 'customerName',
    fieldName: 'name',
    getData: getCustomerNamesList,
    label: 'Customer',
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 4,
    name: 'rateMethodFilter',
    options: [
      {
        value: LaneInfoEnum.city,
        key: 'City Based',
      },
      {
        value: LaneInfoEnum.zip,
        key: 'Zip Based',
      },
      {
        value: LaneInfoEnum.mileage,
        key: 'Mileage Based',
      },
    ],
    multiple: true,
    fieldName: 'key',
    label: 'Rate Method',
    type: 'ButtonGroup',
  },
  {
    key: 6,
    name: 'effectiveDate',
    label: 'Effective Date',
    default: false,
    ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
  },
  {
    key: 7,
    name: 'expDate',
    label: 'Expiration Date',
    default: false,
    ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
  },
  {
    key: 8,
    name: 'createdDate',
    label: 'Creation Date',
    default: false,
    ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
  },
];
export enum EContractsEntityList {
  ALL_CONTRACT = 'allContracts',
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export const DEFAULT_CONTRACT_VIEWS = [
  {
    id: EContractsEntityList.ALL_CONTRACT,
    shared: true,
    parentId: null,
    permanent: true,
    label: '',
    name: 'All Contracts',
    entity: EContractsEntityList.ALL_CONTRACT,
    icon: <DocumentScannerOutlined />,
    active: true,
    default: true,
    columnFilters: ALL_CONTRACT_COLUMN_VISIBILITY,
    metaData: { ...DEFAULT_CONTRACTS_FILTERS },
    columnsOrdering: COLUMN_ORDER,
  },
  {
    id: EContractsEntityList.ACTIVE,
    shared: true,
    parentId: null,
    label: '',
    name: 'Active',
    entity: EContractsEntityList.ACTIVE,
    icon: <DocumentScannerOutlined />,
    active: true,
    default: true,
    columnFilters: { ...ALL_CONTRACT_COLUMN_VISIBILITY },
    metaData: {
      ...DEFAULT_CONTRACTS_FILTERS,
      status: [
        {
          value: CONTRACT_STATUS_FILTERS.ACTIVE,
          key: CONTRACT_STATUS_FILTERS.ACTIVE,
        },
      ],
    },
    columnsOrdering: COLUMN_ORDER,
  },
  {
    id: EContractsEntityList.EXPIRED,
    shared: true,
    parentId: null,
    label: '',
    name: 'Expired',
    entity: EContractsEntityList.EXPIRED,
    icon: <DocumentScannerOutlined />,
    active: true,
    default: true,
    columnFilters: { ...ALL_CONTRACT_COLUMN_VISIBILITY },
    metaData: {
      ...DEFAULT_CONTRACTS_FILTERS,
      status: [
        {
          value: CONTRACT_STATUS_FILTERS.EXPIRED,
          key: CONTRACT_STATUS_FILTERS.EXPIRED,
        },
      ],
    },
    columnsOrdering: COLUMN_ORDER,
  },
];

export const FORM_DEFAULT_CONTRACT_VIEWS: ViewMap = {
  [EContractsEntityList.ALL_CONTRACT]: DEFAULT_CONTRACT_VIEWS[0],
  [EContractsEntityList.ACTIVE]: DEFAULT_CONTRACT_VIEWS[1],
  [EContractsEntityList.EXPIRED]: DEFAULT_CONTRACT_VIEWS[2],
};

export const LANE_INFO_COLUMN_MILEAGE = [
  'minMiles',
  'maxMiles',
  'weight',
  'equipmentType',
  'mode',
  'rateType',
  'rate',
  'description',
];

export const LANE_INFO_COLUMN_CITY = [
  'origin',
  'destination',
  'region',
  'totalMiles',
  'weight',
  'equipmentType',
  'mode',
  'rateType',
  'rate',
  'description',
];

export const LANE_INFO_COLUMN_ZIP = [
  'zipStartOrigin',
  'zipEndOrigin',
  'destinationStartOrigin',
  'destinationEndOrigin',
  'region',
  'equipmentType',
  'mode',
  'rateType',
  'rate',
  'description',
];

export const AUTOMATE_UNIT_VAL: Array<string> = [
  'PERCENT',
  'PER_EMPTY_MILE',
  'PER_LOADED_MILE',
  'PER_TOTAL_MILE',
  'PERCENT_MATRIX',
  'PRICE_PER_MILE_MATRIX',
];

export const GRID_DEFAULT_VALUES = {
  mode: 'FTL',
  rateType: 'FLAT_RATE',
  milageRateType: 'PER_LOADED_MILE',
  unit: 'PERCENT',
  region: 'US',
};
