import { Box, IconButton, Popover, Stack, Typography } from '@mui/material';
import {
  additionalDetailTextStyles,
  checkIconStyles,
  detailTextStyles,
  primaryDetailTextStyles,
  WarningIconContainer,
  TooltipPaperStyles,
} from '../styles';
import React, { useCallback } from 'react';
import {
  IResourceColumn,
  DetailsProps,
  AdditionalDetailProps,
  GanttPuckType,
} from '../types';
import { DriverTooltip } from './DriverTooltip';
import Checkbox from '@mui/material/Checkbox';
import { WarningIcon } from '../static';
import { GANTT_TOOLTIP_POPUP_ID } from '../config';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export const ResourceColumn: React.FC<IResourceColumn> = (props) => {
  const {
    columnDetails,
    additionalDetails,
    containerStyles,
    isCheckBox = false,
    onCheckBoxClick,
    checkBoxStyle,
    isWarningIcon = false,
    customWarningIcon,
    columnTooltipProps,
    resourceData,
    showColumnTooltipOnClick = false,
    warningIconIndexAt = 1,
    onResetClick,
  } = props;

  const getDetailStyles = useCallback((index: number) => {
    if (index === 0) return { ...detailTextStyles, ...primaryDetailTextStyles };
    return detailTextStyles;
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<{
    x: number;
    y: number;
  } | null>(null);

  const handleClick = (event: any) => {
    if (event?.target?.type === 'checkbox') return;
    setAnchorEl({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? GANTT_TOOLTIP_POPUP_ID : undefined;

  const handeDriverCheckbox = (event: React.ChangeEvent<any>) => {
    onCheckBoxClick?.({
      ...resourceData,
      columnDetails,
      checked: event.target?.checked,
      event,
    });
  };
  return (
    <>
      {columnTooltipProps && (
        <Popover
          id={id}
          open={open && showColumnTooltipOnClick}
          // anchorEl={anchorEl}
          anchorPosition={{
            left: anchorEl?.x as number,
            top: anchorEl?.y as number,
          }}
          anchorReference="anchorPosition"
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          sx={{
            '.MuiPaper-root': TooltipPaperStyles,
            ...columnTooltipProps.popoverStyles,
          }}
        >
          <DriverTooltip
            {...columnTooltipProps}
            onActionClick={(data: GanttPuckType) => {
              if (columnTooltipProps?.closeTooltipOnAction) setAnchorEl(null);
              columnTooltipProps?.onActionClick?.(data);
            }}
            onSubTitleClick={(data: GanttPuckType) => {
              if (columnTooltipProps?.closeTooltipOnAction) setAnchorEl(null);
              columnTooltipProps?.onSubTitleClick?.(data);
            }}
            onClose={handleClose}
          />
        </Popover>
      )}
      <Stack
        direction={'row'}
        sx={{
          width: '100%',
          alignItems: 'center',
          height: '100%',
          ...containerStyles,
        }}
        onClick={handleClick}
        aria-describedby={id}
      >
        {isCheckBox && (
          <Box sx={{ display: 'flex' }} alignItems="center">
            <Checkbox
              inputProps={{ 'aria-label': 'resource-select' }}
              onChange={handeDriverCheckbox}
              sx={{ ...checkIconStyles, ...checkBoxStyle }}
            />
          </Box>
        )}
        <Box
          marginRight={'5px'}
          sx={{
            display: 'flex',
            overflow: 'hidden',
            ...props?.detailsContainerStyles,
          }}
        >
          {columnDetails?.map((detail: DetailsProps, i: number) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                paddingLeft: i > 0 ? '4px' : 0,
                ...detail.containerStyles,
              }}
              key={detail.text}
            >
              {isWarningIcon && i == warningIconIndexAt && (
                <Box sx={{ ...WarningIconContainer }}>
                  {customWarningIcon ? customWarningIcon : <WarningIcon />}
                </Box>
              )}
              <Box
                sx={{
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  ...detail?.textContainerStyles,
                }}
                className={`${
                  detail.allowReset && detail.text?.length
                    ? 'resource-column-name'
                    : ''
                } ${detail.className ?? ''}`}
              >
                <Typography
                  key={detail.text}
                  style={{ ...getDetailStyles(0), ...detail.textStyles }}
                >
                  {detail.text}
                </Typography>
                {detail.allowReset && (
                  <IconButton
                    sx={{ width: '18px', height: '18px' }}
                    onClick={(event: any) => {
                      event?.stopPropagation?.();
                      onResetClick?.(detail);
                    }}
                    className="resource-reset-icon"
                  >
                    <AutorenewIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        color: '#2B64CB',
                        cursor: 'pointer',
                        pointerEvents: 'none',
                      }}
                    />
                  </IconButton>
                )}
              </Box>
              {i !== columnDetails?.length - 1 && `,`}
            </Box>
          ))}
        </Box>

        <Box sx={props?.additionalDetailsContainerStyles}>
          {additionalDetails?.map((detail: AdditionalDetailProps) => (
            <Box display={'flex'} key={detail.text} alignItems={'center'}>
              {detail.icon && detail.icon}
              <Typography
                style={{
                  ...additionalDetailTextStyles,
                  ...detail.textStyles,
                }}
              >
                {detail.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Stack>
    </>
  );
};
