import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { createContext, useContext } from 'react';
import { ediService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import { OptionType } from '../../models';
import {
  GetEDITenderResponseDTO,
  GetEDITendersRequest,
  PaginatedTenderListQueryParams,
  UpdateShipmentStatusDTO,
} from '../../models/DTOs/EDI/Requests';
import { EEDITendersEntity } from '../../views/EDITenders/constants/customView';
import { EDITendersFieldsConfig } from '../../views/EDITenders/constants/grid.constants';
import * as types from '../../views/EDITenders/constants/types';
import { IEDITendersSelectOption } from '../../views/EDITenders/constants/types';
import {
  getCustomviewTenderStatus,
  getLocationPayloadFormat,
} from '../../views/EDITenders/utils/api.utils';

interface IUpdateShipmentStatusData {
  action: types.EEDITendersGridAction;
  data: UpdateShipmentStatusDTO;
}

class EDITendersStore {
  @observable tableData: GetEDITenderResponseDTO = [];
  @observable gridFilters: types.IEDITendersFilters = {};
  @observable updateShipmentStatusData: IUpdateShipmentStatusData | null = null;
  @observable openEDITenderId: string | null = null;
  constructor() {
    makeObservable(this);
  }

  @computed
  get getOpenEDITenderId(): string | null {
    return toJS(this.openEDITenderId);
  }

  @action setOpenEDITenderId = (newValue: string | null) => {
    this.openEDITenderId = newValue;
  };

  @computed
  get getUpdateShipmentStatusData(): IUpdateShipmentStatusData | null {
    return toJS(this.updateShipmentStatusData);
  }

  @action setUpdateShipmentStatusData = (
    newValue: IUpdateShipmentStatusData | null
  ) => {
    this.updateShipmentStatusData = newValue;
  };

  @computed get getGridFilters(): types.IEDITendersFilters {
    return toJS(this.gridFilters);
  }

  @action setGridFilters = (newValue: types.IEDITendersFilters) => {
    this.gridFilters = newValue;
  };

  @computed get getTableData(): GetEDITenderResponseDTO {
    return toJS(this.tableData);
  }

  @action fetchEDITenders = async ({
    status,
    filters,
    pageNumber,
    pageSize,
    isScrolling,
  }: {
    status: EEDITendersEntity;
    filters: types.IEDITendersFilters;
    pageNumber: number;
    pageSize: number;
    isScrolling?: boolean;
  }) => {
    try {
      const payload = toPayload({
        filters,
        status,
      });
      const requestBody = new GetEDITendersRequest({
        ...payload,
      });
      const queryParams = new PaginatedTenderListQueryParams();
      queryParams.pageNumber = pageNumber;
      queryParams.pageSize = pageSize;
      const response = await ediService.getEDITenders(queryParams, requestBody);
      runInAction(() => {
        if (response instanceof ServiceError) {
          this.tableData = [];
        } else if (response) {
          const tenders = response?.content?.map((tender) => tender) ?? [];
          if (isScrolling) {
            this.tableData = [...this.tableData, ...tenders];
          } else {
            this.tableData = tenders;
          }
        }
      });
      return response;
    } catch (error) {
      this.tableData = [];
    }
  };
}

export const toPayload = ({
  status,
  filters,
}: {
  status: EEDITendersEntity;
  filters: types.IEDITendersFilters;
}) => {
  const payload = {
    status: getCustomviewTenderStatus(status),
    ...getLocationPayloadFormat('pickup', filters.pickup),
    ...getLocationPayloadFormat('dropOff', filters.dropoff),
    shipmentId:
      filters[EDITendersFieldsConfig.shipmentId.fieldFilterName]?.length &&
      filters[EDITendersFieldsConfig.shipmentId.fieldFilterName].map(
        ({ value }: IEDITendersSelectOption) => value
      ),
    respondBy: filters[EDITendersFieldsConfig.respondBy.fieldFilterName],
    // pickupLocation: '',
    // dropoffLocation: '',
    customer: filters[EDITendersFieldsConfig.customer.fieldFilterName]?.length
      ? filters[EDITendersFieldsConfig.customer.fieldFilterName].map(
          (op: OptionType) => op.value
        )
      : undefined,
  };
  return payload;
};

const EDITendersStoreContext = createContext(new EDITendersStore());

export const useEDITendersStore = () => useContext(EDITendersStoreContext);
