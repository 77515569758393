import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  ChangeStatusBulkRequest,
  DeleteExpenseRequest,
  DeletePayItemDTO,
  EntityListResDTO,
  ExpenseDetails,
  ExpenseListTotalsType,
  ExpensesListDataCount,
  FuelTypesListRequest,
  FuelTypesType,
  GetExpenseCategoriesListRequest,
  GetExpenseDetailsByIdRequest,
  GetExpenseListRequest,
  GetExpenseShortListByCardNumberRequest,
  GetExpenseShortListRequest,
  PaginatedExpenseCategoriesList,
  PaginatedExpenseList,
  PaginatedVendorNamesList,
  Payment,
  QueryParams,
  SaveExpenseRequest,
  SavePayItemRequest,
  SavePaymentRequest,
  TractorEntityListRequest,
  VendorNamesListRequest,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { IExpenseService, annotateNameAsync } from '../interfaces';

const getExpenseListURL = '/web/expense/api/v2/expense/list';
const getTotalsURL = '/web/expense/api/v2/expense/total';
const getExpenseShortListURL = '/web/expense/api/v2/expense/list/short';
const getExpenseShortListByCardNumberURL =
  '/web/expense/api/v2/expense/list/short/cardNumber';
const getExpenseListExportURL = '/web/expense//api/v2/expense/export-to-excel';

const getExpenseDetailsByIdURL = '/web/expense/api/v2/expense';

const getVendorNamesListUrl = '/web/expense/api/v2/vendors/list/name';
const getExpenseCategoriesListURL =  '/web/preference/api/v2/expense/items/getByOrganizationId';

const getFuelTypesListUrl = '/web/expense/api/v2/expense/fuelType/static';
const createExspenseURL = '/web/expense/api/v2/expense';
const deleteExspenseBulkURL = '/web/expense/api/v2/expense/delete/bulk';

const createDriverPaymentsURL =
  '/web/dpm/api/v2/payment/pay-item/create/pay-items';
const createTractorPaymentsURL =
  '/web/dpm/api/v2/payment/pay-item/create/pay-items';

const updateDriverPaymentsURL = '/web/dpm/api/v2/payment/pay-item/update/bulk';
const updateTractorPaymentsURL = '/web/dpm/api/v2/payment/pay-item/update/bulk';

const deleteDriverPaymentsURL = '/web/dpm/api/v2/payment/pay-item/delete';
const deleteTractorPaymentsURL = '/web/dpm/api/v2/payment/pay-item/delete';

const getExpenseListCountURL = '/web/expense/api/v2/expense/get/expense/count';
const changeStatusBulkURL = '/web/expense/api/v2/expense/updateStatus/bulk';
const getAssetList = 'web/people/api/v2/combined-asset/name/filter/pay-to';

export class ExpenseService extends IExpenseService {
  @annotateNameAsync
  async getExpenseList(
    queryParams: GetExpenseListRequest,
    fetchingType: string
  ): Promise<PaginatedExpenseList | undefined> {
    try {
      const response = await httpClient.getRaw(
        fetchingType === 'GET_DATA'
          ? getExpenseListURL
          : getExpenseListExportURL,
        queryParams,
        true,
        true
      );

      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getTotals(
    queryParams: GetExpenseListRequest
  ): Promise<ExpenseListTotalsType | undefined> {
    try {
      const response = await httpClient.getRaw(
        getTotalsURL,
        queryParams,
        true,
        true
      );
      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseListShort(
    queryParams: GetExpenseShortListRequest
  ): Promise<PaginatedExpenseList | undefined> {
    try {
      const response = await httpClient.getRaw(
        getExpenseShortListURL,
        queryParams,
        false,
        true
      );

      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseListShortByCardNumber(
    queryParams: GetExpenseShortListByCardNumberRequest
  ): Promise<PaginatedExpenseList | undefined> {
    try {
      const response = await httpClient.getRaw(
        getExpenseShortListByCardNumberURL,
        queryParams,
        false,
        true
      );

      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseDetailsById(
    queryParams: GetExpenseDetailsByIdRequest
  ): Promise<ExpenseDetails | undefined> {
    try {
      const response = await httpClient.getRaw(
        getExpenseDetailsByIdURL,
        queryParams
      );
      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getVendorNamesList(
    queryParams: VendorNamesListRequest
  ): Promise<PaginatedVendorNamesList | undefined> {
    try {
      const response = await httpClient.getRaw(
        getVendorNamesListUrl,
        queryParams,
        false,
        true
      );

      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseCategoriesList(
    queryParams: GetExpenseCategoriesListRequest
  ): Promise<PaginatedExpenseCategoriesList | undefined> {
    try {
      const response = await httpClient.getRaw(
        getExpenseCategoriesListURL,
        queryParams,
        true,
        true
      );

      return {
        content: response.data,
        last: true,
      } as PaginatedExpenseCategoriesList;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getFuelTypesList(
    queryParams: FuelTypesListRequest
  ): Promise<FuelTypesType[] | undefined> {
    try {
      const response = await httpClient.getRaw(
        getFuelTypesListUrl,
        queryParams,
        undefined,
        true
      );
      return response.data.fuelType;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getAssetListByEntity(
    queryParams: TractorEntityListRequest
  ): Promise<EntityListResDTO[] | undefined> {
    try {
      const response = await httpClient.getRaw(
        getAssetList,
        queryParams,
        undefined,
        true
      );
      return response?.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async createExpense(
    paylodData: SaveExpenseRequest
  ): Promise<ExpenseDetails | undefined> {
    try {
      const response = await httpClient.postRaw(
        createExspenseURL,
        undefined,
        paylodData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'expenseCreate',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'expenseCreate',
      });
      return Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async updateExpense(
    paylodData: SaveExpenseRequest
  ): Promise<ExpenseDetails | undefined> {
    try {
      const response = await httpClient.putRaw(
        createExspenseURL,
        undefined,
        paylodData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'expenseUpdate',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'expenseUpdate',
      });
      return Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async deleteExpensesBulk(
    queryParams: DeleteExpenseRequest
  ): Promise<string[] | undefined> {
    try {
      await httpClient.deleteRaw(deleteExspenseBulkURL, queryParams, null);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'expenseDelete',
      });
      return queryParams?.ids;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'expenseDelete',
      });
      return Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async createPaymentsBulk(
    paylodData: SavePaymentRequest
  ): Promise<Payment[] | undefined> {
    try {
      const data = [];
      const { payItemDTOs, ...rest } = paylodData;

      if (payItemDTOs?.length) {
        const response = await httpClient.postRaw(
          createDriverPaymentsURL,
          undefined,
          { ...rest, payItemDTOs: payItemDTOs },
          false
        );
        data.push(...response.data);
      }
      return data;
    } catch (error) {
      return Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async updatePaymentsBulk(
    paylodData: SavePayItemRequest
  ): Promise<Payment[] | undefined> {
    try {
      const data = [];
      const { payItemDTOs, ...rest } = paylodData;

      if (paylodData?.payItemDTOs?.length) {
        const response = await httpClient.putRaw(
          updateDriverPaymentsURL,
          undefined,
          { ...rest, payItemDTOs },
          false
        );
        data.push(response.data);
      }

      return data;
    } catch (error) {
      return Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async deletePaymentsBulk(
    payItemDTOs?: DeletePayItemDTO
  ): Promise<string[] | number[] | undefined> {
    try {
      let data: number[] = [];
      if (payItemDTOs?.ids?.length) {
        await httpClient.deleteRaw(deleteDriverPaymentsURL, payItemDTOs, null);
        data = [...data, ...(payItemDTOs?.ids ?? [])];
      }
      return data;
    } catch (error) {
      return Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async getExpenseListCount(
    queryParams: QueryParams
  ): Promise<ExpensesListDataCount | undefined> {
    try {
      return await httpClient.get<ExpensesListDataCount>(
        getExpenseListCountURL,
        queryParams,
        ExpensesListDataCount,
        true,
        true
      );
    } catch (error) {
      Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async changeStatusBulk(
    queryParams: ChangeStatusBulkRequest
  ): Promise<string | undefined> {
    try {
      return await httpClient.putRaw(changeStatusBulkURL, queryParams, true);
    } catch (error) {
      Promise.resolve(undefined);
    }
  }
}
