import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller } from 'react-hook-form';
import { countryCodeOptions } from '../../../constants/contacts';

import { SxProps, Theme } from '@mui/material';
import { t } from 'i18next';
import { PhoneType } from '../../../types/index';
import PhoneField from '../../../ui-kit/components/PhoneFieldWithContryandExtn/PhoneField';
const FormPhoneField: React.FC<{
  id?: string;
  name: string | undefined;
  control?: any;
  sizes?: {
    xs: number;
  };
  required?: boolean;
  onChangeCallback?: (data: PhoneType, name: string | undefined) => void;
  label?: string;
  countryCode?: string;
  disabled?: boolean;
  customeStyle?: SxProps<Theme> | undefined;
  phoneNumberCustomStyle?: SxProps<Theme> | undefined;
  phoneFieldStyleProps?: SxProps;
  containerStyles?: any;
}> = ({
  id = '',
  control,
  name = '',
  sizes = { xs: 12 },
  required = false,
  onChangeCallback,
  label = t('phone'),
  disabled,
  customeStyle = {},
  phoneFieldStyleProps,
  phoneNumberCustomStyle = {},
  containerStyles = { marginBottom: 20 },
}) => {
  const getErrorText = (error) => {
    if (!error) return;
    const { phone, extension } = error;
    if (phone) {
      if (extension) {
        if (phone.type === extension.type) return phone.message;
        return `${extension.message} ${phone.message}`;
      }
      return phone.message;
    }
    return extension?.message || '';
  };

  return (
    <Controller
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <Grid xs={sizes.xs} item style={{ ...containerStyles }}>
            <PhoneField
              id={id}
              label={label}
              required={required}
              countryCodeOptions={countryCodeOptions}
              error={!!error}
              helperText={getErrorText(error)}
              countryCode={value?.countryCode || 'USA'}
              number={value?.phone}
              extn={value?.extension}
              id="companyPhone"
              disabled={disabled}
              componentStyleProps={customeStyle}
              phoneNumberStyleProps={phoneNumberCustomStyle}
              onChange={(data: any) => {
                const changedData: PhoneType = {
                  countryCode: data.countryCode,
                  extension: data.extn,
                  phone: data.number,
                };
                {
                  onChange(changedData);
                  onChangeCallback && onChangeCallback(changedData, name);
                }
              }}
              phoneFieldStyleProps={phoneFieldStyleProps}
            />
          </Grid>
        );
      }}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

export default React.memo(FormPhoneField);
