export const driverToolTipContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // p: 1,
};
export const driverToolTipProgressTextStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '140%',
  textAlign: 'center',
  color: 'rgba(4, 0, 34, 0.75)',
};

export const driverToolTipSubContainerStyles = {
  m: '2px',
  p: '4px',
  whiteSpace: 'nowrap',
  textAlign: 'center',
};

export const driverToolTipAddressStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: '0.4px',
  color: 'rgba(4, 0, 34, 0.75)',
};

export const driverToolTipAddressSubTextStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.4px',
  color: 'rgba(0, 17, 34, 0.6)',
};

export const driverToolTipBottomContainerStyles = {
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
};

export const driverToolTipKpiTextStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: '#2B64CB',
};

export const driverToolTipTargetKpiTextStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: '0.4px',
  color: 'rgba(4, 0, 34, 0.75)',
};

export const driverToolTipTargetKpiStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
};

export const driverToolTipHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid rgba(102, 158, 255, 0.5)',
  ml: '-10px',
  pb: '5px',
  width: '250px',
  textAlign: 'center',
};

export const driverToolTipHeaderTitleStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  color: '#FFFFFF',
  maxWidth: '125px',
  minWidth: '80px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const driverToolTipHeaderSubTitleStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: '#5AC7F8',
  maxWidth: '111px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const driverToolTipHeaderSubValueStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#FFFFFF',
  mb: '-10px',
};

export const driverToolTipHeaderLastTimeValueStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#5AC7F8',
};

export const driverToolTipBtnIconStyles = {
  cursor: 'default',
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: '0.4px',
  color: '#5AC7F8',
};

const commonTextStyles = {
  lineHeight: '140%',
  letterSpacing: '0.15px',
  fontFamily: 'Poppins',
};

export const driverTitleStyles = {
  ...commonTextStyles,
  fontWeight: 700,
  fontSize: '16px',
  color: '#000000',
};

export const HOSTextStyles = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.4px',
  color: 'rgba(4, 0, 34, 0.75)',
  width: 'max-content',
  'word-break': 'break-word',
};

export const DriverTooltipContainerStyles = {
  width: '320px',
  background: '#FFFFFF',
  boxShadow: '0px 0px 9px 1px rgba(0, 0, 0, 0.26)',
  borderRadius: '8px',
  // maxHeight: '620px',
  // overflowY: 'hidden',
};
