import { Lock } from '@mui/icons-material';
import { Box, SxProps, Tooltip } from '@mui/material';
import { FC, ReactNode } from 'react';
import { InvoicesConstants } from '../../../locales/en/finance/invoices';
import { ECarrierStatus } from '../../../subPages/carriers/constants/types';
import { StatusTypes } from '../../../types';
import StatusBaseComponent from '../../../ui-kit/components/Assets/StatusComponent/StatusBaseComponent';
import { ELoadStatus } from '../../LoadTabPanel/constants/constants';
import {
  assetConfig,
  assetConfigUserStatus,
  brokeredTripConfig,
  expenseConfig,
  loadStatusConfigs,
  payStatementConfig,
  paymentManagementStatusConfig,
  teamDriverStatusConfigs,
} from './constants';

/**
 * Component takes status prop, which should come from BE.
 * In the case of BE property naming will be different should be changed it from the constants file "../constants"
 * */

export const AssetStatus: FC<{ status: string }> = ({ status }) => (
  <BaseStatusChip configs={assetConfig} value={status} />
);

export interface ILoadStatus extends Pick<TStatusChip, 'getIcon'> {
  status: ELoadStatus;
  theme?: 'light' | 'dark';
  nonInvoiceable?: boolean;
  onHold?: boolean;
}
export const LoadStatus: FC<ILoadStatus> = ({
  status,
  theme = 'light',
  nonInvoiceable,
  getIcon,
  onHold,
}) => {
  const configs = loadStatusConfigs(nonInvoiceable)[theme];
  let iconConfig: Pick<TStatusChip, 'getIcon'> = {};
  if (onHold)
    iconConfig = getOnHoldLockIcon({
      onHold: onHold!,
    });
  return (
    <BaseStatusChip
      configs={configs}
      value={status}
      getIcon={getIcon}
      {...iconConfig}
    />
  );
};

export const StopStatus: FC<
  {
    status: StatusTypes;
    superStatus: ELoadStatus;
    theme?: 'light' | 'dark';
  } & Pick<TStatusChip, 'getIcon'>
> = ({ status, getIcon, superStatus, theme = 'light' }) => {
  const configs = [
    ELoadStatus.CANCELLED,
    ELoadStatus.OFFERED_TO_DRIVER,
  ].includes(superStatus)
    ? loadStatusConfigs()[theme]
    : '';
  return (
    <BaseStatusChip
      config={{
        label: status.message,
        backgroundColor:
          configs && configs[superStatus]
            ? configs[superStatus]?.backgroundColor
            : '#0C6BB9',
        color:
          configs && configs[superStatus]
            ? configs[superStatus]?.color
            : '#f1f1f2',
      }}
      getIcon={getIcon}
    />
  );
};

export const ExpenseStatus: FC<{ status: string }> = ({ status }) => (
  <BaseStatusChip configs={expenseConfig} value={status} />
);

export const ExpenseCategory: FC<{ category: string }> = ({ category }) => (
  <BaseStatusChip
    config={{
      label: category,
      backgroundColor: 'primary.main',
      color: '#FFFFFF',
    }}
    containerStyles={{ width: 'fit-content', padding: 0 }}
  />
);

export const PayStatementStatus: FC<{ status: string }> = ({ status }) => (
  <Box sx={{ ml: 1 }}>
    <BaseStatusChip configs={payStatementConfig} value={status} />
  </Box>
);

export const PaymentManagementStatus: FC<{ status: string }> = ({ status }) => (
  <BaseStatusChip configs={paymentManagementStatusConfig} value={status} />
);

export const TeamDriverStatus: FC<{ active?: boolean }> = ({ active }) => (
  <BaseStatusChip
    configs={teamDriverStatusConfigs}
    value={active ? 'ACTIVE' : 'INACTIVE'}
  />
);

export const UserCustomStatus: FC<{ status: number }> = ({ status }) => (
  <BaseStatusChip configs={assetConfigUserStatus} value={status} />
);

export interface IStatusConfig {
  label: string | ReactNode;
  color: string;
  backgroundColor: string;
}

export interface IStatusConfigs {
  [key: string]: IStatusConfig;
}

export interface TStatusChip {
  configs?: IStatusConfigs;
  value?: keyof IStatusConfigs;
  config?: IStatusConfig;
  getIcon?: ({ color: string }) => JSX.Element;
  containerStyles?: SxProps;
}

export const BaseStatusChip: FC<TStatusChip> = ({
  configs,
  value,
  config: configProp,
  getIcon,
  containerStyles = {},
}) => {
  const config = configProp || (value !== undefined && configs?.[value]);
  if (!config) return null;
  return (
    <Box
      className="BaseStatusChip-root"
      sx={{
        backgroundColor: 'leftMenuExpanded.active',
        borderRadius: '16px',
        padding: '2px',
        ...containerStyles,
      }}
    >
      <StatusBaseComponent
        {...(getIcon && {
          icon: getIcon({ color: config.color }),
        })}
        label={config.label}
        customStyles={{
          label: {
            color: config.color,
          },
          wrap: {
            backgroundColor: config.backgroundColor,
          },
        }}
      />
    </Box>
  );
};

export function CarrierStatus({ status }: { status: ECarrierStatus }) {
  return <TeamDriverStatus active={status === ECarrierStatus.ACTIVE} />;
}

export const BrokeredTripStatus = () => (
  <BaseStatusChip configs={brokeredTripConfig} value={'BROKERED'} />
);

export const getOnHoldLockIcon = ({
  onHold,
  title,
}: {
  onHold: boolean;
  title?: string;
}): Pick<TStatusChip, 'getIcon'> => {
  const iconConfig: Pick<TStatusChip, 'getIcon'> = {
    ...(onHold && {
      getIcon: ({ color }: { color: string }) => {
        return (
          <Tooltip placement="top-start" title={title} arrow>
            <Lock
              fontSize="small"
              sx={{
                width: '14px',
                height: '14px',
                color: color,
              }}
            />
          </Tooltip>
        );
      },
    }),
  };
  return iconConfig;
};

export const InvoiceStatus = ({
  onHold,
  onHoldNote,
  ...props
}: ILoadStatus & { onHold: boolean; onHoldNote?: string }) => {
  const iconConfig: Pick<TStatusChip, 'getIcon'> = getOnHoldLockIcon({
    onHold,
    title: onHoldNote
      ? `${InvoicesConstants.invoiceIsOnHold}: ${onHoldNote}`
      : InvoicesConstants.invoiceIsOnHold,
  });
  return <LoadStatus {...props} {...iconConfig} />;
};
