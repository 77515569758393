import {
  IAccountingService,
  IAlertsService,
  IBasicReportsService,
  ICommonAlertService,
  IContactsService,
  IContractervice,
  ICustomViewService,
  ICustomerService,
  IDocumentService,
  IExpenseService,
  IExportService,
  IFactoringService,
  IFinanceLoadService,
  IGeoBusService,
  IHomeService,
  IIFTAService,
  IImportService,
  IInvoiceService,
  ILayoutService,
  ILoadaiService,
  ILocationService,
  IMaintenanceAssetTractorService,
  IMaintenanceEquipmentService,
  IMaintenanceHistoryService,
  IMaintenanceItemService,
  IMaintenanceVendorService,
  INotesService,
  IPayStatementService,
  IPaymentTermsService,
  IPreferencesService,
  IRecurrenceService,
  IRolesService,
  IStaticService,
  ISubscriptionService,
  ITeamDriverService,
  ITerminalService,
  ITractorService,
  ITrendAnalysisService,
  ITripService,
  IUserService,
  IUtilsService,
  IVendorService,
  IVisibilityService,
  ServiceCallsArgs,
  IFuelCardService,
  IFuelMatrixService,
  IRulesService,
} from './interfaces';

import {
  AccountingSevice,
  AlertsService,
  BasicReportsService,
  CommonAlertService,
  ContactsService,
  ContractService,
  CustomViewService,
  CustomerService,
  DocumentService,
  ExcportService,
  ExpenseService,
  FactoringService,
  FinanceLoadService,
  GeoBusService,
  HomeService,
  ImportService,
  InvoiceService,
  LayoutService,
  LoadTenderService,
  LocationService,
  MaintenanceAssetTractorService,
  MaintenanceEquipmentService,
  MaintenanceHistoryService,
  MaintenanceItemService,
  MaintenanceVendorService,
  NotesService,
  PayStatementService,
  PaymentTermsService,
  PreferenceSevice,
  PreferencesService,
  RecurrenceService,
  RolesService,
  StaticService,
  SubscriptionService,
  TeamDriverService,
  TerminalService,
  TractorService,
  TripService,
  UserService,
  UtilsService,
  VendorService,
  VisibilityService,
  FuelMatrixService,
  FuelCardService,
  RulesService,
} from './impl';

import { ELDService } from './impl/ELDService';
import { FinanceIntegrationService } from './impl/FinanceIntegrationService';
import { TrailerService } from './impl/TrailerService';

import { IELDService } from './interfaces/IELDService';
import { ITrailerService } from './interfaces/ITrailerService';
import { Service } from './interfaces/Service';

import { LoadboardServices } from './impl/LoadboardServices';
import { ILoadboardService } from './interfaces/ILoadboardServices';

import { LoadService } from './impl/LoadService';
import { ILoadService } from './interfaces/ILoadService';

import { DispatchService } from './impl/DispatchService';
import { IFTAService } from './impl/IFTAService';
import { LoadsearchServices } from './impl/LoadsearchServices';
import { TrendAnalysisService } from './impl/TrendAnalysisService';
import { IDispatchService } from './interfaces/IDispatchService';

import { FinanceAccountSummaryService } from './impl/FinanceAccountSummaryService';
import { IFinanceAccountSummaryService } from './interfaces/IFinanceAccountSummaryService';

import { CarrierService } from './impl/CarrierService';
import { DriverService } from './impl/DriverService';
import { EDIService } from './impl/EDIService';
import { EmailService } from './impl/EmailService';
import { HubspotService } from './impl/HubspotService';
import { LoadaiService } from './impl/LoadaiService';
import { ManageTypesService } from './impl/ManageTypesService';
import { PaymentService } from './impl/PaymentService';
import { TemplateService } from './impl/TemplateService';
import { ICarrierService } from './interfaces/ICarrierService';
import { IDriverService } from './interfaces/IDriverService';
import { IEDIService } from './interfaces/IEDIService';
import { IEmailService } from './interfaces/IEmailService';
import { IHubspotService } from './interfaces/IHubspotService';
import { ILoadTenderService } from './interfaces/ILoadTenderServices';
import { ILoadsearchService } from './interfaces/ILoadsearchServices';
import { IManageTypesService } from './interfaces/IManageTypesService';
import { IPaymentService } from './interfaces/IPaymentService';
import { ITemplateService } from './interfaces/ITemplateService';
import { IAdvancedReportService } from '@/api/interfaces/IAdvancedReportService';
import { AdvancedReportService } from '@/api/impl/AdvancedReportService';

function traceMethod<T extends Service>(obj: T) {
  const handler = Object.assign(obj, {
    get(target: any, propKey: string) {
      const origMethod = target[propKey];
      return (...args: any[]) => {
        ServiceCallsArgs.Instance.logMethodCall(
          obj.constructor.name,
          propKey,
          args
        );

        const result = origMethod.apply(this, args);
        return result;
      };
    },
  });
  return new Proxy(obj, handler);
}

export const utilsService: IUtilsService = traceMethod(new UtilsService());
export const tractorService: ITractorService = traceMethod(
  new TractorService()
);
export const trailerService: ITrailerService = traceMethod(
  new TrailerService()
);
export const layoutService: ILayoutService = traceMethod(new LayoutService());
export const loadService: ILoadService = traceMethod(new LoadService());
export const templateService: ITemplateService = traceMethod(
  new TemplateService()
);
export const vendorService: IVendorService = traceMethod(new VendorService());
export const customerService: ICustomerService = traceMethod(
  new CustomerService()
);

export const documentService: IDocumentService = traceMethod(
  new DocumentService()
);

export const contactsService: IContactsService = traceMethod(
  new ContactsService()
);
export const factoringService: IFactoringService = traceMethod(
  new FactoringService()
);

export const exportService: IExportService = traceMethod(new ExcportService());

export const locationService: ILocationService = traceMethod(
  new LocationService()
);

export const terminalService: ITerminalService = traceMethod(
  new TerminalService()
);
export const eldService: IELDService = traceMethod(new ELDService());
export const loadboardService: ILoadboardService = traceMethod(
  new LoadboardServices()
);
export const loadsearchService: ILoadsearchService = traceMethod(
  new LoadsearchServices()
);
export const userService: IUserService = traceMethod(new UserService());

//Maintenance page
export const maintenanceHistoryService: IMaintenanceHistoryService =
  traceMethod(new MaintenanceHistoryService());
export const maintenanceItemService: IMaintenanceItemService = traceMethod(
  new MaintenanceItemService()
);
export const maintenanceEquipmentService: IMaintenanceEquipmentService =
  traceMethod(new MaintenanceEquipmentService());
export const maintenanceVendorService: IMaintenanceVendorService = traceMethod(
  new MaintenanceVendorService()
);
export const maintenanceAssetTractorService: IMaintenanceAssetTractorService =
  traceMethod(new MaintenanceAssetTractorService());
//Maintenance page

export const viewService: ICustomViewService = traceMethod(
  new CustomViewService()
);

export const preferenceSevice = new PreferenceSevice();

export const basicReportsService: IBasicReportsService = traceMethod(
  new BasicReportsService()
);

export const financeIntegrationService: IFactoringService =
  new FinanceIntegrationService();

export const alertsService: IAlertsService = traceMethod(new AlertsService());

export const accountingService: IAccountingService = traceMethod(
  new AccountingSevice()
);
export const importService: IImportService = traceMethod(new ImportService());

export const recurrenceService: IRecurrenceService = traceMethod(
  new RecurrenceService()
);
export const expenceService: IExpenseService = traceMethod(
  new ExpenseService()
);

export const teamDriverServiceType: ITeamDriverService = traceMethod(
  new TeamDriverService()
);

export const visibilityService: IVisibilityService = traceMethod(
  new VisibilityService()
);
export const paymentService: IPaymentTermsService = traceMethod(
  new PaymentTermsService()
);

export const commonAlertService: ICommonAlertService = traceMethod(
  new CommonAlertService()
);

export const trendAnalysisService: ITrendAnalysisService = traceMethod(
  new TrendAnalysisService()
);
export const subscriptionService: ISubscriptionService = traceMethod(
  new SubscriptionService()
);
export const iftaService: IIFTAService = traceMethod(new IFTAService());
export const invoiceService: IInvoiceService = traceMethod(
  new InvoiceService()
);
export const staticService: IStaticService = traceMethod(new StaticService());

export const manageTypesService: IManageTypesService = traceMethod(
  new ManageTypesService()
);

export const preferencesService: IPreferencesService = traceMethod(
  new PreferencesService()
);

export const notesService: INotesService = traceMethod(new NotesService());
export const financeLoadService: IFinanceLoadService = traceMethod(
  new FinanceLoadService()
);

export const tripService: ITripService = traceMethod(new TripService());

export const payStatementService: IPayStatementService = traceMethod(
  new PayStatementService()
);

export const homeService: IHomeService = traceMethod(new HomeService());

export const geoBusService: IGeoBusService = traceMethod(new GeoBusService());

export const dispatchService: IDispatchService = traceMethod(
  new DispatchService()
);

export const financeAccountSummaryService: IFinanceAccountSummaryService =
  traceMethod(new FinanceAccountSummaryService());

export const emailService: IEmailService = traceMethod(new EmailService());
export const ediService: IEDIService = traceMethod(new EDIService());
export const hubspotService: IHubspotService = traceMethod(
  new HubspotService()
);

export const carrierService: ICarrierService = traceMethod(
  new CarrierService()
);

export const loadTenderService: ILoadTenderService = traceMethod(
  new LoadTenderService()
);

export const driverService: IDriverService = traceMethod(new DriverService());

export const loadaiService: ILoadaiService = traceMethod(new LoadaiService());

export const fuelCardService: IFuelCardService = traceMethod(
  new FuelCardService()
);

export const rolesService: IRolesService = traceMethod(new RolesService());
export const paymentManagementService: IPaymentService = traceMethod(
  new PaymentService()
);
export const contractServive: IContractervice = traceMethod(
  new ContractService()
);
export const fuelMatrixService: IFuelMatrixService = traceMethod(
  new FuelMatrixService()
);
export const advancedReportService: IAdvancedReportService = traceMethod(
  new AdvancedReportService()
);
export const rulesService: IRulesService = traceMethod(new RulesService());
