import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ChangeEvent } from 'react';
import { Checkboxes as Checkbox } from '../Checkbox/Checkbox';
///////////////////////////////////////////////////
import { Icon } from '../../shared/Icon';
import AutocompleteField from '../Autocomplete';
import ButtonImproved from '../ButtonImproved';
import PickIconInput from '../PickIconInput';
import SideDrawer from '../SideDrawer';
import TextInput from '../TextField';
/////////////////////////////////////////////////
import { AxeleValues, Target } from './interface';
import { t } from 'i18next';

const AxeleAlertDrawer = ({
  isOpen = true,
  alertType = 'Load',
  action = 'add',
  data = {},
  editData = {},
  onClose,
  onSubmit,
  getLoadUserRoles,
  alertTypes = [],
  loadUserRoles = [],
  frequency = [],
  warning = [],
  frequencyDropDown = [],
  warningDropDown = [],
  values,
  setValues,
  editMode = false,
  validation = {},
  alertTypeDisabled = false,
  setValidation,
}: any) => {
  const alertTypeSel = `${alertType} Alert Types`;
  const errorText = 'Required';
  const handleChange = (
    e:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { checked, value, name }: Target = e.target;
    setValues((vals: AxeleValues) => ({
      ...vals,
      [name]: value !== 'on' && value !== 'off' ? value : checked,
    }));
    if (name == 'Allow dispatch if expired')
      setValues((vals: AxeleValues) => ({
        ...vals,
        ['If Expired, Prevent Dispatch']:
          value !== 'on' && value !== 'off' ? value : checked,
      }));
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(values, validation);
  };

  const getDefault = (
    arr: Array<object>,
    findKey: Array<string> | string | null | undefined,
    multiple = false
  ) => {
    if (multiple) {
      return arr.filter((obj: any) => findKey?.includes(obj.key));
    }
    return findKey === null ? [] : arr.find((obj: any) => obj.key === findKey);
  };

  const onChangeField = (event: any, error: boolean, id: string) => {
    const tempValues = {
      ...values,
      [id]: id == 'icon' ? event : event.target.value,
    };
    setValues(tempValues);
    if (id == 'customFrequencyEvery') {
      let tempValidation = { ...validation };
      if (event.target.value != '' && !/^[0-9]+$/.test(event.target.value))
        tempValidation = { ...validation, [id]: 'Please enter Numbers' };
      else if (event.target.value != '' && event.target.value > 2000)
        tempValidation = {
          ...validation,
          [id]: 'Please enter less than 2000 days',
        };
      else tempValidation = { ...validation, [id]: '' };
      setValidation(tempValidation);
    }
  };

  const onChangeData = (
    event: any,
    newValue: any,
    error: boolean,
    id: string
  ) => {
    if (newValue) {
      let tempValues = { ...values };
      if (id == 'loadUserRoles') {
        const filterData = newValue.map((item: any) => item.key);
        tempValues = { ...values, [id]: filterData };
      } else {
        tempValues = { ...values, [id]: newValue.key };
        if (id == 'loadAlertsType' && alertType == 'Load') {
          getLoadUserRoles(newValue.key);
        } else if (
          id == 'loadAlertsType' &&
          (alertType == 'Trailer' ||
            alertType == 'Tractor' ||
            alertType == 'Driver')
        ) {
          tempValues.uerdefinedLabel = newValue.label;
        }
      }
      setValues(tempValues);
    }
  };

  const axeleContent = (
    <Box height="100%" width={550} sx={{ padding: 2 }}>
      <Box height="80%">
        <Stack direction="row" justifyContent="space-between" height="15%">
          <Box
            sx={(theme: any) => ({
              color: theme.palette.primary.main,
              letterSpacing: '0.19px',
              fontSize: '25.33px',
            })}
          >
            {action == 'add' ? 'Add' : 'Edit'} {alertType} Alert
          </Box>
          <Icon
            icon="closeDrawer"
            height="17px"
            width="16px"
            onClick={onClose}
            fill="primary"
            viewBox="0 0 16 17"
            style={{ cursor: 'pointer' }}
          />
        </Stack>

        <Stack
          height="85%"
          sx={{
            marginTop: 4,
            overflowY: 'auto',
            '& .MuiSelect-select, & .MuiInput-input, & .MuiFormControlLabel-label':
              {
                color: 'rgba(0, 17, 34, 0.75)',
              },
          }}
          spacing={3}
        >
          {alertTypeDisabled &&
          values.loadAlertsType &&
          values.loadAlertsType == 'USERDEFINED' ? (
            <TextInput
              id="loadAlertTypeDisabled"
              label={alertTypeSel}
              styleProps={{ width: '95%' }}
              onChange={onChangeField}
              variant="standard"
              defaultValue={
                values.uerdefinedLabel ? values.uerdefinedLabel : ''
              }
              disabled={true}
            />
          ) : (
            <AutocompleteField
              id="loadAlertsType"
              styleProps={{ width: '95%' }}
              options={alertTypes || []}
              defaultValue={getDefault(alertTypes, values.loadAlertsType)}
              label={alertTypeSel}
              variant="standard"
              required={true}
              onChange={onChangeData}
              error={validation.loadAlertsType}
              helperText={errorText}
              disabled={alertTypeDisabled}
            />
          )}

          {alertType === 'Tractor' && (
            <Checkbox
              onChange={handleChange}
              state="checked"
              checked={values['Time-Based Frequency/Warning']}
              appearance="primary"
              label="Time-Based Frequency/Warning"
            />
          )}

          {alertType !== 'Load' &&
            (alertType !== 'Tractor' ||
              (alertType === 'Tractor' &&
                values['Time-Based Frequency/Warning'])) && (
              <AutocompleteField
                id="frequency"
                styleProps={{ width: '95%' }}
                options={frequencyDropDown || []}
                defaultValue={getDefault(frequencyDropDown, values.frequency)}
                label="Frequency"
                variant="standard"
                required={true}
                onChange={onChangeData}
                error={validation.frequency}
                helperText={errorText}
              />
            )}

          {values.frequency === 'CUSTOM' && (
            <TextInput
              id="customFrequencyEvery"
              label="Custom Frequency Every Day(s)"
              variant="standard"
              defaultValue={values.customFrequencyEvery}
              styleProps={{ width: '95%' }}
              onChange={onChangeField}
              helperText={validation['customFrequencyEvery']}
              error={
                !validation['customFrequencyEvery'] ||
                validation['customFrequencyEvery'] == ''
                  ? false
                  : true
              }
            />
          )}

          {alertType !== 'Load' &&
            (alertType !== 'Tractor' ||
              (alertType === 'Tractor' &&
                values['Time-Based Frequency/Warning'])) && (
              <AutocompleteField
                id="warning"
                styleProps={{ width: '95%' }}
                options={warningDropDown || []}
                defaultValue={getDefault(warningDropDown, values.warning)}
                label="Warning"
                variant="standard"
                required={true}
                onChange={onChangeData}
                error={validation.warning}
                helperText={errorText}
              />
            )}

          {alertType === 'Tractor' && (
            <Checkbox
              onChange={handleChange}
              state="checked"
              checked={values['Miles-Based Frequency/Warning']}
              appearance="primary"
              label="Miles-Based Frequency/Warning"
            />
          )}

          {alertType === 'Tractor' &&
            values['Miles-Based Frequency/Warning'] && (
              <>
                <TextInput
                  id="milesBasedFrequency"
                  label="Frequency (miles)"
                  styleProps={{ width: '95%' }}
                  onChange={onChangeField}
                  variant="standard"
                  defaultValue={values.milesBasedFrequency}
                  helperText={errorText}
                  error={validation.milesBasedFrequency}
                />
                <TextInput
                  id="milesBeforeWarning"
                  label="Miles before warning"
                  styleProps={{ width: '95%' }}
                  onChange={onChangeField}
                  variant="standard"
                  defaultValue={values.milesBeforeWarning}
                  helperText={errorText}
                  error={validation.milesBeforeWarning}
                />
              </>
            )}

          <AutocompleteField
            multiple={true}
            id="loadUserRoles"
            styleProps={{ width: '95%' }}
            options={loadUserRoles || []}
            defaultValue={getDefault(loadUserRoles, values.loadUserRoles, true)}
            label="Send Alerts to"
            variant="standard"
            onChange={onChangeData}
            error={validation.loadUserRoles}
            helperText={errorText}
          />

          <PickIconInput
            width="95%"
            icon={values.icon}
            onChange={(e: string) => {
              onChangeField(e, false, 'icon');
            }}
          />
          <Stack display="flex" flexDirection="row">
            {alertType === 'Load' && (
              <Checkbox
                onChange={handleChange}
                state="checked"
                appearance="primary"
                checked={values['Notification']}
                label="Notification"
              />
            )}
            <Checkbox
              onChange={handleChange}
              state="checked"
              appearance="primary"
              label="Notify via Phone"
              checked={values['Notify via Phone']}
            />
            <Checkbox
              onChange={handleChange}
              state="checked"
              appearance="primary"
              label="Notify via Email"
              checked={values['Notify via Email']}
            />
          </Stack>

          {alertType !== 'Load' && (
            <Stack>
              <Checkbox
                onChange={handleChange}
                state="checked"
                checked={values['If Expired, Prevent Dispatch']}
                appearance="primary"
                label="Allow dispatch if expired"
              />
            </Stack>
          )}
        </Stack>
      </Box>

      <Stack
        direction="row"
        justifyContent="flex-end"
        width="95%"
        sx={{ marginTop: 6 }}
        spacing={1}
      >
        <ButtonImproved
          id="CancelButton"
          size="medium"
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          sx={{
            width: '90px',
          }}
        />
        <ButtonImproved
          id="addAlertButton"
          label={action == 'add' ? t('addAlert') : 'Save'}
          size="medium"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            minWidth: '90px',
          }}
        />
      </Stack>
    </Box>
  );

  return (
    <SideDrawer
      isOpen={isOpen}
      drawerContent={axeleContent}
      drawerRadius="19.3689px"
      paperPadding="20px"
      top={'60px'}
      bottom="unset"
      height={alertType != 'Tractor' ? 'auto' : '100%'}
    />
  );
};

export default AxeleAlertDrawer;
