import { QueryParams } from '../commonMixed';

export class GetManageTypesRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  itemIds?: number[];
  itemName?: string;
  type?: string;
}

export class DeleteManageTypeRequest extends QueryParams {
  id!: string;
}

export class CreateManageTypeRequest extends QueryParams {
  itemName!: string;
  description!: string | null;
  iconName!: string | null;
  createdBy!: string;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
  unit?: string[] | null;
}

export class UpdateManageTypeRequest extends QueryParams {
  itemName!: string;
  description!: string | null;
  iconName!: string | null;
  createdBy!: string;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
  unit?: string[] | null;
  id!: string;
}

export type ManageTypesItem = {
  id: string;
  itemName: string;
  itemCode?: string;
  itemType?: string;
  description: string;
  custom: boolean;
  iconName: string | null;
  createdBy: string | null;
  createDate: string | null;
  deduction: boolean;
  invoiceItem?: boolean;
  settlementItem?: boolean;
  unit?: string[] | null;
};

export type CreateManageFormDataType = {
  itemName: string;
  description: string;
  iconName: string;
  unit?: string[] | UnitType[] | null;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
};

export type UpdateManageFormDataType = {
  itemName: string;
  description: string;
  iconName: string;
  unit?: string[] | UnitType[] | null;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
  id: string;
};

export type UnitType = {
  value: string;
  label: string;
};

export class GetTagsRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  entityTypes?: TagEntityType[];
  searchText?: string;
}

export class CreateTagRequest extends QueryParams {
  name!: string;
  description!: string;
  color!: string;
  entityTypes!: TagEntityType[];
  active!: boolean;
  tagType!: TagType;
}

export type CreateUpdateTagFormType = {
  name: string;
  description: string;
  color: string;
  entityTypes: { key: TagEntityType; value: string }[];
};

export class DeleteTagRequest extends QueryParams {
  ids!: string[];
}

export class DuplicateTagRequest extends QueryParams {
  id!: string;
}

export class UpdateTagRequest extends QueryParams {
  id!: string;
  name!: string;
  description!: string;
  color!: string;
  entityTypes!: TagEntityType[];
  active!: boolean;
  tagType!: TagType;
};

export class GetTagColorsRequest extends QueryParams {

};

export enum TagEntityType {
  'LOAD' = 'LOAD',
  'DRIVER' = 'DRIVER',
  'USER' = 'USER',
}

export enum TagType {
  USER_DEFINED = 'USER_DEFINED',
  SYSTEM_DEFINED = 'SYSTEM_DEFINED',
}

export interface TagItem {
  id: string;
  name: string;
  color: string;
  description: string | null;
  entityTypes: TagEntityType[];
  isActive: boolean;
  tagType: TagType;
  textColor: string;
}

export interface TagColor {
  color: string;
  hexCode: string;
  label: string;
  usageCount: number;
  textColor: string;
}
