import * as yup from 'yup';
import { transformEmptyStringToNull, transformEmptyStringToNumber } from '.';
import { ActivityType } from '../types/types';

export const stopTypeOptions: ActivityType[] = [
  'PICKUP',
  'PICKUP_AND_DROPOFF',
  'RELAY',
  'TRIP_STOP',
  'DROPOFF',
] as const;

export enum StopTypeOptionsEnum {
  Pickup = 'Pickup',
  PickupAndDropOff = 'Pickup & Drop Off',
  Relay = 'Relay',
  DropOff = 'Drop Off',
  TripStop = 'Trip Stop',
}

export const StopTypeOptionsValues = {
  PICKUP: 'Pickup',
  PICKUP_AND_DROPOFF: 'Pickup & Drop Off',
  RELAY: 'Relay',
  DROPOFF: 'Drop Off',
  TRIP_STOP: 'Trip Stop',
} as any;

export enum StopTypeEnum {
  PICKUP = 'PICKUP',
  RELAY = 'RELAY',
  PICKUP_AND_DROPOFF = 'PICKUP_AND_DROPOFF',
  DROPOFF = 'DROPOFF',
  TRIP_STOP = 'TRIP_STOP',
}

export const MAX_HANDLING_TIME_MINS = 480;
export const MIN_HANDLING_TIME_MIS = 1;

export const stopSchema = {
  type: yup.string().oneOf(stopTypeOptions).required(),
  estimatedActivityDuration: yup
    .number()
    .required('Value should be between 1m to 480m')
    .nullable()
    .min(MIN_HANDLING_TIME_MIS, 'Value should be between 1m to 480m')
    .max(MAX_HANDLING_TIME_MINS, 'Value should be between 1m to 480m')
    .typeError('Please enter a number.')
    .transform(transformEmptyStringToNumber),
  relayDropOffEstimatedActivityDuration: yup
    .number()
    .nullable()
    .min(MIN_HANDLING_TIME_MIS, 'Value should be between 1m to 480m')
    .max(MAX_HANDLING_TIME_MINS, 'Value should be between 1m to 480m')
    .typeError('Please enter a number.'),
  referenceNumber: yup
    .string()
    .nullable()
    .max(500, 'Cannot be longer than 500 characters.'),
  notes: yup
    .string()
    .nullable()
    .max(1024, 'Cannot be longer than 1024 characters.'),
  fixedAppointment: yup.boolean().default(false),
  appointmentStartDate: yup
    .date()
    .nullable()
    .typeError('Start Date-time is invalid')
    .transform(transformEmptyStringToNull),
  appointmentEndDate: yup
    .date()
    .nullable()
    .typeError('End Date-time is invalid')
    .transform(transformEmptyStringToNull),
  relayDropOffAppointmentStartDate: yup
    .date()
    .nullable()
    .typeError('Start Date-time is invalid')
    .transform(transformEmptyStringToNull),
  relayDropOffAppointmentEndDate: yup
    .date()
    .nullable()
    .typeError('End Date-time is invalid')
    .transform(transformEmptyStringToNull),
  isDriverAssistRequired: yup.boolean().default(false),
  isRelayDropOffDriverAssistRequired: yup.boolean().default(false),
  location: yup.object().shape({
    locationName: yup
      .string()
      .max(255, 'Cannot be longer than 255 characters.')
      .nullable()
      .transform(transformEmptyStringToNull)
      .typeError('The address is required.'),
    businessName: yup
      .string()
      .nullable()
      .max(255, 'Cannot be longer than 255 characters.')
      .typeError('The address is required.')
      .transform(transformEmptyStringToNull),
    city: yup.string().nullable().transform(transformEmptyStringToNull),
    state: yup.string().nullable().transform(transformEmptyStringToNull),
    zipcode: yup.string().nullable().transform(transformEmptyStringToNull),
    streetAddress: yup
      .string()
      .nullable()
      .transform(transformEmptyStringToNull),
    address: yup
      .string()
      .required('The address is required.')
      .typeError('The address is required.'),
    address2: yup.string().nullable().transform(transformEmptyStringToNull),
  }),
  contact: yup.object().shape({
    contactName: yup
      .string()
      .nullable()
      .max(100, 'Cannot be longer than 100 characters.'),
    countryCode: yup.string().nullable(),
    phone: yup
      .string()
      .nullable()
      .matches(
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
        'Please enter a valid phone number.'
      )
      .length(10, 'The phone number must be exactly 10 digits.')
      .transform(transformEmptyStringToNull),
    extension: yup
      .string()
      .nullable()
      .max(5, 'Extension cannot be more than 5 digit'),
    email: yup.string().email('Please enter a valid email.').nullable(),
    firstname: yup.string().nullable(),
    lastname: yup.string().nullable(),
  }),
  relayPickupRevenueShare: yup
    .number()
    .nullable()
    .min(0, 'Revenue must be between 0 and  100.')
    .max(100, 'Revenue must be between 0 and  100.')
    .transform(transformEmptyStringToNumber),
  relayDropOffRevenueShare: yup
    .number()
    .nullable()
    .min(0, 'Revenue must be between 0 and  100.')
    .max(100, 'Revenue must be between 0 and  100.')
    .transform(transformEmptyStringToNumber),
};
