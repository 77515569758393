import { Box, Typography } from '@mui/material';
import {
  PuckTooltipConatinerStyles,
  defaultStatusContainerStyles,
  TooltipStatusTextStyles,
  headerContainerStyles,
  weekTextStyles,
  tooltipFooterContainerStyles,
  tooltipTitleStyle,
  tooltipTitleContainerStyles,
} from '../styles';
import {
  IPuckTooltip,
  IWeekInformation,
  TooltipFooterContentType,
} from '../types';
import {
  ActionTemplate,
  KpiSection,
  TooltipHOSSection,
  TooltipSubtitleTemplate,
  TooltipTripTemplate,
  WarningsTemplate,
} from '../atoms';
import { EVENT_STATUS } from '../constants/puck.constant';
import { PUCK_TEXT_COLOR_BY_TYPE } from '../bryntum/ganttchart.config';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import { PUCK_COLOR_BY_STATUS } from '../config';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { HoldIcon } from '../static';

const getColorByStatus = (status: string) => {
  if (status === EVENT_STATUS.COMPLETED)
    return PUCK_TEXT_COLOR_BY_TYPE.COMPLETED;
  return PUCK_TEXT_COLOR_BY_TYPE.OTHERS;
};

export const PuckTooltip: React.FC<IPuckTooltip> = (props) => {
  const {
    title,
    subTitles,
    titleContainerStyles,
    trips,
    onClose,
    actions,
    tags,
    ...rest
  } = props;
  return (
    <Box
      id="puck-tooltip-container"
      style={{ ...PuckTooltipConatinerStyles, ...rest.containerStyles }}
    >
      <Box
        id="header-container"
        style={{ ...headerContainerStyles, ...titleContainerStyles }}
      >
        <Box
          id="title-container"
          style={{
            ...tooltipTitleContainerStyles,
            ...rest?.tooltipTitleContainerStyles,
          }}
        >
          <Typography style={{ ...tooltipTitleStyle, ...rest.titleStyles }}>
            {title}
          </Typography>

          <Box display="flex" marginLeft={'auto'} alignItems={'flex-start'}>
            {(rest.status || rest?.displayStatus) && (
              <Box
                style={{
                  ...defaultStatusContainerStyles,
                  backgroundColor:
                    PUCK_COLOR_BY_STATUS?.[rest?.status as string] ||
                    PUCK_COLOR_BY_STATUS?.[EVENT_STATUS.ASSIGNED],
                  ...rest.statusContainerStyles,
                }}
              >
                {rest?.onHold && (
                  <Box sx={{ display: 'flex', marginBottom: '2px' }}>
                    <HoldIcon />
                  </Box>
                )}
                <Typography
                  style={{
                    ...TooltipStatusTextStyles,
                    fontFamily: rest.fontFamily || 'Poppins',
                    color: getColorByStatus(rest.status as string),
                    ...rest?.statusStyle,
                  }}
                >
                  {rest.displayStatus ?? rest.status}
                </Typography>
              </Box>
            )}
            <IconButton onClick={onClose} style={{ padding: 0 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {tags}
      <Box
        id="tooltip=subtitles"
        style={rest?.subTitleContainerStyles}
        padding="0px 16px 16px"
      >
        <TooltipSubtitleTemplate
          subTitles={subTitles}
          onSubTitleClick={rest?.onSubTitleClick}
        />
      </Box>

      {rest?.hosDetails?.element ? (
        rest?.hosDetails?.element
      ) : rest?.hosDetails ? (
        <TooltipHOSSection {...rest?.hosDetails} />
      ) : (
        <></>
      )}

      <TooltipTripTemplate {...trips} />

      {rest?.kpis?.content && <KpiSection kpis={rest.kpis} />}

      {rest?.weekInformation?.length && (
        <Box sx={{ padding: '4px 0' }}>
          {rest?.weekInformation?.map((week: IWeekInformation) => (
            <Box
              width={'100%'}
              justifyContent={'center'}
              display={'flex'}
              key={week?.key}
            >
              <Typography sx={{ ...weekTextStyles, ...week?.keyStyles }}>
                {week?.key}:
              </Typography>
              <Typography
                sx={{
                  ...weekTextStyles,
                  paddingLeft: '3px',
                  ...week?.valueStyles,
                }}
              >
                {week?.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      <ActionTemplate
        actionRenderer={rest.actionRenderer}
        actions={actions}
        onActionClick={rest.onActionClick}
        containerStyles={rest?.actionContainerStyles}
      />

      {rest.footer?.element
        ? rest.footer?.element
        : (rest.footer?.content?.length as number) > 0 && (
            <Box
              id="footer-container"
              style={{
                ...tooltipFooterContainerStyles,
                ...(rest?.footer?.containerStyles as any),
              }}
            >
              <WarningsTemplate
                {...rest.footer}
                onWarningAction={(content: TooltipFooterContentType) => {
                  rest?.onWarningAction?.(content);
                }}
              />
            </Box>
          )}
      {/* </Box> */}
    </Box>
  );
};
