import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import SingleAutocomplete from '../../../../ui-kit/components/SingleAutocomplete';
import TextInput from '../../../../ui-kit/components/TextField';
import { addressType } from '../constants';
import { statesinCountry as statesinCountryList } from '../constants/states';
import { propsType } from '../types/EditableAddressFieldPropType';

const textInputDisableSxProps = {
  '.Mui-disabled': {
    color: '#c5c8ca',
    '-webkit-text-fill-color': '#c5c8ca',
  },
};
function EditModeAddressField({
  data = null,
  onAddressChanged,
  errors,
  required,
  label,
  maxWidth,
  variant,
  disabled,
  singleInput,
}: propsType & { singleInput: boolean }) {
  const [addressData, setAddressData] = useState<addressType | null>(data);

  useEffect(() => {
    setAddressData(data);
  }, [data]);
  const selectedState = statesinCountryList?.find(
    (item) => item?.value === addressData?.state
  );
  return (
    <>
      {addressData && (
        <Grid container sx={{ maxWidth: maxWidth || '-webkit-fill-available' }}>
          <Grid item xs={12}>
            <TextInput
              disabled={disabled}
              error={!!errors}
              helperText={
                required && !addressData.streetAddress && errors
                  ? `${label} is required.`
                  : ''
              }
              autoComplete="off"
              name="standard-read-only-input"
              label={label}
              variant={variant}
              inputProps={{ maxLength: 255 }}
              required={required}
              value={addressData.streetAddress || ''}
              styleProps={{
                width: '100%',
                ...textInputDisableSxProps,
              }}
              id="streetAddress"
              InputLabelProps={{
                shrink: !!addressData.streetAddress,
              }}
              onChange={(event) => {
                const updatedAddress = {
                  ...addressData,
                  streetAddress: event.target.value,
                };
                onAddressChanged(updatedAddress);
                setAddressData(updatedAddress);
              }}
            />
          </Grid>
          {!singleInput && (
            <Grid container item xs={12} spacing={0}>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                xs={12}
                spacing={1}
                marginTop={'5px'}
                // sx={{ margin: textInputDisableSxProps ? '5px' : '20px 0px' }}
              >
                <Grid item xs={4}>
                  <TextInput
                    autoComplete="off"
                    styleProps={{
                      width: '95%',
                      ...textInputDisableSxProps,
                    }}
                    id="city"
                    label="City"
                    variant={variant}
                    error={!!errors}
                    helperText={
                      errors?.city?.message ||
                      (required && !addressData.city && errors)
                        ? `City is required.`
                        : ''
                    }
                    value={addressData.city || ''}
                    InputLabelProps={{
                      shrink: !!addressData.city,
                    }}
                    inputProps={{ maxLength: 50 }}
                    required={required}
                    disabled={disabled}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...addressData,
                        city: event.target.value,
                      };
                      onAddressChanged(updatedAddress);
                      setAddressData(updatedAddress);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SingleAutocomplete
                    name="name"
                    fieldName="name"
                    label="State"
                    options={statesinCountryList}
                    value={selectedState ? selectedState : null}
                    variant={variant}
                    error={errors?.state}
                    helperText={errors?.state?.message}
                    InputLabelProps={{
                      shrink: !!addressData.state,
                    }}
                    required={required}
                    disabled={disabled}
                    onChangeCb={(data) => {
                      const updatedAddress = {
                        ...addressData,
                        state: data?.value,
                        fullAddress: data?.CountryName,
                      };
                      onAddressChanged(updatedAddress);
                      setAddressData(updatedAddress);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    autoComplete="off"
                    styleProps={{ width: '100%', ...textInputDisableSxProps }}
                    id="zip"
                    label="Zip"
                    inputProps={{ maxLength: 6 }}
                    variant={variant}
                    value={addressData.zip || ''}
                    InputLabelProps={{
                      shrink: !!addressData.zip,
                    }}
                    error={!!errors?.zip?.message}
                    helperText={errors?.zip?.message}
                    disabled={disabled}
                    onChange={(event) => {
                      const updatedAddress = {
                        ...addressData,
                        zip: event.target.value,
                      };
                      onAddressChanged(updatedAddress);
                      setAddressData(updatedAddress);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default EditModeAddressField;
