import StorageManager from '../../../../../StorageManager/StorageManager';
import { layoutService, visibilityService } from '../../../../../api';
import { GetLoadByIDDTO } from '../../../../../models';
import { ISendDocumentTurvoType } from '../../../../../views/reports/documents/sections/EntityDocumentsSection';

export const getVisibilityProviderIdsData = async (id: number) => {
  const userStorage = StorageManager.getUser();
  const request = new GetLoadByIDDTO();
  request.loadId = id;
  request.organizationId = userStorage.organizationId;
  const getRes = await layoutService.getLoadByIDData(request);
  return getRes;
};

export const getTinyUrl = async (url: string) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  const temp = await fetch(url, requestOptions);
  return temp && temp?.status == 200 ? await temp.text() : '';
};

export const sendDocumentTurvoData = async (
  payLoad: ISendDocumentTurvoType
) => {
  const getRes = await visibilityService.sendDocumentTurvoData(payLoad);
  return getRes;
};
