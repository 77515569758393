import { Paper, Typography, Box, type SxProps } from '@mui/material';
import * as React from 'react';
import {
  popupContentStyles,
  popupFooterContentStyles,
  popupFooterStyles,
  popupHeaderIconStyles,
  popupHeaderStyles,
  popupHeaderTitleStyles,
  popupSubheaderStyles,
} from '../styles';
import { ReactElement } from 'react';

interface ITooltipProps {
  popupContent: ReactElement<any, any>;
  openOnHover?: boolean;
  headerIcon: ReactElement<any>;
  headerTitle: string;
  headerSubheading?: string;
  isFooterRequired: boolean;
  footerContent?: string;
  footerIcon?: ReactElement<any>;
  headerContainerStyles?: SxProps;
  headerTitleStyles?: SxProps;
  headerSubheadingStyles?: SxProps;
  contentContainerStyles?: SxProps;
  headerTemplate?: JSX.Element;
  footerContainerStyles?: SxProps | any;
}

const Tooltip: React.FunctionComponent<ITooltipProps> = ({
  popupContent,
  headerIcon,
  headerTitle,
  headerSubheading,
  isFooterRequired,
  footerContent,
  headerContainerStyles = {},
  headerTitleStyles = {},
  headerSubheadingStyles = {},
  contentContainerStyles = {},
  footerIcon,
  headerTemplate,
  footerContainerStyles = {},
}) => (
  <>
    <Box sx={{ ...popupHeaderStyles, ...headerContainerStyles }}>
      {headerTemplate || (
        <>
          <Box sx={popupHeaderIconStyles}>{headerIcon}</Box>

          <Box sx={{ ml: 2, mb: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ ...popupHeaderTitleStyles, ...headerTitleStyles }}
            >
              {headerTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ ...popupSubheaderStyles, ...headerSubheadingStyles }}
            >
              {headerSubheading}
            </Typography>
          </Box>
        </>
      )}
    </Box>
    <Paper sx={{ ...popupContentStyles, ...contentContainerStyles }}>
      {popupContent}
    </Paper>

    {isFooterRequired && (
      <Paper sx={{ ...popupFooterStyles, ...footerContainerStyles }}>
        <Box sx={popupFooterContentStyles}>
          {footerIcon}
          <Typography variant="subtitle2">{footerContent}</Typography>
        </Box>
      </Paper>
    )}
  </>
);

export default Tooltip;
