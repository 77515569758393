import { annotateNameAsync, IImportService, ServiceError } from '../interfaces';
import {
  CancelImportRequest,
  ChangeCellValueRequest,
  DownloadImportedFileRequest,
  GenerateFinalDataRequest,
  GetColumnsMappingRequest,
  GetImportedDataRequest,
  ImportFileRequest,
  ExtractColumnsMappingRequest,
  SaveColumnsMappingRequest,
  GetMissingEntitiesRequest,
  CreateMissingEntitiesRequest,
  GetCustomerStaticRequest,
  FinishImportRequest,
  GetFactoringStaticRequest,
  GetCommodityTypesRequest,
  ApplyMissingEntitiesRequest,
  ChangeAddressCellValueRequest,
  ExtractAndCreateRequest,
  GetImportedLineItemsRequest,
} from '../../models';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { docType } from '../../utils/Doc';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

export class ImportService extends IImportService {
  @annotateNameAsync
  async importFile(
    file: File,
    entityName: string,
    dictionary: string,
    dateFormat: string,
    fileType: string,
    deductionType: string
  ): Promise<
    | {
        content: any;
        warnings: { key: string; message: string }[];
        key: string;
        totalRows: number;
      }
    | ServiceError
  > {
    try {
      const request = new ImportFileRequest();
      request.dateFormat = dateFormat;
      request.deductionType = deductionType;
      request.type = fileType;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('organizationId', request.organizationId.toString());
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/upload`,
        request,
        formData,
        false,
        headers
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async cancelImport(
    requestData: CancelImportRequest,
    entityName: string,
    dictionary: string
  ): Promise<{ message: string } | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/cancel`,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getImportedLineItems(
    requestData: GetImportedLineItemsRequest
  ): Promise<{ message: string } | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `/web/customer/api/v2/contract/import/get-results`,
        requestData,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'importFile',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importFile',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async extractAndCreate(
    requestData: ExtractAndCreateRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    | {
        extractedRowsCount: number;
        warningsCount: number;
        errorsCount: number;
        ignoredColumnsCount: number;
        totalRowsCount: number;
      }
    | ServiceError
  > {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/extract/create`,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getColumnsMapping(
    requestData: GetColumnsMappingRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    | {
        importColumnMapping: {
          destinationColumn: string;
          mandatory: boolean;
          sourceColumn: string;
          sourceColumnCode: string;
        }[];
      }
    | ServiceError
  > {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.getRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/get/columnMapping`,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async extractColumnsMapping(
    requestData: ExtractColumnsMappingRequest,
    entityName: string,
    dictionary: string,
    bodyParams: {
      importColumnMapping: Partial<{ [p: string]: string | null }>;
      key: string;
    }
  ): Promise<{ message: string } | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/extract`,
        requestData,
        bodyParams
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async saveColumnsMapping(
    requestData: SaveColumnsMappingRequest,
    entityName: string,
    dictionary: string,
    bodyParams: {
      importColumnMapping: Partial<{ [key: string]: string | null }>;
      key: string;
    }
  ): Promise<{ message: string } | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/create/columnMapping`,
        requestData,
        bodyParams
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getImportedData(
    requestData: GetImportedDataRequest,
    entityName: string,
    dictionary: string
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/get/list`,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async downloadImportedFile(
    requestData: DownloadImportedFileRequest,
    entityName: string,
    dictionary: string
  ): Promise<docType | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.getRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/download`,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async changeCellValue(
    requestData: ChangeCellValueRequest,
    entityName: string,
    dictionary: string,
    cellData: Partial<{ [key: string]: any }>
  ): Promise<any | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/update`,
        requestData,
        cellData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async changeAddressCellValue(
    requestData: ChangeAddressCellValueRequest,
    entityName: string,
    dictionary: string,
    cellData: Partial<{ [key: string]: any }>
  ): Promise<any | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/address/update`,
        requestData,
        cellData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async generateFinalData(
    requestData: GenerateFinalDataRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    | {
        extractedRowsCount: number;
        warningsCount: number;
        errorsCount: number;
        ignoredColumnsCount: number;
        totalRowsCount: number;
      }
    | ServiceError
  > {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/create`,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getMissingEntities(
    requestData: GetMissingEntitiesRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    { [key: string]: { [key: string]: string | number } } | ServiceError
  > {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.getRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/get/missingEntities`,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async createMissingEntities(
    requestData: CreateMissingEntitiesRequest,
    entityName: string,
    dictionary: string,
    bodyParams: any
  ): Promise<boolean | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/create/missingEntities`,
        requestData,
        bodyParams
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async applyMissingEntities(
    requestData: ApplyMissingEntitiesRequest,
    entityName: string,
    dictionary: string,
    bodyParams: any
  ): Promise<boolean | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/apply/missingEntities`,
        requestData,
        bodyParams
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importIssue',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getCustomerStatic(
    requestData: GetCustomerStaticRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/customer/api/v2/data/static`,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverStatic(
    requestData: GetCustomerStaticRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/people/api/v2/organization/static`,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getFactoringStatic(
    requestData: GetFactoringStaticRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/factoring/api/v2/integrations/organization/summary`,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async finishImport(
    requestData: FinishImportRequest,
    entityName: string,
    dictionary: string
  ): Promise<{ message: string } | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/${dictionary}/api/v2/${entityName}/import/finish`,
        requestData,
        {}
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'importFile',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'importFile',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getCommodityTypes(
    requestData: GetCommodityTypesRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/preference/api/v2/commodity/items/getByOrganizationId`,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
