import { useEffect, useState } from 'react';
import { visibilityService } from '@/api';
import {
  CreateLoadAlertRequest,
  GetLoadAlertFlagTypesRequest,
  GetLoadAlertsRequest,
  LoadAlert,
  LoadAlertFlagType,
} from '@/models';
import { ServiceError } from '@/api/interfaces';
import { IHttpErrorType } from '@/types';

export const useLoadAlerts = (loadId: string) => {
  const [loading, setLoading] = useState(false);
  const [loadAlerts, setLoadAlerts] = useState<LoadAlert[]>([]);
  const [flagTypes, setFlagTypes] = useState<LoadAlertFlagType[]>([]);
  const [showAddAlertModal, setShowAddAlertModal] = useState(false);
  const [httpErrors, setHttpErrors] = useState<IHttpErrorType[] | null>(null);

  useEffect(() => {
    getLoadAlertsAndFlagTypes();
  }, []);

  const getLoadAlertsAndFlagTypes = async () => {
    setLoading(true);
    try {
      const [alerts, flagTypes] = await Promise.all([
        getLoadAlerts(),
        getLoadAlertFlagTypes(),
      ]);
      if (!(alerts instanceof ServiceError)) {
        setLoadAlerts(alerts);
      }
      if (!(flagTypes instanceof ServiceError)) {
        setFlagTypes(
          flagTypes.customerAlertFlagTypes.map((flag) => ({
            key: flag.key,
            value: flag.value,
          }))
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const updateAlertsList = async () => {
    const alerts = await getLoadAlerts();
    if (!(alerts instanceof ServiceError)) {
      setLoadAlerts(alerts);
    }
  };

  const getLoadAlertFlagTypes = async () => {
    const request = new GetLoadAlertFlagTypesRequest();
    request.loadId = loadId;
    return await visibilityService.getLoadAlertFlagTypes(request);
  };

  const getLoadAlerts = async () => {
    const request = new GetLoadAlertsRequest();
    request.loadId = loadId;
    return await visibilityService.getLoadAlerts(request);
  };

  const handleAddAlertClick = () => {
    setShowAddAlertModal(true);
  };

  const handleAddAlertDialogClose = () => {
    setShowAddAlertModal(false);
  };

  const handleAddAlertDialogOpen = () => {
    setShowAddAlertModal(true);
  };

  const handleLoadAlertAdd = async (data: any) => {
    const request = new CreateLoadAlertRequest();
    request.loadId = loadId;
    request.name = data.name;
    request.email = data.email;
    request.sendViaEmail = !!data.sendViaEmail;
    request.sendViaPhone = !!data.sendViaPhone;
    request.phoneData = data.phoneData;
    request.loadNotifications = data.loadNotifications;
    const response = await visibilityService.createLoadAlert(request);
    if (!(response instanceof ServiceError)) {
      updateAlertsList();
      setShowAddAlertModal(false);
    } else {
      if ((response.error as any).response.status === 417) {
        setHttpErrors([
          {
            fieldName: 'email',
            message: 'Email is already in use',
          },
        ]);
      }
    }
  };

  return {
    loadAlerts,
    loading,
    flagTypes,
    showAddAlertModal,
    httpErrors,
    handleAddAlertClick,
    handleAddAlertDialogClose,
    handleAddAlertDialogOpen,
    handleLoadAlertAdd,
    updateAlertsList,
  };
};
