import {
  CreateLoadAlertRequest,
  DeleteLoadAlertRequest,
  EditLoadAlertRequest,
  GetContactsRequest,
  GetLoadAlertFlagTypesRequest,
  GetLoadAlertsRequest,
  LoadAlert,
  LoadAlertContact,
  LoadAlertFlagType,
} from '../../models';
import { ISendDocumentTurvoType } from '../../views/reports/documents/sections/EntityDocumentsSection';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IVisibilityService extends Service {
  abstract getLoadAlertFlagTypes(
    requestData: GetLoadAlertFlagTypesRequest
  ): Promise<{ customerAlertFlagTypes: LoadAlertFlagType[] } | ServiceError>;

  abstract getLoadAlerts(
    requestData: GetLoadAlertsRequest
  ): Promise<LoadAlert[] | ServiceError>;

  abstract deleteLoadAlert(
    requestData: DeleteLoadAlertRequest
  ): Promise<void | ServiceError>;

  abstract createLoadAlert(
    requestData: CreateLoadAlertRequest
  ): Promise<void | ServiceError>;

  abstract editLoadAlert(
    requestData: EditLoadAlertRequest
  ): Promise<void | ServiceError>;

  abstract sendDocumentTurvoData(
    payloadData: ISendDocumentTurvoType
  ): Promise<any | null | ServiceError>;

  abstract getContacts(
    requestData: GetContactsRequest
  ): Promise<LoadAlertContact[] | ServiceError>;
}
