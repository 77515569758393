import { TagForm } from '@/common/Tag/components/TagForm';
import {
  tagFormDefaultData,
  tagsValidationSchema,
} from '@/common/Tag/constants/tag.constants';
import FormDialog from '@/common/Ui/FormDialog';
import { FC, useEffect, useState } from 'react';
import { IHttpErrorType } from '@/types';
import { manageTypesService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import {
  CreateTagRequest,
  GetTagColorsRequest,
  TagColor,
  TagEntityType,
  TagItem,
  TagType,
} from '@/models/DTOs/ManageTypes/Requests';

type CreateUpdateTagFormType = {
  name: string;
  description: string;
  color: string;
  entityTypes: { key: TagEntityType; value: string; disabled?: boolean }[];
};

interface Props {
  open: boolean;
  onClose: () => void;
  onCreate?: (item: TagItem) => void;
  initialValues?: CreateUpdateTagFormType;
  entityType?: TagEntityType;
}

export const TagFormModal: FC<Props> = ({
  open,
  onClose,
  onCreate,
  initialValues = tagFormDefaultData,
  entityType,
}) => {
  const [options, setOptions] = useState<TagColor[]>([]);
  const [httpErrors, setHttpErrors] = useState<IHttpErrorType[] | null>(null);

  const handleTagCreate = async (data: CreateUpdateTagFormType) => {
    const request = new CreateTagRequest();
    request.name = data.name.trim();
    request.description = data.description;
    request.tagType = TagType.USER_DEFINED;
    request.color = data.color;
    request.entityTypes = data.entityTypes.map((entityType) => entityType.key);
    request.active = true;
    const result = await manageTypesService.createTag(request);
    if (result instanceof ServiceError) {
      if ((result.error as any).response.status === 417) {
        setHttpErrors([
          {
            fieldName: 'name',
            message: 'Tag Name is already used',
          },
        ]);
      }
      return;
    }
    onCreate && onCreate(result);
    setHttpErrors(null);
    onClose();
  };

  const getOptions = async () => {
    const request = new GetTagColorsRequest();
    const result = await manageTypesService.getTagColors(request);
    if (result instanceof ServiceError) {
      return;
    }
    setOptions(result);
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <FormDialog
      open={open}
      handleClose={() => {
        onClose();
        setHttpErrors(null);
      }}
      onAction={handleTagCreate}
      titleText="Create New Tag"
      actionLabel="Create Tag"
      contentRenderer={() => (
        <TagForm entityType={entityType} colors={options} />
      )}
      validationSchema={tagsValidationSchema}
      data={initialValues}
      httpErrors={httpErrors}
    />
  );
};
