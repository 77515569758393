export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const formatDateToDayAndMonth = (
  date: Date | string,
  isMonthRequired = true,
  isYearRequired = false
) => {
  const d = new Date(date);
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  const yy = d.getFullYear();
  const day = weekDays[d.getDay()];
  const month = d.toLocaleString('default', { month: 'long' });

  if (!isMonthRequired) return `${day} ${dd}`;
  if (isYearRequired) return `${dd} ${month} ${yy}`;
  return `${day} ${mm}/${dd}`;
};

export const formatDateToHHMM = (d: Date | string) => {
  const date = new Date(d);
  let hours = date.getHours() as any;
  let minutes = date.getMinutes() as any;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

export const getHoursInAMPM = (d: Date | string) => {
  const date = new Date(d);
  let hours = date.getHours();
  const ampm = hours >= 12 ? ' PM' : ' AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const strTime = hours + ampm;
  return strTime;
};

export const getHoursIn24Format = (d: Date | string) => {
  const date = new Date(d);
  const hours = date.getHours();
  return hours < 10 ? `0${hours}:00` : `${hours}:00`;
};
