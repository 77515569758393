// import { ESecondaryDetailsPanelType } from 'views/dispatch/constants/types';
import { TagListAuditLogComponent } from '@/views/customTags/customTagChip';
import { ESecondaryDetailsPanelType } from '../../../../../views/dispatch2/constants/types';
import { numberFormat } from '../../../../../views/reports/basicReports/constant';
import { LogLink } from '../components/logLink';
import { hyperLinkProps, secondaryPanelType } from '../type';

export const ActionType = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
};
export const LogEntity: { [key: string]: string } = {
  LOAD: 'load',
  USER: 'user',
  TRIP: 'trip',
  TRACTOR: 'tractor',
  TRAILER: 'trailer',
  DRIVER: 'driver',
  DISPATCHER: 'dispatcher',
  TERMINAL: 'terminal',
  CARRIER: 'carrier',
};

export const DateType = {
  DATE: 'DATE',
  DATETIME: 'DATETIME',
};
interface logsProps {
  logKey: string;
  entity: string;
  user?: hyperLinkProps;
  //  LogEntity?:hyperLinkProps | string;
  tractor?: hyperLinkProps;
  trip?: hyperLinkProps;
  load?: hyperLinkProps;
  invoiceType?: hyperLinkProps;
  invoiceAmount?: hyperLinkProps;
  emailIds?: Array<hyperLinkProps>;
  amount?: hyperLinkProps;
  city?: hyperLinkProps;
  state?: hyperLinkProps;
  documentName?: hyperLinkProps;
  documentType?: hyperLinkProps;
  commodityType?: hyperLinkProps;
  carrier?: hyperLinkProps;
  alertType?: Array<hyperLinkProps>;
  alertName?: hyperLinkProps;
  payType?: hyperLinkProps;
  stopType?: hyperLinkProps;
  handleSecondaryPanelOpen: (data: secondaryPanelType) => void;
  [key: string]: any;
  stopNumber?: hyperLinkProps;
  stopStatus?: hyperLinkProps;
  phoneNumber?: hyperLinkProps;
  externalUser?: hyperLinkProps;
}

const LogType = {
  GENERIC_LOGS: 'GENERIC_LOGS',
  REVERT_LOGS: 'REVERT_LOGS',
  GENERIC_THREE_ENTITY_LOGS: 'GENERIC_THREE_ENTITY_LOGS',
  INVOICE_LOGS: 'INVOICE_LOGS',
  INVOICE_DOWNLOAD_LOGS: 'INVOICE_DOWNLOAD_LOGS',
  TRIP_DELETE_LOGS: 'TRIP_DELETE_LOGS',
  DEFAULT: 'DEFAULT',
  INVOICE_PAYMENT_LOGS: 'INVOICE_PAYMENT_LOGS',
  INVOICE_EMAIL: 'INVOICE_EMAIL',
  FACTORING_LOGS: 'FACTORING_LOGS',
  FACTORY_REFERESH: 'FACTORY_REFERESH',
  GENERIC_LOAD_LOGS: 'GENERIC_LOAD_LOGS',
  LOAD_ASSIGNMENTS_LOGS: 'LOAD_ASSIGN_LOGS',
  LOAD_STOP_LOGS: 'LOAD_STOP_LOGS',
  RELAY_STOP_LOGS: 'RELAY_STOP_UPDATE',
  COMMODITY_LOGS: 'COMMODITY_LOGS',
  DOCUMENT_LOGS: 'DOCUMENT_LOGS',
  ADDITIONAL_LOAD_UPDATE_LOGS: 'ADDITIONAL_LOAD_UPDATE_LOGS',
  NOTE_LOGS: 'NOTE_LOGS',
  ALERT_LOGS: 'ALERT_LOGS',
  ALERT_NOTIFICATION_LOGS: 'ALERT_NOTIFICATION_LOGS',
  PAYMENT_ADD_LOGS: 'PAYMENT_ADD_LOGS',
  PAYMENT_UPDATE_DELETE_LOGS: 'PAYMENT_UPDATE_DELETE_LOGS',
  PAYMENT_RECALCULATE_DOWNLOAD_LOGS: 'PAYMENT_RECALCULATE_DOWNLOAD_LOGS',
  DISPATCH_EMAIL_LOGS: 'DISPATCH_EMAIL',
  DISPATCH_DOWNLOAD_LOGS: 'DISPATCH_DOWNLOAD',
  LOAD_STOP_STATUS_LOGS: 'LOAD_STOP_STATUS_LOGS',
  LOAD_PAID_LOGS: 'LOAD_PAID_LOADS',
  IN_PROGRESS_LOAD_LOGS: 'IN_PROGRESS_LOAD_LOGS',
  LOAD_HOLD_RELEASED: 'LOAD_HOLD_RELEASED',
  READY_TO_INVOICE: 'READY_TO_INVOICE',
  REVERT_TO_READY_TO_INVOICE: 'REVERT_TO_READY_TO_INVOICE',
  TAGS_ADDED: 'TAGS_ADDED',
  TAGS_REMOVED: 'TAGS_REMOVED',
  TAGS_MODIFIED: 'TAGS_MODIFIED',
  DRIVER_TRIP_STATUS: 'DRIVER_TRIP_STATUS',
};

const LogKeys = {
  CREATED: { event: 'created', logType: LogType.GENERIC_LOGS },
  DELETED: { event: 'deleted', logType: LogType.GENERIC_LOGS },
  UPDATED: { event: 'updated', logType: LogType.GENERIC_LOGS },
  COMPLETED: { event: 'completed', logType: LogType.GENERIC_LOGS },
  CANCELED: { event: 'canceled', logType: LogType.GENERIC_LOGS },
  ASSIGNED: { event: 'assigned', logType: LogType.GENERIC_LOGS },
  DISPATCHED: { event: 'dispatched', logType: LogType.GENERIC_LOGS },
  UNDISPATCHED: { event: 'un-dispatched', logType: LogType.GENERIC_LOGS },
  INVOICED: { event: 'invoiced', logType: LogType.GENERIC_LOGS },

  LOAD_PAID: { logType: LogType.LOAD_PAID_LOGS },
  REVERT_TO_INVOICE: { event: 'invoice', logType: LogType.REVERT_LOGS },
  REVERT_TO_AVAILABLE: { event: 'available', logType: LogType.REVERT_LOGS },
  REVERT_TO_PLAN: { event: 'planned ', logType: LogType.REVERT_LOGS },
  REVERT_TO_IN_PROGRESS: { event: 'in-progress', logType: LogType.REVERT_LOGS },
  REVERT_TO_COMPLETED: { event: 'completed', logType: LogType.REVERT_LOGS },
  REVERT_TO_CANCELED: { event: 'canceled', logType: LogType.REVERT_LOGS },

  INVOICE_ADD: { event: 'added', logType: LogType.INVOICE_LOGS },
  INVOICE_UPDATE: { event: 'updated', logType: LogType.INVOICE_LOGS },
  INVOICE_DELETE: { event: 'removed', logType: LogType.INVOICE_LOGS },
  INVOICE_DOWNLOAD: { logType: LogType.INVOICE_DOWNLOAD_LOGS },
  TRIP_DELETE: { logType: LogType.TRIP_DELETE_LOGS },

  BROKERED_TRIP: {
    event: 'brokered',
    logType: LogType.GENERIC_THREE_ENTITY_LOGS,
  },
  NON_BROKERED_TRIP: {
    event: 'non-brokered',
    logType: LogType.GENERIC_THREE_ENTITY_LOGS,
  },

  INVOICE_EMAIL: { logType: LogType.INVOICE_EMAIL },
  INVOICE_ADD_RECEIVE_PAYMENT: {
    event: 'added',
    logType: LogType.INVOICE_PAYMENT_LOGS,
    connectingWord: 'in',
  },
  INVOICE_UPDATE_RECEIVE_PAYMENT: {
    event: 'updated',
    logType: LogType.INVOICE_PAYMENT_LOGS,
    connectingWord: 'in',
  },
  INVOICE_DELETE_RECEIVE_PAYMENT: {
    event: 'removed',
    logType: LogType.INVOICE_PAYMENT_LOGS,
    connectingWord: 'from',
  },

  FACTORING_REFRESH: { event: 'refreshed', logType: LogType.FACTORY_REFERESH },

  FACTORING_SEND: {
    event: 'send the invoice',
    logType: LogType.FACTORING_LOGS,
    connectingWord: 'to',
  },
  FACTORING_SYNC_TO_DOCUMENT: {
    event: 'synced the documents',
    logType: LogType.FACTORING_LOGS,
    connectingWord: 'with',
  },
  FACTORING_SYNC_TO_MODIFIED: {
    event: 'updated the invoice',
    logType: LogType.FACTORING_LOGS,
    connectingWord: 'in',
  },
  FACTORING_SUBMIT: {
    event: 'submit to schedule',
    logType: LogType.FACTORING_LOGS,
    connectingWord: 'with',
  },
  FACTORING_DISCONNECT: {
    event: 'disconnected the invoice',
    logType: LogType.FACTORING_LOGS,
    connectingWord: 'with',
  },

  // PHASE2
  DRIVER_ASSIGN: {
    event: 'assigned driver',
    entityKey: LogEntity.DRIVER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'to',
  },
  DRIVER_PLAN: {
    event: 'planned driver',
    entityKey: LogEntity.DRIVER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'to',
  },
  TRACTOR_ASSIGN: {
    event: 'assigned tractor',
    entityKey: LogEntity.TRACTOR,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'to',
  },
  TRAILER_ASSIGN: {
    event: 'assigned trailer',
    entityKey: LogEntity.TRAILER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'to',
  },
  DISPATCHER_ASSIGN: {
    event: 'assigned dispatcher',
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'to',
  },
  TERMINAL_ASSIGN: {
    event: 'assigned terminal',
    entityKey: LogEntity.TERMINAL,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: ' to',
  },
  CARRIER_ASSIGN: {
    event: 'assigned carrier',
    entityKey: LogEntity.CARRIER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: ' to',
  },
  CARRIER_PLAN: {
    event: 'planned carrier',
    entityKey: LogEntity.CARRIER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: ' to',
  },

  DRIVER_UNASSIGN: {
    event: 'unassigned driver',
    entityKey: LogEntity.DRIVER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'from',
  },
  DRIVER_UNPLANNED: {
    event: 'un-planned driver',
    entityKey: LogEntity.DRIVER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'from',
  },
  CARRIER_UNPLANNED: {
    event: 'un-planned carrier',
    entityKey: LogEntity.CARRIER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'from',
  },
  TRACTOR_UNASSIGN: {
    event: 'unassigned tractor',
    entityKey: LogEntity.TRACTOR,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'from',
  },
  TRAILER_UNASSIGN: {
    event: 'unassigned trailer',
    entityKey: LogEntity.TRAILER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'from',
  },
  DISPATCHER_UNASSIGN: {
    event: 'unassigned dispatcher',
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'from',
  },
  CARRIER_UNASSIGN: {
    event: 'unassigned carrier',
    entityKey: LogEntity.CARRIER,
    logType: LogType.LOAD_ASSIGNMENTS_LOGS,
    connectingWord: 'from',
  },

  LOAD_STOP_ADD: {
    event: 'added',
    connectingWord: 'in',
    logType: LogType.LOAD_STOP_LOGS,
  },
  TRIP_ACCEPTED: {
    event: 'accepted',
    connectingWord: 'in',
    logType: LogType.DRIVER_TRIP_STATUS,
    entityKey: LogEntity.DRIVER,
  },
  TRIP_REJECTED: {
    event: 'rejected',
    connectingWord: 'in',
    entityKey: LogEntity.DRIVER,
    logType: LogType.DRIVER_TRIP_STATUS,
  },
  RELAY_STOP_ADD: {
    event: 'added',
    connectingWord: 'in',
    logType: LogType.RELAY_STOP_LOGS,
  },
  LOAD_STOP_UPDATE: {
    event: 'updated',
    connectingWord: 'in',
    logType: LogType.LOAD_STOP_LOGS,
  },
  RELAY_STOP_UPDATE: {
    event: 'updated',
    connectingWord: 'in',
    logType: LogType.RELAY_STOP_LOGS,
  },
  LOAD_STOP_DELETE: {
    event: 'deleted',
    connectingWord: 'from',
    logType: LogType.LOAD_STOP_LOGS,
  },
  RELAY_STOP_DELETE: {
    event: 'deleted',
    connectingWord: 'from',
    logType: LogType.RELAY_STOP_LOGS,
  },

  LOAD_STOP_RESEQUENCE: {
    event: 're-sequenced',
    connectingWord: 'in',
    logType: LogType.LOAD_STOP_LOGS,
  },

  COMMODITY_ADD: {
    event: 'added',
    connectingWord: 'to',
    logType: LogType.COMMODITY_LOGS,
  },
  COMMODITY_UPDATE: {
    event: 'updated',
    connectingWord: 'in',
    logType: LogType.COMMODITY_LOGS,
  },
  COMMODITY_DELETE: {
    event: 'deleted',
    connectingWord: 'from',
    logType: LogType.COMMODITY_LOGS,
  },

  DOCUMENT_UPLOAD: {
    event: 'added',
    connectingWord: 'to',
    logType: LogType.DOCUMENT_LOGS,
  },
  DOCUMENT_UPDATE: {
    event: 'updated',
    connectingWord: 'in',
    logType: LogType.DOCUMENT_LOGS,
  },
  DOCUMENT_DELETE: {
    event: 'deleted',
    connectingWord: 'from',
    logType: LogType.DOCUMENT_LOGS,
  },
  DOCUMENT_SHARE: {
    event: 'shared',
    connectingWord: 'of',
    logType: LogType.DOCUMENT_LOGS,
  },
  DOCUMENT_DOWNLOAD: {
    event: 'downloaded',
    connectingWord: 'of',
    logType: LogType.DOCUMENT_LOGS,
  },

  ADDITIONAL_LOAD_UPDATE: {
    event: 'updated',
    logType: LogType.ADDITIONAL_LOAD_UPDATE_LOGS,
  },

  NOTE_ADD: { event: 'added', logType: LogType.NOTE_LOGS },
  NOTE_UPDATE: { event: 'updated', logType: LogType.NOTE_LOGS },
  NOTE_DELETE: { event: 'deleted', logType: LogType.NOTE_LOGS },

  LOAD_REALESED: {
    event: 'released',
    connectingWord: 'from',
    logType: LogType.LOAD_HOLD_RELEASED,
  },
  LOAD_ON_HOLD: {
    event: 'put',
    connectingWord: 'on',
    logType: LogType.LOAD_HOLD_RELEASED,
  },

  PICKUP_STOP: { event: 'pickup', logType: LogType.LOAD_STOP_STATUS_LOGS },
  DROPOFF_STOP: { event: 'dropoff', logType: LogType.LOAD_STOP_STATUS_LOGS },
  INTERMEDIATE_STOP: { event: '', logType: LogType.LOAD_STOP_STATUS_LOGS },

  ALERT_ADD: {
    event: 'added',
    logType: LogType.ALERT_LOGS,
    connectingWord: 'in',
  },
  ALERT_UPDATE: {
    event: 'updated',
    logType: LogType.ALERT_LOGS,
    connectingWord: 'in',
  },
  ALERT_DELETE: {
    event: 'removed',
    logType: LogType.ALERT_LOGS,
    connectingWord: 'from',
  },
  ALERT_SMS_SEND: {
    event: 'SMS',
    logType: LogType.ALERT_NOTIFICATION_LOGS,
  },
  ALERT_EMAIL_SEND: {
    event: 'email',
    logType: LogType.ALERT_NOTIFICATION_LOGS,
  },
  IN_PROGRESS: {
    logType: LogType.IN_PROGRESS_LOAD_LOGS,
  },
  DRIVER_PAYMENT_ADD: {
    entityKey: LogEntity.DRIVER,
    logType: LogType.PAYMENT_ADD_LOGS,
  },
  TRACTOR_PAYMENT_ADD: {
    entityKey: LogEntity.TRACTOR,
    logType: LogType.PAYMENT_ADD_LOGS,
  },
  DISPATCHER_PAYMENT_ADD: {
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.PAYMENT_ADD_LOGS,
  },
  CARRIER_PAYMENT_ADD: {
    entityKey: LogEntity.CARRIER,
    logType: LogType.PAYMENT_ADD_LOGS,
  },

  DRIVER_PAYMENT_UPDATE: {
    event: 'updated',
    entityKey: LogEntity.DRIVER,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },
  TRACTOR_PAYMENT_UPDATE: {
    event: 'updated',
    entityKey: LogEntity.TRACTOR,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },
  DISPATCHER_PAYMENT_UPDATE: {
    event: 'updated',
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },
  CARRIER_PAYMENT_UPDATE: {
    event: 'updated',
    entityKey: LogEntity.CARRIER,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },

  DRIVER_PAYMENT_DELETE: {
    event: 'removed',
    entityKey: LogEntity.DRIVER,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },
  TRACTOR_PAYMENT_DELETE: {
    event: 'removed',
    entityKey: LogEntity.TRACTOR,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },
  DISPATCHER_PAYMENT_DELETE: {
    event: 'removed',
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },
  CARRIER_PAYMENT_DELETE: {
    event: 'removed',
    entityKey: LogEntity.CARRIER,
    logType: LogType.PAYMENT_UPDATE_DELETE_LOGS,
  },

  DRIVER_PAYMENT_RECALCULATE: {
    event: 'recalculated',
    entityKey: LogEntity.DRIVER,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  TRACTOR_PAYMENT_RECALCULATE: {
    event: 'recalculated',
    entityKey: LogEntity.TRACTOR,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  DISPATCHER_PAYMENT_RECALCULATE: {
    event: 'recalculated',
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },

  DRIVER_PAYMENT_SHARE: {
    event: 'shared',
    entityKey: LogEntity.DRIVER,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  TRACTOR_PAYMENT_SHARE: {
    event: 'shared',
    entityKey: LogEntity.TRACTOR,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  DISPATCHER_PAYMENT_SHARE: {
    event: 'shared',
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  CARRIER_PAYMENT_SHARE: {
    event: 'shared',
    entityKey: LogEntity.CARRIER,
    logtype: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },

  DRIVER_PAYMENT_DOWNLOAD: {
    event: 'doenloaded',
    entityKey: LogEntity.DRIVER,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  TRACTOR_PAYMENT_DOWNLOAD: {
    event: 'downloaded',
    entityKey: LogEntity.TRACTOR,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  DISPATCHER_PAYMENT_DOWNLOAD: {
    event: 'downloaded',
    entityKey: LogEntity.DISPATCHER,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },
  CARRIER_PAYMENT_DOWNLOAD: {
    event: 'downloaded',
    entityKey: LogEntity.CARRIER,
    logType: LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS,
  },

  DISPATCH_SHEET_EMAIL: { logType: LogType.DISPATCH_EMAIL_LOGS },
  DISPATCH_SHEET_DOWNLOAD: { logType: LogType.DISPATCH_DOWNLOAD_LOGS },

  READY_TO_INVOICE: { logType: LogType.READY_TO_INVOICE },
  REVERT_TO_READY_TO_INVOICE: { logType: LogType.REVERT_TO_READY_TO_INVOICE },
  TAGS_ADDED: { logType: LogType.TAGS_ADDED },
  TAGS_REMOVED: { logType: LogType.TAGS_REMOVED },
  TAGS_MODIFIED: { logType: LogType.TAGS_MODIFIED },
} as any;

const getDataFromArray = (data: Array<hyperLinkProps>) => {
  let result = '';
  if (data && data?.length > 0) {
    data.map((info: hyperLinkProps, index) => {
      result += index == 0 ? info.displayName : ', ' + info.displayName;
    });
  }
  return result;
};
const dummyData = {
  displayName: '',
  type: ESecondaryDetailsPanelType.LOAD,
  id: '',
};

export const secondaryPanelData = {
  DRIVER: ESecondaryDetailsPanelType.DISPATCH,
  DISPATCHER: ESecondaryDetailsPanelType.USER,
} as { [key: string]: ESecondaryDetailsPanelType };

export const LogMessage = (props: logsProps) => {
  const {
    user,
    entity,
    logKey,
    load,
    amount,
    invoiceType = dummyData,
    notes = dummyData,
    emailIds,
    stopType = { ...dummyData, displayName: 'load' },
    trip = dummyData,
    city = dummyData,
    state = dummyData,
    documentName = dummyData,
    documentType = dummyData,
    commodityType = dummyData,
    alertType, //
    alertName = dummyData,
    payType = dummyData,
    stopNumber = dummyData,
    stopStatus = dummyData,
    phoneNumber,
    externalUser = dummyData,
    manifest = dummyData,
    invoice = dummyData,
    handleSecondaryPanelOpen,
  } = props;

  let log = LogType.DEFAULT;
  if (logKey in LogKeys && LogKeys[logKey]) {
    log = LogKeys[logKey].logType;
  }
  let auditLogs = <></>;
  const actionType = LogKeys[logKey]?.event;
  const actualAmount = Number(amount?.displayName);
  const amountValue =
    actualAmount < 0
      ? '-$' + numberFormat(Math.abs(actualAmount))
      : '$' + numberFormat(actualAmount);
  switch (log) {
    case LogType.GENERIC_LOGS: {
      let entityData = {} as hyperLinkProps;
      if (LogEntity[entity] in props) {
        const entityName: string = LogEntity[entity];
        entityData = props[entityName];
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {`${actionType} the ${LogEntity[entity]}`}{' '}
          <LogLink
            data={entityData}
            handlePanelOpen={handleSecondaryPanelOpen}
          />
        </>
      );
      break;
    }
    case LogType.REVERT_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          reverted the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {`to ${actionType} status`}
        </>
      );
      break;
    }
    case LogType.LOAD_PAID_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          marked the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} /> as
          Paid
        </>
      );
      break;
    }
    case LogType.READY_TO_INVOICE: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} /> has
          marked the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} /> in
          Ready to Invoice status
        </>
      );
      break;
    }
    case LogType.REVERT_TO_READY_TO_INVOICE: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} /> has
          reverted the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} /> in
          Ready to Invoice status
        </>
      );
      break;
    }

    case LogType.TAGS_REMOVED: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} /> has
          removed the tag{' '}
          <TagListAuditLogComponent
            tagList={props.addedTags || props.removedTags}
          />{' '}
          in the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.TAGS_ADDED: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} /> has
          attached the tag{' '}
          <TagListAuditLogComponent
            tagList={props.addedTags || props.removedTags}
          />{' '}
          in the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.TAGS_MODIFIED: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} /> has
          attached the tag{' '}
          <TagListAuditLogComponent tagList={props.addedTags || []} /> and has
          removed the tag{' '}
          <TagListAuditLogComponent tagList={props.removedTags || []} /> in the
          load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }

    case LogType.INVOICE_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {`${actionType} ${invoiceType?.displayName} of ${amountValue} in the invoice of load`}{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.INVOICE_DOWNLOAD_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          downloaded the invoice of the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.TRIP_DELETE_LOGS: {
      const newData = { ...trip, isNothyperLink: true } as any;
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          deleted the trip{' '}
          <LogLink
            data={{ ...newData }}
            handlePanelOpen={handleSecondaryPanelOpen}
          />{' '}
          of load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.INVOICE_PAYMENT_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {`${actionType} payment received of ${amountValue} in the invoice ${LogKeys[logKey].connectingWord} load`}{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.LOAD_STOP_STATUS_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          updated the {actionType} stop {stopNumber?.displayName} of the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} /> to{' '}
          {stopStatus?.displayName}
        </>
      );
      break;
    }
    case LogType.IN_PROGRESS_LOAD_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          updated the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} /> to
          In Progress status
        </>
      );
      break;
    }
    case LogType.FACTORING_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {`${actionType} of the load`}{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {LogKeys[logKey].connectingWord} factoring
        </>
      );
      break;
    }
    case LogType.INVOICE_EMAIL: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          shared the invoice of the load with{' '}
          {emailIds ? getDataFromArray(emailIds) : ''}
        </>
      );
      break;
    }
    case LogType.LOAD_ASSIGNMENTS_LOGS: {
      let entityData = {} as hyperLinkProps;
      if (LogKeys[logKey].entityKey in props) {
        const entityName = LogKeys[logKey].entityKey;
        entityData = props[entityName];
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {actionType}{' '}
          {logKey == 'TERMINAL_ASSIGN' ? (
            entityData?.displayName || '-'
          ) : (
            <LogLink
              data={entityData}
              handlePanelOpen={handleSecondaryPanelOpen}
            />
          )}{' '}
          {LogKeys[logKey].connectingWord} the trip{' '}
          <LogLink
            data={{ ...trip }}
            handlePanelOpen={handleSecondaryPanelOpen}
          />{' '}
          of load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.LOAD_STOP_LOGS: {
      let displayData = load;
      if (logKey === 'LOAD_STOP_RESEQUENCE') {
        stopType.displayName = 'load';
      } else if (stopType) {
        const type = stopType?.displayName?.toLocaleString();
        if (type in props) {
          displayData = props[type];
        }
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {LogKeys[logKey].event} {stopType?.displayName} stop{' '}
          {city?.displayName || '-'}, {state?.displayName || '-'}{' '}
          {LogKeys[logKey].connectingWord} {stopType?.displayName}{' '}
          <LogLink
            data={{ ...displayData }}
            handlePanelOpen={handleSecondaryPanelOpen}
          />
        </>
      );
      break;
    }
    case LogType.RELAY_STOP_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {actionType} relay {city?.displayName || '-'},{' '}
          {state?.displayName || '-'} {LogKeys[logKey].connectingWord} the trip{' '}
          <LogLink data={trip} handlePanelOpen={handleSecondaryPanelOpen} /> of
          load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.DRIVER_TRIP_STATUS: {
      let entityData = {} as hyperLinkProps;
      if (LogKeys[logKey].entityKey in props) {
        const entityName = LogKeys[logKey].entityKey;
        entityData = props[entityName];
      }
      auditLogs = (
        <>
          {'Driver '}
          <LogLink
            data={entityData}
            handlePanelOpen={handleSecondaryPanelOpen}
          />{' '}
          {actionType} {'the trip '}
          <LogLink data={trip} handlePanelOpen={handleSecondaryPanelOpen} /> of
          {'load '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.COMMODITY_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {LogKeys[logKey].event} {commodityType?.displayName || '-'} commodity{' '}
          {LogKeys[logKey].connectingWord} the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.DOCUMENT_LOGS: {
      const additionalData = emailIds
        ? 'with ' + getDataFromArray(emailIds)
        : '';
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {actionType} the document {documentName?.displayName || '-'}(
          {documentType?.displayName || '-'}) {LogKeys[logKey].connectingWord}{' '}
          load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {additionalData}
        </>
      );
      break;
    }

    case LogType.ADDITIONAL_LOAD_UPDATE_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          updated the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          information
        </>
      );
      break;
    }
    case LogType.NOTE_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {actionType} a note in the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.ALERT_LOGS: {
      let additionalData = '' as string;
      if (logKey != 'ALERT_DELETE' && alertType) {
        additionalData = getDataFromArray(alertType);
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {actionType} the {additionalData} alert for {alertName?.displayName}{' '}
          {LogKeys[logKey].connectingWord} the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.ALERT_NOTIFICATION_LOGS: {
      let additionalData = '' as string;
      if (alertType) {
        additionalData = getDataFromArray(alertType);
      }
      let notificationData = phoneNumber?.displayName as string;
      if (logKey == 'ALERT_EMAIL_SEND') {
        notificationData = emailIds ? getDataFromArray(emailIds) : '';
      }
      auditLogs = (
        <>
          <LogLink
            data={{ ...externalUser, isNothyperLink: true }}
            handlePanelOpen={handleSecondaryPanelOpen}
          />{' '}
          is notified for the {actionType} alert of the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
          {` through ${actionType} in ${notificationData}`}
        </>
      );
      break;
    }
    case LogType.PAYMENT_ADD_LOGS: {
      let entityData = {} as hyperLinkProps;
      if (LogKeys[logKey].entityKey in props) {
        const entityName = LogKeys[logKey].entityKey;
        entityData = props[entityName];
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          added the {payType?.displayName || '-'} of {amountValue || '-'} to{' '}
          {LogKeys[logKey].entityKey}{' '}
          <LogLink
            data={entityData}
            handlePanelOpen={handleSecondaryPanelOpen}
          />{' '}
          payment in trip{' '}
          <LogLink data={trip} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.PAYMENT_UPDATE_DELETE_LOGS: {
      let additionalData = '' as string;
      if (
        [
          'DRIVER_PAYMENT_DELETE',
          'TRACTOR_PAYMENT_DELETE',
          'DISPATCHER_PAYMENT_DELETE',
        ].includes(logKey)
      ) {
        additionalData = ' of ' + amountValue;
      }
      let entityData = {} as hyperLinkProps;
      if (LogKeys[logKey].entityKey in props) {
        const entityName = LogKeys[logKey].entityKey;
        entityData = props[entityName];
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {LogKeys[logKey].event} the {payType?.displayName || '-'}
          {additionalData} payment of {LogKeys[logKey].entityKey}{' '}
          <LogLink
            data={entityData}
            handlePanelOpen={handleSecondaryPanelOpen}
          />{' '}
          in trip{' '}
          <LogLink data={trip} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.PAYMENT_RECALCULATE_DOWNLOAD_LOGS: {
      let entityData = {} as hyperLinkProps;
      let additionalData = '' as string;
      if (LogKeys[logKey].entityKey in props) {
        const entityName = LogKeys[logKey].entityKey;
        entityData = props[entityName];
      }
      if (
        [
          'DRIVER_PAYMENT_SHARE',
          'TRACTOR_PAYMENT_SHARE',
          'DISPATCHER_PAYMENT_SHARE',
        ].includes(logKey) &&
        emailIds &&
        emailIds?.length > 0
      ) {
        const emails = getDataFromArray(emailIds);
        additionalData = ' with ' + emails;
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          {actionType} the payment of {LogKeys[logKey].entityKey}{' '}
          <LogLink
            data={entityData}
            handlePanelOpen={handleSecondaryPanelOpen}
          />{' '}
          in trip{' '}
          <LogLink data={trip} handlePanelOpen={handleSecondaryPanelOpen} />
          {additionalData}
        </>
      );
      break;
    }
    case LogType.DISPATCH_EMAIL_LOGS: {
      const additionalData = emailIds ? getDataFromArray(emailIds) : '';

      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          shared the dispatch sheet of load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
          {' with '}
          {additionalData}
        </>
      );
      break;
    }
    case LogType.DISPATCH_DOWNLOAD_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          downloaded the dispatch sheet of load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.FACTORY_REFERESH: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          refreshed the status of factoring for the load{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
        </>
      );
      break;
    }
    case LogType.GENERIC_THREE_ENTITY_LOGS: {
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} />{' '}
          changed the trip{' '}
          <LogLink data={trip} handlePanelOpen={handleSecondaryPanelOpen} />
          {` of load`}{' '}
          <LogLink data={load} handlePanelOpen={handleSecondaryPanelOpen} />
          {` to ${actionType} trip`}
        </>
      );
      break;
    }
    case LogType.LOAD_HOLD_RELEASED: {
      let entityName = 'load',
        entityData = load;
      if ('manifest' in props && props['manifest']) {
        entityName = 'manifest';
        entityData = manifest;
      } else if ('invoice' in props && props['invoice']) {
        entityName = 'invoice';
        entityData = invoice;
        if (
          !['MASTER', 'CUSTOM']?.includes(invoiceType?.displayName as string)
        ) {
          entityData = { ...entityData, isNothyperLink: true } as any;
        }
      }
      auditLogs = (
        <>
          <LogLink data={user} handlePanelOpen={handleSecondaryPanelOpen} /> has{' '}
          {`${actionType} ${entityName} `}
          <LogLink
            data={entityData}
            handlePanelOpen={handleSecondaryPanelOpen}
          />
          {` ${LogKeys[logKey].connectingWord} hold${
            notes?.displayName ? ` : "${notes?.displayName}"` : ''
          }`}
        </>
      );
      break;
    }
    case LogType.DEFAULT: {
      auditLogs = <></>;
      break;
    }
  }
  return auditLogs;
};
