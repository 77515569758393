import {
  CreateManageTypeRequest,
  CreateTagRequest,
  DeleteManageTypeRequest,
  DeleteTagRequest,
  DuplicateTagRequest,
  GetManageTypesRequest,
  GetTagColorsRequest,
  GetTagsRequest,
  ManageTypesItem,
  TagColor,
  TagItem,
  UpdateManageTypeRequest,
  UpdateTagRequest,
} from '../../models/DTOs/ManageTypes/Requests';
import { PaginatedResult } from '../../types';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IManageTypesService extends Service {
  abstract getManageTypesList(
    requestData: GetManageTypesRequest,
    routeName: string
  ): Promise<PaginatedResult<ManageTypesItem> | ServiceError>;

  abstract deleteManageType(
    requestData: DeleteManageTypeRequest,
    routeName: string
  ): Promise<string | ServiceError>;

  abstract createManageType(
    requestData: CreateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError>;

  abstract updateManageType(
    requestData: UpdateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError>;

  abstract getTags(
    requestData: GetTagsRequest
  ): Promise<PaginatedResult<TagItem> | ServiceError>;

  abstract createTag(
    requestData: CreateTagRequest
  ): Promise<TagItem | ServiceError>;

  abstract deleteTag(
    requestData: DeleteTagRequest
  ): Promise<string | ServiceError>;

  abstract updateTag(
    requestData: UpdateTagRequest
  ): Promise<TagItem | ServiceError>;

  abstract getTagColors(
    requestData: GetTagColorsRequest
  ): Promise<TagColor[] | ServiceError>;

  abstract duplicateTag(
    requestData: DuplicateTagRequest
  ): Promise<TagItem | ServiceError>;
}
