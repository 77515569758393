const ASSET_MS_TRACTOR = '/web/asset/api/ax2/assets/tractor';
const ASSET_MS_TRAILER = '/web/asset/api/ax2/assets/trailer';
const EXPENSE_MS = '/web/expense/api/v2/expense';
const PREFERENCE_MS = '/web/preference/api/v2/preferences';
const TRIP_EXPENSE_MS = '/web/load/api/v2/tripexpense';
const DRIVER_INFO_MS = '/web/driver/api/v2/drivers/group';
const COLLECTOR_MS = '/web/collector/api/v2/sandbox';

const url = (ms: string, path: string): string => `${ms}${path}`;

export const getPaginatedTrailerListURL = url(ASSET_MS_TRAILER, '/list');
export const updateTrailerURL = url(ASSET_MS_TRAILER, '/update');
export const markTractorAsInactiveURL = url(ASSET_MS_TRACTOR, '/mark-inactive');
export const markTrailerAsInactiveURL = url(ASSET_MS_TRAILER, '/mark-inactive');
export const getTrailerByIdURL = url(ASSET_MS_TRAILER, '/id');
export const deleteTrailerByIdURL = url(ASSET_MS_TRAILER, '/delete');
export const createTrailerURL = url(ASSET_MS_TRAILER, '/create');
export const trailerNameSearchURL = url(ASSET_MS_TRAILER, '/search/names');
export const trailerNameSearchExtendedURL = url(ASSET_MS_TRAILER, '/dropdown');
export const trailerLightListByNameURL = url(ASSET_MS_TRAILER, '/list/short');
export const vinTrailerSearchURL = url(ASSET_MS_TRAILER, '/search/vins');
export const licenseTrailerPlateURL = url(ASSET_MS_TRAILER, '/search/license');
export const exportTrailerToExcelURL = url(ASSET_MS_TRAILER, '/export');
export const getTrailerStaticDataURL =
  '/web/asset/api/v2/assets/trailers/static';

export const vinSearchURL = url(ASSET_MS_TRACTOR, '/search/vins');
export const licensePlateURL = url(ASSET_MS_TRACTOR, '/search/license');
export const exportToExcelURL = url(ASSET_MS_TRACTOR, '/export');
export const createTractorURL = url(ASSET_MS_TRACTOR, '/create');
export const updateTractorURL = url(ASSET_MS_TRACTOR, '/update');
export const getTractorByIdURL = url(ASSET_MS_TRACTOR, '/id');
export const changeTractorStatusByIdStatusURL =
  '/web/asset/api/v2/assets/tractors';
export const deleteTractorByIdURL = url(ASSET_MS_TRACTOR, '/delete');
export const tractorNameSearchURL = url(ASSET_MS_TRACTOR, '/search/names');
export const tractorNameSearchExtendedURL = url(ASSET_MS_TRACTOR, '/dropdown');
export const tractorLightListByNameURL = url(ASSET_MS_TRACTOR, '/list/short');
export const getAssetsCountURL = '/web/asset/api/v2/asset/info/total/count';
export const getPaginatedTractorsListURL = url(ASSET_MS_TRACTOR, '/list');
export const getTractorStaticDataURL =
  '/web/asset/api/v2/assets/tractor/static';

export const verifyAssetDeletionURL =
  '/web/load/api/v2/assets/validate/deletion';

export const getExpenseStaticDataURL = url(EXPENSE_MS, '/static');
export const getTerminalsURL = url(PREFERENCE_MS, '/terminals/list');
export const getStatesURL = '/web/location/api/v2/data/countryandstate/static';
export const getLocationListDetailsByCoordsURL =
  '/web/location/api/v2/search/batch/lookup';
export const getDriverGroupSearchURL =
  '/web/driver/api/v2/drivers/group/info/base/paginated';
export const getDriverGroupDetailsSearchURL =
  '/web/driver/api/v2/drivers/group/details/base/paginated';

export const driverTractorAssignURL = '/web/load/api/v2/driver/tractor/assign';
export const driverTractorUnAssignURL =
  '/web/load/api/v2/driver/tractor/unassign';

//#region tractor alert part

export const getTractorAlertListURL = '/web/asset/api/v2/tractor/alert/list';
export const createTractorAlertURL = '/web/asset/api/v2/tractor/alert';
export const updateTractorAlertsBulkURL =
  '/web/asset/api/v2/tractor/alert/bulk';

export const getDefaultTractorsTypesListURL =
  '/web/preference/api/v2/preference/alert/tractor';

export const getDefaultTractorsTypesDictionaryURL =
  '/web/preference/api/v2/maintenance/items/getByOrganizationId';

export const deleteTractorAlertByIdURL = '/web/asset/api/v2/tractor/alert';
//#endregion

//#region trailer alert part
export const getTrailerAlertListURL = '/web/asset/api/v2/trailer/alert/list';
export const createTrailerAlertURL = '/web/asset/api/v2/trailer/alert';
export const updateTrailerAlertsBulkURL =
  '/web/asset/api/v2/trailer/alert/bulk';

export const getDefaultTrailersTypesListURL =
  '/web/preference/api/v2/preference/alert/trailer';

export const getDefaultTrailersTypesDictionaryURL =
  '/web/preference/api/v2/maintenance/items/getByOrganizationId';

export const deleteTrailerAlertByIdURL = '/web/asset/api/v2/trailer/alert';

export const changeTrailerStatusByIdStatusURL =
  '/web/asset/api/v2/assets/trailers';
//#endregion

// location part
export const validateLocationsUniqueNamesURL =
  'web/location/api/v2/locations/locations/name/validate';
export const createLocationsURL =
  '/web/location/api/v2/locations/create/locations';
//#endregion

// load part
export const validateLoadReferenceIdURL =
  '/web/load/api/v2/loads/creation/validate';
export const getPrevLoadsForDriverAssignPlanURL =
  '/web/load/api/v2/workflow/loads/position';
export const getLoadLightListByNameSearchURL =
  '/web/load/api/v2/loads/summary/seqnumber/list';
export const getLoadSummaryByIdURL =
  '/web/load/api/v2/details/load/summary/by/id';
export const getPaginatedLoadListURL = '/web/load/api/v3/loads/get/list';
export const getLoadWarningsByIdsURL = '/web/load/api/v3/loads/fetch/warnings';
export const createLoadURL = '/web/load/api/v2/loads/new';
export const getLoadOverviewDetailsURL =
  '/web/load/api/v2/details/load/overview';
export const updateLoadReferenceDetailsURL =
  '/web/load/api/v2/details/load/reference-details';
export const getLoadAdditionalDetailsURL =
  '/web/load/api/v2/details/load/additional-details';
export const findLoadDetailsURL =
  'web/load/api/v2/details/load/details/request';

export const findLoadDuplicateDetailsURL =
  'web/load/api/v2/details/load/duplicate/details';

export const updateLoadAdditionalDetailsURL =
  '/web/load/api/v2/details/load/additional-details';
export const getPaginatedTemplateListURL =
  '/web/load/api/v2/loadtemplates/get/list';
export const getTemplateSummaryURL = '/web/load/api/v2/loadtemplates/get/by/id';
export const getParsedLoadDetailsFileURL = '/web/market/api/v2/pxt/parse';
export const getPackageTypesURL =
  '/web/load//api/v2/loads/package-types/static';
export const exportLoadListToExcelURL =
  '/web/load/api/v3/loads/export-to-excel';
export const downloadDispatchSheetURL =
  '/web/load/api/v2/extracts/load/details';
export const downloadDispatchConfirmationURL =
  '/web/load/api/v2/extracts/dispatch-confirmation';
export const getChassisNumberSearchURL =
  '/web/load/api/v2/loads/search/chassisNumber';
export const getAppointmentNumberSearchURL =
  '/web/load/api/v2/loads/search/appointmentNumbers';
export const getBookingNumberSearchURL =
  '/web/load/api/v2/loads/search/bookingNumbers';
export const getContainerNumberSearchURL =
  '/web/load/api/v2/loads/search/containerNumber';
export const createLoadStopURL = '/web/load/api/v2/loads/add-stop';
export const updateLoadStopURL = '/web/load/api/v2/loads/stop';
export const resequenceLoadStopURL = '/web/load/api/v2/loads/stops/re-sequence';
export const deleteLoadStopURL = '/web/load/api/v2/loads/stop';
export const getLoadStaticURL = 'web/load/api/v2/loads/static';
export const getLoadRoutesURL = 'web/load/api/v2/details/trip-routes';
export const assignDispatchTerminalToLoadURL =
  '/web/load/api/v2/details/dispatch-terminal';
export const getDriverInfoForLoadAssignURL =
  'web/load/api/v2/workflow/loads/driver/assignment/preview';
export const assignDriverToLoadURL = 'web/load/api/v2/workflow/assignee';
export const unassignDriverFromLoadURL = 'web/load/api/v2/workflow/unassignee';
export const updateLoadDriverLineItemsURL =
  'web/load/api/v2/loadexpense/update';
export const updateLoadTractorLineItemsURL =
  'web/load/api/v2/loadexpenseTractor/update';
export const updateLoadDispatcherLineItemsURL =
  '/web/load/api/v2/loadexpense/dispatcher/';
export const assignTractorToLoadURL = 'web/load/api/v2/driver/tractor/assign';
export const unassignTractorFromLoadURL =
  'web/load/api/v2/driver/tractor/unassign';
export const assignTrailerToLoadURL = 'web/load/api/v2/driver/trailer/assign';
export const unassignTrailerFromLoadURL =
  'web/load/api/v2/driver/trailer/unassign';
export const assignDispatcherToLoadURL =
  'web/load/api/v2/dispatcher/assignment/assign';
export const unassignDispatcherFromLoadURL =
  'web/load/api/v2/dispatcher/assignment/unassign';
export const getTractorInfoForLoadAssignURL =
  'web/load/api/v2/workflow/loads/tractor/assignment/preview';
export const getDispatcherInfoForLoadAssignURL =
  'web/load/api/v2/workflow/loads/dispatcher/assignment/preview';
export const getSingleDriverInfoForLoadAssignURL =
  'web/load/api/v2/workflow/loads/single-driver/assignment/preview';
export const getDriverWarningsForLoadAssignURL =
  'web/load/api/v2/internal/api/loads/preview/warnings/maintenance';
export const getLoadSummaryListURL = 'web/load/api/v2/loads/seqnumber/list';
export const getLoadRefNumberListURL =
  'web/load/api/v2/loads/summary/reference/list';
export const getLoadOriginDestinationListURL =
  'web/load/api/v2/loads/get/city/state/list';
export const getLoadLocationNamesListURL =
  'web/load/api/v2/loads/location-name/list';
export const updateLoadURL = 'web/load/api/v2/loads/update/patch';
export const splitLoadURL = 'web/load/api/v2/loads/split';
export const getAllActivities = 'web/load/api/ax2/load/stop/activities/preview';
export const updateAllActivities =
  'web/load/api/ax2/load/stop/activities/trip/update';
export const getLoadsCountURL = 'web/load/api/v2/loads/count/loads';
export const getTotalRevenueURL = 'web/load/api/v3/loads/revenue/total';
export const getTripListRevenueShareURL =
  'web/load/api/v2/loads/calculate/revenue-share';
//#end load

//#region payment terms part

export const getExpenseCategoriesURL =
  '/web/preference/api/v2/expense/items/getByOrganizationId';

export const getPaymentTypesURL =
  '/web/preference/api/v2/settlement/items/getByOrganizationId';

export const getDriverPaymentTermsURL =
  '/web/people/api/v2/paymentterms/payment/terms/bulk';

export const getDriverLoadRatePaymentURL =
  '/web/people/api/v2/paymentterms/loadrate';

export const existsPayTypeItemByIdURL =
  '/web/people/api/v2/paymentterms/get/existsPayTypeItemById';

export const createTractorPaymentTermsURL =
  'web/asset/api/v2/tractorPaymentTerms/loadrate';

export const createDriverPaymentTermsURL =
  '/web/people/api/v2/paymentterms/loadrate';
export const createWebUserPaymentTermsURL =
  '/web/people/api/v2/paymentterms/dispatcher/loadrates';

export const bulkUpdateDriverPaymentTermsURL =
  '/web/people/api/v2/paymentterms/payment/terms/bulk';

export const bulkUpdateWebUserPaymentTermsURL =
  '/web/people/api/v2/paymentterms/dispatcher/loadrates/bulk';

export const bulkUpdateTractorPaymentTermsURL =
  '/web/asset/api/v2/tractorPaymentTerms/updatePaymentTerms/bulk';

export const deleteLoadRateByIDs = '/web/people/api/v2/paymentterms/loadrate';

export const deleteTractorLoadRateByIDs =
  '/web/asset/api/v2/tractorPaymentTerms/loadrate';

export const deleteWebUserLoadRateByIDs =
  '/web/people/api/v2/paymentterms/dispatcher/loadrates/by-ids';

export const getTractorPaymentTermsURL =
  '/web/asset/api/v2/tractorPaymentTerms/payment/terms';

export const getWebUserPaymentTermsURL =
  '/web/people/api/v2/paymentterms/dispatcher/loadrates';
//#endregion

export const getListDataTractorURL =
  '/web/asset/api/v2/assets/tractors/base-details';
export const getListDataTrailerURL =
  '/web/asset/api/v2/assets/trailers/base-details';
export const teamDriverActive = 'web/driver/api/v2/drivers/group';
export const tractorAssign = 'web/load/api/v2/driver/tractor/assign';
export const tractorUnassign = 'web/load/api/v2/driver/tractor/unassign';
export const trailerAssign = 'web/load/api/v2/driver/trailer/assign';
export const trailerUnassign = 'web/load/api/v2/driver/trailer/unassign';
export const validate = 'web/load/api/v2/safety/validate';
export const validateWarnings = 'web/load/api/v2/warnings/validateWarnings';

export const implicitAssignURL =
  'web/driver/api/v2/drivers/group/edit/assignment-methods';
export const validateMaintenanceWarnings = 'web/load/api/v2/safety/validate';
export const creatTeamDriverURL = '/web/driver/api/v2/drivers/group';
export const getExpenseCategoriesURLFinance =
  'web/load/api/v2/details/finance/overview';
export const financialItemURL =
  'web/preference/api/v2/financial/items/getByOrganizationId';
export const invoiceURl = 'web/invoice/api/v3/invoice/get/loadId';
export const financeLoadDriverURL = 'web/load/api/v2/loadexpense/get';
export const financeLoadTractorURL = 'web/load/api/v2/loadexpenseTractor/get';
export const financeLoadDispatcherURL =
  'web/load/api/v2/loadexpense/dispatcher/get/by-load-id';
export const financeLoadCreateLineItemURL =
  'web/load/api/v2/loadexpense/create/lineitem';
export const financeLoadUpdateLineItemURL =
  'web/load/api/v2/loadexpense/update/lineitem';
export const financeLoadDeleteLineItemURL =
  'web/load/api/v2/loadexpense/delete/lineitem';
export const financeLoadDispatcherCreateLineItemURL =
  'web/load/api/v2/loadexpense/dispatcher/create/lineitem';
export const financeLoaddDispatcherUpdateLineItemURL =
  'web/load/api/v2/loadexpense/dispatcher/update/lineitem';
export const createLoadNewPaymentExpenseURL =
  'web/load/api/v2/loadexpenseTractor/create/manual';
export const deleteTractorPaymentExpenseURL =
  'web/load/api/v2/loadexpenseTractor/delete/by-id';
export const deleteDispatcherPaymentExpenseURL =
  'web/load/api/v2/loadexpense/dispatcher/by-id';
export const deleteDriverPaymentExpenseURL =
  'web/load/api/v2/loadexpense/delete/by-id';
export const createTractorExpenseManualURL =
  'web/load/api/v2/loadexpenseTractor/create/manual';
export const createDriverExpenseManualURL =
  'web/load/api/v2/loadexpense/create/manual';
export const createDispatcherExpenseManualURL =
  'web/load/api/v2/loadexpense/dispatcher/create/manual';
export const financeLoadDispatcherDeleteLineItemURL =
  'web/load/api/v2/loadexpense/dispatcher/delete/lineitem';
export const financeLoadTractorCreateLineItemURL =
  'web/load/api/v2/loadexpenseTractor/create/lineitem';
export const financeLoaddTractorUpdateLineItemURL =
  'web/load/api/v2/loadexpenseTractor/update/lineitem';
export const financeLoadTractorDeleteLineItemURL =
  'web/load/api/v2/loadexpenseTractor/delete/lineitem';
export const financeLoadInitialCreateLineItemURL =
  'web/load/api/v2/loadexpense/create/driverLoadExpense';
export const financeLoadTractorInitialCreateLineItemURL =
  'web/load/api/v2/loadexpenseTractor/create/tractorLoadExpense';
export const financeLoadDispatcherInitialCreateLineItemURL =
  'web/load/api/v2/loadexpense/dispatcher';
export const financeLoadDownloadURL =
  '/web/load/api/v2/details/finance/get/load-rate-pdf';

export const GetAutopopulateDriverURL =
  '/web/load/api/v2/loadexpense/autopopulate';
export const GetAutopopulateTractorURL =
  '/web/load/api/v2/loadexpenseTractor/autopopulate';
export const GetAutopopulateDispatcherURL =
  '/web/load/api/v2/loadexpense/dispatcher/autopopulate';

export const driverStatementInfoURL =
  '/web/dpm/api/v2/payment/pay-item/get/statement/info';
export const tractorStatementInfoURL =
  '/web/dpm/api/v2/payment/pay-item/get/statement/info';
export const dispatcherStatementInfoURL =
  'web/dpm/api/v2/payment/pay-item/get/statement/info';
export const driverAccessURL =
  '/web/load/api/v2/loadexpenseTractor/driver-access';

export const DRIVERS_GROUP_LAST_URL = {
  getLastHosInfo: '/web/driver/api/v2/drivers/group/last/hosInfo/get',
  getIgnoreHosInfo:
    '/web/driver/api/v2/drivers/group/last/hosInfo/getDriverHOSPreference?organizationId=',
  getHosLastLocation: '/web/driver/api/v2/drivers/group/last/get',
  updateManualHosLocation:
    '/web/driver/api/v2/drivers/group/last/manual/update',
  getLoadDetailsActions: '/web/load/api/v2/details/actions',
};

export const loadActionURL = '/web/load/api/ax2/load-trip/actions/load';
export const templateActionURL = '/web/load/api/v2/loadtemplates/';
export const deleteTemplateURL = 'web/load/api/v2/loadtemplates/loadtemplate';
export const deleteLoadURL = '/web/load/api/v2/loads/load';
export const deleteLoadManifestURL = '/web/load/api/v2/manifest';
export const getLoadMapURL = '/web/load/api/v2/details/trip-routes/map';
export const getTripMapURL = '/web/load/api/v2/trip-details/trip-routes/map';
export const exportTemplateListToExcelURL =
  '/web/load/api/v2/loadtemplates/export-to-excel';
export const getManifestPreviewURL =
  '/web/load/api/v2/manifest/create-expand/preview';
export const createManifestURL = '/web/load/api/v2/manifest';
export const manifestReCalculationURL =
  '/web/load/api/v2/manifest/recalculate-kpis';
export const manifestInvokeURL = '/web/loadselect/api/v2/manifest/invoke';
export const LoadOverviewRemoveLoadsURL =
  '/web/load/api/v2/manifest/remove-load';
export const getSolutionDelayURL =
  '/web/loadselect/api/v2/manifest/get/solution';
export const addLoadManifestURL = '/web/load/api/v2/manifest/add-load';
export const getFinanceRevenueShareURL =
  '/web/load/api/v2/details/finance/revenue-share';
export const getUpdatedManifestLoadURL = '/web/load/api/v2/loads/get/multiple';

export const logAuditURL = '/web/audit/api/v2/auditLog/get-action-logs';

export const contractListUrl = 'web/customer/api/v2/contracts/list';
export const contractNameSerachUrl = 'web/customer/api/v2/contracts/list/names';
export const contractExportToExcelUrl =
  'web/customer/api/v2/contracts/export-to-excel';
export const getLoadMilesById = 'web/load/api/v2/routes/mileage/info';
export const addCommodityUrl = 'web/load/api/v2/details/commodity-data';
export const updateLoadModeUrl = 'web/load/api/v2/details/meta-data';
export const updateLoadDetailsUrl = 'web/load/api/v2/details/load-details';
export const CARRIER_PAYMENT_RESOURCES = {
  createCarrierPayment: `${TRIP_EXPENSE_MS}/carrier/create`,
  updateCarrierPayment: `${TRIP_EXPENSE_MS}/carrier/update`,
  updateCarrierPaymentStatus: `${TRIP_EXPENSE_MS}/carrier/update/partial`,
  getCarrierPaymentByTripId: `${TRIP_EXPENSE_MS}/carrier/get/by-trip-id`,
  deleteCarrierPaymentById: `${TRIP_EXPENSE_MS}/carrier/by-id`,
  deleteCarrierPaymentTripId: `${TRIP_EXPENSE_MS}/carrier/by-trip-id`,
  getTripCarrierAssignmentPreview: `web/load/api/v2/workflow/trips/carrier/assignment/preview`,
  downloadCarrierRateConfirmationSheet: `web/load/api/v2/extracts/carrier-confirmation`,
  carrierList: `web/customer/api/v2/carriers/carrier/list/short`,
  brokeredUnAssign: `web/load/api/v2/trip-details/update/brokered-state`,
  carrierAssign: `web/load/api/v2/workflow/assignee-career`,
  onHoldStateLoad: 'web/load/api/v2/loads/onhold-state',
};

export const DRIVER_INFO_ENDPOINTS = {
  DRIVER_LIST: `${DRIVER_INFO_MS}/info/base/paginated`,
};

export const LOADAI_ENDPOINTS = {
  DRIVER_DEFAULT_PARAMETERS: `${COLLECTOR_MS}/reset-sandbox-settings`,
};

export const TAGS_EBDPOINTS = {
  GET_TAGS_LIST: 'web/load/api/v2/tags/get/list',
  TAG_CREATE: 'web/load/api/v2/tags',
  TAG_ASSIGNMENT: 'web/load/api/v2/loads/update/tag-assignment',
  TAG_BULK_ASSIGNMENT: 'web/load/api/v2/loads/bulk-update/tag-assignment',
};
