import StorageManager from '@/StorageManager/StorageManager';
import { useLoadPermission } from '@/common/hooks/useLoadPermission';
import { TextField } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Controller } from 'react-hook-form';
import {
  customerService,
  invoiceService,
  loadService,
  manageTypesService,
} from '../../api';
import { ServiceError } from '../../api/interfaces';
import { getOrganizationId, getUserId } from '../../common/TimeoffDialog/utils';
import {
  DriverMappingType,
  GetCustomerListRequest,
  GetFundingAccountsRequest,
  GetInvoiceSummaryListRequest,
  GetLoadLocationNamesRequest,
  GetLoadOriginDestinationRequest,
  GetLoadRefNumberRequest,
  GetLoadSummaryListRequest,
  InvoiceListRequest,
  PaginatedTagsListQueryParams,
} from '../../models';
import { LineItem, View, ViewMetaData } from '../../types';
import { getCurrentDateTime, prepareInvoiceFiltersFromList } from '../../utils';
import StaticActions from '../../utils/StaticActions';
import { DateTimezoneUtils } from '../../utils/Timezone.utils';
import { DEFAULT_INVOICE_SORT_NAME } from '../../views/finance/invoices/constants';
import {
  APEX_PROVIDER_ID,
  CANCELLED_LOAD_STATUS_NAME,
  COMPLETED_LOAD_STATUS_NAME,
  DISCONNECT_FACTORING,
  FACTORING_STATUS_INVOICE_APPROVAL,
  FACTORING_STATUS_INVOICE_AUDIT,
  FACTORING_STATUS_INVOICE_FUNDED_NOTPAID,
  FACTORING_STATUS_INVOICE_FUNDED_ZEROADVANCE,
  FACTORING_STATUS_INVOICE_INVOICE_FUNDED_PAID,
  FACTORING_STATUS_INVOICE_ISSUES,
  FACTORING_STATUS_INVOICE_LOGGED,
  FACTORING_STATUS_INVOICE_NOTREADYTOBILL,
  FACTORING_STATUS_INVOICE_READYTOBILL,
  FACTORING_STATUS_INVOICE_SENT,
  FACTORING_STATUS_INVOICE_SUBMITTED,
  INVOICED,
  INVOICED_LOAD_STATUS_NAME,
  PAID,
  PAID_LOAD_STATUS_NAME,
  REFRESH_FACTORING_STATUS,
  REVERT_TO_CANCELLED,
  REVERT_TO_COMPLETED,
  REVERT_TO_INVOICED,
  RTS_PROVIDER_ID,
  SEND_TO_FACTORING,
  SUBMIT_TO_SCHEDULE,
  SYNC_DOCUMENTS,
  SYNC_MODIFIED_INVOICE,
  TAFS_PROVIDER_ID,
  disableBulkActionsForSendProviderIds,
  disableBulkActionsProviderIds,
  disablePanelActionsForSyncProviderIds,
  disablePanelActionsProviderIds,
  TRIUMPH_PROVIDER_ID,
} from './constants';
import {
  CustomerContactType,
  EmailFormType,
  FactoringActionReturnType,
  FactoringErrorDTOType,
  InvoiceModel,
} from './models/InvoiceModel';
import {
  GetTagsRequest,
  TagEntityType,
} from '@/models/DTOs/ManageTypes/Requests';

export const getInvoiceFiltersForRequest = (
  viewMetaData: ViewMetaData,
  isListCall = true
) => {
  const fetchBySeqNumbers = prepareInvoiceFiltersFromList(
    viewMetaData['fetchBySeqNumbers'],
    'seqNumber'
  );
  const loadSeqNumbers = prepareInvoiceFiltersFromList(
    viewMetaData['loadSeqNumbers'],
    'seqNumber'
  );
  const customerNameFilter = prepareInvoiceFiltersFromList(
    viewMetaData['customerNameFilter'],
    'name',
    isListCall
  );
  const driverNameFilter = prepareInvoiceFiltersFromList(
    viewMetaData['driverNameFilter'],
    'nameToFilter',
    isListCall
  );
  const factoringNameFilter = prepareInvoiceFiltersFromList(
    viewMetaData['factoringNameFilter'],
    'displayName',
    isListCall
  );
  const loadStatusList = prepareInvoiceFiltersFromList(
    viewMetaData['loadStatusList'],
    'value'
  );
  const referenceIds = prepareInvoiceFiltersFromList(
    viewMetaData['referenceIds'],
    'referenceId'
  );
  const originCityStateList = prepareInvoiceFiltersFromList(
    viewMetaData['originCityStateList'],
    'value'
  );
  const destinationCityStateList = prepareInvoiceFiltersFromList(
    viewMetaData['destinationCityStateList'],
    'value'
  );
  const originStateList = prepareInvoiceFiltersFromList(
    viewMetaData['originStateList'],
    'value'
  );
  const locationNameList = prepareInvoiceFiltersFromList(
    viewMetaData['locationNameList'],
    'value'
  );
  const destinationStateList = prepareInvoiceFiltersFromList(
    viewMetaData['destinationStateList'],
    'value'
  );
  const invoiceTypeList = prepareInvoiceFiltersFromList(
    viewMetaData['invoiceTypeList'],
    'value'
  );
  const tagIds = prepareInvoiceFiltersFromList(viewMetaData['tagIds'], 'id');

  const terminalIds = viewMetaData.terminalIds;
  const completedFromDate = DateTimezoneUtils.toStartDateISOString(
    viewMetaData.completeDate?.dateRange?.[0],
    null
  );
  const completedToDate = DateTimezoneUtils.toEndDateISOString(
    viewMetaData.completeDate?.dateRange?.[1],
    null
  );
  const invoicedOnFromDate = DateTimezoneUtils.toStartDateISOString(
    viewMetaData.invoicedOn?.dateRange?.[0],
    null
  );
  const invoicedOnToDate = DateTimezoneUtils.toEndDateISOString(
    viewMetaData.invoicedOn?.dateRange?.[1],
    null
  );
  const paidOnFromDate = DateTimezoneUtils.toStartDateISOString(
    viewMetaData.paidOn?.dateRange?.[0],
    null
  );
  const paidOnToDate = DateTimezoneUtils.toEndDateISOString(
    viewMetaData.paidOn?.dateRange?.[1],
    null
  );
  const updatedFromDate = DateTimezoneUtils.toStartDateISOString(
    viewMetaData.updatedOn?.dateRange?.[0],
    null
  );
  const updatedToDate = DateTimezoneUtils.toEndDateISOString(
    viewMetaData.updatedOn?.dateRange?.[1],
    null
  );
  const documentNeeded = viewMetaData['documentNeeded']
    ? viewMetaData['documentNeeded'].value
    : null;
  const calculateDetention =
    viewMetaData['calculateDetention']?.name === 'Yes' ? true : null;
  const emailIsSent = viewMetaData['emailIsSent']?.name === 'Yes' ? true : null;
  const isOverdue = viewMetaData['isOverdue']
    ? viewMetaData['isOverdue'].value
    : null;

  const invoiceSeqNumbers = prepareInvoiceFiltersFromList(
    viewMetaData['invoiceSeqNumbers'],
    'seqNumber'
  );

  const onHoldState = viewMetaData['onHoldState']
    ? viewMetaData['onHoldState'].value
    : null;
  return {
    locationNameList,
    fetchBySeqNumbers,
    customerNameFilter,
    driverNameFilter,
    factoringNameFilter,
    loadStatusList,
    referenceIds,
    originCityStateList,
    destinationCityStateList,
    originStateList,
    destinationStateList,
    completedFromDate,
    completedToDate,
    invoicedOnFromDate,
    invoicedOnToDate,
    paidOnFromDate,
    paidOnToDate,
    updatedFromDate,
    updatedToDate,
    documentNeeded,
    calculateDetention,
    terminalIds,
    isOverdue,
    invoiceTypeList,
    invoiceSeqNumbers,
    loadSeqNumbers,
    onHoldState,
    emailIsSent,
    tagIds,
  };
};

export const getInvoiceFilteredRequest = (
  viewMetaData: ViewMetaData,
  request: InvoiceListRequest
) => {
  const {
    fetchBySeqNumbers,
    customerNameFilter,
    driverNameFilter,
    factoringNameFilter,
    loadStatusList,
    referenceIds,
    originCityStateList,
    destinationCityStateList,
    originStateList,
    destinationStateList,
    completedFromDate,
    completedToDate,
    invoicedOnFromDate,
    invoicedOnToDate,
    paidOnFromDate,
    paidOnToDate,
    updatedFromDate,
    updatedToDate,
    documentNeeded,
    calculateDetention,
    isOverdue,
    terminalIds,
    invoiceTypeList,
    invoiceSeqNumbers,
    loadSeqNumbers,
    onHoldState,
    emailIsSent,
    tagIds,
    locationNameList,
  } = getInvoiceFiltersForRequest(viewMetaData);
  // request.invoiceSeqNumbers = fetchBySeqNumbers;
  request.loadSeqNumbers = loadSeqNumbers;
  request.locationNameList = locationNameList;
  request.customerNameFilter = customerNameFilter;
  request.driverNamesFilter = driverNameFilter;
  request.factoringNameFilter = factoringNameFilter;
  request.loadStatusList = loadStatusList;
  request.referenceIds = referenceIds;
  request.originCityStateList = originCityStateList;
  request.destinationCityStateList = destinationCityStateList;
  request.originStateList = originStateList;
  request.destinationStateList = destinationStateList;
  request.completedFromDate = completedFromDate;
  request.completedToDate = completedToDate;
  request.invoicedOnFromDate = invoicedOnFromDate;
  request.invoicedOnToDate = invoicedOnToDate;
  request.paidOnFromDate = paidOnFromDate;
  request.paidOnToDate = paidOnToDate;
  request.updatedFromDate = updatedFromDate;
  request.updatedToDate = updatedToDate;
  request.documentNeeded = documentNeeded;
  request.calculateDetention = calculateDetention;
  request.isOverdue = isOverdue;
  request.terminalIds = terminalIds;
  request.invoiceTypeList = invoiceTypeList;
  request.emailIsSent = emailIsSent;
  request.tagIds = tagIds;
  request.loadIds = [];
  request.invoiceIds = [];
  request.ageDayGt = null;
  request.invoiceSeqNumbers = invoiceSeqNumbers;
  request.onHoldState = onHoldState;

  return request;
};

// "invoiceTypeList": [
//   "MASTER"
// ],
// "driverNamesFilter": [
//   "string"
// ],

// "ageDayGt": 0,
// "loadIds": [
//   "string"
// ],
// "invoiceIds": [
//   "string"
// ],
// "invoiceSeqNumbers": [
//   "string"
// ],
// "loadSeqNumbers": [
//   "string"
// ],
// "synchStatus": "SYNCED",
// "isFactoringToggleOn": true,
// "calledFromQB": true
// get customer list
export const getCustomerNamesList = async (
  text: string,
  pageNumber: number
) => {
  const requestData = new GetCustomerListRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.nameKeyword = text;

  const response = await customerService.getCustomerList(requestData);
  if (response instanceof ServiceError) {
    return [];
  } else {
    const result: any = response;
    result.content = response.content.map(
      (r: { name: string; id: number }) => ({
        name: r.name,
        id: r.id,
      })
    );
    return result;
  }
};

// get load reference ids
export const getLoadReferenceIds = async (
  text: string,
  pageNumber: number,
  terminalIds?: string[]
) => {
  const requestData = new GetLoadRefNumberRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.reference = text;
  requestData.terminalIds = terminalIds;
  const response = await loadService.getLoadRefIdList(requestData);
  if (response instanceof ServiceError) {
    return [];
  } else {
    const result: any = response;
    result.content = response.content.map((r) => ({
      referenceId: r.referenceId,
      loadIds: r.loadIds,
    }));
    return result;
  }
};

// get invoice seq numbers
export const getLoadSeqNumbers = async (
  text: string,
  pageNumber: number,
  loadStatusList: string[] = [],
  terminalIds: string[] = [],
  loadType = ''
) => {
  const requestData = new GetLoadSummaryListRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.seqNumber = text;
  requestData.terminalIds = terminalIds;
  requestData.loadStatusList = loadStatusList;
  requestData.loadType = loadType;

  const response = await loadService.getLoadSummaryList(requestData);
  if (response instanceof ServiceError) {
    return [];
  } else {
    const result: any = response;
    result.content = response.content.map((r) => ({
      seqNumber: r.seqNumber,
      id: r.id,
      loadIds: r.loadIds,
    }));
    return result;
  }
};

export const getPaginatedTagsList = async (
  text: string,
  pageNumber: number,
  entityTypes: TagEntityType[]
) => {
  const queryParams = new GetTagsRequest();
  queryParams.pageNumber = pageNumber;
  queryParams.pageSize = 25;
  queryParams.searchText = text;
  queryParams.entityTypes = entityTypes;

  const tagList = await manageTypesService.getTags(queryParams);
  if (tagList instanceof ServiceError) {
    return [];
  } else {
    const result: any = tagList;
    return result;
  }
};

// get load origin and destinations
export const getLoadOriginDestinations = async (
  text: string,
  pageNumber: number,
  terminalIds: string[]
) => {
  const requestData = new GetLoadOriginDestinationRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.city = text;
  requestData.terminalIds = terminalIds;

  const response = await loadService.getLoadOriginDestinationList(requestData);
  if (response instanceof ServiceError) {
    return [];
  } else {
    const result: any = response;
    result.content = response.content.map((r) => ({
      name: `${r.city}, ${r.state}`,
      value: `${r.city};${r.state}`,
      center: r.center,
    }));
    return result;
  }
};

export const getLoadLocationNames = async (
  text: string,
  pageNumber: number,
  terminalIds: string[]
) => {
  const requestData = new GetLoadLocationNamesRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.keyword = text;
  requestData.terminalIds = terminalIds;

  const response = await loadService.getLoadLocationNamesList(requestData);
  if (response instanceof ServiceError) {
    return [];
  } else {
    const result: any = response;
    result.content = response.content
      .filter((item) => !!item)
      .map((r) => ({
        name: r,
        value: r,
      }));
    return result;
  }
};

export const getInvoiceSeqNumberList = async (
  text: string,
  pageNumber: number,
  loadStatusList: string[] = [],
  terminalIds: string[] = [],
  loadType = ''
) => {
  const requestData = new GetInvoiceSummaryListRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.seqNumber = text;
  requestData.terminalIds = terminalIds;
  requestData.statusList = loadStatusList;
  requestData.loadType = loadType;

  const response = await loadService.getInvoiceSeqNumberList(requestData);
  if (response instanceof ServiceError) {
    return [];
  } else {
    // const result: any = response;
    // result.content = response.content.map((r) => ({
    //   seqNumber: r.seqNumber,
    //   id: r.id,
    // }));
    return response;
  }
};

// get factoring companies
export const getFactoringCompanies = async () => {
  const result = await StaticActions.getFactoringStatic();
  if (!result) {
    return [];
  }
  const companies: Partial<{
    last: boolean;
    content: { displayName: string; id: string }[];
  }> = {};
  companies.content = result.map(
    (company: { displayName: string; id: string }) => ({
      displayName: company.displayName,
      id: company.id,
    })
  );
  companies.last = true;
  return companies;
};

// get factoring companies
export const getFundingAccounts = async (
  text: string,
  pageNumber: number,
  credentialId: string,
  excludeIds: string[]
) => {
  const requestData = new GetFundingAccountsRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.search = text;
  requestData.credentialId = credentialId;
  requestData.excludeFundingIds = excludeIds;

  const response = await invoiceService.getFundingAccounts(requestData);
  if (response instanceof ServiceError) {
    return [];
  } else {
    const result: any = response;
    result.content = response.content.map((mappingInfo: DriverMappingType) => ({
      mappingId: mappingInfo.mappingId,
      mappingDescription: `${mappingInfo.mappingCode} - ${mappingInfo.mappingDescription}`,
    }));
    return result;
  }
};

export const resetFactoringRelatedFields = (
  data: InvoiceModel,
  invoiceDetails: InvoiceModel
) => {
  data.factoringId = invoiceDetails!.factoringId;
  data.factoringInvoiceId = invoiceDetails!.factoringInvoiceId;
  data.factoringStatus = invoiceDetails!.factoringStatus;
  data.factoringDocumentStatus = invoiceDetails!.factoringDocumentStatus;
  data.factoringNotes = invoiceDetails!.factoringNotes;
  data.factoringInvoiceUpdateDate = invoiceDetails!.factoringInvoiceUpdateDate;
  data.factoringScheduleId = invoiceDetails!.factoringScheduleId;
  return data;
};

// export const generateUpdateInvoiceData = (
//   data: InvoiceModel,
//   invoiceDetails: InvoiceModel
// ) => {
//   if (invoiceDetails.notes !== data.notes) {
//     const user = JSON.parse(localStorage.getItem('user')!);
//     const noteDetails = {
//       updateTime: moment(),
//       userId: user.id,
//     };
//     data.noteDetails = noteDetails;
//   }
//   if (!data.factoringCompany) {
//     data.useFactoring = false;
//     data = resetFactoringRelatedFields(data, new InvoiceModel({}));
//   } else {
//     if (invoiceDetails.factoringCompany !== data.factoringCompany) {
//       data.useFactoring = true;
//       data = resetFactoringRelatedFields(data, new InvoiceModel({}));
//     } else {
//       data.useFactoring = true;
//       data = resetFactoringRelatedFields(data, invoiceDetails);
//     }
//   }
//   return data;
// };

export const getInvoiceEmailDefaultSubject = (
  seqNumber: string,
  organizationName: string,
  loadReferenceId: string
) => {
  return `Invoice ${seqNumber} ${
    loadReferenceId ? ` (Ref. # ${loadReferenceId})` : ''
  } from ${organizationName}`;
};

export const getInvoiceEmailDefaultMessage = (
  invoice: InvoiceModel,
  organizationName: string
) => {
  return `<p>Dear ${
    invoice?.customer?.name
  }, <p><br /></p><p>Please find attached the Invoice ${invoice?.seqNumber}${
    invoice?.invoiceReferenceId
      ? ` (Ref. # ${invoice?.invoiceReferenceId})`
      : ''
  }.</p><p><br /></p><p>Regards,</p><p>${organizationName}</p>`;
};

export const getInvoiceEmailDefaultReceivers = (invoices: InvoiceModel[]) => {
  return invoices
    .map((invoice) => {
      return (
        invoice.factoringCompanyEmail ||
        invoice.customer?.contacts?.find((c) => !!c.isPrimary)?.email ||
        ''
      );
    })
    .filter((email) => !!email);
};

export const getEmailDefaultReceiver = (invoice: InvoiceModel) =>
  invoice.factoringCompanyEmail ||
  invoice.customer?.contacts?.find((contact) => !!contact.isPrimary)?.email ||
  '';

export const getInvoiceEmailDocumentListObjects = (
  invoice: InvoiceModel,
  sendDocumentTypes: string[],
  other: boolean
) => {
  return invoice.assignedDocuments
    .filter(
      (d) =>
        sendDocumentTypes.includes(d.documentType) ||
        (other && !sendDocumentTypes.includes(d.documentType))
    )
    .map((d) => ({ id: d.documentId }));
};

export const getInvoiceEmailDocumentList = (
  invoice: InvoiceModel,
  sendDocumentTypes: string[],
  other: boolean
) => {
  return invoice.assignedDocuments
    .filter(
      (d) =>
        sendDocumentTypes.includes(d.documentType) ||
        (other && !sendDocumentTypes.includes(d.documentType))
    )
    .map((d) => d.documentId);
};

export const getSendDocumentTypes = (data: EmailFormType) => {
  const { rateConfirmation, proofOfDelivery, billOfLading, other } = data;
  const sendDocumentTypes: string[] = [];
  if (rateConfirmation) {
    sendDocumentTypes.push('RATE_CONFIRMATION');
  }
  if (proofOfDelivery) {
    sendDocumentTypes.push('PROOF_OF_DELIVERY');
  }
  if (billOfLading) {
    sendDocumentTypes.push('BILL_OF_LADING');
  }
  if (other) {
    sendDocumentTypes.push('OTHER');
  }
  return sendDocumentTypes;
};

export const getFactoringWarning = (error: FactoringErrorDTOType) => {
  if (error.invoiceNumber) {
    return `(${error.invoiceNumber}) ${error.message}`;
  }
  return error.message;
};

export const getResponseSubMessage = (response: FactoringErrorDTOType[]) => {
  return response.map((error) => getFactoringWarning(error)).join('');
};

export const getIfNeedAddToTable = (
  status: string,
  tableData: InvoiceModel[],
  invoice: InvoiceModel,
  currentView: View
) => {
  const currentItem = tableData.find((data: any) => data.id === invoice.id);
  if (
    currentView.id === 'completed' &&
    (status === REVERT_TO_COMPLETED || status === REVERT_TO_CANCELLED) &&
    !currentItem
  ) {
    return true;
  }
  if (
    currentView.id === 'invoiced' &&
    (status === REVERT_TO_INVOICED || status === INVOICED) &&
    !currentItem
  ) {
    return true;
  }
  return currentView.id === 'paid' && status === PAID && !currentItem;
};

export const getSelectedInvoices = (
  tableData: InvoiceModel[],
  selectionModel: GridSelectionModel
) => {
  return tableData!.filter((data) => selectionModel.includes(data.id));
};

export const getFactoringDisplayedActions = (invoice: InvoiceModel) => {
  const {
    factoringStatus,
    loadStatus,
    amountDue,
    factoringProviderId,
    customerMapped,
    isFactoringInvalid,
  } = invoice;
  if (
    isFactoringInvalid ||
    (typeof customerMapped === 'boolean' && !customerMapped)
  ) {
    return [
      SEND_TO_FACTORING,
      REFRESH_FACTORING_STATUS,
      SYNC_DOCUMENTS,
      SYNC_MODIFIED_INVOICE,
      DISCONNECT_FACTORING,
      SUBMIT_TO_SCHEDULE,
    ];
  }
  if (!factoringStatus) {
    if (loadStatus === PAID_LOAD_STATUS_NAME) {
      return [
        SEND_TO_FACTORING,
        REFRESH_FACTORING_STATUS,
        SYNC_DOCUMENTS,
        SYNC_MODIFIED_INVOICE,
        DISCONNECT_FACTORING,
        SUBMIT_TO_SCHEDULE,
      ];
    }
    return [
      REFRESH_FACTORING_STATUS,
      SYNC_DOCUMENTS,
      SYNC_MODIFIED_INVOICE,
      DISCONNECT_FACTORING,
      SUBMIT_TO_SCHEDULE,
    ];
  }
  //
  if (factoringStatus === FACTORING_STATUS_INVOICE_SENT) {
    if (
      [COMPLETED_LOAD_STATUS_NAME, CANCELLED_LOAD_STATUS_NAME].includes(
        loadStatus
      )
    ) {
      return [
        SEND_TO_FACTORING,
        REFRESH_FACTORING_STATUS,
        SYNC_DOCUMENTS,
        SYNC_MODIFIED_INVOICE,
        DISCONNECT_FACTORING,
        SUBMIT_TO_SCHEDULE,
      ];
    }
    if (loadStatus === INVOICED_LOAD_STATUS_NAME) {
      if (amountDue === 0) {
        if (disablePanelActionsProviderIds.includes(factoringProviderId!)) {
          return [
            SEND_TO_FACTORING,
            SYNC_DOCUMENTS,
            SYNC_MODIFIED_INVOICE,
            SUBMIT_TO_SCHEDULE,
            REFRESH_FACTORING_STATUS,
          ];
        }
        return [
          SEND_TO_FACTORING,
          SYNC_DOCUMENTS,
          SYNC_MODIFIED_INVOICE,
          SUBMIT_TO_SCHEDULE,
        ];
      }
      if (
        disablePanelActionsForSyncProviderIds.includes(factoringProviderId!)
      ) {
        return [
          SEND_TO_FACTORING,
          SUBMIT_TO_SCHEDULE,
          REFRESH_FACTORING_STATUS,
          SYNC_DOCUMENTS,
        ];
      }
      if (factoringProviderId === TAFS_PROVIDER_ID) {
        return [
          SEND_TO_FACTORING,
          SUBMIT_TO_SCHEDULE,
          REFRESH_FACTORING_STATUS,
        ];
      }
      return [SEND_TO_FACTORING, SUBMIT_TO_SCHEDULE];
    }
    if (loadStatus === PAID_LOAD_STATUS_NAME) {
      return [
        SEND_TO_FACTORING,
        REFRESH_FACTORING_STATUS,
        SYNC_DOCUMENTS,
        SYNC_MODIFIED_INVOICE,
        SUBMIT_TO_SCHEDULE,
      ];
    }
  }
  //
  if (
    [
      FACTORING_STATUS_INVOICE_NOTREADYTOBILL,
      FACTORING_STATUS_INVOICE_READYTOBILL,
    ].includes(factoringStatus)
  ) {
    if (
      loadStatus === COMPLETED_LOAD_STATUS_NAME ||
      loadStatus === CANCELLED_LOAD_STATUS_NAME
    ) {
      if (
        disablePanelActionsForSyncProviderIds.includes(factoringProviderId!)
      ) {
        return [
          SEND_TO_FACTORING,
          SUBMIT_TO_SCHEDULE,
          REFRESH_FACTORING_STATUS,
          SYNC_DOCUMENTS,
        ];
      }
      if (factoringProviderId === TAFS_PROVIDER_ID) {
        return [
          SEND_TO_FACTORING,
          SUBMIT_TO_SCHEDULE,
          REFRESH_FACTORING_STATUS,
        ];
      }
      return [SEND_TO_FACTORING, SUBMIT_TO_SCHEDULE];
    }
    if (loadStatus === INVOICED_LOAD_STATUS_NAME) {
      if (amountDue === 0) {
        if (disablePanelActionsProviderIds.includes(factoringProviderId!)) {
          return [
            SEND_TO_FACTORING,
            SYNC_DOCUMENTS,
            SYNC_MODIFIED_INVOICE,
            SUBMIT_TO_SCHEDULE,
            REFRESH_FACTORING_STATUS,
          ];
        }
        return [
          SEND_TO_FACTORING,
          SYNC_DOCUMENTS,
          SYNC_MODIFIED_INVOICE,
          SUBMIT_TO_SCHEDULE,
        ];
      }
      if (
        disablePanelActionsForSyncProviderIds.includes(factoringProviderId!)
      ) {
        return [
          SEND_TO_FACTORING,
          SUBMIT_TO_SCHEDULE,
          REFRESH_FACTORING_STATUS,
          SYNC_DOCUMENTS,
        ];
      }
      return [SEND_TO_FACTORING, SUBMIT_TO_SCHEDULE];
    }
    if (loadStatus === PAID_LOAD_STATUS_NAME) {
      return [
        SEND_TO_FACTORING,
        REFRESH_FACTORING_STATUS,
        SYNC_DOCUMENTS,
        SYNC_MODIFIED_INVOICE,
        SUBMIT_TO_SCHEDULE,
      ];
    }
  }
  //
  if (
    [
      FACTORING_STATUS_INVOICE_SUBMITTED,
      FACTORING_STATUS_INVOICE_LOGGED,
      FACTORING_STATUS_INVOICE_AUDIT,
      FACTORING_STATUS_INVOICE_ISSUES,
      FACTORING_STATUS_INVOICE_APPROVAL,
      FACTORING_STATUS_INVOICE_FUNDED_ZEROADVANCE,
      FACTORING_STATUS_INVOICE_FUNDED_NOTPAID,
      FACTORING_STATUS_INVOICE_INVOICE_FUNDED_PAID,
    ].includes(factoringStatus)
  ) {
    if (
      loadStatus === COMPLETED_LOAD_STATUS_NAME ||
      loadStatus === CANCELLED_LOAD_STATUS_NAME
    ) {
      return [
        SEND_TO_FACTORING,
        REFRESH_FACTORING_STATUS,
        SYNC_DOCUMENTS,
        SYNC_MODIFIED_INVOICE,
        DISCONNECT_FACTORING,
        SUBMIT_TO_SCHEDULE,
      ];
    }
    if (loadStatus === INVOICED_LOAD_STATUS_NAME) {
      if (disablePanelActionsProviderIds.includes(factoringProviderId!)) {
        return [
          SEND_TO_FACTORING,
          SYNC_DOCUMENTS,
          SYNC_MODIFIED_INVOICE,
          SUBMIT_TO_SCHEDULE,
          REFRESH_FACTORING_STATUS,
        ];
      }
      return [
        SEND_TO_FACTORING,
        SYNC_DOCUMENTS,
        SYNC_MODIFIED_INVOICE,
        SUBMIT_TO_SCHEDULE,
      ];
    }
    if (loadStatus === PAID_LOAD_STATUS_NAME) {
      return [
        SEND_TO_FACTORING,
        REFRESH_FACTORING_STATUS,
        SYNC_DOCUMENTS,
        SYNC_MODIFIED_INVOICE,
        SUBMIT_TO_SCHEDULE,
      ];
    }
  }
  return [];
};

export const handleBulkSend = (
  selectedInvoices: InvoiceModel[],
  factoringCompanies: string[],
  countOfValidInvoices: number,
  openSendModalCb: () => void,
  triggerAlert: (
    alertName: string,
    message: string,
    subMessage?: string
  ) => void
) => {
  const rtsOrTriumphFactoringInvoices = selectedInvoices?.filter(
    (invoice) =>
      invoice?.factoringProviderId === RTS_PROVIDER_ID ||
      invoice?.factoringProviderId === TRIUMPH_PROVIDER_ID
  );
  if (new Set(factoringCompanies).size > 1) {
    triggerAlert(
      'sendMultipleCompanies',
      'You can not send Invoices to different factoring accounts at once. Please select rows of the same factoring company'
    );
  }
  // if factoring provider is RTS, there is no max limit
  else if (rtsOrTriumphFactoringInvoices?.length) {
    openSendModalCb();
    return;
  } else if (selectedInvoices.length > 25) {
    triggerAlert(
      'sendSelectedInvoiceCount',
      'You can not send more than 25 invoices at once. Please select less number of rows.'
    );
  } else if (countOfValidInvoices !== selectedInvoices.length) {
    triggerAlert(
      'sendInvalidInvoicesCount',
      `${countOfValidInvoices} out of selected ${selectedInvoices.length}  invoices are subject for sending to factoring. Are you sure you want to send those invoices to Factoring ?`
    );
  } else if (countOfValidInvoices === selectedInvoices.length) {
    triggerAlert(
      'sendValidInvoicesCount',
      'Are you sure you want to send those invoices to Factoring ?'
    );
  } else {
    openSendModalCb();
  }
};

export const handleBulkRefresh = (
  selectedInvoices: InvoiceModel[],
  factoringCompanies: string[],
  countOfValidInvoices: number,
  openRefreshModalCb: () => void,
  triggerAlert: (
    alertName: string,
    message: string,
    subMessage?: string
  ) => void
) => {
  if (new Set(factoringCompanies).size > 1) {
    triggerAlert(
      'refreshMultipleCompanies',
      'You cannot refresh the invoice statuses from different factoring accounts at once. Please select rows of the same factoring company.'
    );
  } else if (selectedInvoices.length > 25) {
    triggerAlert(
      'refreshSelectedInvoiceCount',
      'You can not refresh the statuses of more than 25 invoices at once. Please select less number of rows.'
    );
  } else if (countOfValidInvoices !== selectedInvoices.length) {
    triggerAlert(
      'refreshInvalidInvoicesCount',
      `${countOfValidInvoices} out of selected ${selectedInvoices.length}  invoices are subject for status refresh action. Are you sure you want to refresh the invoice statuses from factoring?`
    );
  } else if (countOfValidInvoices === selectedInvoices.length) {
    triggerAlert(
      'refreshValidInvoicesCount',
      'Are you sure you want to refresh the invoice statuses from factoring?'
    );
  } else {
    openRefreshModalCb();
  }
};

export const handleBulkSubmit = (
  selectedInvoices: InvoiceModel[],
  factoringCompanies: string[],
  countOfValidInvoices: number,
  openSubmitModalCb: () => void,
  triggerAlert: (
    alertName: string,
    message: string,
    subMessage?: string
  ) => void
) => {
  if (new Set(factoringCompanies).size > 1) {
    triggerAlert(
      'submitMultipleCompanies',
      'You cannot submit the invoices to different factoring accounts at once. Please select rows of the same factoring company.'
    );
  } else if (selectedInvoices.length > 25) {
    triggerAlert(
      'submitSelectedInvoiceCount',
      'You cannot submit more than 25 invoices at once. Please select less number of rows.'
    );
  } else if (countOfValidInvoices !== selectedInvoices.length) {
    triggerAlert(
      'submitInvalidInvoicesCount',
      `${countOfValidInvoices} out of selected ${selectedInvoices.length}  invoices are subject for submission for funding. Are you sure you want to submit the invoices for funding by factoring?`
    );
  } else if (countOfValidInvoices === selectedInvoices.length) {
    triggerAlert(
      'submitValidInvoicesCount',
      'Are you sure you want to submit the invoice for funding by factoring?'
    );
  } else {
    openSubmitModalCb();
  }
};

export const getStatusBulkActionOptions = (
  handleStatusChange: (status: string) => void,
  selectedInvoices: InvoiceModel[]
): { [key: string]: { label: string; onChildclick: () => void }[] } => {
  const userStorage = StorageManager.getUser();

  const {
    hasPaymentCompleteReadyInvoice,
    hasPaymentReadyInvoiceToInvoice,
    hasPaymentReadyToInvoiceComplete,
    hasPaymentInvoiceToReadyInvoice,
  } = useLoadPermission();
  let isMasterInvoice = true;
  let isOnHold = true;
  selectedInvoices.map((invoice) => {
    if (
      invoice.status === 'INVOICED' &&
      (invoice.invoiceType === 'MASTER' || invoice.invoiceType === 'CUSTOM')
    ) {
      isMasterInvoice = false;
    }
    if (invoice.onHold) {
      isOnHold = false;
    }
  });
  return {
    INVOICED: [
      ...(hasPaymentInvoiceToReadyInvoice && isMasterInvoice && isOnHold
        ? [
            {
              label: 'Revert to Completed',
              onChildclick: () => handleStatusChange(REVERT_TO_COMPLETED),
            },
          ]
        : []),

      ...(hasPaymentInvoiceToReadyInvoice && isMasterInvoice && isOnHold
        ? [
            {
              label: 'Revert to Ready to Invoice',
              onChildclick: () => handleStatusChange(REVERT_TO_INVOICED),
            },
          ]
        : []),
      ...(isOnHold
        ? [
            {
              label: 'Mark as Paid',
              onChildclick: () => handleStatusChange(PAID),
            },
          ]
        : []),
    ],
    PAID: [
      ...(isOnHold
        ? [
            {
              label: 'Revert to Invoiced',
              onChildclick: () => handleStatusChange(REVERT_TO_INVOICED),
            },
          ]
        : []),
    ],
    READY_TO_INVOICE: [
      ...(hasPaymentReadyToInvoiceComplete && isOnHold
        ? [
            {
              label: 'Revert to Completed',
              onChildclick: () => handleStatusChange(REVERT_TO_COMPLETED),
            },
          ]
        : []),
      ...(hasPaymentReadyInvoiceToInvoice && isOnHold
        ? [
            {
              label: 'Mark as Invoiced',
              onChildclick: () => handleStatusChange(INVOICED),
            },
          ]
        : []),
    ],
    LOAD_COMPLETED: [
      ...(isOnHold && userStorage.roleCode != 'DI'
        ? [
            {
              label: 'Mark as Invoiced',
              onChildclick: () => handleStatusChange(INVOICED),
            },
          ]
        : []),
      ...(hasPaymentCompleteReadyInvoice && isOnHold
        ? [
            {
              label: 'Mark as Ready to Invoice',
              onChildclick: () => handleStatusChange(REVERT_TO_INVOICED),
            },
          ]
        : []),
    ],
    CANCELLED: [
      ...(isOnHold
        ? [
            {
              label: 'Mark as Invoiced',

              onChildclick: () => handleStatusChange(INVOICED),
            },
          ]
        : []),
      ...(hasPaymentCompleteReadyInvoice && isOnHold
        ? [
            {
              label: 'Mark as Ready to Invoice',
              onChildclick: () => handleStatusChange(REVERT_TO_INVOICED),
            },
          ]
        : []),
    ],
  };
};

export const getFactoringOptions = (
  selectedInvoices: InvoiceModel[],
  openSendModalCb: () => void,
  openRefreshModalCb: () => void,
  openSubmitModalCb: () => void,
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void
) => {
  const options = [];
  let showSend = false;
  let showRefresh = false;
  let showSubmit = false;
  const factoringCompanies: string[] = [];
  let countOfValidInvoices = 0;
  let countOfValidInvoicesForRefresh = 0;
  let countOfValidInvoicesForSubmit = 0;
  const invalidInvoicesForSend: InvoiceModel[] = [];
  const invalidInvoicesForRefresh: InvoiceModel[] = [];
  const invalidInvoicesForSubmit: InvoiceModel[] = [];
  for (const invoice of selectedInvoices) {
    if (
      invoice.factoringCompany &&
      invoice.loadStatus !== PAID &&
      !invoice.factoringStatus &&
      invoice.customerMapped &&
      !invoice.isFactoringInvalid &&
      disableBulkActionsForSendProviderIds.includes(
        invoice.factoringProviderId!
      )
    ) {
      showSend = true;
      countOfValidInvoices++;
      if (invoice.factoringCompany) {
        factoringCompanies.push(invoice.factoringCompany);
      }
    } else {
      invalidInvoicesForSend.push(invoice);
    }
    if (
      invoice.factoringStatus &&
      invoice.factoringCompany &&
      invoice.loadStatus !== PAID &&
      invoice.customerMapped &&
      !invoice.isFactoringInvalid &&
      disableBulkActionsProviderIds.includes(invoice.factoringProviderId!)
    ) {
      showRefresh = true;
      countOfValidInvoicesForRefresh++;
      if (invoice.factoringCompany) {
        factoringCompanies.push(invoice.factoringCompany);
      }
    } else {
      invalidInvoicesForRefresh.push(invoice);
    }
    if (
      !invoice.isFactoringInvalid &&
      (invoice.factoringStatus === FACTORING_STATUS_INVOICE_READYTOBILL ||
        invoice.factoringStatus === FACTORING_STATUS_INVOICE_NOTREADYTOBILL) &&
      invoice.factoringProviderId! === APEX_PROVIDER_ID
    ) {
      showSubmit = true;
      countOfValidInvoicesForSubmit++;
    } else {
      invalidInvoicesForSubmit.push(invoice);
    }
  }
  if (showSend) {
    options.push({
      label: SEND_TO_FACTORING,
      onChildclick: () =>
        handleBulkSend(
          selectedInvoices,
          factoringCompanies,
          countOfValidInvoices,
          openSendModalCb,
          triggerAlert
        ),
    });
  }
  if (showRefresh) {
    options.push({
      label: REFRESH_FACTORING_STATUS,
      onChildclick: () =>
        handleBulkRefresh(
          selectedInvoices,
          factoringCompanies,
          countOfValidInvoicesForRefresh,
          openRefreshModalCb,
          triggerAlert
        ),
    });
  }
  if (showSubmit) {
    options.push({
      label: SUBMIT_TO_SCHEDULE,
      onChildclick: () =>
        handleBulkSubmit(
          selectedInvoices,
          factoringCompanies,
          countOfValidInvoicesForSubmit,
          openSubmitModalCb,
          triggerAlert
        ),
    });
  }
  return {
    factoringOptions: options,
    invalidInvoicesForSend,
    invalidInvoicesForRefresh,
    invalidInvoicesForSubmit,
  };
};

export const generateSendActionResponse = (
  response: FactoringActionReturnType,
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void
) => {
  const { factoringErrorDTO, factoringDataDTO } = response;
  if (!factoringErrorDTO.length) {
    triggerAlert?.(
      'sendFactoringResponse',
      'All invoices are accepted by the factoring company. The documents sending is in progress for the accepted invoices. You will be notified separately on the completion of sending process.'
    );
    return;
  }
  if (!factoringDataDTO.length) {
    triggerAlert?.(
      'sendFactoringResponse',
      'None of the selected invoices are accepted by the factoring company.',
      getResponseSubMessage(factoringErrorDTO)
    );
    return;
  }
  if (factoringErrorDTO.length && factoringDataDTO.length) {
    const errorInvoiceIds = factoringErrorDTO
      .map((data) => data.invoiceNumber)
      .join(',');
    triggerAlert?.(
      'sendFactoringResponse',
      `${factoringDataDTO.length} out of ${
        factoringErrorDTO.length + factoringDataDTO.length
      } invoices are accepted by the factoring company. The documents sending is in progress for the accepted invoices. You will be notified separately on the completion of sending process. Rejected Invoices (${errorInvoiceIds})`,
      getResponseSubMessage(factoringErrorDTO)
    );
    return;
  }
};

export const generateRefreshActionResponse = (
  response: FactoringActionReturnType,
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void
) => {
  const { factoringErrorDTO, factoringDataDTO } = response;
  if (!factoringErrorDTO.length) {
    triggerAlert(
      'refreshFactoringResponse',
      `The statuses of ${factoringDataDTO.length} invoices have been refreshed from the factoring company.`
    );
    return;
  }
  if (!factoringDataDTO.length) {
    triggerAlert(
      'refreshFactoringResponse',
      'None of the selected invoices are refreshed from the factoring company.',
      getResponseSubMessage(factoringErrorDTO)
    );
    return;
  }
  if (factoringErrorDTO.length && factoringDataDTO.length) {
    const errorInvoiceIds = factoringErrorDTO
      .map((data) => data.invoiceNumber)
      .join(',');
    triggerAlert(
      'refreshFactoringResponse',
      `The statuses of ${factoringDataDTO.length} out of ${
        factoringDataDTO.length + factoringErrorDTO.length
      } invoices have been refreshed from the factoring company. Rejected Invoices (${errorInvoiceIds})`,
      getResponseSubMessage(factoringErrorDTO)
    );
    return;
  }
};

export const generateDisconnectActionResponse = (
  response: FactoringActionReturnType
) => {
  const { factoringErrorDTO, factoringDataDTO } = response;
  if (!factoringErrorDTO.length) {
    return 'The selected invoices have been disconnected from the factoring company.';
  }
  if (!factoringDataDTO.length) {
    return 'None of the selected invoices are disconnected from the factoring company.';
  }
};

export const generateSubmitActionResponse = (
  response: FactoringActionReturnType,
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void
) => {
  const { factoringErrorDTO, factoringDataDTO } = response;
  if (!factoringErrorDTO.length) {
    triggerAlert(
      'submitFactoringResponse',
      `The selected ${factoringDataDTO.length} invoices have been submitted for funding by the factoring company.`
    );
    return;
  }
  // if (!factoringDataDTO.length) {
  //   triggerAlert(
  //     'submitFactoringResponse',
  //     'None of the selected invoices are submitted by the factoring company. Reasons of not being accepted....'
  //   );
  //   return;
  // }
  if (!factoringDataDTO.length) {
    triggerAlert(
      'submitFactoringResponse',
      'The submission for funding is not accepted by the factoring company.',
      getResponseSubMessage(factoringErrorDTO)
    );
    return;
  }
};

export const getUpdatedFields = (result: InvoiceModel, data: InvoiceModel) => {
  return {
    lineItems: result.lineItems,
    receivedPayments: result.receivedPayments || [],
    customer: data.customer,
    customerId: result.customerId,
    customerContactId: result.customerContactId,
    invoiceTerm: result.invoiceTerm,
    invoiceTermValue: result.invoiceTermValue,
    invoicedOn: result.invoicedOn,
    paidOn: result.paidOn,
    loadedMiles: result.loadedMiles,
    emptyMiles: result.emptyMiles,
    notes: result.notes,
    completeDate: result.completeDate,
    factoringCompany: data.factoringCompany,
    factoringChargePercent: result.factoringChargePercent,
    factoringCharge: result.factoringCharge,
    factoringNotes: result.factoringNotes,
    factoringInvoiceId: result.factoringInvoiceId,
    factoringStatus: result.factoringStatus,
    factoringDocumentStatus: result.factoringDocumentStatus,
    factoringInvoiceUpdateDate: result.factoringInvoiceUpdateDate,
    factoringScheduleId: result.factoringScheduleId,
    factoringId: result.factoringId,
    useFactoring: result.useFactoring,
    factoringProviderId: result.factoringProviderId,
    sourceFactoringNotes: result.sourceFactoringNotes,
    isFactoringInvalid: result.isFactoringInvalid,
    customerMapped: result.customerMapped,
    total: result.total,
    amountDue: result.amountDue,
  };
};

export const getDefaultSortingOrderByStripId = (stripId: string) => {
  switch (stripId) {
    case 'completed':
    case 'ready':
      return '+completeDate';
    case 'invoiced':
      return DEFAULT_INVOICE_SORT_NAME;
    case 'paid':
      return '+paidOn';
  }
};

export const getCurrentInvoiceStatuses = (
  stripId: string,
  requestParams: ViewMetaData
) => {
  if (stripId === 'completed') {
    requestParams.loadStatusList = [
      { value: 'LOAD_COMPLETED' },
      { value: 'CANCELLED' },
    ];
  }
  if (stripId === 'ready') {
    requestParams.loadStatusList = [{ value: 'READY_TO_INVOICE' }];
  }
  if (stripId === 'invoiced') {
    requestParams.loadStatusList = [{ value: 'INVOICED' }];
  }
  if (stripId === 'paid') {
    requestParams.loadStatusList = [{ value: 'PAID' }];
  }
  return requestParams;
};

export const getValidInvoices = (
  allInvoices: InvoiceModel[],
  invalidInvoices: InvoiceModel[]
) => {
  const invalidInvoiceIds = invalidInvoices.map((invoice) => invoice.id);
  return allInvoices.filter(
    (invoice) => !invalidInvoiceIds.includes(invoice.id)
  );
};

export const getWarnings = (
  warnings: { name: string; time: number }[],
  showDetention: boolean
) => {
  if (!warnings) {
    return [];
  }
  return showDetention
    ? warnings
    : warnings.filter((warning) => warning.name !== 'Detention');
};

// making new object combining amounts by driver id and returns map with { driverId: sumAmount } format
export const getDriverIdAmountMap = (invoices: InvoiceModel[]) => {
  const driverIdAmountMap: Record<string, number> = {};
  invoices.forEach((invoice) => {
    const driver = invoice.groupDetails?.[0].drivers[0];
    if (driver) {
      if (driverIdAmountMap[driver.id]) {
        driverIdAmountMap[driver.id] += invoice.amountDue;
      } else {
        driverIdAmountMap[driver.id] = invoice.amountDue;
      }
    }
  });
  return driverIdAmountMap;
};

export const addAmountToDriverMapping = (
  driverMapping: Record<string, DriverMappingType>,
  driverIdAmountMap: Record<string, number>
) => {
  const result = { ...driverMapping };
  for (const [key, value] of Object.entries(result)) {
    if (driverIdAmountMap[key]) {
      value.amount = driverIdAmountMap[key];
    }
    result[key] = value;
  }
  return result;
};

export const addFullNameIfNecessaryToContact = (
  contacts: CustomerContactType[] | null
) => {
  if (!contacts) {
    return [];
  }
  return contacts.map((contact, index: number) => {
    if (!contact.fullName) {
      contact.fullName = `Contact ${index + 1}`;
    }
    return contact;
  });
};

export const getMappingIdAmountObject = (resultWithAmount: {
  [p: string]: DriverMappingType;
}) => {
  return Object.values(resultWithAmount).reduce((finalMappingObject, val) => {
    if (val.mappingId) {
      if (!finalMappingObject[val.mappingId]) {
        finalMappingObject[val.mappingId] = {
          amount: val.amount,
          mappingDescription: val.mappingDescription,
          mappingCode: val.mappingCode,
        };
      } else {
        finalMappingObject[val.mappingId]['amount'] += val.amount;
      }
    }
    return finalMappingObject;
  }, {} as Record<string, { amount: number; mappingDescription: string; mappingCode: string }>);
};

export const getInstructions = (mappingInfo: DriverMappingType[]) => {
  return mappingInfo
    .reduce((notes, value) => {
      if (value.driverInvoiceNotes) {
        notes.push(value.driverInvoiceNotes);
      }
      return notes;
    }, [] as string[])
    .join(', ');
};

export const fixUserDefinedItems = (data: LineItem[] = []) => {
  return data.map((lineItem) => {
    if (
      lineItem.type?.startsWith('USERDEFINED_DEDUCTION') ||
      lineItem.type?.startsWith('USERDEFINED_PAYMENT')
    ) {
      lineItem.type = `${lineItem.type}_${lineItem.itemId}`;
    }
    return lineItem;
  });
};

export const revertUserDefinedItemsTypes = (items?: LineItem[]) => {
  if (!items) return undefined;
  return items.map((lineItem) => {
    if (lineItem) {
      delete lineItem?.isContract;
      delete lineItem?.deduction;
    }
    if (lineItem.isRemove) {
      lineItem.type = 'USERDEFINED_PAYMENT';
      delete lineItem.isRemove;
    }
    return lineItem;
  });
};
export const revertLoadUserDefinedItemsTypes = (items?: LineItem[]) => {
  if (!items) return undefined;
  return items.map((lineItem) => {
    if (lineItem.type?.startsWith('USERDEFINED_PAYMENT')) {
      lineItem.type = 'USERDEFINED_PAYMENT';
    } else if (lineItem.type?.startsWith('USERDEFINED_DEDUCTION')) {
      lineItem.type = 'USERDEFINED_DEDUCTION';
    }
    return lineItem;
  });
};

export const getEmailOrganizationName = (
  terminalName: string | undefined,
  userOrganizationName: string,
  isTerminalEnabled: boolean
) => {
  return isTerminalEnabled
    ? terminalName || userOrganizationName
    : userOrganizationName;
};

export const preparPayloadInvoice = (data: any) => {
  const object = {
    organizationId: getOrganizationId(),
    invoiceReferenceId: data.invoiceReferenceId,
    lineItems: data.lineItems,
    receivedPayments: data.receivedPayments,
    invoiceType: data.childInvoices?.length > 0 ? 'MASTER' : 'CUSTOM',
    invoiceTerm: data.invoiceTerm,
    invoiceTermValue: data.invoiceTermValue,
    notes: data.notes,
    noteDetails: {
      userId: getUserId(),
      updateTime: getCurrentDateTime(),
    },
    terminalId: data.terminal.id,
    messageOnInvoice: data.messageOnInvoice,
    customerId: data.customerContactId,
    customerContactId: data.customerContactId,
    childInvoices: data.childInvoices,
    invoicedOn: getCurrentDateTime(),
  };
  return object;
};

export const notesMsg = (isValue: boolean, control: any, value: string) => {
  return (
    <Controller
      rules={{
        maxLength: 1024,
      }}
      name="messageOnInvoice"
      defaultValue={''}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          sx={{
            width: '100%',
            padding: '0px 0px 0px 7px',
            marginTop: '8px',
            fontSize: '1rem',
            '& label': {
              paddingLeft: '12px',
            },
            '.MuiInput-root': {
              '&::after': {
                borderBottom: '2px solid #989da7',
              },
            },
          }}
          inputProps={{
            sx: {
              fontSize: '1rem',
              // marginTop: '15px',
              '& label': {
                paddingLeft: '12px',
              },
            },
          }}
          id="standard-multiline-flexible"
          label="Message On Invoicing"
          multiline
          maxRows={4}
          variant="standard"
          name={'messageOnInvoice'}
          disabled={isValue}
          InputProps={{
            style: {
              fontSize: '1rem',
              // marginTop: '15px',
            },
          }}
        />
      )}
    />
  );
};

export const getCollectionOfNotes = (InvoiceList: InvoiceModel[]) => {
  let notesText = '';
  InvoiceList.forEach((element) => {
    Boolean(element.notes?.trim())
      ? (notesText = notesText + ` ${element.loadSeqNumber}) ${element.notes}`)
      : null;
  });
  return notesText;
};

export const MultiInvoiceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5 0.5H7.5C6.4 0.5 5.5 1.4 5.5 2.5V18.5C5.5 19.6 6.4 20.5 7.5 20.5H19.5C20.6 20.5 21.5 19.6 21.5 18.5V6.5L15.5 0.5ZM7.5 18.5V2.5H14.5V6.5H19.5V18.5H7.5ZM12.5 17.5H14.5V16.5H15.5C16.05 16.5 16.5 16.05 16.5 15.5V12.5C16.5 11.95 16.05 11.5 15.5 11.5H12.5V10.5H16.5V8.5H14.5V7.5H12.5V8.5H11.5C10.95 8.5 10.5 8.95 10.5 9.5V12.5C10.5 13.05 10.95 13.5 11.5 13.5H14.5V14.5H10.5V16.5H12.5V17.5Z"
        fill="#2B64CB"
      />
      <path
        d="M4.5 3.5H5.5V5.5H4.5V21.5H16.5V20H18.5V21.5C18.5 22.6 17.6 23.5 16.5 23.5H4.5C3.4 23.5 2.5 22.6 2.5 21.5V5.5C2.5 4.4 3.4 3.5 4.5 3.5Z"
        fill="#2B64CB"
      />
    </svg>
  );
};
