import {
  DeleteButtonIcon,
  EditOutlinedIcon,
  CopyOutlinedIcon,
} from '@/ui-kit/components/Assets';
import { useState } from 'react';
import { IHttpErrorType } from '@/types';
import {
  CreateUpdateTagFormType,
  TagItem,
} from '@/models/DTOs/ManageTypes/Requests';
import { ManageTypeActions } from '@/subPages/settings/ManageTypes/ManageTypeActions';
import { useManageTypes } from '@/contexts/ManageTypesContext';
import { tagEntities } from '@/subPages/settings/ManageTypes/constants';
import { ServiceError } from '@/api/interfaces';

export const useItemTag = (tag: TagItem) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [httpErrors, setHttpErrors] = useState<IHttpErrorType[] | null>(null);
  const {
    entity: { name: entityName },
    tags,
    handleUpdateTags,
  } = useManageTypes();

  const tagOptions = [
    {
      name: 'Edit',
      action: () => setShowEditDialog(true),
      startIcon: EditOutlinedIcon({}),
      customStyles: { padding: '8px 16px' },
    },
    {
      name: 'Duplicate',
      action: () => handleDuplicate(),
      startIcon: CopyOutlinedIcon({}),
      customStyles: { padding: '8px 16px' },
    },
    {
      name: 'Delete',
      action: () => setShowDeleteDialog(true),
      startIcon: DeleteButtonIcon({}),
      customStyles: { padding: '8px 16px' },
    },
  ];

  const hideDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleDuplicate = async () => {
    const result = await ManageTypeActions.duplicateTag(tag.id);
    if (result) {
      const updatedData = [...[result], ...tags];
      handleUpdateTags(updatedData);
      ManageTypeActions.tagsListMap[entityName].unshift(result);
    }
  };

  const handleTagDelete = async () => {
    setShowDeleteDialog(false);
    const response = await ManageTypeActions.deleteTag(tag.id);
    if (response) {
      const updatedTagList = tags.filter((tagItem) => tagItem.id !== tag.id);
      const updatedOriginalList = ManageTypeActions.tagsListMap[
        entityName
      ].filter((tagItem) => tagItem.id !== tag.id);
      handleUpdateTags(updatedTagList);
      ManageTypeActions.tagsListMap[entityName] = updatedOriginalList;
    }
  };

  const handleEditDialogClose = () => {
    setShowEditDialog(false);
    setHttpErrors(null);
  };

  const handleTagUpdate = async (data: CreateUpdateTagFormType) => {
    const result = await ManageTypeActions.updateTag(data, tag.id);
    if (result instanceof ServiceError) {
      if ((result.error as any).response.status === 417) {
        setHttpErrors([
          {
            fieldName: 'name',
            message: 'Tag Name is already used',
          },
        ]);
      }
      return;
    }
    setShowEditDialog(false);
    const updatedTagList = tags.map((tagItem) => {
      if (tagItem.id === tag.id) {
        return result;
      }
      return tagItem;
    }) as TagItem[];
    const updatedOriginalTagList = ManageTypeActions.tagsListMap[
      entityName
    ].map((tagItem) => {
      if (tagItem.id === tag.id) {
        return result;
      }
      return tagItem;
    }) as TagItem[];
    setHttpErrors(null);
    handleUpdateTags(updatedTagList);
    ManageTypeActions.tagsListMap[entityName] = updatedOriginalTagList;
  };

  const formDefaultValues = {
    name: tag.name,
    description: tag.description,
    color: tag.color,
    entityTypes: tag.entityTypes.map((entityType) => ({
      key: entityType,
      value: tagEntities[entityType],
    })),
  };

  return {
    tagOptions,
    showDeleteDialog,
    showEditDialog,
    httpErrors,
    formDefaultValues,
    hideDeleteDialog,
    handleTagDelete,
    handleEditDialogClose,
    handleTagUpdate,
  };
};
