import { isHasPermission } from '@/utils';
import { AXELE_PERMISSION_TYPE } from '@/common/Permission';
import EntityNotesActions from '@/common/EntityNotes/EntityNotesActions';
import { EntityNoteItem } from '@/models';
import { useState } from 'react';
import { docType, previewFile } from '@/utils/Doc';
import DocumentActions from '@/views/reports/documents/DocumentActions';
import { NoteEntityType } from '@/common/EntityNotes/utils/types';

export const useNoteItem = (
  getEntityNotes: () => void,
  note: EntityNoteItem,
  entityType: NoteEntityType
) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [file, setFile] = useState<docType | undefined>();

  const editDeletePermissions = (() => {
    switch (entityType) {
      case 'DRIVER':
        return AXELE_PERMISSION_TYPE.USER_PROFILE_EDIT;
      default:
        return AXELE_PERMISSION_TYPE.EQUIPMENT_EDIT;
    }
  })();

  const hasEditNotePermission = isHasPermission([editDeletePermissions]);
  const hasRemoveNotePermission = isHasPermission([editDeletePermissions]);

  const handleNoteDelete = async () => {
    const result = await EntityNotesActions.deleteEntityNote(note.id);
    if (result) {
      getEntityNotes();
    }
    setShowDeletePopup(false);
  };

  const handleShowDeletePopupChange = (show: boolean) => () =>
    setShowDeletePopup(show);

  const handleEditModeChange = (isEditMode: boolean) => () =>
    setEditMode(isEditMode);

  const handleShowActionsChange = (show: boolean) => () => setShowActions(show);

  const handleCancel = () => {
    setShowActions(false);
    setEditMode(false);
  };

  const handleFileView = (docId: string) => async () => {
    if (file) {
      previewFile(file);
    } else {
      const result = await DocumentActions.downloadDocuments([docId]);
      if (result) {
        setFile(result[0]);
        previewFile(result[0]);
      }
    }
  };

  return {
    hasEditNotePermission,
    hasRemoveNotePermission,
    handleNoteDelete,
    handleShowDeletePopupChange,
    showDeletePopup,
    handleEditModeChange,
    editMode,
    handleShowActionsChange,
    showActions,
    handleCancel,
    handleFileView,
    file,
  };
};
