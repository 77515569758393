import { Box, Skeleton, Stack, useTheme } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { paymentService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import DetailsPanel from '../../../common/DetailsPanel';
import { User, UserDetails } from '../../../models/DTOs/user/User';
import {
  WEB_USER_VALIDATION_SCHEMA,
  driverPaymentTermsTabValidationSchema,
  webUserPaymentTermsTabValidationSchema,
} from '../../webUsers/constants';
import { createUser, getUserById, updateUser, validateUser } from '../utils';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';
import UserGeneralForm from './UserGeneralForm';

import EntityDocumentsSection from '../../../views/reports/documents/sections/EntityDocumentsSection';
import { UserDetailsTabs } from '../../shared/constants';

import DriverDetailsForm from './DriverDetailsForm';

import StorageManager from '../../../StorageManager/StorageManager';
import { DirtyDetailsPanelManager } from '../../../common/DetailsPanel/utils';
import { UserCustomStatus } from '../../../common/Ui/StatusComponent/StatusComponent';
import { GetFinancialItemRequest } from '../../../models/DTOs/FinanceLoadService';
import {
  LoadRateDTO,
  PaymentTermsRequest,
} from '../../../models/DTOs/PaymentTerms/request';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { getHttpErrorsFromResponse } from '../../../utils';
import { GanttPreferences } from '../../../views/dispatch/components/Preferences/Preferences';
import { defaultDispatchPreferencesFormData } from '../../../views/dispatch/config';
import DispatchController from '../../../views/dispatch/utils/Controller';
import {
  convertPreferenceDriverToPreferencesData,
  driverPreferenceDataFormat,
} from '../../../views/dispatch/utils/dispatch';
import { GanttValidationSchema } from '../../../views/dispatch/utils/validationSchema';
import { IDispatchPreferencesFormData } from '../../../views/dispatch2/constants/types';
import { useSafetyAlert } from '../../../views/safety/alerts/context/SafetyAlertContext';
import { getStaticDataTractorDetails } from '../../tractors/components/TractorDetailsForm';
import PaymentTermTabs, {
  EPaymentTermTabs,
} from './PaymentTermForm/PaymentTermTabs';
import {
  getAllPaymentTypes,
  getAllPaymentTypesMap,
} from './PaymentTermForm/utils';
import SafetyAlerts from './SafetyAlerts';
import SafetyAlertActions from './SafetyAlerts/SafetyAlertActions';
import { UserActionsSection } from './userActionsSection';
import { EntityNotes } from '@/common/EntityNotes';

interface IProps {
  data: UserDetails;
  onClose: () => void;
  onUpdated?: (data: {
    currentId?: null | string;
    currentUser: User;
    removableId?: null | string;
  }) => void;
  onDeleted?: (data: string) => void;
  onCreated?: (data: User) => void;
  handleStatusUpdated?: (data: UserDetails | undefined) => void;
  entity?: string;
  autoExpanded?: boolean;
  isGlobal?: boolean;
  onUpdateWarningsCount?: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
  defaultTab?: UserDetailsTabs;
}

const stripContentsMap: { [key: string]: React.ElementType } = {
  [UserDetailsTabs.General]: UserGeneralForm,
  [UserDetailsTabs.DriverDetails]: DriverDetailsForm,
  [UserDetailsTabs.PaymentTerms]: PaymentTermTabs,
  [UserDetailsTabs.Documents]: EntityDocumentsSection,
  [UserDetailsTabs.SafetyAlerts]: SafetyAlerts,
  [UserDetailsTabs.Preferences]: GanttPreferences,
  [UserDetailsTabs.Notes]: EntityNotes,
};

const UserDetailsPanel = ({
  data,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  onUpdateWarningsCount,
  handleStatusUpdated,
  entity = 'User',
  autoExpanded = false,
  defaultTab,
  ...restProps
}: IProps): JSX.Element | null => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    ...data,
    preferencesData: defaultDispatchPreferencesFormData,
  });
  const [paymentTabUserType, setPaymentTabUserType] =
    useState<EPaymentTermTabs>(EPaymentTermTabs.driver);
  const [selectedTabStrip, setSelectedTabStrip] = useState(
    UserDetailsTabs.General
  );
  const CurrentContent = stripContentsMap[selectedTabStrip] || UserGeneralForm;
  const [httpErrors, setHttpErrors] = useState<object>();
  const [showDetailsTab, setShowDetailsTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getIsTerminalEnabled } = useRootStore();
  const controller = DispatchController.instance();
  const userRolesList: any = StorageManager.getItem('rolesList');

  const getUserDetailsById = async (id: string) => {
    setLoading(true);
    const userDetails = await getUserById(id);
    setLoading(false);
    if (userDetails) {
      setUserDetails(userDetails);
    }
  };

  const getUserDetails = async () => {
    getUserDetailsById(data.id);
  };

  useEffect(() => {
    if (data.id) {
      setSelectedTabStrip(UserDetailsTabs.General);
      getUserDetails();
    }
  }, [data.id, data.status]);

  useEffect(() => {
    setPaymentTabUserType(
      data.driveStatus === 0
        ? EPaymentTermTabs.webUser
        : EPaymentTermTabs.driver
    );
  }, [data.driveStatus]);

  const handleUserUpdate = async (updatedUser: UserDetails) => {
    const validationResponse = await validateUser(
      updatedUser.id,
      updatedUser.email,
      updatedUser.roleCode,
      updatedUser.driverRefId
    );
    if (validationResponse instanceof ServiceError) {
      setHttpErrors(getHttpErrorsFromResponse(validationResponse));
    } else {
      updatedUser.userType = userRolesList[updatedUser.roleCode];
      const updatedUserResponse = await updateUser(updatedUser);

      if (updatedUserResponse) {
        onUpdated &&
          onUpdated({
            currentUser: new User(updatedUserResponse.currentUser),
            currentId: updatedUserResponse.currentId,
          });
        setUserDetails(new UserDetails(updatedUserResponse.currentUser));
      }
    }
  };

  const handlePaymentTermsUpdate = async (updatedUser: UserDetails) => {
    if (updatedUser.paymentTerms === null) {
      return;
    }

    const requestData = new GetFinancialItemRequest(true);
    const allPaymentTypes: PaymentType[] = await getAllPaymentTypes(
      requestData
    );
    const allPaymentTypesMap = getAllPaymentTypesMap(allPaymentTypes);

    const requestPaymentData = new PaymentTermsRequest(
      updatedUser.paymentTerms,
      allPaymentTypesMap
    );

    const updatedPaymentTermsResponse =
      paymentTabUserType === EPaymentTermTabs.driver
        ? await paymentService.bulkUpdateForDriverPayment(requestPaymentData)
        : await paymentService.bulkUpdateForWebuserPayment(requestPaymentData);

    if (updatedPaymentTermsResponse instanceof ServiceError) {
      // TODO: error on update, should be decided what should to do
      return;
    }
    const updatedUserData = { ...updatedUser };
    if (
      updatedPaymentTermsResponse &&
      updatedPaymentTermsResponse?.loadRateDTO
    ) {
      updatedUserData.paymentTerms = updatedPaymentTermsResponse;
      updatedUserData.paymentTerms?.loadRateDTO?.forEach(
        (eachSavedPaymentTerm) => {
          const foundItem = updatedUser.paymentTerms?.loadRateDTO?.find(
            (itemToBeSaved) => itemToBeSaved.id === eachSavedPaymentTerm.id
          );

          if (foundItem) {
            eachSavedPaymentTerm.unit = foundItem.unit;
          }
        }
      );
      const newLoadRateDTO = updatedPaymentTermsResponse?.loadRateDTO?.map(
        (item) => new LoadRateDTO(item)
      );
      updatedUserData.paymentTerms.loadRateDTO = newLoadRateDTO;
    }
    setUserDetails(updatedUserData);
  };

  const handleSafetyAlertsUpdate = async (data: UserDetails) => {
    const { alertsList } = data;

    const result = await SafetyAlertActions.safetyAlertBulkUpdate(alertsList);
    if (result) {
      let warnings = 0;
      let criticals = 0;
      result?.map((alert) => {
        if (alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL') {
          criticals += 1;
        }
        if (alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING') {
          warnings += 1;
        }
      });
      if (typeof onUpdateWarningsCount === 'function') {
        onUpdateWarningsCount(data.id, warnings, criticals, false);
      }
      setUserDetails((userDetails) => ({ ...userDetails, alertsList: result }));
    }
  };

  const onMarkDoneCompleteCallback = (result: any) => {
    if (typeof onUpdateWarningsCount === 'function') {
      if (result.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING') {
        onUpdateWarningsCount(userDetails.id!, 1, 0, true);
      }
      if (result.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL') {
        onUpdateWarningsCount(userDetails.id!, 0, 1, true);
      }
    }
  };

  const { fetchMainData } = useSafetyAlert();

  const handleUpdate = async (
    userDetailsTab: UserDetailsTabs,
    updatedUser: UserDetails
  ) => {
    switch (userDetailsTab) {
      case UserDetailsTabs.General:
        handleUserUpdate(updatedUser);
        break;

      case UserDetailsTabs.DriverDetails:
        handleUserUpdate(updatedUser);
        break;

      case UserDetailsTabs.Documents:
        break;
      case UserDetailsTabs.SafetyAlerts:
        handleSafetyAlertsUpdate(updatedUser);
        fetchMainData?.();
        break;
      case UserDetailsTabs.PaymentTerms:
        handlePaymentTermsUpdate(updatedUser);
        break;
      case UserDetailsTabs.Preferences:
        const updatedData = driverPreferenceDataFormat(updatedUser);
        updatePreferenceDriver(updatedData as IDispatchPreferencesFormData);
        break;
      default:
        handleUserUpdate(updatedUser);
        break;
    }
  };

  const handleCreate = async (userData: UserDetails) => {
    const validationResponse = await validateUser(
      null,
      userData.email,
      userData.roleCode,
      userData.driverRefId
    );
    if (validationResponse instanceof ServiceError) {
      setHttpErrors(getHttpErrorsFromResponse(validationResponse));
    } else {
      userData.userType = userRolesList[userData.roleCode];
      const newUser = await createUser(userData);
      if (newUser) {
        getUserDetailsById(newUser.id);
        onCreated?.(new User(newUser));
        // The user create API does not return associatedTerminalsList. It's always return empty list
        // setUserDetails(new UserDetails(newUser));
      }
    }
  };

  const handleTabStripChange = (selected: string): void => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    if (selected === UserDetailsTabs.DriverDetails) {
      getUserDetails();
    }
    if (selected === UserDetailsTabs.Preferences) {
      getPreferenceDriver();
    }
    setSelectedTabStrip(selected as UserDetailsTabs);
  };

  React.useEffect(() => {
    getStaticDataTractorDetails();
  }, []);

  useEffect(() => {
    if (userDetails.isDriver && !!userDetails.id && showDetailsTab) {
      setSelectedTabStrip(defaultTab || UserDetailsTabs.General);
    }
  }, [userDetails.isDriver, userDetails.id, showDetailsTab]);

  const tabStripRenderer = (keys: UserDetailsTabs[]) => {
    return (
      <DetailsTabStrips
        keys={keys}
        handleStripChange={handleTabStripChange}
        //TODO: hotfix when selectedTabStrip is not include in fullscreen mode tabs
        selected={selectedTabStrip ? selectedTabStrip : keys[0]}
        showTabStrips={!!userDetails.id}
        showDriverDetailsStrip={
          userDetails.isDriver && !!userDetails.id && showDetailsTab
        }
        showDocumentsStrip={userDetails.driveStatus !== 0}
        showNotes={userDetails.isDriver}
        entity={entity}
      />
    );
  };

  const subHeaderRenderer = () => {
    // return <DetailsSubHeader userId={userDetails.id} />;
    return null;
  };

  const handleChangeShowDetailsTab = (showTabState: boolean) =>
    setShowDetailsTab(showTabState);

  const theme: any = useTheme();
  const panelWrapperCustomStyles = {
    display: 'flex',
    width: '33.3%',
    padding: '0 5px 5px 0',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette.leftMenuCollapsed.canvas,
    boxShadow: 'unset',
  };

  const isEdit = !!data.id;
  const onFullscreen = (isFullscreen: boolean): void => {
    //set default tab when fullscreen
    setSelectedTabStrip(
      isFullscreen && isEdit
        ? UserDetailsTabs.PaymentTerms
        : UserDetailsTabs.General
    );
  };

  const paymentTermTabConfig = {
    ...(selectedTabStrip === UserDetailsTabs.PaymentTerms && {
      paymentTabUserType: paymentTabUserType,
      onChangeUserType: (selectedTab: EPaymentTermTabs) => {
        setPaymentTabUserType(selectedTab);
      },
    }),
  };

  const getPreferenceDriver = async (): Promise<void> => {
    const driverId = userDetails.id;
    if (!driverId && userDetails.isDriver) {
      return;
    }
    const preferenceDriver = await controller.getPreferenceDriver({
      driverId,
    });

    if (preferenceDriver) {
      const _preferencesData =
        convertPreferenceDriverToPreferencesData(preferenceDriver);
      setUserDetails({ ...userDetails, preferencesData: _preferencesData });
    } else {
      setUserDetails({
        ...userDetails,
        preferencesData: defaultDispatchPreferencesFormData,
      });
    }
  };
  const updatePreferenceDriver = async (updateData: any): Promise<void> => {
    let preferenceDriver;
    if (updateData && !updateData?.driverId) {
      updateData.driverId = userDetails.id;
      preferenceDriver = await controller.addPreferenceDriver({
        ...updateData,
      });
    } else {
      preferenceDriver = await controller.updatePreferenceDriver({
        ...updateData,
      });
    }
    if (preferenceDriver) {
      const preferenceData = convertPreferenceDriverToPreferencesData(
        preferenceDriver
      ) as IDispatchPreferencesFormData;
      setUserDetails({ ...userDetails, preferencesData: preferenceData });
    }
  };

  const getValidationSchemaByTab = useCallback(
    (tab: UserDetailsTabs | string): yup.AnyObjectSchema | null => {
      if (tab === UserDetailsTabs.PaymentTerms) {
        if (paymentTabUserType === EPaymentTermTabs.driver) {
          return driverPaymentTermsTabValidationSchema;
        } else if (paymentTabUserType === EPaymentTermTabs.webUser) {
          return webUserPaymentTermsTabValidationSchema;
        } else {
          return null;
        }
      } else if (tab == UserDetailsTabs.Preferences) {
        return GanttValidationSchema;
      } else {
        const tabName =
          getIsTerminalEnabled && tab === 'GENERAL'
            ? 'GENERAL_WITH_TERMINAL'
            : tab;

        return WEB_USER_VALIDATION_SCHEMA[tabName];
      }
    },
    [paymentTabUserType, getIsTerminalEnabled]
  );
  const renderCustomUsetTitle = (userDetails: UserDetails) => {
    if (
      typeof userDetails.status !== 'undefined' &&
      userDetails.status !== null
    ) {
      return (
        <Box ml={1}>
          <UserCustomStatus status={userDetails.status} />
        </Box>
      );
    }
  };

  const onStatusUpdated = (updatedUserResponse: UserDetails | undefined) => {
    if (!updatedUserResponse?.id) {
      return;
    }
    setUserDetails((prevValues) => {
      return { ...prevValues, status: updatedUserResponse!.status };
    });
    handleStatusUpdated?.(updatedUserResponse);
  };

  const userActionsRenderer = () =>
    typeof userDetails.status !== 'undefined' &&
    userDetails.status !== null && (
      <UserActionsSection
        status={userDetails.status}
        userDetails={userDetails}
        handleStatusUpdated={onStatusUpdated}
        handleDeleted={onDeleted}
      />
    );

  if (Object.keys(userDetails).length === 1) return null;

  const noPermissionForInactiveStatus = userDetails.status === 0;

  const panelTitle = userDetails.id
    ? `${userDetails?.firstName || ''} ${userDetails?.lastName || ''}`
    : `Add ${userDetails.isDriver ? 'Driver' : 'Web User'}`;

  const renderCustomTitle = () => renderCustomUsetTitle(userDetails);

  return (
    <>
      <DetailsPanel
        data={userDetails}
        entity={entity}
        renderCustomTitle={renderCustomTitle}
        autoExpanded={autoExpanded}
        actionButtonLabel={userDetails.id ? undefined : `Add ${entity}`}
        panelTitle={panelTitle}
        actionsRenderer={userActionsRenderer}
        showThreeDotMenuIcon={false}
        onClose={onClose}
        onUpdate={(userData) => handleUpdate(selectedTabStrip, userData)}
        onCreate={handleCreate}
        contentRenderer={() => {
          if (loading) {
            return (
              <Stack
                direction="column"
                sx={{ height: '100%', width: '100%' }}
                spacing={2}
              >
                {[...new Array(18)].map(() => (
                  <Skeleton height="2rem" width="100%" variant="rectangle" />
                ))}
              </Stack>
            );
          }
          return (
            <CurrentContent
              data={{
                ...userDetails,
                entity: 'DRIVER',
                entityObject: {
                  id: userDetails.id,
                  name: `${userDetails.firstName} ${userDetails.lastName}`,
                },
              }}
              entityId={userDetails.id}
              entityType="DRIVER"
              onMarkDoneCompleteCallback={onMarkDoneCompleteCallback}
              handleChangeShowDetailsTab={handleChangeShowDetailsTab}
              setUserDetails={setUserDetails}
              inactive={noPermissionForInactiveStatus}
              {...paymentTermTabConfig}
              createAlertCallback={onUpdateWarningsCount}
            />
          );
        }}
        tabStripRenderer={() =>
          tabStripRenderer(Object.values(UserDetailsTabs))
        }
        validationSchema={getValidationSchemaByTab(selectedTabStrip)}
        httpErrors={httpErrors}
        {...(selectedTabStrip !== UserDetailsTabs.Documents &&
          selectedTabStrip !== UserDetailsTabs.SafetyAlerts && {
            subHeaderRenderer,
          })}
        onFullscreen={onFullscreen}
        {...(isEdit && {
          fullscreen: {
            panelPropList: [
              {
                data: userDetails,
                entity: entity,
                panelId: UserDetailsTabs.General,
                panelTitle,
                renderCustomTitle,
                validationSchema: getValidationSchemaByTab(
                  UserDetailsTabs.General
                ),
                onUpdate: (userData) =>
                  handleUpdate(UserDetailsTabs.General, userData),
                contentRenderer: () => (
                  <UserGeneralForm
                    data={{
                      ...userDetails,
                      tags: [],
                      entity: 'DRIVER',
                      entityId: userDetails.id,
                    }}
                    handleChangeShowDetailsTab={handleChangeShowDetailsTab}
                    setUserDetails={setUserDetails}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '18px 0 0 18px', //override border radius
                },
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(UserDetailsTabs.General),
                subHeaderRenderer,
              },
              {
                data: userDetails,
                entity: entity,
                panelId: UserDetailsTabs.DriverDetails,
                validationSchema: getValidationSchemaByTab(
                  UserDetailsTabs.DriverDetails
                ),
                onUpdate: (userData) =>
                  handleUpdate(UserDetailsTabs.DriverDetails, userData),
                contentRenderer: () => (
                  <DriverDetailsForm
                    data={{ ...userDetails, entity: 'DRIVER' }}
                    handleChangeShowDetailsTab={handleChangeShowDetailsTab}
                    setUserDetails={setUserDetails}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: 0, //override border radius
                },
                subHeaderRenderer,
                panelTitle: '',
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(UserDetailsTabs.DriverDetails),
              },
              {
                data: userDetails,
                entity: entity,
                panelTitle: ``,
                panelId: 'CurrentContentForm',
                validationSchema: getValidationSchemaByTab(selectedTabStrip),
                onUpdate: (userData) =>
                  handleUpdate(selectedTabStrip, userData),
                contentRenderer: () => {
                  return (
                    <CurrentContent
                      data={{ ...userDetails, entity: 'DRIVER' }}
                      handleChangeShowDetailsTab={handleChangeShowDetailsTab}
                      setUserDetails={setUserDetails}
                      {...paymentTermTabConfig}
                      onUpdateWarningsCount={onUpdateWarningsCount}
                      inactive={noPermissionForInactiveStatus}
                    />
                  );
                },
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '0 18px 18px 0', //override border radius
                },
                tabStripRenderer: () => {
                  return tabStripRenderer([
                    UserDetailsTabs.Documents,
                    UserDetailsTabs.SafetyAlerts,
                    UserDetailsTabs.PaymentTerms,
                    UserDetailsTabs.Preferences,
                  ]);
                },
                ...(selectedTabStrip !== UserDetailsTabs.Documents && {
                  subHeaderRenderer,
                }),
              },
            ],
          },
        })}
        {...restProps}
      />
    </>
  );
};

export default UserDetailsPanel;
