import { Box, SxProps, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import * as React from 'react';

const AntSwitch = styled(Switch)(({ theme }: { theme: any }) => ({
  width: 26.67,
  height: '13.33px',
  padding: 0,
  display: 'flex',
  top: 3,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '2px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 9,
    height: 9,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.text.disabled,
    boxSizing: 'border-box',
  },
}));

export interface IProps {
  id?: string;
  name?: string;
  label?: React.ReactNode;
  subLabel?: React.ReactNode;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  labelColor?: string;
  containerStyles?: SxProps;
}

const Switcher = ({
  id = '',
  label,
  name,
  subLabel,
  disabled = false,
  defaultChecked = false,
  checked,
  onChange,
  labelColor = 'text.primary',
  containerStyles,
}: IProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'row',
        ...containerStyles,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch
          id={id}
          name={name}
          disabled={disabled}
          checked={checked}
          defaultChecked={defaultChecked}
          inputProps={{ 'aria-label': 'ant design' }}
          onChange={onChange}
        />
      </Stack>
      {label ||
        (subLabel && (
          <Box
            sx={{
              paddingLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {label && (
              <Typography
                variant={'h7'}
                sx={{
                  color: labelColor,
                  paddingBottom: '5px',
                  ...(disabled && {
                    opacity: 0.6,
                  }),
                }}
              >
                {label}
              </Typography>
            )}
            {subLabel && (
              <Typography
                variant={'body3'}
                sx={{
                  color: 'text.secondary',
                  ...(disabled && {
                    opacity: 0.6,
                  }),
                }}
              >
                {subLabel}
              </Typography>
            )}
          </Box>
        ))}
    </Box>
  );
};

export default Switcher;
