import {
  useEffect,
  useState,
  FC,
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';
import useFilterStorage from '../services/storage';
import {
  documentType,
  ManageTypeEntityType,
  manageTypesMap,
  component,
} from '../subPages/settings/ManageTypes/constants';
import { ManageTypesItem, TagItem } from '../models/DTOs/ManageTypes/Requests';

export type ManageTypesContextType = {
  entity: ManageTypeEntityType;
  setEntity: (entity: ManageTypeEntityType) => void;
  manageTypesList: ManageTypesItem[];
  handleUpdateManageTypesList: (data: ManageTypesItem[]) => void;
  tags: TagItem[];
  handleUpdateTags: (data: TagItem[]) => void;
};

export const ManageTypesContext = createContext<ManageTypesContextType>(
  {} as ManageTypesContextType
);

export const ManageTypesContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};

  const [entity, setEntity] = useState<ManageTypeEntityType>(
    manageTypesMap[storageData.selectedTab] || documentType
  );
  const [manageTypesList, setManageTypesList] = useState<ManageTypesItem[]>([]);
  const [tags, setTags] = useState<TagItem[]>([]);

  const handleUpdateManageTypesList = (data: ManageTypesItem[]) => {
    setManageTypesList(data);
  };

  const handleUpdateTags = (data: TagItem[]) => {
    setTags(data);
  };

  return (
    <ManageTypesContext.Provider
      value={{
        entity,
        setEntity,
        manageTypesList,
        handleUpdateManageTypesList,
        tags,
        handleUpdateTags,
      }}
    >
      {children}
    </ManageTypesContext.Provider>
  );
};

export const useManageTypes = () => useContext(ManageTypesContext);
