import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  DriverTooltipContainerStyles,
  driverTitleStyles,
  tooltipFooterContainerStyles,
} from '../styles';
import { IDriverTooltip, TooltipFooterContentType } from '../types';
import {
  ActionTemplate,
  KpiSection,
  TooltipHOSSection,
  TooltipSubtitleTemplate,
  TooltipTripTemplate,
  WarningsTemplate,
} from '../atoms';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export const DriverTooltip: React.FC<IDriverTooltip> = (props) => {
  const {
    subTitles,
    hosDetails,
    trips,
    disableCloseAction = false,
    tags,
    ...restProps
  } = props;
  return (
    <Box
      id="driver-tooltip=container"
      sx={{
        ...DriverTooltipContainerStyles,
        ...restProps?.containerStyles,
      }}
    >
      {/* <Box sx={{ p: 2 }}> */}
      <Box display={'flex'} style={{ padding: '16px 16px 4px' }}>
        <Typography
          sx={{
            ...driverTitleStyles,
            ...restProps?.titleStyles,
          }}
        >
          {restProps.title}
        </Typography>

        {!disableCloseAction && (
          <Box ml="auto">
            <IconButton onClick={restProps.onClose} style={{ padding: 0 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* <Box
        sx={{
          ...DriverScrollableContainerStyles,
          ...restProps?.scrollableContainerStyles,
        }}
      > */}
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        spacing={2}
        sx={restProps?.subTitleContainerStyles}
        padding="4px 16px 16px"
      >
        <TooltipSubtitleTemplate
          subTitles={subTitles}
          onSubTitleClick={restProps?.onSubTitleClick}
          tags={tags}
        />
      </Stack>
      {/* </Box> */}
      <Box
        sx={{
          background: '#FFFFFF',
          borderRadius: '8px',
        }}
      >
        <TooltipTripTemplate {...trips} />

        {hosDetails?.element ? (
          hosDetails?.element
        ) : hosDetails ? (
          <TooltipHOSSection {...hosDetails} />
        ) : (
          <></>
        )}

        {restProps?.kpis?.content && (
          <KpiSection
            kpis={restProps.kpis}
            weekInformation={restProps?.weekInformation}
          />
        )}

        {/* {Boolean(restProps?.weekInformation?.length) && (
          <Box sx={{ padding: '0 4px 0 0' }}>
            {restProps?.weekInformation?.map((week: IWeekInformation) => (
              <Box
                width={'100%'}
                justifyContent={'center'}
                display={'flex'}
                key={week?.key}
              >
                <Typography sx={{ ...weekTextStyles, ...week?.keyStyles }}>
                  {week?.key}:
                </Typography>
                <Typography
                  sx={{
                    ...weekTextStyles,
                    paddingLeft: '3px',
                    ...week?.valueStyles,
                  }}
                >
                  {week?.value}
                </Typography>
              </Box>
            ))}
          </Box>
        )} */}

        <ActionTemplate
          // actionRenderer={restProps.footer}
          actions={restProps.actions}
          onActionClick={restProps.onActionClick}
          containerStyles={restProps?.actionContainerStyles}
        />
        {restProps.footer?.element ||
          ((restProps.footer?.content?.length as number) > 0 && (
            <Box
              id="footer-container"
              style={{
                ...tooltipFooterContainerStyles,
                ...(restProps?.footer?.containerStyles as any),
              }}
            >
              <WarningsTemplate
                {...restProps.footer}
                onWarningAction={(content: TooltipFooterContentType) => {
                  if (restProps?.closeTooltipOnAction) restProps?.onClose?.();
                  restProps?.onWarningAction?.(content);
                }}
              />
            </Box>
          ))}
      </Box>
      {/* </Box> */}
    </Box>
  );
};
