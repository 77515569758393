import { renderToStaticMarkup } from 'react-dom/server';
import {
  MultiLineColumn,
  SingleLineColumn,
  ColumnHeader,
  ColumnHeaderTooltipTemplate,
} from '../../../components/Gantt';

import {
  appendTextToDigit,
  formatedTextFromTwoValues,
  getMultiValueText,
  toFixDigit,
} from '../services';
import { TractorIcon, TrailerIcon } from '../../../static';
import { ganttLegend } from '../../../constants/gantt/gantt.const';
import { ScheduleDriverType } from '../../../types/DispatchTypes';
import { DRIVER_COLUMN_HEADER_TOOLTIP } from '../../../constants/gantt/columnTooltip.config';
import { DRIVER_COLUMN_MIN_WIDTH } from '../constants/gantt.const';
import { t } from 'i18next';
import { TagList } from '@/common/Tag/components/TagList';

export const DISPATCH_SETTINGS = {
  page: 'Dispatch',
  entity: 'grantt',
};

const getColumnHeaderTooltipConfig = (type: string) => {
  return {
    hideDelay: 50,
    hoverDelay: 300,
    hideOnDelegateChange: true,
    html: renderToStaticMarkup(
      <ColumnHeaderTooltipTemplate
        labels={DRIVER_COLUMN_HEADER_TOOLTIP[type]}
      />
    ),
  };
};

export const driverColumnFileds = {
  driver_name: 'driver_name',
  location: 'location',
  revenue: 'revenue',
  driver_pay: 'driver_pay',
  mileage: 'mileage',
  equipment: 'equipment',
  tags: 'tags',
};

export const driverColumnNameByField = {
  driver_name: 'Driver Name',
  location: 'Location',
  revenue: 'Revenue',
  driver_pay: 'Driver Pay',
  mileage: 'Miles',
  equipment: 'Equipment',
};

export const defaultResourceInfoColumnDetails = {
  id: 1,
  type: 'resourceInfo',
  field: driverColumnFileds.driver_name,
  width: 120,
  draggable: false,
  headerRenderer: ({
    headerElement,
  }: {
    headerElement: HTMLElement;
  }): string => {
    (headerElement?.childNodes?.[0] as HTMLElement)?.classList?.add(
      'b-header-column-box'
    );
    return renderToStaticMarkup(
      <ColumnHeader name={t('GanttColumnHeaderDriver')} />
    );
  },
  tooltip: getColumnHeaderTooltipConfig('DRIVER'),
};
export const defaultTripColumnDetails = {
  id: 'location',
  field: driverColumnFileds.location,
  width: 120,
  draggable: false,
  headerRenderer: ({
    headerElement,
  }: {
    headerElement: HTMLElement;
  }): string => {
    (headerElement?.childNodes?.[0] as HTMLElement)?.classList?.add(
      'b-header-column-box'
    );
    return renderToStaticMarkup(
      <ColumnHeader name={t('GanttColumnHeaderLocation')} />
    );
  },
  tooltip: getColumnHeaderTooltipConfig('LOCATION'),
};

const commonColumnTextStyles = { textAlign: 'left' };

export const resourceInfoColumnElProps = (data: ScheduleDriverType) => {
  return {
    ...data,
    containerStyles: { cursor: 'pointer' },
    isCheckBox: false,
    columnDetails: [
      {
        text: data?.driverGroupDetails?.groupName ?? '-',
        textStyles: {
          color: 'rgba(0, 17, 34, 0.75)',
          fontWeight: 400,
          letterSpacing: '0.15px',
        },
      },
    ],
    showColumnTooltipOnClick: true,
    resourceData: data,
  };
};

export const locationColumnElProps = (data: ScheduleDriverType) => {
  return {
    ...data,
    containerStyles: { cursor: 'pointer' },
    isCheckBox: false,
    warningIconIndexAt: 0,
    isWarningIcon:
      Object.keys(data?.warnings || {}).length == 1 &&
      data?.warnings?.DRIVER_HOS &&
      data?.ignoreHOSRestrictions
        ? false
        : data?.isLocationOutdated ||
          Boolean(Object.keys(data?.warnings || {}).length),
    columnDetails: [
      {
        text: formatedTextFromTwoValues(
          data.driverLocationDetails?.city,
          data.driverLocationDetails?.state,
          ',',
          'N/A'
        ),
        textStyles: {
          color: 'rgba(0, 17, 34, 0.75)',
          fontWeight: 400,
          letterSpacing: '0.15px',
          ...commonColumnTextStyles,
        },
      },
    ],
    showColumnTooltipOnClick: true,
    resourceData: data,
  };
};

// const { t, ready } = useTranslation();
export const defaultDriverDetailColumns = [
  {
    id: 6,
    draggable: false,
    width: 120,
    field: driverColumnFileds.tags,
    headerRenderer: ({
      headerElement,
    }: {
      headerElement: HTMLElement;
    }): string => {
      (headerElement?.childNodes?.[0] as HTMLElement)?.classList?.add(
        'b-header-column-box'
      );
      return renderToStaticMarkup(<ColumnHeader name="Tags" />);
    },
    renderer: (event: any) => {
      const data = event?.record?.data;
      return <TagList small tags={data.tags} />;
    },
  },
  {
    id: 2,
    draggable: false,
    width: 140,
    headerRenderer: ({
                       headerElement,
                     }: {
      headerElement: HTMLElement;
    }): string => {
      (headerElement?.childNodes?.[0] as HTMLElement)?.classList?.add(
        'b-header-column-box'
      );
      return renderToStaticMarkup(
        <ColumnHeader name={t('GanttColumnHeaderEquipment')} />
      );
    },
    field: driverColumnFileds.equipment,
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{ width: 'fit-content', display: 'flex' }}
          data={[
            {
              icon: <TractorIcon />,
              text: data?.driverGroupDetails?.tractorNumber || 'N/A',
              textStyles: commonColumnTextStyles,
            },
            {
              icon: <TrailerIcon />,
              text: data?.driverGroupDetails?.trailerNumber || 'N/A',
              textStyles: commonColumnTextStyles,
              containerStyles: { marginLeft: '6px' },
            },
          ]}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('EQUIPMENT'),
  },
  {
    id: 3,
    draggable: false,
    headerRenderer: ({
      headerElement,
    }: {
      headerElement: HTMLElement;
    }): string => {
      (headerElement?.childNodes?.[0] as HTMLElement)?.classList?.add(
        'b-header-column-box'
      );
      return renderToStaticMarkup(
        <ColumnHeader name={t('GanttColumnHeaderRevenue')} />
      );
    },
    field: driverColumnFileds.revenue,
    width: 120,
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          data={[
            {
              text: getMultiValueText(
                appendTextToDigit({
                  val: data?.currentRevenue,
                  prefixLabel: '$',
                }),
                null
              ),
              textStyles: commonColumnTextStyles,
            },
            // {
            //   text: appendTextToDigit({
            //     val: data?.targetRevenue,
            //     prefixLabel: '$',
            //   }),
            // },
          ]}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('REVENUE'),
  },
  {
    id: 4,
    draggable: false,
    width: 120,
    field: driverColumnFileds.driver_pay,
    headerRenderer: ({
      headerElement,
    }: {
      headerElement: HTMLElement;
    }): string => {
      (headerElement?.childNodes?.[0] as HTMLElement)?.classList?.add(
        'b-header-column-box'
      );
      return renderToStaticMarkup(
        <ColumnHeader name={t('GanttColumnHeaderDriverPay')} />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('DRIVERPAY'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          data={[
            {
              text: getMultiValueText(
                appendTextToDigit({
                  val: data?.driverPay,
                  prefixLabel: '$',
                }),
                null
              ),
              textStyles: commonColumnTextStyles,
            },
          ]}
        />
      );
    },
  },
  {
    id: 5,
    draggable: false,
    width: 120,
    field: driverColumnFileds.mileage,
    headerRenderer: ({
      headerElement,
    }: {
      headerElement: HTMLElement;
    }): string => {
      (headerElement?.childNodes?.[0] as HTMLElement)?.classList?.add(
        'b-header-column-box'
      );
      return renderToStaticMarkup(
        <ColumnHeader name={t('GanttColumnHeaderMiles')} />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('MILEAGE'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          data={[
            {
              text: getMultiValueText(toFixDigit(data?.currentMileage), null),
              textStyles: commonColumnTextStyles,
            },
          ]}
        />
      );
    },
  },
];

export const getDriverColumnHeaderText = (
  driverCount?: number | null,
  weekDate?: string
) => {
  return `${driverCount} ${t('GanttTotalDrivers')}                  ${t(
    'GanttWeekOf'
  )}: ${weekDate || '-'}`;
};

export const CANCEL_TRIP_POPUP_CONFIG = {
  title: 'Cancel Trip Assignment',
  body: 'Are you sure you want to cancel trip assignment?',
  okText: 'Yes',
  cancelText: 'No',
  okTextStyles: { textTransform: 'capitalize' },
  cancelTextStyles: { textTransform: 'capitalize' },
};

export const TIMEOFF_TOOLTIP_TYPE = 'BREAK';

export const defaultGanttProps = {
  zoomOnMouseWheel: false,
  infiniteScroll: false,
  forceFit: false,
  puckHeight: '22px',
  resourceMargin: 5,
  rowHeight: 33,
  disableTooltip: true,
  fontFamily: 'Poppins',
  showTooltipOnClick: true,
  legend: ganttLegend,
  showCellTooltip: false,
  height: 'calc(100% - 40px)',
  showCurrentTimeLine: false,
  transitionDuration: 0,
  subGridConfigs: {
    locked: {
      minWidth: DRIVER_COLUMN_MIN_WIDTH,
    },
  },
};

export const GANTT_SELECTION_MODE_CONFIG = {
  rowCheckboxSelection: true,
  showCheckAll: true,
  checkbox: {
    checkCls: 'b-driver-selection-checkbox',
  },
  multiSelect: true,
  checkboxOnly: true,
  preserveSelectionOnDatasetChange: true,
  width: '2em',
  minWidth: '2em',
};

export const GANTT_TIMELINE_DATE_RANGE = 14;
