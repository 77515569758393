export class ContactsListDataTotal {
  customerTotal!: number;
  vendorTotal!: number;
  locationTotal!: number;
}
export class PhoneDTO {
  countryCode!: string;
  extension!: number;
  phone!: number;
}

export class Contact {
  fullName: string;
  description: string;
  phoneData: PhoneDTO;
  email: string;
  isPrimary: boolean;

  sendEmailAlert: boolean;
  sendPhoneAlert: boolean;
  alerts: Array<string>;

  constructor(data: any) {
    this.fullName = data.fullName;
    this.description = data.description;
    this.phoneData = data.phoneData;
    this.email = data.email;
    this.isPrimary = data.isPrimary;
    this.sendEmailAlert = data.sendEmailAlert;
    this.sendPhoneAlert = data.sendPhoneAlert;
    this.alerts = data.alerts ?? [];
  }
}
