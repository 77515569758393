import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import FileUploadUpdated from '../../../../common/Ui/FileUpload/indexUpdated';
import DatePicker from '../../../../common/Ui/FormDatePickerWithoutTimeZone/FormDatePickerWithoutTimeZone';
import RadioGroup from '../../../../common/Ui/RadioGroup';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import {
  getExpenseCategoriesList,
  getFuelTypesList,
  getTractorEntityList,
} from '../../utils/api.utils';

import { t } from 'i18next';
import { useMemo } from 'react';
import { addFuelPaymentsModule } from '../../../../common/PendoClassIDs/constants';
import ReadOnlyField from '../../../../common/ReadOnlyField';
import { statesinCountry } from '../../../../common/Ui/AddressField/constants/states';
import { ExpenseEntityEnum, ExpenseEntityType } from '../../../../models';
type propsType = {
  isFuelPayment: boolean;
  entityType?: ExpenseEntityType;
  entityId?: number;
  fileId?: string;
  isEdit?: boolean;
};

function AddNewPaymentForm({
  isFuelPayment,
  entityType,
  entityId,
  fileId,
  isEdit = false,
}: propsType) {
  const { control, setValue } = useFormContext();

  const getRadioGroupData = useMemo(() => {
    const data = [
      {
        value: 'REIMBURSEMENT',
        label: 'Reimbursement',
      },
      { value: 'DEDUCTION', label: t('deduction') },
    ];

    if (!isFuelPayment)
      data.push(
        { value: 'PAYMENT', label: 'Payment' },
        {
          value: 'NON_TAXABLE_PAYMENT',
          label: 'Non-Taxable Payment',
        }
      );

    return data;
  }, [isFuelPayment]);

  return (
    <Grid container spacing={0} justifyContent="space-between">
      <Grid container alignItems="center">
        <Grid xs={4}>
          <Typography variant="tableHeader" sx={{ color: 'text.secondary' }}>
            Payment Type
          </Typography>
        </Grid>
        <Grid xs={8}>
          <RadioGroup
            row
            control={control}
            name="payCategory"
            defaultValue="DEDUCTION"
            data={getRadioGroupData}
            disabled={isEdit}
          />
        </Grid>
      </Grid>
      {isFuelPayment && (
        <>
          {entityType === ExpenseEntityEnum.DRIVER && (
            <SingleAutocompleteForm
              // disabled={noPermissionForEditPayStatement}
              name={`payToDetails`}
              label="Tractor"
              fieldName="entityName"
              getOptions={(str) =>
                getTractorEntityList({
                  nameFilter: str,
                  payToEntityId: entityId,
                }) ?? []
              }
              control={control}
              sizes={{ xs: 5.7 }}
            />
          )}
          <TextField
            id={addFuelPaymentsModule + 'TruckStop'}
            control={control}
            name="truckStop"
            label="Truck Stop"
            sizes={{ xs: entityType === ExpenseEntityEnum.DRIVER ? 5.7 : 12 }}
          />
          <ReadOnlyField text="Fuel" title={t('expenseCategory')} />
          <SingleAutocompleteForm
            id={addFuelPaymentsModule + 'FuelType'}
            name="fuelTypeObj"
            label="Fuel Type"
            fieldName="value"
            getOptions={getFuelTypesList}
            control={control}
            groupBy="categoryName"
            sizes={{ xs: 5.7 }}
            required
          />
          <TextField
            id={addFuelPaymentsModule + 'FuelQuantity'}
            control={control}
            name="fuelQuantity"
            label={t('fuelQuantity')}
            sizes={{
              xs: 5.7,
            }}
            required
          />
          <SingleAutocompleteForm
            id={addFuelPaymentsModule + 'State'}
            name="state"
            label="State"
            fieldName="name"
            options={statesinCountry}
            control={control}
            sizes={{ xs: 5.7 }}
            required
          />
        </>
      )}
      {!isFuelPayment && (
        <>
          {entityType === ExpenseEntityEnum.DRIVER && (
            <>
              <SingleAutocompleteForm
                name={`payToDetails`}
                label="Tractor"
                fieldName="entityName"
                getOptions={(str) =>
                  getTractorEntityList({
                    nameFilter: str,
                    payToEntityId: entityId,
                  }) ?? []
                }
                control={control}
                sizes={{ xs: 12 }}
              />
            </>
          )}
          <TextField
            id={addFuelPaymentsModule + 'Description'}
            control={control}
            name="description"
            label="Description"
            sizes={{
              xs: 12,
            }}
          />
          <SingleAutocompleteForm
            id={addFuelPaymentsModule + 'ExpenseCategory'}
            name="expenseCategoryObj"
            label={t('expenseCategory')}
            fieldName="itemName"
            getOptions={getExpenseCategoriesList}
            control={control}
            sizes={{ xs: 12 }}
            required
          />
        </>
      )}
      <DatePicker
        control={control}
        disabledText
        name="payDate"
        label="Date"
        sizes={{ xs: 5.7 }}
        required
      />
      <TextField
        id={addFuelPaymentsModule + 'Amount'}
        control={control}
        name="amountToShow"
        label="Amount ($)"
        required
        sizes={{
          xs: 5.7,
        }}
      />
      <FileUploadUpdated
        name="documents"
        label="Upload"
        control={control}
        fileId={fileId}
        onChangeCB={() => setValue('fileChanged', true)}
        onRemoveCB={() => setValue('documents', [])}
      />
    </Grid>
  );
}

export default AddNewPaymentForm;
