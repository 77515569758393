/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ContactSummaryCustomer,
  CreateCustomerContactRequest,
  CreateCustomerRequest,
  CustomerAssignedToLoadRequest,
  CustomerSummaryDTO,
  DeleteCustomerRequest,
  GetCustomerDetailsByIdRequest,
  GetCustomerFiltersRequest,
  GetCustomerListExcelRequest,
  GetCustomerListRequest,
  PaginatedCustomersList,
  PaginatedCustomersListRequest,
  UpdateCustomerRequest,
  ValidateCustomerRefNumber,
  customerAssignedToLoadType,
} from '../../models';
import { Service } from './Service';
import { ServiceError } from './helperTypes';

export abstract class ICustomerService extends Service {
  abstract getPaginatedCustomersList(
    requestData: PaginatedCustomersListRequest,
    filtersData: GetCustomerFiltersRequest
  ): Promise<PaginatedCustomersList>;

  abstract createCustomer(
    requestData: CreateCustomerRequest
  ): Promise<CustomerSummaryDTO | ServiceError>;

  abstract createContact(
    requestData: CreateCustomerContactRequest
  ): Promise<ContactSummaryCustomer | ServiceError>;

  abstract updateCustomer(
    requestData: UpdateCustomerRequest
  ): Promise<CustomerSummaryDTO>;

  abstract deleteCustomer(
    requestData: DeleteCustomerRequest
  ): Promise<{ message: string }>;

  abstract customerAssignedToLoad(
    requestData: CustomerAssignedToLoadRequest
  ): Promise<customerAssignedToLoadType>;

  abstract getPaginatedCustomersListExcel(
    requestData: GetCustomerListExcelRequest,
    filtersData: GetCustomerFiltersRequest
  ): Promise<PaginatedCustomersList>;

  abstract getCustomerList(
    requestData: GetCustomerListRequest
  ): Promise<any | ServiceError>;

  abstract getCustomerDetailsById(
    requestData: GetCustomerDetailsByIdRequest
  ): Promise<CustomerSummaryDTO | null>;

  abstract validateCustomerRefNumber(
    requestData: ValidateCustomerRefNumber
  ): Promise<string | ServiceError>;
}
