import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import DetailsPanel from '../../../../common/DetailsPanel';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';

import { useTheme } from '@mui/material';
import { debounce, uniq } from 'lodash';
import { observer } from 'mobx-react';
import { useSecondaryDetailsPanel } from '../../../../common/HOC/withSecondaryDetailsPanel/useSecondaryDetailsPanel';
import WithSecondaryDetailsPanel from '../../../../common/HOC/withSecondaryDetailsPanel/withSecondaryDetailsPanel';

import { GetPreferenceDriverRequest } from 'models/DTOs/Dispatch/Requests';
import { SecondaryDetailsPanelProps } from '../../../../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { useDispatchPermission } from '../../../../common/hooks/useDispatchPermission';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { defaultDispatchPreferencesFormData } from '../../config';
import { debounceTime } from '../../constants/dispatch';
import {
  EDispatchPanelTab,
  ESecondaryDetailsPanelType,
  IDispatchPreferencesFormData,
} from '../../constants/types';
import { useDispatchDetailsPanelSettings } from '../../context/DispatchDetailsPanelProvider';
import DispatchController from '../../utils/Controller';
import {
  convertPreferenceDriverToPreferencesData,
  driverPreferenceDataFormat,
} from '../../utils/dispatch';
import { GanttValidationSchema } from '../../utils/validationSchema';
import DispatchDetailsForm from './DispatchDetailsForm';
import { DispatchDetailsPanelActionsSection } from './DispatchDetailsPanelActionsSection';

export type IDispatchDetailsPanelData = {
  id?: string; //id is driverGroupId
};

export interface DispatchDetailsPanelProps {
  data: IDispatchDetailsPanelData;
  onClose: () => void;
  onUpdated?: (data: any) => void;
  onDeleted?: (data: any) => void;
  onCreated?: (data: any) => void;
  defaultTab?: EDispatchPanelTab;
  autoExpanded?: boolean;
  isGlobal?: boolean;
}

const DispatchDetailsPanel = observer(
    ({
       data,
       onClose,
       onUpdated,
       onDeleted,
       onCreated,
       defaultTab = EDispatchPanelTab.TIMELINE,
       autoExpanded = false,
       isGlobal = false,
     }: DispatchDetailsPanelProps): JSX.Element => {
      const { hasDispatchViewPermission, hasDispatchEditPermission } =
          useDispatchPermission();
      const {
        lastUpdateTime,
        refreshDispatchDataByDriverGroup,
        onChangedDetailsPanel,
        fetchDriverGroupDataWithTrips,
        driverTeam,
      } = useDispatchDetailsPanelSettings();
      const { trackPromise } = useDetailsPanelStore;

      if (!hasDispatchViewPermission) {
        return <></>;
      }

      const {
        getPendingDispatchDriverGroupIds,
        pushPendingDispatchDriverGroupIds,
      } = useRootStore();
      const { secondaryDetailsPanelData, setSecondaryDetailsPanelData } =
          useSecondaryDetailsPanel();

      const [preferencesData, setPreferencesData] =
          useState<IDispatchPreferencesFormData | null>(
              defaultDispatchPreferencesFormData
          );

      const controller = DispatchController.instance();

      const init = (): void => {
        if (data?.id) {
          trackPromise(
              fetchDriverGroupDataWithTrips(data.id),
              ESecondaryDetailsPanelType.DISPATCH as any
          );
        }
      };

      useEffect(() => {
        init();
      }, [data?.id, lastUpdateTime]);

      const [selectedTab, setSelectedTab] =
          useState<EDispatchPanelTab>(defaultTab);

      const handleTabStripChange = (selected: EDispatchPanelTab): void => {
        setSelectedTab(selected);
      };

      const theme: any = useTheme();
      const panelWrapperCustomStyles = {
        display: 'flex',
        width: '33.3%',
        padding: '0 5px 5px 0',
        height: '100%',
        flexDirection: 'column',
        background: theme.palette.leftMenuCollapsed.canvas,
        boxShadow: 'unset',
      };

      const tabStripRenderer = (keys: EDispatchPanelTab[]) => (
          <DetailsTabStrips
              keys={keys}
              defaultTab={defaultTab}
              onChange={handleTabStripChange}
          />
      );

      const isEdit = true;
      const onFullscreen = (isFullscreen: boolean): void => {
        //set default tab when fullscreen
        if (isFullscreen) {
          getPreferenceDriver();
        }
        setSelectedTab(
            isFullscreen && isEdit ? EDispatchPanelTab.TIMELINE : defaultTab
        );
      };
      const entity = 'Dispatch';

      const getValidationSchemaByTab = useCallback(
          (tab: EDispatchPanelTab | string): yup.AnyObjectSchema | null => {
            if (tab == EDispatchPanelTab.PREFERENCES) {
              return GanttValidationSchema;
            }
            return null;
          },
          []
      );

      const getPreferenceDriver = async (): Promise<void> => {
        const driverId = driverTeam?.drivers?.[0]?.id;
        if (!driverId) {
          return;
        }
        const preferenceDriver = await trackPromise(
            controller.getPreferenceDriver({
              driverId,
            } as GetPreferenceDriverRequest),
            ESecondaryDetailsPanelType.DISPATCH as any
        );
        if (preferenceDriver) {
          const _preferencesData =
              convertPreferenceDriverToPreferencesData(preferenceDriver);
          setPreferencesData(_preferencesData);
        }
      };

      const updatePreferenceDriver = async (
          updateData: IDispatchPreferencesFormData
      ): Promise<void> => {
        let preferenceDriver;
        if (updateData && !updateData?.driverId) {
          updateData.driverId = driverTeam?.drivers?.[0]?.id;
          preferenceDriver = await trackPromise(
              controller.addPreferenceDriver({
                ...updateData,
              }),
              ESecondaryDetailsPanelType.DISPATCH
          );
        } else {
          preferenceDriver = await trackPromise(
              controller.updatePreferenceDriver({
                ...updateData,
              }),
              ESecondaryDetailsPanelType.DISPATCH
          );
        }
        if (preferenceDriver) {
          setPreferencesData(
              convertPreferenceDriverToPreferencesData(preferenceDriver)
          );
        }
      };

      const detailsPanelData = useMemo(() => {
        return {
          id: data?.id,
          driverTeam: driverTeam,
          preferencesData: preferencesData,
        };
      }, [preferencesData, data?.id, driverTeam]);

      const handleUpdate = async (tab: EDispatchPanelTab, updateData: any) => {
        if (tab == EDispatchPanelTab.PREFERENCES) {
          const updatedData = driverPreferenceDataFormat(updateData);
          updatePreferenceDriver(updatedData as IDispatchPreferencesFormData);
        }
      };

      const onChangedDetailsPanelHandler = async (): Promise<void> => {
        onChangedDetailsPanel();
      };

      const handleSecondaryDetailsOpen = (
          id: string,
          type: ESecondaryDetailsPanelType,
          options?: Partial<SecondaryDetailsPanelProps> = { isGlobal: true }
      ) => {
        setSecondaryDetailsPanelData({
          type: type,
          id: id,
          options: {
            ...options,
            onUpdated: onChangedDetailsPanelHandler,
            onDeleted: onChangedDetailsPanelHandler,
          },
        });
      };

      const onNotificationHandler = async (
          incomingDriverGroupIds: string[]
      ): Promise<void> => {
        pushPendingDispatchDriverGroupIds([]);
        const driverGroupIds = uniq(incomingDriverGroupIds);
        if (driverGroupIds.length === 0) {
          return;
        }
        controller.setSkipTrackPromise(true);

        const selectedDriverGroupId: string | null = data?.id || null;
        if (
            incomingDriverGroupIds &&
            selectedDriverGroupId &&
            driverGroupIds.includes(selectedDriverGroupId)
        ) {
          refreshDispatchDataByDriverGroup?.();
        }
      };

      const debouncedOnNotificationHandler = useCallback(
          debounce(onNotificationHandler, debounceTime),
          [data]
      );

      const actionsRenderer = () => {
        if (driverTeam) {
          return (
              <>
                {hasDispatchEditPermission && (
                    <DispatchDetailsPanelActionsSection
                        hocDriverTeam={driverTeam}
                        // hocOnAction={onChangedDetailsPanelHandler} // Important: TIMEOFF use onNotificationHandler for live update
                    />
                )}
              </>
          );
        }
      };

      useEffect(() => {
        if (getPendingDispatchDriverGroupIds.length > 0) {
          debouncedOnNotificationHandler(getPendingDispatchDriverGroupIds);
        }
      }, [getPendingDispatchDriverGroupIds]);

      useEffect(() => {
        const driverId = driverTeam?.drivers?.[0]?.id;
        if (
            (driverId && selectedTab === EDispatchPanelTab.PREFERENCES) ||
            autoExpanded
        ) {
          getPreferenceDriver();
        }
        return () => {
          debouncedOnNotificationHandler?.cancel();
        };
      }, [driverTeam?.drivers?.[0]?.id, selectedTab]);

      return (
          <>
            <DetailsPanel
                panelType={ESecondaryDetailsPanelType.DISPATCH}
                data={detailsPanelData}
                actionButtonLabel={isEdit ? undefined : `Save Preferences`}
                entity={entity}
                panelTitle={driverTeam?.displayName}
                onClose={onClose}
                onUpdate={(userData) => handleUpdate(selectedTab, userData)}
                onDelete={onDeleted}
                onCreate={onCreated}
                isGlobal={isGlobal}
                autoExpanded={autoExpanded}
                contentRenderer={() => (
                    <DispatchDetailsForm
                        selectedTab={selectedTab}
                        detailsPanelData={detailsPanelData}
                        handleSecondaryDetailsOpen={handleSecondaryDetailsOpen}
                        onUpdate={onUpdated}
                    />
                )}
                tabStripRenderer={() =>
                    tabStripRenderer(Object.values(EDispatchPanelTab))
                }
                validationSchema={getValidationSchemaByTab(selectedTab)}
                {...(selectedTab === EDispatchPanelTab.TIMELINE && {
                  footerRenderer: () => <></>,
                })}
                onFullscreen={onFullscreen}
                panelWrapperCustomStyles={
                  {
                    //customize the tab content
                    // height: `calc(100vh - ${ganttHeaderAndFilterHeightPx})`,
                  }
                }
                {...(isEdit && {
                  fullscreen: {
                    panelPropList: [
                      {
                        data: detailsPanelData,
                        entity: entity,
                        panelId: EDispatchPanelTab.TIMELINE,
                        panelTitle: driverTeam?.displayName,
                        onUpdate: onUpdated,
                        contentRenderer: () => (
                            <DispatchDetailsForm
                                selectedTab={EDispatchPanelTab.TIMELINE}
                                detailsPanelData={detailsPanelData}
                                handleSecondaryDetailsOpen={handleSecondaryDetailsOpen}
                            />
                        ),
                        panelWrapperCustomStyles: {
                          ...panelWrapperCustomStyles,
                          borderRadius: '18px 0 0 18px', //override border radius
                        },
                        tabStripRenderer: () =>
                            tabStripRendererByKeyFS(EDispatchPanelTab.TIMELINE),
                      },
                      {
                        data: detailsPanelData,
                        entity: entity,
                        panelId: EDispatchPanelTab.PREFERENCES,
                        validationSchema: getValidationSchemaByTab(
                            EDispatchPanelTab.PREFERENCES
                        ),
                        onUpdate: (userData) =>
                            handleUpdate(EDispatchPanelTab.PREFERENCES, userData),
                        contentRenderer: () => (
                            <DispatchDetailsForm
                                selectedTab={EDispatchPanelTab.PREFERENCES}
                                detailsPanelData={detailsPanelData}
                            />
                        ),
                        panelWrapperCustomStyles: {
                          ...panelWrapperCustomStyles,
                          borderRadius: '0 18px 18px 0', //override border radius
                        },
                        panelTitle: '',
                        tabStripRenderer: () =>
                            tabStripRendererByKeyFS(EDispatchPanelTab.PREFERENCES),
                      },
                    ],
                  },
                })}
                actionsRenderer={actionsRenderer}
            />
            <WithSecondaryDetailsPanel
                secondaryDetailsPanelData={secondaryDetailsPanelData}
                setSecondaryDetailsPanelData={setSecondaryDetailsPanelData}
            />
          </>
      );
    }
);

export default DispatchDetailsPanel;
