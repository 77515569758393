import { ComposedError, httpClient } from '../../axios/axiosInstance';

import { QueryParams } from '../../models/DTOs/commonMixed/QueryParams';
import { annotateNameAsync, IAccountingService } from '../interfaces';

import { getOrganizationId } from '../../common/TimeoffDialog/utils';
import {
  AccountingDTO,
  ActivateProviderDTO,
  ChangeClassMappingTypeRequest,
  ChangeMappingRequest,
  ConnectionDTO,
  CredentialValidDTO,
  CredentialValidRequestDTO,
  GetAxeleMapPayItemsDTO,
  GetClassMappingDropdownRequest,
  GetCustomerName,
  GetCustomerNameRes,
  GetIsClassMappingActiveRequest,
  GetMapIncomeItemsDTO,
  GetMapIncomeItemsResDTO,
  GetMapPayItemsDTO,
  GetPayItemsRequest,
  GetQBCustomersDTO,
  GetQBOCustomersDTO,
  GetQuickbookInvoicesRequest,
  RefreshClassMappingTypeRequest,
  SaveIncomeCategoryDTO,
  SendCustomersToQBDTO,
  SendCustomersToQBResDTO,
  SuppressInvoicesRequest,
  SyncBackQBInvoicesRequest,
  SyncQBInvoicesRequest,
  UpdateQBAuthDTO,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

const getAccountingData = '/web/quickbook/api/v2/integration/qbOnlineDetails';
const postIntegrationData = '/web/quickbook/api/v2/integration/create';
const updateQBAuth = '/web/quickbook/api/v2/integration/updateAuthentication';
const deleteIntegration = '/web/quickbook/api/v2/integration/deactivate';
const updateIntegration = '/web/quickbook/api/v2/integration/update';
const connectToQBOnline = '/web/quickbook/api/v2/integration/connect';
const getMapPayItems = '/web/quickbook/api/v2/income/mapping';
const getAxeleMapPayItems =
  '/web/preference/api/v2/financial/items/getByOrganizationId';
const getMapIncomeItems = '/web/quickbook/api/v2/income/list';
const updateQBOItemsCategory = '/web/quickbook//api/v2/income/refresh';
const savePayItemMapping = '/web/quickbook/api/v2/income/save';
const getQBOCustomers = '/web/quickbook/api/v2/customer/get';
const fetchQBOCustomers = '/web/quickbook/api/v2/customer/fetch';
const sendCustomersToQB = '/web/quickbook/api/v2/customer/create';
const getCustomerName = '/web/quickbook/api/v2/customer/get';
const getQBInvoicesListURL = 'web/quickbook/api/v2/invoice/list';
const suppressInvoicesURL = 'web/quickbook/api/v2/invoice/update/suppress';
const syncBackQBInvoicesURL = 'web/quickbook/api/v2/invoice/sync/back';
const syncQBInvoicesURL = 'web/quickbook/api/v2/invoice/sync';
const getIsClassMappingActiveURL = '/web/quickbook/api/v2/class/preference';
const getClassMappingListURL = '/web/quickbook/api/v2/class/mapping';
const changeClassMappingTypeURL = '/web/quickbook/api/v2/class/save/entitytype';
const refreshClassMappingTypeURL = '/web/quickbook/api/v2/class/refresh';
const mappingChangeURL = '/web/quickbook/api/v2/class/save';
const getClassMappingDropdownURL = '/web/quickbook/api/v2/class/items';

export class AccountingSevice extends IAccountingService {
  requestData: QueryParams;
  constructor() {
    super();
    this.requestData = new QueryParams();
  }

  @annotateNameAsync
  async getCustomerName(requestData: GetCustomerName) {
    try {
      const response = await httpClient.get<GetCustomerNameRes>(
        getCustomerName,
        requestData,
        GetCustomerNameRes
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getAccountingData(terminalIds: string[], organizationId: number) {
    try {
      const prefData = await httpClient.get<AccountingDTO>(
        getAccountingData +
          `?organizationId=${organizationId}&terminalIds=${terminalIds}`,
        undefined,
        undefined,
        false,
        true
      );
      return prefData;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async postIntegrationData(payload: ActivateProviderDTO) {
    try {
      const postData = await httpClient.post<AccountingDTO>(
        postIntegrationData,
        undefined,
        payload,
        undefined,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'activateQBProvider',
      });
      return postData;
    } catch (error: any) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: error?.message?.response?.data?.message,
      });
      window.location.href = `${window.location.origin}/${process.env['123LOADBOARD_REDIRECT_URI']}`;
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateQBAuth(payload: UpdateQBAuthDTO) {
    try {
      const postData = await httpClient.put<AccountingDTO>(
        updateQBAuth,
        undefined,
        payload,
        undefined,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateQBAuth',
      });
      return postData;
    } catch (error: any) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: error?.message?.response?.data?.message,
      });
      window.location.href = `${window.location.origin}/${process.env['123LOADBOARD_REDIRECT_URI']}`;
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async deleteIntegration(accoutId: string, organizationId: number) {
    try {
      const deleteData = await httpClient.delete(
        deleteIntegration +
          `?organizationId=${organizationId}&accountId=${accoutId}`,
        undefined,
        undefined,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteQBProvider',
      });
      return deleteData;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'deleteQBProvider',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateIntegration(payload: ActivateProviderDTO) {
    try {
      const postData = await httpClient.put<AccountingDTO>(
        updateIntegration,
        undefined,
        payload,
        undefined,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateQBProvider',
      });
      return postData;
    } catch (error: any) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: error?.message?.response?.data?.message,
      });
      return error;
    }
  }

  async getMapPayItems(AccountCardId: string) {
    try {
      const mapPayData = await httpClient.get<GetMapPayItemsDTO>(
        getMapPayItems +
          `?organizationId=${getOrganizationId()}&qbAccountId=${AccountCardId}`,
        undefined,
        undefined,
        true
      );
      if (mapPayData.connectionDTO?.integrationStatus === 'INVALID') {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'invalidQBIntegration',
        });
      }
      return mapPayData;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  async getAxeleMapPayItems() {
    try {
      const requestData = new GetPayItemsRequest();
      requestData.isInvoiceItem = true;
      const axeleMapPayItem = await httpClient.get<GetAxeleMapPayItemsDTO>(
        getAxeleMapPayItems,
        requestData,
        GetAxeleMapPayItemsDTO,
        true,
        true
      );
      return axeleMapPayItem;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  async getMapIncomeItems(queryData: GetMapIncomeItemsDTO) {
    try {
      const mapIncomeItems = await httpClient.get<GetMapIncomeItemsResDTO>(
        getMapIncomeItems,
        queryData,
        GetMapIncomeItemsResDTO,
        true
      );
      return mapIncomeItems;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  async updateQBOItemsCategory(AccountCardId: string) {
    try {
      const mapPayData = await httpClient.get<GetMapPayItemsDTO>(
        updateQBOItemsCategory +
          `?organizationId=${getOrganizationId()}&qbAccountId=${AccountCardId}`,
        undefined,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateQBItemCategory',
      });
      return mapPayData;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'updateQBItemCategory',
      });
      return Promise.resolve(null);
    }
  }

  async savePayItemMapping(payload: SaveIncomeCategoryDTO) {
    payload.organizationId = getOrganizationId();
    try {
      const savePayItem = await httpClient.post<GetMapPayItemsDTO>(
        savePayItemMapping,
        undefined,
        payload,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'savePayItemCategory',
      });
      return savePayItem;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'savePayItemCategory',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getQBOCustomers(queryData: GetQBCustomersDTO) {
    try {
      const customersData = await httpClient.get<GetQBOCustomersDTO>(
        getQBOCustomers,
        queryData,
        GetQBOCustomersDTO,
        true
      );
      return customersData;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async fetchQBOCustomers(queryData: GetQBCustomersDTO) {
    try {
      const fetchCustomersData = await httpClient.get<GetQBOCustomersDTO>(
        fetchQBOCustomers,
        queryData,
        GetQBOCustomersDTO,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'fetchQBOCustomers',
      });
      return fetchCustomersData;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'fetchQBOCustomers',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async sendCustomersToQB(payload: SendCustomersToQBDTO) {
    payload.organizationId = getOrganizationId();
    try {
      const sendCustomersData = await httpClient.post<SendCustomersToQBResDTO>(
        sendCustomersToQB,
        undefined,
        payload,
        undefined,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'sendQBOCustomers',
      });
      if (sendCustomersData?.nonSyncedIds?.length > 0) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'customersNonSynced',
        });
      }
      return sendCustomersData;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'sendQBOCustomers',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async connectToQBOnline(qbAccountId: string) {
    try {
      const prefData = await httpClient.put<ConnectionDTO>(
        connectToQBOnline +
          `?qbAccountId=${qbAccountId}&organizationId=${getOrganizationId()}`,
        undefined,
        undefined,
        false
      );
      if (prefData?.integrationStatus === 'VALID') {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'connectToQBO',
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'invalidQBIntegration',
        });
      }
      return prefData;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'connectToQBO',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getQBInvoices(requestData: GetQuickbookInvoicesRequest) {
    try {
      const response = await httpClient.getRaw(
        getQBInvoicesListURL,
        requestData,
        undefined
      );
      if (!response.data?.connectionDTO?.connected) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'inactiveQBIntegration',
        });
      } else if (
        response.data?.connectionDTO?.connected &&
        !response.data?.allTypesMapped
      ) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'unmappedQBIntegration',
        });
      }

      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async suppressInvoices(requestData: SuppressInvoicesRequest) {
    try {
      const response = await httpClient.putRaw(
        suppressInvoicesURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: requestData.suppress
          ? 'suppressInvoices'
          : 'releaseInvoices',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: requestData.suppress
          ? 'suppressInvoices'
          : 'releaseInvoices',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async syncBackQBInvoices(requestData: SyncBackQBInvoicesRequest) {
    try {
      const response = await httpClient.putRaw(
        syncBackQBInvoicesURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'syncBackQBInvoices',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'syncBackQBInvoices',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async syncQBInvoices(requestData: SyncQBInvoicesRequest) {
    try {
      const response = await httpClient.putRaw(
        syncQBInvoicesURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'syncQBInvoices',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'syncQBInvoices',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async validateQBCredentials(payload: CredentialValidRequestDTO) {
    try {
      const response = await httpClient.get<CredentialValidDTO>(
        postIntegrationData +
          `/validate?displayName=${payload.displayName}&organizationId=${payload.organizationId}&terminalIds=${payload.terminalIds}`,
        undefined,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getIsClassMappingActive(requestData: GetIsClassMappingActiveRequest) {
    try {
      const response = await httpClient.getRaw(
        getIsClassMappingActiveURL,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getClassMappingList(requestData: GetIsClassMappingActiveRequest) {
    try {
      const response = await httpClient.getRaw(
        getClassMappingListURL,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async changeClassMappingType(requestData: ChangeClassMappingTypeRequest) {
    try {
      const response = await httpClient.putRaw(
        changeClassMappingTypeURL,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async refreshClassMappingType(requestData: RefreshClassMappingTypeRequest) {
    try {
      const response = await httpClient.putRaw(
        refreshClassMappingTypeURL,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async mappingChange(requestData: ChangeMappingRequest) {
    try {
      const response = await httpClient.postRaw(
        mappingChangeURL,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getClassMappingDropdown(requestData: GetClassMappingDropdownRequest) {
    try {
      const response = await httpClient.getRaw(
        getClassMappingDropdownURL,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
