import React, { FC } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

interface Props {
  label: string;
  value: string;
}

export const LoadAlertInfo: FC<Props> = ({ label, value }) => {
  return (
    <Box
      sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}
    >
      <Typography color="text.secondary" sx={{ fontWeight: 600, fontSize: 14 }}>
        {label} :
      </Typography>
      <Typography
        sx={{
          color: 'text.primary',
          fontSize: '14px',
          fontWeight: 400,
        }}
        variant="body4"
      >
        {value}
      </Typography>
    </Box>
  );
};
