import {
  GetDriverIdListRequest,
  GetEmailsRequest,
  GetNamesRequest,
  GetPrimaryDispatcherRequest,
  GetRolesRequest,
  GetStatusesRequest,
  GetTerminalsRequest,
} from '../../../models';
import { manageTypesService, userService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { PrimaryDispatcher } from '../../../models/DTOs/user/User';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import {
  GetTagsRequest,
  TagEntityType,
} from '@/models/DTOs/ManageTypes/Requests';

/* @TODO davits remove after statics implementation */
export const getTrackingSource = (
  trackingSource: {
    [key: string]: boolean;
  } = {}
): string => {
  let source = '';
  if (trackingSource.eld) {
    source += 'ELD, ';
  }
  if (trackingSource.gps) {
    source += 'GPS';
  }
  return source;
};

export const getUserRoles = async (name: string, pageNumber: number) => {
  const request = new GetRolesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  if (name) {
    request.roleCode = name;
  }
  const response = await userService.getUserRoles(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getUserEmails = async (
  name: string,
  pageNumber: number,
  terminalIds: string[]
) => {
  const request = new GetEmailsRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.terminalIds = terminalIds;
  if (name) {
    request.email = name;
  }
  const response = await userService.getUserEmails(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

/* @TODO davits remove second 2 function below after using new multi selects. Add passing argument ability */
export const getWebUserEmails = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[]
) => {
  const request = new GetEmailsRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.terminalIds = terminalIds;
  if (name) {
    request.email = name;
  }
  request.excludeRoleCodeList = ['DR'];
  const response = await userService.getUserEmails(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getDriverEmails = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[]
) => {
  const request = new GetEmailsRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.terminalIds = terminalIds;
  if (name) {
    request.email = name;
  }
  request.roleCodeList = ['DR'];
  const response = await userService.getUserEmails(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getTerminalsList = async (name: string, pageNumber: number) => {
  const request = new GetTerminalsRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.companyName = name;
  const response = await userService.getTerminals(request);
  return response instanceof ServiceError ? [] : response;
};

export const getNamesList = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[]
) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.terminalIds = terminalIds;
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

/* @TODO davits remove second 2 function below after using new multi selects. Add passing argument ability */
export const getWebUserNamesList = async (
  name: string,
  pageNumber: number,
  terminalIds: string[]
) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.terminalIds = terminalIds;
  request.excludeRoleCodeList = ['DR'];
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getDriverNamesList = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[],
  skipLoader?: boolean,
  pageSize = 25
) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = pageSize;
  request.name = name;
  request.roleCodeList = ['DR'];
  request.terminalIds = terminalIds;
  request.excludeStatuses = [0];
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getAllDriverNamesList = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[],
  skipLoader?: boolean,
  pageSize = 25
) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = pageSize;
  request.name = name;
  request.roleCodeList = ['DR'];
  request.terminalIds = terminalIds;
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getStatusesList = async (name: string, pageNumber: number) => {
  const request = new GetStatusesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.status = name;
  const response = await userService.getStatuses(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return { content: response, last: true };
  }
};

export const getDriverIdList = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[]
) => {
  const request = new GetDriverIdListRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.driverRefId = name;
  request.terminalIds = terminalIds;
  const response = await userService.getDriverIdList(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getPrimaryDispatcherList = async (
  name: string,
  pageNumber: number
) => {
  const request = new GetPrimaryDispatcherRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.excludeRoleCodeList = ['DR'];
  request.userStatus = [1, 2, 3, 4];
  const response = await userService.getPrimaryDispatchers(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    response.content = response.content.map(
      (user) => new PrimaryDispatcher(user)
    );
    return response;
  }
};

export const settingsUsersAddPermissions: {
  [key: string]: AXELE_PERMISSION_TYPE[];
} = {
  DI: [AXELE_PERMISSION_TYPE.USER_PROFILE_ADD],
  NFD: [AXELE_PERMISSION_TYPE.USER_PROFILE_ADD],
  AC: [AXELE_PERMISSION_TYPE.USER_PROFILE_ADD],
  PD: [AXELE_PERMISSION_TYPE.USER_PROFILE_ADD],
  DR: [AXELE_PERMISSION_TYPE.USER_PROFILE_ADD],
  SP: [AXELE_PERMISSION_TYPE.USER_PROFILE_ADD],
};

export const getPaginatedTagsList = async (
  text: string,
  pageNumber: number,
  entityTypes?: TagEntityType[]
) => {
  const queryParams = new GetTagsRequest();
  queryParams.pageNumber = pageNumber;
  queryParams.pageSize = 25;
  queryParams.searchText = text;
  queryParams.entityTypes = entityTypes;

  const tagList = await manageTypesService.getTags(queryParams);
  if (tagList instanceof ServiceError) {
    return [];
  } else {
    const result: any = tagList;
    return result;
  }
};
