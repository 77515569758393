import { renderDateTime } from '@/utils/NameAndDateTimeGridField';
import { Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import HyperLink from '../../common/Ui/HyperLink/HyperLink';
import { InvoiceStatus } from '../../common/Ui/StatusComponent/StatusComponent';
import { CommonConstants } from '../../locales/en/common/index';
import { GridColDefSelf, IconTypes } from '../../types';
import {
  ErrorOutlinedIcon,
  WarningLoadListDetentionIcon,
  WarningLoadListInvoiceIcon,
} from '../../ui-kit/components/Assets';
import {
  amountFormat,
  fromIsoToMoment,
  getFormattedDateForGrid,
  getTimeFromMinutes,
  nullableDateYup,
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
  numberWithThousandSeparator,
} from '../../utils';
import { BulkActionConfigType, LineItem } from './models/InvoiceModel';
import { TagList } from '@/common/Tag/components/TagList';

export const invoiceLineItemFormRequiredFields = {
  type: true,
  quantity: true,
  rate: true,
};

export const receivePaymentFormRequiredFields = {
  amount: true,
};
export const invoiceTypeText = {
  MASTER: 'Combined',
  LOAD: 'Single',
  CUSTOM: 'Manual',
};

export enum EInvoiceType {
  MASTER = 'MASTER', //Combined (Invoice Details Panel)
  LOAD = 'LOAD', //Single (Load Details Panel)
  CUSTOM = 'CUSTOM', //Manual (Invoice Details Panel)
}

export const invoiceColumns: GridColDefSelf[] = [
  // {
  //   flex: 1,
  //   field: 'seqNumber',
  //   headerName: 'Invoice#',
  //   sortable: true,
  //   minWidth: 150,
  // },

  {
    flex: 1,
    field: 'seqNumber',
    headerName: 'Invoice#',
    headerAlign: 'center',
    // sortable: true,
    permanent: true,
    minWidth: 150,
    align: 'center',
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {params.row.warnings.map((w: { name: string; time: number }) => (
              <>
                {w.name === 'Detention' && (
                  <Box sx={{ mr: 1 }}>
                    {renderWarningIcon('Detention Time', w)}
                  </Box>
                )}
                {w.name === 'Overdue' && (
                  <Box>{renderOverdueWarningIcon('Invoice Overdue', w)}</Box>
                )}
              </>
            ))}
          </Box>
          <Box>{params.value}</Box>
        </Box>
      );
    },
  },

  {
    flex: 1,
    field: 'loadSeqNumber',
    headerName: 'Load ID',
    headerAlign: 'center',
    // sortable: true,
    minWidth: 150,
    align: 'center',
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {params.row.warnings.map((w: { name: string; time: number }) => (
              <>
                {w.name === 'Detention' && (
                  <Box sx={{ mr: 1 }}>
                    {renderWarningIcon('Detention Time', w)}
                  </Box>
                )}
                {w.name === 'Overdue' && (
                  <Box>{renderOverdueWarningIcon('Invoice Overdue', w)}</Box>
                )}
              </>
            ))}
          </Box>
          <Box>
            {params.row.invoiceType === 'MASTER' ? (
              'Multi'
            ) : (
              <Box sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                {params.value}
              </Box>
            )}
          </Box>
        </Box>
      );
    },
  },
  {
    flex: 1,
    field: 'Loadstatus',
    headerName: 'Load Status',
    // sortable: true,
    minWidth: 150,
    renderCell: (params) => {
      if (params.row.pinned) {
        return '';
      }
      const status =
        params.row.hasPayment && params.row.status === 'INVOICED'
          ? 'INVOICED'
          : params.row.status;

      return (
        <InvoiceStatus
          status={status}
          type="LOAD"
          onHold={params.row.onHold}
          onHoldNote={params.row?.onHoldNote}
        />
      );
    },
  },
  {
    flex: 1,
    field: 'status',
    headerName: 'Invoice Status',
    // sortable: true,
    minWidth: 150,
    renderCell: (params) => {
      if (params.row.pinned) {
        return '';
      }
      const status =
        params.row.hasPayment && params.row.status === 'INVOICED'
          ? 'PARTIALLY_PAID'
          : params.row.status;
      return (
        <InvoiceStatus
          status={status}
          type="LOAD"
          onHold={params.row.onHold}
          onHoldNote={params.row?.onHoldNote}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'tags',
    headerName: 'Tags',
    sortable: false,
    renderCell: (params) => {
      return (
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={1}
          sx={{ mt: '5px', mb: '5px' }}
        >
          {params.row.invoiceType === 'MASTER' ? (
            'Multi'
          ) : (
            <TagList small tags={params.row.tags} />
          )}
        </Stack>
      );
    },
  },
  {
    flex: 1,
    field: 'customer',
    headerName: 'Customer',
    // sortable: true,
    minWidth: 150,
    isHyperLink: true,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        {!params.row.customerMapped &&
          typeof params.row.customerMapped === 'boolean' &&
          params.row.customer && (
            <WarningIconWithTooltip
              text={'Customer is not Mapped with factoring company.'}
            />
          )}
        <HyperLink value={params.row?.customer?.name || ''} />
      </Box>
    ),
  },
  {
    flex: 1,
    field: 'driverName',
    headerName: 'Driver',
    // sortable: true,
    minWidth: 150,
    renderCell: (params) => {
      return params.row.invoiceType === 'MASTER'
        ? 'Multi'
        : params.row.groupDetails
            .map(
              (driverGroup: { displayName: string }) => driverGroup.displayName
            )
            .join(', ') || '';
    },
  },

  {
    flex: 1,
    field: 'invoiceType',
    headerName: 'Invoice Type',
    // sortable: true,
    minWidth: 150,

    renderCell: (params) => (
      <>
        {params.row.invoiceType ? invoiceTypeText[params.row.invoiceType] : ''}
      </>
    ),
  },
  {
    flex: 1,
    field: 'emailSendDate',
    headerName: 'Send Email',
    // sortable: true,
    minWidth: 150,

    sortable: true,
    renderCell: (params) => {
      if (!params.row.emailSendDate) return null;
      return renderDateTime(params.row.emailSendDate);
    },
  },
  // {
  //   field: tripFieldsConfig.onHoldState.fieldName,
  //   headerName: tripFieldsConfig.onHoldState.label,
  //   renderCell: () => {
  //     return null;
  //   },
  // },
  {
    flex: 1,
    field: 'invoiceReferenceId',
    headerName: 'Ref #',
    // sortable: true,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'paymentSeqNumber',
    headerName: 'Payment Id',
    // sortable: true,
    minWidth: 150,
    renderCell: (params) => {
      if (params.row.receivedPayments.length === 0) return null;
      return (
        <>
          {params.row.receivedPayments?.map(
            (
              data: {
                seqNumber: string;
              },
              index: number
            ) => (
              <span key={data.seqNumber}>
                <Typography>
                  {' '}
                  {!!index && data.seqNumber && `${', '}`}
                  {data.seqNumber}
                </Typography>
              </span>
            )
          )}
        </>
      );
    },
  },
  {
    flex: 1,
    field: 'factoringCompany',
    headerName: 'Factoring',
    sortable: false,
    minWidth: 150,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        {params.row.isFactoringInvalid && (
          <WarningIconWithTooltip
            text={'Factoring activation is failed or is pending.'}
          />
        )}
        <Box
          sx={{
            typography: 'body2',
          }}
        >
          {params.row.factoringCompany || ''}
        </Box>
      </Box>
    ),
  },
  {
    flex: 1,
    field: 'factoringAmount',
    headerName: 'Factoring Charge',
    sortable: true,
    minWidth: 150,
    renderCell: (params) => {
      return (
        <Typography
          variant="body2"
          sx={[
            {
              color: 'text.primary',
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 700,
            },
          ]}
        >
          {amountFormat(params.row.factoringAmount)}
        </Typography>
      );
    },
  },
  {
    flex: 1,
    field: 'total',
    headerName: 'Invoice total',
    align: 'right',
    headerAlign: 'right',
    sortable: true,
    minWidth: 150,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={[
          {
            color: 'text.primary',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 700,
          },
        ]}
      >
        ${numberWithThousandSeparator(params.value, false, 2)}
      </Typography>
    ),
  },
  {
    flex: 1,
    field: 'amountDue',
    headerName: 'Due Amount',
    align: 'right',
    headerAlign: 'right',
    sortable: true,
    minWidth: 150,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={[
          {
            color: 'text.primary',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 700,
          },
        ]}
      >
        ${numberWithThousandSeparator(params.value, false, 2)}
      </Typography>
    ),
  },
  {
    flex: 1,
    field: 'factoringStatus',
    headerName: 'Factoring Status',
    sortable: false,
    minWidth: 150,
    renderCell: (params) => validStatuses[params.row.factoringStatus || ''],
  },
  {
    flex: 1,
    field: 'originLocationName',
    headerName: 'Origin Location Name',
    sortable: false,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'destinationLocationName',
    headerName: 'Destination Location Name',
    sortable: false,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'origin',
    headerName: 'Origin',
    sortable: false,
    minWidth: 150,
    renderCell: (params) =>
      params.row.invoiceType === 'MASTER'
        ? 'Multi'
        : `${params.row.originCity} ${params.row.originState && ','}  ${
            params.row.originState
          }`,
  },
  {
    flex: 1,
    field: 'destination',
    headerName: 'Destination',
    sortable: false,
    minWidth: 150,
    renderCell: (params) =>
      params.row.invoiceType === 'MASTER'
        ? 'Multi'
        : `${params.row.destinationCity}  ${
            params.row.destinationState && ','
          } ${params.row.destinationState}`,
  },
  {
    flex: 1,
    field: 'missingDocuments',
    headerName: 'Missing Documents',
    sortable: false,
    minWidth: 150,
    renderCell: (params) => {
      return params.value?.join('/');
    },
  },
  // {
  //   flex: 1,
  //   field: 'loadReferenceId',
  //   headerName: 'Ref #',
  //   sortable: true,
  //   minWidth: 150,
  // },
  {
    flex: 1,
    field: 'terminal',
    headerName: CommonConstants.terminal,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => params.row.terminal?.companyName,
  },

  {
    flex: 1,
    field: 'completeDate',
    headerName: 'Completed On',
    // sortable: true,
    minWidth: 150,

    valueGetter: (params) =>
      params.row.invoiceType === 'MASTER'
        ? 'Multi'
        : getFormattedDateForGrid(fromIsoToMoment(params.value), 'MM/DD/yyyy'),
  },
  {
    flex: 1,
    field: 'updateDate',
    headerName: 'Updated On',
    // sortable: true,
    minWidth: 150,
    valueGetter: (params) =>
      getFormattedDateForGrid(fromIsoToMoment(params.value), 'MM/DD/yyyy'),
  },
  {
    flex: 1,
    field: 'invoicedOn',
    headerName: 'Invoiced On',
    // sortable: true,
    minWidth: 150,
    valueGetter: (params) =>
      getFormattedDateForGrid(fromIsoToMoment(params.value), 'MM/DD/yyyy'),
  },
  {
    flex: 1,
    field: 'paidOn',
    headerName: 'Paid On',
    // sortable: true,
    minWidth: 150,
    valueGetter: (params) =>
      getFormattedDateForGrid(fromIsoToMoment(params.value), 'MM/DD/yyyy'),
  },
  {
    flex: 1,
    field: 'notes',
    headerName: 'Internal Invoice Notes',
    sortable: false,
    minWidth: 150,
  },
];

export const renderWarningIcon = (
  title: string,
  w: { name: string; time: number }
) => (
  <Tooltip title={`${title}: ${getTimeFromMinutes(w.time)}`}>
    <Box component="span">{warningIcons[w.name]}</Box>
  </Tooltip>
);

export const renderOverdueWarningIcon = (
  title: string,
  w: { name: string; time: number }
) => {
  if (!w.time) {
    return <Box component="span">{warningIcons[w.name]}</Box>;
  }
  return (
    <Tooltip title={`${title}: ${w.time}d`}>
      <Box component="span">{warningIcons[w.name]}</Box>
    </Tooltip>
  );
};

export const FACTORING_STATUS_INVOICE_SENT = 'INVOICE_SENT';
export const FACTORING_STATUS_INVOICE_NOTREADYTOBILL = 'INVOICE_NOTREADYTOBILL';
export const FACTORING_STATUS_INVOICE_READYTOBILL = 'INVOICE_READYTOBILL';
export const FACTORING_STATUS_INVOICE_SUBMITTED = 'INVOICE_SUBMITTED';
export const FACTORING_STATUS_INVOICE_LOGGED = 'INVOICE_LOGGED';
export const FACTORING_STATUS_INVOICE_AUDIT = 'INVOICE_AUDIT';
export const FACTORING_STATUS_INVOICE_ISSUES = 'INVOICE_ISSUES';
export const FACTORING_STATUS_INVOICE_APPROVAL = 'INVOICE_APPROVAL';
export const FACTORING_STATUS_INVOICE_FUNDED_ZEROADVANCE =
  'INVOICE_FUNDED_ZEROADVANCE';
export const FACTORING_STATUS_INVOICE_NOTFUNDED = 'INVOICE_NOTFUNDED';
export const FACTORING_STATUS_INVOICE_FUNDED_NOTPAID = 'INVOICE_FUNDED_NOTPAID';
export const FACTORING_STATUS_INVOICE_INVOICE_FUNDED_PAID =
  'INVOICE_FUNDED_PAID';

export const validStatuses: { [key: string]: string } = {
  INVOICE_SENT: 'Sent',
  INVOICE_NOTREADYTOBILL: 'Not Ready to Bill',
  INVOICE_READYTOBILL: 'Ready to Bill',
  INVOICE_SUBMITTED: 'Submitted',
  INVOICE_LOGGED: 'Logged',
  INVOICE_AUDIT: 'Audit',
  INVOICE_ISSUES: 'Issues',
  INVOICE_APPROVAL: 'Approval',
  INVOICE_FUNDED_ZEROADVANCE: 'Funded/Zero Advance',
  INVOICE_NOTFUNDED: 'Not Funded',
  INVOICE_FUNDED_NOTPAID: 'Funded/Not Paid',
  INVOICE_FUNDED_PAID: 'Funded/Paid',
};

export const PREVIEW_ACTION = 'Preview';
export const DOWNLOAD_ACTION = 'Download';
export const EMAIL_ACTION = 'Email';
export const NO_ACTION = 'Actions';

export const ACTIONS = [
  NO_ACTION,
  PREVIEW_ACTION,
  DOWNLOAD_ACTION,
  EMAIL_ACTION,
];

export const INVOICE_STATUS_ACTIONS: { [key: string]: string[] } = {
  INVOICED: ['Revert to Completed', 'Paid'],
  PAID: ['Revert to Invoiced'],
  LOAD_COMPLETED: ['Invoiced'],
  CANCELLED: ['Invoiced'],
};

export const REVERT_TO_COMPLETED = 'LOAD_COMPLETED';
export const PAID = 'PAID';
export const INVOICED = 'INVOICED';
export const REVERT_TO_INVOICED = 'REVERT_TO_INVOICED';
export const REVERT_TO_CANCELLED = 'CANCELLED';

export const actionNameValueMap: { [key: string]: string } = {
  'Revert to Completed': REVERT_TO_COMPLETED,
  Paid: PAID,
  'Revert to Invoiced': REVERT_TO_INVOICED,
  Cancelled: REVERT_TO_CANCELLED,
  Invoiced: INVOICED,
};

export const COMPLETED_LOAD_STATUS_NAME = 'LOAD_COMPLETED';
export const PAID_LOAD_STATUS_NAME = 'PAID';
export const INVOICED_LOAD_STATUS_NAME = 'INVOICED';
export const CANCELLED_LOAD_STATUS_NAME = 'CANCELLED';
export const READY_TO_INVOICE = 'READY_TO_INVOICE';

export const SEND_TO_FACTORING = 'Send to Factoring';
export const REFRESH_FACTORING_STATUS = 'Refresh Factoring Status';
export const SYNC_DOCUMENTS = 'Sync Documents';
export const SYNC_MODIFIED_INVOICE = 'Sync Modified Invoice';
export const DISCONNECT_FACTORING = 'Disconnect Factoring';
export const SUBMIT_TO_SCHEDULE = 'Submit to Schedule';

export const FACTORING_ACTIONS = [
  SEND_TO_FACTORING,
  REFRESH_FACTORING_STATUS,
  SYNC_DOCUMENTS,
  SYNC_MODIFIED_INVOICE,
  DISCONNECT_FACTORING,
  // SUBMIT_TO_SCHEDULE,
];

const warningIcons: IconTypes = {
  Detention: <WarningLoadListDetentionIcon />,
  Overdue: <WarningLoadListInvoiceIcon />,
};

export const WarningIconWithTooltip = ({
  to,
  stripId,
  externalId,
  linkText = 'Activate Here',
  text,
}: {
  to?: string;
  stripId?: string;
  externalId?: string;
  text: string;
  linkText?: string;
}) => (
  <Box>
    <Tooltip
      placement="top"
      title={
        <>
          {text}
          {to && (
            <Link
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
              to={to}
              state={{
                stripId: stripId,
                externalId: externalId,
              }}
            >
              {linkText}
            </Link>
          )}
        </>
      }
    >
      <Box sx={{ mr: 0.5 }}>
        <ErrorOutlinedIcon />
      </Box>
    </Tooltip>
  </Box>
);

export const invoiceLineItemsDialogSchema = yup.object().shape({
  type: yup.string().required('Type is required.').nullable(),
  quantity: nullableNumberYup()
    .required('Quantity is required.')
    .min(0, 'value must be between 0 and 999,999.9999')
    .max(9999999.99999),
  rate: nullableNumberYup()
    .required('Amount is required.')
    .min(0, 'value must be between 0 and 999,999.9999')
    .max(9999999.99999, 'value must be between 0,1 and 999,999.9999'),
  amount: yup.number().test('amount test', (value, context) => {
    return value < 0 && Math.abs(value) > context.from[0].value.amountDue
      ? context.createError({
          message: `The due amount cannot be negative. Please correct data`,
          path: 'amountTotal',
        })
      : true;
  }),
});

export const invoiceLineItemsSchema = yup.object().shape({
  type: yup.string().required('Type is required.').nullable(),
  quantity: nullableNumberYup()
    .required('Quantity is required.')
    .min(1, 'value must be between 1.0000 and 999,999.9999')
    .max(9999999.99999),
  rate: nullableNumberYup()
    .required('Rate is required.')
    .min(0, 'Value must be between 0 and 999,999.9999.')
    .max(9999999.99999, 'Value must be between 0 and 999,999.9999.'),
  amount: nullableNumberYup().test('amount test', (value, context) => {
    return value < 0 && context.from[1].value.amountDueTotal < 0
      ? context.createError({
          message: `The due amount cannot be negative. Please correct data`,
          path: 'amountTotal',
        })
      : true;
  }),
});

export const fundingItemSchema = yup.object().shape({
  amount: nullableNumberYup()
    .required('Amount is required.')
    .test('amount test', (value, context) => {
      const totalAmount: number = context.from[1].value.fundings.reduce(
        (accumulator: number, object: LineItem) => {
          return accumulator + +object.amount;
        },
        0
      );
      const invoiceTotalDueAmount: number =
        context.from[1].value.totalAmountDue;
      return totalAmount! > invoiceTotalDueAmount
        ? context.createError({
            message: `The total funding amount cannot be greater than the total of the selected invoices `,
            path: 'amountTotal',
          })
        : true;
    }),
});

export const fundingItemsSchema = yup.object().shape({
  fundings: yup
    .array()
    .of(fundingItemSchema)
    .compact()
    .max(9, 'Can not add more than 9'),
  instructions: nullableStringYup().max(
    1024,
    'Name must be at most 100 characters.'
  ),
});

export const receivedPaymentsSchema = yup.object().shape({
  referenceNumber: yup
    .string()
    .test('length', 'Cannot be longer than 50 characters', (val) =>
      !!val ? val.length <= 50 : true
    )
    .nullable(),
  amount: nullableNumberYup()
    .required('Amount is required.')
    .min(1, 'Rate should be greater than 0.')
    .test('amount test', (value, context) => {
      if (typeof context.originalValue !== 'string') {
        return context.schema;
      }
      const invoiceTotal: number = context.from[1].value.lineItems.reduce(
        (accumulator: number, object: LineItem) => {
          return accumulator + object.amount;
        },
        0
      );

      const receivedPaymentsTotal: number =
        context.from[1].value.receivedPayments.reduce(
          (accumulator: number, object: { amount: number }) => {
            return accumulator + +object.amount;
          },
          0
        );
      const amountDue = invoiceTotal - receivedPaymentsTotal;
      return amountDue! < 0
        ? context.createError({
            message: `Amount should be less than ${amountDue + value!}`,
            path: context.path,
          })
        : true;
    }),
  advanced: yup.boolean(),
  paymentDate: nullableDateYup(),
});

export const CarrierReceivedPaymentsSchema = yup.object().shape({
  referenceNumber: yup
    .string()
    .test('length', 'Cannot be longer than 50 characters', (val) =>
      !!val ? val.length <= 50 : true
    )
    .nullable(),
  amount: nullableNumberYup()
    .required('Amount is required.')
    .min(1, 'Rate should be greater than 0.')
    .test('amount test', (value, context) => {
      if (typeof context.originalValue !== 'string') {
        return context.schema;
      }
      const invoiceTotal: number =
        context.from[1].value.selectRecordList.reduce(
          (accumulator: number, object: LineItem) => {
            return accumulator + Number(object.amount);
          },
          0
        );

      const receivedPaymentsTotal: number =
        context.from[1].value.receivedPayments.reduce(
          (accumulator: number, object: { amount: number }) => {
            return accumulator + +Number(object.amount);
          },
          0
        );
      const amountDue = invoiceTotal - receivedPaymentsTotal;
      return amountDue! < 0
        ? context.createError({
            message: `Amount should be less than ${amountDue + value!}`,
            path: context.path,
          })
        : true;
    }),
  advanced: yup.boolean(),
  paymentDate: nullableDateYup(),
});

export const invoiceDetailsFormValidationSchema = yup
  .object()
  .shape(
    {
      lineItems: yup.array().of(invoiceLineItemsDialogSchema).compact(),
      receivedPayments: yup.array().of(receivedPaymentsSchema).compact(),
      customer: yup.object().nullable().required(),
      factoringChargePercent: nullableNumberYup().when(
        'factoringCompany',
        (factoringCompany) => {
          if (factoringCompany) {
            return nullableNumberYup()
              .min(0, 'Value must be between 0 and 100')
              .max(100, 'Value must be between 0 and 100');
          }
        }
      ),
      invoicedOn: yup
        .date()
        .nullable()
        .typeError('Please enter correct date in DD/MM/YYYY format'),
      notes: nullableStringYup().max(
        1024,
        'Cannot be longer than 1024 characters.'
      ),
      amountDue: nullableNumberYup().min(
        0,
        'Amount Due must be greater than 0'
      ),
      loadedMiles: nullableStringMaxValidation(50),
      emptyMiles: nullableStringMaxValidation(50),
      invoiceTerm: nullableStringYup().required('Field is mandatory'),
      invoiceTermValue: nullableNumberYup().when(
        ['invoiceTerm'],
        (invoiceTerm, schema) => {
          if (invoiceTerm === 'CUSTOM') {
            return schema
              .required('Field is mandatory')
              .min(1, 'Value must be between 1 and 366')
              .max(366, 'Value must be between 1 and 366');
          }
          return schema;
        }
      ),
      paidOn: nullableDateYup()
        .typeError('Please enter correct date in DD/MM/YYYY format')
        .when(
          ['paidOn', 'invoicedOn', 'loadStatus'],
          (paidOn, invoicedOn, loadStatus, schema) => {
            if (loadStatus === PAID) {
              return nullableDateYup().min(
                moment(invoicedOn),
                'Must be greater than invoiced on'
              );
            }
            return schema;
          }
        ),
    },
    [
      ['invoicedOn', 'paidOn'],
      ['paidOn', 'invoicedOn'],
      ['paidOn', 'paidOn'],
      ['invoicedOn', 'invoicedOn'],
    ]
  )
  .required();

export const bulkActionsConfig: { [key: string]: BulkActionConfigType } = {
  sendMultipleCompaniesReceiversError: {
    title: 'You cannot send group email for the selected invoices.',
    contentRenderer: () => (
      <>
        <Typography>
          There are missing email addresses for some customers.
        </Typography>
        <Typography>
          Please check data and try again, or use single email sending option
        </Typography>
      </>
    ),
    action: '',
  },
  sendMultipleCompanies: {
    title:
      'You can not send Invoices to different factoring accounts at once. Please select rows of the same factoring company',
    action: 'closeSendModalCb',
  },
  sendSelectedInvoiceCount: {
    title:
      'You can not send more than 25 invoices at once. Please select less number of rows.',
    action: 'closeSendModalCb',
  },
  sendInvalidInvoicesCount: {
    title: '',
    action: 'openSendModalCb',
  },
  sendValidInvoicesCount: {
    title: 'Are you sure you want to send those invoices to Factoring ?',
    action: 'openSendModalCb',
  },
  // refresh config
  refreshMultipleCompanies: {
    title:
      'You cannot refresh the invoice statuses from different factoring accounts at once. Please select rows of the same factoring company.',
    action: 'closeRefreshModalCb',
  },
  refreshSelectedInvoiceCount: {
    title:
      'You can not refresh the statuses of more than 25 invoices at once. Please select less number of rows.',
    action: 'closeRefreshModalCb',
  },
  refreshInvalidInvoicesCount: {
    title: '',
    action: 'handleBulkRefresh',
  },
  refreshValidInvoicesCount: {
    title:
      'Are you sure you want to refresh the invoice statuses from factoring?',
    action: 'handleBulkRefresh',
  },
  // submit config
  submitMultipleCompanies: {
    title:
      'You cannot submit the invoices to different factoring accounts at once. Please select rows of the same factoring company.',
    action: 'closeSubmitModalCb',
  },
  submitSelectedInvoiceCount: {
    title:
      'You cannot submit more than 25 invoices at once. Please select less number of rows.',
    action: 'closeSubmitModalCb',
  },
  submitInvalidInvoicesCount: {
    title: '',
    action: 'openSubmitModalCb',
  },
  submitValidInvoicesCount: {
    title:
      'Are you sure you want to submit the invoice for funding by factoring?',
    action: 'openSubmitModalCb',
  },
  // response config
  sendFactoringResponse: {
    title: '',
    action: '',
  },
  refreshFactoringResponse: {
    title:
      'Are you sure you want to submit the invoice for funding by factoring?',
    action: '',
  },
  submitFactoringResponse: {
    title:
      'Are you sure you want to submit the invoice for funding by factoring?',
    action: '',
  },
};

export const APEX_PROVIDER_ID = 1;
export const OTHER_PROVIDER_ID = 2;
export const TAFS_PROVIDER_ID = 3;
export const TRIUMPH_PROVIDER_ID = 4;
export const OTR_PROVIDER_ID = 5;
export const RTS_PROVIDER_ID = 6;

export const disablePanelActionsProviderIds = [
  OTHER_PROVIDER_ID,
  TRIUMPH_PROVIDER_ID,
  RTS_PROVIDER_ID,
  TAFS_PROVIDER_ID,
];

export const disablePanelActionsForSyncProviderIds = [
  OTHER_PROVIDER_ID,
  TRIUMPH_PROVIDER_ID,
  RTS_PROVIDER_ID,
];

export const disableBulkActionsProviderIds = [
  APEX_PROVIDER_ID,
  OTR_PROVIDER_ID,
];

export const disableBulkActionsForSendProviderIds = [
  APEX_PROVIDER_ID,
  TAFS_PROVIDER_ID,
  RTS_PROVIDER_ID,
  TRIUMPH_PROVIDER_ID,
];

export const refreshWarningTitle =
  'Are you sure you want to refresh the invoice status from factoring?';

export const revertLoadCompletedWarningTitle =
  'Are you sure you want to revert this load status to Completed?';

export const revertInvoicedToCompletedWarningSubTitle =
  'Please note, any changes made to invoice needs to be resynced with Factoring Company.';

export const revertToInvoicedWarningTitle =
  'Are you sure you want to revert this load status to Invoiced?';

export const multipleInvoiceEmailSendWarning =
  'NOTE: The Default Recipient is factoring company‘s email if the invoice is marked for factoring. Otherwise, primary contact of load customer will be detected as a recipient.';

export const loadStatusListMap: { [key: string]: string[] } = {
  invoiced: [INVOICED_LOAD_STATUS_NAME],
  completed: [COMPLETED_LOAD_STATUS_NAME, CANCELLED_LOAD_STATUS_NAME],
  paid: [PAID_LOAD_STATUS_NAME],
  ready: [READY_TO_INVOICE],
};

export const invoiceDetailsCreatFormValidationSchema = yup
  .object()
  .shape({
    lineItems: yup.array().of(invoiceLineItemsDialogSchema).compact(),
    customer: yup.object().nullable().required('Customer is a required field'),
    // terminal: yup.object().nullable().required('Terminal is a required field'),
    notes: nullableStringYup().max(
      1024,
      'Cannot be longer than 1024 characters.'
    ),
    loadedMiles: nullableStringMaxValidation(50),
    amountDue: nullableNumberYup().min(0, 'Amount Due must be greater than 0'),
    invoiceTerm: nullableStringYup().required('Field is mandatory'),
    invoiceTermValue: nullableNumberYup().when(
      ['invoiceTerm'],
      (invoiceTerm, schema) => {
        if (invoiceTerm === 'CUSTOM') {
          return schema
            .required('Field is mandatory')
            .min(1, 'Value must be between 1 and 366')
            .max(366, 'Value must be between 1 and 366');
        }
        return schema;
      }
    ),

    invoiceReferenceId: nullableStringMaxValidation(50),
    // invoiceTerm: nullableStringYup().required('Net Term is a required field'),
  })
  .required();

export const invoiceDetailswithTerminalCreatFormValidationSchema = yup
  .object()
  .shape({
    lineItems: yup.array().of(invoiceLineItemsDialogSchema).compact(),
    customer: yup.object().nullable().required('Customer is a required field'),
    terminal: yup.object().nullable().required('Terminal is a required field'),
    notes: nullableStringYup().max(
      1024,
      'Cannot be longer than 1024 characters.'
    ),
    amountDue: nullableNumberYup().min(0, 'Amount Due must be greater than 0'),
    invoiceTerm: nullableStringYup().required('Field is mandatory'),
    invoiceTermValue: nullableNumberYup().when(
      ['invoiceTerm'],
      (invoiceTerm, schema) => {
        if (invoiceTerm === 'CUSTOM') {
          return schema
            .required('Field is mandatory')
            .min(1, 'Value must be between 1 and 366')
            .max(366, 'Value must be between 1 and 366');
        }
        return schema;
      }
    ),

    invoiceReferenceId: nullableStringMaxValidation(50),
    // invoiceTerm: nullableStringYup().required('Net Term is a required field'),
  })
  .required();
