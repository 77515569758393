import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button } from '@components/ui-kit/button';
import { Divider } from '@components/ui-kit/divider';
import { IconButton } from '@components/ui-kit/icon-button';
import { MenuItem } from '@components/ui-kit/menu-item';
import { Select } from '@components/ui-kit/select';
import { TextField } from '@components/ui-kit/text-field';
import { Typography } from '@components/ui-kit/typography';
import {
  Add,
  Edit,
  EditNote,
  HighlightOff,
  Map,
  MoveDown,
  MoveUp,
  Person,
  Place,
  Tag,
} from '@mui/icons-material';
import MoreVert from '@mui/icons-material/MoreVert';
import {
  Autocomplete,
  Box,
  FormControl,
  InputAdornment,
  ListItemIcon,
  Menu,
  Stack,
  StackProps,
  Tooltip,
} from '@mui/material';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { BusinessProps } from '../../utils/stops';

import { DatePicker } from '@components/ui-kit/date-picker';
import { TimePicker } from '@components/ui-kit/time-picker';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import { useInfiniteQuery, useQuery } from 'react-query';
import { locationService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { ILocationSearch } from '../../../../../common/Ui/AddressField/constants';
import AppointmentConfirmation from '../../../../../components_v2/generic/appointment-confirmation';
import { countryCodeOptions } from '../../../../../constants/contacts';
import {
  ContactSummaryCustomer,
  GetTimezoneByCoordsRequest,
  GetTimezoneByCoordsResponse,
  LocationSummaryDTO,
} from '../../../../../models';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { AppointmentConfirmationOptions } from '../../constants/fieldOptions';
import {
  StopLocationInitialValue,
  stopContactInitialValue,
} from '../../constants/fieldValues';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { initialState } from '../../data.config';
import { StopTypeEnum } from '../../schema/stops';
import { createLoadService } from '../../services/createLoad.service';
import { customerFormService } from '../../services/customerForm.service';
import { stopFormService } from '../../services/stopForm.service';
import { timezoneContainer, timezoneTextStyle } from '../../style';
import { AppointmentConfirmationType, ILoadStop } from '../../types/requests';
import { ActivityType } from '../../types/types';
import { ErrorText } from '../ErrorText';
import { LocationSummary } from './location-summary';
import { LocationContacttDetailsForm } from './locationContact';

interface StopProps {
  remove: (index: number) => void;
  add: (index: number) => void;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  enableMap: (index: number) => void;
  index: number;
  stops: Array<{ id: string }>;
  businesses?: BusinessProps;
  onLocationChanged?: () => void;
}

interface LocationSearchDTO extends ILocationSearch {
  locationDisplayName?: string;
  Coords?: { Lat?: number; Lon?: number };
  timezone?: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

export const Stop: React.FC<StopProps> = ({
  remove,
  index,
  add,
  moveUp,
  moveDown,
  enableMap,
  stops,
  onLocationChanged,
}) => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();
  const stop = watch(`stops.${index}`);
  const isEmptyTrip = getValues(`isEmptyTrip`);
  const { getCompanyPreferences } = useRootStore();

  const loadHiddenFields = watch('hiddenFields.stops');
  const stopsRequiredFields = getValues('requiredFields.stops');

  const nextStop = () => {
    return getValues('stops')?.[index + 1];
  };
  const prevStop = () => {
    return getValues('stops')?.[index - 1];
  };

  const isFirstPickup = (index: number) => {
    const stopInfo = getValues('stops');
    return (
      index == stopInfo.findIndex((stop) => stop.type === StopTypeEnum.PICKUP)
    );
  };

  const isLastDropOff = (index: number) => {
    let lastIndex = -1;
    const stopInfo = getValues('stops');
    stopInfo.forEach((stop, index) => {
      if (stop?.type === StopTypeEnum.DROPOFF) {
        lastIndex = index;
      }
    });
    return lastIndex === index;
  };

  const Timezone =
    stop?.timezone ||
    stop?.location?.timezone ||
    getCompanyPreferences?.timezone ||
    undefined;
  const defaultTimezone = !!moment.tz.zone(Timezone) ? Timezone : undefined;
  const [isHandlingTimeVisible, setIsHandlingTimeVisible] = useState(false);
  const toggleIsHandlingTimeVisible = () => {
    setIsHandlingTimeVisible(!isHandlingTimeVisible);
  };

  const [isDropOffHandlingTimeVisible, setIsDropOffHandlingTimeVisible] =
    useState<boolean>(false);
  const toggleIsDropOffHandlingTimeVisible = () => {
    setIsDropOffHandlingTimeVisible(!isDropOffHandlingTimeVisible);
  };

  const [businessNameInput, setBusinessNameInput] = useState<string>('');
  const [locationNameInput, setLocationNameInput] = useState<string>('');
  const [addressInput, setAddressInput] = useState<string>('');
  const toggleIsDriverAssist = () => {
    setValue(
      `stops.${index}.isDriverAssistRequired`,
      !stop?.isDriverAssistRequired
    );
  };
  const toggleIsDropTrailer = () => {
    setValue(`stops.${index}.dropTrailer`, !stop?.dropTrailer);
  };
  const toggleIsPreLoaded = () => {
    setValue(`stops.${index}.preLoaded`, !stop?.preLoaded);
  };
  const toggleIsRelayDropOffDriverAssistRequired = () => {
    setValue(
      `stops.${index}.isRelayDropOffDriverAssistRequired`,
      !stop?.isRelayDropOffDriverAssistRequired
    );
  };
  const [locationContacts, setLocationContacts] = useState<
    ContactSummaryCustomer[]
  >([]);
  const [locationContactInput, setLocationContactInput] = useState<string>('');
  const [addNewLocationContact, setAddNewLocationContact] =
    useState<ContactSummaryCustomer | null>();
  const [isContactFormExpanded, setIsContactFormExpanded] =
    useState<boolean>(false);
  const toggleIsContactFormExpanded = () =>
    setIsContactFormExpanded(!isContactFormExpanded);
  const [isRefNumberExpanded, setIsRefNumberExpanded] =
    useState<boolean>(false);
  const toggleIsRefNumberExpanded = () =>
    setIsRefNumberExpanded(!isRefNumberExpanded);
  const [isAddressFormExpanded, setIsAddressFormExpanded] = useState(false);
  const toggleIsAddressFormExpanded = (state?: boolean) => {
    state != undefined
      ? setIsAddressFormExpanded(state)
      : setIsAddressFormExpanded(!isAddressFormExpanded);
  };
  const [showLocationSummary, setShowLocationSummary] =
    useState<boolean>(false);
  const [isStopFormExpanded, setIsStopFormExpanded] = useState<boolean>(false);
  const toggleIsStopFormExpanded = (val?: boolean) => {
    setIsContactFormExpanded(val ?? !isContactFormExpanded);
    setIsAddressFormExpanded(val ?? !isAddressFormExpanded);
    setIsRefNumberExpanded(val ?? !isRefNumberExpanded);
  };

  useEffect(() => {
    if (isAddressFormExpanded && isContactFormExpanded && isRefNumberExpanded) {
      setIsStopFormExpanded(true);
    } else {
      setIsStopFormExpanded(false);
    }
  }, [isAddressFormExpanded, isContactFormExpanded, isRefNumberExpanded]);

  const handleEditAddress = () => {
    toggleIsAddressFormExpanded();
    handleMoreActionsClose();
  };
  const handleEditContact = () => {
    toggleIsContactFormExpanded();
    handleMoreActionsClose();
  };
  const handleEditRefNumber = () => {
    toggleIsRefNumberExpanded();
    handleMoreActionsClose();
  };

  const toggleIsMapViewEnabled = (state?: boolean) => {
    enableMap(index);
  };

  const handleViewMap = () => {
    toggleIsMapViewEnabled(true);
    handleMoreActionsClose();
  };

  const confirmationCallPopupState = usePopupState({
    variant: 'popover',
    popupId: 'confirmationCallPopup',
  });

  const relayDropConfirmationCallPopupState = usePopupState({
    variant: 'popover',
    popupId: 'dropOffconfirmationCallPopup',
  });
  const handleChangeConfirmationCall = (state: AppointmentConfirmationType) => {
    confirmationCallPopupState.close();
    relayDropConfirmationCallPopupState?.close?.();
  };

  const isStopTypeRelay = useMemo(() => stop?.type === 'RELAY', [stop?.type]);

  const getConfirmationAppointmentIcon = (
    type: AppointmentConfirmationType
  ) => {
    return <AppointmentConfirmation status={type} />;
  };

  const confirmationCallStateIcon = getConfirmationAppointmentIcon(
    stop?.appointmentConfirmation
  );
  const relayDropConfirmationCallStateIcon = getConfirmationAppointmentIcon(
    stop?.dropOffAppointmentConfirmation
  );
  const isEndTimeVisible = stop?.isEndTimeVisible;
  const isRelayDropOffEndTimeVisible = stop?.isRelayDropOffEndTimeVisible;
  const toggleIsEndTimeVisible = () => {
    setValue(`stops.${index}.isEndTimeVisible`, !stop?.isEndTimeVisible);
  };
  const setStopApptAsFixedAppointment = () => {
    setValue(`stops.${index}.appointmentEndDate`, stop?.appointmentStartDate);
    toggleIsEndTimeVisible();
  };
  const setRelayDropOffApptAsFixedAppointment = () => {
    setValue(
      `stops.${index}.relayDropOffAppointmentEndDate`,
      stop?.relayDropOffAppointmentStartDate
    );
    toggleIsRelayDropOffTimeVisible();
  };
  useState<boolean>(false);
  const toggleIsRelayDropOffTimeVisible = () => {
    setValue(
      `stops.${index}.isRelayDropOffEndTimeVisible`,
      !stop?.isRelayDropOffEndTimeVisible
    );
  };
  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const toggleIsNotesVisible = () => {
    setIsNotesVisible(!isNotesVisible);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMoreActionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setAnchorEl(null);
  };

  const isBusinessPageRef = useRef(false);
  const isLocationPageRef = useRef(false);

  const fetchBusinesses = ({ pageParam = 1 }) =>
    stopFormService.fetchBusinessNameAndLocations({
      searchText: businessNameInput,
      pageNumber: pageParam,
    });
  const { data, fetchNextPage } = useInfiniteQuery(
    [QUERY_KEYS.businessData, { searchText: businessNameInput }],
    fetchBusinesses,
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (_, pages) => {
        return (pages?.length || 0) + 1;
      },
    }
  );
  const businessData = useMemo(() => {
    const bData: any = [];
    data?.pages?.forEach?.((e) => {
      if (e) {
        bData?.push(...(e ?? []));
      }
    });
    return bData || [];
  }, [data?.pages]);

  const fetchLocations = ({ pageParam = 1 }) => {
    if (!isAddressFormExpanded) return [];
    return stopFormService.fetchBusinessNames({
      searchText: locationNameInput,
      pageNumber: pageParam,
    });
  };

  const { data: locations, fetchNextPage: fetchNextLocations } =
    useInfiniteQuery(
      [QUERY_KEYS.locationList, { locationNameInput }],
      fetchLocations,
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (_, pages) => {
          return (pages?.length || 0) + 1;
        },
      }
    );
  const locationList = useMemo(() => {
    const lData: any = [];
    locations?.pages?.forEach?.((e) => {
      if (e) {
        lData?.push(...(e ?? []));
      }
    });
    return lData || [];
  }, [locations?.pages]);

  const handleScrollLocationList = (event: any) => {
    if (isLocationPageRef?.current) return;
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      fetchNextLocations();
    }
  };

  const handleScrollBusiness = (event: any) => {
    if (isBusinessPageRef?.current) return;
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      fetchNextPage();
    }
  };
  const addressList = useQuery<any>(
    [QUERY_KEYS.locationList, { addressInput }],
    () =>
      stopFormService.fetchAddressByTextList({
        searchText: addressInput,
        pageNumber: 1,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleChangeLocationInEditMode = (data: LocationSearchDTO) => {
    if (stop?.location?.locationId) {
      setValue(`stops.${index}.location`, initialState.stops[0].location);
    }
    handleOnChangeBusinessLocation(data);
  };

  const resetContactDetails = () => {
    if (!stop?.isContactDetailsUpdatedManually)
      setValue(`stops.${index}.contact`, {
        contactName: '',
        countryCode: '',
        phone: '',
        extension: '',
        email: '',
      });
  };

  const handleLocationNameChange = (data: LocationSummaryDTO) => {
    //if business/location is cleared, no need to update other values
    if (!data) {
      resetContactDetails();
      resetNotes();
      return;
    }
    handleOnChangeBusinessLocation(data);
  };
  const resetNotes = () => {
    if (!stop?.isNotesUpdatedManually) {
      setValue(`stops.${index}.notes`, '');
    }
  };
  const handleOnChangeBusinessLocation = async (
    data: LocationSummaryDTO | LocationSearchDTO
  ) => {
    setLocationContacts((data as LocationSummaryDTO)?.contacts ?? []);
    // no record exist
    if (!data) {
      clearLocationDetails();
      resetContactDetails();
      resetNotes();
      return;
    }
    // address from trimble
    if (!(data as LocationSummaryDTO)?.id) {
      const loc = data as LocationSearchDTO;
      const locationDetails = {
        locationName: stop?.location?.locationId
          ? ''
          : stop?.location?.locationName,
        businessName: stop?.location?.locationId
          ? ''
          : stop?.location?.businessName,
        locationId: null,
        address: loc?.locationDisplayName ?? '',
        city: loc?.Address?.City,
        state: loc?.Address?.State,
        zipcode: loc?.Address?.Zip,
        country: loc?.Address?.Country,
        streetAddress: loc?.Address?.StreetAddress,
        center: { lat: loc?.Coords?.Lat, lng: loc?.Coords?.Lon },
        isExternal: true,
      };
      setValue(`stops.${index}.location`, locationDetails);
      resetNotes();
      resetContactDetails();
      // fetch and update timezone name
      const timezoneData: GetTimezoneByCoordsResponse | ServiceError | null =
        await locationService.getTimezoneByCoords(
          new GetTimezoneByCoordsRequest({
            latitude: data?.Coords?.Lat,
            longitude: data?.Coords?.Lon,
          })
        );
      if (!(!timezoneData || timezoneData instanceof ServiceError)) {
        setValue(`stops.${index}.location.timezone`, timezoneData.zoneName);
      } else {
        setValue(`stops.${index}.location.timezone`, loc?.TimeZone);
      }
    } else {
      // when location/business is selected from existing records
      data = data as LocationSummaryDTO;
      const locationDetails = {
        locationId: data?.id,
        locationName: data?.locationName,
        businessName: data?.locationName,
        address: data?.addressDTO?.address ?? '',
        city: data?.addressDTO?.city,
        state: data?.addressDTO?.state,
        zipcode: data?.addressDTO?.zipcode,
        streetAddress: data?.addressDTO?.streetAddress,
        center: data?.addressDTO?.center,
        country: data?.addressDTO?.country,
        isExternal: false,
        timezone: data?.timezone,
      };
      setValue(`stops.${index}.location`, locationDetails);
      if (data?.avgHandlingTime) {
        if (!stop?.isHandlingTimeUpdatedManually) {
          setValue(
            `stops.${index}.estimatedActivityDuration`,
            data?.avgHandlingTime
          );
        }
        if (
          stop?.type === 'RELAY' &&
          !stop?.isDropOffHandlingTimeUpdatedManually
        )
          setValue(
            `stops.${index}.relayDropOffEstimatedActivityDuration`,
            data?.avgHandlingTime
          );
      }
      if (!stop?.isContactDetailsUpdatedManually) {
        const primaryContact = data?.contacts?.find((e) => e?.isPrimary);
        if (primaryContact && !stop?.isContactDetailsUpdatedManually) {
          const contactDetails = {
            id: primaryContact?.id,
            contactName: primaryContact?.fullName ?? '',
            countryCode: primaryContact?.phoneData?.countryCode ?? '',
            phone: primaryContact?.phoneData?.phone ?? '',
            extension: primaryContact?.phoneData?.extension ?? '',
            email: primaryContact?.email ?? '',
          };
          setValue(`stops.${index}.contact`, contactDetails);
        } else {
          resetContactDetails();
        }
      }
      if (!stop?.isRefNumberUpdatedManually)
        setValue(`stops.${index}.referenceNumber`, data?.referenceNumber);
      if (!stop?.isNotesUpdatedManually)
        setValue(`stops.${index}.notes`, data?.notes);
    }
    if (errors.stops?.[index]?.location?.address) trigger?.();
    onLocationChanged?.();
    if (stop?.type === 'RELAY') {
      setValue(`stops.${index}.contact`, {
        contactName: '',
        countryCode: '',
        phone: '',
        extension: '',
        email: '',
      });
      setValue(`stops.${index}.referenceNumber`, '');
      setValue(`stops.${index}.notes`, '');
    }
  };

  const handleOnChangeLocationContact = (contact: ContactSummaryCustomer) => {
    if (!contact) return;
    if (!contact?.id) {
      setAddNewLocationContact({
        fullName: contact?.value!,
      } as ContactSummaryCustomer);
      setLocationContactInput('');
      return;
    }
    const contactDetails = {
      id: contact?.id ?? '',
      contactName: contact?.fullName ?? '',
      countryCode: contact?.phoneData?.countryCode ?? '',
      phone: contact?.phoneData?.phone ?? '',
      extension: contact?.phoneData?.extension ?? '',
      email: contact?.email ?? '',
    };
    setValue(`stops.${index}.contact`, contactDetails);
  };

  const getLocationContactOptions = useMemo(() => {
    if (!stop?.location?.locationId) return locationContacts;
    return customerFormService.getCustomerContactDropdownOptions({
      contacts: locationContacts,
      searchText: locationContactInput,
    });
  }, [locationContactInput, locationContacts, stop?.location?.locationId]);

  const clearLocationDetails = () => {
    setValue(`stops.${index}.location.locationName`, '');
    setValue(`stops.${index}.location.locationId`, null);
  };

  const removeAllLocationAndContactFields = (index: number) => {
    setValue(`stops.${index}.location`, StopLocationInitialValue);
    if (!stop?.isContactDetailsUpdatedManually) {
      setValue(`stops.${index}.contact`, stopContactInitialValue);
    }
    if (!stop?.isNotesUpdatedManually) {
      setValue(`stops.${index}.notes`, '');
    }
  };

  const handleClearSelectedLocation = () => {
    removeAllLocationAndContactFields(index);
    setIsAddressFormExpanded(false);
    setShowLocationSummary(false);
    setBusinessNameInput('');
  };

  useEffect(() => {
    if (!errors.stops?.[index]?.notes) return;
    setIsNotesVisible(true);
  }, [errors.stops?.[index]?.notes?.message]);

  useEffect(() => {
    if (
      stop?.location?.locationName ||
      stop?.location?.address ||
      stop?.location?.businessName
    )
      setShowLocationSummary(true);
    else {
      setIsAddressFormExpanded(false);
      setShowLocationSummary(false);
    }
  }, [stop?.location?.locationName, stop?.location?.address]);

  useEffect(() => {
    if (isEndTimeVisible) return;
    setValue(`stops.${index}.appointmentEndDate`, stop?.appointmentStartDate);
    setValue(`stops.${index}.appointmentEndTime`, stop?.appointmentStartTime);
  }, [
    isEndTimeVisible,
    stop?.appointmentStartDate,
    stop?.appointmentStartTime,
  ]);
  useEffect(() => {
    if (isRelayDropOffEndTimeVisible) return;
    setValue(
      `stops.${index}.relayDropOffAppointmentEndDate`,
      stop?.relayDropOffAppointmentStartDate
    );
    setValue(
      `stops.${index}.relayDropOffAppointmentEndTime`,
      stop?.relayDropOffAppointmentStartTime
    );
  }, [
    isRelayDropOffEndTimeVisible,
    stop?.relayDropOffAppointmentStartDate,
    stop?.relayDropOffAppointmentStartTime,
  ]);
  const isAdditionalDetailsError: boolean =
    errors.stops?.[index]?.contact ||
    errors.stops?.[index]?.referenceNumber ||
    (errors.stops?.[index]?.location?.address &&
      !!stop?.location?.locationName);
  useEffect(() => {
    if (isAdditionalDetailsError && !isStopFormExpanded)
      toggleIsStopFormExpanded(true);
  }, [JSON.stringify(errors?.stops?.[index])]);

  const isStopMoveUpAllowed = (index: number, type: ActivityType): boolean => {
    if (
      index == 0 ||
      (index === 1 &&
        type !== StopTypeEnum.PICKUP &&
        type !== StopTypeEnum.TRIP_STOP)
    )
      return true;
    if (
      index === stops?.length - 1 &&
      prevStop()?.type !== StopTypeEnum?.TRIP_STOP &&
      prevStop()?.type !== StopTypeEnum?.DROPOFF
    )
      return true;
    // if current stop is relay, check if its previous stops contains load stop or not
    if (
      type === StopTypeEnum.RELAY &&
      !stopFormService.ifLoadStopExistInPast(
        index - 1,
        getValues('stops').map((s: ILoadStop) => s.type)
      )
    )
      return true;
    // if previous stop is relay, check if there is a load stop starting from next of the current stop
    if (
      prevStop()?.type === StopTypeEnum.RELAY &&
      !stopFormService.ifLoadStopExistInFuture(
        index,
        getValues('stops').map((s: ILoadStop) => s.type)
      )
    )
      return true;
    return false;
  };

  const isStopMoveDownAllowed = (
    index: number,
    type: ActivityType
  ): boolean => {
    if (
      index === stops.length - 1 ||
      (index === stops?.length - 2 &&
        type !== StopTypeEnum.TRIP_STOP &&
        type !== StopTypeEnum.DROPOFF)
    )
      return true;
    if (
      index === 0 &&
      nextStop()?.type !== StopTypeEnum.TRIP_STOP &&
      nextStop().type !== StopTypeEnum.PICKUP
    )
      return true;
    // if current stop is relay, check if its future stops contains load stop or not
    if (
      type === StopTypeEnum.RELAY &&
      !stopFormService.ifLoadStopExistInFuture(
        index + 1,
        getValues('stops').map((s: ILoadStop) => s.type)
      )
    )
      return true;
    // if next stop is relay, check if there is a load stop in the past of the current stop
    if (
      nextStop()?.type === StopTypeEnum.RELAY &&
      !stopFormService.ifLoadStopExistInPast(
        index,
        getValues('stops').map((s: ILoadStop) => s.type)
      )
    )
      return true;
    return false;
  };

  const renderStartDatePickerTemplate = ({
    fieldName,
    value,
    timeFieldName,
  }: {
    fieldName?: string;
    value?: string;
    timeFieldName?: string;
  }) => (
    <Controller
      name={fieldName ?? `stops.${index}.appointmentStartDate`}
      render={({ field }) => (
        <DatePicker
          sx={{ width: '180px' }}
          value={value ? dayjs(value) : undefined}
          onChange={(v) => {
            setValue(`stops.${index}.isManualStartDateSelected`, true);
            field.onChange(v?.format?.());
            // if appt time is not entered, set 00:00 as default
            if (timeFieldName && v?.isValid?.() && !getValues(timeFieldName)) {
              setValue(
                timeFieldName,
                moment(v)
                  ?.clone?.()
                  ?.startOf('day')
                  ?.format('YYYY-MM-DDTHH:mm:ss')
              );
            }
          }}
        />
      )}
    />
  );
  const renderEndDatePickerTemplate = ({
    fieldName,
    value,
    timeFieldName,
  }: {
    fieldName?: string;
    value?: string;
    timeFieldName?: string;
  }) => {
    return (
      <>
        <Controller
          name={fieldName ?? `stops.${index}.appointmentEndDate`}
          render={({ field }) => (
            <DatePicker
              sx={{
                width: '180px',
                display: loadHiddenFields?.appointmentEndDate
                  ? 'none'
                  : undefined,
              }}
              value={value ? dayjs(value) : undefined}
              onChange={(v) => {
                setValue(`stops.${index}.isManualEndDateSelected`, true);
                field.onChange(v?.format?.());
                // if appt time is not entered, set 00:00 as default
                if (
                  timeFieldName &&
                  v?.isValid?.() &&
                  !getValues(timeFieldName)
                ) {
                  setValue(
                    timeFieldName,
                    moment(v)
                      ?.clone?.()
                      ?.startOf('day')
                      ?.format('YYYY-MM-DDTHH:mm:ss')
                  );
                }
              }}
            />
          )}
        />
      </>
    );
  };

  const renderStartTimePickerTemplate = ({
    fieldName,
    value,
  }: {
    fieldName?: string;
    value?: string;
  }) => {
    return (
      <Box sx={timezoneContainer}>
        <Controller
          name={fieldName ?? `stops.${index}.appointmentStartTime`}
          render={({ field }) => (
            <TimePicker
              ampm={false}
              sx={{ width: '120px' }}
              value={field.value ? dayjs(field.value) : undefined}
              onChange={(v) => {
                field.onChange(v?.format?.());
              }}
              // timezone={defaultTimezone}
            />
          )}
        />
        {stop?.location?.timezone && (
          <Typography variant="body1" fontSize={10} sx={timezoneTextStyle}>
            {stop?.location?.timezone}
          </Typography>
        )}
      </Box>
    );
  };
  const renderEndTimePickerTemplate = ({
    fieldName,
    value,
  }: {
    fieldName?: string;
    value?: string;
  }) => {
    return (
      <Box
        sx={{
          ...timezoneContainer,
          display: loadHiddenFields?.appointmentEndDate ? 'none' : undefined,
        }}
      >
        <Controller
          name={fieldName ?? `stops.${index}.appointmentEndTime`}
          render={({ field }) => (
            <TimePicker
              ampm={false}
              sx={{
                width: '120px',
              }}
              value={field.value ? dayjs(field.value) : undefined}
              onChange={(v) => {
                field.onChange(v?.format?.());
              }}
              // timezone={defaultTimezone}
            />
          )}
        />
        {stop?.location?.timezone && (
          <Typography variant="body1" fontSize={10} sx={timezoneTextStyle}>
            {stop?.location?.timezone}
          </Typography>
        )}
      </Box>
    );
  };

  const getLocationContactsOptionLabel = useCallback(
    (value: any) => {
      if (typeof value === 'string') return value;
      return createLoadService.getAutocompleteOptionLabel({
        list: locationContacts,
        value,
        fieldName: 'fullName',
        entityToCompare: 'fullName',
        returnDefaultValue: false,
      });
    },
    [locationContacts]
  );

  const handleAddNewContact = (newContact: ContactSummaryCustomer) => {
    setLocationContacts((prev) => [...prev, newContact]);
    const contactDetails = {
      id: newContact?.id,
      contactName: newContact?.fullName,
      countryCode: newContact?.phoneData?.countryCode,
      phone: newContact?.phoneData?.phone,
      extension: newContact?.phoneData?.extension,
      email: newContact?.email,
    };
    setValue(`stops.${index}.contact`, contactDetails);
    setAddNewLocationContact(null);
  };

  const getRevenueShare = (revenue: number) => {
    return revenue > 100 ? 0 : 100 - Number(revenue);
  };
  return (
    <>
      <Stack direction="row" gap={0} sx={{ mt: index == 0 ? '5px' : '0' }}>
        <Stack
          direction="column"
          alignItems="center"
          sx={{ width: '50px' }}
          gap="2px"
        >
          <Box
            sx={{
              backgroundColor: index == 0 ? 'transparent' : 'rgba(0 0 0 / 45%)',
              width: '2px',
              height: '48px',
            }}
          ></Box>
          <Place sx={{ fontSize: 40 }}></Place>
          <Box
            sx={{
              backgroundColor:
                index == stops.length - 1 ? 'transparent' : 'rgba(0 0 0 / 45%)',
              flexGrow: 1,
              width: '2px',
              height: '2px',
            }}
          ></Box>
        </Stack>
        <Stack gap={1} sx={{ flexGrow: 1, width: '250px' }}>
          <Stack direction="row" gap={1}>
            <FormControl size="small" sx={{ marginRight: 'auto' }}>
              <Controller
                name={`stops.${index}.type`}
                render={({ field }) => (
                  <Select
                    {...field}
                    isPlain
                    onChange={(event) => {
                      field.onChange(event.target?.value);
                      onLocationChanged?.();
                    }}
                  >
                    {stopFormService
                      .getStopTypeOptions({
                        stopIndex: index,
                        totalStopsCount: stops.length,
                        types: getValues('stops').map((s: ILoadStop) => s.type),
                        isEmptyTrip,
                      })
                      .map((option, index) => (
                        <MenuItem key={index} value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
            {stop?.type !== 'RELAY' && (
              <>
                {isHandlingTimeVisible ||
                errors?.stops?.[index]?.estimatedActivityDuration ? (
                  <Controller
                    name={`stops.${index}.estimatedActivityDuration`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Handling Time (min)"
                        onBlur={toggleIsHandlingTimeVisible}
                        autoFocus
                        sx={{ width: '155px' }}
                        onChange={(event: any) => {
                          setValue(
                            `stops.${index}.isHandlingTimeUpdatedManually`,
                            true
                          );
                          field.onChange(event?.target?.value);
                        }}
                        error={
                          errors?.stops?.[index]?.estimatedActivityDuration
                        }
                        helperText={
                          errors?.stops?.[index]?.estimatedActivityDuration
                            ?.message
                        }
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-24]*',
                        }}
                      />
                    )}
                  />
                ) : (
                  <>
                    {isFirstPickup(index) && (
                      <Button
                        variant="text"
                        onClick={() => toggleIsPreLoaded()}
                      >
                        {stop?.preLoaded ? <b>Pre-Loaded</b> : 'Live Loading'}
                      </Button>
                    )}
                    {isLastDropOff(index) && (
                      <Button
                        variant="text"
                        onClick={() => toggleIsDropTrailer()}
                      >
                        {stop?.dropTrailer ? (
                          <b>Drop Trailer</b>
                        ) : (
                          'Live Unloading'
                        )}
                      </Button>
                    )}
                    <Button
                      variant="text"
                      onClick={() => toggleIsHandlingTimeVisible()}
                    >
                      <Stack
                        direction="row"
                        gap="3px"
                        display={
                          loadHiddenFields?.estimatedActivityDuration
                            ? 'none'
                            : undefined
                        }
                      >
                        <Box sx={{ mr: '4px' }}>Handling Time:</Box>
                        {Math.floor(stop?.estimatedActivityDuration / 60) >
                          0 && (
                          <Box>
                            <b>
                              {Math.floor(stop?.estimatedActivityDuration / 60)}
                            </b>
                            h
                          </Box>
                        )}
                        {stop?.estimatedActivityDuration % 60 > 0 && (
                          <Box>
                            <b> {stop?.estimatedActivityDuration % 60}</b>min
                          </Box>
                        )}
                      </Stack>
                    </Button>
                  </>
                )}
                <Button
                  variant="text"
                  onClick={() => toggleIsDriverAssist()}
                  sx={{
                    display: loadHiddenFields?.isDriverAssistRequired
                      ? 'none'
                      : undefined,
                  }}
                >
                  <Box sx={{ mr: '3px' }}>Driver Assist:</Box>
                  {stop?.isDriverAssistRequired ? <b>Yes</b> : 'No'}
                </Button>
              </>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ flexShrink: 0 }}>
            <Stack
              direction="column"
              // gap={2}
              sx={{
                flexGrow: 1,
                width: '200px',
                '&:not(:hover) .business-hoverActions': { display: 'none' },
              }}
            >
              {showLocationSummary ? (
                <LocationSummary
                  stopIndex={index}
                  onMapView={handleViewMap}
                  onClose={handleClearSelectedLocation}
                  onEdit={toggleIsStopFormExpanded}
                  showCollapse={isStopFormExpanded}
                  onCollapse={() => {
                    if (isAdditionalDetailsError) return;
                    toggleIsStopFormExpanded(false);
                  }}
                />
              ) : (
                <>
                  <Controller
                    name={`stops.${index}.location.locationName`}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={businessData}
                        getOptionLabel={(option) => {
                          return option?.locationDisplayName ?? option;
                        }}
                        filterOptions={(options, state) => options}
                        value={stop?.location?.locationName}
                        clearOnBlur
                        handleHomeEndKeys
                        id="createLoad-stop-businessname"
                        onInputChange={(_, v) => {
                          setBusinessNameInput(v);
                        }}
                        ListboxProps={{
                          onScroll: (data: any) => {
                            handleScrollBusiness(data);
                          },
                        }}
                        onChange={(_, value) => {
                          handleOnChangeBusinessLocation(value);
                          field.onChange(
                            (value as LocationSummaryDTO)
                              ?.locationDisplayName ?? ''
                          );
                        }}
                        renderOption={(props, option) => {
                          return (
                            <Stack
                              p={1}
                              {...(props as StackProps)}
                              alignItems={'flex-start!important'}
                            >
                              <Typography
                                color={'textPrimary'}
                                fontWeight={option?.id ? 500 : 400}
                              >
                                {option?.locationDisplayName}
                              </Typography>
                              {option?.id && (
                                <Typography
                                  variant="body3"
                                  color={'textSecondary'}
                                >
                                  {option?.addressDTO?.address}
                                </Typography>
                              )}
                            </Stack>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={stopsRequiredFields?.locationName}
                            autoFocus={index === 0}
                            label="Search business name or address"
                            error={errors?.stops?.[index]?.location?.address}
                            helperText={
                              errors?.stops?.[index]?.location?.address?.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </>
              )}
            </Stack>

            <Tooltip title="More">
              <IconButton
                onClick={handleMoreActionsClick}
                size="small"
                sx={{ ml: '10px', flexShrink: 0 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreVert fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleMoreActionsClose}
              onClick={handleMoreActionsClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                disabled={isStopMoveUpAllowed(index, stop.type)}
                onClick={() => moveUp(index)}
              >
                <ListItemIcon>
                  <MoveUp fontSize="small" />
                </ListItemIcon>
                Move Up
              </MenuItem>
              <MenuItem
                disabled={isStopMoveDownAllowed(index, stop.type)}
                onClick={() => moveDown(index)}
              >
                <ListItemIcon>
                  <MoveDown fontSize="small" />
                </ListItemIcon>
                Move Down
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleViewMap}>
                <ListItemIcon>
                  <Map fontSize="small" />
                </ListItemIcon>
                View/Edit Map
              </MenuItem>
              <MenuItem
                onClick={handleEditAddress}
                sx={{
                  display: loadHiddenFields?.editAddress ? 'none' : undefined,
                }}
              >
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                Edit Address
              </MenuItem>
              {!isStopTypeRelay && (
                <MenuItem
                  onClick={handleEditContact}
                  sx={{
                    display: loadHiddenFields?.editContact ? 'none' : undefined,
                  }}
                >
                  <ListItemIcon>
                    <Person fontSize="small" />
                  </ListItemIcon>
                  Edit Contact
                </MenuItem>
              )}
              {!isStopTypeRelay && (
                <MenuItem
                  onClick={handleEditRefNumber}
                  sx={{
                    display: loadHiddenFields?.editReferenceNumber
                      ? 'none'
                      : undefined,
                  }}
                >
                  <ListItemIcon>
                    <Tag fontSize="small" />
                  </ListItemIcon>
                  Edit Reference Number
                </MenuItem>
              )}
              {stops.length > 2 && (
                <MenuItem onClick={() => remove(index)}>
                  <ListItemIcon>
                    <HighlightOff fontSize="small" />
                  </ListItemIcon>
                  Remove
                </MenuItem>
              )}
            </Menu>
          </Stack>

          {/* {isAddressFormExpanded && ( */}
          <Stack direction="column" gap={1} sx={{ flexGrow: 1, pr: '55px' }}>
            {isAddressFormExpanded && (
              <Stack gap={1}>
                {!loadHiddenFields?.businessName && (
                  <Controller
                    name={`stops.${index}.location.businessName`}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        freeSolo
                        options={locationList || []}
                        getOptionLabel={(option) =>
                          option?.locationDisplayName ?? option
                        }
                        value={stop?.location?.businessName}
                        clearOnBlur
                        handleHomeEndKeys
                        id="createLoad-stop-businessname-edit-mode"
                        onInputChange={(_, v) => {
                          setLocationNameInput(v);
                          field.onChange(v);
                        }}
                        onBlur={() => {
                          if (
                            stop?.location?.businessName !==
                            stop?.location?.locationName
                          ) {
                            clearLocationDetails();
                          }
                        }}
                        filterOptions={(options, state) => options}
                        ListboxProps={{
                          onScroll: (data: any) => {
                            handleScrollLocationList(data);
                          },
                        }}
                        onChange={(_, value) => {
                          handleLocationNameChange(value);
                          field.onChange(
                            (value as LocationSummaryDTO)
                              ?.locationDisplayName ?? ''
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Business Name"
                            error={
                              errors?.stops?.[index]?.location?.businessName
                            }
                            helperText={
                              errors?.stops?.[index]?.location?.businessName
                                ?.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                )}
                <Controller
                  name={`stops.${index}.location.address`}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={addressList?.data || []}
                      getOptionLabel={(option) =>
                        option?.locationDisplayName ?? option
                      }
                      value={stop?.location?.address}
                      filterOptions={(options, state) => options}
                      clearOnBlur
                      handleHomeEndKeys
                      id="createLoad-stop-address-edit-mode"
                      onInputChange={(_, v) => {
                        setAddressInput(v);
                      }}
                      onChange={(_, value) => {
                        handleChangeLocationInEditMode(value);
                        field.onChange(
                          (value as LocationSummaryDTO)?.locationDisplayName ??
                            ''
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Address"
                          required={stopsRequiredFields?.address}
                          error={errors?.stops?.[index]?.location?.address}
                          helperText={
                            errors?.stops?.[index]?.location?.address?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Controller
                    name={`stops.${index}.location.streetAddress2`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Address 2"
                        fullWidth
                        error={errors?.stops?.[index]?.streetAddress2?.address}
                        helperText={
                          errors?.stops?.[index]?.location?.streetAddress2
                            ?.message
                        }
                      />
                    )}
                  />
                  {stop?.location?.city && stop?.location?.state && (
                    <Typography
                      width={'fit-content'}
                      variant="caption"
                      color={'textSecondary'}
                    >
                      {stop?.location?.city}
                      {', '}
                      {stop?.location?.state} {stop?.location?.zipcode}
                    </Typography>
                  )}
                </Stack>
                {/* Alternative wrapper to the above streetAdress2 text field.
                
                <InputTextField 
                  name={`stops.${index}.addressAlt`}
                  label="Address 2"
                  value={selBusiness?.address?.streetAddress2}
                  setValue={updateBusinessAddress2}
                />
                */}
              </Stack>
            )}

            {isContactFormExpanded &&
              !loadHiddenFields?.editContact &&
              !isStopTypeRelay && (
                <>
                  <Divider isSubheader textAlign="left" sx={{ mt: 2 }}>
                    Contact
                  </Divider>
                  <Stack direction="row" gap={1}>
                    <Controller
                      name={`stops.${index}.contact.contactName`}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          id="createLoad-stop-contact"
                          handleHomeEndKeys
                          sx={{ width: '220px' }}
                          freeSolo
                          options={getLocationContactOptions}
                          getOptionLabel={getLocationContactsOptionLabel}
                          onChange={(_, data) => {
                            handleOnChangeLocationContact(
                              data as ContactSummaryCustomer
                            );
                            field.onChange(
                              (data as ContactSummaryCustomer)?.fullName
                            );
                          }}
                          onInputChange={(_, v) => {
                            if (v !== stop?.contact?.contactName) {
                              setValue(
                                `stops.${index}.isContactDetailsUpdatedManually`,
                                true
                              );
                            }
                            field.onChange(v);
                            setLocationContactInput(v);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Contact Name" />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`stops.${index}.contact.countryCode`}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          id="countryCode"
                          disableClearable={true}
                          getOptionLabel={(value) =>
                            countryCodeOptions?.find((v) => v?.key === value)
                              ?.label ??
                            value?.label ??
                            value
                          }
                          options={countryCodeOptions}
                          value={countryCodeOptions[0].key}
                          onChange={(_: any, newValue: any) => {
                            setValue(
                              `stops.${index}.isContactDetailsUpdatedManually`,
                              true
                            );
                            field.onChange(newValue?.key);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              label="Country"
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`stops.${index}.contact.phone`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Phone"
                          error={errors?.stops?.[index]?.contact?.phone}
                          helperText={
                            errors?.stops?.[index]?.contact?.phone?.message
                          }
                          onChange={(event: any) => {
                            setValue(
                              `stops.${index}.isContactDetailsUpdatedManually`,
                              true
                            );
                            field.onChange(event.target?.value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={`stops.${index}.contact.extension`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="ext"
                          sx={{ width: '100px' }}
                          error={errors?.stops?.[index]?.contact?.extension}
                          helperText={
                            errors?.stops?.[index]?.contact?.extension?.message
                          }
                          onChange={(event: any) => {
                            setValue(
                              `stops.${index}.isContactDetailsUpdatedManually`,
                              true
                            );
                            field.onChange(event.target?.value);
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name={`stops.${index}.contact.email`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Email"
                        error={errors?.stops?.[index]?.contact?.email}
                        helperText={
                          errors?.stops?.[index]?.contact?.email?.message
                        }
                        onChange={(event: any) => {
                          setValue(
                            `stops.${index}.isContactDetailsUpdatedManually`,
                            true
                          );
                          field.onChange(event.target?.value);
                        }}
                      />
                    )}
                  />
                </>
              )}

            {isRefNumberExpanded &&
              !loadHiddenFields?.editReferenceNumber &&
              !isStopTypeRelay && (
                <>
                  <Divider isSubheader textAlign="left" sx={{ mt: 2 }}>
                    Ref # / PO #
                  </Divider>
                  <Controller
                    name={`stops.${index}.referenceNumber`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Reference #"
                        error={errors?.stops?.[index]?.referenceNumber}
                        helperText={
                          errors?.stops?.[index]?.referenceNumber?.message
                        }
                        onChange={(event: any) => {
                          setValue(
                            `stops.${index}.isRefNumberUpdatedManually`,
                            true
                          );
                          field.onChange(event.target?.value);
                        }}
                      />
                    )}
                  />
                </>
              )}
          </Stack>
          {/* )} */}

          {stop?.type == 'RELAY' && (
            <>
              <Stack direction="column" gap={1}>
                <Stack direction="row" alignItems="center">
                  <Typography flexGrow="1">
                    <b>Trailer Drop Off</b>
                  </Typography>
                  {isDropOffHandlingTimeVisible ||
                  errors?.stops?.[index]
                    ?.relayDropOffEstimatedActivityDuration ? (
                    <Controller
                      name={`stops.${index}.relayDropOffEstimatedActivityDuration`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Handling Time (min)"
                          onBlur={toggleIsDropOffHandlingTimeVisible}
                          autoFocus
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-24]*',
                          }}
                          sx={{ width: '155px' }}
                          error={
                            errors?.stops?.[index]
                              ?.relayDropOffEstimatedActivityDuration
                          }
                          helperText={
                            errors?.stops?.[index]
                              ?.relayDropOffEstimatedActivityDuration?.message
                          }
                          onChange={(event: any) => {
                            setValue(
                              `stops.${index}.isDropOffHandlingTimeUpdatedManually`,
                              true
                            );
                            field.onChange(event?.target?.value);
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => toggleIsDropOffHandlingTimeVisible()}
                    >
                      <Stack
                        direction="row"
                        gap="3px"
                        display={
                          loadHiddenFields?.estimatedActivityDuration
                            ? 'none'
                            : undefined
                        }
                      >
                        <Box sx={{ mr: '4px' }}>Handling Time:</Box>
                        {Math.floor(
                          stop?.relayDropOffEstimatedActivityDuration / 60
                        ) > 0 && (
                          <Box>
                            <b>
                              {Math.floor(
                                stop?.relayDropOffEstimatedActivityDuration / 60
                              )}
                            </b>
                            h
                          </Box>
                        )}
                        {stop?.relayDropOffEstimatedActivityDuration % 60 >
                          0 && (
                          <Box>
                            <b>
                              {' '}
                              {stop?.relayDropOffEstimatedActivityDuration % 60}
                            </b>
                            min
                          </Box>
                        )}
                      </Stack>
                    </Button>
                  )}
                  <Button
                    variant="text"
                    onClick={() => toggleIsRelayDropOffDriverAssistRequired()}
                    sx={{
                      display: loadHiddenFields?.isDriverAssistRequired
                        ? 'none'
                        : undefined,
                    }}
                  >
                    <Box sx={{ mr: '3px' }}>Driver Assist:</Box>
                    {stop?.isRelayDropOffDriverAssistRequired ? (
                      <b>Yes</b>
                    ) : (
                      'No'
                    )}
                  </Button>
                </Stack>
                <Stack gap={0}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="body2">On</Typography>
                    {renderStartDatePickerTemplate({
                      fieldName: `stops.${index}.relayDropOffAppointmentStartDate`,
                      value: stop?.relayDropOffAppointmentStartDate
                        ? moment(
                            stop?.relayDropOffAppointmentStartDate
                          ).format()
                        : undefined,
                      timeFieldName: `stops.${index}.relayDropOffAppointmentStartTime`,
                    })}
                    {renderStartTimePickerTemplate({
                      fieldName: `stops.${index}.relayDropOffAppointmentStartTime`,
                    })}
                    {isRelayDropOffEndTimeVisible && (
                      <>
                        {renderEndDatePickerTemplate({
                          fieldName: `stops.${index}.relayDropOffAppointmentEndDate`,
                          value: stop?.relayDropOffAppointmentEndDate
                            ? moment(
                                stop?.relayDropOffAppointmentEndDate
                              ).format()
                            : undefined,
                          timeFieldName: `stops.${index}.relayDropOffAppointmentEndTime`,
                        })}
                        {renderEndTimePickerTemplate({
                          fieldName: `stops.${index}.relayDropOffAppointmentEndTime`,
                        })}
                        {/* <TimeField format="HH:mm"></TimeField> */}
                      </>
                    )}
                    <Box>
                      <IconButton
                        size="small"
                        sx={{ flexShrink: 0 }}
                        {...bindTrigger(relayDropConfirmationCallPopupState)}
                      >
                        {relayDropConfirmationCallStateIcon}
                      </IconButton>
                    </Box>
                    {!isRelayDropOffEndTimeVisible &&
                      !loadHiddenFields?.appointmentEndDate && (
                        <Button
                          variant="text"
                          onClick={() => toggleIsRelayDropOffTimeVisible()}
                        >
                          Set End Time
                        </Button>
                      )}

                    <Menu {...bindMenu(relayDropConfirmationCallPopupState)}>
                      {AppointmentConfirmationOptions?.map((e) => (
                        <MenuItem
                          key={e?.value}
                          selected={
                            stop?.dropOffAppointmentConfirmation == e.value
                          }
                          onClick={() => {
                            setValue(
                              `stops.${index}.dropOffAppointmentConfirmation`,
                              e.value
                            );
                            handleChangeConfirmationCall(e.value);
                          }}
                          sx={{ alignItems: 'center' }}
                        >
                          <ListItemIcon>
                            {getConfirmationAppointmentIcon(e.value)}
                          </ListItemIcon>
                          {e.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Stack>
                  {isRelayDropOffEndTimeVisible && (
                    <Button
                      variant="text"
                      onClick={setRelayDropOffApptAsFixedAppointment}
                      sx={{ width: '220px' }}
                    >
                      Set as Fixed appointment
                    </Button>
                  )}
                  <ErrorText
                    text={
                      errors?.stops?.[index]?.relayDropOffAppointmentStartDate
                        ?.message ??
                      errors?.stops?.[index]?.relayDropOffAppointmentStartTime
                        ?.message ??
                      errors?.stops?.[index]?.relayDropOffAppointmentEndDate
                        ?.message ??
                      errors?.stops?.[index]?.relayDropOffAppointmentEndTime
                        ?.message
                    }
                    sx={{ marginTop: '8px' }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="body2">Revenue Share</Typography>
                  <Controller
                    name={`stops.${index}.relayDropOffRevenueShare`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ width: '100px' }}
                        id={`stops.${index}.relayDropOffRevenueShare`}
                        type="number"
                        value={stop?.relayDropOffRevenueShare}
                        label=""
                        onBlur={() =>
                          setValue(
                            `stops.${index}.relayPickupRevenueShare`,
                            getRevenueShare(stop?.relayDropOffRevenueShare)
                          )
                        }
                        error={errors?.stops?.[index]?.relayDropOffRevenueShare}
                        helperText={
                          errors?.stops?.[index]?.relayDropOffRevenueShare
                            ?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Typography flexGrow="1">
                    <b>Trailer Pick Up</b>
                  </Typography>
                  {isHandlingTimeVisible ||
                  errors?.stops?.[index]?.estimatedActivityDuration ? (
                    <Controller
                      name={`stops.${index}.estimatedActivityDuration`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Handling Time (min)"
                          onBlur={toggleIsHandlingTimeVisible}
                          autoFocus
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-24]*',
                          }}
                          sx={{ width: '155px' }}
                          error={
                            errors?.stops?.[index]?.estimatedActivityDuration
                          }
                          helperText={
                            errors?.stops?.[index]?.estimatedActivityDuration
                              ?.message
                          }
                          onChange={(event: any) => {
                            setValue(
                              `stops.${index}.isHandlingTimeUpdatedManually`,
                              true
                            );
                            field.onChange(event?.target?.value);
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => toggleIsHandlingTimeVisible()}
                    >
                      <Stack
                        direction="row"
                        gap="3px"
                        display={
                          loadHiddenFields?.estimatedActivityDuration
                            ? 'none'
                            : undefined
                        }
                      >
                        <Box sx={{ mr: '4px' }}>Handling Time:</Box>
                        {Math.floor(stop?.estimatedActivityDuration / 60) >
                          0 && (
                          <Box>
                            <b>
                              {Math.floor(stop?.estimatedActivityDuration / 60)}
                            </b>
                            h
                          </Box>
                        )}
                        {stop?.estimatedActivityDuration % 60 > 0 && (
                          <Box>
                            <b> {stop?.estimatedActivityDuration % 60}</b>min
                          </Box>
                        )}
                      </Stack>
                    </Button>
                  )}
                  <Button
                    variant="text"
                    onClick={() => toggleIsDriverAssist()}
                    sx={{
                      display: loadHiddenFields?.isDriverAssistRequired
                        ? 'none'
                        : undefined,
                    }}
                  >
                    <Box sx={{ mr: '3px' }}>Driver Assist:</Box>
                    {stop?.isDriverAssistRequired ? <b>Yes</b> : 'No'}
                  </Button>
                </Stack>
                <Stack gap={0}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="body2">On</Typography>
                    {renderStartDatePickerTemplate({
                      fieldName: `stops.${index}.appointmentStartDate`,
                      value: stop?.appointmentStartDate
                        ? moment(stop?.appointmentStartDate).format()
                        : undefined,
                      timeFieldName: `stops.${index}.appointmentStartTime`,
                    })}
                    {renderStartTimePickerTemplate({
                      fieldName: `stops.${index}.appointmentStartTime`,
                    })}
                    {isEndTimeVisible && (
                      <>
                        {renderEndDatePickerTemplate({
                          value: stop?.appointmentEndDate
                            ? moment(stop?.appointmentEndDate).format()
                            : undefined,
                          timeFieldName: `stops.${index}.appointmentEndTime`,
                        })}
                        {renderEndTimePickerTemplate({
                          fieldName: `stops.${index}.appointmentEndTime`,
                        })}
                      </>
                    )}
                    <Box>
                      <IconButton
                        size="small"
                        sx={{ flexShrink: 0 }}
                        {...bindTrigger(confirmationCallPopupState)}
                      >
                        {confirmationCallStateIcon}
                      </IconButton>
                    </Box>

                    {!isEndTimeVisible &&
                      !loadHiddenFields?.appointmentEndDate && (
                        <Button
                          variant="text"
                          onClick={() => toggleIsEndTimeVisible()}
                        >
                          Set End Time
                        </Button>
                      )}

                    <Menu {...bindMenu(confirmationCallPopupState)}>
                      {AppointmentConfirmationOptions?.map((e) => (
                        <MenuItem
                          key={e?.value}
                          selected={stop?.appointmentConfirmation == e.value}
                          onClick={() => {
                            setValue(
                              `stops.${index}.appointmentConfirmation`,
                              e.value
                            );
                            handleChangeConfirmationCall(e.value);
                          }}
                          sx={{ alignItems: 'center' }}
                        >
                          <ListItemIcon>
                            {getConfirmationAppointmentIcon(e.value)}
                          </ListItemIcon>
                          {e.label}
                        </MenuItem>
                      ))}
                    </Menu>
                    {!isStopTypeRelay && !loadHiddenFields?.notes && (
                      <IconButton
                        sx={{ ml: 'auto' }}
                        size="small"
                        onClick={() => toggleIsNotesVisible()}
                      >
                        <EditNote fontSize="large" />
                      </IconButton>
                    )}
                  </Stack>
                  {isEndTimeVisible && (
                    <Button
                      variant="text"
                      onClick={setStopApptAsFixedAppointment}
                      sx={{ width: '220px' }}
                    >
                      Set as Fixed appointment
                    </Button>
                  )}
                  <ErrorText
                    text={
                      errors?.stops?.[index]?.appointmentStartDate?.message ??
                      errors?.stops?.[index]?.appointmentStartTime?.message ??
                      errors?.stops?.[index]?.appointmentEndDate?.message ??
                      errors?.stops?.[index]?.appointmentEndTime?.message
                    }
                    sx={{ marginTop: '8px' }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="body2">Revenue Share</Typography>
                  <Controller
                    name={`stops.${index}.relayPickupRevenueShare`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ width: '100px' }}
                        // value={stop?.relayPickupRevenueShare}
                        id={`stops.${index}.relayPickupRevenueShare`}
                        type="number"
                        label=""
                        value={stop?.relayPickupRevenueShare}
                        onBlur={() =>
                          setValue(
                            `stops.${index}.relayDropOffRevenueShare`,
                            getRevenueShare(stop?.relayPickupRevenueShare)
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        error={errors?.stops?.[index]?.relayPickupRevenueShare}
                        helperText={
                          errors?.stops?.[index]?.relayPickupRevenueShare
                            ?.message
                        }
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </>
          )}
          <Stack gap={1}>
            {stop?.type != 'RELAY' && (
              <Stack gap={0}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="body2">On</Typography>
                  {renderStartDatePickerTemplate({
                    fieldName: `stops.${index}.appointmentStartDate`,
                    value: stop?.appointmentStartDate
                      ? moment(stop?.appointmentStartDate).format()
                      : undefined,
                    timeFieldName: `stops.${index}.appointmentStartTime`,
                  })}
                  {renderStartTimePickerTemplate({
                    fieldName: `stops.${index}.appointmentStartTime`,
                  })}
                  {isEndTimeVisible && (
                    <>
                      {renderEndDatePickerTemplate({
                        value: stop?.appointmentEndDate
                          ? moment(stop?.appointmentEndDate).format()
                          : undefined,
                        timeFieldName: `stops.${index}.appointmentEndTime`,
                      })}
                      {renderEndTimePickerTemplate({
                        fieldName: `stops.${index}.appointmentEndTime`,
                      })}
                    </>
                  )}
                  <Box>
                    <IconButton
                      size="small"
                      sx={{ flexShrink: 0 }}
                      {...bindTrigger(confirmationCallPopupState)}
                    >
                      {confirmationCallStateIcon}
                    </IconButton>
                  </Box>
                  {!isEndTimeVisible &&
                    !loadHiddenFields?.appointmentEndDate && (
                      <Button
                        variant="text"
                        onClick={() => toggleIsEndTimeVisible()}
                      >
                        Set End Time
                      </Button>
                    )}

                  <Menu {...bindMenu(confirmationCallPopupState)}>
                    {AppointmentConfirmationOptions?.map((e) => (
                      <MenuItem
                        key={e?.value}
                        selected={stop?.appointmentConfirmation == e.value}
                        onClick={() => {
                          setValue(
                            `stops.${index}.appointmentConfirmation`,
                            e.value
                          );
                          handleChangeConfirmationCall(e.value);
                        }}
                        sx={{ alignItems: 'center' }}
                      >
                        <ListItemIcon>
                          {getConfirmationAppointmentIcon(e.value)}
                        </ListItemIcon>
                        {e.label}
                      </MenuItem>
                    ))}
                  </Menu>
                  {!isStopTypeRelay && !loadHiddenFields?.notes && (
                    <IconButton
                      sx={{ ml: 'auto' }}
                      size="small"
                      onClick={() => toggleIsNotesVisible()}
                    >
                      <EditNote fontSize="large" />
                    </IconButton>
                  )}
                </Stack>
                {isEndTimeVisible && (
                  <Button
                    variant="text"
                    onClick={setStopApptAsFixedAppointment}
                    sx={{ width: '220px' }}
                  >
                    Set as Fixed appointment
                  </Button>
                )}
                <ErrorText
                  text={
                    errors?.stops?.[index]?.appointmentStartDate?.message ??
                    errors?.stops?.[index]?.appointmentStartTime?.message ??
                    errors?.stops?.[index]?.appointmentEndDate?.message ??
                    errors?.stops?.[index]?.appointmentEndTime?.message
                  }
                  sx={{ marginTop: '8px' }}
                />
              </Stack>
            )}

            {isNotesVisible && !isStopTypeRelay && (
              <Controller
                name={`stops.${index}.notes`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      display: loadHiddenFields?.notes ? 'none' : undefined,
                      mt: 0.5,
                    }}
                    id={'stop-' + index + '-notes'}
                    label="Notes"
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={3}
                    error={errors.stops?.[index]?.notes}
                    helperText={errors.stops?.[index]?.notes?.message}
                    onChange={(event: any) => {
                      setValue(`stops.${index}.isNotesUpdatedManually`, true);
                      field.onChange(event?.target?.value);
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </Stack>
      </Stack>

      {index != stops.length - 1 && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Stack direction="column" alignItems="center" sx={{ width: '50px' }}>
            <IconButton
              size="small"
              sx={{ border: 'dashed 1px rgba(0 0 0 / 75%)' }}
              onClick={() => add(index)}
            >
              <Add sx={{ fontSize: 24 }}></Add>
            </IconButton>
          </Stack>

          <Box
            sx={{
              width: '1px',
              flexGrow: 1,
              height: '1px',
              backgroundColor: 'rgba(0 0 0 / 5%)',
            }}
          />
        </Stack>
      )}
      <LocationContacttDetailsForm
        locationId={stop?.location?.locationId}
        open={Boolean(addNewLocationContact)}
        data={addNewLocationContact}
        onSuccess={handleAddNewContact}
        onClose={() => setAddNewLocationContact(null)}
      />
    </>
  );
};
