import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { loadService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { Typography } from '../../../../../components_v2/ui-kit/typography';
import {
  AddLoadCommodity,
  ByLoadIdRequest,
  CommodityTypeSummary,
  deleteCommodityReq,
  PackageTypesResponse,
} from '../../../../../models';
import { commodityValidationSchema } from '../../../../../subPages/loadsList/LoadDetailsPanel/constants/constants';
import { Commodity } from '../../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { getCommodityTypes } from '../../../../../subPages/loadsList/LoadDetailsPanel/utils';
import { AddIcon } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import ThreeDotMenuIcon from '../../../../../ui-kit/components/ThreeDotMenuIcon';
import CommodityForm from '../../../../LoadSharedComponents/forms/CommodityForm';
import { getDefaultCommodity } from '../../../../LoadSharedComponents/utils';
import FormDialog from '../../../../Ui/FormDialog';
import { LoadDetails } from '../models';
import { SingleFieldInfo } from './singleFieldInfo';
const MenuOptions = [
  {
    label: 'Delete',
  },
  {
    label: 'Edit',
  },
];
export const CommodityDetails = observer(
  ({
    loadDetails,
    loadId,
    onUpdated,
  }: {
    loadDetails?: LoadDetails;
    loadId: string;
    onUpdated: () => void;
  }) => {
    const [addCommodityDialogOpen, setCommodityDialog] = useState(false);
    const [editCommodityDialogOpen, setEditCommodityDialog] = useState(false);
    const [selectedCommodity, setSelectedCommodity] = useState<Commodity>();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [commodityTypes, setCommodityTypeOptions] = useState<
      CommodityTypeSummary[]
    >([]);
    const [packageTypeOptions, setPackageTypeOptions] = useState<
      PackageTypesResponse[]
    >([]);

    const getCommodityTypeOptions = async () => {
      const commodityTypes = await getCommodityTypes();
      setCommodityTypeOptions(commodityTypes);
    };
    useEffect(() => {
      const getPackageTypeOptions = async () => {
        const response = await loadService.getPackageTypes();
        if (!response || response instanceof ServiceError) {
          return;
        }
        setPackageTypeOptions(response);
      };
      getPackageTypeOptions();
      getCommodityTypeOptions();
    }, []);

    const onAddCommodity = async (commodity: Commodity) => {
      setCommodityDialog(false);
      const newCommodity = new AddLoadCommodity({
        ...commodity,
        commodityTypeId: commodity.commodityTypeId,
        commodityType: commodity.commodityType,
        itemName: commodity.commodityTypeDisplayValue ?? commodity.itemName,
      });

      const request = new ByLoadIdRequest();
      request['loadId'] = loadId;
      const response = await loadService.addUpdateLoadCommodityDetails(
        request,
        newCommodity
      );
      if (response) {
        onUpdated();
      }
      setCommodityDialog(false);
      setEditCommodityDialog(false);
    };

    const handleDeleteCommodity = async () => {
      const request = new deleteCommodityReq();
      request['loadId'] = loadId;
      request['commodityId'] = selectedCommodity.id;
      const response = await loadService.deleteLoadCommodityDetails(request);
      if (response) {
        onUpdated();
      }
      handleMenuClose();
      setCommodityDialog(false);
      setEditCommodityDialog(false);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const handleMenuItemClick = (
      action: { icon: string; label: string },
      accountData: Commodity,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      if (action?.label == 'Delete') {
        handleDeleteCommodity();
      } else {
        setEditCommodityDialog(true);
      }
      handleMenuClose();

      // handleDelete(alert);
    };
    const handleMenuClick = (
      event: React.MouseEvent<HTMLElement>,
      eventData: Commodity
    ): void => {
      event.stopPropagation();
      setSelectedCommodity({
        ...eventData,
        commodity: {
          id: eventData.commodityTypeId,
          itemName: eventData.itemName,
        },
      });
      setAnchorEl(event.currentTarget);
    };
    return (
      <Box>
        <Box
          sx={{
            paddingBottom: '20px',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              variant="h7"
              sx={{
                paddingRight: '0px',
                color: 'primary.main',
              }}
            >
              Commodity
            </Typography>
          </Box>

          <ButtonImproved
            onClick={() => {
              setCommodityDialog(true);
            }}
            variant="contained"
            startIcon={<AddIcon color="#fff" />}
            label={t('TripAddNewLoadOption5AddNewCommodity')}
            styleProps={{ minWidth: 35, marginLeft: 10 }}
          />
        </Box>
        {loadDetails?.commodityData?.map((commodityData: Commodity) => (
          <Box
            key={commodityData.id}
            sx={{
              margin: '10px 0px',
              marginBottom: '30px',
              background: '#f5f5f5!important',
              padding: '16px',
              borderRadius: '10px',
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              marginBottom={'5px'}
            >
              <Typography
                variant="h7"
                sx={{
                  color: 'primary.main',
                }}
              >
                {commodityData.itemName}
              </Typography>

              <ThreeDotMenuIcon
                key={commodityData?.id}
                styleProps={{ fontSize: '12px', padding: '0px' }}
                menuOptions={MenuOptions}
                accountData={commodityData}
                open={anchorEl}
                handleMenuClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleMenuClick(event, commodityData)
                }
                handleMenuClose={handleMenuClose}
                anchorEl={anchorEl}
                handleMenuItemClick={handleMenuItemClick}
              />
            </Box>
            <Stack
              spacing={{ xs: 1, sm: 1 }}
              direction="row"
              useFlexGap
              sx={{ flexWrap: 'wrap', fontSize: '12px' }}
            >
              {commodityData.qty && (
                <div>
                  <SingleFieldInfo
                    label={'Quantity'}
                    value={commodityData.qty}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
              {commodityData.packageType && (
                <div>
                  <SingleFieldInfo
                    label={'Package Type'}
                    value={commodityData.packageType}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
              <div>
                <SingleFieldInfo
                  label={'Stackable'}
                  value={commodityData?.stackable ? 'Yes' : 'No'}
                />
                <span style={{ margin: '0 5px' }}>|</span>
              </div>
              {commodityData.floorLoad && (
                <div>
                  <SingleFieldInfo
                    label={'Floor Load'}
                    value={commodityData?.floorLoad ? 'Yes' : 'No'}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
              {commodityData.weight && (
                <div>
                  <SingleFieldInfo
                    label={'Weight'}
                    value={commodityData.weight + ' lbs'}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
              {commodityData.volume && (
                <div>
                  <SingleFieldInfo
                    label={'Volume'}
                    value={commodityData.volume + ' cu.ft'}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
              {commodityData.length && (
                <div>
                  <SingleFieldInfo
                    label={'Length'}
                    value={commodityData.length + ' ft'}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
              {commodityData.width && (
                <div>
                  <SingleFieldInfo
                    label={'Width'}
                    value={commodityData.width + ' ft'}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
              {commodityData.height && (
                <div>
                  <SingleFieldInfo
                    label={'Height'}
                    value={commodityData.height + ' ft'}
                  />
                  <span style={{ margin: '0 5px' }}>|</span>
                </div>
              )}
            </Stack>
            {commodityData.description && (
              <SingleFieldInfo
                styles={{ marginTop: '5px' }}
                label={'Description'}
                value={commodityData.description}
              />
            )}
          </Box>
        ))}
        {addCommodityDialogOpen && (
          <FormDialog
            data={getDefaultCommodity(commodityTypes)}
            titleText={t('TripAddNewLoadOption5AddNewCommodity')}
            actionLabel={t('TripAddNewLoadOption5AddCommodity')}
            open={addCommodityDialogOpen}
            onAction={onAddCommodity}
            handleClose={() => {
              setCommodityDialog(false);
            }}
            validationSchema={commodityValidationSchema}
            contentRenderer={() => (
              <CommodityForm
                packageTypeOptions={packageTypeOptions}
                nameStart=""
              />
            )}
          ></FormDialog>
        )}
        {editCommodityDialogOpen && (
          <FormDialog
            data={selectedCommodity}
            titleText={t('TripAddNewLoadOptionEditCommodity')}
            actionLabel={'Save'}
            open={editCommodityDialogOpen}
            onAction={onAddCommodity}
            disableActionBtnByDefault={true}
            handleClose={() => {
              setEditCommodityDialog(false);
            }}
            validationSchema={commodityValidationSchema}
            contentRenderer={() => (
              <CommodityForm
                packageTypeOptions={packageTypeOptions}
                nameStart=""
              />
            )}
          ></FormDialog>
        )}
      </Box>
    );
  }
);
