import moment from 'moment';
import { documentService } from '../api';
import { contractNameSerachUrl } from '../api/impl/requestConstants';
import { ServiceError } from '../api/interfaces';
import { httpClient } from '../axios/axiosInstance';
import {
  LaneInfoEnum,
  LaneInfoType,
  LaneRateItemType,
} from '../components/contract/types';
import { CreateDocumentOnlyRequest, QueryParams } from '../models';
import { GetContractCustomerListRequest } from '../models/DTOs/Customer/Requests';
import {
  AssignDocumentRequest,
  AssignDocumentResponseType,
  DocumentSummaryResponse,
} from '../models/DTOs/Document/Document';
import { UserDetails } from '../models/DTOs/user/User';
import EncryptedStorageManager from '../StorageManager/EncryptedStorageManager';
import StorageManager from '../StorageManager/StorageManager';
import { deleteFile } from '../subPages/expenses/utils/utils';
import { getDocumentTypes } from '../subPages/users/utils';
import { DateTimezoneUtils } from './Timezone.utils';

export const convertContractListRespToTableData = (data: any) => {
  return {
    id: data?.id || Math.random(),
    contractName: data?.name || '',
    status: data?.status || '',
    customer: data?.customerName || '',
    rates: data?.rateCount || '',
    rateMethod: data?.rateMethod || '',
    effectiveDate: data?.effectiveDate || '',
    expirationDate: data?.expDate || '',
    customerId: data?.customerId,
    createdDate: data?.createdDate || '',
  };
};

const initialCellValue = {
  isInvalid: false,
  message: '',
  value: '',
};

export const prepareForLaneTableView = (
  data: LaneRateItemType,
  laneInfoType: LaneInfoType
) => {
  return data?.map((dt: LaneRateItemType) => {
    if (laneInfoType === LaneInfoEnum.zip) {
      return {
        id: dt.id || Math.random(),
        modifiedDate: {
          ...initialCellValue,
          value: dt?.modifiedDate,
        },
        zipStartOrigin: {
          ...initialCellValue,
          value: dt?.origin?.zipStart === 0 ? '0' : dt?.origin?.zipStart,
        },
        zipEndOrigin: {
          ...initialCellValue,
          value: dt?.origin?.zipEnd === 0 ? '0' : dt?.origin?.zipEnd,
        },
        destinationStartOrigin: {
          ...initialCellValue,
          value:
            dt.destination.zipStart === 0 ? '0' : dt?.destination?.zipStart,
        },
        destinationEndOrigin: {
          ...initialCellValue,
          value: dt.destination.zipEnd === 0 ? '0' : dt?.destination?.zipEnd,
        },
        totalMiles: {
          ...initialCellValue,
          value: dt.totalMiles,
        },
        weight: {
          ...initialCellValue,
          value: dt.weight,
        },
        equipmentType: {
          ...initialCellValue,
          value: dt.equipmentType,
        },
        mode: {
          ...initialCellValue,
          value: dt.mode,
        },
        region: {
          ...initialCellValue,
          value: dt.region,
        },
        rateType: {
          ...initialCellValue,
          value: dt.rateType,
        },
        rate: {
          ...initialCellValue,
          value: dt.rate,
        },
        minimumAmount: {
          ...initialCellValue,
          value: dt.minimumAmount,
        },
        description: {
          ...initialCellValue,
          value: dt.description,
        },
      };
    }
    if (laneInfoType === LaneInfoEnum.city) {
      return {
        id: dt.id || Math.random(),
        modifiedDate: {
          ...initialCellValue,
          value: dt?.modifiedDate,
        },
        origin: {
          ...initialCellValue,
          value: dt?.origin,
        },
        destination: {
          ...initialCellValue,
          value: dt?.destination,
        },
        totalMiles: {
          ...initialCellValue,
          value: dt?.totalMiles,
        },
        weight: {
          ...initialCellValue,
          value: dt?.weight,
        },
        equipmentType: {
          ...initialCellValue,
          value: dt?.equipmentType,
        },
        mode: {
          ...initialCellValue,
          value: dt?.mode,
        },
        region: {
          ...initialCellValue,
          value: dt?.region,
        },
        rateType: {
          ...initialCellValue,
          value: dt?.rateType,
        },
        rate: {
          ...initialCellValue,
          value: dt?.rate,
        },
        minimumAmount: {
          ...initialCellValue,
          value: dt?.minimumAmount,
        },
        description: {
          ...initialCellValue,
          value: dt?.description,
        },
      };
    }
    if (laneInfoType === LaneInfoEnum.mileage) {
      return {
        id: dt.id || Math.random(),
        modifiedDate: {
          ...initialCellValue,
          value: dt?.modifiedDate,
        },
        minMiles: {
          ...initialCellValue,
          value: dt?.minMiles === 0 ? '0' : dt?.minMiles,
        },
        maxMiles: {
          ...initialCellValue,
          value: dt?.maxMiles === 0 ? '0' : dt?.maxMiles,
        },
        totalMiles: {
          ...initialCellValue,
          value: dt?.totalMiles,
        },
        weight: {
          ...initialCellValue,
          value: dt?.weight,
        },
        equipmentType: {
          ...initialCellValue,
          value: dt?.equipmentType,
        },
        mode: {
          ...initialCellValue,
          value: dt?.mode,
        },
        rateType: {
          ...initialCellValue,
          value: dt?.rateType,
        },
        rate: {
          ...initialCellValue,
          value: dt?.rate,
        },
        minimumAmount: {
          ...initialCellValue,
          value: dt?.minimumAmount,
        },
        description: {
          ...initialCellValue,
          value: dt?.description,
        },
      };
    }
  });
};

export const prepareForTableView = (data, accessorialItemStaticData) => {
  const arr = [];
  if (data?.length) {
    data.forEach((dt) => {
      const keys = Object.keys(dt);
      const obj = {};
      keys.map((key) => {
        if (key === 'id') {
          obj[key] = dt[key];
        } else if (key == 'rateType') {
          let rateTypeId = null;
          const accessorialRes = accessorialItemStaticData.filter((li) => {
            if (dt['rateTypeId']) {
              if (dt[key] == li?.itemCode && dt['rateTypeId'] == li?.id) {
                rateTypeId = li?.id;
                return li;
              }
            } else if (dt[key] == li?.itemCode) {
              rateTypeId = li?.id;
              return li;
            }
          });
          obj[key] = {
            isInvalid: false,
            message: '',
            value: rateTypeId || dt[key],
          };
        } else {
          obj[key] = {
            isInvalid: false,
            message: '',
            value: dt[key] === 0 ? '0' : dt[key],
          };
        }
      });
      arr.push(obj);
    });
  }
  return arr;
};

export const createAccessorialItems = (data, accessorialItemStaticData) => {
  const arr: any = [];
  data?.forEach((dt) => {
    let rateTypeId = '',
      rateTypeCode;
    if (dt?.rateType?.value) {
      const res = accessorialItemStaticData.filter(
        (li) => li.id === dt?.rateType?.value
      );
      if (res && res.length > 0) {
        rateTypeId = res[0]?.id;
        rateTypeCode = res[0]?.itemCode;
      }
    }
    arr.push({
      rateType: rateTypeCode,
      rateTypeId: rateTypeId,
      unit: dt?.unit?.value,
      fuelSurcharge: dt?.fuelSurcharge,
      rate: dt?.rate?.value,
      fuelSurchargeId:
        dt?.fuelSurchargeId?.value ?? dt?.fuelSurchargeName?.value,
      autoPopulate: dt?.autoPopulate?.value,
      description: dt?.description?.value,
    });
  });
  return arr;
};

export const createLaneItems = (
  data: any,
  laneType: LaneInfoType,
  newData: any
) => {
  const arr: any = [];
  const generateLocationObject = (dt) => {
    if (laneType === 'CITY_BASED') {
      return {
        origin: {
          city: dt?.origin?.value?.city,
          state: dt?.origin?.value?.state,
          zipStart: dt?.origin?.value?.zipcode,
          zipEnd: dt?.origin?.value?.zipcode,
          center: dt?.origin?.value?.center,
        },
        destination: {
          city: dt?.destination?.value?.city,
          state: dt?.destination?.value?.state,
          zipStart: dt?.destination?.value?.zipcode,
          zipEnd: dt?.destination?.value?.zipcode,
          center: dt?.destination?.value?.center,
        },
      };
    }
    if (laneType === 'ZIP_BASED') {
      return {
        origin: {
          city: '',
          state: '',
          zipStart: dt?.zipStartOrigin?.value,
          zipEnd: dt?.zipEndOrigin?.value,
        },
        destination: {
          city: '',
          state: '',
          zipStart: dt?.destinationStartOrigin?.value,
          zipEnd: dt?.destinationEndOrigin?.value,
        },
      };
    }
    return {
      origin: {
        city: '',
        state: '',
        zipStart: '',
        zipEnd: '',
      },
      destination: {
        city: '',
        state: '',
        zipStart: '',
        zipEnd: '',
      },
    };
  };
  data.forEach((dt: any) => {
    arr.push({
      totalMiles: dt?.totalMiles?.value,
      weight: dt?.weight?.value,
      equipmentType: dt.equipmentType.value || null,
      rateType: dt?.rateType?.value,
      rate: dt?.rate?.value,
      minimumAmount: dt?.minimumAmount?.value,
      description: dt?.description?.value,
      mode: dt?.mode?.value,
      region: dt?.region?.value,
      maxMiles: dt?.maxMiles?.value,
      minMiles: dt?.minMiles?.value,
      ...generateLocationObject(dt),
    });
  });
  newData?.forEach((dt: any) => {
    arr.push({
      totalMiles: dt?.totalMiles?.value,
      weight: dt?.weight?.value,
      equipmentType: dt.equipmentType.value || null,
      rateType: dt?.rateType?.value,
      rate: dt?.rate?.value,
      minimumAmount: dt?.minimumAmount?.value,
      description: dt?.description?.value,
      mode: dt?.mode?.value,
      region: dt?.region?.value,
      maxMiles: dt?.maxMiles?.value,
      minMiles: dt?.minMiles?.value,
      ...generateLocationObject(dt),
    });
  });
  return arr;
};

export const getCreateContractRequestObject = (
  data,
  type = 'CITY_BASED',
  laneRateItems: any[],
  accessorialRateItems: any[]
) => {
  return {
    organizationId: EncryptedStorageManager.getItem('user')?.organizationId,
    name: data?.contractName,
    customerId: data?.customerName?.id,
    customerName: data?.customerName?.name,
    effectiveDate: data?.startDate?.format?.('YYYY-MM-DD') || data?.startDate,
    expDate: data?.endDate?.format?.('YYYY-MM-DD') || data?.endDate,
    notes: data?.description,
    rateMethod: type,
    laneRateItems: [...laneRateItems],
    accessorialItems: [...accessorialRateItems],
  };
};

export const formatRowsForLaneItemsTable = (data, type) => {
  if (data?.length) {
    if (type === 'CITY_BASED') {
      return data?.map((dt) => ({
        id: dt?.id || Math.random(),
        origin: dt.origin,
        destination: dt.destination,
        totalMiles: dt.totalMiles,
        weight: dt.weight,
        equipmentType: dt.equipmentType,
        mode: dt.mode,
        rateType: dt.rateType,
        rate: dt.rate,
        description: dt.description,
      }));
    }
    if (type === 'MILEAGE_BASED') {
      return data?.map((dt) => ({
        id: dt?.id || Math.random(),
        maxMiles: dt.maxMiles,
        minMiles: dt.minMiles,
        totalMiles: dt.totalMiles,
        weight: dt.weight,
        equipmentType: dt.equipmentType,
        mode: dt.mode,
        rateType: dt.rateType,
        rate: dt.rate,
        description: dt.description,
      }));
    }
  }
  return [];
};

export const formatContractFilters = (filters: any) => {
  return {
    statusFilter: filters?.status?.map((data: any) => data?.value) || [],
    idFilter: filters?.contractName?.map((data: any) => data?.id) || [],
    rateMethodFilter:
      filters?.rateMethodFilter?.map((data: any) => data?.value) || [],
    customerIdFilter: filters?.customerName?.map((data: any) => data?.id) || [],
    effectiveFrom:
      DateTimezoneUtils.toStartDateISOString(
        filters?.effectiveDate?.dateRange?.[0]
      ) || '',
    effectiveTo:
      DateTimezoneUtils.toStartDateISOString(
        filters?.effectiveDate?.dateRange?.[1]
      ) || '',
    expireFrom:
      DateTimezoneUtils.toStartDateISOString(
        filters?.expDate?.dateRange?.[0]
      ) ||
      '' ||
      [],
    expireTo:
      DateTimezoneUtils.toStartDateISOString(
        filters?.expDate?.dateRange?.[1]
      ) || '',
    createdFrom:
      DateTimezoneUtils.toStartDateISOString(
        filters?.createdDate?.dateRange?.[0]
      ) || '',
    createdTo:
      DateTimezoneUtils.toStartDateISOString(
        filters?.createdDate?.dateRange?.[1]
      ) || '',
  };
};

export const getContractNamesList = async (
  text: string,
  pageNumber: number,
  skipLoader?: boolean
) => {
  const requestData = new GetContractCustomerListRequest();
  requestData.pageNumber = pageNumber;
  requestData.pageSize = 25;
  requestData.contractName = text;
  try {
    const response = await httpClient.getRaw(
      contractNameSerachUrl,
      requestData
    );
    if (response instanceof ServiceError) {
      return [];
    } else {
      const result: any = response.data;
      return result;
    }
  } catch (e) {
    throw e;
  }
};

export const getDateFromDateObject = (date: Date) => {
  if (date) {
    const newDate = moment(date, 'YYYY/MM/DD');
    const month = newDate.format('MMM');
    const day = newDate.format('DD');
    const year = newDate.format('YYYY');
    return `${month} ${day}, ${year}`;
  } else return date;
};

const assignDocument = async (
  file: DocumentSummaryResponse,
  contract: UserDetails
) => {
  const user = StorageManager.getUser() || {};

  const documentTypesList = await getDocumentTypes();

  if (!documentTypesList) return;
  const documentType = documentTypesList?.find(
    (item) => item.itemCode === 'CUSTOMER_CONTRACT'
  );

  const assignDocumentRequest = new AssignDocumentRequest({
    description: '',
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: 'CUSTOMER_CONTRACT',
    documentTypeId: documentType?.id || '',
    documentId: file.id,
    fileName: file.fileName,
    fileSize: file.fileSize,
    uploadDate: file.uploadDate,
    ownerId: user.id,
    attachedEntities: [
      {
        properties: [
          {
            id: contract?.id.toString() || '',
            title: contract?.name,
          },
        ],
        type: 'CONTRACT',
      },
    ],
  });
  const response = documentService.assignDocument(assignDocumentRequest);
  return response instanceof ServiceError ? null : response;
};

const uploadDocument = async (assignedDocument: File) => {
  const queryParams = new QueryParams();
  queryParams.addUserIdToParams();

  const requestBody = new CreateDocumentOnlyRequest({
    data: assignedDocument,
    ownerId: queryParams.userId,
  });

  const response = await documentService.createDocumentOnly(requestBody);
  return response || null;
};
export const saveFile = async (
  contract: any,
  file: FileList
): Promise<AssignDocumentResponseType | undefined> => {
  if (contract && !!file?.length) {
    const uploadedFile = await uploadDocument(file[0]);

    if (uploadedFile) {
      const document = await assignDocument(uploadedFile, contract);

      if (document) return document;
    }
    return;
  }
};

export const updateDocument = async (
  contractData,
  newAttachedDocument,
  oldDocument
) => {
  if (newAttachedDocument && newAttachedDocument?.length > 0) {
    const newDocumnet = newAttachedDocument[0];
    if (oldDocument?.documentId && newDocumnet) {
      if (oldDocument?.documentId == newDocumnet?.documentId) {
        return;
      }
      if (oldDocument?.documentId != newDocumnet?.id) {
        await deleteFile([oldDocument?.documentId]);
      }
    }
    await saveFile(contractData, newAttachedDocument);
    return true;
  } else if (oldDocument && oldDocument?.documentId) {
    await deleteFile([oldDocument?.documentId]);
    return true;
  }
  return true;
};
