export const urls = {
  getAllTemplates: '/web/load/api/v2/loadtemplates/get/list',
  getSingleTemplateData: '/web/load/api/v2/loadtemplates/get/one',
  getCustomer: '/web/customer/api/v2/customers/search',
  getContracts: 'web/customer/api/v2/contracts/list',
  getContractsByName:
    'web/customer/api/v2/contracts/list/names?pageSize=125&pageNumber=1&',
  getContarctById: 'web/customer/api/v2/contracts/get?id={id}',
  getMatchContract: 'web/customer/api/v2/contracts/best-matching-lane',
  factoringListURL:
    '/web/factoring/api/v2/integrations/organization/factoring/search',
  calculateLoadStopsTotalMiles: '/web/load/api/v2/loads/calculate/mileage',
  createLoad: '/web/load/api/v2/loads/create',
  getTemplateData: '/web/load/api/v2/loadtemplates/get/one?id=',
  getFuelSurchargeRate: '/web/customer/api/v2/fuel-surcharge/calculate/rate',
};
