import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select from '@mui/material/Select/Select';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import { invoiceModuleFactoringInfo } from '../../../../common/PendoClassIDs/constants';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import { fromIsoToMoment, getFormattedDateTime } from '../../../../utils';
import {
  CANCELLED_LOAD_STATUS_NAME,
  COMPLETED_LOAD_STATUS_NAME,
  DISCONNECT_FACTORING,
  FACTORING_ACTIONS,
  INVOICED_LOAD_STATUS_NAME,
  PAID_LOAD_STATUS_NAME,
  READY_TO_INVOICE,
  REFRESH_FACTORING_STATUS,
  SEND_TO_FACTORING,
  SUBMIT_TO_SCHEDULE,
  SYNC_DOCUMENTS,
  SYNC_MODIFIED_INVOICE,
  validStatuses,
  WarningIconWithTooltip,
} from '../../constants';
import InvoiceActions from '../../InvoiceActions';
import {
  FactoringActionReturnType,
  FactoringType,
  InvoiceModel,
} from '../../models/InvoiceModel';
import { getFactoringDisplayedActions } from '../../utils';
import FactoringActions from '../BulkActions/FactoringActions';
import FactoringSingleActions from '../SingleActions/FactoringSingleActions';

const disabledFactoringStatuses = [
  PAID_LOAD_STATUS_NAME,
  INVOICED_LOAD_STATUS_NAME,
];

export function Factoring({
  invoice,
  triggerAlert,
  handleFactoringResponse,
  canEditInvoice,
  isFactorValueUpdated,
}: {
  invoice: InvoiceModel;
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void;
  handleFactoringResponse: (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => void;
  canEditInvoice: boolean;
  isFactorValueUpdated?: () => void | undefined;
}) {
  const {
    control,
    setValue,
    watch,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext();
  const [factoring, setFactoring] = useState<FactoringType[]>([]);
  const [submitForFundingDialogOpen, setSubmitForFundingDialogOpen] =
    useState(false);
  const [sendToFactoringDialogOpen, setSendToFactoringDialogOpen] =
    useState(false);
  const [sendDocumentDialogOpen, setSendDocumentDialogOpen] = useState(false);
  const [updateAtFactoringDialogOpen, setUpdateAtFactoringDialogOpen] =
    useState(false);
  const [refreshDialogOpen, setRefreshDialogOpen] = useState(false);
  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const [isFactoringInvalid, setIsFactoringInvalid] = useState(false);

  const [factoringCompany, lineItems, messageOnInvoice] = watch([
    'factoringCompany',
    'lineItems',
    'messageOnInvoice',
  ]);
  useEffect(() => {
    const getFactoringStatic = async () => {
      const result = await InvoiceActions.getFactoringStatic();
      setFactoring(result || []);
    };
    getFactoringStatic();
  }, []);

  useEffect(() => {
    if (invoice.id) {
      setIsFactoringInvalid(invoice.isFactoringInvalid);
    }
  }, [invoice.id]);

  const resetFactoringRelatedFields = () => {
    reset(
      {
        ...getValues(),
        factoringInvoiceId: null,
        factoringStatus: null,
        factoringDocumentStatus: null,
        factoringNotes: null,
        factoringInvoiceUpdateDate: null,
        factoringScheduleId: null,
      },
      { keepDirty: true }
    );
  };

  const onFactoringChange = (data: any) => {
    if (data) {
      setIsFactoringInvalid(data.isFactoringInvalid);
      reset(
        {
          ...getValues(),
          factoringChargePercent: data.factoringCharge,
          factoringId: data.id,
          useFactoring: true,
          factoringProviderId: data.providerId,
          sourceFactoringNotes: data.invoiceFactoringNote,
        },
        { keepDirty: true }
      );
      // if selected factoring is the same as default factoring we should revert default values
      if (data.displayName === invoice.factoringCompany) {
        reset(
          {
            ...getValues(),
            factoringInvoiceId: invoice.factoringInvoiceId,
            factoringStatus: invoice.factoringStatus,
            factoringChargePercent: invoice.factoringChargePercent,
            factoringDocumentStatus: invoice.factoringDocumentStatus,
            factoringNotes: invoice.factoringNotes,
            factoringInvoiceUpdateDate: invoice.factoringInvoiceUpdateDate,
            factoringScheduleId: invoice.factoringScheduleId,
            factoringProviderId: invoice.factoringProviderId,
            sourceFactoringNotes: invoice.sourceFactoringNotes,
          },
          { keepDirty: true }
        );
      } else {
        // if we change factoring company name we should make null other factoring related fields
        setValue('useFactoring', true);
        resetFactoringRelatedFields();
      }
    } else {
      // if we remove factoring company name we should make null other factoring related fields
      setValue('useFactoring', false);
      setValue('factoringId', null);
      setValue('factoringProviderId', null);
      setValue('sourceFactoringNotes', null);
      setValue('factoringChargePercent', null);
      resetFactoringRelatedFields();
      setIsFactoringInvalid(false);
    }
    const invoiceFactoringNote = data.invoiceFactoringNote ?? '';
    if (messageOnInvoice) {
      invoiceFactoringNote &&
        setValue(
          'messageOnInvoice',
          messageOnInvoice + ', ' + invoiceFactoringNote
        );
    } else {
      setValue('messageOnInvoice', invoiceFactoringNote);
    }
  };

  const handleActionChange = async (actionName: string) => {
    // REFRESH, DISCONNECT -> modal
    if (actionName === SEND_TO_FACTORING) {
      setSendToFactoringDialogOpen(true);
    }
    if (actionName === SUBMIT_TO_SCHEDULE) {
      setSubmitForFundingDialogOpen(true);
    }
    if (actionName === SYNC_DOCUMENTS) {
      setSendDocumentDialogOpen(true);
    }
    if (actionName === SYNC_MODIFIED_INVOICE) {
      setUpdateAtFactoringDialogOpen(true);
    }
    if (actionName === REFRESH_FACTORING_STATUS) {
      setRefreshDialogOpen(true);
    }
    if (actionName === DISCONNECT_FACTORING) {
      setDisconnectDialogOpen(true);
    }
  };

  const disabledFactoringActions = useMemo(() => {
    return getFactoringDisplayedActions(invoice);
  }, [invoice]);

  const { hasFinancialInvoiceEdit, hasInvoiceAddPermission } =
    useLoadPermission();
  const disableInvoiceEditing = !hasFinancialInvoiceEdit || !canEditInvoice;

  const showFactoringActionsButton =
    !isDirty &&
    factoringCompany &&
    [
      COMPLETED_LOAD_STATUS_NAME,
      READY_TO_INVOICE,
      CANCELLED_LOAD_STATUS_NAME,
      INVOICED_LOAD_STATUS_NAME,
      PAID_LOAD_STATUS_NAME,
    ].includes(invoice.status) &&
    !(invoice.status === 'PAID' && !invoice.factoringStatus);

  const hasLineItems = lineItems?.length > 0;

  const invoiceCanceledStatus =
    invoice.status === CANCELLED_LOAD_STATUS_NAME &&
    !invoice.lineItems.length &&
    invoice.nonInvoiceable;

  return (
    <>
      <Box sx={{}}>
        <Typography sx={{ color: 'primary.main', fontWeight: '600' }}>
          {' '}
          Factoring Information
        </Typography>
      </Box>

      {/* {showFactoringActionsButton && hasLineItems && ( */}
      <Box
        sx={{
          display: 'flex',
          mb: 0,
          justifyContent: 'space-between',
          // m: 0,
          p: '10px 0px',
        }}
      >
        <Box sx={{ m: 0, p: 0 }}>
          <Typography variant={'body1'}>
            Factoring Status:{' '}
            {validStatuses[invoice?.factoringStatus as string]}
          </Typography>
          <Typography variant={'subtitle2'}>
            {`Last Updated: ${getFormattedDateTime(
              fromIsoToMoment(invoice?.factoringInvoiceUpdateDate)
            )}`}
          </Typography>
        </Box>
        {showFactoringActionsButton && hasLineItems && (
          <Box sx={{ width: 210 }}>
            <Select
              displayEmpty
              fullWidth
              labelId="label"
              id="select"
              onChange={(event) => {
                handleActionChange(event.target.value);
              }}
              sx={(theme) => {
                return {
                  background: theme.palette.primary.main,
                  borderRadius: '10px',
                  '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.contrastText,
                  },
                  height: '35px',
                };
              }}
              inputProps={{
                color: 'red',
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <Typography color="primary.contrast">
                      Factoring Actions
                    </Typography>
                  );
                }
                return (
                  <Typography color="primary.contrast">{selected}</Typography>
                );
              }}
              disabled={disableInvoiceEditing || invoiceCanceledStatus}
            >
              {FACTORING_ACTIONS.map((action) => {
                return (
                  <MenuItem
                    key={action}
                    value={action}
                    disabled={
                      action === SEND_TO_FACTORING
                        ? !hasInvoiceAddPermission ||
                          disabledFactoringActions.includes(action)
                        : disabledFactoringActions.includes(action)
                    }
                  >
                    {action}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        )}
      </Box>
      {/* )} */}
      <Box sx={{ display: 'flex', mb: 0 }}>
        <Box sx={{ flex: 1, m: 0, p: 0, pt: '3px', pr: '9px' }}>
          <SingleAutocompleteForm
            id={invoiceModuleFactoringInfo + 'FactoringCompany'}
            startAdornment={
              isFactoringInvalid && factoringCompany ? (
                <WarningIconWithTooltip
                  stripId={'customer'}
                  externalId={invoice.customer?.id}
                  text={'Factoring activation is failed or is pending.'}
                />
              ) : null
            }
            control={control}
            name={`factoringCompany`}
            fieldName={`displayName`}
            label={'Factoring Company'}
            options={factoring}
            fieldValue={'displayName'}
            onChangeCb={(data) => onFactoringChange(data)}
            disabled={
              disabledFactoringStatuses.includes(invoice.status) ||
              disableInvoiceEditing ||
              invoiceCanceledStatus
            }
          />
        </Box>
        <Box sx={{ flex: 1, m: 0, p: 0 }}>
          <TextField
            id={invoiceModuleFactoringInfo + 'FactoringCommission'}
            control={control}
            name={'factoringChargePercent'}
            label={'Factoring charges (%)'}
            disabled={
              disabledFactoringStatuses.includes(invoice.status) ||
              disableInvoiceEditing ||
              !factoringCompany ||
              invoiceCanceledStatus
            }
            onlyNumbers
          />
        </Box>
      </Box>

      {/* <Box sx={{ mb: 0 }}>
        <TextField
          id={invoiceModuleFactoringInfo + 'MessageOnInvoice'}
          control={control}
          name={'factoringNotes'}
          label={'Message on Invoice'}
          disabled={disableInvoiceEditing || invoiceCanceledStatus}
        />
      </Box> */}
      <FactoringActions
        sendToFactoringDialogOpen={sendToFactoringDialogOpen}
        setSendToFactoringDialogOpen={setSendToFactoringDialogOpen}
        refreshDialogOpen={refreshDialogOpen}
        setRefreshDialogOpen={setRefreshDialogOpen}
        submitForFundingDialogOpen={submitForFundingDialogOpen}
        setSubmitForFundingDialogOpen={setSubmitForFundingDialogOpen}
        invoices={[invoice]}
        triggerAlert={triggerAlert}
        handleFactoringResponse={handleFactoringResponse}
        isFactorValueUpdated={isFactorValueUpdated}
      />
      <FactoringSingleActions
        disconnectDialogOpen={disconnectDialogOpen}
        setDisconnectDialogOpen={setDisconnectDialogOpen}
        updateAtFactoringDialogOpen={updateAtFactoringDialogOpen}
        setUpdateAtFactoringDialogOpen={setUpdateAtFactoringDialogOpen}
        sendDocumentDialogOpen={sendDocumentDialogOpen}
        setSendDocumentDialogOpen={setSendDocumentDialogOpen}
        invoice={invoice}
        handleFactoringResponse={handleFactoringResponse}
        triggerAlert={triggerAlert}
      />
    </>
  );
}
