import React, { useState } from 'react';
import {
  DeleteLoadAlertRequest,
  EditLoadAlertRequest,
  LoadAlert,
} from '@/models';
import { visibilityService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import { IHttpErrorType } from '@/types';

export const useLoadAlertItem = (
  alert: LoadAlert,
  updateAlertsList: () => void
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [httpErrors, setHttpErrors] = useState<IHttpErrorType[] | null>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    data: any,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    if (action.label === 'Delete') {
      setShowDeleteAlert(true);
    }
    if (action.label === 'Edit') {
      setShowEditModal(true);
    }
    setAnchorEl(null);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteAlert = async () => {
    const request = new DeleteLoadAlertRequest();
    request.id = alert.id;
    const result = await visibilityService.deleteLoadAlert(request);
    if (!(result instanceof ServiceError)) {
      updateAlertsList();
      setShowDeleteAlert(false);
    }
  };

  const handleEditAlertDialogClose = () => {
    setShowEditModal(false);
  };

  const handleAlertEdit = async (data: any) => {
    const request = new EditLoadAlertRequest();
    request.loadId = alert.loadId;
    request.name = data.name;
    request.email = data.email;
    request.sendViaEmail = !!data.sendViaEmail;
    request.sendViaPhone = !!data.sendViaPhone;
    request.phoneData = data.phoneData;
    request.loadNotifications = data.loadNotifications;
    request.id = alert.id;
    const response = await visibilityService.editLoadAlert(request);
    if (!(response instanceof ServiceError)) {
      updateAlertsList();
      setShowEditModal(false);
    } else {
      if ((response.error as any).response.status === 417) {
        setHttpErrors([
          {
            fieldName: 'email',
            message: 'Email is already in use',
          },
        ]);
      }
    }
  };

  return {
    anchorEl,
    showDeleteAlert,
    showEditModal,
    handleMenuClose,
    handleMenuClick,
    handleMenuItemClick,
    handleDeleteModalClose,
    handleDeleteAlert,
    handleEditAlertDialogClose,
    handleAlertEdit,
    httpErrors,
  };
};
