import { QueryParams } from '../commonMixed/QueryParams';

export class GetLoadAlertFlagTypesRequest extends QueryParams {
  loadId!: string;
}

export class DeleteLoadAlertRequest extends QueryParams {
  id!: string;
}

export class GetLoadAlertsRequest extends QueryParams {
  loadId!: string;
}

export class CreateLoadAlertRequest extends QueryParams {
  loadId!: string;
  email!: string;
  loadNotifications!: { value: string; key: string }[];
  name!: string;
  phoneData!: {
    extension: string;
    phone: string;
    countryCode: string;
  } | null;
  sendViaEmail!: boolean;
  sendViaPhone!: boolean;
}

export class EditLoadAlertRequest extends QueryParams {
  loadId!: string;
  email!: string;
  loadNotifications!: { value: string; key: string }[];
  name!: string;
  phoneData!: {
    extension: string;
    phone: string;
    countryCode: string;
  } | null;
  sendViaEmail!: boolean;
  sendViaPhone!: boolean;
  id!: string;
}

export interface LoadAlertFlagType {
  value: string;
  key: string;
}

export interface LoadAlert {
  email: string;
  id: string;
  loadId: string;
  name: string;
  phoneData: {
    extension: string;
    phone: string;
    countryCode: string;
  } | null;
  sendViaEmail: boolean;
  sendViaPhone: boolean;
  loadNotifications: { value: string; key: string }[];
}

export class GetContactsRequest extends QueryParams {
  loadId!: string;
}

export interface LoadAlertContact {
  id: string;
  name: string;
  email: string;
  phoneData: {
    extension: string;
    phone: string;
    countryCode: string;
  } | null;
}
