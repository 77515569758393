import { documentService } from '../api';
import { ServiceError } from '../api/interfaces';
import {
  AssignDocumentRequest,
  AssignDocumentResponseType,
  DocumentSummaryResponse,
  Terminal,
} from '../models';
import TerminalShort from '../models/common/TerminalShort';
import StorageManager from '../StorageManager/StorageManager';
import { deleteFile } from '../subPages/expenses/utils/utils';

import { getDocumentTypes, uploadDocument } from '../subPages/users/utils';

export const getPrimaryTerminalOrFirstOne = (terminals: TerminalShort[]) =>
  terminals?.find((t) => t.isPrimary === true) || terminals[0];

export const getTerminalById = (id: string, terminals?: TerminalShort[]) => {
  if (terminals?.length && id) {
    return terminals.find((t) => t.id === id);
  }
  return null;
};
const assignDocument = async (
  file: DocumentSummaryResponse,
  terminalData: Terminal,
  type?: string
) => {
  const user = StorageManager.getUser() || {};

  const documentTypesList = await getDocumentTypes();

  if (!documentTypesList) return;
  const documentType = documentTypesList?.find(
    (item) => item.itemCode === 'OTHER'
  );
  const assignDocumentRequest = new AssignDocumentRequest({
    description: '',
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: 'OTHER',
    documentTypeId: documentType?.id || '',
    documentId: file.id,
    fileName: file.fileName,
    fileSize: file.fileSize,
    uploadDate: file.uploadDate,
    ownerId: user.id,
    terminalId: terminalData?.id,
    attachedEntities: [
      {
        properties: [
          {
            id: terminalData?.id.toString() || '',
            title: terminalData?.companyName,
          },
        ],
        type: type ? type : 'TERMINAL',
      },
    ],
  });
  const response = documentService.assignDocument(assignDocumentRequest);
  return response instanceof ServiceError ? null : response;
};
export const saveFile = async (
  terminalData: any,
  file: FileList,
  type?: string
): Promise<AssignDocumentResponseType | undefined> => {
  if (terminalData && !!file?.length) {
    const uploadedFile = await uploadDocument(file[0]);

    if (uploadedFile) {
      const document = await assignDocument(uploadedFile, terminalData, type);

      if (document) return document;
    }
    return;
  }
};

export const updateDocument = async (
  terminalData,
  newDocumnet,
  oldDocument,
  type
) => {
  if (newDocumnet) {
    if (oldDocument?.documentId && newDocumnet) {
      if (oldDocument?.documentId == newDocumnet?.documentId) {
        return oldDocument;
      }
      if (oldDocument?.documentId != newDocumnet?.id) {
        await deleteFile([oldDocument?.documentId]);
      }
    }
    const newFileData = await saveFile(terminalData, newDocumnet, type);
    return newFileData;
  } else if (oldDocument && oldDocument?.documentId) {
    await deleteFile([oldDocument?.documentId]);
    return oldDocument;
  }
  return '';
};
