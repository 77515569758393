import { Service } from '@/api/interfaces/Service';
import { ServiceError } from '@/api/interfaces/helperTypes';
import {
  CreateCustomAdvancedReportRequest,
  DeleteAdvancedReportRequest,
  DownloadAdvancedReportRequest,
  DownloadPredefinedAdvancedReportRequest,
  GetAdvancedReportsRequest,
} from '@/models/DTOs/AdvancedReports/Requests';
import { AdvancedReport } from '@/models/DTOs/AdvancedReports/AdvancedReports';
import { docType } from '@/utils/Doc';

export abstract class IAdvancedReportService extends Service {
  abstract getAdvancedReports(
    requestData: GetAdvancedReportsRequest
  ): Promise<AdvancedReport[] | ServiceError>;

  abstract createCustomAdvancedReport(
    requestData: CreateCustomAdvancedReportRequest
  ): Promise<AdvancedReport | ServiceError>;

  abstract deleteCustomAdvancedReport(
    requestData: DeleteAdvancedReportRequest
  ): Promise<boolean | ServiceError>;

  abstract downloadAdvancedReport(
    requestData: DownloadAdvancedReportRequest
  ): Promise<docType | ServiceError>;

  abstract downloadPredefinedAdvancedReport(
    requestData: DownloadPredefinedAdvancedReportRequest
  ): Promise<docType | ServiceError>;
}
