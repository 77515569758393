import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import {
  SelectTripButtonStyles,
  SelectTripIconButtonStyles,
  SelectTripIconStyles,
  SelectTripPuckStyles,
  SelectTripTextStyles,
  VerticalLineStyles,
  defaultContainerStyles,
  defaultContentStyles,
  defaultDottedPuckStyles,
  defaultPuckStyles,
} from '../styles';
import { DTIcon, HoldIcon } from '../static';
import {
  NEED_LOAD_CONTENT_CLASSNAME,
  NEED_LOAD_HOVER_CLASSNAME,
  NEED_LOAD_TEXT_HOVER_CLASSNAME,
  PUCK_HOVER_VISIBILITY_CLASSNAME,
} from '../config';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import {
  PUCK_ASSIGNEMENT_CANCEL_ID,
} from '../constants/puck.constant';
import ClearIcon from '@mui/icons-material/Clear';

interface IPuck {
  containerStyles?: object;
  puckStyles?: object;
  isDelayed?: boolean;
  originText?: string;
  middleText?: string;
  destinationText?: string;
  innerContentStyles?: object;
  delayedIcon?: React.ReactNode | JSX.Element;
  puckBackgroundColor?: string;
  isDottedPuck?: boolean;
  isEmptyPuck?: boolean;
  puckContainerColor?: string;
  showPuckOnHover?: boolean;
  warningsCount?: number | string | null;
  warnings?: Array<any>;
  puckInnerElement?: JSX.Element | null;
  isTypeNeedLoad?: boolean;
  iconBackgroundColor?: string;
  isTripSelect?: boolean;
  tripSelectConfig?: IPuckSelectTrip;
  onHold?: boolean;
}

type TypographyTemplateProps = {
  innerContentStyles?: object;
  text?: string;
  status?: string;
};

interface IPuckSelectTrip {
  text?: string;
  icon?: JSX.Element;
  iconStyles?: object;
  buttonLabel?: string;
  buttonStyles?: object;
  containerStyles?: object;
  allowOnlyCancel?: boolean;
  btnId?: string;
  cancelEl?: JSX.Element;
  showClearIcon?: boolean;
  cancelIconButtonStyles?: object;
}

const TypographyTemplate = (props: TypographyTemplateProps) => {
  return (
    <Typography
      className="puck-content-text"
      sx={{
        ...defaultContentStyles,
        ...props.innerContentStyles,
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Puck: React.FC<IPuck> = (props) => {
  const {
    isDelayed = false,
    isDottedPuck = false,
    isEmptyPuck = false,
    delayedIcon = <DTIcon />,
    showPuckOnHover = false,
    isTypeNeedLoad = false,
    isTripSelect = false,
    ...restProps
  } = props;

  const defaultContainerStylesMemo: object = useMemo(() => {
    if (isDottedPuck)
      return {
        ...defaultContainerStyles,
        ...defaultDottedPuckStyles,
        backgroundColor: restProps?.puckContainerColor || 'inherit',
      };
    if (isEmptyPuck)
      return {
        ...defaultContainerStyles,
        backgroundColor: restProps?.puckContainerColor || 'inherit',
      };
    return {
      ...defaultContainerStyles,
      backgroundColor:
        restProps?.puckContainerColor ?? restProps?.iconBackgroundColor,
    };
  }, [isDottedPuck, isEmptyPuck]);

  const defaultPuckStylesMemo: object = useMemo(() => {
    if (isDottedPuck || isEmptyPuck)
      return {
        ...defaultPuckStyles,
        backgroundColor: restProps.puckBackgroundColor || 'inherit',
        marginLeft: isDelayed ? '10px' : 0,
        justifyContent: 'center',
      };
    return {
      ...defaultPuckStyles,
      marginLeft: isDelayed ? '10px' : 0,
      backgroundColor: restProps.puckBackgroundColor,
    };
  }, [isDottedPuck, isDelayed, isEmptyPuck]);

  if (isTripSelect) {
    return <SelectTrip {...restProps?.tripSelectConfig} />;
  }

  return (
    <Box
      style={{
        ...defaultContainerStylesMemo,
        ...restProps.containerStyles,
      }}
      className={
        isTypeNeedLoad
          ? NEED_LOAD_HOVER_CLASSNAME
          : showPuckOnHover
            ? PUCK_HOVER_VISIBILITY_CLASSNAME
            : ''
      }
    >
      {isDelayed && (
        <Box display={'flex'} ml={1} alignItems={'center'} gap={'3px'}>
          {restProps?.onHold && <HoldIcon />}
          {Boolean(restProps?.warningsCount) &&
            (restProps?.warningsCount ?? delayedIcon)}
        </Box>
      )}
      <Box className={NEED_LOAD_CONTENT_CLASSNAME}>
        <Typography
          sx={{ height: '100%', alignItems: 'center', display: 'flex' }}
        >
          +
        </Typography>
      </Box>
      <Box
        className={isTypeNeedLoad ? NEED_LOAD_TEXT_HOVER_CLASSNAME : ''}
        style={{
          ...defaultPuckStylesMemo,
          ...restProps.puckStyles,
        }}
      >
        {restProps?.puckInnerElement ||
          (!(isDottedPuck || isEmptyPuck) && (
            <TypographyTemplate
              text={restProps.originText}
              innerContentStyles={restProps.innerContentStyles}
            />
          ))}
        <TypographyTemplate
          text={restProps.middleText}
          innerContentStyles={restProps.innerContentStyles}
        />
        {!(isDottedPuck || isEmptyPuck) && (
          <TypographyTemplate
            text={restProps.destinationText}
            innerContentStyles={restProps.innerContentStyles}
          />
        )}
      </Box>
    </Box>
  );
};

const SelectTrip: React.FC<IPuckSelectTrip> = (params) => {
  const { showClearIcon = false, ...props } = params;
  return (
    <Box
      sx={{
        ...defaultContainerStyles,
        ...SelectTripPuckStyles,
        ...props?.containerStyles,
      }}
      className="empty-select-trip"
    >
      <Box
        display={props?.allowOnlyCancel ? 'none' : 'flex'}
        alignItems={'center'}
      >
        <Typography sx={SelectTripTextStyles}>
          {props?.text ?? 'Select Trip Below'}
        </Typography>
        <Box display={'flex'}>
          {props?.icon ?? (
            <ArrowDownwardOutlinedIcon sx={SelectTripIconStyles} />
          )}
        </Box>
        <Box sx={VerticalLineStyles}></Box>
      </Box>

      {props?.cancelEl ?? showClearIcon ? (
        <IconButton
          className="cancel-trip-assignment-button"
          color="primary"
          sx={{
            ...SelectTripIconButtonStyles,
            ...props.cancelIconButtonStyles,
          }}
          id={props?.btnId ?? PUCK_ASSIGNEMENT_CANCEL_ID}
        >
          <ClearIcon
            sx={{ width: '18px', height: '18px', pointerEvents: 'none' }}
          />
        </IconButton>
      ) : (
        <Button
          id={props?.btnId ?? PUCK_ASSIGNEMENT_CANCEL_ID}
          variant="contained"
          sx={{ ...SelectTripButtonStyles, ...props?.buttonStyles }}
          color="primary"
        >
          {props?.buttonLabel ?? 'Cancel'}
        </Button>
      )}
    </Box>
  );
};
