import StorageManager from '@/StorageManager/StorageManager';
import { loadService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import { AXELE_PERMISSION_TYPE } from '@/common/Permission';
import { getHasPermission } from '@/common/Permission/utils/helperUtils';
import FormDialog from '@/common/Ui/FormDialog';
import { useLoadPermission } from '@/common/hooks/useLoadPermission';
import {
  ITagsAssignParamsRequest,
  ITagsBulkAssignParamsRequest,
  ITagsBulkAssignPayload,
  PaginatedTagsListQueryParams,
  TagCreateResponseDTO,
} from '@/models';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  debounce,
  InputAdornment,
  Stack,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TagInput } from '@/common/Tag/components/TagInput';
import { TagEntityType } from '@/models/DTOs/ManageTypes/Requests';

export const tagColorCode = [
  {
    red: 247,
    green: 123,
    blue: 114,
  },
  {
    red: 242,
    green: 152,
    blue: 78,
  },
  {
    red: 130,
    green: 199,
    blue: 132,
  },
  {
    red: 76,
    green: 175,
    blue: 80,
  },
  {
    red: 12,
    green: 107,
    blue: 185,
  },
  {
    red: 47,
    green: 59,
    blue: 88,
  },
  {
    red: 92,
    green: 102,
    blue: 125,
  },
  {
    red: 182,
    green: 189,
    blue: 199,
  },
];
export const getColotInfo = (color: string, textColor = 'white') => {
  const colorConfig = {
    color: `${textColor} !important`,
    background: `${color} !important `,
    padding: '4px, 8px, 4px, 8px !important',
    borderRadius: 1,
    '&:hover': {
      color: `${textColor} !important`,
      background: `${color} !important `,
      '& .MuiChip-deleteIcon': {
        color: `white !important`,
        width: '16px',
        height: '16px',
      },
    },
    '& .MuiChip-deleteIcon': {
      color: `${textColor} !important`,
      width: '16px',
      height: '16px',
    },
  };
  return colorConfig;
};

export const getColotLabelInfo = (color: string, textColor = 'white') => {
  const colorConfig = {
    color: `${textColor} !important`,
    background: `${color} !important `,
    padding: '5px 7px',
    margin: '5px',
    borderRadius: 1,
  };
  return colorConfig;
};

export const TagListComponent = ({ tagList }: any) => {
  return (
    <>
      {tagList?.map((innerValue: any) => (
        <Chip
          size="small"
          key={innerValue['name' as keyof InnerValue]}
          label={innerValue['name' as keyof InnerValue]}
          sx={getColotInfo(innerValue?.rgbColor || null)}
        />
      ))}
    </>
  );
};
export const TagListAuditLogComponent = ({ tagList }: any) => {
  return (
    <>
      {tagList?.map((innerValue: any) => (
        <Chip
          size="small"
          key={innerValue['displayName' as keyof InnerValue]}
          label={innerValue['displayName' as keyof InnerValue]}
          sx={{ ml: '2px' }}
        />
      ))}
    </>
  );
};

export const CustomTagComponent = ({ tags, id, tagsUpdated }: any) => {
  const { hasInvoiceEditPermission, hasLoadEditPermission } =
    useLoadPermission();
  const iEditable = hasInvoiceEditPermission || hasLoadEditPermission;
  const [isTagModel, setTagModel] = useState<boolean>(false);
  return (
    <Box sx={{ p: '10px 0px' }}>
      {tags?.length > 0 && (
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '400',
            color: 'text.secondary',
            paddingBottom: '5px',
          }}
        >
          Tag(s)
        </Typography>
      )}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={1}
          sx={{ width: '87%', paddingRight: 2 }}
        >
          {tags?.length > 0 && <TagListComponent tagList={tags} />}
          {!tags?.length && (
            <Typography
              sx={{
                fontStyle: 'italic',
                fontSize: '14px',
                fontWeight: '400',
                color: 'text.disabled',
                mt: '10px',
              }}
            >
              {' '}
              No tags selected
            </Typography>
          )}
        </Stack>
        <Stack
          sx={{
            width: '10%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'cente',
          }}
        >
          {iEditable && (
            <Button
              variant="outlined"
              href="#outlined-buttons"
              size="small"
              sx={{
                minWidth: '10px !important',
                width: '36px',
                height: '36px',
                borderRadius: '10px',
              }}
              onClick={() => setTagModel(true)}
            >
              <ModeEditOutlinedIcon />
            </Button>
          )}
        </Stack>
      </Box>
      <LazyLoadedMultiSelectDialog
        open={isTagModel}
        onClose={() => setTagModel(false)}
        id={id}
        tags={tags}
        onAction={tagsUpdated}
      />
    </Box>
  );
};

interface InnerValue {
  name: string;
}
export const getAutocompleteStyles = () => {
  const MuiInputLabelStyles: SxProps = {
    marginTop: '-5px',
  };

  const styles = {
    '.MuiAutocomplete-root': {
      '.MuiInputLabel-root': {
        ...MuiInputLabelStyles,
      },
    },
    '.MuiAutocomplete-inputRoot': {
      borderRadius: '1px',
      padding: '0px',
    },
    '.MuiAutocomplete-paper': {
      borderRadius: '1px',
      padding: '0px',
    },
  };

  return styles;
};

export const LazyLoadedMultiSelect = ({
  fieldValue,
  value,
  onChangeCb,
  defaultOptions,
  immutableModel,
  fieldName = 'name',
  lable,
  isBulkAssign = false,
  keyValue = 'selectedOptions',
}: any) => {
  const { setValue } = useFormContext();
  const [isTagModel, setTagModel] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);
  const [innerValues, setInnerValues] = useState<InnerValue[]>(value || []);
  const last = useRef(true);
  const pageNumber = useRef(1);

  const makeRequest = async (val: string, listBoxNode?: any) => {
    if (defaultOptions?.length > 0) {
      setOptions(defaultOptions);
      return;
    }
    if (loading && last.current) return;
    setLoading(true);
    const queryParams = new PaginatedTagsListQueryParams();
    queryParams.pageNumber = pageNumber.current;
    queryParams.pageSize = 20;
    queryParams.sort = '';
    queryParams.searchText = val;
    queryParams.isActive = true;
    const result = await loadService.getPaginatedTagsList(queryParams);
    try {
      if (result) {
        last.current = result.last;
        pageNumber.current++;
        if (listBoxNode && !defaultOptions) {
          if (result?.content?.length === 0) {
            setOptions([{}]);
          } else {
            setOptions((oldOptions) => {
              const newResults = result.content || result;
              return newResults?.length
                ? [...oldOptions, ...newResults]
                : oldOptions;
            });
            setLoading(false);
            listBoxNode.scrollTop =
              listBoxNode.scrollHeight -
              listBoxNode.scrollHeight / (pageNumber.current - 1);
          }
        } else {
          if (result?.content) {
            if (result?.content?.length === 0) {
              setOptions([{}]);
            } else {
              setOptions(result?.content);
            }
          } else {
            setOptions([{}]);
          }

          setLoading(false);
        }
      }
    } catch (error) {
      setOptions([{}]);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    // setOptions([]);
    setInputValue('');
  };

  const handleOpen = () => {
    setOpen(true);
    // onOpen && onOpen();
  };

  const handleMakeRequestOnInputChange = async (value: string) => {
    pageNumber.current = 1;
    makeRequest(value);
  };

  const handleChange = (data: any[]) => {
    const newData =
      data && fieldValue
        ? data.map((d) => (typeof d === 'object' ? d[fieldValue] : d))
        : data;
    setInnerValues(data);
    onChangeCb?.(data);
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (open) {
      handleMakeRequestOnInputChange(inputValue);
    }
  }, [inputValue, open]);
  useEffect(() => {
    setValue(keyValue, innerValues);
  }, [innerValues]);

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);

  const onDelete = (title: InnerValue) => () => {
    const data = innerValues.filter(
      (prevValue: InnerValue) =>
        prevValue['id' as keyof InnerValue] !== title['id' as keyof InnerValue]
    );
    onChangeCb?.(data);
    setInnerValues(data);
  };
  const styles = getAutocompleteStyles();
  return (
    <Box sx={styles}>
      <Autocomplete
        multiple
        id="lazy-loaded-multi-select"
        options={options}
        limitTags={1}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        loading={loading}
        onChange={(event, value) => {
          handleChange(value);
        }}
        ListboxProps={{
          onScroll: (event) => {
            const listBoxNode = event.currentTarget;
            if (
              Math.abs(
                listBoxNode.scrollHeight -
                  listBoxNode.clientHeight -
                  listBoxNode.scrollTop
              ) < 1 &&
              !last.current
            ) {
              makeRequest(inputValue, listBoxNode);
            }
          },
        }}
        value={innerValues}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        isOptionEqualToValue={(option: any, value) => {
          if (option) {
            if (fieldValue) {
              if (typeof value === 'object' && immutableModel) {
                return option[fieldValue] === value[fieldValue];
              }
              return option[fieldName] === value;
            }
            return option[fieldName] === value[fieldName];
          }
          return false;
        }}
        renderTags={(value, getTagProps) => {
          return (
            <>
              {value
                .slice(0, value.length)
                .map(
                  (option, index) =>
                    option.name && (
                      <Chip
                        {...getTagProps({ index })}
                        sx={getColotInfo(option.rgbColor)}
                        key={option[fieldName as keyof InnerValue]}
                        label={option[fieldName as keyof InnerValue]}
                        onDelete={onDelete(option)}
                      />
                    )
                )}
            </>
          );
        }}
        filterOptions={(options) => options}
        renderOption={(props, option, { selected }) => {
          return (
            <>
              {(props as any)?.['data-option-index'] === 0 &&
                // hasTagAddPermission &&
                !defaultOptions?.length && (
                  <Box
                    sx={{
                      p: '8px 16px',
                      borderBottom:
                        options?.length != 1 ? '1px solid grey' : '',
                      fontSize: '16px',
                      cursor: 'pointer',
                      '&:hover': {
                        background: '#1c20250a',
                      },
                    }}
                    onClick={() => {
                      setTagModel(true);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                      }}
                    >
                      Create New Tag
                    </Typography>
                  </Box>
                )}
              {option.name && (
                <Typography
                  fontSize={14}
                  component={'li'}
                  {...props}
                  key={props.id}
                >
                  <Box>
                    <Chip
                      label={option.name}
                      sx={getColotInfo(option.rgbColor)}
                      key={option['id']}
                    />
                  </Box>
                </Typography>
              )}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={'outlined'}
            onChange={debouncedChangeHandler}
            label={lable}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
              },
            }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'disabled',
              sx: {
                ...{
                  borderRadius: '6px',
                },
              },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                        right: 8,
                      }}
                      color="inherit"
                      size={16}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <CreateCustomTagsDialog
        open={isTagModel}
        onClose={() => setTagModel(false)}
        onAction={(result) => {
          const selectedOptions = innerValues;
          if (selectedOptions.length > 0) {
            selectedOptions.unshift(result?.data);
          } else {
            selectedOptions.push(result?.data);
          }
          onChangeCb?.(selectedOptions);
          setInnerValues(selectedOptions);
          setValue(keyValue, selectedOptions);
          setTagModel(false);
        }}
      />
    </Box>
  );
};

export default LazyLoadedMultiSelect;

export const getCircleStyle = (data: any, selected: any) => {
  let colorConfig = {
    height: '25px',
    width: '25px',
    backgroundColor: `rgba(${data.red} , ${data.green}, ${data.blue}, 1) !important `,
    borderRadius: '50%',
    display: 'inline-block',
    mr: '8px',
  };
  if (data.green === selected.green) {
    colorConfig = Object.assign(colorConfig, {
      borderStyle: 'solid',
      border: '3px solid #ffffff',
      outline: '3px solid black',
    });
  }
  return colorConfig;
};

export function CreateCustomTags(params: any) {
  const { setValue } = useFormContext();
  const [inputvalue, setInputValue] = useState<string>('');
  const [spaceError, setSpaceError] = useState<string>('');
  const [selectCode, setSelectCode] = useState<any>({
    blue: 130,
    green: 199,
    red: 132,
  });
  useEffect(() => {
    setValue('colorCode', selectCode);
  }, [selectCode]);
  useEffect(() => {
    setValue('name', inputvalue);
  }, [inputvalue]);
  useEffect(() => {
    if (params.isError.length) {
      setSpaceError(params.isError);
    }
  }, [params.isError]);
  return (
    <Box sx={{ display: 'inline-flex', width: '100%' }}>
      <Box sx={{ width: '50%' }}>
        <Typography sx={{ fontWeight: 400, fontSize: '12px', pb: '5px' }}>
          Tag Name
        </Typography>
        <TextField
          error={Boolean(spaceError.length)}
          size="small"
          required
          sx={{
            height: '20PX',
            width: '100%',
            '& input::placeholder': {
              fontSize: '12px',
            },
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          name={'name'}
          value={inputvalue}
          placeholder="Tag Name"
          onChange={(data) => {
            const scriptWithoutSpaces = data?.target?.value.replace(/\s/g, '');
            if (/\s/.test(data?.target?.value)) {
              setSpaceError('Tag cannot have spaces');
              return;
            }
            Boolean(spaceError.length) && setSpaceError('');
            if (data?.target?.value.length === 16) {
              setSpaceError(
                'Tag name should be not be more than 15 characters'
              );
              return;
            }
            setInputValue(data.target.value);
          }}
          helperText={spaceError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">
                  {inputvalue.length}/15
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ ml: '20px' }}>
        <Typography sx={{ fontWeight: 400, fontSize: '12px', pb: '10px' }}>
          Tag Color
        </Typography>
        {tagColorCode.map((data) => {
          return (
            <Box
              component="span"
              onClick={() => {
                setSelectCode(data);
              }}
              sx={getCircleStyle(data, selectCode)}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export function LazyLoadedMultiSelectDialog({
  open,
  onAction,
  onClose,
  id,
  tags,
}: {
  open: boolean;
  onAction?: ({ data }: { data: any }) => void;
  onClose?: () => void;
  tags?: any;
  id: number;
}) {
  return (
    <FormDialog
      data={{}}
      titleText="Edit Tag(s)"
      actionLabel="Update"
      open={open}
      width={682}
      onAction={async (data) => {
        const queryParams = new ITagsAssignParamsRequest();
        queryParams.loadId = id;
        const createTag = await loadService.loadAssignmentTag(
          data.selectedOptions,
          queryParams
        );
        if (createTag instanceof ServiceError) {
          return;
        }
        onAction?.(createTag);
        onClose?.();
      }}
      handleClose={onClose}
      contentRenderer={() => (
        <LazyLoadedMultiSelect lable={'Tags to add'} value={tags || []} />
      )}
      customStyles={{
        borderRadius: '8px !important',
      }}
    />
  );
}

export function CreateCustomTagsDialog({
  open,
  onAction,
  onClose,
  data,
}: {
  open: boolean;
  onAction?: ({ data }: { data: any }) => void;
  onClose?: () => void;
  data?: any;
}) {
  const [isError, setError] = useState('');
  return (
    <FormDialog
      titleText="Create New Tag"
      actionLabel="Create Tag"
      open={open}
      width={682}
      onAction={async (data) => {
        // revert old errors
        setError('');
        if (!data.name) {
          setError('Tag name is required');
          return;
        }
        const payLoad = new TagCreateResponseDTO({
          name: data.name,
          organizationId: StorageManager.getUser()?.organizationId,
          description: '',
          tagType: 'USER_DEFINED',
          rgbColor: data.colorCode,
          active: true,
        });
        const createTag = await loadService.createUserDefinedTag(payLoad);
        if (createTag instanceof ServiceError) {
          setError(createTag.error.response?.data?.errorMessage || 'Error');
          return;
        }
        onAction?.(createTag);
        setError('');
      }}
      handleClose={() => {
        onClose?.();
        setError('');
      }}
      contentRenderer={() => <CreateCustomTags isError={isError} />}
      customStyles={{
        borderRadius: '8px !important',
      }}
    />
  );
}

export const BulkCustomTagComponent = ({ data }: any) => {
  const [value, setValueKey] = React.useState(0);
  const { setValue } = useFormContext();
  const { hasInvoiceEditPermission, hasLoadEditPermission } =
    useLoadPermission();
  const iEditable = hasInvoiceEditPermission || hasLoadEditPermission;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue('keyValue', newValue);
    setValue('tagIdsToRemove', []);
    setValue('setLoadIds', []);
    setValueKey(newValue);
  };
  let loadsSelectedTags: any[] = [];

  data?.map((item: { tags: any }) => {
    loadsSelectedTags = [...loadsSelectedTags, ...item.tags];
  });
  const uniqueLoadsSelectedTags = Array.from(
    new Map(loadsSelectedTags.map((item) => [item.id, item])).values()
  );
  return (
    <Box sx={{}}>
      <Tabs value={value} onChange={handleChange} sx={{ marginBottom: '12px' }}>
        <Tab
          label="Add/Remove"
          sx={{ fontSize: '14px', fontWeight: '400', color: 'black' }}
        />
        <Tab
          label="Set"
          sx={{ fontSize: '14px', fontWeight: '400', color: 'black' }}
        />
      </Tabs>
      {value === 0 && iEditable && (
        <Stack spacing={2}>
          <TagInput
            fetchOptions
            onChange={(values) => setValue('tagIdsToUpdate', values)}
            entityType={TagEntityType.LOAD}
            placeholder="Tags to add"
          />
          <TagInput
            fetchOptions
            defaultValue={uniqueLoadsSelectedTags}
            onChange={(values) => setValue('tagIdsToRemove', values)}
            entityType={TagEntityType.LOAD}
            placeholder="Tags to remove"
          />
        </Stack>
      )}
      {value === 1 && iEditable && (
        <Box>
          <Typography
            sx={{ color: 'text.disabled', fontSize: '12px', pb: '10px' }}
          >
            This action will <b>replace all tags </b> currently applied to the
            selected load(s) with the ones chosen below{' '}
          </Typography>
          <TagInput
            fetchOptions
            onChange={(values) => setValue('tagIdsToUpdate', values)}
            entityType={TagEntityType.LOAD}
            placeholder="Set Tags"
          />
        </Box>
      )}
    </Box>
  );
};
const styleContentBox = {
  componentTheme: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '12px 10px!important',
        },
      },
    },
  },
};
export function BulkLoadCustomTagsDialog({
  open,
  onAction,
  onClose,
  loads,
}: {
  open: boolean;
  onAction?: ({ data }: { data: any }) => void;
  onClose?: () => void;
  loads?: any;
}) {
  return (
    <FormDialog
      titleText="Edit Tag(s)"
      actionLabel="Update"
      open={open}
      width={682}
      onAction={async (data) => {
        const queryParams = new ITagsBulkAssignParamsRequest();
        queryParams.overwrite = Boolean(data?.keyValue || 0);
        const payLoad = new ITagsBulkAssignPayload();
        payLoad.loadIds = loads?.map((item: { id: string }) => item.id);
        payLoad.tagIdsToUpdate = data.tagIdsToUpdate?.map(
          (item: { id: string }) => item.id
        );
        payLoad.tagIdsToRemove = data.tagIdsToRemove?.map(
          (item: { id: string }) => item.id
        );
        const loadBulkAssignmentTag = await loadService.loadBulkAssignmentTag(
          payLoad,
          queryParams
        );
        if (loadBulkAssignmentTag instanceof ServiceError) {
          return;
        }
        onAction?.(payLoad?.loadIds);
      }}
      handleClose={onClose}
      contentRenderer={() => <BulkCustomTagComponent data={loads} />}
      customStyles={{
        borderRadius: '8px !important',
        color: 'text.primary',
      }}
      componentTheme={styleContentBox.componentTheme}
    />
  );
}

export const useTagsPermission = () => {
  const hasTagViewPermission = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.TAGS_VIEW],
  });
  const hasTagRemovePermission = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_REMOVE],
  });
  const hasTagAddPermission = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.TAGS_ADD],
  });
  const hasTagEditPermission = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.TAGS_EDIT],
  });
  return {
    hasTagViewPermission,
    hasTagRemovePermission,
    hasTagAddPermission,
    hasTagEditPermission,
  };
};
