import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CircularProgressTextContainerStyles,
  CircularProgressTextStyles,
  HOSTextStyles,
  HOSTitleStyles,
  driverToolTipBottomContainerStyles,
  driverToolTipContainerStyles,
  driverToolTipProgressTextStyles,
  hosOutDatedTextStyles,
} from '../styles';
import { IKeyContent, IProgressContent, ITooltipHOSSection } from '../types';
import React from 'react';

interface ICircularProgress {
  value?: number;
  innerText?: string;
  color?: string;
  tooltipStyles?: object;
  tooltipTitle?: string;
  tooltipArrowStyles?: object;
  labelStyles?: object;
}

const CircularProgressBar: React.FC<ICircularProgress> = (props) => {
  const {
    value,
    innerText,
    color = '#5482D1',
    tooltipStyles,
    tooltipTitle,
    tooltipArrowStyles,
    labelStyles = {},
  } = props;
  return (
    <Box
      sx={{ position: 'relative', display: 'inline-flex', m: 'auto' }}
      alignItems="center"
    >
      <Tooltip
        title={tooltipTitle}
        placement="right"
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: '#1F2E3D!important',
              ...tooltipStyles,
            },
          },
          arrow: {
            sx: {
              color: '#1F2E3D',
              ...tooltipArrowStyles,
            },
          },
        }}
        arrow={true}
      >
        <div>
          <CircularProgress
            variant="determinate"
            sx={{
              color: '#E0E0E0',
            }}
            size={45}
            thickness={4}
            value={100}
          />
          <CircularProgress
            disableShrink
            value={value}
            size={45}
            variant="determinate"
            sx={{
              animationDuration: '550ms',
              position: 'absolute',
              left: 0,
              transform: 'scaleX(-1) rotate(-90deg)!important',
              color: color,
            }}
          />
          <Box sx={CircularProgressTextContainerStyles}>
            <Typography
              variant="caption"
              component="p"
              sx={{ ...CircularProgressTextStyles, ...labelStyles }}
            >
              {innerText || value}
            </Typography>
          </Box>
        </div>
      </Tooltip>
    </Box>
  );
};

const renderKeyValueDataSection = (content: IKeyContent) => {
  const { key, value, keyStyles, valueStyles } = content;
  return (
    <>
      <Box maxWidth={100}>
        <Typography
          sx={{
            ...HOSTextStyles,
            ...keyStyles,
          }}
        >
          {key}
        </Typography>
      </Box>
      {value && (
        <Typography
          sx={{
            ...HOSTextStyles,
            fontWeight: 700,
            fontSize: '12px',
            paddingLeft: '2px',
            ...valueStyles,
          }}
        >
          {` ${value}`}
        </Typography>
      )}
    </>
  );
};

const TooltipHOSSection: React.FC<ITooltipHOSSection> = (props) => {
  const { progress, progressSecondary, primaryDriverName, secondaryDriverName, ...restProps } = props;

  const showDivider = () => {
    if (
      restProps?.keyValues?.length &&
      (progress?.length || restProps?.caption?.length || restProps?.isOutDated)
    )
      return <Divider sx={{ border: '1px solid #CCDFFF', margin: '8px 0' }} />;
    return <></>;
  };

  return (
    <Box
      sx={{ p: '16px', ...driverToolTipBottomContainerStyles }}
      id="driver-tooltip-container"
    >
      {restProps?.title && (
        <Typography sx={HOSTitleStyles}>{restProps?.title}</Typography>
      )}
      <Typography
        sx={{
          ...driverToolTipProgressTextStyles,
          textAlign: 'left',
          marginBottom: 1,
        }}
      >
        {primaryDriverName}
      </Typography>
      <Box sx={driverToolTipContainerStyles}>
        {restProps?.isOutDated ? (
          <Typography
            style={{
              ...hosOutDatedTextStyles,
              ...restProps?.ouDatedTextStyles,
            }}
          >
            {restProps?.outDatedText ?? 'HOS is Outdated'}
          </Typography>
        ) : (
          progress?.map((content: IProgressContent) => (
            <Box sx={{ textAlign: 'center' }} key={content.value}>
              <CircularProgressBar
                value={content.value}
                innerText={content.label}
                color={content.color ?? '#07B2B8'}
                labelStyles={content.labelStyles}
              />
              {content.caption && (
                <Typography sx={driverToolTipProgressTextStyles}>
                  {content.caption}
                </Typography>
              )}
            </Box>
          ))
        )}
      </Box>
      {restProps?.caption && (
        <Typography
          sx={{
            ...HOSTextStyles,
            marginTop: '2px',
            ...restProps?.captionStyles,
          }}
        >
          {restProps?.caption}
        </Typography>
      )}
      {showDivider()}
      <Box>
        {restProps?.keyValues?.map((keyValue: IKeyContent) => (
          <Stack
            direction={'row'}
            alignItems={'baseline'}
            key={keyValue.key}
            marginTop={'2px'}
            sx={keyValue?.containerStyles}
          >
            {renderKeyValueDataSection(keyValue)}{' '}
          </Stack>
        ))}
      </Box>
      {progressSecondary && (
        <>
          {showDivider()}
          <Typography
            sx={{
              ...driverToolTipProgressTextStyles,
              textAlign: 'left',
              marginBottom: 1,
            }}
          >
            {secondaryDriverName}
          </Typography>
          <Box sx={driverToolTipContainerStyles}>
            {progressSecondary?.map((content: IProgressContent) => (
              <Box sx={{ textAlign: 'center' }} key={content.value}>
                <CircularProgressBar
                  value={content.value}
                  innerText={content.label}
                  color={content.color ?? '#07B2B8'}
                  labelStyles={content.labelStyles}
                />
                {content.caption && (
                  <Typography sx={driverToolTipProgressTextStyles}>
                    {content.caption}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
          {restProps?.captionSecondary && (
            <Typography
              sx={{
                ...HOSTextStyles,
                marginTop: '2px',
                ...restProps?.captionStyles,
              }}
            >
              {restProps?.captionSecondary}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default TooltipHOSSection;
