export const TripsConstants = {
  all: 'All ',
  needDrivers: 'Need Drivers',
  activeTrips: 'Active Trips',
  toBeSettled: 'To Be Settled',
  pastTrips: 'Past Trips',

  NoRowsOverlayNodatatodisplay: 'No data to display',

  TitleUnassignedTrips: 'Unassigned Trips',
  TitleTrips: 'Trips',
  tripplanned: 'Planned',
  tripavailable: 'Available',
  tripId: 'Trip ID',
  tripStatus: 'Trip Status',
  legs: 'Legs',
  tripStartLocation: 'Trip Start Location',
  tripEndLocation: 'Trip End Location',
  tripStart: 'Trip Start',
  tripEnd: 'Trip End',
  settlementStatus: 'Settlement Status',
  settlementAmount: 'Settlement Amount',
  revenue: 'Revenue',
  tripMiles: 'Trip Miles',
  emptyMiles: 'Empty Miles',
  tripStartDate: 'Trip Appointment Start Date',
  tripStartFrom: 'Start From',
  actualTripStartDate: 'Trip Actual Start Date',
  actualTripEndDate: 'Trip Actual End Date',
  tripStartTo: 'Start To',
  tripEndDate: 'Trip Appointment End Date',
  tripEndFrom: 'End From',
  tripEndTo: 'End To',
  tripStartState: 'Trip Start State',
  tripEndState: 'Trip End State',
  hasRelay: 'Has Relay',
  chassis: 'Chassis',
  container: 'Container',
  reference: 'Reference',
  dispatchTerminal: 'Dispatch Terminal',
  delayVoilation: 'Delay Violation',
  detention: 'Detention',
  needDriversNow: 'Need Drivers Now',
  driverOperationMode: 'Driver Operation Mode',
  driverOperationModeSolo: 'Solo',
  driverOperationModeTeam: 'Team',
  createdDateTime: 'Created Date-Time',
  manifestID: 'ManifestID',
  holdState: 'Hold',
  onHoldState: 'On Hold',
  loadID: 'Load ID',
  trailer: 'Trailer',
  tractor: 'Tractor',
  driver: 'Driver',
  carrier: 'Carrier',
  brokered: 'Brokered',
  canBeBrokered: 'Eligible to Broker',
  originLocationName: 'Origin Location Name',
  destinationLocationName: 'Destination Location Name',
  emptyTrip: 'Empty Trip',
  loadPriority: 'Priority',
  customer: 'Customer',
  delayViolation: 'Delay Violation',
  dispatcher: 'Dispatcher',

  TripFiltersGeneral: 'General',
  TripFiltersTripInformation: 'Trip Information',
  TripFiltersRouteInformation: 'Route Information',

  TripSeltlementStatusPending: 'Pending',
  TripSeltlementStatusInReview: 'In Review',
  TripSeltlementStatusReviewed: 'Reviewed',
  TripSeltlementStatusClosed: 'Closed',

  triptableSettings: 'TableSettings',
  tripshowHideColumns: 'Show/Hide Columns',
  tripdownloadTableData: 'Download Table Data',
  tripreset: 'Reset',

  TripAddNewLoadoption1: '1. Upload Ratecon Document or Select Template',
  TripAddNewLoadCheckList: 'Check the list of supported RC Documents.',
  TripAddNewLoadCheckListLearnMore: 'Learn More',
  TripAddNewLoadCheckListOr: 'Or',
  TripAddNewLoadChooseATemplate: 'Choose a template',
  uploadFileSectionConfigsDragandDrop: 'Drag & Drop Ratecon doc',
  uploadFileSectionConfigsBrowseFiles: 'Browse Files',
  TripAddNewLoadCheckListSupportedRCDocument: 'Supported RC Document',
  SupportedRCDocumentsLoadOpssupportsdataextraction:
    'LoadOps supports data extraction from rate confirmation pdf documents from the following shippers/brokers:',
  SupportedRCDocumentsLoadOpsiscurrentlytoaddsupportformoreshippersbrokers:
    'LoadOps is currently working to add support for more shippers/brokers',
  SupportedRCDocumentsIfyouwouldlikeLoadOpstosupportadditionalbrokers:
    'If you would like LoadOps to support additional shippers/brokers, send 5-10 different original PDFs of sample rate confirmations from your customers to',
  SupportedRCDocumentssupportloadopscom: 'loadops.support@optym.com',
  SupportedRCDocumentsNotePDFsmustbeoriginalandcannotbescannedversions:
    'Note: PDFs must be original and cannot be scanned versions.',

  TripAddNewLoadOption2: '2. Customer Details',
  TripAddNewLoadOptionCustomerName: 'Customer Name',
  TripAddNewLoadOptionContact: 'Contact',
  TripAddNewLoadOptionReference: 'Reference#',
  TripAddNewLoadOptionFactoringCompany: 'Factoring Company',
  TripAddNewLoadOptionInvoiceTerminal: 'Invoice Terminal*',

  TripAddNewLoadOption3: '3. Stop Information',
  TripAddNewLoadOption3Info:
    'Create a load route by adding stops, splitting loads and entering pickup / dropoff information.',
  TripAddNewLoadOption3AddStop: 'Add Stop',
  TripAddNewLoadOption3AddRelay: 'Add Relay',
  TripAddNewLoadOption3BusinessName: 'Business Name',
  TripAddNewLoadOption3Address: 'Address',
  TripAddNewLoadOption3AddressManual: 'Address',
  TripAddNewLoadOption3FixedAppointment: 'Fixed Appointment',
  TripAddNewLoadOption3SubLabel:
    'Check this if you want to set fixed appointment',
  TripAddNewLoadOption3StartDateTime: 'Start Date + Time',
  TripAddNewLoadOption3EndDateTime: 'End Date + Time',
  TripAddNewLoadOption3Addlocationgeofence: 'Add location & geofence',
  TripAddNewLoadOption3Editlocationgeofence: 'Edit location & geofence',
  TripAddNewLoadOption3SetLocationBoundary: 'Set Location & Boundary',
  TripAddNewLoadOption3Satellite: 'Satellite',
  TripAddNewLoadOption3Map: 'Map',
  TripAddNewLoadOption3Cancel: 'Cancel',
  TripAddNewLoadOption3Save: 'Save',
  TripAddNewLoadOption3Pickup: 'Pickup',
  TripAddNewLoadOption3DropOff: 'Drop Off',
  TripAddNewLoadOption3ShowLessFields: 'Show Less Fields',
  TripAddNewLoadOption3ShowMoreFields: 'Show More Fields',
  TripAddNewLoadOption3Activity: 'Activity ',
  TripAddNewLoadOption3HandlingTimeMin: 'Handling Time (Min)',
  TripAddNewLoadOption3Notes: 'Notes',
  TripAddNewLoadOption3DriverAssist: 'Driver Assist',
  TripAddNewLoadOption3DriverAssistSubLabel:
    'Check this if driver assistance is needed',
  TripAddNewLoadOption3Reference: 'Reference #',
  TripAddNewLoadOption3Name: 'Name',
  TripAddNewLoadOption3Email: 'Email',
  TripAddNewLoadOption3Phone: 'Phone',
  TripAddNewLoadOption3Ext: 'Ext.',

  TripAddNewLoadOption4: '4. Load Details',
  TripAddNewLoadOption4EquipmentType: 'Equipment Type: ',
  TripAddNewLoadOption4LoadType1: 'Load Type: ',
  TripAddNewLoadOption4RequiredEquipmentType: 'Required Equipment Type',
  TripAddNewLoadOption4Seal: 'Seal #',
  TripAddNewLoadOption4NotesForDispatching: 'Notes For Dispatching',
  TripAddNewLoadOption4Weight: 'Weight (lbs)',
  TripAddNewLoadOption4LoadType: 'Load Type',

  TripAddNewLoadOption5Commodity: '5. Commodity',
  TripAddNewLoadOption5AddNewCommodity: 'Add New Commodity',
  TripAddNewLoadOptionEditCommodity: 'Edit Commodity',

  TripAddNewLoadOption5Quantity: 'Quantity',
  TripAddNewLoadOption5PackageType: 'Package Type',
  TripAddNewLoadOption5CommodityType: 'Commodity Type',
  TripAddNewLoadOption5Description: 'Description',
  TripAddNewLoadOption5Weight: 'Weight (lbs.)',
  TripAddNewLoadOption5Volume: 'Volume (cu.ft.)',
  TripAddNewLoadOption5Stackable: 'Stackable?',
  TripAddNewLoadOption5FloorLoad: 'Floor Load?',
  TripAddNewLoadOption5Dimensions: 'Dimensions',
  TripAddNewLoadOption5Length: 'Length (ft.)',
  TripAddNewLoadOption5Width: 'Width (ft.)',
  TripAddNewLoadOption5Height: 'Height (ft.)',
  TripAddNewLoadOption5AddCommodity: 'Add Commodity',

  TripAddNewLoadOption6Title: '6. Invoice Details',
  TripAddNewLoadOption6RevenueBase: 'Revenue - Base',
  TripAddNewLoadOption6RevenueAccessorial: 'Revenue - Accessorial',
  TripAddNewLoadOption6BaseType: 'Base Type',
  TripAddNewLoadOption6AccessorialType: 'Accessorial Type',
  TripAddNewLoadOption6BaseAmount: 'Base Amount ($)',
  TripAddNewLoadOption6Rate: 'Rate ($)',
  TripAddNewLoadOption6InternalInvoiceNotes: 'Internal Invoice Notes',
  TripAddNewLoadOption6MessageonInvoice: 'Message on Invoice',
  TripAddNewLoadOption6BillofLanding: 'Bill of Lading (BOL)',
  TripAddNewLoadOption6PBillofLandingSubLabel:
    'Check this if the load requires BOL document',
  TripAddNewLoadOption6ProofofDelivery: 'Proof of Delivery',
  TripAddNewLoadOption6ProofofDeliverySubLabel:
    'Check this if the load requires a document for Proof of Delivery',
  TripAddNewLoadAddAccessorialItem: '+ Add Accessorial Item',
  TripAddNewLoadOptionAddAccessorialItem: 'Add Accessorial Item',
  TripAddNewLoadOption6Quantity: 'Quantity',
  TripAddNewLoadOption6Amount: 'Amount',
  TripAddNewLoadOption6Description: 'Description',

  TripAddNewLoadInvoiceTotal: 'Invoice Total',
  TripAddNewLoadFactoringCharges: 'Factoring Charges ($)',
  TripAddNewLoadMountDue: 'Amount Due ($)',
  TripAddNewLoadOptionSaveasTemplate: 'Save as Template',

  TripAddNewLoadPopOverText:
    'Do you want to leave this page without creating the load?',

  TripSearchTrips: 'Search Trips',

  FloatingActionButtonSectionAddTripStop: 'Add Trip Stop',
  FloatingActionButtonSectionAddLoadStop: 'Add Load Stop',
  FloatingActionButtonSectionAddRelay: 'Add Relay',
  RelayStopFormConnectionPoint: 'Connection Point',
  RelayStopFormBusinessName: 'Business Name',
  RelayStopFormAddress: 'Address',
  RelayStopFormTrailerDropOffAppointment: 'Trailer Drop Off Appointment',
  RelayStopFormFixedAppointment: 'Fixed Appointment',
  RelayStopFormFixedAppointmentSubLabel:
    'Check this if you want to set fixed appointment',
  RelayStopFormStartDateTime: 'Start Date + Time',
  RelayStopFormEndDateTime: 'End Date + Time',
  RelayStopFormHandlingTime: 'Handling Time (Min)',
  RelayStopFormRevenueShare: 'Revenue Share (%)',
  RelayStopFormDriverAssist: 'Driver Assist',
  RelayStopFormDriverAssistSubLabel:
    'Check this if driver assistance is needed',
  RelayStopFormTrailerPickupAppointment: 'Trailer Pickup Appointment',

  TripPanelTabRoute: 'Route',
  TripPanelTabFinance: 'Finance',
  TripPanelTabCarrierPayment: 'Carrier Payment',

  DispatchSheetDownload: 'Download',
  DispatchSheetEmail: 'Email',
  DispatchSheetPreview: 'Preview',

  TripPlanDispatchRevenue: 'Dispatch Revenue ($)',
  TripPlanViewMap: 'View Map',

  TripAssignComponentAssignDispatcher: 'Assign Dispatcher',
  DispatcherAssignmentToLoadAssignDispatcher: 'Assign Dispatcher',
  DispatcherAssignmentToLoadDispatcher: 'Dispatcher',
  DispatcherAssignmentToLoadAssign: 'Assign',

  TripAssignComponentAssignDriver: 'Assign Driver',
  DriverAssignmentToLoadAssignDriver: 'Assign Driver',
  DriverAssignmentFormDriver: 'Driver',
  DriverAssignmentFormAssign: 'Assign',
  DriverAssignmentFormPlan: 'Plan',
  DriverAssignmentFormPreviousTrip: 'Previous Trip',
  DriverAssignmentToLoadSave: 'Save',

  TripAssignComponentAssignTractor: 'Assign Tractor',
  TractorAssignmentToLoadAssignTractor: 'Assign Tractor',
  TractorAssignmentFormTractor: 'Tractor',
  TractorAssignmentToLoadAssign: 'Assign',

  TripAssignComponentAssignTrailer: 'Assign Trailer',
  TrailerAssignmentToLoadAssignTrailer: 'Assign Trailer',
  TrailerAssignmentToLoadAssign: 'Assign',
  TrailerAssignmentToLoadTrailer: 'Trailer',

  DispatchSheetDispatch: 'Dispatch',
  DispatchSheetCarrier: 'Carrier',
  DispatchSheetRateConfirmation: 'Rate Confirmation',

  TRIP_STATUS_LISTPlanned: 'Planned',
  TRIP_STATUS_LISTAvailable: 'Available',
  TRIP_STATUS_LISTAssigned: 'Assigned',
  TRIP_STATUS_LISTDispatched: 'Dispatched',
  TRIP_STATUS_LISTInTransit: 'In Transit',
  TRIP_STATUS_LISTCompleted: 'Completed',
  TRIP_STATUS_LISTCanceled: 'Cancelled',

  loadFinancials: 'Load Financials',
  tripFinancials: 'Trip Financials',

  factoringchargededucted: 'Factoring charge deducted', //tooltip

  totalMoneyOut: 'Total Money Out',
  totalMoneyIn: 'Total Money In',
  totalRevenueShare: 'Total Revenue Share',
  deleteTripOnHold:
    'Trip cannot be deleted as it is associated with load/manifest which is on hold',
  deleteLoadOnHold: 'Load cannot be deleted as it is on hold',
  preLoaded: 'Pre-Loaded',
  dropTrailer: 'Drop Trailer',
};
