export enum E3DotMenuType {
  DUPLICATE = 'DUPLICATE',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
  MARK_AS_PAID = 'MARK_AS_PAID',
  MARK_AS_INVOICE = 'MARK_AS_INVOICE',
  DISPATCH = 'DISPATCH',
  DELETE = 'DELETE',
  AVAILABLE = 'AVAILABLE',
  UPDATE_PROGRESS = 'UPDATE_PROGRESS',
  MARK_AS_HOLD = 'MARK_AS_HOLD',
  RELEASE_LOAD = 'RELEASE_LOAD',
  CANCEL_LOAD = 'CANCEL_LOAD',
}
