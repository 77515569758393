import { CommodityTypesListRequest } from '../../models';
import { annotateNameAsync, IUtilsService } from '../interfaces';
import axiosInstance from '../../axios/axiosInstance';

const getCommodityTypesListURL =
  '/web/preference/api/v2/commodity/items/getByOrganizationId';

export class UtilsService extends IUtilsService {
  @annotateNameAsync
  async getCommodityTypes(requestData: CommodityTypesListRequest) {
    const params = requestData.getParamsFromRequest();

    try {
      const response = await axiosInstance.get(getCommodityTypesListURL, {
        params,
      });

      const data = await response.data;
      return Promise.resolve(data);
    } catch (error) {
      return Promise.resolve(null);
    }
  }
}
