import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React from 'react';

interface IProps {
  isLoading: boolean;
  onClick: (event: any) => void;
  onCreateTemplate?: () => void;
  onAutomationApply?: () => void;
}

const SubmitButton: React.FC<IProps> = ({
  isLoading,
  onClick,
  onCreateTemplate,
  onAutomationApply,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleOptionClick = async (event: React.MouseEvent<HTMLLIElement>) => {
    switch (event.currentTarget.textContent) {
      case options[0]:
        onCreateTemplate?.();
        break;
      case options[1]:
        onAutomationApply?.();
        break;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        size="large"
        sx={{ boxShadow: 'none' }}
        ref={anchorRef}
      >
        <Button
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={16} /> : <></>}
          onClick={onClick}
        >
          Save
        </Button>
        <Button onClick={handleToggle}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 9999,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      onClick={handleOptionClick}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export { SubmitButton };

const options = ['Save as Template', 'Apply Automation'];
