import { LoadViewTranslations } from './modal';
import { LoadPopupTranslations } from './popup';
export const LoadConstants = {
  noLoads: 'You have no loads in LoadOps. Why don’t we change that?',
  allLoads: 'All Loads',
  needDrivers: 'Need Drivers',
  activeLoads: 'Active Loads',
  toBeInvoiced: 'To Be Invoiced',
  toBeSettled: 'To Be Settled',
  pastLoads: 'Past Loads',

  settlementStatusPending: 'Pending',
  settlementStatusInReview: 'In Review',
  settlementStatusClosed: 'Closed',

  loadTypeManifest: 'Manifest',
  loadTypePartial: 'Partial',
  loadTypeFTL: 'FTL',

  loadsOverviewMiEmpty: 'Empty Miles',
  loadsOverviewLoaded: 'Loaded',
  loadsOverviewRevenue: 'Revenue',
  loadsOverviewTrips: 'Trips',
  loadsOverviewMile: 'mile',
  loadsOverviewPickup: 'Pickup',
  loadsOverviewDropOff: 'Drop Off',
  loadsOverviewCustomerName: 'Customer',
  loadsOverviewPrimaryContact: 'Primary Contact',
  loadsOverviewFactoringCompany: 'Factoring Company',
  loadsOverviewTracking: 'Tracking',
  loadOverViewRef: 'Ref #',
  loadOverViewPo: 'PO #',
  loadOverViewBol: 'BOL #',
  loadOverViewPriority: 'Priority',
  loadOverViewBroker: 'Is Brokered?',
  loadOverViewEquipment: 'Req. Equipment',
  loadOverViewSeal: 'Seal',
  loadOverViewLoadType: 'Load Type',
  loadOverViewTags: 'Tags',
  loadOverViewLoadId: 'Load Id',
  loadOverViewRefMode: 'Ref. Mode',
  loadOverViewTemp: 'Temp',
  loadOverViewSet: 'Set +/-',
  loadOverViewWeight: 'Weight',
  loadOverViewNotes: 'Notes',

  loadOverViewChassis: 'Chassis#',
  loadOverViewContainer: 'Container',
  loadOverViewImpExp: 'Import/Export',
  loadOverViewBooking: 'Booking #',
  loadOverViewAppt: 'Appointment',

  loadsRoutesLoadedMiles: 'Loaded Miles',
  loadsRoutesTripMiles: 'Trip Miles',
  loadsRoutesNumberOfStops: 'Number of Stops',
  loadsRoutesDispatchSheet: 'Dispatch Sheet',
  loadsRoutesMapView: 'Map View',

  loadsFinanceChangeRevenueShare: 'Change Revenue Share',

  loadsInoviceAddBaseItem: '+ Add Base Item',

  loadsTrackingShareTracking: 'Share Tracking',
  loadsTrackingAddNewTracker: 'Add New Alert',
  loadsTrackingSelectAtleastOneNotification: 'Select at least one notification',
  loadsTrackingAllNotifications: 'All Notifications',
  loadTsrackingSendAllNotifications: 'Send all notifications to a user',
  loadsTrackingAddTracker: 'Add Alert',
  loadsTrackingURL: 'Tracking URL',
  loadsTrackingTotalNum: 'Total #',

  loadsTrackingArrivedAtPickup: 'Arrived at pickup',
  loadsTrackingEnRouteToDelivery: 'Enroute to delivery',
  loadsTrackingArrivedAtDeliveryLocation: 'Arrived at delivery location',
  loadsTrackingLoadingDoneAtPickupLocation: 'Loading done at pickup location',
  loadsTrackingUnloadingDoneAtDeliveryLocation:
    'Unloading done at delivery location',
  loadsTrackingEnRouteToPickup: 'Enroute to pickup',
  loadsTrackingArrivedAtDelivery: 'Arrived at delivery',
  loadsTrackingCompletionOfDelivery: 'Completion of delivery',

  loadsDocumentsDocType: 'Document Type',
  loadsDocumentsDocName: 'Name',
  loadsDocumentsDocUploadDate: 'Upload Date',
  loadsDocumentsTotalDocs: 'Total Documents',

  loadsDocumentsUploadDoc: 'Upload Document',
  loadsDocumentsUploadAFile: '1. Upload a file into LoadOps',
  loadsDocumentsFileType:
    '2. Let us know what type (driver license, BoL, etc.)',
  loadsDocumentsLinkFile: '3. Link it to the right thing (load, driver)',
  loadsDocumentsProfit: '4. Profit!',
  loadsDocumentsBrowseToUpload: 'Browse To Upload',

  //document details form
  docUploadedBy: 'Uploaded By',
  docUploadDate: 'Upload Date',
  docDocumentName: 'Document Name',
  docDocumentType: 'Document Type',
  docAssociatedType: 'Associated Type',
  docAssociatedName: 'Associated Name',
  docNotes: 'Notes',

  loadsLoadActivity: 'Load Activity',
  loadsBeta: 'Beta',
  loadsActivityMsg:
    'View a full record of chronological system activities related to load activity.',

  ...LoadViewTranslations,
  ...LoadPopupTranslations,

  loadIsCurrentlyOnHold: 'Load is currently on hold',
  loadIsOnHold: 'This load is on hold',
};
