import StorageManager from '@/StorageManager/StorageManager';
import { Box, useTheme } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import EventEmitter from '../../../EventEmitter/EventEmitter';
import EventHoldLoadAction from '../../../EventEmitter/Events/EventHoldLoadAction';
import EventLoadTripAction from '../../../EventEmitter/Events/EventLoadTripAction';
import EventUpdateProgress from '../../../EventEmitter/Events/EventUpdateProgress';
import {
  financeLoadService,
  invoiceService,
  loadService,
  tripService,
} from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { TripsConstants } from '../../../locales/en/allLoads/trips';
import {
  ByLoadIdRequest,
  DeleteLoadManifestRequest,
  DeleteLoadRequest,
  GetAllStopsRequest,
  GetInvoiceNotesRequest,
  Trip,
  TripLocation,
  TripStop,
  TripStopResponse,
  UpdateStatusPayload,
  UpdateStatusResponse,
} from '../../../models';
import {
  CheckOrAssignAssetRequest,
  DeleteTripRequest,
  GetTripStopRequest,
  UpdateTripStatusPayload,
  UpdateTripStatusRequest,
  UpdateTripStatusResponse,
} from '../../../models/DTOs/Trip/Requests';
import { InvoiceModel } from '../../../subPages/invoices/models/InvoiceModel';
import LoadController from '../../../subPages/loadsList/LoadController';
import { LoadDetailsSummary } from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { ELoadType } from '../../../subPages/loadsList/constants';
import { LoadSummary } from '../../../subPages/loadsList/models/LoadSummary';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import { ESecondaryDetailsPanelType } from '../../../views/dispatch/constants/types';
import {
  ActionDialogFormData,
  LoadDetailDialog,
} from '../../../views/myLoads/components/LoadDetailDialog';
import { E3DotMenuType } from '../../../views/myLoads/components/LoadDetailDialog/types';
import { getTripDetailsActions } from '../../../views/trips/services/api.utils';
import { DirtyDetailsPanelManager } from '../../DetailsPanel/utils';
import LongMenu from '../../LongMenu';
import { useLoadPermission } from '../../hooks/useLoadPermission';
import { CommonCreateLoadPanel } from '../component/CommonCreateLoadPanel';
import { ELoadStatus, isAhead } from '../constants/constants';
import { RoutesInfo } from '../tabs/Routes/Models';
import AssetAssignmentPopup, {
  FormAsset,
} from '../tabs/Routes/components/AssetAssignmentPopup';
import {
  HoldLoadPopup,
  HoldLoadPopupProps,
} from '../tabs/Routes/components/HoldLoadPopup';
import { EHoldLoadEntityType } from '../tabs/Routes/components/HoldLoadPopup/utils';
import UpdateStopActivitiesDialog from '../tabs/Routes/components/UpdateStopDialog';
import {
  convertTripStatus,
  initUpdateActivitiesFormData,
  location2BusinessAndAddress,
} from '../tabs/Routes/utils';
import LoadActionsController from '../utils/LoadActionsController';
import { generateLoadDetailPayload, mappingAction } from '../utils/helper';

interface ActionsSectionProps {
  entity: 'Load' | 'Trip';
  onStatusAction: (
    action: ELoadStatus | 'UPDATE_PROGRESS',
    data: UpdateStatusResponse | null
  ) => void;
  seqNumber?: string | null;
  loadData?: Partial<LoadSummary> & Pick<LoadSummary, 'id'>;
  tripData?: Partial<Trip> & Pick<Trip, 'id'>;
  assignedTractor?: FormAsset;
  isDetailsPanel?: boolean;
  onCreatedLoadHandler?: (load: LoadDetailsSummary) => Promise<void>;
  onDeleted?: () => void;
  isExpanded?: boolean;
  skipOpenDetailsPanel?: boolean;
}

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}

// @Anna: This whole Functionality was already written I have just changed the struture of the component
export function ActionsSection({
  onStatusAction: onActionProp,
  entity,
  loadData,
  tripData,
  onCreatedLoadHandler,
  onDeleted,
  isDetailsPanel = true,
  assignedTractor,
  isExpanded = false,
  skipOpenDetailsPanel = false,
}: ActionsSectionProps) {
  const [unDispatchMsg, setUnDispatchMsg] = useState<{
    open: boolean;
    message: string | null;
  }>({
    open: false,
    message: null,
  });
  const [id, status, seqNumber] = loadData
    ? [loadData.id, loadData.loadStatus, loadData.seqNumber]
    : [tripData?.id, tripData?.status, tripData?.seqNumber];
  const {
    hasRemovePermission,
    hasLoadEditPermission,
    hasStatusChangeToCompleted,
    hasStatusChangeCompletedToAvailable,
    hasStatusChangeCompletedToInvoiced,
    hasStatusChangeInvoicedToCompleted,
    hasStatusChangeInvoicedToPaid,
    hasStatusChangePaidToInvoiced,
    hasStatusChangeToCancelled,
    hasInvoiceEditPermission,

    hasPaymentCompleteReadyInvoice,
    hasPaymentReadyInvoiceToInvoice,
    hasPaymentInvoiceToReadyInvoice,
    hasPaymentReadyToInvoiceComplete,
  } = useLoadPermission();
  const userStorage = StorageManager.getUser();
  const [actions, setActions] = useState<ELoadStatus[]>([]);
  const [isThreeDotOpen, setThreeDotOpen] = useState(true);
  const [isCombinedInvoice, setCombinedInvoice] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceModel | null>(null);
  const [apptDateMissingWarning, setIsApptDateMissingWarning] =
    useState<boolean>(false);
  const theme: any = useTheme();
  const loadActionsController = LoadActionsController.instance();
  const [current3DotMenu, setCurrent3DotMenu] = useState<{
    type: E3DotMenuType;
    data?: any;
    holdData?: {
      entityType: EHoldLoadEntityType;
      data?: any;
    };
  } | null>(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<{
    open: boolean;
    hideBtn: boolean;
    type: string;
    title: string;
    width: string;
    body: string;
    cancel: string;
  }>({
    open: false,
    type: '',
    title: '',
    width: '440px',
    body: '',
    cancel: 'Okay',
    hideBtn: false,
  });

  const getLoadDetailsActions = async (): Promise<void> => {
    if (id && isThreeDotOpen) {
      setIsLoading(true);
      if (entity === 'Load') {
        const response = await LoadController.instance().getLoadDetailsActions(
          id
        );
        setCombinedInvoice(response?.includedInCombinedInvoice || false);
        setActions(response?.data || []);
      } else {
        const response = await getTripDetailsActions(id);
        setActions(response || []);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRemovePermission) {
      getLoadDetailsActions();
    }
  }, [hasRemovePermission, isThreeDotOpen]);

  const afterAction = (
    toStatus: ELoadStatus | 'UPDATE_PROGRESS',
    data: UpdateStatusResponse | null = null
  ) => {
    onActionProp?.(toStatus, data);
    EventEmitter.send(
      new EventLoadTripAction({
        fromStatus: status,
        toStatus,
        loadId: tripData?.connectedLoads?.[0]?.loadId,
        tripIds: loadData?.tripConnectionDetails?.map((trip) => trip.tripId),
        entity,
        id,
        skipOpenDetailsPanel,
      })
    );
    //The status of load in load list should also be changed without having to refresh the page
    //From : 3dot menu action
    //To: AllLoads component
  };

  const updateLoadAction = async (
    nextStatus: ELoadStatus,
    payload?: UpdateStatusPayload
  ): Promise<ServiceError | UpdateStatusResponse> =>
    loadActionsController.handleActionOnLoad({
      loadId: id!,
      status: nextStatus,
      updateData: payload,
    });

  const updateTripAction = async (
    nextStatus: ELoadStatus,
    payload?: UpdateStatusPayload
  ): Promise<ServiceError | UpdateTripStatusResponse> =>
    tripService.updateStatus(
      new UpdateTripStatusRequest({
        tripId: id!,
        status: nextStatus,
      }),
      new UpdateTripStatusPayload(payload)
    );

  const onAction = async (
    nextStatus: ELoadStatus,
    payload?: UpdateStatusPayload
  ): Promise<any | ServiceError> => {
    let response;
    if (entity === 'Load') {
      response = await updateLoadAction(nextStatus, payload);
    } else {
      response = await updateTripAction(nextStatus, payload);
    }
    if (!(response instanceof ServiceError)) {
      afterAction(nextStatus, response);
    } else {
      setUnDispatchMsg({
        open: true,
        message:
          response?.composedError?.error?.response?.data.errorMessage ??
          response?.error?.response?.data.errorMessage,
      });
      return;
    }
    return response;
  };

  const onRevertToAvailableAction = async (): Promise<void> => {
    const response = await onAction(ELoadStatus.AVAILABLE);
    if (response instanceof ServiceError) {
      const responseData = response.composedError.error.response?.data;
      setCurrent3DotMenu({
        type: E3DotMenuType.AVAILABLE,
        data: responseData,
      });
    }
  };

  useEffect(() => {
    setActions([]);
    if (current3DotMenu?.type === E3DotMenuType.DELETE) {
      setCurrent3DotMenu(null);
      return;
    }
    getLoadDetailsActions(); //fetch actions in background
  }, [id, status, isThreeDotOpen, loadData?.onHold, tripData?.onHold]);

  const onDispatchedAction = async (): Promise<void> => {
    await onAction(ELoadStatus.DISPATCHED);
    setCurrent3DotMenu(null);
  };

  const sortedActions = useMemo(
    () =>
      actions.sort((aAction, bAction) => {
        const findIndex = (applicableAction: ELoadStatus) =>
          Object.values(ELoadStatus).findIndex(
            (action) => action === applicableAction
          );
        return findIndex(aAction) - findIndex(bAction);
      }),
    [actions]
  );

  const handleDispatch = async () => {
    let tripId: string | undefined;
    if (entity === 'Trip') {
      tripId = id;
    } else {
      const requestData = new ByLoadIdRequest();
      requestData[loadData?.loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] =
        id;
      const response = await loadService.getLoadRoutes(requestData);
      if (!(response instanceof ServiceError) && response.data) {
        const routesInfo = new RoutesInfo(response.data);
        tripId = routesInfo.trips[0].id;
      }
    }
    if (!tripId) return;
    const response = await tripService.checkOrAssignAsset(
      new CheckOrAssignAssetRequest({
        tripId,
        assetType: 'TRACTOR',
      })
    );
    if (
      !(response instanceof ServiceError) &&
      ['ASSET_OCCUPIED', 'ASSET_MISMATCH'].includes(response.conflictType)
    ) {
      if (response.conflictType === 'ASSET_MISMATCH') {
        if (entity === 'Load') {
          LoadController.instance().getLoadRoutes(id, (routesInfo) => {
            setCurrent3DotMenu({
              type: E3DotMenuType.DISPATCH,
              data: {
                tripId,
                data: response,
                location:
                  routesInfo?.trips?.length &&
                  routesInfo?.trips[0]?.stops?.length &&
                  routesInfo?.trips[0]?.stops[0]?.location
                    ? (routesInfo?.trips[0]?.stops[0]?.location as TripLocation)
                    : '',
              },
            });
          });
        } else {
          const trip = await tripService.getTripStop(
            new GetTripStopRequest({
              tripId,
              fetchHosData: false,
            })
          );
          if (trip instanceof TripStopResponse) {
            setCurrent3DotMenu({
              type: E3DotMenuType.DISPATCH,
              data: {
                tripId,
                data: response,
                location: trip.stops[0].location as TripLocation,
              },
            });
          }
        }
      } else {
        setCurrent3DotMenu({
          type: E3DotMenuType.DISPATCH,
          data: {
            data: response,
          },
        });
      }
    } else await onDispatchedAction();
  };

  const customDialogModel = () => {
    const setting = {
      open: true,
      backgroundColor: theme.palette.primary.main,
      width: '440px',
      title: ` `,
      body: 'Load cannot be reverted to completed/canceled as it is added on combined invoice.',
      cancelText: 'Okay',
      hideDelete: true,
    };
    setDeleteDialogOpen(setting);
  };

  const customReadyInvoiceDialogModel = () => {
    const setting = {
      open: true,
      backgroundColor: theme.palette.primary.main,
      width: '440px',
      title: ` `,
      body: 'Load cannot be reverted to completed/canceled/ready to invoice as it is added on combined invoice.',
      cancelText: 'Okay',
      hideDelete: true,
    };
    setDeleteDialogOpen(setting);
  };

  const getLoadTypeDisplay = (): string => {
    return loadData?.loadType == ELoadType.MANIFEST ? 'Manifest' : 'Load';
  };

  const fetchInvoiceDetailsByLoadId = async () => {
    if (!loadData?.id) return;
    if (invoiceDetail) return; //fetched
    const invoiceData = await financeLoadService.GetinvoiceDetail(loadData?.id);
    setInvoiceDetail(new InvoiceModel(invoiceData));
  };

  const isHoldInvoiceScope = useMemo(() => {
    return (
      entity === 'Load' &&
      !!loadData?.loadType &&
      !!loadData?.loadStatus &&
      loadData.loadType !== ELoadType.MANIFEST &&
      [ELoadStatus.INVOICED, ELoadStatus.PAID].includes(loadData.loadStatus) &&
      hasInvoiceEditPermission
    );
  }, [id, loadData?.loadType, loadData?.loadStatus, loadData?.onHold]);

  useEffect(() => {
    if (isHoldInvoiceScope) {
      fetchInvoiceDetailsByLoadId();
    }
  }, [isHoldInvoiceScope]);

  const options = useMemo(() => {
    const options: Action[] = [];
    const onHold = entity === 'Load' ? !!loadData?.onHold : !!tripData?.onHold;

    const getIsExceptedActionHoldingInvoice = (
      loadStatus: ELoadStatus
    ): boolean => {
      return (
        !!isHoldInvoiceScope &&
        !!loadData?.onHold &&
        [
          ELoadStatus.PAID,
          ELoadStatus.INVOICED,
          ELoadStatus.LOAD_COMPLETED,
          ELoadStatus.CANCELLED,
          ELoadStatus.READY_TO_INVOICE,
        ].includes(loadStatus)
      );
    };

    if (
      entity === 'Load' &&
      loadData?.loadType != ELoadType.MANIFEST &&
      hasLoadEditPermission
    ) {
      options.push({
        name: `${t('ActionSectionDeleteTripDuplicate')}`,
        action: (): void => {
          setCurrent3DotMenu({ type: E3DotMenuType.DUPLICATE });
        },
      });
    }

    if (entity === 'Load' && loadData?.loadStatus) {
      //OnHold begin
      const loadType = getLoadTypeDisplay();
      //is associated load of manifest
      const isAssociatedLoadOfManifest: boolean =
        !!loadData.manifestBaseDetails?.manifestId &&
        loadData.manifestBaseDetails?.manifestId !== loadData.id;

      const isLoadConnectedWithCustomInvoice = !!invoiceDetail?.masterInvoiceId;
      if (isHoldInvoiceScope) {
        //for Single Invoice begin
        if (!onHold) {
          options.push({
            name: `Mark as Hold`,
            action: async (): Promise<void> => {
              if (isLoadConnectedWithCustomInvoice) {
                setUnDispatchMsg({
                  open: true,
                  message: `Invoice cannot be put on hold as it is a part of combined invoice  ${invoiceDetail?.masterInvoiceSeqNumber}`,
                });
                return;
              }

              setCurrent3DotMenu({
                type: E3DotMenuType.MARK_AS_HOLD,
                holdData: {
                  entityType: EHoldLoadEntityType.INVOICE,
                },
              });
            },
          });
        } else if (onHold) {
          options.push({
            name: `Release`,
            action: async (): Promise<void> => {
              if (isLoadConnectedWithCustomInvoice) {
                setUnDispatchMsg({
                  open: true,
                  message: `Invoice cannot released as it is a part of combined invoice ${invoiceDetail?.masterInvoiceSeqNumber}`,
                });
                setThreeDotOpen(false);
              } else {
                setCurrent3DotMenu({
                  type: E3DotMenuType.RELEASE_LOAD,
                  holdData: {
                    entityType: EHoldLoadEntityType.INVOICE,
                  },
                });
              }
            },
          });
        }
        //for Single Invoice end
      } else {
        //for Load begin
        if (
          ([
            ELoadStatus.AVAILABLE,
            ELoadStatus.ASSIGNMENT_PLANNED,
            ELoadStatus.OFFERED_TO_DRIVER,
            ELoadStatus.IN_TRANSIT,
            ELoadStatus.DISPATCHED,
            ELoadStatus.CANCELLED,
            ELoadStatus.READY_TO_INVOICE,
          ].includes(loadData.loadStatus) &&
            hasLoadEditPermission) ||
          ([ELoadStatus.LOAD_COMPLETED, ELoadStatus.READY_TO_INVOICE].includes(
            loadData.loadStatus
          ) &&
            hasInvoiceEditPermission)
        ) {
          if (!onHold) {
            options.push({
              name: `Mark as Hold`,
              action: (): void => {
                if (
                  [ELoadStatus.CANCELLED].includes(loadData.loadStatus) &&
                  loadData.nonInvoiceable
                ) {
                  setUnDispatchMsg({
                    open: true,
                    message: `Cancelled (Non-Invoiceable) cannot be put on hold.`,
                  });
                  return;
                }

                if (isAssociatedLoadOfManifest) {
                  setUnDispatchMsg({
                    open: true,
                    message: `Load cannot be put on hold as it is associated with manifest ${loadData.manifestBaseDetails?.seqNumber}`,
                  });
                  setThreeDotOpen(false);
                } else {
                  setCurrent3DotMenu({
                    type: E3DotMenuType.MARK_AS_HOLD,
                    holdData: {
                      entityType: EHoldLoadEntityType.LOAD,
                    },
                  });
                }
              },
            });
          } else if (onHold) {
            options.push({
              name: `Release`,
              action: (): void => {
                if (isAssociatedLoadOfManifest) {
                  setUnDispatchMsg({
                    open: true,
                    message: `Load cannot be released as it is associated with manifest ${loadData.manifestBaseDetails?.seqNumber}`,
                  });
                  setThreeDotOpen(false);
                } else {
                  setCurrent3DotMenu({
                    type: E3DotMenuType.RELEASE_LOAD,
                    holdData: {
                      entityType: EHoldLoadEntityType.LOAD,
                    },
                  });
                }
              },
            });
          }
        }
        //for Load end
      }
      //OnHold end
    }

    const validateLoadStopsLocations = async () => {
      return await loadService.validateLoadStopsLocations(
        (loadData ?? tripData) as Partial<LoadSummary>
      );
    };
    sortedActions.forEach((applicableStatus) => {
      if (!status) return;
      switch (applicableStatus) {
        case ELoadStatus.AVAILABLE:
          const isCompleted =
            entity === 'Trip'
              ? convertTripStatus(status) === ELoadStatus.LOAD_COMPLETED
              : status === ELoadStatus.LOAD_COMPLETED;
          if (
            (isCompleted
              ? hasStatusChangeCompletedToAvailable
              : hasLoadEditPermission) &&
            !onHold
          )
            options.push({
              name: `${t('ActionSectionDeleteTripReverttoAvailable')}`,
              action: (): void => {
                onRevertToAvailableAction();
              },
            });
          break;
        case ELoadStatus.ASSIGNMENT_PLANNED:
          if (hasLoadEditPermission && !onHold)
            options.push({
              name: `${t('ActionSectionDeleteTripReverttoPlan')}`,
              action: (): void => {
                onAction(ELoadStatus.ASSIGNMENT_PLANNED);
              },
            });
          break;
        case ELoadStatus.OFFERED_TO_DRIVER:
          if (!onHold) {
            if (
              isAhead(status, ELoadStatus.OFFERED_TO_DRIVER) &&
              hasLoadEditPermission
            ) {
              options.push({
                name:
                  tripData?.brokerageTrip ||
                  loadData?.tripConnectionDetails?.[0]?.brokerageTrip
                    ? `Assign Carrier`
                    : `${t('assignDriver')}`,
                action: (): void => {
                  onAction(ELoadStatus.OFFERED_TO_DRIVER);
                },
              });
            } else if (hasLoadEditPermission) {
              options.push({
                name: `${t('ActionSectionDeleteTripUnDispatch')}`,
                action: (): void => {
                  onAction(ELoadStatus.OFFERED_TO_DRIVER);
                },
              });
            }
          }
          break;
        case ELoadStatus.DISPATCHED:
          if (hasLoadEditPermission) {
            if (isAhead(status, ELoadStatus.DISPATCHED)) {
              if (!onHold) {
                options.push({
                  name: `Dispatch`,
                  action: async () => {
                    handleDispatch();
                  },
                });
              }
            } else
              options.push({
                name: `${t('ActionSectionDeleteTripUnDispatch')}`,
                action: (): void => {
                  onAction(ELoadStatus.OFFERED_TO_DRIVER);
                },
              });
          }
          break;
        case ELoadStatus.LOAD_COMPLETED:
          if (getIsExceptedActionHoldingInvoice(ELoadStatus.LOAD_COMPLETED))
            break;
          if (isAhead(status, ELoadStatus.LOAD_COMPLETED)) {
            if (hasStatusChangeToCompleted && !onHold)
              options.push({
                name: `${t('ActionSectionDeleteTripMarkasComplete')}`,
                action: async (): Promise<void> => {
                  let response: Array<any> = [{}, {}];
                  if (entity === 'Load') {
                    response = await Promise.allSettled([
                      validateLoadStopsLocations(),
                      invoiceService.getInvoiceNotes(
                        new GetInvoiceNotesRequest({
                          loadId: id!,
                        })
                      ),
                    ]);
                  } else {
                    response[0].value = await validateLoadStopsLocations();
                  }
                  if (response?.[0]?.value) {
                    return setIsApptDateMissingWarning(true);
                  }
                  setCurrent3DotMenu({
                    type: E3DotMenuType.COMPLETE,
                    data: response?.[1]?.value,
                  });
                },
              });
          } else {
            if (
              status !== ELoadStatus.INVOICED ||
              hasStatusChangeInvoicedToCompleted
            )
              if (status === ELoadStatus.READY_TO_INVOICE) {
                if (hasPaymentReadyToInvoiceComplete)
                  options.push({
                    name: `${t('ActionSectionDeleteTripReverttoCompleted')}`,
                    action: (): void => {
                      if (
                        isCombinedInvoice &&
                        loadData?.loadStatus === ELoadStatus.INVOICED
                      ) {
                        customDialogModel();
                        return;
                      }
                      onAction(ELoadStatus.LOAD_COMPLETED);
                    },
                  });
              } else {
                options.push({
                  name: `${t('ActionSectionDeleteTripReverttoCompleted')}`,
                  action: (): void => {
                    if (
                      isCombinedInvoice &&
                      loadData?.loadStatus === ELoadStatus.INVOICED
                    ) {
                      customDialogModel();
                      return;
                    }
                    onAction(ELoadStatus.LOAD_COMPLETED);
                  },
                });
              }
          }
          break;
        case ELoadStatus.CANCELLED:
          if (getIsExceptedActionHoldingInvoice(ELoadStatus.CANCELLED)) break;
          if (isAhead(status, ELoadStatus.CANCELLED)) {
            if (hasStatusChangeToCancelled && !onHold) {
              options.push({
                name: `${t('ActionSectionDeleteTripMarkasCanceled')}`,
                action: async (): Promise<void> => {
                  let response: Array<any> = [{}, {}];
                  if (entity === 'Load') {
                    response = await Promise.allSettled([
                      validateLoadStopsLocations(),
                      invoiceService.getInvoiceNotes(
                        new GetInvoiceNotesRequest({
                          loadId: id!,
                        })
                      ),
                    ]);
                  } else {
                    response[0].value = await validateLoadStopsLocations();
                  }
                  if (response?.[0]?.value) {
                    return setIsApptDateMissingWarning(true);
                  }
                  setCurrent3DotMenu({
                    type: E3DotMenuType.CANCEL_LOAD,
                    data: response?.[1]?.value,
                  });
                },
              });
            }
          } else {
            if (hasStatusChangeInvoicedToCompleted) {
              options.push({
                name: `${t('ActionSectionDeleteTripReverttoCanceled')}`,
                action: (): void => {
                  if (
                    isCombinedInvoice &&
                    loadData?.loadStatus === ELoadStatus.INVOICED
                  ) {
                    customDialogModel();
                    return;
                  }
                  onAction(ELoadStatus.CANCELLED);
                },
              });
            }
          }
          break;
        case ELoadStatus.READY_TO_INVOICE:
          if (status === ELoadStatus.INVOICED) {
            if (!hasPaymentInvoiceToReadyInvoice) return;
            options.push({
              name: `Revert to Ready to Invoice`,
              action: (): void => {
                if (
                  isCombinedInvoice &&
                  loadData?.loadStatus === ELoadStatus.INVOICED
                ) {
                  customReadyInvoiceDialogModel();
                  return;
                }
                onAction(ELoadStatus.READY_TO_INVOICE);
              },
            });
          } else {
            if (!hasPaymentCompleteReadyInvoice) return;
            options.push({
              name: `Mark as Ready to Invoice`,
              action: (): void => {
                onAction(ELoadStatus.READY_TO_INVOICE);
              },
            });
          }
          break;
        case ELoadStatus.INVOICED:
          if (getIsExceptedActionHoldingInvoice(ELoadStatus.INVOICED)) break;
          if (isAhead(status, ELoadStatus.INVOICED)) {
            if (status === ELoadStatus.READY_TO_INVOICE) {
              if (hasPaymentReadyInvoiceToInvoice) {
                options.push({
                  name: `${t('ActionSectionDeleteTripMarkasInvoiced')}`,
                  action: (): void => {
                    setCurrent3DotMenu({ type: E3DotMenuType.MARK_AS_INVOICE });
                  },
                });
              }
            } else if (
              (status !== ELoadStatus.LOAD_COMPLETED ||
                hasStatusChangeCompletedToInvoiced) &&
              !onHold
            ) {
              options.push({
                name: `${t('ActionSectionDeleteTripMarkasInvoiced')}`,
                action: (): void => {
                  setCurrent3DotMenu({ type: E3DotMenuType.MARK_AS_INVOICE });
                },
              });
            }
          } else if (status === ELoadStatus.PAID) {
            if (userStorage.roleCode != 'DI' && userStorage.roleCode != 'NFD') {
              options.push({
                name: `${t('ActionSectionDeleteTripReverttoInvoiced')}`,
                action: (): void => {
                  onAction(ELoadStatus.INVOICED);
                },
              });
            }
          } else {
            if (status !== ELoadStatus.PAID || hasStatusChangePaidToInvoiced)
              options.push({
                name: `${t('ActionSectionDeleteTripMarkasInvoiced')}`,
                action: (): void => {
                  setCurrent3DotMenu({ type: E3DotMenuType.MARK_AS_INVOICE });
                },
              });
          }
          break;
        case ELoadStatus.PAID:
          if (getIsExceptedActionHoldingInvoice(ELoadStatus.PAID)) break;
          if (
            status !== ELoadStatus.INVOICED ||
            hasStatusChangeInvoicedToPaid
          ) {
            options.push({
              name: `${t('ActionSectionDeleteTripMarkasPaid')}`,
              action: (): void => {
                setCurrent3DotMenu({ type: E3DotMenuType.MARK_AS_PAID });
              },
            });
          }
          break;
      }
    });
    if (
      tripData &&
      status &&
      entity === 'Trip' &&
      [
        ELoadStatus.DISPATCHED,
        ELoadStatus.IN_TRANSIT,
        ELoadStatus.LOAD_COMPLETED,
      ].includes(status) &&
      !tripData?.onHold &&
      hasLoadEditPermission
    ) {
      options.push({
        name: `${t('ActionSectionDeleteTripUpdateProgress')}`,
        action: async (): Promise<void> => {
          const tripStop = await tripService.getTripStop(
            new GetTripStopRequest({
              tripId: id!,
              fetchHosData: false,
            })
          );
          if (!(tripStop instanceof ServiceError)) {
            const firstStop = tripStop.stops[0] as TripStop;
            const response = await loadService.getAllActivities(
              new GetAllStopsRequest({
                tripOrLoadId: id,
              })
            );
            if (!(response instanceof ServiceError)) {
              setCurrent3DotMenu({
                type: E3DotMenuType.UPDATE_PROGRESS,
                data: initUpdateActivitiesFormData(
                  response,
                  tripStop.id,
                  tripStop.status,
                  firstStop.location,
                  'TRAILER',
                  tripStop.previousTripDetails?.status,
                  tripData?.assignment?.trailerId
                    ? {
                        id: tripData?.assignment.trailerId,
                        name: tripData?.assignment.trailerName,
                      }
                    : null
                ),
              });
            }
          }
        },
      });
    }

    if (hasRemovePermission) {
      options.push({
        name: `Delete ${
          loadData?.loadType === ELoadType.MANIFEST ? 'Manifest' : entity
        }`,
        action: (): void => {
          onDeleteHandler();
        },
      });
    }

    return options;
  }, [
    actions,
    status,
    hasRemovePermission,
    hasLoadEditPermission,
    tripData?.brokerageTrip,
    loadData?.tripConnectionDetails?.[0]?.brokerageTrip,
    loadData?.onHold,
    tripData?.onHold,
    isCombinedInvoice,
    invoiceDetail?.id,
    invoiceDetail?.masterInvoiceId,
    isHoldInvoiceScope,
  ]);

  const onDeleteHandler = () => {
    setCurrent3DotMenu({ type: E3DotMenuType.DELETE });
  };
  const onCloseLoadDetailDialog = (): void => {
    setCurrent3DotMenu(null);
  };

  const onActionLoadDetailDialog = async (
    data: ActionDialogFormData
  ): Promise<void> => {
    if (current3DotMenu && mappingAction[current3DotMenu?.type]) {
      const requestBody: UpdateStatusPayload = generateLoadDetailPayload(
        data,
        current3DotMenu.type,
        entity
      );

      await onAction(
        mappingAction[current3DotMenu?.type] as ELoadStatus,
        requestBody
      );
      setCurrent3DotMenu(null);
    }
  };

  useEffect(() => {
    setActions([]);
    getLoadDetailsActions(); //fetch actions in background
  }, [id]);

  const sendEventHoldLoadActionData = ({
    actionType,
    entityType,
  }: {
    actionType: E3DotMenuType;
    entityType: EHoldLoadEntityType;
  }) => {
    setCurrent3DotMenu(null);
    if (!loadData?.id) return;
    EventEmitter.send(
      new EventHoldLoadAction({
        loadIds: [
          loadData.id,
          ...(loadData?.manifestLoadIds ? loadData.manifestLoadIds : []),
        ],
        tripIds:
          loadData?.tripConnectionDetails?.map(({ tripId }) => tripId) || [],
        invoiceIds: invoiceDetail?.id ? [invoiceDetail.id] : [],
        type: actionType,
      })
    );
  };
  const getHoldPayload = (): Pick<
    HoldLoadPopupProps,
    'loadData' | 'invoiceData'
  > => {
    if (!current3DotMenu?.holdData?.entityType || !loadData?.id) return {};
    return {
      loadData: {
        id: loadData?.id,
        loadType: loadData?.loadType,
      },
      invoiceData: invoiceDetail?.id
        ? {
            id: invoiceDetail?.id,
            invoiceType: invoiceDetail?.invoiceType,
          }
        : undefined,
    };
  };

  const handleAction = () => {
    switch (current3DotMenu?.type) {
      case E3DotMenuType.DUPLICATE:
        return (
          loadData && (
            <Box
              sx={{
                ...(isExpanded && {
                  position: 'fixed',
                  inset: '0px',
                  zIndex: '1300',
                }),
              }}
            >
              <CommonCreateLoadPanel
                onCreatedLoadHandler={onCreatedLoadHandler}
                loadData={loadData as LoadSummary}
                onClose={() => {
                  setCurrent3DotMenu(null);
                }}
                isDuplicate
              />
            </Box>
          )
        );
      case E3DotMenuType.DISPATCH:
        return (
          current3DotMenu.data?.data && (
            <AssetAssignmentPopup
              assetAssignment={current3DotMenu.data?.data}
              onClose={() => setCurrent3DotMenu(null)}
              onActionCompleted={onDispatchedAction}
              data={{
                ...location2BusinessAndAddress(current3DotMenu.data?.location),
                tripId: current3DotMenu.data?.tripId,
                tractor: assignedTractor,
                assetType: 'TRACTOR',
                userChoice: 'DRIVER',
                driverAsset: current3DotMenu.data?.data.driverAsset,
              }}
            />
          )
        );
      case E3DotMenuType.CANCEL_LOAD: {
        return (
          <DeletePopup
            width="400px"
            open
            onClose={() => {
              setCurrent3DotMenu(null);
            }}
            title="Are you sure you want to cancel this load?"
            subtitle="This action cannot be reversed. If you proceed, select whether the load should remain invoiceable to bill a customer for any portion of the load."
            onAction={() =>
              setCurrent3DotMenu((prev) => ({
                ...prev,
                type: E3DotMenuType.CANCEL,
              }))
            }
            buttonText="Yes"
            cancelText="No"
          />
        );
      }
      case E3DotMenuType.CANCEL:
      case E3DotMenuType.COMPLETE:
      case E3DotMenuType.MARK_AS_PAID:
      case E3DotMenuType.MARK_AS_INVOICE:
        const currentDate = moment();

        return (
          <LoadDetailDialog
            entity={`${
              loadData?.loadType === ELoadType.MANIFEST ? 'Manifest' : entity
            }`}
            data={{
              seqNumber:
                loadData?.loadType === ELoadType.MANIFEST
                  ? loadData.manifestBaseDetails.seqNumber
                  : seqNumber,
              completionDate:
                current3DotMenu?.type === E3DotMenuType.COMPLETE
                  ? currentDate
                  : null,
              invoiceNotes: [
                E3DotMenuType.COMPLETE,
                E3DotMenuType.CANCEL,
              ].includes(current3DotMenu?.type)
                ? current3DotMenu.data?.message || ''
                : undefined,
              invoicedDate:
                current3DotMenu?.type === E3DotMenuType.MARK_AS_INVOICE
                  ? currentDate
                  : null,
              paidDate:
                current3DotMenu?.type === E3DotMenuType.MARK_AS_PAID
                  ? currentDate
                  : null,
              ...(current3DotMenu?.type === E3DotMenuType.CANCEL && {
                markAsNonInvoiceable: true,
                cancellationDate: currentDate,
                keepInvoiceLineItems: false,
              }),
            }}
            type={current3DotMenu?.type}
            onClose={() => {
              onCloseLoadDetailDialog();
            }}
            onAction={(data) => {
              onActionLoadDetailDialog(data);
            }}
          />
        );
      case E3DotMenuType.DELETE:
        if (!!tripData?.onHold || !!loadData?.onHold) {
          return (
            <DeletePopup
              open
              onClose={() => {
                setCurrent3DotMenu(null);
              }}
              title={''}
              subtitle={
                entity === 'Trip'
                  ? TripsConstants.deleteTripOnHold
                  : TripsConstants.deleteLoadOnHold
              }
              cancelText={'Okay'}
              backgroundColor={theme.palette.primary.main}
              width={'440px'}
            />
          );
        }

        let setting: any;
        if (
          isCombinedInvoice &&
          (loadData?.loadStatus === ELoadStatus.INVOICED ||
            loadData?.loadStatus === ELoadStatus.PAID)
        ) {
          setting = {
            open: true,
            backgroundColor: theme.palette.primary.main,
            width: '440px',
            title: ` `,
            body: 'Load cannot be deleted as it is connected with combined invoice.',
            cancelText: 'Okay',
            hideDelete: true,
            onClose: () => {
              setCurrent3DotMenu(null);
            },
          };
        } else {
          if (!current3DotMenu.data) {
            setting = {
              open: current3DotMenu?.type === E3DotMenuType.DELETE,
              backgroundColor: theme.palette.error.dark,
              width: '440px',
              title: `Delete ${
                loadData?.loadType === ELoadType.MANIFEST ? 'Manifest' : entity
              } ?`,
              body: (
                <>
                  <div>
                    {`${t('ActionSectionDeleteTrip')} ${
                      loadData?.loadType === ELoadType.MANIFEST
                        ? 'Manifest'
                        : entity.toLowerCase()
                    }?`}
                  </div>
                  <i>
                    {loadData?.loadType === ELoadType.MANIFEST
                      ? 'Deleting the manifest will also delete the associated trips in manifest and will also revert the associated loads to available status.'
                      : entity === 'Load'
                      ? t('loadDeleteSubtitleWithInvoice')
                      : t('loadDeleteSubtitle')}
                  </i>
                </>
              ),
              cancelText: 'Cancel',
              buttonText: 'Delete',
              onAction: async () => {
                let response;
                if (entity === 'Load') {
                  if (loadData?.loadType === ELoadType.MANIFEST) {
                    response = await loadService.deleteLoadManifest(
                      new DeleteLoadManifestRequest({
                        manifestId: id,
                      })
                    );
                  } else {
                    response = await loadService.deleteLoad(
                      new DeleteLoadRequest({
                        loadId: id,
                      })
                    );
                  }
                  if (typeof onDeleted === 'function') {
                    onDeleted(id);
                  }
                } else {
                  response = await tripService.deleteTrip(
                    new DeleteTripRequest({
                      tripId: id,
                    })
                  );
                }
                if (response instanceof ServiceError) {
                  setCurrent3DotMenu({
                    type: E3DotMenuType.DELETE,
                    data: response.composedError.error.response?.data,
                  });
                } else if (response.successfullyDeleted) {
                  // setCurrent3DotMenu(null);
                  afterAction(ELoadStatus.DELETED);
                } else {
                  setCurrent3DotMenu({
                    type: E3DotMenuType.DELETE,
                    data: response,
                  });
                }
              },
              onClose: () => {
                setCurrent3DotMenu(null);
              },
            };
          } else {
            const getMessage = () => {
              const responseData = current3DotMenu.data;
              const message = responseData.message || responseData.errorMessage;
              const settlements = (responseData.settlementIdList || [])
                .concat(responseData.tractorSettlementIdList || [])
                .concat(responseData.dispatcherSettlement || []);
              if (settlements.length) {
                return `The ${entity.toLowerCase()} cannot be deleted as there is an active settlement ${settlements
                  .map(
                    ({
                      seqNumber,
                      settlementSeqNumber,
                    }: {
                      seqNumber?: number;
                      settlementSeqNumber?: number;
                    }) => `[${seqNumber || settlementSeqNumber}]`
                  )
                  .join(', ')} associated with the trip`;
              }
              switch (entity) {
                case 'Trip':
                  switch (responseData.deletedTripInfo?.reason) {
                    case 'SINGL_TRIP':
                      return `The trip cannot be deleted as it is the default trip of the load [${responseData.deletedTripInfo.seqNumber}]`;
                    case 'MULTY_TRIP':
                      return 'Trip cannot be deleted as the trip stops cannot be moved to the next/previous associated trip';
                    case 'TRIP_STATUS_ERROR':
                      return 'The Trip cannot be deleted as the it is In Progress/Completed/Canceled';
                  }
                  break;
                case 'Load':
                  if (
                    responseData?.carrierSettlement &&
                    responseData?.seqNumber
                  ) {
                    return `Load cannot be deleted as the carrier payment associated with the trip ${responseData?.seqNumber} is Closed.`;
                  }
                  if (message) return message;

                  break;
              }
              return responseData || `Can not delete this ${entity}`;
            };

            setting = {
              open: current3DotMenu?.type === E3DotMenuType.DELETE,
              backgroundColor: theme.palette.primary.main,
              width: '440px',
              title: '',
              body: getMessage(),
              cancelText: 'OK',
              onClose: () => {
                setCurrent3DotMenu(null);
              },
            };
          }
        }
        return <DeletePopup {...setting} />;
      case E3DotMenuType.UPDATE_PROGRESS:
        return (
          current3DotMenu.data && (
            <UpdateStopActivitiesDialog
              onClose={() => setCurrent3DotMenu(null)}
              onAction={() => {
                setCurrent3DotMenu(null);
                onActionProp?.('UPDATE_PROGRESS');
                EventEmitter.send(
                  new EventUpdateProgress({
                    toTypes: [
                      ESecondaryDetailsPanelType.LOAD,
                      ESecondaryDetailsPanelType.TRIP,
                    ],
                  })
                );
              }}
              data={current3DotMenu.data}
            />
          )
        );
      case E3DotMenuType.AVAILABLE:
        if (current3DotMenu.data) {
          return (
            <DeletePopup
              backgroundColor={theme.palette.primary.main}
              open
              title=""
              body={current3DotMenu.data.errorMessage || ''}
              onClose={() => setCurrent3DotMenu(null)}
              cancelText="Ok"
            />
          );
        }
      case E3DotMenuType.MARK_AS_HOLD:
        if (!loadData?.id || !current3DotMenu?.holdData?.entityType) return;
        const holdPayload = getHoldPayload();
        const entityType = current3DotMenu.holdData?.entityType;
        return (
          <HoldLoadPopup
            actionType={E3DotMenuType.MARK_AS_HOLD}
            entityType={entityType}
            {...holdPayload}
            onClose={() => setCurrent3DotMenu(null)}
            onActionCompleted={() => {
              sendEventHoldLoadActionData({
                actionType: E3DotMenuType.MARK_AS_HOLD,
                entityType: entityType,
              });
            }}
          />
        );
      case E3DotMenuType.RELEASE_LOAD:
        if (!loadData?.id || !current3DotMenu.holdData?.entityType) return;
        const releasePayload = getHoldPayload();
        const entityTypeRl = current3DotMenu.holdData?.entityType;
        return (
          <HoldLoadPopup
            actionType={E3DotMenuType.RELEASE_LOAD}
            entityType={entityTypeRl}
            {...releasePayload}
            onClose={() => setCurrent3DotMenu(null)}
            onActionCompleted={() => {
              sendEventHoldLoadActionData({
                actionType: E3DotMenuType.RELEASE_LOAD,
                entityType: entityTypeRl,
              });
            }}
          />
        );
    }
  };
  if (!options.length && entity !== 'Load') return null;
  return (
    <>
      {
        <LongMenu
          color={isDetailsPanel ? 'primary.contrast' : 'text.secondary'}
          options={options}
          canPerformClick={(event: React.MouseEvent<HTMLElement>) => {
            if (DirtyDetailsPanelManager.isShouldPanelShake()) {
              return false;
            }
            return true;
          }}
          onClick={() => {
            setThreeDotOpen(true);
          }}
          isLoading={isLoading}
        />
      }
      {current3DotMenu && handleAction()}
      <DeletePopup
        open={unDispatchMsg.open}
        onClose={() => {
          setUnDispatchMsg({
            open: false,
            message: null,
          });
        }}
        title={''}
        subtitle={unDispatchMsg.message}
        cancelText={'Okay'}
        backgroundColor={theme.palette.primary.main}
        width={'440px'}
      />
      <DeletePopup
        title={deleteDialogOpen.title}
        body={deleteDialogOpen.body}
        open={deleteDialogOpen?.open}
        width="340px"
        cancelText={'Okay'}
        backgroundColor={theme.palette.primary.main}
        onClose={() => {
          setCurrent3DotMenu(null);
          setDeleteDialogOpen((prevState) => ({ ...prevState, open: false }));
        }}
      />
      <DeletePopup
        open={apptDateMissingWarning}
        title={t('MissingApptDatesTitle')}
        body={t('MissingApptDatesBody')}
        subtitle={t('MissingApptDatesSubtitle')}
        onClose={() => setIsApptDateMissingWarning(false)}
        cancelText={'Ok'}
        width={'370px'}
      />
    </>
  );
}

export default ActionsSection;
