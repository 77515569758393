import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { CloseIcon } from '@/ui-kit/assets/icons';

interface Props {
  bgColor?: string;
  color?: string;
  small?: boolean;
  removable?: boolean;
  onRemoveClick?: () => void;
  title: string;
}

export const Tag: FC<Props> = ({
  bgColor,
  color = 'white',
  title,
  small,
  removable = false,
  onRemoveClick,
}) => (
  <Box
    sx={{
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      background: bgColor,
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '10px',
      paddingRight: '10px',
    }}
  >
    <Typography sx={{ fontSize: small ? '11px' : '13px', color }}>
      {title}
    </Typography>
    {removable && (
      <Box onClick={onRemoveClick} sx={{ ml: 1, cursor: 'pointer' }}>
        <CloseIcon color={color} width={10} />
      </Box>
    )}
  </Box>
);
