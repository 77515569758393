import * as yup from 'yup';
import { Address } from './models/customer';

import {
  gridPageSize,
  lastLocationValidationSchema,
  nullableStringMaxValidation,
} from '../../utils';
import { contactValidationSchema } from '../../validator/validationSchemas';

const addressDefaultData = {
  fullAddress: '',
  address: '',
  city: '',
  state: '',
  streetAddress: '',
  streetAddress2: '',
  zipcode: '',
};

export const customerDefaultData = {
  id: '',
  name: '',
  type: '',
  addressDTO: new Address(addressDefaultData),
  factoring: { id: '', factoringName: '' },
  useFactoring: false,
  status: true,
  phoneData: {},
  email: '',
  mc: '',
  notes: '',
  customerPreference: 'NONE',
  commodityTypes: [],
  contacts: [],
  billToAddress: new Address(addressDefaultData),
  billToName: '',
  useDifferentBillTo: false,
  useSameBillTo: true,
  invoiceTerm: null,
  invoiceTermValue: null,
  messageOnInvoice: null,
  billingNotes: '',
  billingPhoneData: {},
};

export const contactDefaultData = {
  name: '',
  description: '',
  phoneData: {},
  email: '',
  extension: '',
  isPrimary: false,
};

export const customerListDefaultRequestData = {
  pageNumber: 1,
  pageSize: gridPageSize,
  sort: '+name',
};

export const emptyListViewConfig = {
  title: 'time to get social',
  body: 'Looks like you need to add some contacts in to the system. What is a carrier without a customer after all?',
};

export const deletePopupConfig = {
  bodyText: {
    default: 'Are you sure you want to delete this customer?',
    isCustomerAssignedToLoad:
      'The customer cannot be removed as it is either assigned to completed load(s) or current load(s).',
    isCustomerAssignedToInvoice:
      'Customer cannot be deleted as it has the invoices present.',
    isCustomerAssignedToPayment:
      'Customer cannot be deleted as there are payment received from the customer.',
  },
};

export const customerDetailsFormValidationSchema = yup
  .object({
    name: nullableStringMaxValidation(200).required('Name is required.'),
    notes: nullableStringMaxValidation(255),
    addressDTO: lastLocationValidationSchema,
    mc: yup
      .number()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .typeError('Field must contain only digits.')
      .min(0, 'MC# cannot be longer than 8 characters.')
      .max(99999999, 'MC# cannot be longer than 8 characters.')
      .nullable(),
    contacts: yup.array().of(contactValidationSchema).compact(),
  })
  .required();

export const notificationsOption = [
  { value: 'En route at Pickup', name: 'ENROUTE_PICKUP' },
  { value: 'En route to Delivery', name: 'ENROUTE_DELIVERY' },
  { value: 'Arrived at Pickup', name: 'ARRIVED_PICKUP' },
  { value: 'Arrived at Delivery', name: 'ARRIVED_DELIVERY' },
  { value: 'Completion of Delivery', name: 'COMPLETE_DELIVERY' },
  {
    value: 'En route to Intermediate Stop',
    name: 'ENROUTE_INTERMEDIATE_STOP',
  },
  {
    value: 'Arrived at Intermediate Stop',
    name: 'ARRIVED_INTERMEDIATE_STOP',
  },
];
