import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormAddress } from '../../../../../common/Ui/AddressField';
import FileUpload from '../../../../../common/Ui/FileUpload/indexUpdated';
import FormPhoneField from '../../../../../common/Ui/FormPhoneField';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../../common/Ui/TextField/TextField';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { docType } from '../../../../../utils/Doc';
import { COMPANY_NAME, replyToOptions } from '../../constants';
import PreferencesActions from '../../PreferencesActions';
import SettingBlock from '../Blocks/SettingBlock';
import { SettingBlockItem } from '../Blocks/SettingBlockItem';
function CompanyName() {
  const { control } = useFormContext();
  const organizationName = StorageManager.getUser().organizationName;
  return (
    <SettingBlockItem
      title="Company Name"
      subTitle="Your Company’s Name, I mean what else is there to say?"
    >
      <TextField
        control={control}
        defaultValue={organizationName}
        label="Company Name"
        required
        disabled
        name={''}
      />
    </SettingBlockItem>
  );
}

function CompanyDOT() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem title="Company DOT" subTitle="DOT Number for Company">
      <TextField
        control={control}
        name="companyDetails.dot"
        label="DOT"
        required
        disabled
      />
    </SettingBlockItem>
  );
}

function CompanyLogo() {
  const { setValue } = useFormContext();
  const [logo, setLogo] = useState<null | docType>(null);

  useEffect(() => {
    const getCompanyLogo = async () => {
      const result = await PreferencesActions.getCompanyLogo();
      if (result?.id) {
        setLogo(result);
      }
    };
    getCompanyLogo();
  }, []);

  const handleFileChange = (file: FileList | null) => {
    if (file) {
      setValue('fileChanged', true);
    }
  };

  return (
    <SettingBlockItem
      title="Company Logo"
      subTitle="Used for preparing Invoices (1MB maximum, JPG or PNG, 1200px wide max)"
    >
      <FileUpload
        defaultFile={logo}
        accept={{
          'image/jpeg': [],
          'image/png': [],
        }}
        name="logo"
        onChangeCB={handleFileChange}
        supportedFileSize={5}
        removable={false}
        supportedImgMaxResolution={1200}
      />
    </SettingBlockItem>
  );
}

function PhoneNumber() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Phone Number"
      subTitle="Displayed on invoices"
      hasExceptions
    >
      <FormPhoneField
        control={control}
        name="companyDetails.phoneData"
        label="Phone #"
        phoneNumberCustomStyle={{ flexGrow: 0 }}
        customeStyle={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

function FaxNumber() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Fax Number"
      subTitle="Displayed on invoices"
      hasExceptions
    >
      <FormPhoneField
        control={control}
        name="companyDetails.faxData"
        label="Phone #"
        phoneNumberCustomStyle={{ flexGrow: 0 }}
        customeStyle={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

function Email() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Email"
      subTitle="Email used in Invoices and communication."
    >
      <TextField
        control={control}
        name="companyDetails.email"
        label="Email"
        required
      />
    </SettingBlockItem>
  );
}
function ScacCode() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="SCAC Code"
      subTitle="Used for processing EDI tenders."
    >
      <TextField
        control={control}
        name="companyDetails.scacCode"
        label="SCAC Code"
      />
    </SettingBlockItem>
  );
}

function EmailFrom() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Email From"
      subTitle="Default Reply email for all Invoices and communication."
    >
      <SingleAutocompleteForm
        control={control}
        label="Reply Email"
        fieldName="value"
        fieldValue="key"
        name="replyTo"
        options={replyToOptions}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function CompanyAddress() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Company Address"
      subTitle="Company address used in Invoices."
    >
      <FormAddress
        control={control}
        name="companyDetails.addressData"
        sizes={{ xs: 12 }}
        isManual
        required
      />
    </SettingBlockItem>
  );
}

export default function CompanyInfo() {
  return (
    <section id={COMPANY_NAME} className="section">
      <SettingBlock
        title="Company Info"
        subTitle="These details are reflected on documents such as your customer invoices, driver settlements. These will also be reflected on your primary terminal."
      >
        <CompanyName />
        <CompanyDOT />
        <ScacCode />
        <CompanyLogo />
        <PhoneNumber />
        <FaxNumber />
        <Email />
        <EmailFrom />
        <CompanyAddress />
      </SettingBlock>
    </section>
  );
}
