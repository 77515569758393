import {
  FormLabel,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { loadService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { Divider } from '../../../../../components_v2/ui-kit/divider';
import { IconButton } from '../../../../../components_v2/ui-kit/icon-button';
import { ESecondaryDetailsPanelType } from '../../../../../constants/gantt/gantt.const';
import { CommodityTypeProvider } from '../../../../../contexts/CommodityTypeContext';
import {
  GetRemoveLoadManifestDataResp,
  ITagsAssignParamsRequest,
  LoadOverviewRemoveLoads,
} from '../../../../../models';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import { LoadSummary } from '../../../../../subPages/loadsList/models/LoadSummary';
import { ComponentType } from '../../../../../types';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import ThreeDotMenuIcon from '../../../../../ui-kit/components/ThreeDotMenuIcon';
import { navigationLinks } from '../../../../../utils/commonNavigation/constants/constants';
import { entityTypes } from '../../../../../utils/entityTypesData';
import { TagListComponent } from '../../../../../views/customTags/customTagChip';
import TerminalController from '../../../../../views/settings/terminals/TerminalController';
import DeletePopup from '../../../../DeletePopup';
import { DirtyDetailsPanelManager } from '../../../../DetailsPanel/utils';
import { Warnings } from '../../../../LoadSharedComponents/components/Warnings';
import SecondaryDetailsPanel from '../../../../SecondaryDetailsPanel/SecondaryDetailsPanel';
import { getOrganizationId } from '../../../../TimeoffDialog/utils';
import FormDialog from '../../../../Ui/FormDialog';
import HyperLink from '../../../../Ui/HyperLink/HyperLink';
import { useCarrierPermission } from '../../../../hooks/useCarrierPermission';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import { styles } from '../../Tracking/styles/styles';
import TagForm from '../forms/tagEditForm';
import { LoadManifest } from '../models';
import { removeLoadFromManifest } from '../utils';
import { KPICards } from './KPICards';
import { CommodityDetails } from './commodityDetails';
import { LoadSubDetails } from './loadSubDetails';
import { ModeDetails } from './modeDetails';
import { SingleFieldInfo } from './singleFieldInfo';
import { TagList } from '@/common/Tag/components/TagList';
import { TagItem } from '@/models/DTOs/ManageTypes/Requests';
export const loadIdStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
  display: 'inline-flex',
};

export const loadboxTitleStyle = {
  paddingY: '10px',
  paddingLeft: '15px',
  display: 'flex',
};

export const loadBoxContentStyle = {
  // padding: '10px',
  marginLeft: '20px',
};
export const loadCustomerStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 17, 34, 0.6)',
  display: 'inline-flex',
};
export const loadLabelStyles = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  display: 'inline-flex',
  color: 'rgba(0, 17, 34, 0.60)',
};
export const loadIconStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 17, 34, 0.6)',
  display: 'inline-flex',
  margin: '3px 5px 3px 0px',
};
export const OverViewloadRemoveStyle = {
  padding: '0px 16px 13px 13px',
  background: '#F5F5F5',
  borderRadius: '8px',
  margin: '15px 0px',
};
export const OverViewloadRemoveTitleStyle = {
  color: '#2B64CB',
  fontWeight: '600',
  paddingRight: '5px ',
  paddingLeft: '5px',
  cursor: 'pointer',
};
export const MenuOptions = [
  // {
  //   label: 'Delete',
  // },
  {
    label: 'Edit',
  },
];

export const LoadDetails = observer(
  ({
    loadStatus,
    loadType,
    manifestDeleteForLoad,
    isLoading,
    onUpdated,
    load,
    trackingUrl,
  }: {
    loadStatus: string;
    loadType: string;
    isLoading?: boolean;
    manifestDeleteForLoad?: (
      id: GetRemoveLoadManifestDataResp,
      status: boolean
    ) => void;
    onUpdated: () => void;
    load: LoadSummary;
    trackingUrl: string | null;
  }) => {
    const theme = useTheme();
    const location = useLocation();
    const { hasLoadEditPermission } = useLoadPermission();
    const [secondaryPanelData, setSecondaryPanelData] = useState<{
      type: keyof ComponentType;
      id: string | number;
    } | null>(null);
    const { getIsTerminalEnabled } = useRootStore();
    const { terminals } = TerminalController.instance();
    const { watch, setValue, control } = useFormContext();
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openWarning, setOpenWarning] = useState<boolean>(false);
    const [selectedLoad, setSelectedLoad] = useState<LoadManifest>();
    const [reqLoadRemove, setReqLoadRemove] = useState({
      open: false,
      message: null,
    });
    const [addCommodityDialogOpen, setCommodityDialog] = useState(false);
    const [tagEditDialogOpen, setTagEditDialogOpen] = useState(false);

    const { hasCarrierViewPermission } = useCarrierPermission();
    const [tagAnchorEl, setTagAnchorEl] = useState<null | HTMLElement>(null);

    const {
      myLoadStore: { setUpdatedLoadId },
      invoiceStore: { setPaymentUpdated },
      tripsStore,
    } = useStores();
    const data = watch();
    const {
      id,
      customer,
      loadDetails,
      loadedMiles,
      emptyMiles,
      revenue,
      loads,
      tripMiles,
      tags,
    } = data;
    const terminalReadOnlyCase = [
      'LOAD_COMPLETED',
      'CANCELLED',
      'INVOICED',
      'PAID',
    ].includes(loadStatus);

    const handleTagMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      setTagAnchorEl(event.currentTarget);
    };
    const handleTagMenuClose = () => {
      setTagAnchorEl(null);
    };

    const handleTagMenuItemClick = (
      action: { icon: string; label: string },
      accountData: any,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      if (action?.label == 'Delete') return;

      setTagEditDialogOpen(true);
      handleTagMenuClose();
      // handleDelete(alert);
    };

    const closeSecondaryDetailsPanel = () => {
      setSecondaryPanelData(null);
    };

    const handleCustomerClick = () => {
      if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.CUSTOMER,
        id: customer?.id,
      });
    };

    const handleSecondaryDetailsPanelSave = (data) => {
      setUpdatedLoadId && setUpdatedLoadId(id);
      const { type } = secondaryPanelData;
      if (type === 'CUSTOMER') {
        setValue('customer.name', data.name);
      }
      closeSecondaryDetailsPanel();
    };
    const removeLoad = async () => {
      const requestData = new LoadOverviewRemoveLoads({
        loadId: selectedLoad?.id,
        organizationId: getOrganizationId(),
      });
      const response = await removeLoadFromManifest(requestData);
      setOpenDelete(false);

      if (!(response instanceof ServiceError)) {
        const filteredItems = loads?.filter(
          (paymentTerm: { id: string }) => paymentTerm.id !== selectedLoad?.id
        );
        setValue('loads', filteredItems);
        manifestDeleteForLoad?.(response, Boolean(filteredItems.length));
        if (
          location?.pathname === navigationLinks[entityTypes.TRIPS] &&
          (response.affectedTripIds?.length > 0 ||
            response.removedTripIds?.length > 0)
        ) {
          tripsStore.getMultipleTripData(response);
        }
      } else {
        setReqLoadRemove({
          open: true,
          message: response.composedError.message?.response?.data.message,
        });
      }
    };
    const getDeleteBody = () => {
      if (loadType === 'MANIFEST') {
        return `${loads?.length === 1 ? t('subTile1') : t('subTitle2')}`;
      }
      return t('sureRemoveLoad');
    };

    const onUpdateTags = async (data: TagItem[]) => {
      const queryParams = new ITagsAssignParamsRequest();
      queryParams.loadId = id;
      const createTag = await loadService.loadAssignmentTag(data, queryParams);
      if (createTag instanceof ServiceError) {
        return;
      }
      handleTagMenuClose();
      setTagEditDialogOpen(false);
      onUpdated();
    };
    return isLoading ? (
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Skeleton
            variant="rectangular"
            height={'4rem'}
            width={'100%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'4rem'}
            width={'100%'}
            sx={{
              borderRadius: '5px',
            }}
          />
        </Stack>
        <Skeleton
          variant="rectangular"
          height={'8rem'}
          width={'100%'}
          sx={{
            borderRadius: '5px',
          }}
        />
        <Skeleton
          variant="rectangular"
          height={'8rem'}
          width={'100%'}
          sx={{
            borderRadius: '5px',
          }}
        />
        <Skeleton
          variant="rectangular"
          height={'8rem'}
          width={'100%'}
          sx={{
            borderRadius: '5px',
          }}
        />
      </Stack>
    ) : (
      <CommodityTypeProvider>
        <>
          <Box sx={{ pt: 1, pb: 2 }}>
            <Box
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: 'text.secondary',
                display: 'inline-block',
                lineHeight: '18px',
                // padding: '14px',
                width: '100%',
                borderRadius: '16px',
                background: '#f5f5f5!important',
              }}
            >
              {isLoading ? (
                <Stack direction="row" spacing={1}>
                  <Skeleton
                    variant="rectangular"
                    height={'4rem'}
                    width={'100%'}
                    sx={{
                      borderRadius: '5px',
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={'4rem'}
                    width={'100%'}
                    sx={{
                      borderRadius: '5px',
                    }}
                  />
                </Stack>
              ) : (
                <Box sx={{ ...loadboxTitleStyle }}>
                  <KPICards
                    kpis={{ loadedMiles, emptyMiles, revenue, tripMiles }}
                    loadType={loadType}
                  />
                  <ThreeDotMenuIcon
                    styleProps={{
                      fontSize: '12px',
                      padding: '0px',
                      marginTop: '2px',
                    }}
                    menuOptions={MenuOptions}
                    open={Boolean(tagAnchorEl)}
                    handleMenuClick={handleTagMenuClick}
                    handleMenuClose={handleTagMenuClose}
                    anchorEl={tagAnchorEl}
                    handleMenuItemClick={handleTagMenuItemClick}
                  />
                </Box>
              )}
              <Box sx={{ ...loadBoxContentStyle }}>
                <Box marginTop={2} display={'flex'} flexDirection={'column'}>
                  <SingleFieldInfo
                    label={t('loadsOverviewCustomerName')}
                    value={customer?.name}
                    styles={{ mt: '10px' }}
                    contentRenderer={() => (
                      <HyperLink
                        value={customer?.name}
                        onClick={handleCustomerClick}
                      />
                    )}
                  />
                  {trackingUrl && (
                    <Box
                      marginTop={'4px'}
                      sx={{
                        width: '100%',
                        wordBreak: 'break-word',
                      }}
                    >
                      <Stack direction="row">
                        <FormLabel
                          sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            marginRight: '1px',
                          }}
                        >
                          {t('loadsOverviewTracking') + ' : '}
                        </FormLabel>
                        <Typography
                          align="left"
                          sx={styles.urlText}
                          onClick={() => {
                            trackingUrl &&
                              window.open(trackingUrl, '_blank').focus();
                          }}
                        >
                          {trackingUrl}
                        </Typography>
                        <IconButton
                          sx={{
                            padding: '1px',
                            mx: '4px',
                          }}
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(trackingUrl);
                          }}
                        >
                          <IconsComponent
                            iconName="ContentCopy"
                            source="MUIcons"
                          />
                        </IconButton>
                      </Stack>
                    </Box>
                  )}
                </Box>
                {tags?.length > 0 && (
                  <Box display={'flex'} sx={{ marginTop: '10px' }}>
                    <FormLabel sx={{ fontSize: '14px', fontWeight: 600 }}>
                      Tags:
                    </FormLabel>
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                      sx={{ mt: '-2px', mb: '5px', marginLeft: '4px' }}
                    >
                      <TagList small tags={tags} />
                    </Stack>
                  </Box>
                )}
                <Box sx={{ mt: 2, mb: 2, mr: 2 }}>
                  <Warnings load={load as LoadSummary} />
                </Box>
              </Box>
            </Box>
            <LoadSubDetails load={data} onUpdated={onUpdated} />

            <ModeDetails
              loadDetails={loadDetails}
              loadId={load.id}
              onUpdated={onUpdated}
            />
            <Divider />
            <CommodityDetails
              loadDetails={loadDetails}
              loadId={load.id}
              onUpdated={onUpdated}
            />
          </Box>
          {secondaryPanelData && (
            <SecondaryDetailsPanel
              isGlobal={true}
              type={secondaryPanelData.type}
              id={secondaryPanelData.id}
              onClose={() => setSecondaryPanelData(null)}
              onUpdated={(data) => handleSecondaryDetailsPanelSave(data)}
            />
          )}
          {tagEditDialogOpen && (
            <FormDialog
              data={{
                customer,
                loadDetails,
                tags,
                trackingUrl,
                loadedMiles,
                emptyMiles,
                revenue,
                tripMiles,
              }}
              titleText={'Edit'}
              actionLabel={'Save'}
              disableActionBtnByDefault={true}
              open={tagEditDialogOpen}
              onAction={(data) => {
                onUpdateTags(data.tags);
              }}
              handleClose={() => {
                setTagEditDialogOpen(false);
                handleTagMenuClose();
              }}
              contentRenderer={() => (
                <TagForm handleCustomerClick={handleCustomerClick} />
              )}
            ></FormDialog>
          )}
          <DeletePopup
            title={t('removeLoad')}
            body={getDeleteBody()}
            subtitle={`${loads?.length === 1 ? t('subTile1') : t('subTitle2')}`}
            open={openDelete}
            onAction={async () => {
              removeLoad();
            }}
            okText={'Remove'}
            onClose={() => setOpenDelete(false)}
          />
          <DeletePopup
            title={openWarning ? t('warning') : ''}
            body={`Load cannot be removed as the 1st stop of load is completed.`}
            open={openWarning}
            hideDelete={true}
            backgroundColor={theme.palette.primary.main}
            onClose={() => setOpenWarning(false)}
          />
          <DeletePopup
            open={reqLoadRemove.open}
            onClose={() => {
              setReqLoadRemove({
                open: false,
                message: null,
              });
            }}
            title={'Info'}
            body={reqLoadRemove.message}
            hideDelete={true}
            cancelText={'Okay'}
            backgroundColor={theme.palette.primary.main}
            width={'440px'}
          />
        </>
      </CommodityTypeProvider>
    );
  }
);
