import {
  GetTagsRequest,
  TagEntityType,
  TagItem,
} from '@/models/DTOs/ManageTypes/Requests';
import MenuItem from '@mui/material/MenuItem';
import { Tag } from '@/common/Tag/components/Tag';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from "react";
import { TagFormModal } from '@/common/Tag/components/TagFormModal';
import { manageTypesService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import { tagEntities } from '@/common/Tag/constants/tag.constants';

type OptionType = TagItem & { label?: string };

interface Props {
  options?: OptionType[];
  fetchOptions?: boolean;
  onChange?: (values: OptionType[]) => void;
  entityType?: TagEntityType;
  defaultValue?: OptionType[];
  placeholder?: string;
}

export const TagInput: FC<Props> = ({
  options = [],
  fetchOptions = false,
  onChange,
  entityType,
  defaultValue,
  placeholder = 'tags',
}) => {
  const isFirstRender = useRef(true);
  const [tagOptions, setTagOptions] = useState<TagItem[]>(options);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState<OptionType[]>(defaultValue ?? []);
  const filteredOptions = tagOptions.filter(
    (option) => !values.some((value) => value.id === option.id)
  );
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    if (open && fetchOptions) {
      getOptions();
    }
  }, [inputValue, open, fetchOptions]);

  useEffect(() => {
    if (defaultValue && isFirstRender.current) {
      setValues(defaultValue);
      isFirstRender.current = false;
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange?.(values);
  }, [values]);

  const getOptions = async () => {
    setLoading(true);
    const request = new GetTagsRequest();
    request.entityTypes = entityType ? [entityType] : [];
    const result = await manageTypesService.getTags(request);
    if (!(result instanceof ServiceError)) {
      setTagOptions(result.content);
    }
    setLoading(false);
  };

  const handleTagCreate = async (data: TagItem) => {
    setValues([...values, data]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Autocomplete
        size="small"
        multiple
        open={open}
        limitTags={3}
        options={filteredOptions}
        loading={loading}
        onClose={handleClose}
        onBlur={handleClose}
        onOpen={handleOpen}
        value={values}
        disableCloseOnSelect
        onChange={(e, value) => {
          if (
            (value[value.length - 1] as OptionType)?.label?.startsWith('Add')
          ) {
            setShowAddModal(true);
            return;
          }
          setValues(value as OptionType[]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label={placeholder}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) =>
            option.name.toLowerCase().includes(params.inputValue.toLowerCase())
          );

          // Suggest adding the typed value as a new option
          if (
            params.inputValue !== '' &&
            !options.some((option) => option.name === params.inputValue)
          ) {
            filtered.push({
              id: '',
              color: 'black',
              name: `Add "${params.inputValue}"`,
              label: `Add "${params.inputValue}"`,
            } as any);
          }

          return filtered;
        }}
        renderOption={(props, option) => {
          return (
            <>
              <MenuItem
                {...props}
                sx={{
                  display: 'flex !important', // Ensures flexbox behavior
                  justifyContent: 'space-between !important',
                  alignItems: 'center',
                  paddingRight: '12px !important',
                  paddingLeft: '12px !important',
                }}
              >
                <Tag
                  title={option.name}
                  bgColor={option.color}
                  color={option.textColor}
                />
              </MenuItem>
            </>
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Box key={key} sx={{ marginRight: 0.5 }}>
                <Tag
                  removable
                  onRemoveClick={() =>
                    setValues(values.filter((v) => v.id !== option.id))
                  }
                  bgColor={option.color}
                  color={option.textColor}
                  title={option.name}
                  {...tagProps}
                />
              </Box>
            );
          });
        }}
      />
      {showAddModal && (
        <TagFormModal
          onCreate={handleTagCreate}
          open={showAddModal}
          onClose={() => setShowAddModal(false)}
          entityType={entityType}
          initialValues={{
            color: '',
            name: inputValue,
            entityTypes: entityType
              ? [
                  {
                    key: entityType,
                    value: tagEntities[entityType],
                    disabled: true,
                  },
                ]
              : [],
            description: '',
          }}
        />
      )}
    </>
  );
};
