import React from 'react';
import {
  DOWNVOTE_PUCK_ID,
  EVENT_STATUS,
  EVENT_TYPES,
  PUCK_DEFAULT_GAP_SIZE,
  PUCK_DEFAULT_PADDING_SIZE,
  PUCK_MIN_SIZE_TO_DOWNVOTE,
  PUCK_SIZES_IN_PX,
  defaultOriginDestinationStyles,
} from '../../constants/puck.constant';
import {
  getDateFormatForTime,
  getTripSelectPuckContent,
} from './ganttchart.service';
import { DTIcon } from '../../static';
import {
  GAP_PUCK_BORDER_COLOR,
  PUCK_TEXT_COLOR_BY_TYPE,
} from '../ganttchart.config';
import { PUCK_COLOR_BY_STATUS } from '../../config';
import { Typography } from '@mui/material';
import {
  DEADHEAD_BORDER_COLOR,
  DEFAULT_THEME_COLOR,
  NEEDLOAD_COLOR,
} from '../../constants/gantt.constant';

interface IPuckResponsiveRendererdData {
  eventRecord: any;
  renderData: any;
}

export const getEventBackgroundColor = (status: string): string => {
  switch (status) {
    case EVENT_STATUS.AVAILABLE:
      return PUCK_COLOR_BY_STATUS[EVENT_TYPES.AVAILABLE];
    case EVENT_STATUS.PLANNED:
    case EVENT_STATUS.PLANNED_DISAPPROVE:
      return PUCK_COLOR_BY_STATUS[EVENT_TYPES.PLANNED];
    case EVENT_STATUS.COMPLETED:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.COMPLETED];
    case EVENT_STATUS.CANCELLED:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.CANCELLED];
    case EVENT_STATUS.INVOICED:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.INVOICED];
    case EVENT_STATUS.PAID:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.PAID];
    case EVENT_STATUS.IN_PROGRESS:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.IN_PROGRESS];
    case EVENT_STATUS.RELAY_START:
    case EVENT_STATUS.RELAY_END:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.RELAY_START];
    case EVENT_STATUS.DETAINED:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.DETAINED];
    case EVENT_STATUS.DISPATCHED:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.DISPATCHED];
    case EVENT_STATUS.ASSIGNED:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.ASSIGNED];
    case EVENT_STATUS.IN_TRANSIT:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.IN_TRANSIT];
    case EVENT_STATUS.RECOMMENDED:
      return PUCK_COLOR_BY_STATUS[EVENT_STATUS.RECOMMENDED];
    case EVENT_STATUS.DEADHEAD:
      return 'rgba(76, 175, 80, 0.08)';
    default:
      return 'inherit';
  }
};

export const LOADPUCK_PROPS = (data: any) => {
  return {
    AVAILABLE: {
      puckBackgroundColor:
        data?.puckBackgroundColor ??
        getEventBackgroundColor(EVENT_STATUS.AVAILABLE),
    },
    EMPTYLOAD: { emptyLoad: true },
    GAP: {
      containerWidth: '100%',
      containerHeight: '100%',
      outerBoxStyle: { margin: 'auto' },
      isDottedPuck: true,
      emptyLoad: true,
      showPuckOnHover: true,
      puckBackgroundColor:
        data?.puckBackgroundColor ?? PUCK_COLOR_BY_STATUS[EVENT_TYPES.GAP],
      innerContentStyles: { color: GAP_PUCK_BORDER_COLOR },
      puckInnerElement: (
        <Typography style={{ color: `${DEFAULT_THEME_COLOR}` }}>+</Typography>
      ),
      containerStyles: {
        ...data?.containerStyles,
        border: `1px solid ${DEFAULT_THEME_COLOR}`,
      },
    },
    EMPTY: {
      containerWidth: '100%',
      containerHeight: '100%',
      outerBoxStyle: { margin: 'auto' },
      isDottedPuck: true,
      emptyLoad: true,
      showPuckOnHover: true,
      puckBackgroundColor:
        data?.puckBackgroundColor ?? PUCK_COLOR_BY_STATUS[EVENT_TYPES.GAP],
      innerContentStyles: { color: GAP_PUCK_BORDER_COLOR },
      puckInnerElement: (
        <Typography style={{ color: `${DEFAULT_THEME_COLOR}` }}>+</Typography>
      ),
      containerStyles: {
        ...data?.containerStyles,
        border: `1px solid ${DEFAULT_THEME_COLOR}`,
      },
    },
    RELAY_START: {
      withIcon: true,
      iconPosition: 'left' as any,
      notificationBadge: true,
    },
    RELAY_END: {
      withIcon: true,
      iconPosition: 'right' as any,
      notificationBadge: true,
    },
    PLANNED: {},
    PLANNED_DISAPPROVE: {
      puckBackgroundColor:
        data?.puckBackgroundColor ??
        getEventBackgroundColor(EVENT_STATUS.PLANNED_DISAPPROVE),
      withDownVote: true,
      downVoteId: DOWNVOTE_PUCK_ID,
      withDownVoteIconColor: '#C8372C',
      thumsDownIconContained: true,
    },
    COMPLETED: {
      puckBackgroundColor:
        data?.puckBackgroundColor ??
        getEventBackgroundColor(EVENT_STATUS.COMPLETED),
      innerContentStyles: {
        color: data?.textColor ?? PUCK_TEXT_COLOR_BY_TYPE.COMPLETED,
      },
    },
    DETAINED: {
      withIcon: true,
      isDelayed: true,
      delayedIcon: <DTIcon />,
      iconPosition: 'left' as any,
    },
    NEED_LOAD: {
      isEmptyPuck: true,
      containerStyles: {
        border: `1px solid ${NEEDLOAD_COLOR}`,
        borderRadius: '8px',
        backgroundColor: getEventBackgroundColor(EVENT_STATUS.NEED_LOAD),
        ...data?.containerStyles,
      },
      innerContentStyles: { color: NEEDLOAD_COLOR },
      isTypeNeedLoad: true,
    },
    DEADHEAD: {
      isEmptyPuck: true,
      containerStyles: {
        border: `2px solid ${DEADHEAD_BORDER_COLOR}`,
        borderRadius: '8px',
        backgroundColor: getEventBackgroundColor(EVENT_STATUS.DEADHEAD),
        ...data?.containerStyles,
      },
      puckContainerColor: 'inherit',
      puckBackgroundColor: data?.puckBackgroundColor ?? 'inherit',
      innerContentStyles: { color: '#000000' },
    },
  };
};

export const isTripEmpty = (status: string) => status === EVENT_STATUS.EMPTY;

export const puckResponsiveRenderedData = ({
  eventRecord,
  renderData,
}: IPuckResponsiveRendererdData) => {
  const puckWidth = renderData?.width;

  if (eventRecord?.data?.isTripSelect) {
    return getTripSelectPuckContent(puckWidth, eventRecord.data);
  }

  if (
    eventRecord.data.status === EVENT_STATUS.DEADHEAD ||
    eventRecord.data.status === EVENT_STATUS.NEED_LOAD
  ) {
    if (puckWidth < 125) {
      return {
        ...eventRecord.data,
        originText: '',
        destinationText: '',
        originTimeline: '',
        destinationTimeline: '',
        middleText: '',
        withDownVote: false,
      };
    }
    if (!eventRecord?.data?.middleText) {
      if (
        eventRecord.data?.miles &&
        eventRecord.data?.travelTimeDisplay &&
        !(
          isTripEmpty(eventRecord?.data?.type) ||
          isTripEmpty(eventRecord?.data?.status)
        )
      )
        eventRecord.data.middleText = `${eventRecord.data?.miles} mi | ${eventRecord.data?.travelTimeDisplay}`;
      else if (!eventRecord.data?.miles)
        eventRecord.data.middleText = eventRecord.data?.travelTimeDisplay;
      else if (eventRecord.data?.travelTimeDisplay)
        eventRecord.data.middleText = `${eventRecord.data?.miles} mi`;
      else eventRecord.data.middleText = '';
    }
    return eventRecord.data;
  }

  if (puckWidth < PUCK_SIZES_IN_PX.SIZE_1) {
    return {
      ...eventRecord.data,
      originText: '',
      destinationText: '',
      originTimeline: '',
      destinationTimeline: '',
      middleText: '',
      withDownVote: false,
      delayedIcon: <></>,
      warningsCount: null,
      isDelayed: false,
    };
  }
  if (
    isPuckContentChangeNotAllowed(eventRecord.data.status) ||
    isPuckContentChangeNotAllowed(eventRecord.data.type)
  ) {
    return eventRecord.data;
  }
  if (!eventRecord?.data?.middleText) {
    if (eventRecord.data?.miles && eventRecord.data?.travelTimeDisplay)
      eventRecord.data.middleText = `${eventRecord.data?.miles} mi | ${eventRecord.data?.travelTimeDisplay}`;
    else eventRecord.data.middleText = '';
  }
  if (puckWidth < PUCK_SIZES_IN_PX.SIZE_2) {
    const props = {
      ...eventRecord.data,
      originText: '',
      destinationText: eventRecord?.data.destination?.state,
      originTimeline: '',
      middleText: '',
      withDownVoteText: false,
      delayedIcon: <></>,
      warningsCount: null,
      innerContentStyles: {
        ...eventRecord?.data?.innerContentStyles,
        maxWidth: `${puckWidth - PUCK_DEFAULT_PADDING_SIZE}px`,
        ...defaultOriginDestinationStyles,
      },
    };
    if (puckWidth < PUCK_MIN_SIZE_TO_DOWNVOTE) props.withDownVote = false;
    return props;
  }
  if (puckWidth < PUCK_SIZES_IN_PX.SIZE_3) {
    return {
      ...eventRecord.data,
      originText: `${eventRecord?.data.origin?.state}`,
      destinationText: `${eventRecord?.data.destination?.state}`,
      originTimeline: '',
      middleText: '',
      innerContentStyles: {
        ...eventRecord?.data?.innerContentStyles,
        maxWidth: `${Math.floor(puckWidth / 2) - PUCK_DEFAULT_GAP_SIZE}px`,
        ...defaultOriginDestinationStyles,
      },
    };
  }
  if (puckWidth < PUCK_SIZES_IN_PX.SIZE_4) {
    return {
      ...eventRecord.data,
      originText: `${getSplicedString(eventRecord.data?.origin?.city, 5)}, ${
        eventRecord.data?.origin?.state
      }`,
      destinationText: `${getSplicedString(
        eventRecord.data?.destination?.city,
        5
      )}, ${eventRecord.data?.destination?.state}`,
      middleText: '',
    };
  }
  if (puckWidth < PUCK_SIZES_IN_PX.SIZE_5) {
    return {
      ...eventRecord.data,
      originText: `${getSplicedString(eventRecord.data?.origin?.city, 5)}, ${
        eventRecord.data?.origin?.state
      }`,
      destinationText: `${getSplicedString(
        eventRecord.data?.destination?.city,
        5
      )}, ${eventRecord.data?.destination?.state}`,
      middleText: getSplicedString(eventRecord?.data?.middleText, 16),
    };
  }
  if (puckWidth < PUCK_SIZES_IN_PX.SIZE_6) {
    return {
      ...eventRecord.data,
      originText: `${getSplicedString(eventRecord.data?.origin?.city, 11)}, ${
        eventRecord.data?.origin?.state
      }`,
      destinationText: `${getSplicedString(
        eventRecord.data?.destination?.city,
        11
      )}, ${eventRecord.data?.destination?.state}`,
      middleText: getSplicedString(eventRecord?.data?.middleText, 20),
    };
  }
  if (puckWidth < PUCK_SIZES_IN_PX.SIZE_7) {
    return {
      ...eventRecord.data,
      originText: `${getSplicedString(eventRecord.data?.origin?.city, 18)}, ${
        eventRecord.data?.origin?.state
      }`,
      destinationText: `${getSplicedString(
        eventRecord.data?.destination?.city,
        18
      )}, ${eventRecord.data?.destination?.state}`,
      middleText: getSplicedString(eventRecord?.data?.middleText, 25),
    };
  }
  const numberOfContentsInPuck = 3;
  const gapSize = 2 * PUCK_DEFAULT_GAP_SIZE;
  const sizeOfPuckContent = Math.floor(
    (puckWidth - gapSize) / numberOfContentsInPuck
  );
  const eventData = { ...eventRecord?.data };
  eventData.originText =
    eventData.originText ??
    `${eventData.origin?.city}, ${eventData.origin?.state}`;
  eventData.destinationText =
    eventData.destinationText ??
    `${eventData.destination?.city}, ${eventData.destination?.state}`;
  eventData.innerContentStyles = {
    ...eventRecord?.data?.innerContentStyles,
    maxWidth: `${sizeOfPuckContent}px`,
    ...defaultOriginDestinationStyles,
  };
  return eventData;
};

const getSplicedString = (text: string, count: number) => {
  if (!text) return '';
  return text.length >= count ? `${text.slice(0, count)}...` : text;
};

const isPuckContentChangeNotAllowed = (status: string) => {
  if (
    status === EVENT_STATUS.EMPTY ||
    status === EVENT_TYPES.GAP ||
    status === EVENT_TYPES.EMPTYLOAD
  ) {
    return true;
  }
  return false;
};

export const getPuckContentFromEventRecord = (data: any) => {
  if (!data?.data) {
    if (!data.eventRecord?.data) return {};
    data = { data: data?.eventRecord?.data };
  }
  const {
    originTimeline,
    destinationTimeline,
    originText,
    destinationText,
    middleText,
    puckInnerTextClass,
    puckBackgroundColor,
    ...rest
  } = data?.data;
  const props = {
    originTimeline: originTimeline ?? getDateFormatForTime(rest?.startDate),
    destinationTimeline:
      destinationTimeline ?? getDateFormatForTime(rest?.endDate),
    originText,
    destinationText,
    middleText,
    puckInnerTextClass,
    puckBackgroundColor:
      puckBackgroundColor ?? getEventBackgroundColor(data?.data?.status),
    containerStyles: {
      ...data?.data?.containerStyles,
      height: data?.puckHeight,
    },
    ...rest,
  };
  return props;
};
