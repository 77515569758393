import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CreateManageTypeRequest,
  DeleteManageTypeRequest,
  GetManageTypesRequest,
  ManageTypesItem,
  UpdateManageTypeRequest,
  GetTagsRequest,
  TagItem,
  CreateTagRequest,
  DeleteTagRequest,
  UpdateTagRequest,
  GetTagColorsRequest,
  TagColor,
  DuplicateTagRequest,
} from '../../models/DTOs/ManageTypes/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { PaginatedResult } from '../../types';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { IManageTypesService } from '../interfaces/IManageTypesService';

export class ManageTypesService extends IManageTypesService {
  @annotateNameAsync
  async getManageTypesList(
    requestData: GetManageTypesRequest,
    routeName: string
  ): Promise<PaginatedResult<ManageTypesItem> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/preference/api/v2/${routeName}/items/list`,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deleteManageType(
    requestData: DeleteManageTypeRequest,
    routeName: string
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        `/web/preference/api/v2/${routeName}/items`,
        requestData,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteItem',
      });
      return response.data;
    } catch (error: any) {
      const errorObj = this.getServiceError(error as ComposedError);
      if (errorObj?.error?.response?.status === 405) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'generalFailureMsg',
          message: 'Custom type is currently in use',
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'generalFailureMsg',
        });
      }
      return errorObj;
    }
  }

  @annotateNameAsync
  async createManageType(
    requestData: CreateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `/web/preference/api/v2/${routeName}/items`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'savePayItemCategory',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async updateManageType(
    requestData: UpdateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/preference/api/v2/${routeName}/items`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'savePayItemCategory',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getTags(
    requestData: GetTagsRequest
  ): Promise<PaginatedResult<TagItem> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/preference/api/v2/tags/get/list`,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async createTag(
    requestData: CreateTagRequest
  ): Promise<TagItem | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `/web/preference/api/v2/tags`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Tag created successfully',
      });
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deleteTag(
    requestData: DeleteTagRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        `/web/preference/api/v2/tags`,
        requestData,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Tag deleted successfully',
      });
      return response.data;
    } catch (error: any) {
      const errorObj = this.getServiceError(error as ComposedError);
      if (errorObj?.error?.response?.status === 417) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: 'Tag is currently in use',
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: 'Something went wrong. Please try again later.',
        });
      }
      return errorObj;
    }
  }

  @annotateNameAsync
  async updateTag(
    requestData: UpdateTagRequest
  ): Promise<TagItem | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/preference/api/v2/tags`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Tag updated successfully',
      });
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async duplicateTag(
    requestData: DuplicateTagRequest
  ): Promise<TagItem | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/preference/api/v2/tags/duplicate`,
        requestData,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Tag created successfully',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Something went wrong. Please try again later.',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getTagColors(
    requestData: GetTagColorsRequest
  ): Promise<TagColor[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/preference/api/v2/tags/get/color-list`,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }
}
