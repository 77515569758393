import { financeLoadService, loadService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { Warning } from '../../../../locales/en/dispatch/warning';
import {
  AssignDispatcherToLoadRequest,
  AssignDriverToLoadRequest,
  AssignScenarioType,
  AssignTractorToLoadRequest,
  AssignTrailerToLoadRequest,
  BrokeredUnAssignParams,
  ByLoadIdOnlyRequest,
  GetHosLastLocationRequest,
  GetPrevLoadsRequest,
  UnAssignDriverFromLoadRequest,
  WarningsForLoadAssignRequest,
  assignCarrierToLoadParams,
} from '../../../../models';
import {
  GetPreviousLoadContent,
  PaginatedGetPreviousLoadResponse,
  PaginatedResponse,
} from '../../../../models/DTOs/Dispatch/Dispatch';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import {
  LoadItemOptionsProps,
  generateLoadItemOptions,
} from '../../../TimeoffDialog/components/Form/LoadItem';
import {
  CreateLoadFinanceExpenseDTO,
  EPaymentDetailType,
} from '../FinanceOverview/FinanceOverviewModel';
import { Trip } from './Models';

type DriverType = 'primary' | 'secondary' | '';

const hazmatWrnText = (driver: DriverType = '') =>
  `The ${
    driver && driver + ' '
  }driver has no hazmat certification required for this load.`;

const trailerTankerText = (driver: DriverType = '') =>
  `The ${
    driver && driver + ' '
  }driver has no permission to carry a trailer of tanker type.`;

const trailerAssignmentText = (driver: DriverType = '') =>
  `The ${driver && driver + ' '}driver has no permission to carry a trailer.`;

const tractorManualTransmissionText = (driver: DriverType = '') =>
  `The ${
    driver && driver + ' '
  }driver has no permission to drive a tractor with manual transmission.`;

const tractorAirBrakingText = (driver: DriverType = '') =>
  `The ${
    driver && driver + ' '
  }driver has no permission to drive a tractor with air brakes`;

const trailerMismatchWrnText =
  "The assigned trailer's type mismatches with the required equipment type for this load.";

export default class RoutesController {
  static async getDriverInfoForLoadAssign(
    driverGroup,
    loadId: string,
    loadStatus: string,
    prevLoadId: string | null
  ) {
    const result = await loadService.getDriverInfoForLoadAssign(
      driverGroup.id,
      loadId,
      loadStatus,
      prevLoadId
    );

    if (result instanceof ServiceError) {
      // error handling
    } else {
      return driverGroup.drivers.map((driver) => ({
        ...driver,
        paymentDetails: result.data?.[driver.id],
      }));
    }
  }

  static async getPrevLoadsForDriverAssignPlan({
    seqNumber,
    pageNumber,
    driverGroupId,
    loadStatus,
  }: {
    seqNumber: string;
    pageNumber: number;
    driverGroupId: string;
    loadStatus: string;
  }): Promise<PaginatedResponse<LoadItemOptionsProps> | null> {
    const queryParams = new GetPrevLoadsRequest({
      pageNumber,
      pageSize: 25,
      seqNumber,
      driverGroupId,
      loadStatus,
    });

    const response: PaginatedGetPreviousLoadResponse | ServiceError =
      await loadService.getPrevLoadsForDriverAssignPlan(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    }
    const { content, ...restResponse } = response;
    const parsedContents: LoadItemOptionsProps[] = content.map(
      (item: GetPreviousLoadContent): LoadItemOptionsProps => {
        return generateLoadItemOptions(item);
      }
    );
    return {
      ...restResponse,
      content: parsedContents,
    } as PaginatedResponse<LoadItemOptionsProps>;
  }

  static async assignDriverToLoad(
    groupId: string,
    trip: Trip,
    prevLoadId: string,
    loadAssignmentStatus: string,
    driverPaymentDetails
  ) {
    const req = new AssignDriverToLoadRequest();
    req.autoPositioning = !prevLoadId;
    req.groupId = groupId;
    req.loadAssignmentStatus = loadAssignmentStatus;
    req.loadId = trip.id;
    req.terminalId = trip.terminal?.id;
    req.prevLoadId = prevLoadId;

    const result = await loadService.assignDriverToLoad(req);

    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripAssignDriver',
      });
      return;
    }
    const expenses =
      result?.data?.loadExpensesByDriverIds &&
      Object.keys?.(result?.data?.loadExpensesByDriverIds)?.length
        ? result?.data?.loadExpensesByDriverIds
        : driverPaymentDetails;
    if (driverPaymentDetails) {
      this.updateDriversPayment(
        expenses,
        driverPaymentDetails,
        result?.data?.loadExpensesByDriverIds
      );
    }
    return this.convertResToAsset(result.data);
  }

  static async assignDispatchTerminalToLoad(
    tripId: string,
    terminalId: string
  ) {
    const result = await loadService.assignDispatchTerminalToLoad(
      tripId,
      terminalId
    );
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripAssignTerminal',
      });
      return;
    }
    return result;
  }

  static convertResToAsset(data) {
    type Asset = { id: number; name: string; explicit: boolean };
    const assets: { tractor: Asset | null; trailer: Asset | null } = {
      tractor: null,
      trailer: null,
    };
    const {
      tractorId,
      tractorName,
      tractorExplicit,
      trailerId,
      trailerName,
      trailerExplicit,
    } = data?.load?.assignment;
    if (tractorId) {
      assets.tractor = {
        id: tractorId,
        name: tractorName,
        explicit: tractorExplicit,
      };
    }
    if (trailerId) {
      assets.trailer = {
        id: trailerId,
        name: trailerName,
        explicit: trailerExplicit,
      };
    }
    return assets;
  }

  static async updateDriversPayment(
    expenses: any,
    driverPaymentDetails: any,
    assignedDriverDetails: any
  ) {
    const entity: any = assignedDriverDetails
      ? Object.values?.(assignedDriverDetails)?.[0]
      : {};
    if (!Boolean(entity?.id)) {
      for (const property in expenses) {
        expenses[property].items = driverPaymentDetails[property].items;
        expenses[property].selectRecordList = expenses[property]?.items;
        expenses[property].total = driverPaymentDetails[property]?.total;
        const requestPayload = new CreateLoadFinanceExpenseDTO(
          expenses[property]
        );
        await financeLoadService.createNewLoadFinanceExpense(
          EPaymentDetailType.Driver,
          requestPayload
        );
      }
    } else {
      for (const property in expenses) {
        expenses[property].items = driverPaymentDetails[property].items;
        await loadService.updateLoadDriverLineItems(expenses[property]);
      }
    }
  }

  static async getDriverWarningsForLoadAssign(
    driverGroupId: string,
    loadId: string
  ) {
    const req = new WarningsForLoadAssignRequest();
    req.driverGroupId = driverGroupId;
    req.loadId = loadId;
    req.scenarioType = AssignScenarioType.DRIVER;
    req.warningTypes = [
      'ShowHazardousCommodityWarning',
      'ShowTrailerTypeMatchingWarningForDriver',
    ];
    const result = await loadService.getWarningsForLoadAssign(req);
    const warnings = await this.calculateHosLocationWarnings(driverGroupId);

    return result instanceof ServiceError
      ? warnings
      : [...warnings, ...this.calculateWarnings(result.data)];
  }

  static async calculateHosLocationWarnings(
    id: string
  ): Promise<{ label: string }[]> {
    const response = await loadService.getHosLastLocation(
      new GetHosLastLocationRequest({ ids: [id] })
    );

    const driverWarningData = response?.mapping?.[id];

    if (driverWarningData?.hosOutdated && driverWarningData?.locationOutdated) {
      return [
        {
          label: Warning.hosOutdated_locationOutdated,
        },
      ];
    }

    if (driverWarningData?.hosOutdated) {
      return [
        {
          label: Warning.hosOutdated,
        },
      ];
    }

    if (driverWarningData?.locationOutdated) {
      return [
        {
          label: Warning.locationOutdated,
        },
      ];
    }

    return [];
  }

  static async getTractorWarningsForLoadAssign(
    tractorId: number,
    loadId: string,
    driverGroupId?: string
  ) {
    const req = new WarningsForLoadAssignRequest();
    req.tractorId = tractorId;
    req.loadId = loadId;
    req.driverGroupId = driverGroupId;
    req.scenarioType = AssignScenarioType.TRACTOR;
    req.warningTypes = [
      'ShowManualTransmissionWarning',
      'ShowAirBrakingWarning',
    ];
    const result = await loadService.getWarningsForLoadAssign(req);

    if (result instanceof ServiceError) {
      // error handling
    } else {
      return this.calculateWarnings(result.data);
    }
  }

  static async getTrailerWarningsForLoadAssign(
    trailerId: number,
    loadId: string,
    driverGroupId?: string
  ) {
    const req = new WarningsForLoadAssignRequest();
    req.trailerId = trailerId;
    req.driverGroupId = driverGroupId;
    req.loadId = loadId;
    req.scenarioType = AssignScenarioType.TRAILER;
    req.warningTypes = [
      'ShowTrailerAssignmentWarning',
      'ShowTankerTrailerWarning',
      'ShowTrailerTypeMatchingWarningForTrailer',
      'ShowTrailerTypeMatchingWarningForDriverLoads',
    ];
    const result = await loadService.getWarningsForLoadAssign(req);

    if (result instanceof ServiceError) {
      // error handling
    } else {
      return this.calculateWarnings(result.data);
    }
  }

  // tractor assign part
  static async getTractorInfoForLoadAssign(tractorId: string, loadId: string) {
    const result = await loadService.getTractorInfoForLoadAssign(
      tractorId,
      loadId
    );
    return { paymentDetails: result.data };
  }

  // dispatcher assign part
  static async getDispatcherInfoForLoadAssign(
    dispatcherId: string,
    loadId: string
  ) {
    const result = await loadService.getDispatcherInfoForLoadAssign(
      dispatcherId,
      loadId
    );
    return { paymentDetails: result.data };
  }

  static async unassignTractorFromLoad(trailerId: string, loadId: string) {
    const req = new AssignTractorToLoadRequest();
    req.tractorId = trailerId;
    req.loadId = loadId;

    const result = await loadService.unassignTractorFromLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripUnAssignTractor',
      });
      return;
    }
    if (!result.data.successfullyUnassigned) {
      return {
        canNotDeleteMessage: `Please remove the payment details associated with this load from the tractor settlement report "${result.data.settlementIdList // @TODO: Anoush settlementIdList rename from the BE side
          .map((settlement) => settlement.settlementSeqNumber) // TODO: Anoush settlementSeqNumber rename from the BE side
          .join(', ')}" to be able to proceed with tractor un-assignment.`,
      };
    }
    if (result.data.tractorId) {
      const {
        tractorId: id,
        tractorName: name,
        tractorExplicit: explicit,
      } = result.data;
      result.tractor = { id, name, explicit };
    }
    return result;
  }

  static async assignTractorToLoad(
    tractorId: string,
    loadId: string,
    tractorAmountForAssign
  ) {
    const req = new AssignTractorToLoadRequest();
    req.tractorId = tractorId;
    req.loadId = loadId;

    const result = await loadService.assignTractorToLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripAssignTractor',
      });
      return;
    }
    const expense = result?.data?.loadExpenseTractor?.id
      ? result.data.loadExpenseTractor
      : tractorAmountForAssign?.[tractorId];
    if (tractorAmountForAssign) {
      this.updateTractorPayment(
        expense,
        tractorAmountForAssign,
        result?.data?.loadExpenseTractor
      );
    }

    return result.data;
  }

  static async updateTractorPayment(
    expenses: any,
    tractorPaymentDetailsObj: any,
    assignedTractorDetails: any
  ) {
    const tractorPaymentDetails: any =
      tractorPaymentDetailsObj?.[expenses.tractorId];
    if (!Boolean(assignedTractorDetails?.id)) {
      expenses.items = tractorPaymentDetails?.items;
      expenses.selectRecordList = expenses?.items;
      expenses.total = tractorPaymentDetails?.total;
      const requestPayload = new CreateLoadFinanceExpenseDTO(expenses);
      await financeLoadService.createNewLoadFinanceExpense(
        EPaymentDetailType.Tractor,
        requestPayload
      );
    } else {
      expenses.items = tractorPaymentDetails?.items;
      await loadService.updateLoadTractorLineItems(expenses);
    }
  }

  static async unassignDispatcherFromLoad(loadId: string) {
    const req = new AssignDispatcherToLoadRequest();
    req.loadId = loadId;

    const result = await loadService.unassignDispatcherFromLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripUnAssignDispatcher',
      });
      return;
    }
    if (!result.data.successfullyUnassigned) {
      return {
        canNotDeleteMessage: `Please remove the payment details associated with this load from the dispatcher commission report ${result.data.settlementIdList
          .map((settlement) => settlement.seqNumber)
          .join(', ')} to be able to proceed with dispatcher un-assignment.`,
      };
    }
    return result.data;
  }

  static async unassignDriverFromLoad(loadId: string) {
    const req = new UnAssignDriverFromLoadRequest();
    req.tripId = loadId;

    const result = await loadService.unassignDriverFromLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripUnAssignDriver',
      });
      return;
    }
    if (!result.data.successfullyUnassigned) {
      return {
        canNotDeleteMessage: `Please remove the payment details associated with this load from the driver settlement report "${result.data.settlementIdList
          .map((settlement) => settlement.seqNumber)
          .join(', ')}" to be able to proceed with driver un-assignment.`,
      };
    }
    return result.data;
  }

  static async unassignCarrierFromLoad(loadId: string) {
    const req = new UnAssignDriverFromLoadRequest();
    req.tripId = loadId;

    const result = await loadService.unassignDriverFromLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripUnAssignCarrier',
      });
      return;
    }
    if (!result.data.successfullyUnassigned) {
      return {
        canNotDeleteMessage: `Please remove the payment details associated with this trip from the carrier payment to be able to proceed with carrier un-assignment.."`,
      };
    }
    return result.data;
  }

  static async assignDispatcherToLoad(
    dispatcherId: string,
    loadId: string,
    dispatcherAmountForAssign
  ) {
    const req = new AssignDispatcherToLoadRequest();
    req.dispatcherId = dispatcherId;
    req.loadId = loadId;

    const result = await loadService.assignDispatcherToLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripAssignDispatcher',
      });
      return;
    }
    const expense = result.data.loadExpenseDispatcher?.id
      ? result.data.loadExpenseDispatcher
      : dispatcherAmountForAssign?.[dispatcherId];
    if (dispatcherAmountForAssign) {
      this.updateDispatcherPayment(
        expense,
        dispatcherAmountForAssign,
        result?.data?.loadExpenseDispatcher
      );
    }
    return result.data;
  }

  static async updateDispatcherPayment(
    expenses: any,
    dispatcherPaymentDetailsObj: any,
    assignedDispatcherDetails: any
  ) {
    const dispatcherPaymentDetails: any =
      dispatcherPaymentDetailsObj?.[expenses.dispatcherId];
    if (!Boolean(assignedDispatcherDetails?.id)) {
      expenses.items = dispatcherPaymentDetails?.items;
      expenses.selectRecordList = expenses?.items;
      expenses.total = dispatcherPaymentDetails?.total;
      const requestPayload = new CreateLoadFinanceExpenseDTO(expenses);
      await financeLoadService.createNewLoadFinanceExpense(
        EPaymentDetailType.Dispatcher,
        requestPayload
      );
    } else {
      expenses.items = dispatcherPaymentDetails?.items;
      await loadService.updateLoadDispatcherLineItems(expenses);
    }
  }

  static async unassignTrailerFromLoad(trailerId: number, loadId: string) {
    const req = new AssignTrailerToLoadRequest();
    req.trailerId = trailerId;
    req.loadId = loadId;

    const result = await loadService.unassignTrailerFromLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripUnAssignTrailer',
      });
      return;
    }
    if (result.data.trailerId) {
      const {
        trailerId: id,
        trailerName: name,
        trailerExplicit: explicit,
      } = result.data;
      result.trailer = { id, name, explicit };
    }
    return result;
  }

  static async assignTrailerToLoad(trailerId: string, loadId: string) {
    const req = new AssignTrailerToLoadRequest();
    req.trailerId = trailerId;
    req.loadId = loadId;

    const result = await loadService.assignTrailerToLoad(req);
    if (result instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripAssignTrailer',
      });
      return;
    }
    return result.data;
  }

  static setupDriverWarnings(
    warnings: boolean[],
    generateWarning: (driver: DriverType) => string
  ): string[] {
    const list = [];
    const length = warnings?.length;
    if (length === 1) {
      if (warnings[0]) {
        list.push(generateWarning(''));
      }
      return list;
    }
    if (length === 2) {
      if (warnings[0]) {
        list.push(generateWarning('primary'));
      }
      if (warnings[1]) {
        list.push(generateWarning('secondary'));
      }
    }
    return list;
  }

  static calculateWarnings(data) {
    let warnings: string[] = [];
    const {
      warnings: {
        warningsStates: {
          ShowHazardousCommodityWarning,
          ShowTrailerTypeMatchingWarningForDriver,
          ShowTrailerTypeMatchingWarningForTrailer,
          ShowTankerTrailerWarning,
          ShowTrailerAssignmentWarning,
          ShowTrailerTypeMatchingWarningForDriverLoads,
          ShowManualTransmissionWarning,
          ShowAirBrakingWarning,
        },
      },
      safetyAndMaintenanceIssue,
    } = data;
    warnings = warnings.concat(
      this.setupDriverWarnings(ShowHazardousCommodityWarning, hazmatWrnText),
      this.setupDriverWarnings(ShowTankerTrailerWarning, trailerTankerText),
      this.setupDriverWarnings(
        ShowTrailerAssignmentWarning,
        trailerAssignmentText
      ),
      this.setupDriverWarnings(
        ShowManualTransmissionWarning,
        tractorManualTransmissionText
      ),
      this.setupDriverWarnings(ShowAirBrakingWarning, tractorAirBrakingText)
    );
    if (ShowTrailerTypeMatchingWarningForDriver) {
      warnings.push(trailerMismatchWrnText);
    }
    if (ShowTrailerTypeMatchingWarningForTrailer) {
      warnings.push(trailerMismatchWrnText);
    }
    if (ShowTrailerTypeMatchingWarningForDriverLoads) {
      warnings.push(trailerMismatchWrnText);
    }

    for (const key in safetyAndMaintenanceIssue) {
      if (!safetyAndMaintenanceIssue.hasOwnProperty(key)) return;
      const warningItem = safetyAndMaintenanceIssue[key];
      warningItem.alertMessages?.forEach((warning) => {
        if (warning?.text) {
          warnings.push(warning.text);
        }
      });
    }
    return warnings;
  }

  static async getCarrierList(
    searchText: string | undefined,
    pageNumber: number
  ): Promise<any | undefined> {
    const response = await loadService.getCarrierList(searchText, pageNumber);
    if (response) {
      return response?.content;
    }
  }
  static async getBrokeredUnAssign(
    request: BrokeredUnAssignParams
  ): Promise<any | undefined> {
    const response = await loadService.brokeredUnAssign(request);
    if (response) {
      return response;
    }
  }
  static async assignCarrierToLoad(
    request: assignCarrierToLoadParams
  ): Promise<any | undefined> {
    const response = await loadService.assignCarrierToLoad(request);
    if (response) {
      return response;
    }
  }
}

export const getLoadMapData = async (loadId: string) => {
  const request = new ByLoadIdOnlyRequest(loadId);
  return await loadService.getLoadMapData(request);
};
