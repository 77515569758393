import React, { FC } from 'react';
import { useLoadAlerts } from '@/common/LoadTabPanel/tabs/Alerts/hooks';
import { Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AddIcon } from '@/ui-kit/components/Assets';
import ButtonImproved from '@/ui-kit/components/ButtonImproved';
import { AXELE_PERMISSION_TYPE, Permission } from '@/common/Permission';
import { LoadAlertItem } from '@/common/LoadTabPanel/tabs/Alerts/components/LoadAlertItem';
import FormDialog from '@/common/Ui/FormDialog';
import { AddLoadAlertDefaultFormValues } from '@/common/LoadTabPanel/tabs/Alerts/constants';
import { AddEditLoadAlertForm } from '@/common/LoadTabPanel/tabs/Alerts/components/AddEditLoadAlertForm';
import { AddEditLoadAlertFormValidationSchema } from '@/common/LoadTabPanel/tabs/Alerts/constants/LoadAlertsConstants';

interface Props {
  loadId: string;
  customerId?: string;
}

export const LoadAlerts: FC<Props> = ({ loadId, customerId }) => {
  const {
    loading,
    loadAlerts,
    flagTypes,
    showAddAlertModal,
    httpErrors,
    handleAddAlertDialogClose,
    handleAddAlertDialogOpen,
    handleLoadAlertAdd,
    updateAlertsList,
  } = useLoadAlerts(loadId);
  if (loading) {
    return (
      <Stack direction={'column'} mt={'1rem'} spacing={3}>
        {[...new Array(10)].map((index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={'100%'}
            height={'1rem'}
          />
        ))}
      </Stack>
    );
  }
  return (
    <>
      <Stack marginTop={2} gap={2}>
        <Stack
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant={'h7'} color={'primary.main'}>
            Alerts
          </Typography>
          <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
            <ButtonImproved
              onClick={handleAddAlertDialogOpen}
              variant="contained"
              startIcon={<AddIcon color="#fff" />}
              label="Add Alert"
            />
          </Permission>
        </Stack>
        <Stack>
          {loadAlerts.map((alert) => (
            <LoadAlertItem
              key={alert.id}
              alert={alert}
              flagTypes={flagTypes}
              updateAlertsList={updateAlertsList}
            />
          ))}
        </Stack>
      </Stack>
      {showAddAlertModal && (
        <FormDialog
          data={AddLoadAlertDefaultFormValues}
          open={showAddAlertModal}
          titleText="Add New Alert"
          onAction={handleLoadAlertAdd}
          handleClose={handleAddAlertDialogClose}
          actionLabel="Add Alert"
          contentRenderer={() => (
            <AddEditLoadAlertForm loadId={loadId} flagTypes={flagTypes} />
          )}
          validationSchema={AddEditLoadAlertFormValidationSchema}
          httpErrors={httpErrors}
        />
      )}
    </>
  );
};
