import React from 'react';

const WarningIcon = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.708496 13.8743H16.2918L8.50016 0.416016L0.708496 13.8743ZM9.2085 11.7493H7.79183V10.3327H9.2085V11.7493ZM9.2085 8.91602H7.79183V6.08268H9.2085V8.91602Z"
        fill="#FFA726"
      />
    </svg>
  );
};

export default WarningIcon;
