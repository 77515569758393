import {
  driverColumnsInfo,
  driverFilters,
  userColumnsInfo,
  userFilters,
  webUserColumnsInfo,
  webUserFilters,
} from '../views/settings/users/constants';

import { PayStatementsEntityTypeEnum } from '../models';
import {
  expenseColumnsInfo,
  expenseFilters,
} from '../views/finance/expenses/constants';
import {
  DEFAULT_INVOICE_SORT_NAME,
  invoiceCompletedView,
  invoiceFilters,
  invoicePaidView,
  invoicedView,
  paidInvoiceFilters,
  invoicedInvoiceFilters,
} from '../views/finance/invoices/constants';
import {
  getPayStatementColumns,
  payStatementFilters,
} from '../views/finance/payStatement/constants';
import {
  customerColumnsInfo,
  customerFilters,
  locationColumnsInfo,
  locationFilters,
  vendorColumnsInfo,
  vendorFilters,
} from '../views/operations/contacts/constants';
import {
  tractorColumnsInfo,
  tractorFilters,
  trailerColumnsInfo,
} from '../views/operations/equipment/components/ViewsSection';
import {
  activeLoadsStripId,
  allLoadsStripId,
  needDriversStripId,
  pastLoadsStripId,
  toBeInvoicedStripId,
  toBeSettledStripId,
} from './commonConstants';

import {
  carrierColumnsInfo,
  defaultCarrierFilters,
} from '../subPages/carriers/constants/constants';
import { DOCUMENT_NAME as DEFAULT_MANAGE_TYPE_NAME } from '../subPages/settings/ManageTypes/constants';
import {
  templateColumnsInfo,
  templateFilters,
} from '../subPages/templates/constants';
import {
  EDITendersAcceptedView,
  EDITendersAllView,
  EDITendersCanceledView,
  EDITendersDeclinedView,
  EEDITendersEntity,
} from '../views/EDITenders/constants/customView';
import {
  availableTrips,
  dispatchComponentKey,
  unassignedTripsId,
} from '../views/dispatch2/components/Trip/grid.config';
import { defaultDispatch2Filters } from '../views/dispatch2/constants/dispatch';
import { accountSummaryFilters } from '../views/finance/accountSummary/constants/accountSummary';
import { allPaymentView } from '../views/finance/paymentManagement/components/PaymentDataGrid/PaymentDataGrid.constants';
import {
  ELoadBoardEntity,
  bookedLoadView,
  bookmarkView,
  loadSearchView,
  pendingLoadView,
} from '../views/loadboards/constants';
import { maintView } from '../views/maintenanceRecords/components/ViewSection/constants';
import {
  activeLoadFilters,
  allLoadFilters,
  allLoadsColOrdering,
  loadColumnsInfo,
  loadNeedDriverFilters,
  loadToBeInvoicedFilters,
  loadToBeSettledFilters,
  pastLoadFilters,
} from '../views/myLoads/components/ViewSection/constants';
import { allTerminalsView } from '../views/settings/terminalsV2/constants/views';
import {
  EEntityList,
  activeTripsView,
  allTrips,
  needDriversView,
  pastTripsView,
  toBeSettledView,
} from '../views/trips/components/ViewSection/constants';
import { DEFAULT_CONTRACT_VIEWS, EContractsEntityList } from './contracts';

export const persistUserState: any = {
  version: '1.1.0',
  userId: 'null',
  pages: {
    Contacts: {
      stripId: 'customer',
      customer: {
        filters: customerFilters,
        columns: customerColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '+name',
          excludeRoleCodeList: ['DR'],
        },
      },
      vendor: {
        filters: vendorFilters,
        columns: vendorColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '+name',
          excludeRoleCodeList: ['DR'],
        },
      },
      location: {
        filters: locationFilters,
        columns: locationColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '+locationName',
          excludeRoleCodeList: ['DR'],
        },
      },
      carrier: {
        filters: defaultCarrierFilters,
        columns: carrierColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '+name',
          excludeRoleCodeList: ['DR'],
        },
      },
    },
    Equipment: {
      stripId: 'tractor',
      tractor: {
        filters: tractorFilters,
        columns: tractorColumnsInfo,
        query: {},
      },
      trailer: {
        filters: tractorFilters,
        columns: trailerColumnsInfo,
        query: {},
      },
    },
    Invoices: {
      stripId: 'completed',
      completed: {
        filters: invoiceFilters,
        columns: invoiceCompletedView.columnFilters,
        sort: DEFAULT_INVOICE_SORT_NAME,
      },
      ready: {
        filters: invoiceFilters,
        columns: invoiceCompletedView.columnFilters,
        sort: DEFAULT_INVOICE_SORT_NAME,
      },
      invoiced: {
        filters: invoicedInvoiceFilters,
        columns: invoicedView.columnFilters,
        sort: DEFAULT_INVOICE_SORT_NAME,
      },
      paid: {
        filters: paidInvoiceFilters,
        columns: invoicePaidView.columnFilters,
        sort: DEFAULT_INVOICE_SORT_NAME,
      },
    },
    Loadsearch: {
      stripId: ELoadBoardEntity.loadsearch,
      // columns: loadsearchColumnInfo,
      [ELoadBoardEntity.loadsearch]: {
        filters: loadSearchView.metaData,
        columns: loadSearchView.columnFilters,
        query: {},
      },
      [ELoadBoardEntity.bookmark]: {
        filters: bookmarkView.metaData,
        columns: bookmarkView.columnFilters,
        query: {},
      },
      [ELoadBoardEntity.pendingLoad]: {
        filters: pendingLoadView.metaData,
        columns: pendingLoadView.columnFilters,
        query: {},
      },
      [ELoadBoardEntity.booked]: {
        filters: bookedLoadView.metaData,
        columns: bookedLoadView.columnFilters,
        query: {},
      },
    },
    MyLoads: {
      stripId: allLoadsStripId,
      [allLoadsStripId]: {
        filters: allLoadFilters,
        columns: loadColumnsInfo,
        columnsOrdering: allLoadsColOrdering,
        query: {},
      },
      [needDriversStripId]: {
        filters: loadNeedDriverFilters,
        columns: loadColumnsInfo,
        query: {},
      },
      [activeLoadsStripId]: {
        filters: activeLoadFilters,
        columns: loadColumnsInfo,
        query: {},
      },
      [toBeInvoicedStripId]: {
        filters: loadToBeInvoicedFilters,
        columns: loadColumnsInfo,
        query: {},
      },
      [toBeSettledStripId]: {
        filters: loadToBeSettledFilters,
        columns: loadColumnsInfo,
        query: {},
      },
      [pastLoadsStripId]: {
        filters: pastLoadFilters,
        columns: loadColumnsInfo,
        query: {},
      },
    },
    Trips: {
      stripId: EEntityList.allTrips,
      [EEntityList.allTrips]: {
        filters: allTrips.metaData,
        columns: allTrips.columnFilters,
        columnsOrdering: [
          'warning',
          'seqNumber',
          'isEmptyTrip',
          'status',
          'tags',
          'brokerageTrip',
          'groupDetails',
          'carrierDetails',
          'dispatcherName',
          'tractorName',
          'trailerName',
          'chassisNumbers',
          'containerNumbers',
          'loadPriority',
          'canBeBrokered',
          'numberOfStops',
          'originLocationName',
          'destinationLocationName',
          'startLocation',
          'endLocation',
          'startDate',
          'endDate',
          'actualStartDate',
          'actualEndDate',
          'actualStartDateTime',
          'actualEndDateTime',
          'connectedLoads',
          'manifestSeqNumbersFilter',
          'onHoldState',
          'customers',
          'referenceIds',
          'revenue',
          'settlementStatus',
          'settlementAmount',
          'tripMileage',
          'emptyMileage',
          'terminal',
          'createDate',
        ],
        query: {},
      },
      [EEntityList.needDrivers]: {
        filters: needDriversView.metaData,
        columns: needDriversView.columnFilters,
        columnsOrdering: [
          'warning',
          'seqNumber',
          'isEmptyTrip',
          'status',
          'tags',
          'brokerageTrip',
          'groupDetails',
          'carrierDetails',
          'dispatcherName',
          'tractorName',
          'trailerName',
          'chassisNumbers',
          'containerNumbers',
          'loadPriority',
          'canBeBrokered',
          'numberOfStops',
          'originLocationName',
          'destinationLocationName',
          'startLocation',
          'endLocation',
          'startDate',
          'endDate',
          'actualStartDate',
          'actualEndDate',
          'actualStartDateTime',
          'actualEndDateTime',
          'connectedLoads',
          'manifestSeqNumbersFilter',
          'customers',
          'referenceIds',
          'revenue',
          'settlementStatus',
          'settlementAmount',
          'tripMileage',
          'emptyMileage',
          'terminal',
          'createDate',
        ],
        query: {},
      },
      [EEntityList.activeTrips]: {
        filters: activeTripsView.metaData,
        columns: activeTripsView.columnFilters,
        query: {},
        columnsOrdering: [
          'warning',
          'seqNumber',
          'isEmptyTrip',
          'status',
          'tags',
          'brokerageTrip',
          'groupDetails',
          'carrierDetails',
          'dispatcherName',
          'tractorName',
          'trailerName',
          'chassisNumbers',
          'containerNumbers',
          'loadPriority',
          'canBeBrokered',
          'numberOfStops',
          'originLocationName',
          'destinationLocationName',
          'startLocation',
          'endLocation',
          'startDate',
          'endDate',
          'actualStartDate',
          'actualEndDate',
          'actualStartDateTime',
          'actualEndDateTime',
          'connectedLoads',
          'manifestSeqNumbersFilter',
          'customers',
          'referenceIds',
          'revenue',
          'settlementStatus',
          'settlementAmount',
          'tripMileage',
          'emptyMileage',
          'terminal',
          'createDate',
        ],
      },
      [EEntityList.toBeSettled]: {
        filters: toBeSettledView.metaData,
        columns: toBeSettledView.columnFilters,
        query: {},
        columnsOrdering: [
          'warning',
          'seqNumber',
          'isEmptyTrip',
          'status',
          'tags',
          'brokerageTrip',
          'groupDetails',
          'carrierDetails',
          'dispatcherName',
          'tractorName',
          'trailerName',
          'chassisNumbers',
          'containerNumbers',
          'loadPriority',
          'canBeBrokered',
          'numberOfStops',
          'originLocationName',
          'destinationLocationName',
          'startLocation',
          'endLocation',
          'startDate',
          'endDate',
          'actualStartDate',
          'actualEndDate',
          'actualStartDateTime',
          'actualEndDateTime',
          'connectedLoads',
          'manifestSeqNumbersFilter',
          'customers',
          'referenceIds',
          'revenue',
          'settlementStatus',
          'settlementAmount',
          'tripMileage',
          'emptyMileage',
          'terminal',
          'createDate',
        ],
      },
      [EEntityList.pastTrips]: {
        filters: pastTripsView.metaData,
        columns: pastTripsView.columnFilters,
        query: {},
        columnsOrdering: [
          'warning',
          'seqNumber',
          'isEmptyTrip',
          'status',
          'tags',
          'brokerageTrip',
          'groupDetails',
          'carrierDetails',
          'dispatcherName',
          'tractorName',
          'trailerName',
          'chassisNumbers',
          'containerNumbers',
          'loadPriority',
          'canBeBrokered',
          'numberOfStops',
          'originLocationName',
          'destinationLocationName',
          'startLocation',
          'endLocation',
          'startDate',
          'endDate',
          'actualStartDate',
          'actualEndDate',
          'actualStartDateTime',
          'actualEndDateTime',
          'connectedLoads',
          'manifestSeqNumbersFilter',
          'customers',
          'referenceIds',
          'revenue',
          'settlementStatus',
          'settlementAmount',
          'tripMileage',
          'emptyMileage',
          'terminal',
          'createDate',
        ],
      },
    },
    Contracts: {
      stripId: EContractsEntityList.ALL_CONTRACT,
      [EContractsEntityList.ALL_CONTRACT]: {
        stripId: DEFAULT_CONTRACT_VIEWS[0].id,
        filters: DEFAULT_CONTRACT_VIEWS[0].metaData,
        columns: DEFAULT_CONTRACT_VIEWS[0].columnFilters,
        columnsOrdering: DEFAULT_CONTRACT_VIEWS[0].columnsOrdering,
      },
      [EContractsEntityList.ACTIVE]: {
        stripId: DEFAULT_CONTRACT_VIEWS[1].id,
        filters: DEFAULT_CONTRACT_VIEWS[1].metaData,
        columns: DEFAULT_CONTRACT_VIEWS[1].columnFilters,
        columnsOrdering: DEFAULT_CONTRACT_VIEWS[1].columnsOrdering,
      },
      [EContractsEntityList.EXPIRED]: {
        stripId: DEFAULT_CONTRACT_VIEWS[2].id,
        filters: DEFAULT_CONTRACT_VIEWS[2].metaData,
        columns: DEFAULT_CONTRACT_VIEWS[2].columnFilters,
        columnsOrdering: DEFAULT_CONTRACT_VIEWS[2].columnsOrdering,
      },
    },
    Templates: {
      stripId: 'template',
      ['template']: {
        filters: templateFilters,
        columns: templateColumnsInfo,
        query: {},
      },
    },
    Documents: {
      stripId: null,
      filters: {
        documentTypeIds: [],
        entityTypes: null,
        entities: [],
        permissionType: null,
      },
      query: {},
    },
    Users: {
      stripId: 'user',
      webUser: {
        filters: webUserFilters,
        columns: webUserColumnsInfo,
        query: {
          sort: '+firstname,+lastname',
          excludeRoleCodeList: ['DR'],
        },
      },
      user: {
        filters: userFilters,
        columns: userColumnsInfo,
        query: {
          sort: '+firstname,+lastname',
        },
      },
      driver: {
        filters: driverFilters,
        columns: driverColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '+firstname,+lastname',
          roleCodeList: ['DR'],
        },
      },
    },
    Expenses: {
      stripId: 'allExpenses',
      allExpenses: {
        filters: expenseFilters,
        columns: expenseColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '-paidDate',
          categoryKey: ['FUEL'],
        },
      },
      fuelExpense: {
        filters: expenseFilters,
        columns: expenseColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '-paidDate',
          categoryKey: ['FUEL'],
        },
      },
      otherExpense: {
        filters: expenseFilters,
        columns: expenseColumnsInfo,
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '-paidDate',
          excludeCategoryKey: ['FUEL'],
        },
      },
    },
    PayStatement: {
      stripId: 'pendingPayment',
      pendingPayment: {
        filters: payStatementFilters,
        columns: getPayStatementColumns(
          PayStatementsEntityTypeEnum.pendingPayment
        ),
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '-amount',
        },
      },
      inReviewStatement: {
        filters: payStatementFilters,
        columns: getPayStatementColumns(
          PayStatementsEntityTypeEnum.inReviewStatement
        ),
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '-amount',
        },
      },
      reviewedStatement: {
        filters: payStatementFilters,
        columns: getPayStatementColumns(
          PayStatementsEntityTypeEnum.reviewedStatement
        ),
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '-amount',
        },
      },
      closedStatement: {
        filters: payStatementFilters,
        columns: getPayStatementColumns(
          PayStatementsEntityTypeEnum.closedStatement
        ),
        query: {
          pageSize: 25,
          pageNumber: 1,
          sort: '-amount',
        },
      },
    },
    AccountSummary: accountSummaryFilters,
    [dispatchComponentKey]: {
      grantt: defaultDispatch2Filters,
      [unassignedTripsId]: {
        filters: availableTrips.metaData,
        columns: availableTrips.columnFilters,
        query: {},
      },
    },
    ManageTypes: {
      selectedTab: DEFAULT_MANAGE_TYPE_NAME,
    },
    EDITenders: {
      stripId: EEDITendersEntity.NEW,
      [EEDITendersEntity.NEW]: {
        filters: EDITendersCanceledView.metaData,
        columns: EDITendersCanceledView.columnFilters,
        query: {},
      },
      [EEDITendersEntity.ACCEPTED]: {
        filters: EDITendersAcceptedView.metaData,
        columns: EDITendersAcceptedView.columnFilters,
        query: {},
      },
      [EEDITendersEntity.DECLINED]: {
        filters: EDITendersDeclinedView.metaData,
        columns: EDITendersDeclinedView.columnFilters,
        query: {},
      },
      [EEDITendersEntity.CANCELED]: {
        filters: EDITendersCanceledView.metaData,
        columns: EDITendersCanceledView.columnFilters,
        query: {},
      },
      [EEDITendersEntity.ALL]: {
        filters: EDITendersAllView.metaData,
        columns: EDITendersCanceledView.columnFilters,
        query: {},
      },
    },
    Terminals: {
      stripId: 'allTerminals',
      allTerminals: {
        filters: allTerminalsView.metaData,
        columns: allTerminalsView.columnFilters,
        query: {},
      },
    },
    Maintenance: {
      stripId: 'maintenance',
      maintenance: {
        filters: maintView.metaData,
        columns: maintView.columnFilters,
        query: {},
      },
    },
    Payments: {
      stripId: 'allPayment',
      allPayment: {
        filters: allPaymentView.metaData,
        columns: allPaymentView.columnFilters,
        query: {},
      },
    },
  },
};
