import { useFormContext } from 'react-hook-form';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import Checkbox from '../../../common/Ui/Checkbox';
import FormPhoneField from '../../../common/Ui/FormPhoneField';
import { MultipleAutocompleteForm } from '../../../common/Ui/MultipleAutocomplete';
import TextField from '../../../common/Ui/TextField/TextField';
import { isHasPermission } from '../../../utils';
import { notificationsOption } from '../constants';

export default function ContactForm({ id }: { id: number }) {
  const { control, setValue, watch } = useFormContext();
  const [alerts] = watch([`contacts.${id}.alerts`]);
  const noPermissionForEditCustomer = !isHasPermission([
    AXELE_PERMISSION_TYPE.CUSTOMER_EDIT,
  ]);

  const handleNotificationChange = (data) => {
    const valueArray = data.map((item) => item.name);
    setValue(`contacts.${id}.alerts`, valueArray);
  };
  return (
    <>
      <TextField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.fullName`}
        label="Name"
        sizes={{ xs: 6 }}
        required
        size={'small'}
      />
      <TextField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.email`}
        label="Email"
        sizes={{ xs: 6 }}
        size={'small'}
      />
      <FormPhoneField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.phoneData`}
        label="Phone #"
        sizes={{ xs: 12 }}
        size={'small'}
      />
      <TextField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.description`}
        label="Description"
        sizes={{ xs: 12 }}
        size={'small'}
      />
      <MultipleAutocompleteForm
        control={control}
        name={`contacts.${id}.alerts`}
        selectedValues={notificationsOption.filter((notification) =>
          alerts?.includes(notification.name)
        )}
        label="Load Notifications"
        options={notificationsOption}
        fieldName="value"
        variant="standard"
        onChangeCb={handleNotificationChange}
        sizes={{ xs: 12 }}
      />
      <Checkbox
        name={`contacts.${id}.sendPhoneAlert`}
        control={control}
        // onChangeCb={handleBillToCheckChange}
        labelPlacement={'end'}
        label={'Notify via Phone'}
      />
      <Checkbox
        // id={addLoadPageStopInfo + 'preLoaded'}
        name={`contacts.${id}.sendEmailAlert`}
        control={control}
        // onChangeCb={handleBillToCheckChange}
        labelPlacement={'end'}
        label={'Notify via Email'}
      />
    </>
  );
}
