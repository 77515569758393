export const puckContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px',
  borderRadius: '4px',
  gap: '5px',
  margin: '0',
  width: '100%',
};

export const puckInnerContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
};
export const originDestinationTextStyles = {
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  display: 'flex',
  letterSpacing: '0.15px',
};
export const outerTimerStyles = {
  paddingBottom: 0,
  color: '#000',
  fontWeight: 'bold',
  letterSpacing: '0.4px',
};

export const glowContainerStyles = {
  display: 'flex',
  border: '3px dashed #3B873E',
  borderRadius: '16px',
  animation: 'border-pulsate 2s infinite',
  '@keyframes border-pulsate': {
    '0%': { borderColor: 'rgba(60, 179, 113, 1)' },
    '50%': { borderColor: 'rgba(0, 255, 255, 0)' },
    '100%': { borderColor: 'rgba(60, 179, 113, 1)' },
  },
};

export const appointmentStyles = {
  paddingBottom: 0,
  fontSize: '10px',
};

//  NEW PUCK STYLES

export const defaultContainerStyles = {
  width: '100%',
  height: '22px',
  display: 'flex',
  background: '#F76E64',
  borderRadius: '8px',
};

export const defaultPuckStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0 10px',
  // borderRadius: '6px',
};

export const defaultContentStyles = {
  fontSize: '13px',
  color: '#FFFFFF',
  letterSpacing: '0.15px',
};

export const defaultDottedPuckStyles = {
  border: '1px solid #2B64CB',
};

export const defaultStatusContainerStyles = {
  border: '2px solid #FFFFFF',
  borderRadius: '16px',
  height: 'auto',
  padding: '3px 7px',
  alignItems: 'center',
  fontSize: '13px',
  display: 'flex',
  maxWidth: '136px',
  'text-align': 'center',
  width: 'max-content',
  gap: '3px',
};

export const tooltipTripKeyContainerStyles = {
  maxWidth: '45px',
  width: '-webkit-fill-available',
};

export const hosOutDatedTextStyles = {
  lineHeight: '140%',
  color: '#C8372C',
  fontSize: '10px',
  fontWeight: 700,
};

export const VerticalLineStyles = {
  border: '1px solid rgba(0, 17, 34, 0.12)',
  height: '25px',
  margin: '0 3px',
};
export const SelectTripPuckStyles = {
  padding: '4px 8px',
  background: '#FFFFFF',
  border: '1px solid #6B92DB',
  borderRadius: '8px',
  alignItems: 'center',
  height: '29px',
};

export const SelectTripTextStyles = {
  'text-transform': 'capitalize',
  color: 'rgba(4, 0, 34, 0.75)',
  lineHeight: '160%',
  fontSize: '13px',
  fontWeight: 500,
  fontFamily: 'Poppins',
};
export const SelectTripIconStyles = {
  color: 'rgba(4, 0, 34, 0.75)',
  width: '18px',
};

export const SelectTripButtonStyles = {
  ml: 'auto',
  height: '24px',
  fontSize: '13px',
  textTransform: 'capitalize',
  fontFamily: 'Poppins',
  cursor: 'pointer',
  lineHeight: '160%',
};

export const SelectTripIconButtonStyles = {
  background: '#2B64CB',
  color: '#FFF',
  borderRadius: '4px',
  height: '24px',
  ml: 'auto',
  ':hover': {
    backgroundColor: '#3a7cf1!important',
    cursor: 'pointer',
  },
};
