import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonImproved from '@/ui-kit/components/ButtonImproved';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import IconButton from '@mui/material/IconButton';
import { EntityNoteItem } from '@/models';
import { FC } from 'react';
import moment from 'moment';
import { useNoteItem } from '@/common/EntityNotes/hooks';
import { deletePopOver } from '@/common/LoadTabPanel/tabs/Notes/constants/constant';
import DeletePopup from '@/ui-kit/components/DeletePopup';
import { NoteForm } from '@/common/EntityNotes/components/NoteForm';
import { NoteEntityType } from '@/common/EntityNotes/utils/types';
import dayjs from 'dayjs';

interface Props {
  note: EntityNoteItem;
  getEntityNotes: () => void;
  entityId: string;
  entityType: NoteEntityType;
}

export const NoteItem: FC<Props> = ({
  note,
  getEntityNotes,
  entityType,
  entityId,
}) => {
  const {
    hasEditNotePermission,
    hasRemoveNotePermission,
    handleNoteDelete,
    showDeletePopup,
    handleShowDeletePopupChange,
    handleEditModeChange,
    editMode,
    handleShowActionsChange,
    showActions,
    handleCancel,
    handleFileView,
    file,
  } = useNoteItem(getEntityNotes, note, entityType);

  if (editMode) {
    return (
      <NoteForm
        getEntityNotes={getEntityNotes}
        entityType={entityType}
        entityId={entityId}
        defaultValues={{
          note: note.note,
          attachment: note.attachments,
          id: note.id,
        }}
        onCancel={handleCancel}
        file={file}
      />
    );
  }

  return (
    <Stack
      sx={{ padding: 1, borderRadius: 4, backgroundColor: '#FEF7EB', mt: 1 }}
      direction="column"
      spacing={1}
      onMouseEnter={handleShowActionsChange(true)}
      onMouseLeave={handleShowActionsChange(false)}
    >
      <Stack
        direction="row"
        width={'100%'}
        justifyContent={'space-between'}
        spacing={2}
        alignItems={'flex-start'}
      >
        <Typography variant="body2" fontWeight={500}>
          {note.note}
        </Typography>
        <Box>
          {!showActions ? (
            <StickyNote2OutlinedIcon
              fontSize="small"
              sx={{ color: 'primary.main' }}
            />
          ) : (
            <Stack direction="row" spacing={1}>
              {note.editable && hasEditNotePermission && (
                <IconButton onClick={handleEditModeChange(true)}>
                  <EditOutlinedIcon
                    fontSize="small"
                    sx={{ color: 'primary.main' }}
                  />
                </IconButton>
              )}
              {note.deletable && hasRemoveNotePermission && (
                <IconButton onClick={handleShowDeletePopupChange(true)}>
                  <DeleteOutlineOutlinedIcon
                    fontSize="small"
                    sx={{ color: 'primary.main' }}
                  />
                </IconButton>
              )}
            </Stack>
          )}
        </Box>
      </Stack>
      <Box sx={{ alignItems: 'flex-start' }}>
        {note.attachments?.[0] && (
          <ButtonImproved
            size="medium"
            variant="text"
            disabled={false}
            label={note.attachments[0].fileName}
            startIcon={<InsertPhotoIcon />}
            styleProps={{ color: 'text.primary' }}
            onClick={handleFileView(note.attachments[0].documentId)}
          />
        )}
      </Box>
      <Stack
        direction="row"
        width={'100%'}
        justifyContent={'space-between'}
        spacing={1}
      >
        <Stack direction="column">
          <Typography sx={{ color: 'text.primary', fontSize: 12 }}>
            {note.creatorName} {`(${note.roleCode})`}
          </Typography>
          <Typography sx={{ color: 'text.secondary', fontSize: 10 }}>
            {dayjs(note.updateDate).format('MMMM D, YYYY h:mm A')}
          </Typography>
        </Stack>
      </Stack>
      <DeletePopup
        open={showDeletePopup}
        onAction={handleNoteDelete}
        onClose={handleShowDeletePopupChange(false)}
        title={deletePopOver.title}
        body={deletePopOver.body}
      />
    </Stack>
  );
};
