import React from 'react';

const LoadDelayTooltipIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="#FFBB58" />
      <path
        d="M10.4513 9.19315C10.7363 9.47815 10.8788 9.87398 10.8788 10.3886C10.8788 10.9032 10.7363 11.299 10.4513 11.5761C10.1663 11.8532 9.7625 11.9957 9.25584 11.9957H8.61459V8.76565H9.25584C9.7625 8.76565 10.1663 8.90815 10.4513 9.19315ZM17.9167 6.04232V14.7507C17.9167 15.6215 17.2042 16.334 16.3333 16.334H3.66667C2.79584 16.334 2.08334 15.6215 2.08334 14.7507V6.04232C2.08334 5.17148 2.79584 4.45898 3.66667 4.45898H16.3333C17.2042 4.45898 17.9167 5.17148 17.9167 6.04232ZM4.47417 7.4119L4.6325 11.204H5.78042L5.93875 7.4119H4.47417ZM6.02584 12.5023C6.02584 12.2886 5.94667 12.1144 5.79625 11.9719C5.64584 11.8294 5.45584 11.7582 5.22625 11.7582C4.98875 11.7582 4.79084 11.8294 4.64042 11.9719C4.49 12.1144 4.41875 12.2886 4.41875 12.5023C4.41875 12.7082 4.49 12.8823 4.64042 13.0248C4.79084 13.1594 4.98875 13.2307 5.22625 13.2307C5.45584 13.2307 5.64584 13.1594 5.79625 13.0248C5.94667 12.8823 6.02584 12.7082 6.02584 12.5023ZM12.2563 10.3886C12.2563 9.84232 12.1375 9.3594 11.8921 8.93982C11.6546 8.52023 11.3142 8.18773 10.8788 7.95815C10.4433 7.72857 9.92875 7.60982 9.34292 7.60982H7.26084V13.1673H9.34292C9.92084 13.1673 10.4354 13.0486 10.8708 12.819C11.3142 12.5894 11.6546 12.2569 11.8921 11.8373C12.1296 11.4177 12.2563 10.9348 12.2563 10.3886ZM16.1354 12.1223H14.3621V7.60982H13.0083V13.1673H16.1354V12.1223Z"
        fill="white"
      />
    </svg>
  );
};

export default LoadDelayTooltipIcon;
