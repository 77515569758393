import { Box, Typography } from '@mui/material';
import { LoadDelayTooltipIcon } from '../static';
import {
  footerTextStyles,
  footerShowMoreTextStyles,
  footerHyperLinkStyles,
} from '../styles';
import { IWarnings } from '../types';
import React, { useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { GANTT_TOOLTIP_POPUP_ID } from '../config';

const WarningsTemplate: React.FC<IWarnings> = (props) => {
  const [expandFooterContent, setExpandFooterContent] =
    useState<boolean>(false);

  const updateTooltipPopupHeight = () => {
    if (props?.disableAutoHeightSync) return;
    const tooltipDomPopupEl = document.getElementById(GANTT_TOOLTIP_POPUP_ID);
    if (!tooltipDomPopupEl) return;
    const tooltipDomEl = tooltipDomPopupEl.querySelector(
      '.MuiPopover-paper'
    ) as HTMLElement;
    if (!tooltipDomEl) return;
    const topDifferenceInPx = Number(
      tooltipDomEl?.style?.top?.replace('px', '')
    );
    const maxHeight =
      tooltipDomEl?.offsetHeight +
      (window.innerHeight - topDifferenceInPx - tooltipDomEl?.offsetHeight) -
      10;
    if (isNaN(maxHeight)) return;
    tooltipDomEl.style.maxHeight = maxHeight + 'px';
  };

  if (!props?.element && !props?.content?.length) return <></>;
  if (!expandFooterContent) {
    const contentLength = props?.content?.length as number;
    const splicedContent = props?.content?.slice(0, 3);
    return (
      <>
        {splicedContent?.map((content) => (
          <Box
            key={content.text}
            id={content.text}
            display="flex"
            alignItems={'center'}
            marginBottom={'8px'}
            gap="3px"
            sx={content?.containerStyles}
          >
            <Box width={'20px'} sx={content.iconContainerStyles}>
              {content.icon || <LoadDelayTooltipIcon />}
            </Box>
            <Typography style={{ ...footerTextStyles, ...content.textStyles }}>
              {content.text}
            </Typography>
            {content?.hyperlink && (
              <Typography
                onClick={() => props?.onWarningAction?.(content)}
                component={'a'}
                sx={{ ...footerHyperLinkStyles, ...content.hyperlinkStyles }}
              >
                {content.hyperlink}
              </Typography>
            )}
          </Box>
        ))}
        {contentLength > 3 && (
          <Typography
            component={'a'}
            style={footerShowMoreTextStyles}
            onClick={() => {
              setExpandFooterContent((prev) => !prev);
              updateTooltipPopupHeight();
            }}
          >
            Show More{' '}
            <KeyboardArrowDownOutlinedIcon style={{ fontSize: '14px' }} />
          </Typography>
        )}
      </>
    );
  }
  return (
    <>
      {props?.content?.map((content) => (
        <Box
          key={content.text}
          id={content.text}
          display="flex"
          alignItems={'center'}
          marginBottom={'8px'}
          gap="3px"
          sx={content.containerStyles}
        >
          <Box width={'20px'} sx={content.iconContainerStyles}>
            {content.icon || <LoadDelayTooltipIcon />}
          </Box>
          <Typography style={{ ...footerTextStyles, ...content.textStyles }}>
            {content.text}
          </Typography>
          {content?.hyperlink && (
            <Typography
              onClick={() => props?.onWarningAction?.(content)}
              component={'a'}
              sx={{
                ...footerHyperLinkStyles,
                ...content.hyperlinkStyles,
              }}
            >
              {content.hyperlink}
            </Typography>
          )}
        </Box>
      ))}
      <Typography
        component={'a'}
        style={footerShowMoreTextStyles}
        onClick={() => {
          setExpandFooterContent((prev) => !prev);
        }}
      >
        Show Less <KeyboardArrowUpOutlinedIcon style={{ fontSize: '14px' }} />
      </Typography>
    </>
  );
};

export default WarningsTemplate;
