import { BryntumScheduler } from '@bryntum/schedulerpro-react';
import { Tooltip } from '@bryntum/schedulerpro';
import { Box, Checkbox, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import '../css/index.css';
import '@bryntum/schedulerpro/schedulerpro.classic.css';
import {
  ToolTip,
  TooltipPopup,
  UnAssignedResourceDetail,
  GanttLoader,
} from '../atoms';
import {
  checkBoxEvent,
  defaultHourAndDayPresets,
  timeoffToolTipBody,
} from './ganttchart.seeds';
import { IGanttchartProps, IPuckTooltipPropsOnClick } from './types';
import {
  disabledDragDropEvents,
  puckContainerStyles,
  eventMenuItems,
  resourceInfoTooltip,
  schedulerConfigFeatures,
  timeRangeStyle,
  GanttControlsDefaultConfig,
} from './ganttchart.config';
import {
  eventBodyTemplate,
  findResourceIndexFromStore,
  getToolTipProps,
  handleEventRender,
  ifDatesCollapse,
  isAllowedForAction,
  isAllowedToDragAndResize,
  isAllowedToEditEvent,
  isTooltipAllowed,
  resourceIdExists,
  validateEventDropAndResize,
} from './services/ganttchart.service';
import {
  DOWNVOTE_PUCK_ID,
  EVENT_STATUS,
  EVENT_TYPES,
  resourceInfoHeaderClass,
  unassignedDriverRowBackground,
} from '../constants/puck.constant';
import { DEFAULT_SCROLL_VALUE } from '../constants/gantt.constant';
import {
  GanttActions,
  PuckTooltip,
  ResourceColumn,
  Legend,
} from '../components';
import {
  GanttPuckType,
  NavigateToTypes,
  TooltipFooterContentType,
  ZoomChangeTypes,
} from '../types';
import { PUCK_HOVER_VISIBILITY_CLASSNAME, TOOLTIP_TYPES } from '../config';
import { GanttBottomBarStyles } from '../styles';

const Chart = React.forwardRef((props: IGanttchartProps, scheduler?: any) => {
  const {
    height = '100%',
    showCurrentTimeLine = true,
    unassignedEvents,
    newRecord,
    rowReorderFeature = true,
    rowHeight = 100,
    zoomOnMouseWheel = true,
    allowDragAndResize = true,
    onEventDrop,
    onEventClick,
    onBeforeEventRender,
    eventTemplate,
    disableTooltip = false,
    toolTipTemplate,
    resourceInfoTemplate,
    columns,
    unassignedResourceTemplate,
    onCellClick,
    onUnassignedEventAdd,
    onEventDragOrResize,
    readOnly = true,
    allowColumnResize = true,
    allowColumnSorting = false,
    infiniteScroll = true,
    tooltipHoverDelay = 300,
    ganttVisibleDate,
    disableEventDragDropStatus = disabledDragDropEvents,
    presets = defaultHourAndDayPresets,
    viewPreset = 'hourAndDay',
    mapUnassignedEventToResource = false,
    unassignedRowBackgroundColor = unassignedDriverRowBackground,
    onScroll,
    removeAddEventAfterAssign,
    showEventSelectedTimeline,
    resourceInfoHeaderCls = resourceInfoHeaderClass,
    showResourceTimerangeTooltip = false,
    processEventMenuItems,
    eventMenuOptions,
    columnTooltipContent,
    columnTooltipRenderer,
    resourceTimeRangeTooltip,
    resourceTimerangeTooltipContent,
    eventTooltipContent,
    startDate,
    endDate,
    columnWidth = 250,
    onDownvote,
    onHorizontalScroll,
    showCellTooltip = true,
    forceFit = false,
    legend,
    eventTooltipListeners,
    eventTooltipAlignment = 't-b',
    allowEventContentResponsiveness = true,
    resetCurrentGap = false,
    onResetCurrentGap,
    triggerEventMenu,
    disableEventMenu = true,
    eventSelectionDisabled = true,
    eventTooltipConfig = {
      hideOnDelegateChange: true,
      hideDelay: 0,
    },
    timeRangeTooltipConfig = { hideOnDelegateChange: true, hideDelay: 0 },
    scrollTo = {},
    eventContainerStyles = puckContainerStyles,
    onGanttReady,
    windowSize,
    onNavigation,
    onZoomChange,
    onReset,
    onAfterNavigation,
    onAfterZoomChange,
    ganttBottomBarStyles,
    fontFamily = 'Poppins',
    puckHeight = '28px',
    showTooltipOnClick = false,
    puckTooltipTemplateOnClick,
    puckTooltipPropsOnClick,
    columnTooltipProps,
    allowColumnCheckbox = false,
    additionalColumns = [],
    customFeatures = {},
    showColumnTooltipOnClick = false,
    closeTooltipOnAction = true,
    ganttControlsConfig = {},
    loader,
    showActionBar = true,
    listeners,
    hideColumns = [],
    allowGridResizeByClick = false,
    ...defaultProps
  } = props;

  // const scheduler = useRef<any>({});
  const [currentAddEvent, setCurrentAddEvent] = useState(null as any);
  const [unassignedTasks, setUnassignedTasks] = useState([] as any);
  const [showUnassignedLoads, setShowUnassignedLoads] =
    useState<boolean>(false);
  const [currentPuckTooltipEl, setCurrentPuckTooltipEl] =
    useState<GanttPuckType | null>();
  const [disableZoom, setDisableZoom] = useState<{
    in: boolean;
    out: boolean;
  }>({
    in: ganttControlsConfig?.disableZoomIn ?? false,
    out: ganttControlsConfig.disableZoomOut ?? false,
  });

  if (defaultProps.resourceTimeRanges) {
    defaultProps?.resourceTimeRanges?.map((timeRange: any) => {
      timeRange.style = timeRange.style || timeRangeStyle;
    });
  }

  useEffect(() => {
    if (
      scheduler?.current?.instance &&
      newRecord?.resource &&
      newRecord?.event
    ) {
      addNewResource(newRecord?.resource, newRecord?.position || 0);
      addNewEvent(newRecord?.event);
    }
  }, [newRecord]);

  useEffect(() => {
    if (unassignedTasks?.length && currentAddEvent?.event) {
      addUnassignedLoads(currentAddEvent);
    }
  }, [unassignedTasks, currentAddEvent]);

  useEffect(() => {
    removeUnassignedEventsAndResources();
    setUnassignedTasks(unassignedEvents);
  }, [unassignedEvents]);

  useEffect(() => {
    if (scheduler?.current?.instance) {
      rescheduleTimeline(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (scheduler?.current?.instance && ganttVisibleDate) {
      scheduler.current.instance.visibleDate = {
        date: new Date(ganttVisibleDate),
        block: 'start',
      };
    }
  }, [ganttVisibleDate]);

  useEffect(() => {
    if (
      scheduler?.current?.instance?.features?.timeRanges &&
      showEventSelectedTimeline === false
    ) {
      removeTimeRangeStore('selectedTime');
    }
  }, [showEventSelectedTimeline]);

  useEffect(() => {
    if (scheduler?.current?.instance && showResourceTimerangeTooltip) {
      enableResourceTimRangeTooltip();
    }
  }, [showResourceTimerangeTooltip]);

  useEffect(() => {
    if (
      resetCurrentGap &&
      currentAddEvent?.eventElement?.children[0]?.childNodes?.length
    ) {
      currentAddEvent.eventElement.children[0].childNodes[0].classList.add(
        'event-container-visibility'
      );
      setCurrentAddEvent(null);
      onResetCurrentGap && onResetCurrentGap();
    }
  }, [resetCurrentGap, currentAddEvent]);

  useEffect(() => {
    if (scheduler?.current?.instance && scrollTo?.direction) {
      const scrollBy = scrollTo?.value ?? DEFAULT_SCROLL_VALUE;
      switch (scrollTo.direction) {
        case 'left':
          scheduler.current.instance.scrollHorizontallyTo(
            scheduler.current?.instance.subGrids.normal.scrollable.x - scrollBy,
            true
          );
          // scheduler.current.instance.timeAxisSubGrid.scrollable.x -= scrollBy;
          break;
        case 'right':
          // scheduler.current.instance.timeAxisSubGrid.scrollable.x += scrollBy;
          scheduler.current.instance.scrollHorizontallyTo(
            scheduler.current?.instance.subGrids.normal.scrollable.x + scrollBy,
            true
          );
          break;
      }
    }
  }, [scrollTo.direction, scrollTo.value]);

  useEffect(() => {
    // const schedulerInstance = scheduler?.current?.schedulerProInstance;
    const schedulerInstance = scheduler?.current?.schedulerInstance;
    if (schedulerInstance) {
      onGanttReady?.(schedulerInstance);
    }
  }, [scheduler?.current?.schedulerInstance]);

  const enableResourceTimRangeTooltip = async () => {
    new Tooltip({
      anchorToTarget: true,
      hoverDelay: tooltipHoverDelay,
      align: 't-b',
      ...timeRangeTooltipConfig,
      forSelector: '.b-sch-resourcetimerange',
      getHtml: ({ activeTarget, ...rest }: any) =>
        renderToStaticMarkup(
          resourceTimeRangeTooltip ? (
            resourceTimeRangeTooltip({ activeTarget, ...rest })
          ) : (
            <ToolTip
              {...timeoffToolTipBody(
                activeTarget?.elementData?.eventRecord?.data,
                resourceTimerangeTooltipContent &&
                  resourceTimerangeTooltipContent({
                    event: activeTarget?.elementData?.eventRecord?.data,
                    resource: activeTarget?.elementData?.resourceRecord?.data,
                    elementData: { activeTarget, ...rest },
                  })
              )}
            />
          )
        ),
    });
  };

  const disableGantt = () => {
    if (!scheduler?.current?.instance) return;
    scheduler.current.instance.infiniteScroll = false;
    scheduler.current.instance.readOnly = true;
  };

  const enableGantt = () => {
    if (!scheduler?.current?.instance) return;
    scheduler.current.instance.readOnly = readOnly;
    scheduler.current.instance.infiniteScroll = infiniteScroll;
  };

  const removeEventsByResourceId = (resourceId: string) => {
    const eventStore = scheduler?.current?.instance.eventStore;
    const eventsToBeRemoved = eventStore.records.filter(
      (resource: any) => resource?.data?.resourceId === resourceId
    );
    eventStore?.remove(eventsToBeRemoved);
  };

  const removeResource = (resourceId: string) => {
    const resourcesToBeRemoved =
      scheduler?.current?.instance.resourceStore.records.filter(
        (resource: any) => resource?.data?.id === resourceId
      );
    scheduler?.current?.instance?.resourceStore?.remove(resourcesToBeRemoved);
  };

  const removeUnassignedEventsAndResources = () => {
    unassignedTasks?.forEach((load: any) => {
      removeEventsByResourceId(load?.resourceId);
      removeResource(load?.resourceId);
    });
    enableGantt();
  };

  const deleteEventById = (id: string) => {
    if (!scheduler?.current?.instance) return;
    const record = scheduler.current?.instance?.eventStore?.records?.find(
      (e: any) => e?.data?.id === id
    );
    if (record) scheduler.current?.instance?.eventStore?.remove(record);
  };

  const addNewEvent = (events: any) => {
    if (!scheduler?.current?.instance) return;
    scheduler?.current?.instance?.eventStore?.add(events);
  };

  const rescheduleTimeline = (
    startDate: Date | string | undefined,
    endDate: Date | string | undefined
  ) => {
    if (!scheduler?.current?.instance) return;
    if (!startDate) return;
    scheduler.current.instance.startDate = new Date(startDate);
    if (!endDate) return;
    scheduler.current.instance.endDate = new Date(endDate);
  };

  const addNewResource = (resource: any, pos = 0) => {
    if (!scheduler?.current?.instance) return;
    scheduler?.current?.instance?.resourceStore?.insert(pos, resource);
  };

  const addUnassignedLoads = (event: any) => {
    const resourceToAdd = event?.resourceRecord?.data;
    const newResources: any[] = [];
    const checkBoxEvents = [] as any;
    const resourceIndex = findResourceIndexFromStore(
      event?.source?.resourceStore?.records,
      resourceToAdd?.id
    );
    const eventsToAdd = unassignedTasks;
    eventsToAdd?.map((load: any, i: number) => {
      const duplicateResource = JSON.parse(
        JSON.stringify({
          resourceToAdd,
          id: `${resourceToAdd?.id}-nr${i}`,
          name: load?.name,
          status: EVENT_STATUS.AVAILABLE,
          resourceId: `${resourceToAdd?.id}-nr${i}`,
        })
      );
      const checkBox = JSON.parse(
        JSON.stringify({
          ...checkBoxEvent,
          id: `${checkBoxEvent.id}-${i}`,
          resourceId: `${resourceToAdd?.id}-nr${i}`,
          startDate: new Date(
            new Date(event?.eventRecord?.data?.endDate).getTime() +
              1.2 * 24 * 60 * 60 * 1000
          ),
          endDate: new Date(
            new Date(event?.eventRecord?.data?.endDate).getTime() +
              1.4 * 24 * 60 * 60 * 1000
          ),
        })
      );
      newResources?.push(duplicateResource);
      checkBoxEvents?.push(checkBox);
      load.resourceId = `${resourceToAdd?.id}-nr${i}`;
    });

    addNewResource(newResources, Number(resourceIndex || 0) + 1);
    addNewEvent(eventsToAdd);
    addNewEvent(checkBoxEvents);
    disableGantt();
  };

  const removeTimeRangeStore = (timeRange: any) => {
    scheduler.current?.instance?.features?.timeRanges?.store?.remove(timeRange);
  };

  const showPuckTooltip = (event: GanttPuckType) => {
    if (
      event?.eventRecord?.data?.type === EVENT_TYPES.GAP ||
      event?.eventRecord?.data?.type === EVENT_TYPES.EMPTY
    ) {
      event?.eventElement?.children[0]?.children[0]?.classList?.remove?.(
        PUCK_HOVER_VISIBILITY_CLASSNAME
      );
    }
    setCurrentPuckTooltipEl(event);
  };

  const handleEventClick = (event: GanttPuckType) => {
    setCurrentPuckTooltipEl(null);
    if (showTooltipOnClick && !event?.eventRecord?.data?.isTripSelect) {
      showPuckTooltip(event);
    }
    handleEventSelectedTimeline(event);
    if (
      event?.eventRecord?.data?.status === EVENT_TYPES.CHECKBOX ||
      event?.eventRecord?.data?.type === EVENT_TYPES.CHECKBOX
    ) {
      return addSelectedCheckBoxEvent(event);
    }
    if (
      event.event.target.id === DOWNVOTE_PUCK_ID ||
      event.event.target.parentNode.id === DOWNVOTE_PUCK_ID ||
      event.event.target.parentNode.parentNode.id === DOWNVOTE_PUCK_ID
    ) {
      return onDownvote && onDownvote(event.eventRecord.data);
    }
    if (showUnassignedLoads && unassignedTasks?.length) {
      if (
        event?.eventRecord?.data?.id ===
          currentAddEvent?.eventRecord?.data?.id ||
        resourceIdExists(unassignedTasks, event?.resourceRecord?.data?.id)
      ) {
        return;
      }
      removeUnassignedEventsAndResources();
      setShowUnassignedLoads(false);
      setCurrentAddEvent(null);
      setUnassignedTasks([]);
    } else if (
      isAllowedForAction(event?.eventRecord?.data?.type) &&
      event?.eventRecord?.data?.showPuckOnHover
    ) {
      setCurrentAddEvent(event);
      // event.eventElement.children[0].childNodes[0].style.visibility = "visible";
      event.eventElement.children[0].childNodes[0].classList.remove(
        'event-container-visibility'
      );
      setShowUnassignedLoads(true);
    }
    onEventClick && onEventClick(event);
  };
  const addSelectedCheckBoxEvent = (event: any) => {
    const eventToAdd = scheduler.current?.instance?.eventStore?.records?.find(
      (e: any) =>
        e?.data?.resourceId === event?.eventRecord?.data?.resourceId &&
        (e?.data?.status === EVENT_STATUS.AVAILABLE ||
          e?.data?.type === EVENT_STATUS.AVAILABLE)
    );
    if (!eventToAdd?.data)
      return onUnassignedEventAdd && onUnassignedEventAdd(eventToAdd?.data);
    const record = JSON.parse(JSON.stringify(eventToAdd?.data));
    if (removeAddEventAfterAssign)
      deleteEventById(currentAddEvent?.eventRecord?.data.id);
    record.gapId = currentAddEvent?.eventRecord?.data?.id;
    record.resourceId = currentAddEvent?.eventRecord?.data.resourceId;
    if (mapUnassignedEventToResource) {
      record.status = EVENT_STATUS.PLANNED;
      addNewEvent(record);
    }
    onUnassignedEventAdd && onUnassignedEventAdd(record);
  };

  const handleCellClick = (data: any) => {
    if (
      showUnassignedLoads &&
      !resourceIdExists(unassignedTasks, data?.record?.data?.id)
    ) {
      if (currentAddEvent?.eventElement?.children[0]?.childNodes?.length) {
        currentAddEvent.eventElement.children[0].childNodes[0].classList.add(
          'event-container-visibility'
        );
      }
      setCurrentAddEvent(null);
      removeUnassignedEventsAndResources();
      setShowUnassignedLoads(false);
      onCellClick && onCellClick(data);
    }
    if (!showUnassignedLoads) onCellClick && onCellClick(data);
  };

  const getAllResourceEvents = () =>
    scheduler?.current?.instance?.eventStore?.records;

  const getEventsByResourceId = (resourceId: string) =>
    getAllResourceEvents()?.filter((e: any) => e.resourceId === resourceId);

  const handleOnEventAfterDrop = (data: any) => {
    if (
      validateEventDropAndResize({
        newResource: data?.context?.newResource,
        startDate: data?.context?.startDate,
        endDate: data?.context?.endDate,
      })
    ) {
      getEventsByResourceId(data?.resourceRecord?.data?.id).map(
        (event: any) => {
          if (
            ifDatesCollapse(data?.context, event?.data) ||
            event?.startDate > data?.context?.startDate
          ) {
            if (
              data.context.timeDiff > 0 &&
              data?.eventRecords[0]?.data?.id !== event?.data?.id
            ) {
              event.startDate = new Date(
                new Date(event.startDate).getTime() + data.context.timeDiff
              );
              event.endDate = new Date(
                new Date(event.endDate).getTime() + data.context.timeDiff
              );
            }
          }
        }
      );
    }
    onEventDragOrResize && onEventDragOrResize(data);
  };

  const handleEventSelectedTimeline = (event: any) => {
    if (!scheduler?.current?.instance) return;
    if (showEventSelectedTimeline) {
      const selectedTimeline = getDateFromCoords(event.event.x, event?.event.y);
      const hours = new Date(selectedTimeline).getHours();
      const min = new Date(selectedTimeline).getMinutes();
      scheduler.current.instance.features.timeRanges.store?.add({
        id: 'selectedTime',
        name: `${hours < 10 ? 0 : ''}${hours}:${min < 10 ? 0 : ''}${min}`,
        startDate: selectedTimeline,
      });
    }
  };
  const getDateFromCoords = (x: number, y: number) =>
    scheduler?.current?.instance?.getDateFromXY?.([x, y], '', false);
  const defaultGanttColumns = useMemo(
    () => [
      {
        id: 1,
        type: 'resourceInfo',
        editor: false,
        width: columnWidth,
        headerRenderer: (data: any) => {
          data.headerElement.classList.add(`${resourceInfoHeaderCls}`);
        },
        renderer: (event: any) => {
          if (event?.record?.data?.status === EVENT_STATUS.AVAILABLE) {
            event?.row.eachElement(
              (el: any) => (el.style.background = unassignedRowBackgroundColor)
            );
            return unassignedResourceTemplate ? (
              unassignedResourceTemplate(event)
            ) : (
              <UnAssignedResourceDetail
                name={event?.record?.data?.name}
                textStyles={{ fontFamily }}
              />
            );
          }
          const { data } = event?.record;
          return resourceInfoTemplate ? (
            resourceInfoTemplate(event)
          ) : (
            <ResourceColumn
              {...data}
              isCheckBox={data?.isCheckBox ?? allowColumnCheckbox}
              columnDetails={
                data?.columnDetails || [
                  {
                    text: data?.name,
                    textStyles: { fontFamily, ...data?.textStyles },
                  },
                  {
                    text: data.origin?.city,
                    textStyles: { fontFamily, ...data?.textStyles },
                  },
                ]
              }
              showColumnTooltipOnClick={showColumnTooltipOnClick}
              columnTooltipProps={columnTooltipProps?.(event?.record?.data)}
            />
          );
        },
      },
      ...additionalColumns,
    ],
    [
      resourceInfoHeaderCls,
      unassignedRowBackgroundColor,
      resourceInfoTemplate,
      unassignedResourceTemplate,
      columnWidth,
    ]
  );

  const handleGanttNavigation = (navTo: NavigateToTypes) => {
    if (!scheduler?.current?.instance) return;
    if (ganttControlsConfig.allowDefaultNavigationControl === false)
      return onNavigation?.(navTo);
    const startDate = scheduler?.current?.instance?.startDate;
    const endDate = scheduler?.current?.instance?.endDate;
    if (!startDate || !endDate) return;
    const defaultWindowSize: number =
      windowSize ?? new Date(endDate).getTime() - new Date(startDate).getTime();
    if (navTo === 'LEFT') {
      rescheduleTimeline(
        new Date(new Date(startDate).getTime?.() - defaultWindowSize),
        new Date(new Date(endDate)?.getTime?.() - defaultWindowSize)
      );
    } else
      rescheduleTimeline(
        new Date(new Date(startDate).getTime?.() + defaultWindowSize),
        new Date(new Date(endDate)?.getTime?.() + defaultWindowSize)
      );
    onAfterNavigation?.({ navTo, startDate, endDate });
  };

  const handleZoomChange = (zoomType: ZoomChangeTypes) => {
    if (!scheduler?.current?.instance) return;
    if (ganttControlsConfig.allowDefaultZoomControl === false)
      return onZoomChange?.(zoomType);
    if (zoomType === 'IN') scheduler.current.instance.zoomLevel += 1;
    else scheduler.current.instance.zoomLevel -= 1;
    onAfterZoomChange?.({
      zoomType,
      zoomLevel: scheduler?.current?.instance?.zoomLevel,
      viewPreset: scheduler?.current?.instance?.viewPreset?.data,
    });
    handleDisableZoom(scheduler?.current?.instance?.zoomLevel);
  };

  const handleDisableZoom = (currentZoomLevel: number) => {
    if (currentZoomLevel === 0) {
      return setDisableZoom({
        in: ganttControlsConfig.disableZoomIn ?? false,
        out: ganttControlsConfig?.disableZoomOut ?? true,
      });
    }
    if (currentZoomLevel === (presets as Array<any>)?.length - 1) {
      return setDisableZoom({
        in: ganttControlsConfig.disableZoomIn ?? true,
        out: ganttControlsConfig?.disableZoomOut ?? false,
      });
    }
    setDisableZoom({
      in: ganttControlsConfig.disableZoomIn ?? false,
      out: ganttControlsConfig?.disableZoomOut ?? false,
    });
  };

  const handlePuckTooltipClose = () => {
    if (
      currentPuckTooltipEl?.eventRecord?.data?.type === EVENT_TYPES.GAP ||
      currentPuckTooltipEl?.eventRecord?.data?.type === EVENT_TYPES.EMPTY
    ) {
      currentPuckTooltipEl?.eventElement?.children[0]?.children[0]?.classList?.add?.(
        PUCK_HOVER_VISIBILITY_CLASSNAME
      );
    }
    setCurrentPuckTooltipEl(null);
  };

  const renderPuckTooltipOnClick = (
    currentPuckTooltipEl: GanttPuckType
  ): JSX.Element => {
    const tooltipProps = puckTooltipPropsOnClick?.(currentPuckTooltipEl);
    if (tooltipProps === false) return <></>;
    return (
      <TooltipPopup
        open={Boolean(currentPuckTooltipEl?.eventElement)}
        // anchorEl={currentPuckTooltipEl?.eventElement}
        anchorPosition={{
          left: currentPuckTooltipEl?.domEvent?.x,
          top: currentPuckTooltipEl?.domEvent?.y,
        }}
        anchorReference="anchorPosition"
        onClose={handlePuckTooltipClose}
        sx={(tooltipProps as IPuckTooltipPropsOnClick)?.popoverContainerStyles}
      >
        {puckTooltipTemplateOnClick?.(currentPuckTooltipEl) || (
          <PuckTooltip
            {...(tooltipProps as IPuckTooltipPropsOnClick)}
            onClose={handlePuckTooltipClose}
            onActionClick={(data: GanttPuckType) => {
              if (closeTooltipOnAction) handlePuckTooltipClose();
              (tooltipProps as IPuckTooltipPropsOnClick)?.onActionClick?.(data);
            }}
            onSubTitleClick={(data: GanttPuckType) => {
              if (closeTooltipOnAction) handlePuckTooltipClose();
              (tooltipProps as IPuckTooltipPropsOnClick)?.onSubTitleClick?.(
                data
              );
            }}
            onWarningAction={(content: TooltipFooterContentType) => {
              if (closeTooltipOnAction) handlePuckTooltipClose();
              (tooltipProps as IPuckTooltipPropsOnClick)?.onWarningAction?.(
                content
              );
            }}
          />
        )}
      </TooltipPopup>
    );
  };

  const handleTimeOffClick = (event: GanttPuckType) => {
    setCurrentPuckTooltipEl({
      ...event,
      eventElement: event.domEvent.target,
      elementType: TOOLTIP_TYPES['BREAK'],
    });
  };

  useEffect(() => {
    if (!Array.isArray(hideColumns) || !scheduler?.current?.instance) return;
    scheduler.current.instance.columns?.allRecords?.forEach?.((column) => {
      if (hideColumns?.includes(column?.data?.field)) column.hidden = true;
      else column.hidden = false;
    });
  }, [hideColumns]);

  return (
    <Box height={'100%'}>
      <BryntumScheduler
        ref={scheduler}
        {...(defaultProps as any)}
        forceFit={!infiniteScroll ? forceFit : false}
        eventSelectionDisabled={eventSelectionDisabled}
        onScroll={onScroll}
        infiniteScroll={infiniteScroll}
        zoomKeepsOriginalTimespan
        zoomOnTimeAxisDoubleClick={false}
        height={height}
        readOnly={readOnly}
        viewPreset={viewPreset}
        presets={presets}
        rowReorderFeature={rowReorderFeature}
        showOnHover
        onEventDrop={onEventDrop}
        rowHeight={rowHeight}
        zoomOnMouseWheel={zoomOnMouseWheel}
        onBeforeEventDrag={(event: any) =>
          allowDragAndResize
            ? isAllowedToDragAndResize(
                event?.eventRecord?.data?.status,
                disableEventDragDropStatus
              )
            : false
        }
        onBeforeEventResize={(event: any) =>
          allowDragAndResize
            ? isAllowedToDragAndResize(
                event?.eventRecord?.data?.status,
                disableEventDragDropStatus
              )
            : false
        }
        onBeforeEventEdit={(event: any) =>
          allowDragAndResize
            ? isAllowedToEditEvent(event?.eventRecord?.data?.status)
            : false
        }
        listeners={{
          eventClick(event: any) {
            handleEventClick(event);
          },
          beforeDragCreate() {
            return false;
          },
          horizontalScroll(event: any) {
            onHorizontalScroll?.(event);
          },
          resourceTimeRangeClick(event: GanttPuckType) {
            handleTimeOffClick(event);
          },
          subGridCollapse(data: any) {
            if (!scheduler?.current || !allowGridResizeByClick) return;
            if (data?.subGrid?.region === 'normal') {
              scheduler?.current?.instance?.columns?.grid
                ?.getSubGrid('normal')
                .expand?.();
              scheduler.current.instance.columns.grid.subGrids.locked.width =
                scheduler.current.instance.columns.grid.subGrids.locked?.maxWidth;
            } else if (data?.subGrid?.region === 'locked') {
              scheduler?.current?.instance?.columns?.grid
                ?.getSubGrid('locked')
                .expand?.();
              scheduler.current.instance.columns.grid.subGrids.locked.width =
                scheduler.current.instance.columns.grid.subGrids.locked
                  ?.minWidth ?? 0;
            }
          },
          ...listeners,
        }}
        hideOverlayScroller={false}
        onEventMouseOver={defaultProps.onEventMouseOver}
        onEventMouseOut={defaultProps.onEventMouseOut}
        onAfterEventDrop={handleOnEventAfterDrop}
        onEventResizeEnd={function (event: any) {
          const args = {
            context: {
              ...event?.eventRecord?.data,
              startDate: event?.eventRecord?.data?.startDate,
              endDate: event?.eventRecord?.data?.endDate,
              timeDiff:
                (new Date(event?.eventRecord?.endDate) as any) -
                (new Date(event?.eventRecord?.originalData?.endDate) as any),
            },
            eventRecords: [event?.eventRecord],
            resourceRecord: event?.resourceRecord,
          };
          handleOnEventAfterDrop(args);
        }}
        eventDragFeature={{
          constrainDragToResource: true,
          tooltipTemplate: () => '',
          validatorFn: ({ newResource, startDate, endDate }: any) =>
            validateEventDropAndResize({
              newResource,
              startDate,
              endDate,
            }),
        }}
        eventResizeFeature={{
          validatorFn: ({ resourceRecord, startDate, endDate }: any) =>
            validateEventDropAndResize({
              newResource: resourceRecord,
              startDate,
              endDate,
            }),
        }}
        scheduleContextFeature={false}
        scheduleTooltipFeature={false}
        scheduleMenuFeature={false}
        cellMenuFeature={false}
        createEventOnDblClick={false}
        onAfterEventSave={() => false}
        headerMenuFeature={false}
        features={{
          ...schedulerConfigFeatures,
          cellTooltip: {
            disabled: !showCellTooltip,
            hoverDelay: 300,
            tooltipRenderer: (data: any) =>
              columnTooltipRenderer ||
              renderToStaticMarkup(
                resourceInfoTooltip(
                  data,
                  columnTooltipContent && columnTooltipContent(data)
                )
              ),
          },
          eventMenu: {
            disabled: disableEventMenu,
            triggerEvent: triggerEventMenu,
            items: { ...eventMenuItems, ...eventMenuOptions },
            processItems(data: any) {
              return processEventMenuItems && processEventMenuItems(data);
            },
          },
          regionResize: allowColumnResize,
          sort: {
            disabled: !allowColumnSorting,
          },
          timeRanges: {
            showCurrentTimeLine,
            enableMouseEvents: true,
          },
          eventTooltip: {
            disabled: disableTooltip,
            align: eventTooltipAlignment,
            trackMouse: false,
            allowOver: true,
            hoverDelay: tooltipHoverDelay,
            ...eventTooltipConfig,
            template: (data: any) => {
              if (
                isTooltipAllowed(data?.eventRecord?.status) &&
                isTooltipAllowed(data?.eventRecord?.type)
              ) {
                if (toolTipTemplate) {
                  return toolTipTemplate(data);
                }
                const toolTipProps = getToolTipProps(
                  data?.eventRecord.data,
                  eventTooltipContent &&
                    eventTooltipContent({
                      ...data?.eventRecord?.data,
                      eventData: data,
                    })
                );
                return renderToStaticMarkup(<ToolTip {...toolTipProps} />);
              }
            },
            listeners: eventTooltipListeners,
          },
          ...customFeatures,
        }}
        onCellClick={handleCellClick}
        eventBodyTemplate={(data: any) => {
          if (data?.data?.status === EVENT_TYPES.CHECKBOX) {
            return renderToStaticMarkup(
              <Checkbox style={{ marginLeft: 'auto' }} defaultChecked={false} />
            );
          }
          if (data?.data?.status === EVENT_TYPES.CHECKBOX_CHECKED) {
            return renderToStaticMarkup(<Checkbox defaultChecked />);
          }
          if (eventTemplate && eventTemplate(data)) {
            const element = eventTemplate(data) ?? <></>;
            return typeof element === 'string'
              ? element
              : renderToStaticMarkup(element);
          }
          return renderToStaticMarkup(
            eventBodyTemplate({ ...data, fontFamily, puckHeight })
          );
        }}
        eventRenderer={(data: any) => {
          const { eventRecord, renderData } = data;
          renderData.style = eventContainerStyles;
          if (onBeforeEventRender && onBeforeEventRender(data)) {
            return onBeforeEventRender(data);
          }
          return handleEventRender({
            eventRecord,
            renderData,
            allowEventContentResponsiveness,
          });
        }}
        columns={columns || defaultGanttColumns}
      />
      {showActionBar && (
        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{ ...GanttBottomBarStyles, ...ganttBottomBarStyles }}
          className="gantt-action-bar"
        >
          <Legend {...legend} />
          <GanttLoader {...loader} />
          <Box ml="auto">
            <GanttActions
              textStyles={{ fontFamily }}
              onNavigate={handleGanttNavigation}
              onReset={onReset}
              onZoomChange={handleZoomChange}
              // showControlActions={ganttControlsConfig.showControlActions}
              {...{
                ...GanttControlsDefaultConfig,
                disableZoomIn: disableZoom?.in,
                disableZoomOut: disableZoom?.out,
                ...ganttControlsConfig,
              }}
            />
          </Box>
        </Stack>
      )}
      {currentPuckTooltipEl &&
        puckTooltipTemplateOnClick?.(currentPuckTooltipEl) !== false &&
        renderPuckTooltipOnClick(currentPuckTooltipEl)}
    </Box>
  );
});

export default Chart;
