import {
  Box,
  IconButton,
  Typography,
  SxProps,
  IconButtonProps,
} from '@mui/material';
import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButtonContainerStyles, resetTextStyles } from '../styles';
import { NavigateToTypes, ZoomChangeTypes } from '../types';
import { IGanttControlConfig } from '../bryntum';

interface IGanttActions extends IGanttControlConfig {
  zoomLevel?: number;
  onNavigate?: (navTo: NavigateToTypes) => void;
  onReset?: () => void;
  onZoomChange?: (zoom: ZoomChangeTypes) => void;
  hideNavigationControl?: boolean;
  hideZoomControl?: boolean;
  hideReset?: boolean;
  disableReset?: boolean;
  textStyles?: SxProps;
  showControlActions?: boolean;
}

const IconButtonTemplate = (props: IconButtonProps) => {
  const { children, sx = {}, onClick, ...rest } = props;
  return (
    <IconButton
      size="small"
      sx={{ ...IconButtonContainerStyles, ...sx }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </IconButton>
  );
};

export const GanttActions: React.FC<IGanttActions> = (props: IGanttActions) => {
  const { onNavigate, onReset, onZoomChange, zoomLevel, ...restProps } = props;
  if (restProps?.showControlActions === false) return <></>;
  return (
    <Box
      sx={{
        gap: '14px',
        display: 'flex',
        alignItems: 'center',
        float: 'right',
      }}
    >
      {!restProps?.hideReset && (
        <IconButtonTemplate
          className={restProps.disableReset ? 'disabled-button' : ''}
          disabled={restProps.disableReset}
          sx={{ ...IconButtonContainerStyles, height: '28px' }}
          onClick={onReset}
        >
          <RefreshIcon fontSize="small" color="primary" />
          <Typography sx={{ ...resetTextStyles, ...restProps.textStyles }}>
            Reset
          </Typography>
        </IconButtonTemplate>
      )}

      {!restProps?.hideNavigationControl && (
        <Box>
          <IconButtonTemplate
            onClick={() => {
              onNavigate?.('LEFT');
            }}
            className={restProps.disableLeftNavigation ? 'disabled-button' : ''}
            disabled={restProps?.disableLeftNavigation}
          >
            <ArrowBackOutlinedIcon fontSize="small" color="primary" />
          </IconButtonTemplate>

          <IconButtonTemplate
            sx={{ ml: 1 }}
            onClick={() => {
              onNavigate?.('RIGHT');
            }}
            className={
              restProps.disableRightNavigation ? 'disabled-button' : ''
            }
            disabled={restProps?.disableRightNavigation}
          >
            <ArrowForwardOutlinedIcon fontSize="small" color="primary" />
          </IconButtonTemplate>
        </Box>
      )}

      {!restProps?.hideZoomControl && (
        <Box>
          <IconButtonTemplate
            onClick={() => {
              onZoomChange?.('OUT');
            }}
            disabled={restProps.disableZoomOut}
            className={restProps.disableZoomOut ? 'disabled-button' : ''}
          >
            <RemoveIcon fontSize="small" color="primary" />
          </IconButtonTemplate>

          <IconButtonTemplate
            sx={{ ml: 1 }}
            onClick={() => {
              onZoomChange?.('IN');
            }}
            disabled={restProps.disableZoomIn}
            className={restProps.disableZoomIn ? 'disabled-button' : ''}
          >
            <AddIcon fontSize="small" color="primary" />
          </IconButtonTemplate>
        </Box>
      )}
    </Box>
  );
};
