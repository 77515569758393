import React from 'react';
import { TooltipSubtitlesType } from '../types';
import { Box, Typography } from '@mui/material';
import {
  subtitlesContainerStyles,
  subTitleKeyStyles,
  subtitleValueStyles,
  subtitleAdditionalTextStyles,
  subtitleSubValueTextStyles,
  commonTypographyStyles,
} from '../styles';
import { TagItem } from '@/models/DTOs/ManageTypes/Requests';
import { TagList } from '@/common/Tag/components/TagList';

interface ITooltipSubtitleTemplate {
  subTitles?: TooltipSubtitlesType[];
  onSubTitleClick?: (data: object) => void;
  tags: Array<TagItem>;
}

const TooltipSubtitleTemplate: React.FC<ITooltipSubtitleTemplate> = (props) => {
  const { subTitles, tags, ...restProps } = props;
  return (
    <>
      {subTitles?.map((subtitle: TooltipSubtitlesType) => (
        <Box
          key={subtitle.key}
          style={{ ...subtitlesContainerStyles, ...subtitle.containerStyles }}
        >
          {subtitle.key && (
            <Box sx={subtitle.keyContainerStyles}>
              <Typography
                style={{ ...subTitleKeyStyles, ...subtitle.keyStyles }}
              >
                {subtitle.key}:
              </Typography>
            </Box>
          )}
          <Box sx={commonTypographyStyles}>
            {!subtitle?.values?.[0]?.text?.length &&
            !subtitle?.additionalValue?.length &&
            !subtitle?.subValue?.length ? (
              <Typography style={subtitleAdditionalTextStyles}>
                {subtitle?.emptyText ?? '-'}
              </Typography>
            ) : (
              <>
                <Box
                  display={'flex'}
                  gap="2px"
                  sx={subtitle?.valueContainerStyles}
                >
                  {subtitle?.values?.map(
                    (data: { id: string; text: string }, index: number) => (
                      <Typography
                        key={data.id}
                        style={{
                          ...subtitleValueStyles,
                          ...subtitle.valueStyles,
                        }}
                        component={'a'}
                        onClick={() =>
                          restProps?.onSubTitleClick?.({
                            ...subtitle,
                            value: data,
                          })
                        }
                      >
                        {data.text}
                        {index !== (subtitle?.values?.length as number) - 1 &&
                          `,`}
                      </Typography>
                    )
                  )}
                  {subtitle.additionalValue && (
                    <Typography
                      style={{
                        ...subtitleAdditionalTextStyles,
                        ...subtitle.additionalValueStyles,
                      }}
                    >
                      {subtitle.additionalValue}
                    </Typography>
                  )}
                </Box>
                {subtitle.subValue && (
                  <Typography
                    style={{
                      ...subtitleSubValueTextStyles,
                      ...subtitle.subValueStyles,
                    }}
                  >
                    {subtitle.subValue}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      ))}
      <Box style={{ ...subtitlesContainerStyles, alignItems: 'center' }}>
        <Box>
          <Typography style={{ ...subTitleKeyStyles }}>Tags:</Typography>
        </Box>
        <Box>
          <TagList tags={tags} small />
        </Box>
      </Box>
    </>
  );
};

export default TooltipSubtitleTemplate;
