import { annotateNameAsync, ServiceError } from '@/api/interfaces';
import { ComposedError, httpClient } from '@/axios/axiosInstance';
import {
  CreateCustomAdvancedReportRequest,
  DeleteAdvancedReportRequest,
  DownloadAdvancedReportRequest,
  DownloadPredefinedAdvancedReportRequest,
  GetAdvancedReportsRequest,
} from '@/models/DTOs/AdvancedReports/Requests';
import { AdvancedReport } from '@/models/DTOs/AdvancedReports/AdvancedReports';
import { IAdvancedReportService } from '@/api/interfaces/IAdvancedReportService';
import { docType } from '@/utils/Doc';
import { RootStoreInstence } from '@/store/root-store/rootStateContext';

export class AdvancedReportService extends IAdvancedReportService {
  @annotateNameAsync
  async getAdvancedReports(
    requestData: GetAdvancedReportsRequest
  ): Promise<AdvancedReport[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/interload/api/v2/advanced/reports/report/list`,
        requestData,
        true,
        false
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createCustomAdvancedReport(
    requestData: CreateCustomAdvancedReportRequest
  ): Promise<AdvancedReport | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/interload/api/v2/advanced/reports/create/report`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteCustomAdvancedReport(
    requestData: DeleteAdvancedReportRequest
  ): Promise<boolean | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        `web/interload/api/v2/advanced/reports/delete/report`,
        requestData,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async downloadAdvancedReport(
    requestData: DownloadAdvancedReportRequest
  ): Promise<docType | ServiceError> {
    try {
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message:
          'Report Generation is in progress. Report will be downloaded automatically.',
      });
      const response = await httpClient.postRaw(
        `web/interload/api/v2/advanced/reports/download/comprehensive`,
        requestData,
        undefined,
        false,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Report Generated Successfully.',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      if (error.response.status === 417) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: error.response.data.message,
        });
        return this.getServiceError(composedError);
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Report Generation Failed.',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async downloadPredefinedAdvancedReport(
    requestData: DownloadPredefinedAdvancedReportRequest
  ): Promise<docType | ServiceError> {
    try {
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message:
          'Report Generation is in progress. Report will be downloaded automatically.',
      });
      const response = await httpClient.postRaw(
        `web/interload/api/v2/advanced/reports/download/predefined-comprehensive`,
        undefined,
        requestData,
        false,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Report Generated Successfully.',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      if (error.response.status === 417) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: error.response.data.message,
        });
        return this.getServiceError(composedError);
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Report Generation Failed.',
      });
      return this.getServiceError(composedError);
    }
  }
}
