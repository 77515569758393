import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DatePicker from '../../../../../common/Ui/FormDatePicker/FormDatePicker';
import { SingleAutocompleteForm } from '../../../../Ui/SingleAutocomplete';
import TextField from '../../../../Ui/TextField/TextField';

export default function ModeForm() {
  const { control, watch } = useFormContext();
  const [mode] = watch(['mode']);

  return (
    <Grid container spacing={2}>
      <SingleAutocompleteForm
        control={control}
        name="mode"
        label="Mode"
        options={[
          { value: 'Drayage', key: 'Drayage' },
          { value: 'TL', key: 'TL' },
        ]}
        wrapperStyles={{ paddingRight: '2px' }}
        fieldName="value"
        fieldValue="key"
        sizes={{ xs: 6 }}
      />

      {mode === 'Drayage' && (
        <>
          <TextField
            id={'serviceType'}
            control={control}
            name="serviceType"
            label={'Service Type'}
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'steamShipLine'}
            control={control}
            name="steamShipLine"
            label={'Steamship Line'}
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'containerTerminal'}
            control={control}
            name="containerTerminal"
            label={'Terminal info'}
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'holdCategory'}
            control={control}
            name="holdCategory"
            label={'Holds Category'}
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'holdType'}
            control={control}
            name="holdType"
            label={'Holds Type'}
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'yardLocation'}
            control={control}
            name="yardLocation"
            label={'Yard Location'}
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'containerSize'}
            control={control}
            name="containerSize"
            label={'Container Size'}
            sizes={{ xs: 6 }}
          />{' '}
          <TextField
            id={'chassisProvider'}
            control={control}
            name="chassisProvider"
            label={'Chassis Provider'}
            sizes={{ xs: 6 }}
          />
          <DatePicker
            styleProps={{
              width: 1,
            }}
            label={'Earliest Return Date'}
            control={control}
            name="erd"
            sizes={{ xs: 6 }}
          />
          <DatePicker
            styleProps={{
              width: 1,
            }}
            label={'Last Free Day'}
            control={control}
            name="lastFreeDay"
            sizes={{ xs: 6 }}
          />
          <DatePicker
            styleProps={{
              width: 1,
            }}
            label={'Perdiem Start Date'}
            control={control}
            name="perdiemStartDate"
            sizes={{ xs: 6 }}
          />
          <DatePicker
            styleProps={{
              width: 1,
            }}
            label={'Full outgate date'}
            control={control}
            name="fullOutgateDate"
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'holdCategory'}
            control={control}
            name="inPin"
            label={'In Pin'}
            sizes={{ xs: 6 }}
          />
          <TextField
            id={'holdType'}
            control={control}
            name="outPin"
            label={'Out Pin'}
            sizes={{ xs: 6 }}
          />
        </>
      )}
    </Grid>
  );
}
