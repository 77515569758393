import { IDriverTooltip } from '@/common/Gantt/types';
import { FASOrder } from '../FinanceAccountSummary/FinanceAccountSummary';
import {
  IDispatchDriverTimeline,
  IDispatchDriverTimelineTrip,
} from './Timeline';
import { HosConstantsType } from '../../../types/DispatchTypes';
import { ESecondaryDetailsPanelType } from '@/views/dispatch2/constants/types';

export interface IDispatchDriver {
  driverGroupDetails: DispatchDriverGroupDetails;
  driverTimelineSummary: DispatchDriverTimelineSummary;
}

export interface IDispatchDriverShort {
  driverGroupDetails: {
    id: string;
    driverId: number;
  };
}

export interface DispatchDriverGroupDetails {
  id: string;
  groupName: string;
  groupMode: string;
  primaryDriverPhone: string;
  dispatcherId: any;
  primaryDriverEmploymentType: string;
  primaryDriverOperationType: string;
  tractorId: any;
  tractorNumber: any;
  trailerId: any;
  trailerNumber: any;
  driverId?: number;
  email?: string;
  phoneNumber?: string;
}

export interface DispatchDriverTimelineSummary {
  lastTripPlanUpdateTime: string;
  currentTimingEarnings: number;
  currentTimingMileage: number;
  entitySummaries: DispatchEntitySummary[];
  breakSummaries: DispatchBreakSummary[];
}

export enum EDispatchEntitySummaryType {
  TRIP = 'TRIP',
  EMPTY = 'EMPTY',
  TIMEOFF = 'TIMEOFF',
}
export interface DispatchEntitySummary {
  id: string;
  previousId: string;
  nextId: string;
  type: string;
  status: string;
  deadheadMilage: number;
  loadedMileage: number;
  driveTimeMins: number;
  loadIds: string[];
  loadSeqNumbers: string;
  startTime: string;
  endTime: string;
  origin: DispatchOrigin;
  destination: DispatchDestination;
  tractorId: number;
  tractorNumber: string;
  trailerId: any;
  trailerNumber: any;
  revenue?: number;
  usableHours: DispatchUsableHours;
  hosSummary: DispatchHosSummary;
  customerList?: string[];
  timeOffStartTime?: string;
  timeOffEndTime?: string;
  tripId?: string;
  boundaryStatusFlag?: boolean;
  currentStopStatus?: string;
  dispatcherName?: string;
}

export interface DispatchOrigin {
  city: string;
  state: string;
  country: string;
  tripDestinationTimezone: string;
  relay: boolean;
}

export interface DispatchDestination {
  city: string;
  state: string;
  country: string;
  tripDestinationTimezone: string;
  relay: boolean;
}

export interface DispatchUsableHours {
  usableDriveMins: number;
  usableDutyMins: number;
}

export interface DispatchHosSummary {
  breakTimeRemainingMins: number;
  weeklyRemainingDutyMins: number;
  dailyDriveMins: number;
  dailyDutyMins: number;
}

export interface DispatchBreakSummary {
  startTime: string;
  endTime: string;
  hosStatus: string;
  locationDetails: DispatchLocationDetails;
}

export interface DispatchLocationDetails {
  city: string;
  state: string;
  country: any;
  tripDestinationTimezone: string;
}

export interface DispatchOrder {
  property: string;
  direction: string;
}

export class PaginatedDispatchDriverListResponse {
  content!: IDispatchDriver[];
  order!: FASOrder[];
  first!: boolean;
  last!: boolean;
  number!: number;
  numberOfElements!: number;
  size!: number;
  totalElements!: number;
  totalPages!: number;
  totalAmount!: number;
}

export interface IDriverGroupWarning {
  type: string;
  properties: { name: string; value: string }[];
}

export interface IDriverGroupWarningResponse {
  driverSafetyWarnings: IDriverGroupWarning[];
  driverPlanningWarnings: IDriverGroupWarning[];
  tractorSafetyWarnings: IDriverGroupWarning[];
  trailerSafetyWarnings: any[];
  driverTractorIncompatibilityWarnings: IDriverGroupWarning[];
  driverTrailerIncompatibilityWarnings: IDriverGroupWarning[];
  loadWarnings: IDriverGroupWarning[];
  warningsCount: number;
  driverGroupId: string;
  driverHOSWarning: boolean;
}
export class GanttWarningsResponse extends Array<IDriverGroupWarningResponse> {
  //array of IDriverGroupWarningResponse,
}

export interface IGanttDriverLocationMarkersResponse {
  markers: IGanttDriverLocationMarker[];
  mappings?: {
    [property: string]: GanttDriverLocationMarkersMapping;
  };
}
export class GanttDriverLocationMarkersResponse
  implements IGanttDriverLocationMarkersResponse
{
  markers!: IGanttDriverLocationMarker[];
  missingMarkers?: GanttDriverLocationMarkersMissing[];
  currentWeek?: Array<string>;
  hosConstants?: HosConstantsType;
  numberOfDrivers?: number;
}
export interface GanttDriverLocationMarkersMissing {
  id: string;
  organizationId?: number;
  groupName: string;
  driverIds: Array<number>;
  activeForOperation: boolean;
  dispatcherId: number;
  groupStatus?: number;
}
export interface GanttDriverLocationMarkersMapping {
  lat: number;
  lng: number;
  city: string;
  state: string;
  country: any;
  locationName: string;
  locationSource: string;
  time: string;
  id: any;
  driversLocations: {
    lat: number;
    lng: number;
    city: string;
    state: string;
    country: any;
    locationName: string;
    locationSource: string;
    time: string;
    driverId: number;
  }[];
}

export class GetDispatchDetailsPanelResponse
  implements IDispatchDriverTimeline
{
  driverGroupId!: string;
  driverGroupName!: string;
  dispatcherId: any;
  dispatcherName: any;
  organizationId!: number;
  currentTrip!: IDispatchDriverTimelineTrip[];
  assignedTrips!: IDispatchDriverTimelineTrip[];
  futureTrips!: IDispatchDriverTimelineTrip[];
}

export class DriverTractorAssignResponse {}
export class DriverTrailerAssignResponse {}

export interface IGanttDriverLocationMarker {
  lat: number;
  lng: number;
  type: string;
  status: string;
  issue: boolean;
  deadHead?: boolean;
  routeFlag?: string;
  driverGroupID: string;
  driverGroupName: string;
  shiftDriveTimeRemaining: number;
  shiftDutyTimeRemaining: number;
  cycleTimeRemaining: number;
  timeToBreak: number;
  locationSource?: string;
  isHOSOutdated?: boolean;
  isLocationOutdated?: boolean;
  tooltip: IDriverTooltip | IGanttDriverLocationMarkerTooltip;
}

export interface IGanttDriverLocationMarkerTooltip {
  content: IGanttDriverLocationMarkerContent[];
  detention: boolean;
  delay: boolean;
  hos: boolean;
}

export interface IGanttDriverLocationMarkerContent {
  key: string;
  value: string;
  subValue?: string;
}

export interface GetPreviousLoadContent {
  id: string;
  seqNumber: any;
  organizationId: number;
  loadCategory: string;
  loadStatus: string;
  fromLocation: string;
  toLocation: string;
  startDate: string;
  endDate: string;
  firstStopTimezone: string;
  lastStopTimezone: string;
}
export class PaginatedGetPreviousLoadResponse {
  content!: GetPreviousLoadContent[];
  order!: FASOrder[];
  first!: boolean;
  last!: boolean;
  number!: number;
  numberOfElements!: number;
  size!: number;
  totalElements!: number;
  totalPages!: number;
  totalAmount!: number;
}

export class PaginatedResponse<TModel> {
  content!: TModel[];
  order!: FASOrder[];
  first!: boolean;
  last!: boolean;
  number!: number;
  numberOfElements!: number;
  size!: number;
  totalElements!: number;
  totalPages!: number;
  totalAmount!: number;
}

export class CreateTimeoffResponse {
  //
}
export class RevertOrActivateTimeoffResponse {
  //
}
export class UpdateGroupAssgnmentResponse {
  //
}

export class GetPreferenceDriverResponse {
  id!: string;
  organizationId!: number;
  driverId!: number;
  statesToAvoid!: any[];
  useSleeperBerthProvision!: boolean;
  easypass!: boolean;
  avoidTollRoutes!: boolean;
  minHaulLength!: number;
  maxHaulLength!: number;
  expectedWeekRevenue!: number;
  expectedMinimumRevenue!: number;
  preferredTruckstops!: string[];
  statesToAvoidForPickupDelivery!: any[];
  workWeekType!: string;
  ignoreHOSRestrictions!: boolean;
  dvirPreferences!: string;
  language!: string | null;
  weeklyResetDay!: string | null;
  weeklyRevenueTarget!: string | number | null;
  weeklyEarningsTarget!: string | number | null;
  weeklyMileageTarget!: string | number | null;
  drivingBehaviourPreference!: string;
  locationPreference!: Array<any>;
}

export class UpdatePreferenceDriverResponse extends GetPreferenceDriverResponse {
  id!: string;
  organizationId!: number;
  driverId!: number;
  statesToAvoid!: any[];
  useSleeperBerthProvision!: boolean;
  easypass!: boolean;
  avoidTollRoutes!: boolean;
  minHaulLength!: number;
  maxHaulLength!: number;
  expectedWeekRevenue!: number;
  expectedMinimumRevenue!: number;
  preferredTruckstops!: string[];
  statesToAvoidForPickupDelivery!: any[];
  workWeekType!: string;
  ignoreHOSRestrictions!: boolean;
  dvirPreferences!: string;
  language!: string | null;
}

export class GetDriverLoadsGroupAssignmentResponse
  implements IDriverLoadsGroupAssignmentResponse
{
  data!: IDriverLoadsGroupAssignmentData[];
  lastSuccessfulEngineOutputDate!: string;
}

export interface IDriverLoadsGroupAssignmentResponse {
  data: IDriverLoadsGroupAssignment[];
  lastSuccessfulEngineOutputDate: string;
}

export interface IDriverLoadsGroupAssignment {
  organizationId: number;
  driverId: number;
  driverName: string;
  loadStatus: string;
  loadType: string;
  numberOfStops: number;
  signatureCollectionEnabled: boolean;
  distanceTraveled: number;
  createDate: string;
  updateDate: string;
  loadCreatorUserId: number;
  stops: Stop[];
  shipments: any[];
  loadDetails: LoadDetails;
  loadCategory: string;
  concernRaised: boolean;
  revenue: number;
  actualDispatchedTime: string;
  operationType: string;
  hasBeenCancelled: boolean;
  assignment: Assignment;
  seqNumber: string;
  proofOfDeliveryMandatory: boolean;
  billOfLadingMandatory: boolean;
  driverGroupId: string;
  nonInvoiceable: boolean;
  connectedLoads: any[];
  visibilityProvidersIds: VisibilityProvidersIds;
  acceptedByDriver: boolean;
  id: string;
  groupDetails: GroupDetails;
  applicableActions: string[];
  explicit: boolean;
}

interface Stop {
  activity: string;
  stopStatus: string;
  createDate: string;
  shipments: any[];
  id: number;
  sequenceNumber: number;
  location: Location;
  actualTimeOfArrival: string;
  actualTimeOfCompletion: string;
  appointmentStartDate: string;
  appointmentEndDate: string;
  isDriverAssistRequired: boolean;
  estimatedActivityDuration: number;
  violation: number;
  actualTimeSpent: number;
  totalMiles: number;
  totalDriveTime: number;
  estimatedTimeSpent: number;
  totalActualMiles: number;
  totalActualDriveTime: number;
  beforeEvaluationMilage: number;
  stopCategory: string;
  first: boolean;
  last: boolean;
  driveTime: number;
  milage: number;
  activityType: string;
  fixedAppointment: boolean;
  connectedStopDetails: any[];
}

interface Location {
  id: any;
  organizationId: number;
  axeleId: any;
  locationName: any;
  fullAddress: string;
  address: string;
  streetAddress: string;
  streetAddress2: any;
  city: string;
  zipcode: string;
  state: string;
  lat: number;
  lng: number;
  contacts: any[];
  center: Center;
  timezone: string;
  notes: any;
  avgHandlingTime: any;
  recordDate: any;
  country: any;
}

interface Center {
  lat: number;
  lng: number;
}

interface LoadDetails {
  trailerTypes: any[];
  noteDetails: NoteDetails;
  equipmentAdditionalDetails: EquipmentAdditionalDetails;
}

interface NoteDetails {}

interface EquipmentAdditionalDetails {
  chassisNumber: any;
  containerNumber: any;
  lowerLimitTemperature: any;
  upperLimitTemperature: any;
  sealNumber: any;
}

interface Assignment {
  tractorId: number;
  tractorAxeleId: any;
  tractorModel: string;
  tractorName: string;
  trailerId: any;
  trailerAxeleId: any;
  trailerModel: any;
  trailerName: any;
  assignmentDate: string;
  timestamp: number;
  trailerExplicit: boolean;
  tractorExplicit: boolean;
  loadExpenseTractor: any;
}

interface VisibilityProvidersIds {}

interface GroupDetails {
  id: string;
  organizationId: number;
  groupName: string;
  groupMode: string;
  drivers: Driver[];
  activeForOperation: boolean;
  dispatcherId: any;
  employmentType: string;
  operationType: string;
  displayName: string;
  assetAssignment: AssetAssignment;
}

interface Driver {
  id: number;
  name: string;
  email: string;
  mobileno: string;
  extension: any;
  countryCode: any;
  shareTractorExpenses: boolean;
}

interface AssetAssignment {
  tractorId: number;
  tractorModel: string;
  tractorName: string;
  trailerId: any;
  trailerModel: any;
  trailerName: any;
}
export class RegenerateTimelineResponse {
  message!: string;
}
export interface ITractorInfoForDriverAssign {
  id: number;
  name: string;
  model: string;
  status: string;
  ownerEmail: string;
  automateSettlementCalculation: boolean;
}

export type ITrailerInfoForDriverAssign = ITractorInfoForDriverAssign;
export class GetTractorInfoForDriverAssignResponse extends PaginatedResponse<ITractorInfoForDriverAssign> {}
export class GetTrailerInfoForDriverAssignResponse extends PaginatedResponse<ITrailerInfoForDriverAssign> {}
export class ChangeStatusBulkSelectUnassignedTripsResponse {
  //
}

export interface DispatchGridDataItem {
  organizationId: number;
  driverGroup: {
    id: string;
    name: string;
    drivers: any[];
  };
  tractor: {
    id: number;
    name: string;
  } | null;
  trailer: {
    id: number;
    name: string;
  } | null;
  loads: {
    id: string;
    seqNumber: string;
  }[];
  trip: {
    id: string;
    seqNumber: string;
  } | null;
  nextTrip: {
    id: string;
    seqNumber: string;
  } | null;
  nextLoads: {
    id: string;
    seqNumber: string;
  }[];
  customers: {
    id: number;
    seqNumber: string;
  }[];
  nextCustomers: {
    id: number;
    seqNumber: string;
  }[];
  distanceToNextStop: number | null;
  etaNextStop: string | null;
  nextStopTimezone: string | null;
  origin: string | null;
  originApointmentTime: string | null;
  originTimezone: string | null;
  destination: string | null;
  destinationApointmentTime: string | null;
  destinationTimezone: string | null;
  nextOrigin: string | null;
  nextOriginAppointmentTime: string | null;
  nextOriginTimezone: string | null;
  nextDestination: string | null;
  nextDestinationAppointmentTime: string | null;
  nextDestinationTimezone: string | null;
  status: string | null;
  driverLastLocation: {
    lat: number;
    lon: number;
    city: string;
    state: string;
    country: string;
    locationName: string;
    address: string;
    zipCode: string;
    lastUpdatedTime: string;
  } | null;
}

export interface SecondaryPanelType {
  type: ESecondaryDetailsPanelType;
  id: string | number;
  isGlobal?: boolean;
  defaultTab?: string;
  tripPrimaryId?: string;
}
