import axios from 'axios';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { QueryParams } from '../../models';
import {
  ChangeStatusBulkSelectUnassignedTripsResponse,
  CreateTimeoffResponse,
  DispatchGridDataItem,
  DriverTractorAssignResponse,
  DriverTrailerAssignResponse,
  GanttDriverLocationMarkersResponse,
  GanttWarningsResponse,
  GetDispatchDetailsPanelResponse,
  GetDriverLoadsGroupAssignmentResponse,
  GetPreferenceDriverResponse,
  GetTractorInfoForDriverAssignResponse,
  GetTrailerInfoForDriverAssignResponse,
  PaginatedDispatchDriverListResponse,
  PaginatedGetPreviousLoadResponse,
  RegenerateTimelineResponse,
  RevertOrActivateTimeoffResponse,
  UpdateGroupAssgnmentResponse,
  UpdatePreferenceDriverResponse,
} from '../../models/DTOs/Dispatch/Dispatch';
import {
  ChangeStatusBulkSelectUnassignedTripsRequest,
  CreateTimeoffRequest,
  DeleteTimeoffRequest,
  DriverTractorAssignRequest,
  DriverTractorUnAssignRequest,
  DriverTrailerAssignRequest,
  DriverTrailerUnAssignRequest,
  GanttDriverLocationMarkersRequest,
  GanttWarningsRequest,
  GetDispatchDetailsPanelMapDataRequest,
  GetDispatchDetailsPanelRequest,
  GetDispatchGridDataRequest,
  GetDriverLoadsGroupAssignmentRequest,
  GetPreferenceDriverRequest,
  GetPreviousLoadRequest,
  GetTractorInfoForDriverAssignRequest,
  GetTrailerInfoForDriverAssignRequest,
  PaginatedDispatchDriverRequest,
  PaginatedDispatchDriverRequestQuery,
  RegenerateTimelineRequest,
  RevertOrActivateTimeoffRequest,
  UpdateGroupAssgnmentRequest,
  UpdatePreferenceDriverRequest,
  UpdateTimeoffRequest,
} from '../../models/DTOs/Dispatch/Requests';
import {
  DriverMapMarkersRequestParams,
  GanttDriverMapMarkersResponse,
} from '../../models/DTOs/Driver/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { IDispatchService } from '../interfaces/IDispatchService';

const ganttApiPath = '/web/gantt/api/v2/gantt';
const timeOffURL = '/web/load/api/v2/timeoff';

export const API_RESOURCE_URL = {
  driver: {
    list: `${ganttApiPath}/v3`,
    ganttWarnings: `${ganttApiPath}/data/warnings/v3`,
    getGanttDriverLocationMarkers: `${ganttApiPath}/driverLocationMarkers`,
    getDriverMapMarkers: `${ganttApiPath}/driverMapMarkers`,
  },
  dispatchDetailsPanel: {
    getDispatchDetailsPanel: '/web/load/api/v2/timeline/group/trip',
    getDispatchDetailsPanelMapData: '/web/load/api/v2/timeline/group/trip/map',
  },
  tractor: {
    getTractorInfoForDriverAssign:
      '/web/asset/api/v2/assets/tractors/base-details',
    driverTractorAssign: '/web/load/api/v2/driver/tractor/assign',
    driverTractorUnAssign: '/web/load/api/v2/driver/tractor/unassign',
  },
  trailer: {
    getTrailerInfoForDriverAssign:
      '/web/asset/api/v2/assets/trailers/base-details',
    driverTrailerAssign: '/web/load/api/v2/driver/trailer/assign',
    driverTrailerUnAssign: '/web/load/api/v2/driver/trailer/unassign',
  },
  timeoff: {
    getPreviousLoad: '/web/load/api/v2/workflow/loads/position',
    create: timeOffURL,
    update: timeOffURL,
    delete: timeOffURL,
    revertOrActivateTimeoff: timeOffURL,
  },
  timeline: {
    updateGroupAssgnment: '/web/load/api/v2/workflow/update/assignment',
    getDriverLoadsGroupAssignment:
      '/web/load/api/v2/driverLoads/group/assignment',
    regenerateTimeline: 'web/driver/api/v2/tripplan/invoke',
  },
  preference: {
    updatePreferenceDriver: '/web/preference/api/v2/preference/driver',
    getPreferenceDriver: '/web/preference/api/v2/preference/drivers',
  },
  unassignedTrips: {
    changeStatusBulkSelect: '/web/load/api/ax2/load-trip/actions/trips/bulk',
  },
};

export const isAxiosErrorCancel = (value: ComposedError): boolean => {
  return axios.isCancel(value?.error);
};

export class DispatchService extends IDispatchService {
  @annotateNameAsync
  async getPaginatedDriverList(
    requestData: PaginatedDispatchDriverRequest,
    queryParams?: PaginatedDispatchDriverRequestQuery,
    skipTrackPromise = false
  ): Promise<PaginatedDispatchDriverListResponse | ServiceError> {
    try {
      const result = await httpClient.putRaw(
        API_RESOURCE_URL.driver.list,
        queryParams,
        requestData,
        false,
        skipTrackPromise
      );

      result.data.content = result.data.content ?? [];
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getGanttWarnings(
    requestData: GanttWarningsRequest,
    skipTrackPromise = true
  ): Promise<GanttWarningsResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GanttWarningsResponse>(
        API_RESOURCE_URL.driver.ganttWarnings,
        requestData,
        GanttWarningsResponse,
        false,
        skipTrackPromise
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getGanttDriverLocationMarkers(
    requestBody: GanttDriverLocationMarkersRequest,
    skipTrackPromise = true
  ): Promise<GanttDriverLocationMarkersResponse | ServiceError | null> {
    try {
      const result = await httpClient.post<GanttDriverLocationMarkersResponse>(
        API_RESOURCE_URL.driver.getGanttDriverLocationMarkers,
        undefined,
        requestBody,
        GanttDriverLocationMarkersResponse,
        false,
        undefined,
        skipTrackPromise
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverMapMarkers(
    requestBody: DriverMapMarkersRequestParams,
    queryParams: QueryParams,
    skipTrackPromise = true
  ): Promise<GanttDriverMapMarkersResponse | ServiceError | null> {
    try {
      const result = await httpClient.post<GanttDriverMapMarkersResponse>(
        API_RESOURCE_URL.driver.getDriverMapMarkers,
        queryParams,
        requestBody,
        GanttDriverMapMarkersResponse,
        true,
        undefined,
        skipTrackPromise
      );
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDispatchDetailsPanel(
    requestData: GetDispatchDetailsPanelRequest,
    skipTrackPromise = true
  ): Promise<GetDispatchDetailsPanelResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GetDispatchDetailsPanelResponse>(
        API_RESOURCE_URL.dispatchDetailsPanel.getDispatchDetailsPanel,
        requestData,
        GetDispatchDetailsPanelResponse,
        false,
        skipTrackPromise
      );

      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'failedGetTimelineTripDispatch',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDispatchDetailsPanelMapData(
    requestData: GetDispatchDetailsPanelMapDataRequest,
    skipTrackPromise = true
  ): Promise<GanttDriverLocationMarkersResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GanttDriverLocationMarkersResponse>(
        API_RESOURCE_URL.dispatchDetailsPanel.getDispatchDetailsPanelMapData,
        requestData,
        GanttDriverLocationMarkersResponse,
        false,
        skipTrackPromise
      );

      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'failedGetTimelineMapDispatch',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async driverTractorAssign(
    requestData: DriverTractorAssignRequest
  ): Promise<DriverTractorAssignResponse | ServiceError | null> {
    try {
      const result = await httpClient.post<DriverTractorAssignResponse>(
        API_RESOURCE_URL.tractor.driverTractorAssign,
        undefined,
        requestData,
        DriverTractorAssignResponse,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTractorAssign',
      });
      return result;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'driverTractorAssign',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async driverTractorUnAssign(
    requestData: DriverTractorUnAssignRequest
  ): Promise<DriverTractorAssignResponse | ServiceError | null> {
    try {
      const result = await httpClient.post<DriverTractorAssignResponse>(
        API_RESOURCE_URL.tractor.driverTractorUnAssign,
        undefined,
        requestData,
        DriverTractorAssignResponse,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTractorUnAssign',
      });
      return result;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'driverTractorUnAssign',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async driverTrailerAssign(
    requestData: DriverTrailerAssignRequest
  ): Promise<DriverTrailerAssignResponse | ServiceError | null> {
    try {
      const result = await httpClient.post<DriverTrailerAssignResponse>(
        API_RESOURCE_URL.trailer.driverTrailerAssign,
        undefined,
        requestData,
        DriverTrailerAssignResponse,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTrailerAssign',
      });
      return result;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'driverTrailerAssign',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async driverTrailerUnAssign(
    requestData: DriverTrailerUnAssignRequest
  ): Promise<DriverTrailerAssignResponse | ServiceError | null> {
    try {
      const result = await httpClient.post<DriverTrailerAssignResponse>(
        API_RESOURCE_URL.trailer.driverTrailerUnAssign,
        undefined,
        requestData,
        DriverTrailerUnAssignRequest,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTrailerUnAssign',
      });
      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'driverTrailerUnAssign',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPreviousLoad(
    requestData: GetPreviousLoadRequest
  ): Promise<PaginatedGetPreviousLoadResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<PaginatedDispatchDriverListResponse>(
        API_RESOURCE_URL.timeoff.getPreviousLoad,
        requestData,
        PaginatedDispatchDriverListResponse,
        false,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createTimeoff(
    requestData: CreateTimeoffRequest
  ): Promise<CreateTimeoffResponse | ServiceError | null> {
    try {
      const result = await httpClient.post<CreateTimeoffResponse>(
        API_RESOURCE_URL.timeoff.create,
        undefined,
        requestData,
        CreateTimeoffRequest,
        false
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateTimeoff(
    requestData: UpdateTimeoffRequest
  ): Promise<CreateTimeoffResponse | ServiceError | null> {
    try {
      const result = await httpClient.put<CreateTimeoffResponse>(
        API_RESOURCE_URL.timeoff.update,
        undefined,
        requestData,
        CreateTimeoffRequest,
        false
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTimeoff(
    requestData: DeleteTimeoffRequest
  ): Promise<CreateTimeoffResponse | ServiceError | null> {
    try {
      const result = await httpClient.delete<CreateTimeoffResponse>(
        API_RESOURCE_URL.timeoff.delete,
        requestData,
        null,
        CreateTimeoffResponse
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async revertOrActivateTimeoff(
    requestData: RevertOrActivateTimeoffRequest
  ): Promise<RevertOrActivateTimeoffResponse | ServiceError | null> {
    try {
      return await httpClient.put<RevertOrActivateTimeoffResponse>(
        API_RESOURCE_URL.timeoff.revertOrActivateTimeoff,
        undefined,
        requestData,
        RevertOrActivateTimeoffRequest,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateGroupAssgnment(
    requestData: UpdateGroupAssgnmentRequest
  ): Promise<UpdateGroupAssgnmentResponse | ServiceError | null> {
    try {
      return await httpClient.put<CreateTimeoffResponse>(
        API_RESOURCE_URL.timeline.updateGroupAssgnment,
        undefined,
        requestData,
        CreateTimeoffResponse,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updatePreferenceDriver(
    requestData: UpdatePreferenceDriverRequest
  ): Promise<UpdatePreferenceDriverResponse | ServiceError | null> {
    try {
      const response = await httpClient.put<UpdatePreferenceDriverResponse>(
        API_RESOURCE_URL.preference.updatePreferenceDriver,
        undefined,
        requestData,
        UpdatePreferenceDriverResponse,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updatePreferenceDriver',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'updatePreferenceDriver',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async addPreferenceDriver(
    requestData: UpdatePreferenceDriverRequest
  ): Promise<UpdatePreferenceDriverResponse | ServiceError | null> {
    try {
      const response = await httpClient.post<UpdatePreferenceDriverResponse>(
        API_RESOURCE_URL.preference.updatePreferenceDriver,
        undefined,
        requestData,
        UpdatePreferenceDriverResponse,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updatePreferenceDriver',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'updatePreferenceDriver',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPreferenceDriver(
    requestData: GetPreferenceDriverRequest,
    skipTrackPromise = true
  ): Promise<GetPreferenceDriverResponse | ServiceError | null> {
    try {
      const response = await httpClient.get<{
        driverIdToPreference: {
          [driverId: number]: GetPreferenceDriverResponse;
        };
      }>(
        API_RESOURCE_URL.preference.getPreferenceDriver,
        requestData,
        GetPreferenceDriverRequest,
        false,
        skipTrackPromise
      );
      const data = response?.driverIdToPreference[requestData.driverId] || null;
      return data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverLoadsGroupAssignment(
    requestData: GetDriverLoadsGroupAssignmentRequest,
    skipTrackPromise = true
  ): Promise<GetDriverLoadsGroupAssignmentResponse | ServiceError | null> {
    try {
      return await httpClient.get<GetDriverLoadsGroupAssignmentResponse>(
        API_RESOURCE_URL.timeline.getDriverLoadsGroupAssignment,
        requestData,
        GetPreferenceDriverRequest,
        false,
        skipTrackPromise
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async regenerateTimeline(
    requestData: RegenerateTimelineRequest
  ): Promise<RegenerateTimelineResponse | ServiceError | null> {
    try {
      const response = await httpClient.put<RegenerateTimelineResponse>(
        API_RESOURCE_URL.timeline.regenerateTimeline,
        requestData,
        undefined,
        RegenerateTimelineRequest,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'regenerateTimeline',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'regenerateTimeline',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTractorInfoForDriverAssign(
    requestData: GetTractorInfoForDriverAssignRequest
  ): Promise<GetTractorInfoForDriverAssignResponse | ServiceError> {
    try {
      return await httpClient.get<GetTractorInfoForDriverAssignResponse>(
        API_RESOURCE_URL.tractor.getTractorInfoForDriverAssign,
        requestData,
        GetTractorInfoForDriverAssignRequest,
        false,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTrailerInfoForDriverAssign(
    requestData: GetTrailerInfoForDriverAssignRequest
  ): Promise<GetTrailerInfoForDriverAssignResponse | ServiceError> {
    try {
      return await httpClient.get<GetTrailerInfoForDriverAssignResponse>(
        API_RESOURCE_URL.trailer.getTrailerInfoForDriverAssign,
        requestData,
        GetTrailerInfoForDriverAssignRequest,
        false,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async changeStatusBulkSelectUnassignedTrips(
    requestData: ChangeStatusBulkSelectUnassignedTripsRequest
  ): Promise<ChangeStatusBulkSelectUnassignedTripsResponse | ServiceError> {
    try {
      const result = await httpClient.putRaw(
        API_RESOURCE_URL.unassignedTrips.changeStatusBulkSelect,
        undefined,
        requestData,
        false
      );
      return result.data;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'changeStatusBulkSelectUnassignedTrips',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDispatchGridData(
    requestData: GetDispatchGridDataRequest
  ): Promise<DispatchGridDataItem[] | ServiceError> {
    try {
      const result = await httpClient.putRaw(
        '/web/gantt/api/v2/gantt/list',
        undefined,
        requestData
      );
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
