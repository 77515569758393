import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { loadService } from '../../../../../api';
import { Typography } from '../../../../../components_v2/ui-kit/typography';
import { OptionType, UpdateLoadDetailsRequest } from '../../../../../models';
import { LoadReeferModeOptions } from '../../../../../subPages/loadsList/CreateLoad/constants/fieldOptions';
import { loadPriorityOptions } from '../../../../../subPages/loadsList/CreateLoad/schema';
import LoadController from '../../../../../subPages/loadsList/LoadController';
import { loadDetailsValidationRules } from '../../../../../subPages/loadsList/LoadDetailsPanel/constants/constants';
import ThreeDotMenuIcon from '../../../../../ui-kit/components/ThreeDotMenuIcon';
import FormDialog from '../../../../Ui/FormDialog';
import LoadDetailsForm from '../forms/loadDetailsForm';
import { LoadOverviewDetails } from '../models';
import { MenuOptions } from './LoadDetails';
import { SingleFieldInfo } from './singleFieldInfo';

export const LoadSubDetails = observer(
  ({
    load,
    onUpdated,
  }: {
    load: LoadOverviewDetails;
    onUpdated: () => void;
  }) => {
    const { loadDetails } = load;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const [editDialogOpen, setEditDialog] = useState(false);
    const [equipmentTypeOptions, setEquipmentTypeOptions] =
      useState<OptionType[]>();
    const handleMenuItemClick = (
      action: { icon: string; label: string },
      accountData: any,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      setEditDialog(true);
      handleMenuClose();
      if (action?.label !== 'Delete') return;
      // handleDelete(alert);
    };
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const getOptions = async () => {
      const loadStaticData = await LoadController.instance().getLoadStatic();
      setEquipmentTypeOptions(loadStaticData?.content);
    };
    const reeferSelected = loadDetails?.trailerTypes?.find(
      (item) => item.key === 'Reefer'
    );
    useEffect(() => {
      getOptions();
    }, []);
    const onUpdateLoadDetails = async (data: any) => {
      setEditDialog(false);
      const loadDetails = new UpdateLoadDetailsRequest({ ...data });

      const response = await loadService.updateLoadDetails(loadDetails);
      if (response) {
        onUpdated();
      }
    };

    return (
      <Box
        sx={{
          margin: '10px 0px',
          background: '#f5f5f5!important',
          padding: '16px',
          borderRadius: '10px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          marginBottom={'5px'}
        >
          <Typography
            variant="h7"
            sx={{
              color: 'primary.main',
            }}
          >
            Load Details
          </Typography>
          <ThreeDotMenuIcon
            styleProps={{ fontSize: '12px', padding: '0px' }}
            menuOptions={MenuOptions}
            open={Boolean(anchorEl)}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            anchorEl={anchorEl}
            handleMenuItemClick={handleMenuItemClick}
          />
        </Box>
        <Stack
          spacing={{ xs: 1, sm: 1 }}
          direction="row"
          useFlexGap
          sx={{ flexWrap: 'wrap', fontSize: '12px' }}
        >
          <div>
            <SingleFieldInfo
              label={t('loadOverViewLoadId')}
              value={load.seqNumber}
            />
            <span style={{ margin: '0 5px' }}>|</span>
          </div>
          {load?.loadDetails?.referenceId && (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewRef')}
                value={load?.loadDetails?.referenceId}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          )}
          {load?.purchaseOrderNumber && (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewPo')}
                value={load?.purchaseOrderNumber}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          )}
          {load?.bolNumber && (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewBol')}
                value={load?.bolNumber}
              />{' '}
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          )}
          {loadDetails?.loadPriority && (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewPriority')}
                value={
                  loadPriorityOptions?.filter(
                    (data) => data.value == loadDetails?.loadPriority
                  )?.[0]?.['label']
                }
              />{' '}
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          )}
          <div>
            <SingleFieldInfo
              label={t('loadOverViewBroker')}
              value={loadDetails?.canBeBrokered ? 'Yes' : 'No'}
            />{' '}
            <span style={{ margin: '0 5px' }}>|</span>
          </div>
          {loadDetails?.trailerTypes ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewEquipment')}
                value={
                  loadDetails?.trailerTypes
                    ? loadDetails?.trailerTypes
                        ?.map((types) => types?.value)
                        ?.join(', ')
                    : ''
                }
                styles={{ mt: '10px' }}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {loadDetails?.equipmentAdditionalDetails?.sealNumber ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewSeal')}
                value={loadDetails?.equipmentAdditionalDetails?.sealNumber}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            <></>
          )}
          {loadDetails?.equipmentAdditionalDetails?.chassisNumber ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewChassis')}
                value={loadDetails?.equipmentAdditionalDetails?.chassisNumber}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {loadDetails?.equipmentAdditionalDetails?.containerNumber ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewContainer')}
                value={loadDetails?.equipmentAdditionalDetails?.containerNumber}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {loadDetails?.equipmentAdditionalDetails?.loadDirection ? (
            <div>
              <SingleFieldInfo
                //need mapping
                label={t('loadOverViewImpExp')}
                value={loadDetails?.equipmentAdditionalDetails?.loadDirection}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {loadDetails?.equipmentAdditionalDetails?.bookingNumber ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewBooking')}
                value={loadDetails?.equipmentAdditionalDetails?.bookingNumber}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {loadDetails?.equipmentAdditionalDetails?.appointmentNumber ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewAppt')}
                value={
                  loadDetails?.equipmentAdditionalDetails?.appointmentNumber
                }
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {reeferSelected &&
          loadDetails?.equipmentAdditionalDetails?.reeferMode ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewRefMode')}
                value={
                  LoadReeferModeOptions?.filter(
                    (loadMode) =>
                      loadMode.id ==
                      loadDetails?.equipmentAdditionalDetails?.reeferMode
                  )?.[0]?.['label']
                }
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {loadDetails?.equipmentAdditionalDetails?.temperature ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewTemp')}
                value={loadDetails?.equipmentAdditionalDetails?.temperature}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}

          {loadDetails?.equipmentAdditionalDetails?.variance ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewSet')}
                value={loadDetails?.equipmentAdditionalDetails?.variance}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {loadDetails?.totalWeight ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewWeight')}
                value={loadDetails?.totalWeight}
              />
              <span style={{ margin: '0 5px' }}>|</span>
            </div>
          ) : (
            ''
          )}
          {load?.loadType ? (
            <div>
              <SingleFieldInfo
                label={t('loadOverViewLoadType')}
                value={load?.loadType == 'LTL' ? 'Partial' : load?.loadType}
              />
            </div>
          ) : (
            ''
          )}
        </Stack>

        {loadDetails?.notes ? (
          <SingleFieldInfo
            label={t('loadOverViewNotes')}
            value={loadDetails?.notes}
            styles={{ mt: '10px' }}
          />
        ) : (
          ''
        )}

        {editDialogOpen && (
          <FormDialog
            data={{
              ...loadDetails,
              ...loadDetails?.equipmentAdditionalDetails,
              // ...load,
              seqNumber: load?.seqNumber,
              purchaseOrderNumber: load?.purchaseOrderNumber,
              bolNumber: load?.bolNumber,
              loadType: load?.loadType,
              id: load.id,
            }}
            titleText={'Edit'}
            actionLabel={'Save'}
            open={editDialogOpen}
            onAction={onUpdateLoadDetails}
            disableActionBtnByDefault={true}
            isDisableResetBtn={true}
            handleClose={() => {
              setEditDialog(false);
            }}
            validationSchema={loadDetailsValidationRules}
            contentRenderer={() => (
              <LoadDetailsForm
                equipmentTypeOptions={equipmentTypeOptions ?? []}
                load={load}
              />
            )}
          ></FormDialog>
        )}
      </Box>
    );
  }
);
