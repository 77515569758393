import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../../../StorageManager/StorageManager';
import Accordion from '../../../../../common/Accordion';
import { AXELE_ROLE_TYPE } from '../../../../../common/Permission';
import { FormAddress } from '../../../../../common/Ui/AddressField';
import Checkbox from '../../../../../common/Ui/Checkbox';
import FileUploadUpdated from '../../../../../common/Ui/FileUpload/indexUpdated';
import FormPhoneField from '../../../../../common/Ui/FormPhoneField';
import TextField from '../../../../../common/Ui/TextField/TextField';
import { Typography } from '../../../../../components_v2/ui-kit/typography';
import { TerminalContentDTO } from '../../../../../models';
import { TerminalLogo } from '../../../terminals/components/TerminalLogo';

const defaultTextFieldProps = {
  sizes: {
    xs: 6,
  },
  style: { marginBottom: '16px' },
};
export const Overview = ({
  canEdit,
  isCreatePanel,
  terminalDTO,
}: {
  canEdit: boolean;
  isCreatePanel: boolean;
  terminalDTO?: TerminalContentDTO;
}) => {
  const { control, watch, setValue } = useFormContext();
  const userStorage = StorageManager.getUser();
  const formValues = watch();

  const documentId = watch('termsAndConditions')?.documentId;
  useEffect(() => {
    if (formValues.useSamePayableTo) {
      setValue(`payableToDetails.addressData`, formValues.addressData);
      setValue(`payableToDetails.name`, formValues.companyName);

      setValue(`payableToDetails.email`, formValues.email);
      setValue(`payableToDetails.phoneData`, formValues.phoneData);
    }
  }, [formValues.useSamePayableTo]);

  useEffect(() => {
    if (formValues.useSamePayableTo) {
      setValue(`payableToDetails.addressData`, formValues.addressData);
      setValue(`payableToDetails.name`, formValues.companyName);
      setValue(`payableToDetails.phoneData`, formValues.phoneData);

      setValue(`payableToDetails.email`, formValues.email);
    }
  }, [
    formValues.addressData,
    formValues.companyName,
    formValues.email,
    formValues.phoneData,
  ]);

  return (
    <Box className={'Overview-root'} sx={{ width: '100%' }}>
      {!isCreatePanel && terminalDTO && (
        <TerminalLogo terminalDTO={terminalDTO} />
      )}
      <Grid container>
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'Terminal Name'}
          name={'companyName'}
          required
          disabled={!canEdit}
          sizes={{ xs: 12 }}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'Contact at this Terminal'}
          name={'contactName'}
          disabled={!canEdit}
          sizes={{ xs: 12 }}
          required
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'DOT Number'}
          name={'dot'}
          onlyNumbers={true}
          disabled={!canEdit}
          sizes={{ xs: 6 }}
          required
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'MC Number'}
          name={'mc'}
          disabled={!canEdit}
          sizes={{ xs: 6 }}
        />

        <FormPhoneField
          control={control}
          name={`phoneData`}
          label="Phone"
          sizes={{ xs: 12 }}
          gridSxProp={{
            mt: 1,
            mb: 0,
          }}
          disabled={!canEdit}
          required
        />
        <FormPhoneField
          control={control}
          name={`faxData`}
          label="Fax"
          sizes={{ xs: 12 }}
          gridSxProp={{
            mt: 1,
            mb: 0,
          }}
          disabled={!canEdit}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'Email'}
          name={'email'}
          {...(isCreatePanel
            ? {
                disabled: !canEdit,
              }
            : {
                disabled: userStorage?.roleCode !== AXELE_ROLE_TYPE.ADMIN,
              })}
          sizes={{ xs: 12 }}
          required
        />
        <FormAddress
          isManual={true}
          control={control}
          name={'addressData'}
          sizes={{ xs: 12 }}
          disabled={!canEdit}
          style={{
            marginBottom: 20,
            paddingTop: 0,
          }}
          required
        />
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <FileUploadUpdated
            name={`termsAndConditions`}
            uploadBtnText="Add Terms and condition"
            fileId={documentId}
            control={control}
            accept={{ 'application/pdf': [] }}
            disabled={!canEdit}
          />
        </Grid>
        <Accordion
          detailsStyle={{ padding: '0px' }}
          summaryRenderer={() => (
            // @ISSUE: Vasil
            <Typography variant="h7" sx={{ color: 'primary.main' }}>
              {'Payable To'}
            </Typography>
          )}
        >
          <Checkbox
            // id={addLoadPageStopInfo + 'preLoaded'}
            name={`useSamePayableTo`}
            control={control}
            labelPlacement={'end'}
            label={'Same as terminal contact information'}
          />
          {!formValues.useSamePayableTo && (
            <>
              <TextField
                {...defaultTextFieldProps}
                control={control}
                label={'Name'}
                name={'payableToDetails.name'}
                required
                disabled={!canEdit}
                sizes={{ xs: 12 }}
              />
              <TextField
                {...defaultTextFieldProps}
                control={control}
                label={'Email'}
                name={'payableToDetails.email'}
                {...(isCreatePanel
                  ? {
                      disabled: !canEdit,
                    }
                  : {
                      disabled: userStorage?.roleCode !== AXELE_ROLE_TYPE.ADMIN,
                    })}
                sizes={{ xs: 12 }}
                required
              />
              <FormAddress
                isManual={true}
                control={control}
                name={'payableToDetails.addressData'}
                sizes={{ xs: 12 }}
                disabled={!canEdit}
                style={{
                  marginBottom: 20,
                  paddingTop: 0,
                }}
                required
              />
              <FormPhoneField
                control={control}
                name={`payableToDetails.phoneData`}
                label="Phone"
                sizes={{ xs: 12 }}
                gridSxProp={{
                  mt: 1,
                  mb: 0,
                }}
                disabled={!canEdit}
                required
              />
            </>
          )}
        </Accordion>
      </Grid>
    </Box>
  );
};
