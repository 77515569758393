import { Box, Checkbox, Grid, Link, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../../StorageManager/StorageManager';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import { Permission } from '../../../../common/Permission';
import SecondaryDetailsPanel from '../../../../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { LoadStatus } from '../../../../common/Ui/StatusComponent/StatusComponent';
import { UI_DATE_FORMAT } from '../../../../constants/date.constants';
import {
  ConnectedLoadType,
  PayStatementsEntityTypeEnum,
  PaymentDetails,
} from '../../../../models';
import { TractorIcon } from '../../../../static';
import { ComponentType } from '../../../../types';
import AxeleAccordion from '../../../../ui-kit/components/Accordion';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';
import {
  amountFormat,
  fromIsoToMoment,
  getFormattedDateTime,
} from '../../../../utils/index';
import { deletePopupConfig, paymentDeleteAction } from '../../constants';
import { isPayItemActionAllowed } from '../../utils/api.utils';
import { payStatementAddPermissions } from '../../utils/permissions';
import { TagList } from '@/common/Tag/components/TagList';

type propsType = {
  id: number;
  showCheckbox: boolean;
  disabledCheckbox?: boolean;
  entityType: PayStatementsEntityTypeEnum;
  onCheckboxChange: (
    checked: boolean,
    ids: number[],
    payment?: PaymentDetails,
    paymentListNmae?: string
  ) => void;
  onRemoveInReviewPayments?: (
    payment: PaymentDetails,
    isFuelPayment?: boolean
  ) => void;
  isShowPayTo?: boolean;
  isUpdated?: () => void;
};

function TripPaymentForm({
  id,
  showCheckbox,
  disabledCheckbox,
  entityType,
  onCheckboxChange,
  onRemoveInReviewPayments,
  isUpdated,
  isShowPayTo = false,
}: propsType) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [secondaryPanelData, setSecondaryPanelData] = useState<{
    type: keyof ComponentType;
    id: string | number;
    isGlobal?: boolean;
  } | null>(null);

  const { watch, setValue } = useFormContext();

  const [
    payment,
    pickupDate,
    pickupTimeZone,
    dropoffDate,
    dropoffTimeZone,
    tripPaymentAmount,
    revenue,
    payCategoryName,
    loadStatus,
    loadId,
    originCityStateList,
    destinationCityStateList,
    referenceNumber,
    isChecked,
    connectedLoads,
    tripDetails,
    payToEntityId,
    entityName,
  ] = watch([
    `tripPaymentsList.content.${id}`,
    `tripPaymentsList.content.${id}.tripDetails.pickupDate`,
    `tripPaymentsList.content.${id}.tripDetails.pickupTimeZone`,
    `tripPaymentsList.content.${id}.tripDetails.dropoffDate`,
    `tripPaymentsList.content.${id}.tripDetails.dropoffTimeZone`,
    `tripPaymentsList.content.${id}.amount`,
    `tripPaymentsList.content.${id}.tripDetails.revenue`,
    `tripPaymentsList.content.${id}.payCategoryName`,
    `tripPaymentsList.content.${id}.tripDetails.loadStatus`,
    `tripPaymentsList.content.${id}.loadId`,
    `tripPaymentsList.content.${id}.tripDetails.originCityStateList`,
    `tripPaymentsList.content.${id}.tripDetails.destinationCityStateList`,
    `tripPaymentsList.content.${id}.tripDetails.referenceNumber`,
    `tripPaymentsList.content.${id}.isChecked`,
    `tripPaymentsList.content.${id}.tripDetails.connectedLoads`,
    `tripPaymentsList.content.${id}.tripDetails`,
    `tripPaymentsList.content.${id}.payToEntityId`,
    `tripPaymentsList.content.${id}.entityName`,
  ]);

  const userStorageData = StorageManager.getUser();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorElement(null);
  };

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    data: any,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    if (action?.label !== 'Remove') return;
    setIsDeletePopupOpen(true);

    setAnchorElement(null);
  };

  const removePayment = async (): Promise<void> => {
    setIsDeletePopupOpen(false);
    onRemoveInReviewPayments?.(payment);
  };

  const paymentsSummaryRenderer = () => {
    return (
      <Grid
        container
        alignItems={'center'}
        spacing={0}
        direction="row"
        justifyContent={'space-between'}
        sx={{ height: '40px' }}
      >
        <Grid container alignItems={'center'} direction="row" spacing={0}>
          <Grid>
            <Typography variant="h7" sx={{ color: 'primary.main' }}>
              {amountFormat(tripPaymentAmount)}
            </Typography>
          </Grid>
          {isPayItemActionAllowed(entityType) && (
            <Grid>
              <Permission
                includes={payStatementAddPermissions[userStorageData?.roleCode]}
              >
                <ThreeDotMenuIcon
                  menuOptions={[{ id, ...paymentDeleteAction[entityType] }]}
                  open={!!anchorElement}
                  handleMenuClick={handleMenuClick}
                  handleMenuClose={handleMenuClose}
                  anchorEl={anchorElement}
                  handleMenuItemClick={handleMenuItemClick}
                />
              </Permission>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderPaymentRecipient = () => {
    if (!payToEntityId) return <></>;
    if (!tripDetails?.driverGroup?.name?.length)
      return (
        <Grid item xs={12}>
          <Box
            sx={{
              background: '#C5E2FC',
              padding: '8px',
              display: 'flex',
              borderRadius: '6px',
            }}
          >
            <Typography fontSize={12}>
              Tractor:{' '}
              <Box display={'inline-block'} position={'relative'} top="5px">
                <TractorIcon />
              </Box>{' '}
              <b style={{ textDecoration: 'underline', fontSize: 12 }}>
                {entityName}
              </b>
            </Typography>
          </Box>
        </Grid>
      );
    return (
      <Grid item xs={12}>
        <Box
          sx={{
            background: '#C5E2FC',
            padding: '8px',
            display: 'flex',
            borderRadius: '6px',
          }}
        >
          <Typography fontSize={12}>
            Driven by:{' '}
            <b style={{ textDecoration: 'underline' }}>
              {tripDetails?.driverGroup?.name}
            </b>
            , using tractor:{' '}
            <Box display={'inline-block'} position={'relative'} top="5px">
              <TractorIcon />
            </Box>{' '}
            <b style={{ textDecoration: 'underline', fontSize: 12 }}>
              {entityName}
            </b>
          </Typography>
        </Box>
      </Grid>
    );
  };

  const titleRenderer = () => {
    return (
      <>
        <Grid container>
          <Typography variant="h7" sx={{ color: 'text.primary' }}>
            Trip ID:
          </Typography>
          <Link
            variant="h7"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              whiteSpace: 'pre-wrap',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginLeft: '5px',
            }}
            onClick={(event) => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              event.stopPropagation();
              setSecondaryPanelData({
                type: 'TRIP',
                isGlobal: true,
                id: loadId,
              });
            }}
          >
            {payCategoryName}
          </Link>
        </Grid>

        <Grid xs={12}>
          <Typography variant="h8" sx={{ color: 'text.primary' }}>
            Pickup:
          </Typography>
          <Typography
            variant="body3"
            sx={{ color: 'text.secondary', marginLeft: '5px' }}
          >
            {`${
              originCityStateList ? originCityStateList + ' @' : ''
            } ${getFormattedDateTime(
              fromIsoToMoment(pickupDate, pickupTimeZone),
              UI_DATE_FORMAT
            )}`}
          </Typography>
        </Grid>

        <Grid>
          <Typography variant="h8" sx={{ color: 'text.primary' }}>
            Drop Off:
          </Typography>
          <Typography
            variant="body3"
            sx={{ color: 'text.secondary', marginLeft: '5px' }}
          >
            {`${
              destinationCityStateList ? destinationCityStateList + ' @' : ''
            } ${getFormattedDateTime(
              fromIsoToMoment(dropoffDate, dropoffTimeZone),
              UI_DATE_FORMAT
            )}`}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <Grid container sx={{ marginTop: '15px' }}>
      {showCheckbox && (
        <Grid item xs={1.3} sx={{ marginTop: '10px' }}>
          <Checkbox
            checked={isChecked}
            disabled={disabledCheckbox}
            onChange={(e) => {
              e.stopPropagation();
              setValue(
                `tripPaymentsList.content.${id}.isChecked`,
                e.target.checked
              );
              return onCheckboxChange(
                e.target.checked,
                [payment.id],
                payment,
                'tripPaymentsList'
              );
            }}
          />
        </Grid>
      )}
      <Grid item xs={showCheckbox ? 10.7 : 12}>
        <AxeleAccordion
          titleRenderer={() => titleRenderer()}
          renderLabel={() => paymentsSummaryRenderer()}
          renderCaption={renderPaymentRecipient}
        >
          {connectedLoads?.map((load: ConnectedLoadType, index: number) => (
            <Grid
              key={load.id}
              item
              container
              sx={{
                marginTop: index === 0 ? 0 : '16px',
                marginLeft: '20px',
                width: `calc(100% - ${20}px)`,
                padding: '18px',
                backgroundColor: (theme) => {
                  return `${theme.palette.uncategorized?.oldHeaderSecondary}`;
                },
                borderRadius: '8px',
              }}
            >
              <Grid
                item
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                xs={12}
              >
                <Grid item>
                  <Grid container>
                    <Typography variant="h7" sx={{ color: 'text.primary' }}>
                      Load:
                    </Typography>
                    <Link
                      variant="h7"
                      sx={{
                        color: 'primary.main',
                        textDecoration: 'underline',
                        whiteSpace: 'pre-wrap',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginLeft: '5px',
                      }}
                      onClick={(event) => {
                        if (DirtyDetailsPanelManager.isShouldPanelShake())
                          return;
                        event.stopPropagation();
                        setSecondaryPanelData({
                          type: 'LOAD',
                          isGlobal: true,
                          id: load.id,
                        });
                      }}
                    >
                      <Link
                        variant="h7"
                        sx={{
                          color: 'primary.main',
                          textDecoration: 'underline',
                          whiteSpace: 'pre-wrap',
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          marginLeft: '5px',
                        }}
                        onClick={(event) => {
                          if (DirtyDetailsPanelManager.isShouldPanelShake())
                            return;
                          event.stopPropagation();
                          setSecondaryPanelData({
                            type: 'LOAD',
                            isGlobal: true,
                            id: load.id,
                          });
                        }}
                      >
                        {load.seqNumber}
                      </Link>
                    </Link>
                  </Grid>
                </Grid>
                <Grid item>
                  <LoadStatus status={load.loadStatus} type="LOAD" />
                </Grid>
              </Grid>

              <Stack
                direction="row"
                flexWrap="wrap"
                gap={1}
                sx={{ mt: '5px', mb: '5px' }}
              >
                <TagList tags={load.tags} />
              </Stack>

              <Grid
                item
                container
                justifyContent={'space-between'}
                xs={12}
                sx={{ mt: 3 }}
              >
                <Grid item xs={4}>
                  <Grid>
                    <Typography color={'text.secondary'} variant={'h8'}>
                      Customer
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Typography color={'text.primary'} variant={'inputText'}>
                      {load.customerName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid>
                    <Typography color={'text.secondary'} variant={'h8'}>
                      Ref#
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Typography color={'text.primary'} variant={'inputText'}>
                      {load.referenceId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid>
                    <Typography color={'text.secondary'} variant={'h8'}>
                      {t('invoiceTotal')}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Typography color={'text.primary'} variant={'inputText'}>
                      {amountFormat(load.revenue) || ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </AxeleAccordion>
      </Grid>
      {isDeletePopupOpen && (
        <DeletePopup
          open={!!isDeletePopupOpen}
          title={deletePopupConfig.inReviewStatement.title}
          body={deletePopupConfig.inReviewStatement.body}
          subtitle={deletePopupConfig.inReviewStatement.subtitle}
          onAction={removePayment}
          onClose={() => setIsDeletePopupOpen(false)}
          buttonText={deletePopupConfig.inReviewStatement.buttonText}
          width="450px"
        />
      )}
      {secondaryPanelData && (
        <SecondaryDetailsPanel
          type={secondaryPanelData.type}
          isGlobal={secondaryPanelData.isGlobal}
          id={secondaryPanelData.id}
          onClose={() => {
            setSecondaryPanelData(null);
            isUpdated?.();
          }}
        />
      )}
    </Grid>
  );
}

export default memo(TripPaymentForm);
