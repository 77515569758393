import { unitValueLabelMap } from './constants';
import {
  ManageTypesItem,
  TagItem,
  UnitType,
} from '../../../models/DTOs/ManageTypes/Requests';
import EncryptedStorageManager from '../../../StorageManager/EncryptedStorageManager';

export const getCurrentUserName = () =>
  `${EncryptedStorageManager.getItem('user').firstname} ${
    EncryptedStorageManager.getItem('user').lastname
  }`;

export const setUnitDataForRequest = (units: UnitType[] | null | undefined) => {
  return (units || []).map((unit: UnitType) => unit.value);
};

export const getUnitDataFromRequest = (units: string[] | null | undefined) => {
  return (units || []).map((unit: string) => ({
    value: unit,
    label: unitValueLabelMap[unit],
  }));
};

export const getIsItemNameIsUnique = (
  manageTypesList: ManageTypesItem[],
  itemName: string
) => {
  const matchNames = manageTypesList.map((manageType) => manageType.itemName);
  return (
    matchNames.filter(
      (name) =>
        name.toLocaleLowerCase().trim() === itemName.toLocaleLowerCase().trim()
    ).length > 0
  );
};

export const getIsTagNameIsUnique = (tags: TagItem[], itemName: string) => {
  const matchNames = tags.map((tag) => tag.name);
  return (
    matchNames.filter(
      (name) =>
        name.toLocaleLowerCase().trim() === itemName.toLocaleLowerCase().trim()
    ).length === 0
  );
};
