import React from 'react';

function DTIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      fill="none"
      viewBox="0 0 18 14"
      className='svgIcon'
    >
      <path
        fill="#fff"
        d="M8.642 5.65c.3.3.45.717.45 1.258 0 .542-.15.959-.45 1.25-.3.292-.717.442-1.259.442h-.675V5.2h.675c.542 0 .959.15 1.259.45zm8.691-3.317V11.5c0 .917-.75 1.667-1.666 1.667H2.333c-.916 0-1.666-.75-1.666-1.667V2.333c0-.916.75-1.666 1.666-1.666h13.334a1.66 1.66 0 011.666 1.666zM2.35 3.775l.167 3.992h1.208l.167-3.992H2.35zm1.633 5.358a.737.737 0 00-.241-.558.838.838 0 00-.6-.225.865.865 0 00-.617.225.73.73 0 00-.233.558c0 .217.075.4.233.55a.897.897 0 00.617.217.869.869 0 00.6-.217.704.704 0 00.241-.55zm6.559-2.225c0-.575-.125-1.083-.384-1.525A2.602 2.602 0 009.092 4.35c-.459-.242-1-.367-1.617-.367H5.283v5.85h2.192c.608 0 1.15-.125 1.608-.366a2.573 2.573 0 001.075-1.034c.259-.45.384-.958.384-1.525zm5-2.925h-4.525v1.142h1.55v4.708h1.425V5.125h1.55V3.983z"
      />
    </svg>
  );
}

export default DTIcon;
