import { DateRange } from '@mui/x-date-pickers-pro-v5';
import moment, { Moment, MomentInput } from 'moment';

export const isValidMomentObject = (dateInput: Moment): boolean => {
  return dateInput.isValid();
};

export const isValidDateRangeMomentObject = (
  newValue: DateRange<Moment>
): boolean => {
  const results: boolean[] = [];
  if (newValue[0]) {
    results.push(isValidMomentObject(newValue[0]));
  }
  if (newValue[1]) {
    results.push(isValidMomentObject(newValue[1]));
  }
  return !results.includes(false);
};

export const convertToDateObject = (dateInput: MomentInput): Date | null => {
  if (dateInput === null) {
    return null;
  }
  if (dateInput instanceof Date) {
    return dateInput;
  }

  if (moment.isMoment(dateInput)) {
    return dateInput.toDate();
  }

  if (typeof dateInput === 'string') {
    return new Date(dateInput);
  }

  if (typeof dateInput === 'number') {
    return new Date(dateInput);
  }
  throw Error('Invalid input');
};

export const convertToMomentObject = (
  dateInput: MomentInput
): Moment | null => {
  if (dateInput === null) {
    return null;
  }
  if (dateInput instanceof Date) {
    return moment(dateInput);
  }

  if (moment.isMoment(dateInput)) {
    return dateInput;
  }

  if (typeof dateInput === 'string') {
    return moment(dateInput);
  }
  if (typeof dateInput === 'number') {
    return moment(dateInput);
  }
  throw Error('Invalid input');
};

export const getFormatedDate = (date?: DateRange<Moment>): DateRange<Date> => {
  if (!date) return [null, null];
  const formated = [
    date[0] ? convertToDateObject(date[0]) : null,
    date[1] ? convertToDateObject(date[1]) : null,
  ];

  return formated as DateRange<Date>;
};

export const formatDate = (utcDate: string) => {
  const localTime = new Date(utcDate);

  const formattedDate = `${(localTime.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${localTime
    .getDate()
    .toString()
    .padStart(2, '0')}/${localTime.getFullYear()}`;

  return formattedDate;
};
