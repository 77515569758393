import { Type } from 'class-transformer';
import { TripStopStatusEnum } from '../../../ui-kit/components/TripPlan';

import { Moment } from 'moment';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { relayStopToSplitPayload } from '../../../common/LoadTabPanel/tabs/Routes/utils';

import { LoadMetaDataDetails } from '../../../common/LoadTabPanel/tabs/Overview/models';
import StorageManager from '../../../StorageManager/StorageManager';
import {
  LineItem,
  ReceivePayment,
} from '../../../subPages/invoices/models/InvoiceModel';
import { revertLoadUserDefinedItemsTypes } from '../../../subPages/invoices/utils';
import { LoadPriorityEnum } from '../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import { ReeferModeEnum } from '../../../subPages/loadsList/CreateLoad/schema/load';
import { AppointmentConfirmationEnum } from '../../../subPages/loadsList/CreateLoad/types/requests';
import {
  CenterDTO,
  Commodity,
  EquipmentType,
  LoadDetailsSummary,
  Stop as StopModel,
} from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { ICoordinates } from '../../../views/loadboards/loadTypes';
import { OptionType, QueryParams } from '../commonMixed';
import { Payload } from '../commonMixed/Payload';
import { AddressDTO } from '../locations/Location';

export enum AssignScenarioType {
  DRIVER = 'ASSIGN_DRIVER_TO_LOAD',
  TRACTOR = 'ASSIGN_TRACTOR_TO_LOAD',
  TRAILER = 'ASSIGN_TRAILER_TO_LOAD',
}

// AXL2-6116: PaginatedLoadListRequest is DEPRECATED (usages should be reviewed)
export class PaginatedLoadListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  loadIdList!: number[];
  sort!: string;
  terminalIds!: string[];
  seqNumber?: string;
  driverIdsFilter?: number[];
  loadStatusList?: OptionType[];
  loadSettlementStatusList?: OptionType[]; // should be checked
  pickupFromDate?: string;
  pickupToDate?: string;
  gridColumnMetadataList?: string[];
  referenceIds?: string[];
  customerIdsFilter?: string[];
  equipmentTypes?: string[];
  dispatcherIdsFilter?: string[];
  chassisNumbers?: string[];
  containerNumbers?: string[];
  bookingNumber?: string[];
  appointmentNumber?: string[];
  loadDirection?: string[];
  originCityStateList?: string[];
  destinationCityStateList?: string[];
  loadIdsFilter?: string[];
  manifestIdsFilter?: string[];
  hasViolation?: boolean;
  calculateDetention?: boolean;
  needToAssign?: boolean;
  tractorIds?: []; // should be removed
  trailerIds?: []; // should be removed
  operationMode?: string[]; // should be removed
  loadIsInvoiceable?: boolean;
  appointmentToDate?: string;
  appointmentFromDate?: string;
  actualAppointmentToDate?: string;
  actualDropOffFromDate?: string;
  tractorIdsFilter?: string[];
  trailerIdsFilter?: string[];
  tripIdsFilter?: string[];
  dispatcherNameFilter?: string[];
  originStateList?: string[];
  destinationStateList?: string[];
  tagIds?: any[];
  locationNameList?: string[];
  loadType?: string[] | string;
}

export class PaginatedLoadListRequestNew extends Payload {
  loadIdList!: number[];
  terminalIds!: string[];
  seqNumber?: string;
  driverIdsFilter?: number[];
  loadStatusList?: OptionType[];
  loadSettlementStatusList?: OptionType[]; // should be checked
  pickupFromDate?: string;
  pickupToDate?: string;
  actualPickupFromDate?: string;
  actualPickupToDate?: string;
  lastFreeDayFromDate?: string;
  lastFreeDayToDate?: string;
  referenceIds?: string[];
  customerIdsFilter?: string[];
  equipmentTypes?: string[];
  dispatcherIdsFilter?: string[];
  chassisNumbers?: string[];
  containerNumbers?: string[];
  bookingNumbers?: string[];
  appointmentNumbers?: string[];
  loadDirections?: string[];
  originCityStateList?: string[];
  destinationCityStateList?: string[];
  loadIdsFilter?: string[];
  hasViolation?: boolean;
  calculateDetention?: boolean;
  needToAssign?: boolean;
  tractorIds?: []; // should be removed
  trailerIds?: []; // should be removed
  operationMode?: string[]; // should be removed
  loadIsInvoiceable?: boolean;
  appointmentToDate?: string;
  appointmentFromDate?: string;
  actualDropOffToDate?: string;
  actualDropOffFromDate?: string;
  tractorIdsFilter?: string[];
  trailerIdsFilter?: string[];
  tripIdsFilter?: string[];
  dispatcherNameFilter?: string[];
  originStateList?: string[];
  destinationStateList?: string[];
  commodityTypes?: string[];
  dispatchNotes?: string;
  loadTypes?: string;
  isRelay?: boolean;
  manifestIdsFilter?: string[];
  carrierIdsFilter?: string[];
  hasBrokerageTrip?: boolean | null;
  onHoldState?: boolean | null;
  tagIds?: any[] | null;
  locationNameList?: string[];
}

export class PaginatedLoadListQueryParams extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  gridColumnMetadataList?: any[];
}

export class PaginatedTagsListQueryParams extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  searchText?: string;
  isActive?: boolean;
}
export class ITagsAssignParamsRequest extends QueryParams {
  loadId!: number;
}

export class ITagsBulkAssignParamsRequest extends QueryParams {
  overwrite!: boolean;
}

// @TODO: Nvard/Davit - there is inconsistnecy between axele2 contact and axele1 contact models ( like fullName and firstname,lastname)
class Contact {
  id?: string;
  countryCode: string;
  email: string;
  extension: string;
  firstname: string;
  lastname: string;
  phone: string;
  fullName?: string;

  constructor(dto: any) {
    this.countryCode = dto.phoneData?.countryCode || '';
    this.phone = dto.phoneData?.phone || '';
    this.extension = dto.phoneData?.extension || '';
    this.email = dto.email || '';
    this.firstname = dto.fullName || '';
    this.lastname = '';
  }
}

class StopLocationContact extends Contact {
  isPrimary: boolean;

  constructor(dto: any) {
    super(dto);
    this.isPrimary = dto.isPrimary;
  }
}

//@TODO: Nvard - check streeAddress2
export class StopLocation {
  id: number | null;
  address: string;
  fullAddress: string; // @TODO: Nvard - AXL2-2109 - check in create load
  avgHandlingTime: number;
  @Type(() => CenterDTO)
  center: CenterDTO;
  city: string;
  @Type(() => StopLocationContact)
  contacts: StopLocationContact[];
  lat: number | null;
  lng: number | null;
  locationName: string;
  notes: string | null;
  state: string;
  streetAddress: string;
  streetAddress2: string; // @TODO: Nvard - AXL2-2109 - check in create load
  timezone: string | null;
  zipcode: string;
  geoCoordinates?: ICoordinates;

  constructor(dto: any) {
    this.id = dto.id;
    this.address = dto.address;
    this.fullAddress = dto.fullAddress;
    this.avgHandlingTime = dto.avgHandlingTime;
    this.center = dto.addressDTO?.center || dto.center;
    this.lat = dto.addressDTO?.center?.lat || dto.center?.lat || null;
    this.lng = dto.addressDTO?.center?.lng || dto.center?.lng || null;
    this.locationName = dto.locationName;
    this.notes = dto.notes;
    this.city = dto.city;
    this.state = dto.state;
    this.streetAddress = dto.streetAddress;
    this.streetAddress2 = dto.streetAddress2;
    this.timezone = dto.timezone;
    this.zipcode = dto.zipcode;
    this.geoCoordinates = dto.geoCoordinates || [];
    this.contacts = // @TODO: Nvard please check also this contact dto, seems there are many inconsistencies with the BE, this is just a workaround
      dto.contacts?.map((contactData) => {
        const contact = new StopLocationContact(contactData);
        // @TODO: Nvard/Davit/Arshak review these fields
        contact.id = undefined;
        contact.fullName = undefined;
        contact.phoneData = undefined;
        contact.description = undefined;
        return contact;
      }) || [];
  }
}

export class LoadStopContact {
  firstname!: string;
  lastname!: string;
  phone!: number;
  extension!: number;
  email!: string;
  countryCode!: string;
  alerts!: Array<string>;
  sendEmailAlert!: boolean;
  sendPhoneAlert!: boolean;
}

export class LoadStop {
  id: number;
  activityType: string;
  appointmentStartDate: string;
  appointmentEndDate: string;
  fixedAppointment: boolean;
  isDriverAssistRequired: boolean;
  estimatedActivityDuration: number;
  refNumber: string;
  notes: string;
  sequenceNumber: number;
  @Type(() => LoadStopContact)
  contact: LoadStopContact;
  @Type(() => StopLocation)
  location: StopLocation;
  @Type(() => NoteDetails)
  noteDetails: NoteDetails | null;
  appointmentConfirmation?: AppointmentConfirmationEnum;
  preLoaded?: boolean;
  dropTrailer?: boolean;
  // firstStop?: boolean;
  // lastStop?: boolean;
  constructor(data: StopModel) {
    this.id = data.id;
    this.activityType = data.activityType;
    this.appointmentStartDate = data.appointmentStartDate;
    this.appointmentEndDate = data.appointmentEndDate;
    this.fixedAppointment = data.fixedAppointment;
    this.isDriverAssistRequired = data.isDriverAssistRequired;
    this.estimatedActivityDuration = data.avgHandlingTime;
    this.refNumber = data.refNumber;
    this.notes = data.notes;
    this.noteDetails = data.noteDetails || null;
    this.sequenceNumber = data.sequenceNumber;
    this.location = new StopLocation({ ...data.location, ...data.addressDTO });
    // @TODO: Nvard/DAvit VAsilyan - contact models in axele1 and axele2 are not consistent
    this.contact = {
      firstname: data.contact?.fullName,
      lastname: '',
      email: data.contact?.email,
      ...data.contact?.phoneData,
    } as LoadStopContact;
    this.appointmentConfirmation =
      data?.appointmentConfirmation ?? AppointmentConfirmationEnum.NO;
    this.preLoaded = data.preLoaded;
    this.dropTrailer = data.dropTrailer;
    // this.firstStop = data?.firstStop;
    // this.lastStop = data?.lastStop;
  }
}

export class ResequenceLoadStopRequest extends QueryParams {
  loadId: string | null;
  loadStopId: number | null;
  moveUp: boolean;
  applyDeletion: boolean;

  constructor(data: {
    loadId: string;
    loadStopId: number | null;
    moveUp: boolean;
    applyDeletion: boolean;
  }) {
    super();
    this.loadId = data.loadId;
    this.loadStopId = data.loadStopId;
    this.moveUp = data.moveUp;
    this.applyDeletion = data.applyDeletion;
  }
}

export class UpSertLoadStopRequest {
  loadId: string | null;
  @Type(() => LoadStop)
  stopDTO: LoadStop;
  organizationId: number;

  constructor(data: { loadId: string | null; stop: StopModel }) {
    this.loadId = data.loadId;
    this.stopDTO = new LoadStop(data.stop);
    const userStorage = StorageManager.getUser() || {};
    this.organizationId = userStorage.organizationId;
  }
}

export class UpdateLoadStopRequest extends UpSertLoadStopRequest {}

export class CreateLoadStopRequest extends UpSertLoadStopRequest {
  previousTripStopId?: number;
  tripId: string;

  constructor(data: {
    loadId: string;
    stop: StopModel;
    previousTripStopId?: number | null;
  }) {
    super({ loadId: data.loadId, stop: data.stop });
    this.tripId = data.tripId;
    this.stopDTO.id = undefined; // @TODO: Nvard, please have a look on this class, in the case of create it should not have id.
    if (data.previousTripStopId) {
      this.previousTripStopId = data.previousTripStopId;
    }
  }
}

export class DeleteLoadStopRequest extends QueryParams {
  loadId: string;
  stopId: number;

  constructor(data: { loadId: string; stopId: number }) {
    super();
    this.loadId = data.loadId;
    this.stopId = data.stopId;
  }
}

export class ValidateLoadReferenceIdRequest extends QueryParams {
  loadCategory?: string;
  referenceId: string;
  customerId: number;

  constructor({
    loadCategory = 'LOAD',
    customerId,
    referenceId,
  }: {
    loadCategory: string;
    customerId: number;
    referenceId: string;
  }) {
    super();
    this.loadCategory = loadCategory;
    this.referenceId = referenceId;
    this.customerId = customerId;
  }
}

export class LoadCommodity {
  commodityTypeId: string;
  commodityType: string;
  itemName: string;
  description: string;
  floorLoad: boolean;
  height: string;
  length: string;
  width: string;
  packageType: string;
  qty: number;
  stackable: boolean;
  volume: string;
  weight: string;
  id: string;

  constructor(data: any) {
    this.commodityTypeId = data.commodityTypeId;
    this.commodityType = data.commodityType;
    this.itemName = data.commodityTypeDisplayValue;
    this.description = data.description;
    this.floorLoad = data.floorLoad;
    this.height = data.height;
    this.length = data.length;
    this.width = data.width;
    this.packageType = data.packageType;
    this.qty = data.qty;
    this.stackable = data.stackable;
    this.volume = data.volume;
    this.id = data.id;
    this.weight = data.weight;
  }
}

//@TODO: Nvard/Gevorg sending only these fields
class Invoice {
  notes!: string;
  noteDetails!: NoteDetails | null;
  @Type(() => LineItem)
  lineItems!: LineItem[];
  @Type(() => ReceivePayment)
  receivedPayments!: ReceivePayment[];
  invoiceTerm!: string;
  invoiceTermValue!: number | null;
}

export class NoteDetails {
  userId!: number;
  updateTime!: Moment | string;
}

export class EquipmentAdditionalDetails {
  chassisNumber!: string | null;
  containerNumber!: string | null;
  sealNumber!: string | null;
  lowerLimitTemperature!: string | null;
  upperLimitTemperature!: string | null;
  loadDirection!: string | null;
  bookingNumber!: string | null;
  appointmentNumber!: string | null;
}

export class LoadDetails {
  referenceId!: string | null;
  trailerTypes!: string[];
  commodityData!: LoadCommodity[] | null;
  // @TODO: notes and noteDetails will be refactored later both in backend and frontend
  notes!: string | null;
  noteDetails!: NoteDetails;
  equipmentAdditionalDetails!: EquipmentAdditionalDetails;
  totalWeight!: number | null;
  useFactoring!: boolean;
  factoringId!: string | null;
  factoringName!: string;
  factoringNotes!: string | null;
  factoringChargePercent!: number | null;
}

export class Stop {
  id!: number;
  stopStatus!: string;
  refNumber!: string;
  activityType!: string;
  isDriverAssistRequired!: boolean;
  appointmentStartDate!: string;
  appointmentEndDate!: string;
  fixedAppointment!: boolean;
  estimatedActivityDuration!: number;
  notes!: string;
  noteDetails?: NoteDetails | null;
  location!: StopLocation;
  contact!: Contact | null;
  geoCoordinates?: ICoordinates;
  addressDTO?: AddressDTO;
  avgHandlingTime?: number;
  appointmentConfirmation?: AppointmentConfirmationEnum;
  sequenceNumber?: string;
  preLoaded?: boolean;
  dropTrailer?: boolean;
  firstStop?: boolean;
  lastStop?: boolean;
}

export class CreateLoadStop {
  relayStop!: RelayStop | null;
  loadStop!: Stop | null;
}

export class CreateLoadRequest extends QueryParams {
  customerContactId: number | null;
  customerId: number | null;
  terminalId: string | null;
  invoice: Invoice;
  billOfLadingMandatory: boolean;
  proofOfDeliveryMandatory: boolean;
  loadCategory: string;
  loadDetails: LoadDetails;
  loadStatus: string;
  loadType: string;
  noteDetails?: NoteDetails | null;
  stops: CreateLoadStop[] | [];

  constructor(data: LoadDetailsSummary) {
    super();
    const {
      customer,
      contact,
      loadCategory,
      loadStatus,
      factoring,
      referenceId,
      factoringNotes,
      factoringChargePercent,
      notes,
      noteDetails,
      invoiceTerm,
      invoiceTermValue,
      invoiceNotes,
      invoiceNoteDetails,
      lineItems,
      receivedPayments,
      equipmentTypes,
      stops,
      sealNumber,
      chassisNumber,
      containerNumber,
      lowerLimitTemperature,
      upperLimitTemperature,
      terminal,
      loadType,
      commodityData,
      totalWeight,
      billOfLadingMandatory,
      proofOfDeliveryMandatory,
      bookingNumber,
      appointmentNumber,
      loadDirection,
    } = data;
    this.customerContactId = contact?.id || null;
    this.customerId = customer?.id || null;
    this.loadCategory = loadCategory;
    this.loadStatus = loadStatus;
    this.loadType = loadType;
    this.billOfLadingMandatory = billOfLadingMandatory;
    this.proofOfDeliveryMandatory = proofOfDeliveryMandatory;

    const invoice = new Invoice();
    invoice.notes = invoiceNotes;
    if (invoiceNoteDetails) {
      invoice.noteDetails = invoiceNoteDetails;
    }
    invoice.lineItems = revertLoadUserDefinedItemsTypes(lineItems);
    invoice.receivedPayments = receivedPayments;
    invoice.invoiceTerm = invoiceTerm || 'DUE_ON_RECEIPT';
    invoice.invoiceTermValue = invoiceTermValue;
    this.invoice = invoice;

    const loadDetails = new LoadDetails();
    loadDetails.factoringId = factoring?.id || null;
    loadDetails.factoringName = factoring?.displayName || '';
    loadDetails.factoringNotes = factoringNotes;
    loadDetails.factoringChargePercent = factoringChargePercent;
    loadDetails.notes = notes;
    if (noteDetails) {
      loadDetails.noteDetails = noteDetails;
    }
    loadDetails.referenceId = referenceId;
    loadDetails.useFactoring = true;
    loadDetails.totalWeight = totalWeight;
    loadDetails.commodityData =
      commodityData?.map((ct: Commodity) => new LoadCommodity(ct)) || null;
    loadDetails.trailerTypes = equipmentTypes?.map(
      (item: EquipmentType) => item.key
    ) || ['Van'];
    const equipmentAdditionalDetails = new EquipmentAdditionalDetails();
    equipmentAdditionalDetails.chassisNumber = chassisNumber;
    equipmentAdditionalDetails.containerNumber = containerNumber;
    equipmentAdditionalDetails.sealNumber = sealNumber;
    equipmentAdditionalDetails.lowerLimitTemperature = lowerLimitTemperature;
    equipmentAdditionalDetails.upperLimitTemperature = upperLimitTemperature;
    equipmentAdditionalDetails.loadDirection = loadDirection
      ? loadDirection
      : null;
    equipmentAdditionalDetails.appointmentNumber = appointmentNumber
      ? appointmentNumber
      : '';
    equipmentAdditionalDetails.bookingNumber = bookingNumber
      ? bookingNumber
      : '';
    loadDetails.equipmentAdditionalDetails = equipmentAdditionalDetails;
    this.loadDetails = loadDetails;

    this.terminalId = terminal?.id || null;
    // @TODO: Nvard - stops dto is not complete with axele1
    const newStops: Stop[] = [];
    stops?.forEach((stopData) => {
      const stop = new CreateLoadStop();
      if (stopData.stopType !== 'RELAY') {
        // @TODO: AXL2-5440 - refactor Stop model
        stop.relayStop = null;
        const loadStop = new Stop();
        loadStop.id = stopData.id;
        loadStop.stopStatus = stopData.stopStatus || 'NONE';
        loadStop.activityType = stopData.activityType || null;
        loadStop.appointmentStartDate = stopData.appointmentStartDate;
        loadStop.appointmentEndDate = stopData.appointmentEndDate;
        loadStop.estimatedActivityDuration = stopData.avgHandlingTime;
        loadStop.fixedAppointment = stopData.fixedAppointment;
        loadStop.isDriverAssistRequired = stopData.isDriverAssistRequired;
        loadStop.notes = stopData.notes;
        loadStop.noteDetails = stopData.noteDetails;
        loadStop.refNumber = stopData.refNumber;
        loadStop.location = new StopLocation({
          ...stopData.location,
          ...stopData.addressDTO,
        });
        loadStop.contact = new Contact(stopData.contact || {});
        stop.loadStop = loadStop;
      } else {
        const relayStop = new RelayStop(relayStopToSplitPayload(stopData));
        delete relayStop.organizationId;
        delete relayStop.connectionPoint.organizationId;
        delete relayStop.legStartStopId;
        stop.relayStop = relayStop;
        stop.loadStop = null;
      }

      newStops.push(stop);
    });
    this.stops = newStops;
  }
}

export class LoadWarningByIdsRequest extends QueryParams {
  ids: string[];

  constructor(ids: string[]) {
    super();
    this.ids = ids;
  }
}

export class GetLoadDriverGroupWarnings extends QueryParams {
  driverGroupId?: string;
}

export class ByLoadIdRequest extends QueryParams {
  loadId?: string;
  manifestId?: string;
}
export class ByLoadIdOnlyRequest extends QueryParams {
  loadId: string;

  constructor(id: string) {
    super();
    this.loadId = id;
  }
}
export class GetManifestPreviewRequest extends QueryParams {
  loadIds!: string[];
  organizationId!: number;
  manifestId!: string[];
  // constructor(dto: any) {
  //   super();
  //   Object.assign(this, dto);
  // }
}

export class GetDriverInfoForLoadAssignRequest extends QueryParams {
  loadId!: string;
  driverId!: string;
  loadStatus!: string;
  prevLoadId!: string;
}

export class GetTractorInfoForLoadAssignRequest extends QueryParams {
  loadId!: string;
  tractorId!: string;
  terminalIds?: string[];
}

export class GetDispatcherInfoForLoadAssignRequest extends QueryParams {
  loadId!: string;
  dispatcherId!: string;
}

export class AssignTractorToLoadRequest extends Payload {
  loadId!: string;
  tractorId!: string;

  constructor() {
    super(true);
  }
}

export class AssignTrailerToLoadRequest extends Payload {
  loadId!: string;
  trailerId!: number;

  constructor() {
    super(true);
  }
}

export class AssignDispatcherToLoadRequest extends Payload {
  loadId!: string;
  dispatcherId!: string;

  constructor() {
    super(true);
  }
}

export class UnAssignDriverFromLoadRequest extends QueryParams {
  tripId!: string;

  constructor() {
    super(true);
  }
}

export class AssignDriverToLoadRequest extends Payload {
  loadId!: string;
  groupId!: string;
  autoPositioning!: boolean;
  loadAssignmentStatus!: string;
  terminalId!: string | undefined;
  prevLoadId!: string;

  constructor(dto) {
    super(true);
    Object.assign(this, dto);
  }
}

export class GetPrevLoadsRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  seqNumber!: string;
  driverGroupId!: string;
  loadStatus!: string;

  constructor(dto) {
    super(true);
    Object.assign(this, dto);
  }
}

export class WarningsForLoadAssignRequest extends Payload {
  driverGroupId?: string;
  loadId!: string;
  scenarioType!: AssignScenarioType;
  tractorId?: number;
  trailerId?: number;
  warningTypes?: string[];

  constructor() {
    super(true);
  }
}

export class GetLoadSummaryListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  seqNumber!: string;
  loadStatusList!: string[];
  operationTypeList!: string[];
  terminalIds!: string[];
  loadType!: string;
}
export class GetInvoiceSummaryListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  seqNumber!: string;
  statusList!: string[];
  operationTypeList!: string[];
  terminalIds!: string[];
  loadType!: string;
}

export class GetLoadAdditionalDetailsRequest extends QueryParams {
  loadId: string;

  constructor(data: any) {
    super();
    this.loadId = data.loadId;
  }
}

export class FindLoadDetailsRequest extends QueryParams {
  loadId: string;

  constructor(data: any) {
    super();
    this.loadId = data.loadId;
  }
}

export class GetLoadOverviewDetailsRequest extends QueryParams {
  loadId: string;

  constructor(data: any) {
    super();
    this.loadId = data.loadId;
  }
}

export class UpdateLoadReferenceDetailsRequest extends QueryParams {
  id: string;
  referenceId: string;
  seqNumber: string;
  terminalId: string;
  dispatchRevenue: number;
  equipmentTypes: string;
  loadPriority?: LoadPriorityEnum.None;
  canBeBrokered?: false;
  constructor(data: any) {
    super();
    this.id = data.id || '';
    this.referenceId = data.referenceId || '';
    this.seqNumber = data.seqNumber || '';
    this.terminalId = data.invoiceTerminal?.id;
    this.dispatchRevenue = data.dispatchRevenue;
    this.equipmentTypes = data.equipmentTypes;
    this.loadPriority = data?.loadPriority;
    this.canBeBrokered = data?.canBeBrokered;
  }
}

export class UpdateLoadAdditionalDetailsRequest extends QueryParams {
  id: string;
  loadStatus: string;
  loadType: string;
  loadDetails: {
    notes: string;
    noteDetails: NoteDetails | null;
    totalWeight: string;
    commodityData: LoadCommodity[];
    trailerTypes: string[];
    equipmentAdditionalDetails: {
      chassisNumber: string;
      containerNumber: string;
      sealNumber: string;
      lowerLimitTemperature: string;
      upperLimitTemperature: string;
      bookingNumber?: string;
      appointmentNumber?: string;
      loadDirection?: string;
      temperature?: number;
      variance?: number;
      reeferMode?: ReeferModeEnum;
    };
    metaDataDetails: {
      mode?: string;
      serviceType?: string;
      steamShipLine?: string;
      yardLocation?: string;
      containerTerminal?: string;
      holdCategory?: string;
      holdType?: string;
      perdiemStartDate?: string;
      fullOutgateDate?: string;
      lastFreeDay?: string;
      inPin?: string;
      outPin?: string;
    };
  };

  constructor(data: any) {
    super();
    const {
      id,
      loadStatus,
      loadType,
      notes,
      noteDetails,
      totalWeight,
      chassisNumber,
      containerNumber,
      bookingNumber,
      appointmentNumber,
      loadDirection,
      sealNumber,
      lowerLimitTemperature,
      upperLimitTemperature,
      commodityData,
      equipmentTypes,
      temperature,
      variance,
      reeferMode,
      mode,
      serviceType,
      steamShipLine,
      yardLocation,
      containerTerminal,
      holdCategory,
      holdType,
      perdiemStartDate,
      fullOutgateDate,
      lastFreeDay,
      inPin,
      outPin,
    } = data;
    this.id = id;
    this.loadStatus = loadStatus;
    this.loadType = loadType;
    this.loadDetails = {
      notes,
      noteDetails,
      totalWeight,
      equipmentAdditionalDetails: {
        chassisNumber,
        containerNumber,
        bookingNumber,
        appointmentNumber,
        loadDirection,
        sealNumber,
        lowerLimitTemperature,
        upperLimitTemperature,
        temperature,
        variance,
        reeferMode,
      },
      metaDataDetails: {
        mode,
        serviceType,
        steamShipLine,
        yardLocation,
        containerTerminal,
        holdCategory,
        holdType,
        perdiemStartDate,
        fullOutgateDate,
        lastFreeDay,
        inPin,
        outPin,
      },
      commodityData: commodityData?.map(
        (ct: Commodity) => new LoadCommodity(ct)
      ),
      trailerTypes: equipmentTypes.map(
        (item: { key: string; value: string }) => item.key
      ),
    };
  }
}

export class AddLoadCommodity {
  commodityTypeId: string;
  commodityType: string;
  itemName: string;
  description: string;
  floorLoad: boolean;
  height: string;
  length: string;
  width: string;
  packageType: string;
  qty: number;
  stackable: boolean;
  volume: string;
  weight: string;
  id?: string;

  constructor(data: any) {
    this.commodityTypeId = data.commodityTypeId;
    this.commodityType = data.commodityType;
    this.itemName = data.itemName;
    this.description = data.description;
    this.floorLoad = data.floorLoad;
    this.height = data.height;
    this.length = data.length;
    this.width = data.width;
    this.packageType = data.packageType;
    this.qty = data.qty;
    this.stackable = data.stackable;
    this.volume = data.volume;
    this.id = data?.id;
    this.weight = data.weight;
  }
}

export class deleteCommodityReq extends QueryParams {
  loadId?: string;
  commodityId?: string;
}

export class GetLoadRefNumberRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  reference!: string;
  terminalIds?: string[];
}

export class GetContainerListRequest extends QueryParams {
  pageNumber?: number;
  pageSize: number;
  containerSearch!: string | undefined;

  constructor(keyword: string | undefined) {
    super();
    this.pageSize = 25;
    this.containerSearch = keyword;
  }
}

export class GetChassisListRequest extends QueryParams {
  pageNumber?: number;
  pageSize: number;
  chassisSearch!: string | undefined;

  constructor(keyword: string | undefined) {
    super();
    this.pageSize = 25;
    this.chassisSearch = keyword;
  }
}

export class GetBookingAndAppointmentListRequest extends QueryParams {
  pageNumber?: number;
  pageSize: number;
  search!: string | undefined;

  constructor(keyword: string | undefined, pageNumber?: number) {
    super();
    this.pageSize = 25;
    this.pageNumber = pageNumber;
    this.search = keyword;
  }
}

// export class GetAppointmentNumbersListRequest extends QueryParams {
//   pageNumber?: number;
//   pageSize: number;
//   appointmentSearch!: string | undefined;

//   constructor(keyword: string | undefined) {
//     super();
//     this.pageSize = 25;
//     this.appointmentSearch = keyword;
//   }
// }

export class GetLoadOriginDestinationRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  city!: string;
  terminalIds!: string[];
}

export class GetLoadLocationNamesRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  keyword!: string;
  terminalIds!: string[];
}

export class TripTerminalRequest extends Payload {
  terminalId!: string;
  id!: string;
}

export class LoadSummaryListResponse {
  id!: string;
  loadAxeleId!: string;
  loadStatus!: string;
  referenceId!: string;
  seqNumber!: string;
  loadIds!: string[];
}

export class LoadOriginDestinationResponse {
  city!: string;
  state!: string;
  center?: {
    lat: number;
    lng: number;
  };
}

export class GetLoadRefNumberResponse extends QueryParams {
  referenceId!: string;
  loadIds!: string[];
}

export class UpdateLoadRequest {
  id!: string;
  loadStatus!: string;
}

export interface ConnectionDetail {
  appointmentEndDate?: string;
  appointmentStartDate?: string;
  driverAssistRequired?: boolean;
  driverGroupId?: string;
  driverId?: number;
  driverPicked?: boolean;
  estimatedActivityDuration?: number;
  fixedAppointment?: boolean;
  revenueShare?: number;
  terminalId?: string;
}

export interface SplitPayload {
  connectionPoint: {
    address: string;
    avgHandlingTime: number;
    axeleId: string;
    center: {
      lat: number;
      lng: number;
    };
    city: string;
    contacts: {
      contactTitle: string;
      countryCode: string;
      email: string;
      extension: string;
      firstname: string;
      isAfterHours: boolean;
      isPrimary: boolean;
      lastname: string;
      middlename: string;
      phone: string;
      prefix: string;
      suffix: string;
    }[];
    fullAddress: string;
    id: number;
    lat: number;
    lng: number;
    locationName: string;
    notes: string;
    recordDate: string;
    state: string;
    streetAddress: string;
    streetAddress2: string;
    timezone: string;
    zipcode: string;
    organizationId: number;
  };
  dropOffConnectionDetails: ConnectionDetail;
  legStartStopId: number;
  loadId: string;
  pickupConnectionDetails: ConnectionDetail;
}

export class SplitLoadRequest extends QueryParams implements SplitPayload {
  connectionPoint!: {
    address: string;
    avgHandlingTime: number;
    axeleId: string;
    center: {
      lat: number;
      lng: number;
    };
    city: string;
    contacts: {
      contactTitle: string;
      countryCode: string;
      email: string;
      extension: string;
      firstname: string;
      isAfterHours: boolean;
      isPrimary: boolean;
      lastname: string;
      middlename: string;
      phone: string;
      prefix: string;
      suffix: string;
    }[];
    fullAddress: string;
    id: number;
    lat: number;
    geoCoordinates: ICoordinates;
    lng: number;
    locationName: string;
    notes: string;
    recordDate: string;
    state: string;
    streetAddress: string;
    streetAddress2: string;
    timezone: string;
    zipcode: string;
    organizationId: number;
  };
  dropOffConnectionDetails!: ConnectionDetail;
  legStartStopId!: number;
  loadId!: string;
  pickupConnectionDetails!: ConnectionDetail;

  constructor(dto: SplitPayload) {
    super();
    Object.assign(this, dto);
    this.connectionPoint.organizationId = this.organizationId;
  }
}

export class RelayStop extends SplitLoadRequest {}

export class GetLastHosInfoRequest extends QueryParams {
  ids!: string[];

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class GetHosLastLocationRequest extends GetLastHosInfoRequest {}

export interface UpdateManualHosDriverIdToHos {
  restDuration: number;
  dutyTimeRemaining: number;
  shiftDriveRemaining: number;
  weeklyHoursRemaining: number;
  breakTimeRemaining: number;

  driverId: number;
  id: string;
  lastUpdateDate: string;
  organizationId: number;
}

export interface UpdateManualHosLocation {
  address: string;
  city: string;
  driverId: number;
  id: string;
  lat: number;
  lng: number;
  locationDateTime: string;
  locationName: string;
  organizationId: number;
  state: string;
  zipcode: string;
}

export class UpdateLoadDetailsRequest {
  organizationId: number;
  id: string;
  seqNumber: string;
  referenceId: string | null;
  purchaseOrderNumber: string;
  bolNumber: string;
  loadPriority?: LoadPriorityEnum;
  canBeBrokered?: boolean;
  dispatchNotes?: string;
  trailerTypes!: string[];
  sealNumber: string;
  totalWeight!: number | null;
  loadType: string;
  containerNumber: string;
  chassisNumber: string;
  lowerLimitTemperature: string;
  upperLimitTemperature: string;
  bookingNumber?: string;
  appointmentNumber?: string;
  loadDirection?: string;
  temperature?: number;
  reeferMode?: string;
  noteDetails!: NoteDetails;

  constructor(data: any) {
    this.organizationId = data.organizationId;
    this.id = data.id;
    this.seqNumber = data.seqNumber;
    this.referenceId = data.referenceId;
    this.purchaseOrderNumber = data.purchaseOrderNumber;
    this.bolNumber = data.bolNumber;
    this.loadPriority = data.loadPriority;
    this.canBeBrokered = data.canBeBrokered;
    this.dispatchNotes = data.notes;
    this.trailerTypes = data.trailerTypes?.map((data) => data.key);
    this.sealNumber = data.sealNumber;
    this.totalWeight = data.totalWeight;
    this.loadType = data.loadType;
    this.containerNumber = data.containerNumber;
    this.chassisNumber = data.chassisNumber;
    this.lowerLimitTemperature = data.lowerLimitTemperature;
    this.upperLimitTemperature = data.upperLimitTemperature;
    this.bookingNumber = data.bookingNumber;
    this.appointmentNumber = data.appointmentNumber;
    this.loadDirection = data.loadDirection;
    this.temperature = data.temperature;
    this.reeferMode = data.reeferMode;
    this.noteDetails = data.noteDetails;
  }
}

export class UpdateLoadModeRequest {
  organizationId: number;
  id: string;
  metaData?: LoadMetaDataDetails;

  constructor(data: any) {
    this.organizationId = data.organizationId;
    this.id = data.id;
    this.metaData = data.metaData;
  }
}
export class UpdateManualHosLocationRequest {
  driverIdToHos?: { [property: string]: UpdateManualHosDriverIdToHos };
  groupId?: string | null;
  location?: UpdateManualHosLocation;
  organizationId?: number;

  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export class GetLoadDetailsActionsRequest extends QueryParams {
  loadId?: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class GetAllStopsRequest extends QueryParams {
  organizationId!: number;
  tripOrLoadId!: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class UpdateAllActivitiesRequest extends QueryParams {
  organizationId!: number;
  tripId!: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class UpdateAllActivitiesPayload {
  activityUpdateDataList!: {
    actualTimeOfArrival?: string | null;
    actualTimeOfCompletion?: string | null;
    id: number;
    loadingEndDate?: string | null;
    loadingStartDate?: string | null;
    stopStatus: TripStopStatusEnum;
  }[];

  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export class UpdateStatusRequest extends QueryParams {
  loadId!: string;
  status!: ELoadStatus;

  constructor(dto: { loadId: string; status: ELoadStatus }) {
    super();
    Object.assign(this, dto);
  }
}

export interface LoadCancellationDetails {
  cancellationDate: string;
  invoiceNotes: string;
  keepInvoiceLineItems: boolean;
  markAsNonInvoiceable: boolean;
  noteDetails: {
    updateTime: string;
    userId: number;
  };
}

export class UpdateStatusPayload {
  completionDate?: string;
  invoiceNotes?: string;
  noteDetails?: {
    updateTime: string;
    userId: number;
  };
  invoicedDate?: string;
  paidDate?: string;
  loadCancellationDetails?: LoadCancellationDetails;

  constructor(dto?: {
    completionDate?: string;
    invoiceNotes?: string;
    invoicedDate?: string;
    paidDate?: string;
    loadCancellationDetails?: LoadCancellationDetails;
    noteDetails?: {
      updateTime: string;
      userId: number;
    };
  }) {
    Object.assign(this, dto);
  }
}

export class DeleteLoadRequest extends QueryParams {
  loadId!: string;

  constructor(dto: { loadId: string }) {
    super();
    Object.assign(this, dto);
  }
}
export class DeleteLoadManifestRequest extends QueryParams {
  manifestId!: string;
  constructor(dto: { manifestId: string }) {
    super();
    Object.assign(this, dto);
  }
}

export class GetTripMapDataRequest extends QueryParams {
  tripId!: string;
}
export class LoadOverviewRemoveLoads extends QueryParams {
  organizationId!: number;
  loadId!: string;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class GetUpdatedManifestDataRequest extends QueryParams {
  organizationId!: number;
  loadIds!: Array<string>;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class GetRemoveLoadManifestDataResp {
  deleted!: boolean;
  errorMessage!: string;
  loadId!: string;
  manifestId!: string;
  type!: string;
  affectedTripIds!: Array<string>;
  removedTripIds!: Array<string>;
}

type OptionsType = {
  value: string;
  key: string;
};
type ListOptionsType = Array<OptionsType>;

export interface ILoadStaticContent {
  appointmentWindowType: ListOptionsType;
  commodityPackageTypes: ListOptionsType;
  commodityType?: ListOptionsType;
  invoiceSyncStatusList: ListOptionsType;
  loadDirectionTypes: Array<OptionsType>;
  loadStatusType: ListOptionsType;
  loadType: ListOptionsType;
  onTimeStatusType: ListOptionsType;
  stopActivityTypes: ListOptionsType;
  supportedPdfFormats: ListOptionsType;
  trailerType: ListOptionsType;
}
