import { Badge, styled } from '@mui/material';

export const StyledBadge: any = styled(Badge)<{ badgeColor: any }>(
  ({ theme, badgeColor }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: badgeColor,
      color: badgeColor,
      bottom: '36%',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: '-2px',
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  })
);

export const disabledStyles = { mt: 1, pointerEvents: 'none', opacity: 0.22 };

export const textStyles = {
  fontWeight: 400,
  letterSpacing: '0.4px',
};

export const detailCardSubtitleStyles = {
  lineHeight: '150%',
  fontSize: '12px',
};

// NEW DETAILS CARD STYLES

export const detailTextStyles = {
  fontSize: '12px',
  display: 'inline-block',
  'white-space': 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#757575',
  letterSpacing: '0.4px',
  width: '100%',
  // display: 'flex',
  gap: '5px',
};

export const primaryDetailTextStyles = {
  fontWeight: 'normal',
  fontSize: '14px',
  color: '#000000',
  letterSpacing: '0.15px',
};

export const additionalDetailTextStyles = {
  fontSize: '12px',
};

export const checkIconStyles = {
  width: '18px',
  height: '18px',
  marginRight: '10px',
};
export const WarningIconContainer = {
  marginRight: '5px',
};

export const columnDetailsContainerStyle = {
  maxWidth: '280px',
  width: '220px',
};
