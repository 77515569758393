import { useTheme } from '@emotion/react';
import { Box, Checkbox, Stack, Theme } from '@mui/material';
import { observer } from 'mobx-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getCreateLoadPanelStyles } from './styles';

import { CustomerDetails, CustomerSummary } from './components/customer';
import Header from './components/header';
import { LoadDetails, LoadSummary } from './components/load';
import { QuickStartDetails } from './components/quick-start';
import { RateDetails, RateSummary } from './components/rate';
import Sidebar from './components/sidebar';
import { StopsDetails, StopsSummary } from './components/stops';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@components/ui-kit/accordion';
import { Alert } from '@components/ui-kit/alert';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ArrowForward,
  AttachMoney,
  BoltOutlined,
  LocationOnOutlined,
  PersonOutline,
} from '@mui/icons-material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { debounce } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { LoadSectionIcon } from '../../../_assets/static/svgs/LoadSectionIcon';
import { LoadSectionIconPrimary } from '../../../_assets/static/svgs/LoadSectionIconPrimary';
import { loadService } from '../../../api';
import { useCarrierPermission } from '../../../common/hooks/useCarrierPermission';
import {
  ILoadStaticContent,
  LoadDetailsResponse,
  TemplateDetailsResponse,
} from '../../../models';
import useFilterStorage from '../../../services/storage';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { useStores } from '../../../store/root.context';
import { LineItem } from '../../../types';
import {
  getCommodityTypes,
  getNextExpandedSection,
  getNextExpandedSectionOfLastElement,
} from '../LoadDetailsPanel/utils';
import { IGroup } from './FieldGroupsConfig';
import { RateConFileViewer } from './components/quick-start/ratecon-viewer';
import { CloseLoadFormDialog } from './components/ui/close-load-form-dialog';
import { CreateTemplateNameDialog } from './components/ui/create-template-name-dialog';
import { SubmitButton } from './components/ui/submit-button';
import { CustomerRequiredDocOptions } from './constants/fieldOptions';
import {
  LOAD_SECTION_KEYS_LIST,
  SECTION_LAST_ELEMENT_ID_LIST,
} from './constants/fieldValues';
import { QUERY_KEYS } from './constants/queryKeys';
import { initialState } from './data.config';
import { CreateLoadRequestDTO } from './dto/create-load-request.dto';
import { createLoadFormSchema } from './schema';
import { createLoadService } from './services/createLoad.service';
import { rateFormService } from './services/rate.service';
import { CreateLocationResDTO } from './types/requests';
import { ILoadDetails } from './types/types';
interface IProps {
  onClose: () => void;
  open: boolean;
  onLoadCreated?: (
    load: LoadDetailsResponse,
    createLoadRateConFile?: File | null
  ) => void;
  templateId?: string;
  value?: ILoadDetails;
  onTemplateCreated?: (templateData?: TemplateDetailsResponse) => void;
  assignmentData?: {
    driverGroupId?: string;
    driverGroupName?: string;
    action?: string;
    dispatcherId?: number;
  };
  onSubmit?: (load: CreateLoadRequestDTO, file: File | null) => void;
  disableLoadCreation?: boolean;
}

let currentErrorIndex = 0;
let currentTotalErrorCount = 0;

const CreateLoadPanel: React.FC<IProps> = ({
  onClose,
  open,
  onLoadCreated,
  templateId,
  value,
  onTemplateCreated,
  assignmentData,
  onSubmit,
  disableLoadCreation = false,
}) => {
  const theme = useTheme();
  const styles = getCreateLoadPanelStyles(theme as Theme);
  const { isTerminalEnabled, allTerminals, getCompanyPreferences } =
    useRootStore();
  const {
    myLoadStore: { createLoadRateConFile, clearCreateLoadGlobalStates },
  } = useStores();
  const [closeAddLoadDialog, setCloseAddLoadDialog] = useState<boolean>(false);
  const closeAddLoadWarningDialog = () => setCloseAddLoadDialog(false);
  const { hasCarrierViewPermission } = useCarrierPermission();

  const [expandedSection, setExpandedSection] = React.useState<string | false>(
    'quick-start'
  );
  const expandedSectionRef = useRef<string>();
  const addLoadRef = useRef<HTMLInputElement>();
  const handleExpandedSectionChange =
    (section: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedSection(isExpanded ? section : '');
    };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [createTemplateDialog, setCreateTempalteDialog] =
    useState<boolean>(false);
  const toggleCreateTemplateDialog = () =>
    setCreateTempalteDialog(!createTemplateDialog);
  const [isCreateTemplateLoading, setIsCreateTemplateLoading] =
    useState<boolean>(false);
  const LStorageHook = useFilterStorage({ page: 'MyLoads' });
  const storageData = LStorageHook.getStorage() || {};

  const form = useForm({
    resolver: yupResolver(createLoadFormSchema),
    // reValidateMode: 'onChange',
    defaultValues: {
      ...initialState,
    } as ILoadDetails,
  });
  const getLoadStaticContentData = async () =>
    ((await loadService?.getLoadStaticData?.()) as { data: ILoadStaticContent })
      ?.data;

  useEffect(() => {
    form.setValue('assignToDriver', Boolean(assignmentData?.driverGroupId));
  }, [assignmentData?.driverGroupId, form.setValue]);

  useQuery<ILoadStaticContent>(
    QUERY_KEYS.loadStaticData,
    () => getLoadStaticContentData(),
    {
      refetchOnWindowFocus: false,
      enabled: open,
      keepPreviousData: true,
    }
  );

  useQuery<any>(QUERY_KEYS.loadCommodityList, () => getCommodityTypes(), {
    refetchOnWindowFocus: false,
    enabled: open,
    keepPreviousData: true,
  });

  const invoiceLineItems = useQuery<{ base: LineItem[]; fees: LineItem[] }>(
    QUERY_KEYS.lineItems,
    async () => {
      const fees = await rateFormService.getAccessorialFeeOptions();
      updateBaseRate(fees.base);
      return fees;
    },
    {
      refetchOnWindowFocus: false,
      enabled: open,
      keepPreviousData: true,
    }
  )?.data;

  useEffect(() => {
    if (!allTerminals?.length || !open) return;
    const primaryTerminal = allTerminals?.find?.((e: any) => e?.isPrimary);
    form.setValue?.('customer.invoice', primaryTerminal?.id);
    form.setValue?.('isTerminalEnabled', !!isTerminalEnabled);
    form.setValue?.(
      'customer.invoiceCompanyName',
      primaryTerminal?.companyName
    );
  }, [allTerminals, open, isTerminalEnabled]);

  useEffect(() => {
    if (!getCompanyPreferences || !open) return;
    const { billOfLadingMandatory, proofOfDeliveryMandatory } =
      getCompanyPreferences!;
    const values = [];
    if (billOfLadingMandatory) values.push(CustomerRequiredDocOptions[0]?.id);
    if (proofOfDeliveryMandatory)
      values.push(CustomerRequiredDocOptions[1]?.id);
    form.setValue?.('customer.requiredDocuments', values);
    form.setValue('rate.invoiceTerm', getCompanyPreferences?.invoiceTerm);
    form.setValue(
      'rate.invoiceTermValue',
      getCompanyPreferences?.invoiceTermValue
    );
    form.setValue('load.equipment', [getCompanyPreferences?.trailerType!]);
  }, [getCompanyPreferences, open]);
  useEffect(() => {
    if (!templateId || !open || !form) return;
    form?.setValue?.('templateId', templateId);
  }, [templateId, open]);

  useEffect(() => {
    if (!value || !open || !form) return;
    if (!Object.keys?.(value)?.length) return;
    form.reset?.({
      ...value,
      assignToDriver: Boolean(assignmentData?.driverGroupId),
      requiredFields: initialState.requiredFields,
      hiddenFields: storageData?.createLoad?.['hiddenFields'] ?? {},
    });
  }, [JSON.stringify(value), open]);

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDownEventCallback, true);
    } else {
      document.removeEventListener('keydown', handleKeyDownEventCallback, true);
    }
  }, [open]);

  useEffect(() => {
    expandedSectionRef.current = expandedSection as string;
  }, [expandedSection]);

  const handleKeyDownEventCallback = useCallback(
    (event) => handleKeyDownEvent(event),
    []
  );

  const handleKeyDownEvent = debounce((event: any) => {
    event?.preventDefault?.();
    if (event?.key !== 'Tab') return;
    if (event?.shiftKey) {
      const next = getNextExpandedSection(
        expandedSectionRef?.current!,
        LOAD_SECTION_KEYS_LIST
      );
      if (next) setExpandedSection(next);
    } else {
      const next = getNextExpandedSectionOfLastElement(
        event.target?.id,
        LOAD_SECTION_KEYS_LIST,
        SECTION_LAST_ELEMENT_ID_LIST
      );
      if (next) setExpandedSection(next);
    }
  }, 300);

  const numFormErrors = useMemo(() => {
    let count = 0;
    for (const obj of Object.values(form.formState.errors)) {
      count += Object.keys(obj).length ?? 0;
    }
    const errorMap = createLoadService.getValidationErrorMap(
      form.formState.errors
    );
    return { count, errorMap };
  }, [JSON.stringify(form.formState.errors)]);

  const closeAddLoad = () => {
    form.reset(initialState);
    setExpandedSection('quick-start');
    clearCreateLoadGlobalStates?.();
    closeAddLoadWarningDialog();
    setIsSidebarOpen(false);
    onClose?.();
  };

  const handleCreateLoad = async () => {
    try {
      form.clearErrors();
      const isVaid = validateFormCustomErrors();
      if (!isVaid) return;
      setIsLoading(true);
      const createLoadFormVals = (await validateLocations()) as ILoadDetails;
      updateMissingRateTypes(createLoadFormVals, invoiceLineItems?.base);
      const createLoadRequesPayload = new CreateLoadRequestDTO(
        form.getValues(),
        {
          invoiceTerm: getCompanyPreferences?.invoiceTerm,
          invoiceTermValue: getCompanyPreferences?.invoiceTermValue,
        }
      );
      if (disableLoadCreation) {
        setIsLoading(false);
        const stops = form.getValues('stops');
        const assignToDriver = form.getValues('assignToDriver');
        if (assignToDriver && isStopDatesEmpty(stops)) {
          return;
        }
        return onSubmit?.(
          {
            ...createLoadRequesPayload,
            assignToDriver: assignToDriver,
          } as any,
          createLoadRateConFile
        );
      }
      const res = await createLoadService.createLoad(createLoadRequesPayload);
      if (createLoadRateConFile) await uploadRateConFile(res);
      const loads = res?.data?.loads;
      if (loads?.length > 1) {
        let loadRes: LoadDetailsResponse | null = null;
        for (const load of loads) {
          if (load?.createdTrips?.length === 1) continue;
          loadRes = load;
          break;
        }
        if (loadRes) onLoadCreated?.(loadRes, createLoadRateConFile);
        else onLoadCreated?.(loads?.[0], createLoadRateConFile);
      } else {
        onLoadCreated?.(loads?.[0], createLoadRateConFile);
      }
      setIsLoading(false);

      form.reset(initialState);
      closeAddLoad();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const isStopDatesEmpty = (stops: any): boolean => {
    let isDatesEmpty = false;
    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      if (
        !stop?.appointmentStartDate ||
        !stop?.appointmentStartTime ||
        !stop?.appointmentEndDate ||
        !stop?.appointmentEndTime
      ) {
        isDatesEmpty = true;
        form?.setError(`stops.${i}.appointmentStartDate`, {
          message: 'Appointment date and time is required',
        });
      }
      if (stop?.type === 'RELAY') {
        if (
          !stop?.relayDropOffAppointmentStartDate ||
          !stop?.relayDropOffAppointmentStartTime ||
          !stop?.relayDropOffAppointmentEndDate ||
          !stop?.relayDropOffAppointmentEndTime
        ) {
          isDatesEmpty = true;
          form?.setError(`stops.${i}.relayDropOffAppointmentStartDate`, {
            message: 'Appointment date and time is required',
          });
        }
      }
    }
    return isDatesEmpty;
  };

  const updateBaseRate = (fees: Array<LineItem>) => {
    updateMissingRateTypes(form.getValues(), fees);
  };

  const updateMissingRateTypes = (
    createLoadFormVals: ILoadDetails,
    lineItemOptions?: Array<LineItem>
  ) => {
    if (
      !createLoadFormVals?.rate?.feeDetail &&
      !createLoadFormVals?.rate?.baseRateType
    ) {
      form.setValue('rate.baseRateType', lineItemOptions?.[0]?.id);
      form.setValue('rate.feeDetail', lineItemOptions?.[0]);
      form.setValue('rate.description', lineItemOptions?.[0]?.description);
    }
  };

  const uploadRateConFile = async (data: any) => {
    return await createLoadService.saveRateconFile({
      file: createLoadRateConFile!,
      load: {
        ...data?.data?.loads?.[0],
        terminalId:
          data?.data?.loads?.[0]?.terminalId ??
          data?.data?.loads?.[0]?.terminal?.id,
      },
    });
  };
  const validateLocations = async () => {
    try {
      if (Object.keys(form.formState?.errors)?.length) return;
      const newLocations = await createLoadService.validateStopLocations(
        form.getValues().stops
      );
      const stops = form.getValues().stops;
      if (newLocations?.positions) {
        let index = 0;
        for (const stopPos of newLocations.positions) {
          if (!stops[stopPos]) continue;
          stops[stopPos].location = new CreateLocationResDTO(
            newLocations.locations?.[index]
          ) as any;
          stops[stopPos].contacts = newLocations.locations?.[
            index
          ]?.contacts?.map((e: any) => ({
            countryCode: e?.phoneData?.countryCode,
            phone: e?.phoneData?.phone,
            extension: e?.phoneData?.extension,
            email: e?.email,
            firstname: e?.fullName,
            lastname: '',
            isPrimary: e?.isPrimary,
          }));
          index++;
        }
      }
      return { ...form.getValues(), stops };
    } catch (error) {
      return form.getValues();
    }
  };
  const validateFormCustomErrors = (): boolean => {
    const { stopApptDatesValidation } = createLoadService.validateForm(
      form.getValues() as ILoadDetails
    );
    if (stopApptDatesValidation?.errorCount > 0) {
      for (let i = 0; i < stopApptDatesValidation?.errors?.length; i++) {
        const errorList = stopApptDatesValidation.errors?.[i];
        errorList?.forEach((err) => {
          form?.setError(
            (err?.fieldName as any) ?? `stops.${i}.appointmentStartDate`,
            err
          );
        });
      }
    }
    let isTerminalValid = true;
    if (isTerminalEnabled && !form.getValues()?.customer?.invoice?.length) {
      isTerminalValid = false;
      form.setError('customer.invoice', {
        type: 'required',
        message: 'Invoice Terminal is required.',
      });
    }
    return !Boolean(stopApptDatesValidation?.errorCount) && isTerminalValid;
  };

  const goToNextErrorField = async () => {
    await form.trigger();
    validateAndUpdateHiddenFieldsWithError(form.formState?.errors);
    validateFormCustomErrors();
    if (form.getValues('assignToDriver'))
      isStopDatesEmpty(form.getValues('stops'));
    const keys = Object.keys?.(form.formState?.errors);
    if (
      currentErrorIndex >= keys?.length ||
      currentTotalErrorCount > keys?.length
    )
      currentErrorIndex = 0;
    setExpandedSection(keys?.[currentErrorIndex]);
    currentErrorIndex++;
    currentTotalErrorCount = keys?.length;
    const currentElToFocus = addLoadRef.current?.querySelector(
      `div #${keys?.[0]}`
    );
    if (currentElToFocus)
      currentElToFocus?.scrollIntoView?.({
        block: 'start',
        behavior: 'smooth',
      });
  };
  const handleOnSave = async () => {
    addLoadRef?.current?.scrollTo?.({ top: 0, behavior: 'smooth' });
    await form.trigger();
    form.handleSubmit(handleCreateLoad)?.();
    setTimeout(() => {
      validateFormCustomErrors();
      validateAndUpdateHiddenFieldsWithError(form.formState?.errors);
    });
  };

  const handleApplyAutomation = async () => {
    form.setValue('applyAutomation', true);
    handleOnSave();
  };

  const validateAndUpdateHiddenFieldsWithError = (errors: any) => {
    const updatedHiddenFields = createLoadService.validateHiddenFieldErrors(
      errors,
      form.getValues('hiddenFields')
    );
    form.setValue('hiddenFields', updatedHiddenFields);
  };

  const handleDialogClose = (event: Event, reason: string) => {
    if (reason == 'backdropClick') return;
    setCloseAddLoadDialog(true);
  };
  const handleCreateTemplate = async (templateName: string) => {
    try {
      setIsCreateTemplateLoading(true);
      const res = await createLoadService.createTemplate({
        load: form.getValues(),
        templateName,
        file: createLoadRateConFile!,
      });
      onTemplateCreated?.(res);
      toggleCreateTemplateDialog();
      setIsCreateTemplateLoading(false);
    } catch (error) {
      setIsCreateTemplateLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!open) return;
    form.setValue('hiddenFields', storageData?.createLoad?.hiddenFields);
  }, [open]);
  const handleFieldConfigChange = (group: IGroup, show: boolean) => {
    const formSection: string = group?.fieldSection!;
    const formHiddenVals = form.getValues('hiddenFields');
    form.setValue(`hiddenFields.${formSection}`, {
      ...formHiddenVals?.[formSection],
      [group?.fieldName as string]: !show,
    });
    const hiddenFields = storageData?.createLoad?.['hiddenFields'] ?? {};
    hiddenFields[formSection] = {
      ...formHiddenVals?.[formSection],
      [group?.fieldName as string]: !show,
    };
    LStorageHook.updateStorage('createLoad', {
      hiddenFields: {
        ...storageData['hiddenFields'],
        ...hiddenFields,
      },
    });
  };

  const getSidebarIgnoredFields = () => {
    const fields = [];
    if (!hasCarrierViewPermission) fields?.push('CanBeBrokered');
    if (!isTerminalEnabled) fields?.push('InvoiceTerminal');
    return fields;
  };

  return (
    <>
      <Dialog
        sx={styles.dialog}
        onClose={handleDialogClose}
        open={open}
        maxWidth="xl"
      >
        <Header
          onClose={handleDialogClose as any}
          toggleSidebar={toggleSidebar}
          isCreate={true}
        />
        <DialogContent id="add-load-dialog-content" sx={styles.root}>
          <Box sx={styles.container} className="CreateLoadPanel-root">
            <RateConFileViewer sx={styles.pdfViewContainer} />
            <Box sx={styles.form} ref={addLoadRef}>
              <FormProvider {...form}>
                <form>
                  {!!numFormErrors?.count ? (
                    <Alert
                      variant="filled"
                      severity="error"
                      // icon={false}
                      action={
                        <Button
                          color="inherit"
                          variant="outlined"
                          size="small"
                          sx={{
                            color: 'white',
                            borderColor: 'white',
                            animation: 'error-glow 1s 6 alternate',
                          }}
                          endIcon={<ArrowForward />}
                          onClick={goToNextErrorField}
                        >
                          Next Error
                        </Button>
                      }
                    >
                      {/* <Stack direction={'row'} sx={{ gap: '8px', alignItems: 'center' }}> */}
                      {/* <Tooltip title={<ErrorListTooltip errors={numFormErrors?.errorMap} />} ><ErrorOutlineOutlined sx={{ width: '20px', height: '20px' }} /></Tooltip> */}
                      {numFormErrors?.count} Error
                      {/* </Stack> */}
                    </Alert>
                  ) : (
                    false
                  )}
                  <Accordion
                    expanded={expandedSection === 'quick-start'}
                    onChange={handleExpandedSectionChange('quick-start')}
                  >
                    <AccordionSummary
                      id="quick-start"
                      prefixIcon={<BoltOutlined />}
                    >
                      Quick Start
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 2, py: 2.5 }}>
                      <QuickStartDetails
                        isTerminalEnabled={isTerminalEnabled}
                        allTerminals={allTerminals}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedSection === 'customer'}
                    onChange={handleExpandedSectionChange('customer')}
                  >
                    <AccordionSummary
                      id="customer"
                      prefixIcon={<PersonOutline />}
                    >
                      {expandedSection !== 'customer' &&
                      form.getValues('customer')?.customerId ? (
                        <></>
                      ) : (
                        'Customer'
                      )}
                      {expandedSection !== 'customer' && <CustomerSummary />}
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 2, py: 2.5 }}>
                      {expandedSection === 'customer' && <CustomerDetails />}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedSection === 'stops'}
                    onChange={handleExpandedSectionChange('stops')}
                  >
                    <AccordionSummary
                      id="stops"
                      prefixIcon={<LocationOnOutlined />}
                    >
                      {expandedSection !== 'stops' &&
                      form.getValues('stops')?.[0]?.location?.locationName
                        ?.length ? (
                        <></>
                      ) : (
                        'Stops'
                      )}
                      {expandedSection !== 'stops' && <StopsSummary />}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {/* {expandedSection === 'stops' && ( */}
                      <StopsDetails />
                      {/* )} */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedSection === 'load'}
                    onChange={handleExpandedSectionChange('load')}
                  >
                    <AccordionSummary
                      id="load"
                      prefixIcon={
                        <Box marginRight="8px">
                          {expandedSection === 'load' ? (
                            <LoadSectionIconPrimary />
                          ) : (
                            <LoadSectionIcon />
                          )}
                        </Box>
                      }
                    >
                      {expandedSection !== 'load' &&
                      form.getValues('load')?.equipment?.length ? (
                        <></>
                      ) : (
                        'Load'
                      )}
                      {expandedSection !== 'load' && <LoadSummary />}
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 2, py: 2.5 }}>
                      {expandedSection === 'load' && <LoadDetails />}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedSection === 'rate'}
                    onChange={handleExpandedSectionChange('rate')}
                  >
                    <AccordionSummary id="rate" prefixIcon={<AttachMoney />}>
                      {expandedSection !== 'rate' &&
                      !!Number(form.getValues('rate')?.totalRate) ? (
                        <></>
                      ) : (
                        'Rate'
                      )}
                      {expandedSection !== 'rate' && <RateSummary />}
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 2, py: 2.5 }}>
                      {/* {expandedSection === 'rate' && */}
                      <RateDetails />
                    </AccordionDetails>
                  </Accordion>
                </form>
              </FormProvider>
            </Box>
            <Box display={isSidebarOpen ? undefined : 'none'} overflow={'auto'}>
              <Sidebar
                sx={styles.sidebar}
                onFieldChange={handleFieldConfigChange}
                values={form.watch('hiddenFields')}
                ignoredFieldsGroupLabel={getSidebarIgnoredFields()}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            gap: '10px',
            padding: '15px 20px',
            justifyContent: 'space-between',
          }}
        >
          {assignmentData?.driverGroupId && (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Checkbox
                defaultChecked={true}
                onChange={(event: any) => {
                  form.setValue('assignToDriver', event.target?.checked);
                }}
              />
              <label>Plan a driver ({assignmentData?.driverGroupName})</label>
            </Stack>
          )}
          <Stack direction={'row'} gap={1} alignItems={'center'} ml="auto">
            <Button onClick={handleDialogClose as any} size="large">
              Cancel
            </Button>
            <SubmitButton
              isLoading={isLoading}
              onClick={handleOnSave}
              onCreateTemplate={toggleCreateTemplateDialog}
              onAutomationApply={handleApplyAutomation}
            />
          </Stack>
        </DialogActions>
      </Dialog>
      <CloseLoadFormDialog
        open={closeAddLoadDialog}
        onClose={closeAddLoadWarningDialog}
        onSubmit={closeAddLoad}
      />
      <CreateTemplateNameDialog
        open={createTemplateDialog}
        onClose={toggleCreateTemplateDialog}
        onSubmit={handleCreateTemplate}
        isLoading={isCreateTemplateLoading}
      />
    </>
  );
};

export default observer(CreateLoadPanel);
