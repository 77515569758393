import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import {
  CardHeader,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { WarningLoadListDelayIcon } from '../../../../../ui-kit/components/Assets';
import { useMemo } from 'react';
import { DetailsPanelThreeDotMenu } from '../../../../../common/DetailsPanel/components/ThreeDotMenu';
import { useDispatchPermission } from '../../../../../common/hooks/useDispatchPermission';
import { ELoadStatus } from '../../../../../common/LoadTabPanel/constants/constants';
import { ITimeoffDialogData } from '../../../../../common/TimeoffDialog/types';
import { IDispatchDriverTimelineTrip } from '../../../../../models/DTOs/Dispatch/Timeline';
import { numberToMiles } from '../../../../../utils/formater.utils';
import TimeoffDateTime from '../../../../../utils/timeoffDateTime';
import { defaultNoValue } from '../../../constants/dispatch';
import {
  StyledCardTripRoot,
  StyledSectionDelay,
  StyledSectionDelayTitle,
  StyledSectionETA,
  StyledSectionHeader,
  StyledSectionItem,
  StyledSectionKPIValue,
} from '../styles';
import withActionTimeOff from './withActionTimeOff';

export interface GanttCardTripTimeOffProps {
  isCurrent?: boolean;
  trip: IDispatchDriverTimelineTrip;
  timeoffDialogData?: ITimeoffDialogData | null;
  onAfterUpdateTimeOffAction?: () => Promise<void>;
  isReOrderActive?: boolean;
}

export const GanttCardTripTimeOff = ({
  trip,
  timeoffDialogData,
  onAfterUpdateTimeOffAction,
  isReOrderActive = false,
}: GanttCardTripTimeOffProps): JSX.Element => {
  const { hasDispatchEditPermission } = useDispatchPermission();
  const theme: any = useTheme();
  const TimeOff3DotMenuWithActions = useMemo(
    () => withActionTimeOff(DetailsPanelThreeDotMenu),
    []
  );
  const isTimeOffCompleted =
    trip.status === ELoadStatus.LOAD_COMPLETED ||
    !!trip?.stops[0]?.actualTimeOfArrival;
  return (
    <StyledCardTripRoot>
      <CardHeader
        avatar={<BedOutlinedIcon sx={{ color: 'primary.contrast' }} />}
        title={
          <Typography variant="h7" sx={{ color: 'primary.contrast' }}>
            Time Off @{' '}
            {[trip?.stops[0]?.location?.city, trip?.stops[0]?.location?.state]
              .filter((str) => !!str)
              .join(', ')}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2" sx={{ color: 'info.light' }}>
            {trip?.stops[0]?.appointmentStartDate &&
            trip?.stops[0]?.appointmentEndDate ? (
              TimeoffDateTime.rangeDateToTime(
                trip?.stops[0]?.appointmentStartDate,
                trip?.stops[0]?.appointmentEndDate
              )
            ) : (
              <></>
            )}
          </Typography>
        }
        action={
          isReOrderActive ? (
            <MenuOutlinedIcon />
          ) : (
            hasDispatchEditPermission && (
              <TimeOff3DotMenuWithActions
                data={trip}
                loadData={timeoffDialogData}
                hocOnAction={onAfterUpdateTimeOffAction}
              />
            )
          )
        }
        sx={{
          height: '80px',
          width: '100%',
          paddingBottom: '20px',
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D',
          borderRadius: '8px',
        }}
      />

      <Box
        sx={{
          marginTop: '-10px',
          borderRadius: '10px',
          backgroundColor: 'primary.contrast',
          paddingTop: '16px',
        }}
      >
        <StyledSectionItem>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '12px',
            }}
          >
            Time off Location:{' '}
            <StyledSectionETA component={'span'} whiteSpace={'pre-wrap'}>
              {trip?.stops[0]?.location?.address ||
                trip?.stops[0]?.location?.fullAddress}
            </StyledSectionETA>
          </Typography>
        </StyledSectionItem>

        <StyledSectionItem>
          <StyledSectionHeader>
            {isTimeOffCompleted ? 'Start Time' : 'Est. Start Time'}
            {': '}
            {TimeoffDateTime.getDateTimeDisplayTooltip({
              dateTime: trip?.startTime,
              timezone: trip?.stops[0]?.location?.timezone,
              defaultValue: defaultNoValue,
            })}
          </StyledSectionHeader>
          <StyledSectionETA>
            {`Appt. time:  ${TimeoffDateTime.getDateTimeDisplayTooltip({
              dateTime: trip?.stops[0]?.appointmentStartDate,
              timezone: trip?.stops[0]?.location?.timezone,
              defaultValue: defaultNoValue,
            })}`}
          </StyledSectionETA>
        </StyledSectionItem>

        <StyledSectionItem>
          <StyledSectionHeader>
            {isTimeOffCompleted ? 'End Time' : 'Est. End Time'}
            {': '}
            {TimeoffDateTime.getDateTimeDisplayTooltip({
              dateTime: trip?.endTime,
              timezone: trip?.stops[0]?.location?.timezone,
              defaultValue: defaultNoValue,
            })}
          </StyledSectionHeader>
          <StyledSectionETA>{`Appt. time:  ${TimeoffDateTime.getDateTimeDisplayTooltip(
            {
              dateTime: trip?.stops[0]?.appointmentEndDate,
              timezone: trip?.stops[0]?.location?.timezone,
              defaultValue: defaultNoValue,
            }
          )}`}</StyledSectionETA>
        </StyledSectionItem>

        <Divider variant="middle" sx={{ marginTop: '16px' }} />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          width={'100%'}
        >
          <CardHeader
            title={<StyledSectionETA>Miles to Location</StyledSectionETA>}
            subheader={
              <StyledSectionKPIValue>
                {numberToMiles(trip?.emptyMiles)}
              </StyledSectionKPIValue>
            }
          />
          <CardHeader
            title={
              <StyledSectionETA whiteSpace={'nowrap'}>
                Drive time to Location
              </StyledSectionETA>
            }
            subheader={
              <StyledSectionKPIValue>
                {TimeoffDateTime.minutesToDurationFormat(trip?.driveTimeMins)}
              </StyledSectionKPIValue>
            }
          />
        </Stack>
        {trip.violations && trip.violations > 0 ? (
          <StyledSectionDelay>
            <CardHeader
              avatar={
                <WarningLoadListDelayIcon color={theme.palette.error.dark} />
              }
              title={
                <StyledSectionDelayTitle>
                  Time off Start Delay: {trip.violations}hrs
                </StyledSectionDelayTitle>
              }
            />
          </StyledSectionDelay>
        ) : (
          <></>
        )}
      </Box>
    </StyledCardTripRoot>
  );
};
