import { Box, Button, Typography, Popover, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { useRef, useState } from 'react';
import { ILegend, LegendContentType } from '../types';
import {
  GanttLegendBoxStyles,
  GanttLegendButtonStyles,
  GanttLegendHeaderContainer,
  GanttLegendTitleStyles,
  LegendPopupContainerStyles,
} from '../styles';

export const Legend: React.FC<ILegend> = (props) => {
  const { content = [], type = 'vertical', ...otherProps } = props;
  const [openLegend, setOpenLegend] = useState<boolean>(false);
  const legendButtonRef = useRef(null);

  const handleLegendClose = () => setOpenLegend(false);
  if (!content?.length) return <></>;
  return (
    <>
      <Button
        ref={legendButtonRef}
        sx={{ ...GanttLegendButtonStyles, ...otherProps?.btnStyles }}
        onClick={() => {
          setOpenLegend((prev) => !prev);
        }}
      >
        Legend
      </Button>
      <Popover
        id={'gant-legend'}
        open={openLegend}
        anchorEl={legendButtonRef?.current}
        onClose={handleLegendClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{ ...LegendPopupContainerStyles, ...otherProps?.popupStyles }}
      >
        <Box sx={GanttLegendHeaderContainer}>
          <Typography sx={GanttLegendTitleStyles}>
            {otherProps?.title ?? 'Trip Statuses'}
          </Typography>
        </Box>
        <List
          sx={{
            display: type === 'horizontal' ? 'flex' : 'block',
            padding: '0',
            overflowY: 'auto',
            ...otherProps.containerStyles,
          }}
        >
          {content.map((data: LegendContentType, i: number) => (
            <ListItem
              key={i}
              sx={{
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '9px 16px',
                ...otherProps.itemContainerStyles,
              }}
            >
              <Box
                sx={{
                  ...GanttLegendBoxStyles,
                  backgroundColor: data.color,
                  ...data.colorBoxStyles,
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  marginLeft: '8px',
                  ...data.textStyles,
                }}
              >
                {data.text}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};
