import { b64EncodeUnicode } from '../../../utils';
import { QueryParams } from '../commonMixed';
import { resetPasswordType } from './User';

export class PaginatedUserListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  userStatus?: string[];
  emailFilter?: string[];
  nameFilter?: string[];
  roleCodeList?: string[];
  terminalIds?: string[];
  driverIdList?: string[];
  employmentType?: string[];
  excludeRoleCodeList?: string[];
  safetyIssueType?: string[];
  userTypes?: string[];
  gridColumnMetadataList?: string[];
  tagIds?: string[];

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.sort = dto.sort;
      this.excludeRoleCodeList = dto.excludeRoleCodeList;
      this.nameFilter = dto.nameFilter;
      this.userStatus = dto.userStatus;
      this.roleCodeList = dto.roleCodeList;
      this.emailFilter = dto.emailFilter;
      this.terminalIds = dto.terminalIds;
      this.driverIdList = dto.driverIdList;
      this.employmentType = dto.employmentType;
      this.safetyIssueType = dto.safetyIssueType;
      this.userTypes = dto.userTypes;
      this.gridColumnMetadataList = dto.gridColumnMetadataList;
      this.tagIds = dto.tagIds;
    }
  }
}

export class PaginatedAllUserListRequest extends PaginatedUserListRequest {}

export class GetUserByIdRequest extends QueryParams {
  id!: number | string;
  loggedIn!: number;
}

export class GetOwnViewsRequest extends QueryParams {
  userId!: number;
  entities!: string[];
}

export class GetAvailableViewsRequest extends QueryParams {
  userId!: number;
  entities!: string[];
}

export class ValidateViewRequest extends QueryParams {
  userId!: number;
  id!: string;
  name!: string;
  entity!: string;
  entities!: string[];
}

export class CreateViewRequest extends QueryParams {
  userId!: number;
}

export class UpdateViewRequest extends QueryParams {
  userId!: number;
}

export class CopyViewRequest extends QueryParams {
  id!: string;
}

export class CloseViewRequest extends QueryParams {
  id!: string;
}

export class DeleteViewRequest extends QueryParams {
  id!: string;
}

export class GetRolesRequest extends QueryParams {
  roleCode!: string;
  pageSize!: number;
  pageNumber!: number;
}

export class GetEmailsRequest extends QueryParams {
  email!: string;
  pageSize!: number;
  pageNumber!: number;
  roleCodeList!: string[];
  excludeRoleCodeList!: string[];
  terminalIds?: string[];
}

export class GetPrimaryDispatcherRequest extends QueryParams {
  name!: string;
  sort!: string;
  pageSize!: number;
  pageNumber!: number;
  excludeRoleCodeList!: string[];
  userStatus!: number[];
  terminalIds!: string[];
}

export class GetTerminalsRequest extends QueryParams {
  companyName!: string;
  pageSize!: number;
  pageNumber!: number;
}

export class GetNamesRequest extends QueryParams {
  name!: string;
  pageSize!: number;
  pageNumber!: number;
  roleCodeList!: string[];
  excludeRoleCodeList!: string[];
  terminalIds?: string[];
  excludeStatuses?: number[];
}

export class GetStatusesRequest extends QueryParams {
  status!: string;
  pageSize!: number;
  pageNumber!: number;
}

export class CreateUserRequest extends QueryParams {}

export class UpdateUserRequest extends QueryParams {}

export class DeleteUserRequest extends QueryParams {
  id!: string;
}

export class ValidateUserRequest extends QueryParams {
  id!: null | string;
  email!: string;
  dot!: number;
  roleCode!: string;
  driverRefId!: string;
}

export class GetUserAssociatedTerminalsRequest extends QueryParams {
  loggedUserId!: string;
}

export class GetDriverIdListRequest extends QueryParams {
  driverRefId!: string;
  pageSize!: number;
  pageNumber!: number;
  terminalIds?: string[];
}

export class GetInactiveUserDriverMetadataRequest extends QueryParams {
  constructor(
    private id: number,
    private key: string,
    public organizationId: number
  ) {
    super();
    this.id = id;
    this.key = key;
    this.organizationId = organizationId;
  }
}
export class GetInactiveUserDriveURLRequest extends QueryParams {
  constructor(
    private id: number,
    private key: string,
    public organizationId: number,
    private password: string
  ) {
    super();
    this.id = id;
    this.key = key;
    this.organizationId = organizationId;
    this.password = b64EncodeUnicode(password);
  }
}

export class GetEldIntegrationsRequest extends QueryParams {
  constructor(private integrationType: string) {
    super();
    this.integrationType = integrationType;
  }
}
export class GetEldIntegrationDriversRequest extends QueryParams {
  constructor(
    private credentialId: number,
    private pageNumber: number,
    private pageSize: number,
    private pattern: string,
    private linkedFilter: string,
    private sort: string
  ) {
    super(true);
    this.credentialId = credentialId;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.pattern = pattern;
    this.linkedFilter = linkedFilter;
    this.sort = sort;
  }
}

export class GetSafetyAlertListRequest extends QueryParams {
  driverId!: string;
}

export class GetDriverAlertListRequest extends QueryParams {}

export class GetDocumentTypesRequest extends QueryParams {}

export class DeleteSafetyAlertRequest extends QueryParams {
  ids: string[];
}

export class SafetyAlertBulkUpdateRequest extends QueryParams {}

export class CreateSafetyAlertRequest extends QueryParams {
  documentTypeId!: string;
  documentType!: string;
  driverId!: string;
  notes!: string;
  validFrom!: string;
  validTo!: string;
}
export class invitationsUserStatusRequest extends QueryParams {
  id: string;
  email: string;
  status: string;
  notifyUser?: boolean;
  constructor(data: any) {
    super(true);
    this.id = data.id;
    this.email = data.email;
    this.status = data.status;
    this.notifyUser = data.notifyUser;
  }
}

export class invitationsUserDeactivateRequest {
  message!: string;
  valid!: boolean;
}
export class resetPasswordRequest extends QueryParams {
  confirmPassword: string;
  oldPassword: string;
  password: string;
  constructor(data: resetPasswordType) {
    super();
    this.confirmPassword = b64EncodeUnicode(data.confirmPassword);
    this.oldPassword = b64EncodeUnicode(data.oldPassword);
    this.password = b64EncodeUnicode(data.password);
  }
}
export class resetOtherUserPasswordRequest extends QueryParams {
  value: string;
  actionType: string;
  accountId?: string;
  constructor(data: resetPasswordType, accountId?: string) {
    super();
    this.value = b64EncodeUnicode(data.password);
    this.actionType = 'CHANGE_PASSWORD';
    this.accountId = accountId;
  }
}
