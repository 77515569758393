export function addAsteriskForApiKey(apiKey: string) {
  if (apiKey.length <= 5) {
    return apiKey; // No need to modify if the string is too short
  }

  const part1 = apiKey.substring(0, 5); // First 5 characters
  const part2 = apiKey.substring(5); // Remaining characters

  let modifiedPart2 = '';
  for (let i = 0; i <= part2.length; i++) {
    modifiedPart2 = modifiedPart2 + '*';
  }
  return part1 + modifiedPart2.slice(0, -1);
}
