import { FinanceLoadType } from '../../../../models/DTOs/FinanceLoadService';
import { ContactSummary } from '../../../customers/models/customer';
import { LoadDetailsSummary } from '../models/LoadDetails';
import { getStopFormsData } from './';

export const getLoadDuplicateDetailsFromMyLoad = ({
  invoiceDetail,
  loadDuplicateDetails,
}: {
  invoiceDetail: FinanceLoadType;
  loadDuplicateDetails: any;
}) => {
  const {
    customer,
    lineItems,
    terminal,
    loadId,
    factoringId,
    factoringCompany,
    factoringChargePercent,
    tags,
  } = invoiceDetail;
  const { loadDetails, customerContactId, stops, loadType } =
    loadDuplicateDetails;
  const {
    commodityData = [],
    equipmentAdditionalDetails,
    trailerTypes = [],
    notes,
    noteDetails,
    totalWeight,
    factoringNotes,
  } = loadDetails || {};
  const {
    sealNumber,
    containerNumber,
    chassisNumber,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    lowerLimitTemperature,
    upperLimitTemperature,
  } = equipmentAdditionalDetails || {};

  const myCustomer = invoiceDetail?.id
    ? customer
    : loadDuplicateDetails.customerDetails ||
      (loadDuplicateDetails.ediDetails
        ? {
            ...loadDuplicateDetails.ediDetails,
            name: loadDuplicateDetails.ediDetails.customer,
            ediCustomerId: loadDuplicateDetails.ediDetails.customerIsa,
            id: loadDuplicateDetails.customerId,
          }
        : null);

  const getContact = (): ContactSummary | null => {
    const contact = myCustomer?.contacts?.find(
      (item: ContactSummary) => item.id == customerContactId
    );
    if (contact && !invoiceDetail?.id) {
      contact.fullName = contact.firstname;
    }
    return contact;
  };

  const response = new LoadDetailsSummary({
    id: loadId,
    commodityData: commodityData?.map((item) => ({
      ...item,
      commodityTypeDisplayValue: item.itemName,
      commodity: { id: item.commodityTypeId, itemName: item.itemName },
    })),
    sealNumber,
    containerNumber,
    chassisNumber,
    lowerLimitTemperature,
    upperLimitTemperature,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    notes,
    noteDetails,
    totalWeight,
    stops: getStopFormsData(stops),
    //CustomerDetailsForm begin
    customer: myCustomer,
    contact: getContact(),
    referenceId: '',
    factoringChargePercent:
      factoringChargePercent ??
      invoiceDetail?.factoringDetails?.factoringChargePercent,
    factoringNotes,
    factoring:
      ((factoringId || invoiceDetail?.factoringDetails?.factoringId) && {
        id: factoringId ?? invoiceDetail?.factoringDetails?.factoringId,
        displayName: invoiceDetail?.factoringDetails?.factoringCompany,
        factoringChargePercent:
          invoiceDetail?.factoringDetails?.factoringChargePercent,
      }) ||
      null,
    terminal:
      (terminal && { id: terminal.id, name: terminal.companyName }) || null,

    //CustomerDetailsForm end

    //InvoiceDetailsForm begin
    lineItems: lineItems || [],
    //InvoiceDetailsForm end

    //InvoiceAdditionalInfoForm begin
    proofOfDeliveryMandatory: invoiceDetail.proofOfDeliveryMandatory,
    billOfLadingMandatory: invoiceDetail.billOfLadingMandatory,
    invoiceNotes: invoiceDetail.notes,
    //InvoiceAdditionalInfoForm end

    //LoadDetails begin
    equipmentTypes: trailerTypes,
    loadType: loadType,
    //LoadDetails end
    tags: tags,
  });
  return response;
};
