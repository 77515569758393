import moment, { Moment } from 'moment';
import { AppointmentModelType } from '../../common/Appointment';
import { IMaintenanceHistory } from '../../models/DTOs/maintenance/maintenanceHistory/Model';

export const toUtcISOString = (date: Moment): string => {
  return moment.utc(moment(date).format('YYYYMMDD'), 'YYYYMMDD').toISOString();
};
export const dateFormOnlye = (date: Moment): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const toUtcDate = (date: Moment): Date => {
  return moment.utc(moment(date).format('YYYYMMDD'), 'YYYYMMDD').toDate();
};

export const toAppointment = (
  item: IMaintenanceHistory
): AppointmentModelType => {
  const titles = [item.historyMaintenanceItems?.[0]?.itemName];
  if (item.historyMaintenanceItems.length > 1) {
    titles.push('+');
    titles.push(`${item.historyMaintenanceItems.length}...`);
  }

  return {
    id: item.id,
    title: titles.join(' '),
    subTitle: item.equipmentName,
    iconName: item.historyMaintenanceItems[0]?.iconName,
    startDate: moment(new Date(item.completedDate)).startOf('date').toDate(),
    endDate: moment(new Date(item.completedDate)).endOf('date').toDate(),
    originalData: item,
  };
};
