import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import StorageManager from '../../../StorageManager/StorageManager';
import { alertsService } from '../../../api';
import AddNewButton from '../../../common/AddNewButton/AddNewButton';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';
import axeleRoutes from '../../../common/auxComponents/axeleRoutes';
import {
  DeleteLoadAlertDTO,
  GetLoadAlertsTypeDTO,
  GetLoadUserRolesDTO,
  GetStatic,
} from '../../../models';
import AxeleAlertDrawer from '../../../ui-kit/components/AxeleAlertDrawer';
import { CommonDataGrid } from '../../../ui-kit/components/DataGridPro';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import TabsV2, { ITabV2 } from '../../../ui-kit/components/TabsV2';
import ActionButton from './components/ActionButton';
import Cell from './components/Cell';
import {
  alertTabData,
  deletePopOver,
  editDataSet,
  filterDuplication,
  filterForName,
  filterNameDriver,
  filterTTDuplication,
  filterValidation,
  frequencyText,
  setDriverOptions,
  setLoadUsers,
  setOptions,
  setStaticOptions,
  showHideAddAlert,
  tabTypes,
  titleCase,
  updateText,
  validateDT,
  validateLoad,
  validateTractor,
  warningText,
} from './constants/constants';
const Alert = () => {
  const [selectedTab, setSelectedTab] = useState<tabTypes>('Load');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tabsData: alertTabData[] = [
    {
      label: 'Load',
      // @TODO temp icon should be removed once IconComponent will be removed
      iconName: selectedTab == 'Load' ? 'LoadsLightTemp' : 'LoadsIconTemp',
      source: 'AxeleIcon',
      key: 'Load',
    },
    {
      label: 'Driver',
      iconName: 'SupervisorAccountRounded',
      source: 'MUIcons',
      key: 'Driver',
    },
    {
      label: 'Tractor',
      iconName: selectedTab == 'Tractor' ? 'TractorLight' : 'Tractor',
      source: 'AxeleIcon',
      key: 'Tractor',
    },
    {
      label: 'Trailer',
      iconName: selectedTab == 'Trailer' ? 'TrailerLight' : 'Trailer',
      source: 'AxeleIcon',
      key: 'Trailer',
    },
  ];

  const loadCols: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
      renderCell: (params: any) => {
        return (
          <Cell
            params={params}
            iconName={params.row.iconKey ? params.row.iconKey : ''}
            source="MUIcons"
            variant="body1"
            textColor="primary.main"
            text={params.row.name}
            iconColor="primary.main"
          />
        );
      },
    },
    {
      field: 'sendAlertsTo',
      headerName: 'Send Alerts To',
      flex: 0.28,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.sendAlertsTo?.length > 0 && (
              <Cell
                params={params}
                iconName="SupervisedUserCircle"
                source="MUIcons"
                variant="caption"
                textColor="#001122"
                text={params.row.sendAlertsTo.toString()}
              />
            )}
          </>
        );
      },
    },
    {
      field: 'actionButton',
      headerName: '',
      flex: 0.02,
      renderCell: (params: any) => (
        <ActionButton
          params={params}
          performEdit={performEdit}
          setDeleteItem={setDeleteItem}
          setDeleteOpen={setDeleteOpen}
        />
      ),
    },
  ];

  const tableCols: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.33,
      renderCell: (params: any) => {
        return (
          <Cell
            params={params}
            iconName={params.row.iconPicker ? params.row.iconPicker : ''}
            source="MUIcons"
            variant="body1"
            textColor="primary.main"
            text={titleCase(params.row.name)}
            iconColor="primary.main"
          />
        );
      },
    },
    {
      field: 'preventDispatch',
      headerName: 'Prevent Dispatch',
      flex: 0.15,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.dispatchAllowedIfCertificationExpires && (
              <Cell
                params={params}
                iconName="PauseCircleOutline"
                source="MUIcons"
                variant="caption"
                textColor="text.secondary"
                text={
                  params.row.dispatchAllowedIfCertificationExpires
                    ? 'Will Prevent Dispatch'
                    : ''
                }
                iconColor="error.main"
              />
            )}
          </>
        );
      },
    },
    {
      field: 'warning',
      headerName: t('warning'),
      flex: 0.15,
      renderCell: (params: any) => {
        return (
          <Cell
            params={params}
            iconName="WarningAmber"
            source="MUIcons"
            variant="caption"
            textColor="text.secondary"
            text={
              params.row.warningDuration
                ? titleCase(params.row.warningDuration)
                : ''
            }
            multilineText={warningText(params.row)}
          />
        );
      },
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 0.15,
      renderCell: (params: any) => {
        return (
          <Cell
            params={params}
            iconName="CalendarToday"
            source="MUIcons"
            variant="caption"
            textColor="text.secondary"
            text={params.row.frequency ? titleCase(params.row.frequency) : ''}
            multilineText={frequencyText(params.row)}
          />
        );
      },
    },
    {
      field: 'sendAlertsTo',
      headerName: 'Send Alerts To',
      flex: 0.2,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.recipient?.length > 0 && (
              <Cell
                params={params}
                iconName="SupervisedUserCircle"
                source="MUIcons"
                variant="caption"
                textColor="#001122"
                text={
                  params.row.recipient
                    ? titleCase(params.row.recipient.toString())
                    : ''
                }
              />
            )}
          </>
        );
      },
    },
    {
      field: 'actionButton',
      headerName: '',
      flex: 0.02,
      renderCell: (params: any) => (
        <ActionButton
          params={params}
          performEdit={performEdit}
          setDeleteItem={setDeleteItem}
          setDeleteOpen={setDeleteOpen}
        />
      ),
    },
  ];

  const [tableColsData, setTableColsData] = useState<any[]>(loadCols);
  const [tableData, setTableData] = useState([]);
  const [showAlertPanel, setShowAlertPanel] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [alertTypes, setAlertsType] = useState([]);
  const [loadUserRoles, setLoadUserRoles] = useState([]);
  const [frequencyDropDown, setFrequencyDropDown] = useState([]);
  const [warningDropDown, setWarningDropDown] = useState([]);
  const [deleteItem, setDeleteItem] = useState<any>({});
  const [values, setValues] = useState({});
  const [alertsTypeRes, setAlertsTypeRes] = useState([]);
  const [action, setAction] = useState('add');
  const [validation, setValidation] = useState({ loadAlertsType: false });
  const [alertTypeDisabled, setAlertTypeDisabled] = useState(false);
  const userStorage = StorageManager.getUser() || {};

  useEffect(() => {
    getLoadAlertsList();
  }, []);

  const getLoadAlertsList = async () => {
    setIsLoading(true);
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    const loadAlertsTypeRes = await alertsService.getLoadAlertsList(request);
    setIsLoading(false);
    let list: any = [];
    loadAlertsTypeRes.loadAlertPrefDTOS.map((item: any) => {
      list = [
        ...list,
        {
          name: item.alertTypeRowTitle,
          sendAlertsToRoles: item.sendAlertsToRoles,
          alertReceiverStates: item.alertReceiverStates,
          sendAlertsTo: item.sendAlertsToRoles,
          organizationId: item.organizationId,
          tab: 'Load',
          iconKey: item.iconKey,
          alertTypeCardTitle: item.alertTypeCardTitle,
          id: item.id,
        },
      ];
    });
    setTableData(list);
    getLoadsData();
  };

  const onAddAlertClicked = async () => {
    setAction('add');
    setAlertTypeDisabled(false);
    setValidation({ loadAlertsType: false });
    setValues(
      selectedTab == 'Tractor'
        ? {
            ['Time-Based Frequency/Warning']: true,
            ['If Expired, Prevent Dispatch']: true,
          }
        : { ['If Expired, Prevent Dispatch']: true }
    );
    if (selectedTab == 'Load') getLoadsData();
    else if (selectedTab == 'Driver') getDriverTabData(tableData);
    else if (selectedTab == 'Trailer') getTrailerTabData(tableData);
    else if (selectedTab == 'Tractor') getTractorTabData(tableData);
    handleAlertDrawer();
  };

  const getDriverAlertsList = async () => {
    setIsLoading(true);
    const typeReq = new GetLoadAlertsTypeDTO();
    typeReq.organizationId = userStorage.organizationId;
    const altRes = await alertsService.getDriverAlertsType(typeReq);
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    const driverListRes = await alertsService.getDriverAlertsList(request);
    setIsLoading(false);
    let list: any = [];
    driverListRes.safety.map((item: any) => {
      list = [
        ...list,
        {
          name: filterNameDriver(item, altRes),
          preventDispatch: item.dispatchAllowedIfCertificationExpires,
          warning: item.warningDuration,
          frequency: item.frequency,
          sendAlertsTo: item.recipient,
          tab: 'Driver',
          ...item,
        },
      ];
    });
    setTableData(list);
    getDriverTabData(list, altRes);
  };

  const getTractorAlertsList = async () => {
    setIsLoading(true);
    const typeReq = new GetLoadAlertsTypeDTO();
    typeReq.organizationId = userStorage.organizationId;
    const altRes = await alertsService.getTractorAlertsType(typeReq);
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    const tractorListRes = await alertsService.getTractorAlertsList(request);
    setIsLoading(false);
    let list: any = [];
    tractorListRes.tractorMaintenances.map((item: any) => {
      list = [
        ...list,
        {
          name: filterForName(item, altRes),
          preventDispatch: item.dispatchAllowedIfCertificationExpires,
          warning: item.warningDuration,
          frequency: item.frequency,
          tab: 'Tractor',
          sendAlertsTo: item.recipient ? item.recipient : '',
          ...item,
        },
      ];
    });
    setTableData(list);
    getTractorTabData(list, altRes);
  };

  const getTrailerAlertsList = async () => {
    setIsLoading(true);
    const typeReq = new GetLoadAlertsTypeDTO();
    typeReq.organizationId = userStorage.organizationId;
    const altRes = await alertsService.getTrailerAlertsType(typeReq);
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    const trailerListRes = await alertsService.getTrailerAlertsList(request);
    setIsLoading(false);
    let list: any = [];
    trailerListRes.trailerMaintenances.map((item: any) => {
      list = [
        ...list,
        {
          name: filterForName(item, altRes),
          preventDispatch: item.dispatchAllowedIfCertificationExpires,
          warning: item.warningDuration,
          frequency: item.frequency,
          tab: 'Trailer',
          sendAlertsTo: item.recipient ? item.recipient : '',
          ...item,
        },
      ];
    });
    setTableData(list);
    getTrailerTabData(list);
  };

  const performEdit = async (params: any) => {
    setAction('edit');
    setAlertTypeDisabled(true);
    const { row } = params;

    if (row.tab == 'Load')
      await makeLoadEdit(row.name, row.sendAlertsTo ? row.sendAlertsTo : []);
    else
      setAlertsType([
        {
          label: titleCase(
            row.tab == 'Driver' ? row.documentType : row.maintenanceType
          ),
          key: row.tab == 'Driver' ? row.documentType : row.maintenanceType,
        },
      ]);

    const tempValues = await editDataSet(row, row.tab);
    setValues(tempValues);
    setValidation({ loadAlertsType: false });
    handleAlertDrawer();
  };

  const makeLoadEdit = async (name: string, sendAlertsTo: string[]) => {
    const temp = [
      {
        label: name,
        key: name,
      },
    ];
    setAlertsType(temp);
    const requestLoadUserRoles = new GetLoadUserRolesDTO();
    requestLoadUserRoles.organizationId = userStorage.organizationId;
    requestLoadUserRoles.loadAlertType = name;
    const loadUserRolesRes = await alertsService.getLoadUserRoles(
      requestLoadUserRoles
    );
    const tempOptions = await setOptions(loadUserRolesRes);
    const tempUsers = await setLoadUsers(sendAlertsTo, tempOptions);
    setLoadUserRoles(tempUsers);
  };

  const performDelete = async () => {
    const request = new DeleteLoadAlertDTO();
    request.id = deleteItem.row.id;
    request.organizationId = userStorage.organizationId;
    if (selectedTab == 'Load') {
      await alertsService.deleteLoadAlert(request);
      setDeleteOpen(false);
      getLoadAlertsList();
    } else if (selectedTab == 'Driver') {
      request.documentType = deleteItem.row.documentType;
      request.documentTypeId = deleteItem.row.documentTypeId;
      request.dispatchAllowedIfCertificationExpires =
        deleteItem.row.dispatchAllowedIfCertificationExpires;
      await alertsService.deleteDriverAlert(request, selectedTab);
      setDeleteOpen(false);
      getDriverAlertsList();
    } else if (selectedTab == 'Tractor') {
      request.maintenanceType = deleteItem.row.maintenanceType;
      request.maintenanceTypeId = deleteItem.row.maintenanceTypeId;
      request.dispatchAllowedIfCertificationExpires =
        deleteItem.row.dispatchAllowedIfCertificationExpires;
      await alertsService.deleteDriverAlert(request, selectedTab);
      setDeleteOpen(false);
      getTractorAlertsList();
    } else if (selectedTab == 'Trailer') {
      request.maintenanceType = deleteItem.row.maintenanceType;
      request.maintenanceTypeId = deleteItem.row.maintenanceTypeId;
      request.dispatchAllowedIfCertificationExpires =
        deleteItem.row.dispatchAllowedIfCertificationExpires;
      await alertsService.deleteDriverAlert(request, selectedTab);
      setDeleteOpen(false);
      getTrailerAlertsList();
    }
  };

  const getLoadsData = async () => {
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    const loadAlertsTypeRes = await alertsService.getLoadAlertsType(request);
    setAlertsTypeRes(loadAlertsTypeRes);
    const loadAlertsTypeData = await setOptions(loadAlertsTypeRes);
    setAlertsType(loadAlertsTypeData);
  };

  const getLoadUserRoles = async (selectedAlertsType: string) => {
    const requestLoadUserRoles = new GetLoadUserRolesDTO();
    requestLoadUserRoles.organizationId = userStorage.organizationId;
    requestLoadUserRoles.loadAlertType = selectedAlertsType;
    const loadUserRolesRes = await alertsService.getLoadUserRoles(
      requestLoadUserRoles
    );
    const tempOptions = await setOptions(loadUserRolesRes);
    setLoadUserRoles(tempOptions);
  };

  const getDriverTabData = async (list: any, altRes?: any) => {
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    let driverAlertsTypeRes = [] as any;
    if (altRes) {
      driverAlertsTypeRes = altRes;
    } else {
      driverAlertsTypeRes = await alertsService.getDriverAlertsType(request);
    }
    setAlertsTypeRes(driverAlertsTypeRes);
    const tempDuplicate = await filterDuplication(driverAlertsTypeRes, list);
    const tempOptions = await setDriverOptions(tempDuplicate);
    setAlertsType(tempOptions);
    const requestStatic = new GetStatic();
    const staticRes = await alertsService.getStaticForAlerts(requestStatic);
    const tempFrequency = await setStaticOptions(staticRes.frequencyData);
    setFrequencyDropDown(tempFrequency);
    const tempWarning = await setStaticOptions(staticRes.warningDuration);
    setWarningDropDown(tempWarning);
    const tempUsers = await setStaticOptions(staticRes.recipientData);
    setLoadUserRoles(tempUsers);
  };

  const getTrailerTabData = async (list: any) => {
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    const driverAlertsTypeRes = await alertsService.getTrailerAlertsType(
      request
    );
    setAlertsTypeRes(driverAlertsTypeRes);
    const tempDuplicate = await filterTTDuplication(driverAlertsTypeRes, list);
    const tempOptions = await setDriverOptions(tempDuplicate);
    setAlertsType(tempOptions);
    const requestStatic = new GetStatic();
    const staticRes = await alertsService.getStaticForTrailer(requestStatic);
    const tempFrequency = await setStaticOptions(staticRes.frequencyData);
    setFrequencyDropDown(tempFrequency);
    const tempWarning = await setStaticOptions(staticRes.warningDuration);
    setWarningDropDown(tempWarning);
    const tempUsers = await setStaticOptions(staticRes.recipientData);
    setLoadUserRoles(tempUsers);
  };

  const getTractorTabData = async (list: any, tractorData?: any) => {
    const request = new GetLoadAlertsTypeDTO();
    request.organizationId = userStorage.organizationId;
    let driverAlertsTypeRes = [] as any;
    if (tractorData && tractorData?.length > 0) {
      driverAlertsTypeRes = tractorData;
    } else {
      driverAlertsTypeRes = await alertsService.getTractorAlertsType(request);
    }
    setAlertsTypeRes(driverAlertsTypeRes);
    const tempDuplicate = await filterTTDuplication(driverAlertsTypeRes, list);
    const tempOptions = await setDriverOptions(tempDuplicate);
    setAlertsType(tempOptions);
    const requestStatic = new GetStatic();
    const staticRes = await alertsService.getStaticForTractor(requestStatic);
    const tempFrequency = await setStaticOptions(staticRes.frequencyData);
    setFrequencyDropDown(tempFrequency);
    const tempWarning = await setStaticOptions(staticRes.warningDuration);
    setWarningDropDown(tempWarning);
    const tempUsers = await setStaticOptions(staticRes.recipientData);
    setLoadUserRoles(tempUsers);
  };

  const handleAlertDrawer: showHideAddAlert = () => {
    setShowAlertPanel((isShow) => !isShow);
  };

  const handleTabChange = (tabOption: ITabV2) => {
    const tab = tabOption.key as tabTypes;
    setSelectedTab(tab);
    if (tab == 'Load') {
      setTableData([]);
      setTableColsData(loadCols);
      getLoadAlertsList();
      setValues({});
    } else if (tab == 'Driver') {
      setTableData([]);
      setTableColsData(tableCols);
      getDriverAlertsList();
      setValues({});
    } else if (tab == 'Tractor') {
      setTableData([]);
      setTableColsData(tableCols);
      getTractorAlertsList();
      setValues({});
    } else if (tab == 'Trailer') {
      setTableData([]);
      setTableColsData(tableCols);
      getTrailerAlertsList();
      setValues({});
    }
  };

  const loadSubmit = async (data: any) => {
    const validation = await validateLoad(data);
    setValidation(validation);
    const tempFilter = await filterValidation(validation);
    if (isEmpty(tempFilter)) {
      let payload = {
        alertTypeCardTitle: 'load',
        alertTypeRowTitle: data.loadAlertsType,
        iconKey: data.icon,
        organizationId: userStorage.organizationId,
        sendAlertsToRoles: data.loadUserRoles,
        alertReceiverStates: {
          shouldSendEmail: !!data['Notify via Email'],
          shouldSendNotification: !!data.Notification,
          shouldSendSms: !!data['Notify via Phone'],
        },
      };
      const request = new GetLoadAlertsTypeDTO();
      request.organizationId = userStorage.organizationId;
      if (action == 'add') {
        await alertsService.createLoadAlert(request, payload);
      } else {
        payload = { ...payload, id: data.itemId };
        await alertsService.editLoadAlert(request, payload);
      }
      handleAlertDrawer();
      getLoadAlertsList();
    }
  };

  const driverSubmit = async (data: any, validationData: any) => {
    const tempValidation = await validateDT(data);
    if (data.frequency == 'CUSTOM') {
      tempValidation.customFrequencyEvery =
        validationData.customFrequencyEvery &&
        validationData.customFrequencyEvery != ''
          ? validationData.customFrequencyEvery
          : false;
    }

    setValidation(tempValidation);
    const tempFilter = await filterValidation(tempValidation);
    if (isEmpty(tempFilter)) {
      let documentTypeId: any = alertsTypeRes.filter(
        (item: any) => item.itemCode == data.loadAlertsType
      );
      if (data.loadAlertsType == 'USERDEFINED')
        documentTypeId = alertsTypeRes.filter(
          (item: any) =>
            item.itemCode == data.loadAlertsType &&
            item.itemName == data.uerdefinedLabel
        );

      let payload = {
        dispatchAllowedIfCertificationExpires: data[
          'If Expired, Prevent Dispatch'
        ]
          ? data['If Expired, Prevent Dispatch']
          : false,
        documentType: data.loadAlertsType,
        documentTypeId: documentTypeId[0].id ? documentTypeId[0].id : '',
        frequency: data.frequency,
        frequencyValue:
          data.frequency == 'CUSTOM' ? data.customFrequencyEvery : 0,
        iconPicker: data.icon,
        organizationId: userStorage.organizationId,
        recipient: data.loadUserRoles,
        updateDate: updateText,
        warningDuration: data.warning,
        warningDurationByDays: 0,
        alertReceiverStates: {
          shouldSendEmail: !!data['Notify via Email'],
          shouldSendSms: !!data['Notify via Phone'],
        },
      };
      if (action == 'add') {
        await alertsService.createDriverAlert(payload);
      } else {
        payload = { ...payload, id: data.itemId };
        await alertsService.editDriverAlert(payload);
      }
      handleAlertDrawer();
      getDriverAlertsList();
    }
  };

  const trailerSubmit = async (data: any, validationData: any) => {
    const tempValidation = await validateDT(data);
    if (data.frequency == 'CUSTOM') {
      tempValidation.customFrequencyEvery =
        validationData.customFrequencyEvery &&
        validationData.customFrequencyEvery != ''
          ? validationData.customFrequencyEvery
          : false;
    }
    setValidation(tempValidation);
    const tempFilter = await filterValidation(tempValidation);
    if (isEmpty(tempFilter)) {
      let documentTypeId: any = alertsTypeRes.filter(
        (item: any) => item.itemCode == data.loadAlertsType
      );
      if (data.loadAlertsType == 'USERDEFINED')
        documentTypeId = alertsTypeRes.filter(
          (item: any) =>
            item.itemCode == data.loadAlertsType &&
            item.itemName == data.uerdefinedLabel
        );

      let payload = {
        dispatchAllowedIfCertificationExpires: data[
          'If Expired, Prevent Dispatch'
        ]
          ? data['If Expired, Prevent Dispatch']
          : false,
        frequency: data.frequency,
        frequencyValue:
          data.frequency == 'CUSTOM' ? data.customFrequencyEvery : 0,
        iconPicker: data.icon,
        maintenanceType: data.loadAlertsType,
        maintenanceTypeId: documentTypeId[0].id ? documentTypeId[0].id : '',
        organizationId: userStorage.organizationId,
        recipient: data.loadUserRoles,
        updateDate: updateText,
        warningDuration: data.warning,
        warningDurationByDays: 0,
        alertReceiverStates: {
          shouldSendEmail: !!data['Notify via Email'],
          shouldSendSms: !!data['Notify via Phone'],
        },
      };
      if (action == 'add') {
        await alertsService.createTrailerAlert(payload);
      } else {
        payload = { ...payload, id: data.itemId };
        await alertsService.editTrailerAlert(payload);
      }
      handleAlertDrawer();
      getTrailerAlertsList();
    }
  };

  const tractorSubmit = async (data: any, validationData: any) => {
    const tempValidation = await validateTractor(data);
    if (data.frequency == 'CUSTOM') {
      tempValidation.customFrequencyEvery =
        validationData.customFrequencyEvery &&
        validationData.customFrequencyEvery != ''
          ? validationData.customFrequencyEvery
          : false;
    }
    setValidation(tempValidation);
    const tempFilter = await filterValidation(tempValidation);
    if (isEmpty(tempFilter)) {
      let documentTypeId: any = alertsTypeRes.filter(
        (item: any) => item.itemCode == data.loadAlertsType
      );
      if (data.loadAlertsType == 'USERDEFINED')
        documentTypeId = alertsTypeRes.filter(
          (item: any) =>
            item.itemCode == data.loadAlertsType &&
            item.itemName == data.uerdefinedLabel
        );

      let payload = {
        alertByMiles: data.milesBeforeWarning ? data.milesBeforeWarning : null,
        dispatchAllowedIfCertificationExpires: data[
          'If Expired, Prevent Dispatch'
        ]
          ? data['If Expired, Prevent Dispatch']
          : false,
        frequency: data.frequency ? data.frequency : null,
        frequencyByMiles: data.milesBasedFrequency
          ? data.milesBasedFrequency
          : null,
        frequencyValue:
          data.frequency && data.frequency == 'CUSTOM'
            ? data.customFrequencyEvery
            : 0,
        iconPicker: data.icon,
        maintenanceType: data.loadAlertsType,
        maintenanceTypeId: documentTypeId[0].id ? documentTypeId[0].id : '',
        organizationId: userStorage.organizationId,
        recipient: data.loadUserRoles,
        updateDate: updateText,
        warningDuration: data.warning ? data.warning : null,
        warningDurationByDays: 0,
        alertReceiverStates: {
          shouldSendEmail: !!data['Notify via Email'],
          shouldSendSms: !!data['Notify via Phone'],
        },
      };
      if (action == 'add') {
        await alertsService.createTractorAlert(payload);
      } else {
        payload = { ...payload, id: data.itemId };
        await alertsService.editTractorAlert(payload);
      }
      handleAlertDrawer();
      getTractorAlertsList();
    }
  };

  const onSubmit = (data: any, validationData: any) => {
    if (selectedTab == 'Load') loadSubmit(data);
    else if (selectedTab == 'Driver') driverSubmit(data, validationData);
    else if (selectedTab == 'Trailer') trailerSubmit(data, validationData);
    else if (selectedTab == 'Tractor') tractorSubmit(data, validationData);
  };

  return (
    <Grid sx={{ backgroundColor: 'uncategorized.panel', height: '100%' }}>
      <Grid container>
        <Grid item xs={10.5}>
          <TabsV2
            keyName="key"
            tabs={tabsData}
            selectedTab={selectedTab}
            setSelectedTab={handleTabChange}
          />
        </Grid>
        <Permission includes={[AXELE_PERMISSION_TYPE.ALERTS_ADD]}>
          <Grid item xs={1.5}>
            <AddNewButton title="Alert" onClick={() => onAddAlertClicked()} />
          </Grid>
        </Permission>
      </Grid>

      <Box sx={{ height: '100%', '> div': { ml: 0 } }}>
        {selectedTab == 'Load' ? (
          <CommonDataGrid
            loading={isLoading}
            tableDensity="comfortable"
            tableData={tableData}
            tableColumnsData={loadCols}
            checkboxSelection={false}
            isCardView={true}
            rowHeight={45}
            cardBackgroundColor="uncategorized.panel"
            displayFilters={false}
            settingsPanel={false}
          />
        ) : (
          <CommonDataGrid
            loading={isLoading}
            tableDensity="comfortable"
            tableData={tableData}
            tableColumnsData={tableCols}
            checkboxSelection={false}
            isCardView={true}
            rowHeight={45}
            cardBackgroundColor="uncategorized.panel"
            displayFilters={false}
            settingsPanel={false}
          />
        )}
      </Box>

      <AxeleAlertDrawer
        isOpen={showAlertPanel}
        alertType={selectedTab}
        onClose={handleAlertDrawer}
        onSubmit={onSubmit}
        alertTypes={alertTypes}
        getLoadUserRoles={getLoadUserRoles}
        loadUserRoles={loadUserRoles}
        values={values}
        frequencyDropDown={frequencyDropDown}
        warningDropDown={warningDropDown}
        setValues={setValues}
        action={action}
        validation={validation}
        alertTypeDisabled={alertTypeDisabled}
        setValidation={setValidation}
      />

      <DeletePopup
        open={deleteOpen}
        onAction={() => performDelete()}
        onClose={() => {
          setDeleteOpen(false);
        }}
        title={deletePopOver.title}
        body={deletePopOver.body}
      />
    </Grid>
  );
};

export default Alert;

axeleRoutes.addRoute({
  path: '/settings/alerts',
  pathId: 'alerts',
  parentId: 'layout',
  element: <Alert />,
});
