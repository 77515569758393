import React, { useEffect, useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import {
  AssetsCount,
  DriverTrailerAssignResponse,
  ExpensesStaticDataType,
  TrailerAlertSummaryDTO,
  TrailerResponse,
} from '../../../models';
import DetailsTabStrips, {
  tabStripRendererByKeyFS,
} from '../../tractors/components/DetailsTabStrips';
import { Trailer } from '../models/Trailer';

import { useTheme } from '@mui/material';
import { t } from 'i18next';
import { trailerService } from '../../../api';
import { getTerminals } from '../../../common/Layouts/main/topMenu/constants/helper';
import { EQUIPMENT_PANEL_TABS } from '../../../constants/contacts';
import { useEquipment } from '../../../contexts/EquipmentContext';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import { renderCustomTitle } from '../../../views/operations/equipment/AssetsPanelCustomTitle';
import { useSafetyAlert } from '../../../views/safety/alerts/context/SafetyAlertContext';
import MaintenanceController from '../../equipment/maintenance/controllers/Controller';
import {
  getDocumentTypeList,
  getStateOptions,
} from '../../expenses/utils/utils';
import { saveFile, updateTractorAndTrailerDocs } from '../../shared/equipment';
import TractorController from '../../tractors/Controller';
import { ActionsSection } from '../actionsSection';
import { TrailerSummary } from '../models/TrailerSummary';
import TrailerController from '../TrailerController';
import TrailerDetailsForm, {
  getStaticDataTrailerDetails,
} from './TrailerDetailsForm';
import { EQUIPMENT_TRAILER_VALIDATION_SCHEMA } from './ValidationSchema';
const deletePopupConfigs = (message: string) => ({
  allowedDelete: {
    allow: true,
    body: 'Are you sure you want to delete this trailer?',
  },
  notAllowedDelete: {
    body: `${message}.`,
  },
});

interface IProps {
  data: any;
  onClose: () => void;
  onUpdated: (data: any) => void;
  onDeleted: (data: any) => void;
  onCreated: (data: any) => void;
  defaultTab?: EQUIPMENT_PANEL_TABS;
  autoExpanded?: boolean;
  isGlobal?: boolean;
  onUpdateWarningsCount: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
  onAlertUpdated?: (response: any) => void;
}

export default function TrailerDetailsPanel({
  data,
  onClose,
  onUpdated,
  onUpdateWarningsCount,
  onDeleted,
  onCreated,
  defaultTab = EQUIPMENT_PANEL_TABS.GENERAL,
  autoExpanded = false,
  isGlobal = false,
  onAlertUpdated,
}: IProps) {
  const { setEquipmentCount } = useEquipment();
  const [trailerData, setTrailerData] = useState(data);
  const [httpErrors, setHttpErrors] = useState();
  const [, setDeletePopup] = useState<{
    allow?: boolean;
    body: string;
  } | null>();

  const staticDataRef = React.useRef<ExpensesStaticDataType>({
    stateOptions: [],
    documentTypes: [],
    terminalsList: [],
  });

  const [selectedTab, setSelectedTab] =
    useState<EQUIPMENT_PANEL_TABS>(defaultTab);

  const fetchDataMaintenanceTab = async (): Promise<void> => {
    const { fetchMaintenanceHistoriesByEquipment } =
      MaintenanceController.instance();
    const maintenanceHistoryList = await fetchMaintenanceHistoriesByEquipment({
      assetId: data.id,
      assetType: EquipmentType.Trailer,
    });
    setTrailerData((oldVal: any) => ({
      ...oldVal,
      maintenanceHistoryList,
    }));
  };

  const getStaticData = async () => {
    const documentTypes = await getDocumentTypeList();
    const terminalsList = await getTerminals();
    const stateOptions = await getStateOptions();

    staticDataRef.current = {
      stateOptions,
      documentTypes,
      terminalsList: terminalsList.content,
    };
  };

  useEffect(() => {
    getStaticData();
  }, []);

  useEffect(() => {
    if (data.id || selectedTab === EQUIPMENT_PANEL_TABS.ALERTS) {
      const { getTrailerById, getTrailerAlertList } =
        TrailerController.instance();

      let alertsList: TrailerAlertSummaryDTO[] = [];

      getTrailerAlertList(data.id, (alerts) => {
        alertsList = alerts;
        getTrailerById(data.id, (trailer: TrailerSummary | Trailer) => {
          setTrailerData({ ...trailer, alertsList });
        });
      });

      fetchDataMaintenanceTab();
    }
  }, [data.id, selectedTab]);

  const { fetchMainData } = useSafetyAlert();

  const handleUpdate = (
    panelTab: EQUIPMENT_PANEL_TABS,
    updatedTrailer: TrailerSummary
  ): void => {
    const { updateTrailer, updateTrailerAlertsBulk } =
      TrailerController.instance();

    switch (panelTab) {
      case EQUIPMENT_PANEL_TABS.ALERTS:
        if (updatedTrailer.alertsList?.length) {
          updateTrailerAlertsBulk(updatedTrailer.alertsList, (alertsList) => {
            fetchMainData?.();
            onAlertUpdated?.(alertsList);
            let warnings = 0;
            let criticals = 0;
            alertsList?.map((alert) => {
              if (
                alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL'
              ) {
                criticals += 1;
              }
              if (
                alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING'
              ) {
                warnings += 1;
              }
            });
            if (typeof onUpdateWarningsCount === 'function') {
              onUpdateWarningsCount(trailerData.id, warnings, criticals, false);
            }
            setTrailerData({ ...trailerData, alertsList });
          });
        }
        break;

      case EQUIPMENT_PANEL_TABS.MAINTENANCE:
        break;

      default:
        updateTrailer(
          { ...updatedTrailer, id: trailerData.id },
          async (res, errors) => {
            if (errors) {
              return setHttpErrors(errors);
            }
            await updateTractorAndTrailerDocs(
              updatedTrailer,
              trailerData,
              staticDataRef.current,
              EquipmentType.Trailer
            );
            const data = await trailerService.getTrailerById(trailerData.id);
            onUpdated?.(new TrailerSummary(res));
            setTrailerData({ ...new Trailer(data) });
          }
        );
        break;
    }
  };

  const handleCreate = (newTrailer: TrailerSummary): void => {
    TrailerController.instance().createTrailer(
      newTrailer,
      async (res, errors) => {
        if (errors) {
          return setHttpErrors(errors);
        }
        setEquipmentCount({
          ...TractorController.instance().equipmentCount,
        } as AssetsCount);
        const trailerData = {
          ...res,
          assignedDocuments: newTrailer?.assignedDocuments,
        };
        await saveFile(
          trailerData,
          staticDataRef.current,
          EquipmentType.Trailer
        );
        const data = await trailerService.getTrailerById(trailerData.id);
        setTrailerData({ ...new Trailer(data), alertsList: [] });
        onCreated(new TrailerSummary(res));
      }
    );
  };

  const handleTabStripChange = (selected: EQUIPMENT_PANEL_TABS): void => {
    setSelectedTab(selected);
  };

  function handleDeleteClick() {
    TrailerController.instance().verifyTrailerDeletion(
      trailerData.id,
      (canDelete) => {
        if (canDelete.assetIsAllowedToDelete) {
          setDeletePopup(deletePopupConfigs(canDelete.message).allowedDelete);
        } else {
          setDeletePopup(
            deletePopupConfigs(canDelete.message).notAllowedDelete
          );
        }
      }
    );
  }

  const deactivateTrailer = () => {
    const updatedData = { ...trailerData, status: 'INACTIVE' };
    setTrailerData(updatedData);
    onUpdated?.(new TrailerSummary(updatedData));
  };

  const theme: any = useTheme();
  const panelWrapperCustomStyles = {
    display: 'flex',
    width: '33.3%',
    padding: '0 5px 5px 0',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette.leftMenuCollapsed.canvas,
    boxShadow: 'unset',
  };

  const tabStripRenderer = (keys: EQUIPMENT_PANEL_TABS[]) => (
    <DetailsTabStrips
      keys={keys}
      defaultTab={defaultTab}
      onChange={handleTabStripChange}
      tractorId={trailerData?.id}
    />
  );
  const isEdit = !!trailerData.id;
  const onFullscreen = (isFullscreen: boolean): void => {
    //set default tab when fullscreen
    setSelectedTab(
      isFullscreen && isEdit ? EQUIPMENT_PANEL_TABS.MAINTENANCE : defaultTab
    );
  };
  const entity = 'Trailer';

  useEffect(() => {
    getStaticDataTrailerDetails();
  }, []);

  const handleTrailerDriverAssign = async (updatedData: any) => {
    const driverGroupId = updatedData?.driverGroup.id;
    const trailerId = data.id;
    const result = await TrailerController.instance().driverTrailerAssign(
      driverGroupId,
      trailerId
    );
    if (result instanceof DriverTrailerAssignResponse) {
      const getTrailer = await trailerService.getTrailerById(data.id);
      const updatedData = { ...data };
      updatedData.driver = {
        name: result.driverGroupName,
        id: result.driverGroupId,
      };
      updatedData.status = 'ASSIGNED';
      updatedData.locationSource = 'DRIVER';
      updatedData.lastLocation =
        getTrailer.assetLocationDTO.addressDTO?.fullAddress;
      setTrailerData((prevValues: Trailer) => ({
        ...prevValues,
        driverGroup: { name: result.driverGroupName, id: result.driverGroupId },
        status: 'ASSIGNED',
        locationSource: 'DRIVER',
        lastLocation: {
          address: getTrailer.assetLocationDTO.addressDTO?.address,
          city: getTrailer.assetLocationDTO.addressDTO?.city,
          fullAddress: getTrailer.assetLocationDTO.addressDTO?.fullAddress,
          state: getTrailer.assetLocationDTO.addressDTO?.state,
          streetAddress: getTrailer.assetLocationDTO.addressDTO?.streetAddress,
          streetAddress2:
            getTrailer.assetLocationDTO.addressDTO?.streetAddress2,
          zipcode: getTrailer.assetLocationDTO.addressDTO?.zipcode,
          timezone: getTrailer.assetLocationDTO.addressDTO?.timezone,
        },
      }));
      onUpdated?.(new TrailerSummary(updatedData));
      return true;
    }
  };

  const handleTrailerDriverUnAssign = async (driverGroupId: string) => {
    const result = await TrailerController.instance().driverTrailerUnAssign(
      driverGroupId
    );
    if (result instanceof DriverTrailerAssignResponse) {
      const updatedData = { ...data };
      updatedData.driver = null;
      updatedData.status = 'AVAILABLE';
      updatedData.locationSource = '';
      updatedData.lastLocation = null;
      setTrailerData((prevValues: Trailer) => ({
        ...prevValues,
        driverGroup: null,
        status: 'AVAILABLE',
        locationSource: null,
        lastLocation: {
          fullAddress: '',
          address: null,
          streetAddress: null,
          streetAddress2: null,
          timezone: null,
          zipcode: null,
          city: null,
          center: null,
          state: null,
        },
      }));
      onUpdated?.(new TrailerSummary(updatedData));
      return true;
    }
  };

  const handleTrailerStatusChange = async (
    trailerId: number,
    status: string
  ) => {
    const result = await TrailerController.instance().changeTrailerStatus(
      trailerId,
      status
    );
    if (result[0] instanceof TrailerResponse) {
      const updatedData = { ...data };
      updatedData.status = result[0].status;
      setTrailerData((prevValues: Trailer) => ({
        ...prevValues,
        status: result[0].status,
      }));
      onUpdated?.(new TrailerSummary(updatedData));
      return true;
    }
  };

  const [openDialog, setOpenDialog] = useState<
    'markAvailable' | 'markUnavailable' | 'delete' | ''
  >('');
  const [showPopup, setShowPopup] = useState<{
    allow?: boolean;
    body?: string;
  } | null>();

  const actionsRenderer = () => {
    if (trailerData.id) {
      return (
        <ActionsSection
          onDeleted={onDeleted}
          trailerData={trailerData}
          onUnassign={handleTrailerDriverUnAssign}
          onStatusChange={handleTrailerStatusChange}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          deactivateTrailer={deactivateTrailer}
        />
      );
    }
  };

  const panelTitle = trailerData.id
    ? `${t('trailer')}: ${trailerData.trailerName || ''}`
    : t('addNewTrailer');

  const renderCustomTitleWrapper =
    trailerData.id && (() => renderCustomTitle(trailerData));

  return (
    <>
      <DetailsPanel
        data={trailerData}
        actionButtonLabel={trailerData.id ? undefined : t('addTrailer')}
        entity={entity}
        panelTitle={panelTitle}
        isSaveIconVisible={trailerData.id}
        renderCustomTitle={renderCustomTitleWrapper}
        onClose={onClose}
        onUpdate={(userData) => handleUpdate(selectedTab, userData)}
        onDelete={handleDeleteClick}
        onCreate={handleCreate}
        contentRenderer={(expanded: boolean) => (
          <TrailerDetailsForm
            expanded={expanded}
            selectedTab={selectedTab}
            onAssign={handleTrailerDriverAssign}
            onUpdateWarningsCount={onUpdateWarningsCount}
            data={trailerData}
            setOpenDialog={setOpenDialog}
            setShowPopup={setShowPopup}
          />
        )}
        tabStripRenderer={() =>
          tabStripRenderer([
            EQUIPMENT_PANEL_TABS.GENERAL,
            EQUIPMENT_PANEL_TABS.ALERTS,
            EQUIPMENT_PANEL_TABS.MAINTENANCE,
            EQUIPMENT_PANEL_TABS.DOCUMENT,
            EQUIPMENT_PANEL_TABS.NOTES,
          ])
        }
        validationSchema={EQUIPMENT_TRAILER_VALIDATION_SCHEMA[selectedTab]}
        httpErrors={httpErrors}
        autoExpanded={autoExpanded}
        isGlobal={isGlobal}
        onFullscreen={onFullscreen}
        {...(isEdit && {
          fullscreen: {
            panelPropList: [
              {
                data: trailerData,
                entity: entity,
                panelId: EQUIPMENT_PANEL_TABS.GENERAL,
                panelTitle,
                renderCustomTitle: renderCustomTitleWrapper,
                validationSchema:
                  EQUIPMENT_TRAILER_VALIDATION_SCHEMA[
                    EQUIPMENT_PANEL_TABS.GENERAL
                  ],
                onUpdate: (userData) =>
                  handleUpdate(EQUIPMENT_PANEL_TABS.GENERAL, userData),
                contentRenderer: () => (
                  <TrailerDetailsForm
                    expanded={false}
                    selectedTab={EQUIPMENT_PANEL_TABS.GENERAL}
                    onUpdateWarningsCount={onUpdateWarningsCount}
                    onAssign={handleTrailerDriverAssign}
                    data={data}
                    setOpenDialog={setOpenDialog}
                    setShowPopup={setShowPopup}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '18px 0 0 18px', //override border radius
                },
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(EQUIPMENT_PANEL_TABS.GENERAL),
              },
              {
                data: trailerData,
                entity: entity,
                panelId: EQUIPMENT_PANEL_TABS.ALERTS,
                validationSchema:
                  EQUIPMENT_TRAILER_VALIDATION_SCHEMA[
                    EQUIPMENT_PANEL_TABS.ALERTS
                  ],
                onUpdate: (userData) =>
                  handleUpdate(EQUIPMENT_PANEL_TABS.ALERTS, userData),
                contentRenderer: () => (
                  <TrailerDetailsForm
                    expanded={false}
                    selectedTab={EQUIPMENT_PANEL_TABS.ALERTS}
                    onUpdateWarningsCount={onUpdateWarningsCount}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: 0, //override border radius
                },
                panelTitle: '',
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(EQUIPMENT_PANEL_TABS.ALERTS),
              },
              {
                data: trailerData,
                entity: entity,
                panelTitle: ``,
                panelId: 'CurrentContentForm',
                validationSchema:
                  EQUIPMENT_TRAILER_VALIDATION_SCHEMA[selectedTab],
                onUpdate: (userData) => handleUpdate(selectedTab, userData),
                contentRenderer: () => {
                  return (
                    <TrailerDetailsForm
                      expanded={false}
                      selectedTab={selectedTab}
                      onUpdateWarningsCount={onUpdateWarningsCount}
                    />
                  );
                },
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '0 18px 18px 0', //override border radius
                },
                tabStripRenderer: () => {
                  return tabStripRenderer([EQUIPMENT_PANEL_TABS.MAINTENANCE]);
                },
              },
            ],
          },
        })}
        actionsRenderer={actionsRenderer}
      />
    </>
  );
}
