import { Box, Typography } from '@mui/material';
import { GanttLoaderStyles } from '../styles';
import { LoaderConfigProps } from '../types';
import { defaultLoaderConfig } from '../config';
import React from 'react';

const GanttLoader: React.FC<LoaderConfigProps> = (props) => {
  const {
    status = false,
    text,
    icon = defaultLoaderConfig.icon,
    textStyles,
  } = props;
  if (!status) return <></>;
  return (
    <Box alignItems="center" display={'flex'} ml={1}>
      {icon}
      <Typography sx={{ ...GanttLoaderStyles, ...textStyles }}>
        {text}
      </Typography>
    </Box>
  );
};

export default GanttLoader;
