import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { contractServive } from '../../../api';
import { SingleAutocompleteForm } from '../../../common/Ui/SingleAutocomplete';
import { ContractDetailsDTO, GetContractOneRequest } from '../../../models';
import {
  RootStoreInstence,
  useRootStore,
} from '../../../store/root-store/rootStateContext';
import { useStores } from '../../../store/root.context';
import { WarningLoadListIcon } from '../../../ui-kit/components/Assets';
import { amountFormat, getFormattedDatewithTimeZone } from '../../../utils';
import InvoiceActions from '../InvoiceActions';
import { TagList } from '@/common/Tag/components/TagList';

export const loadGroupInvoiceItem = memo(({ data }: { data: any }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      width={'100%'}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="body4">loadGroupInvoiceItem</Typography>
        <Typography variant="body4">loadGroupInvoiceItem</Typography>
      </Stack>
      <Box>$7888 </Box>
    </Stack>
  );
});
loadGroupInvoiceItem.displayName = 'loadGroupInvoiceItem';

export const CustomeTextLine = ({
  option,
  isDropDown,
  isCreating = false,
  isWarning = false,
}: {
  option: any;
  isDropDown: boolean;
  isCreating?: boolean;
  isWarning?: boolean;
}) => {
  const {
    invoiceStore: { isInvoiceCreate, selectedItem, setOpenSecondaryPanel },
  } = useStores();
  const [contractDetails, setContractDetails] =
    useState<ContractDetailsDTO | null>(null);

  useEffect(() => {
    if (!option?.lineItems) {
      setContractDetails(null);
      return;
    }
    async function getContractDetails(id: string) {
      try {
        const response = await contractServive.getContractByIdWithoutCancel(
          new GetContractOneRequest({
            id: id,
          })
        );
        setContractDetails(response);
      } catch (error) {}
    }
    const contract: any = option?.lineItems?.find((e: any) =>
      Boolean(e?.contractId)
    );
    if (contract?.contract?.name) {
      return setContractDetails(contract?.contract);
    }
    if (!contract?.contractId) {
      setContractDetails(null);
      return;
    }
    getContractDetails(contract?.contractId);
  }, [JSON.stringify(option?.lineItems)]);

  return (
    <>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '600',
          color: 'primary.main',
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            color: 'primary.main',
            display: 'flex',
            textDecoration: isDropDown && isCreating ? '' : 'underline',
          }}
          onClick={() => {
            setOpenSecondaryPanel({
              id: option.loadId,
              open: true,
            });
          }}
        >
          {isWarning && (
            <Box
              component="span"
              sx={{ display: 'flex', pr: '4px', mt: '5px' }}
            >
              <WarningLoadListIcon color="#FFA726" width="22" height="14" />
            </Box>
          )}
          {option?.seqNumber || option?.loadSeqNumber}
        </Typography>
        {(option?.loadReferenceId || option?.invoiceReferenceId) && (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: 'text.secondary',
              pl: '6px',
              pt: '3px',
            }}
          >
            (Ref: {option?.loadReferenceId ?? option?.invoiceReferenceId})
          </Typography>
        )}
      </Typography>

      <Stack
        direction="row"
        flexWrap="wrap"
        gap={1}
        sx={{ mt: '5px', mb: '5px' }}
      >
        <TagList tags={option.tags} />
      </Stack>

      <Typography
        variant="body4"
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          color: 'text.secondary',
          display: 'inline-block',
          lineHeight: '18px',
        }}
      >
        {option?.originCity}, {option?.originState} - {option?.destinationCity},{' '}
        {option?.destinationState || ''} {!isInvoiceCreate && <br></br>}
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '12px',
            pl: isInvoiceCreate ? '5px' : '0PX',
          }}
          variant="body4"
        >
          Loaded:
        </Typography>{' '}
        {option?.loadedMiles || 0}mi <span style={{ margin: '0 2px' }}>|</span>
        <Typography
          sx={{ fontWeight: '600', fontSize: '12px', pl: '5px' }}
          variant="body4"
        >
          Empty:
        </Typography>{' '}
        {option?.emptyMiles || 0}mi <br />
        {option?.completeDate && (
          <>
            <Typography
              sx={{ fontWeight: '600', fontSize: '12px' }}
              variant="body4"
            >
              Completed on:
            </Typography>{' '}
            {getFormattedDatewithTimeZone(option?.completeDate)}
            {contractDetails && <span style={{ margin: '0 3px' }}>|</span>}
          </>
        )}
        {contractDetails && (
          <>
            <Typography
              sx={{ fontWeight: '600', fontSize: '12px' }}
              variant="body4"
            >
              Contract:
            </Typography>{' '}
            {contractDetails?.name}{' '}
            {contractDetails?.customerName &&
              ` - ${contractDetails?.customerName}`}
          </>
        )}
      </Typography>
    </>
  );
};
export const SingleAutocompleteGroupinvoice = ({
  disabled,
  canEditInvoice,
}: {
  disabled: boolean;
  canEditInvoice: boolean;
}) => {
  const { getIsTerminalEnabled } = useRootStore();

  const { control, setValue, watch, getValues } = useFormContext();
  const customerInfo = watch('customer');
  const terminalId = watch('terminal');
  const isLoadSet = watch('isLoadSet');
  const childInvoices: [] = watch('childInvoices');
  const loadList = getValues('loadList');
  const status = getValues('status');

  setValue('loadList', null);
  useEffect(() => {
    if (!loadList) return;
    setValue('loadList', null);
    setValue('loadList', '');
  }, [loadList]);

  useEffect(() => {
    if (!customerInfo) {
      setValue('childInvoices', []);
    }
  }, [terminalId, customerInfo]);

  const getPlaceHolderRender = () => {
    return (
      <Typography
        sx={{
          color:
            !(customerInfo?.id && (terminalId || !getIsTerminalEnabled)) ||
            isLoadSet ||
            status === 'PAID'
              ? '#b1b1b1bf'
              : 'primary.main',
          fontSize: '13px',
          // height: '28px',
        }}
      >
        <SearchIcon
          sx={{
            fontSize: '13px',
          }}
        />{' '}
        Add Load
      </Typography>
    );
  };

  const getLoadByInvoiceData = async (data: any) => {
    setValue('isLoadSet', true);
    const result = await InvoiceActions.previewInvoice(data.invoiceId);
    const updatedList = childInvoices;
    if (result) {
      data = { ...data, ...result };
      updatedList.push(data);
      setValue('childInvoices', updatedList);
      setValue('isLoadSet', false);
    }
  };

  return (
    <SingleAutocompleteForm
      disabled={
        !(customerInfo?.id && (terminalId || !getIsTerminalEnabled)) ||
        isLoadSet ||
        status === 'PAID' ||
        disabled ||
        !canEditInvoice
      }
      getOptions={(seqNumber, pageNumber) =>
        InvoiceActions.getInvoiceSearchLoads(
          pageNumber,
          seqNumber,
          customerInfo?.id,
          terminalId?.id,
          childInvoices
        )
      }
      disableClear
      {...{
        variant: 'outlined',
      }}
      onChangeCb={(data: any) => {
        if (!data) return;
        getLoadByInvoiceData(data);
      }}
      disableClear
      renderOption={{
        enableOptionSubtitle: true,
        renderOptionSubTitle: (option: any): JSX.Element => {
          return <LoadItemInvoice data={option} />;
        },
      }}
      label={getPlaceHolderRender()}
      styledOption={{
        height: '70px !important',
      }}
      control={control}
      name="loadList"
      customFilterOptions={(options) => options}
      // fieldName="LoadItem"
      // blurOnSelect={true}
    />
  );
};

export const LoadItemInvoice = memo(({ data }: { data: any }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        width={'100%'}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <CustomeTextLine option={data} isDropDown={true} />
        </Stack>
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: '400',
            color: 'primary.main',
          }}
        >
          {amountFormat(data.total || 0)}
        </Box>
      </Stack>
    </>
  );
});
// LoadItemInvoice.displayName = 'LoadItem';x

export const loadGroupInvoiceTitleRender = (
  option: any,
  isCreating: boolean,
  isWarning: boolean
) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        width={'100%'}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <CustomeTextLine
            option={option}
            isDropDown={false}
            isCreating={isCreating}
            isWarning={isWarning}
          />

          {/* {customeTextLine(option, false, isCreating, isWarning)} */}
        </Stack>
      </Stack>
    </>
  );
};
export const InvoiceLoader = () => {
  return (
    <>
      <Stack direction="column">
        <Skeleton height={'3rem'} variant="rectangular" width={'100%'} />
        <Skeleton height={'3rem'} variant="rectangular" width={'100%'} />
        <Skeleton height={'3rem'} variant="rectangular" width={'100%'} />
        <Skeleton height={'3rem'} variant="rectangular" width={'100%'} />
        <Skeleton height={'3rem'} variant="rectangular" width={'100%'} />
      </Stack>
    </>
  );
};
const style = {
  '.MuiTextField-root': {
    borderColor: `white !important`,
    '.MuiOutlinedInput-root': {
      '.MuiTypography-root': {},
      borderRadius: '14px !important',
      borderColor: 'white',
      height: '15px !important',
    },
    '.MuiInput-input': {
      width: '100%',
    },
  },
};
export const SingleAutoCompleteNetTerms = (
  terms: any,
  control: any,
  isDisable: boolean,
  isInvoiceCreate: boolean
) => {
  const [open, setOpen] = React.useState(false);
  const [isWarning, setWarning] = React.useState(false);
  const { setValue, watch, formState } = useFormContext();
  const [invoiceTermValue, invoiceTerm] = watch([
    'invoiceTermValue',
    'invoiceTerm',
  ]);
  const isValidNumber = (number) => {
    return typeof number === 'number' && number >= 1 && number <= 366;
  };
  useEffect(() => {
    if (invoiceTerm) return;
    const preferences = RootStoreInstence.getCompanyPreferences;
    if (preferences?.invoiceTerm) {
      if (preferences?.invoiceTerm === 'CUSTOM') {
        setValue('invoiceTerm', 'CUSTOM');
        setValue('invoiceTermValue', preferences?.invoiceTermValue);
      }
      setValue('invoiceTerm', preferences?.invoiceTerm);
    }
  }, []);
  return (
    <Controller
      rules={{
        maxLength: 1024,
      }}
      name="invoiceTerm"
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <FormControl
            sx={{
              m: 1,
              width: '47%',
              pt: 0,
              mr: '-1px',
            }}
            variant="standard"
            error={isWarning}
          >
            <InputLabel id="demo-simple-select-label">
              Net Term <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={invoiceTerm}
              name="invoiceTerm"
              error={!!error}
              disabled={isDisable}
              label="Net Terms"
              onClick={(e) => {
                if (
                  e.target?.nodeName != 'INPUT' &&
                  e.target?.id != 'customeTag' &&
                  e.target?.textContent !=
                    'Custom Number of DaysCustom Number of Days' &&
                  e.target?.textContent != 'Custom Number of Days'
                ) {
                  if (e.target?.dataset?.value) {
                    setValue('invoiceTerm', e.target?.dataset?.value);
                    setValue('invoiceTermValue', null);
                    onChange(e.target?.dataset?.value);
                    setWarning(false);
                  }

                  setOpen(false);
                }
              }}
              onOpen={(e) => {
                setOpen(true);
              }}
              open={open}
              sx={{ width: '100%' }}
            >
              {terms?.map((config: { key: string; value: string }) => {
                if (config.key != 'CUSTOM') {
                  return (
                    <MenuItem className="MuiMenu-list" value={config.key}>
                      {config.value}
                    </MenuItem>
                  );
                }
                if (config.key === 'CUSTOM' && open) {
                  return (
                    <MenuItem
                      className="MuiMenu-list"
                      sx={{ background: 'white !important', width: '100%' }}
                      id="customeTag"
                      value={config.key}
                    >
                      <TextField
                        sx={{ width: '100% !important' }}
                        label={'Custom Number of Days'}
                        variant={'outlined'}
                        size="small"
                        error={!!error}
                        InputProps={{
                          sx: {
                            borderRadius: '14px',
                            height: '40px',
                            width: '100% !important',
                          },
                        }}
                        value={invoiceTermValue}
                        name="invoiceTermValue"
                        type={'number'}
                        onChange={(e) => {
                          onChange('CUSTOM');

                          setValue('invoiceTerm', 'CUSTOM');
                          setValue('invoiceTermValue', e.target.value);
                          setWarning(!isValidNumber(Number(e.target.value)));
                        }}
                      />
                    </MenuItem>
                  );
                }
                if (config.key === 'CUSTOM') {
                  return (
                    <MenuItem
                      className="MuiMenu-list"
                      value={config.key}
                      id="customeTag"
                    >
                      {invoiceTermValue}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            {isWarning && (
              <FormHelperText>Value must be between 1 and 366</FormHelperText>
            )}
          </FormControl>
        );
      }}
      name={'invoiceTerm'}
      control={control}
      defaultValue=""
    />
  );
};
