import React from 'react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { TooltipPaperStyles } from '../styles';
import { GANTT_TOOLTIP_POPUP_ID } from '../config';

type ITooltipPopup = PopoverProps;

const TooltipPopup: React.FC<ITooltipPopup> = (props) => {
  const {
    id = GANTT_TOOLTIP_POPUP_ID,
    anchorEl,
    open = true,
    children,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    onClose,
    sx = {},
    ...restProps
  } = props;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          width: '330px',
          maxWidth: '330px',
          ...TooltipPaperStyles,
        },
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Popover>
  );
};

export default TooltipPopup;
