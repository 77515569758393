import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PreferencesFormType, PreferencesTabStripType } from '../../../models';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { updateDocument } from '../../../utils/terminal';
import PreferencesActions from './PreferencesActions';
import ContainerPreferences from './components/Container';
import HeaderPreferences from './components/Header';
import {
  financeType,
  formDefaultValues,
  preferencesValidationSchema,
} from './constants';

export default function Preferences() {
  const [entity, setEntity] = useState<PreferencesTabStripType>(financeType);
  const { setCompanyPreferences } = useRootStore();

  const state = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(preferencesValidationSchema),
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const getPreferences = async () => {
      const data = await PreferencesActions.getPreferences();
      if (data) {
        state.reset(data, { keepDefaultValues: false });
      }
    };
    getPreferences();
  }, []);

  const onSubmit = async (data: PreferencesFormType) => {
    const uploadFile = async () => {
      if (data.fileChanged && data.logo) {
        await PreferencesActions.updateLogo(data.logo[0]);
      }
    };
    const { termsAndConditions, assignedDoc } = data;
    if (!data.termsAndConditions?.documentId) {
      data.termsAndConditions = null;
      delete data.assignedDoc;
    }

    const updatePreferences = async () => {
      await PreferencesActions.updatePreferences(data);
    };
    const documentRes = await updateDocument(
      data,
      termsAndConditions,
      assignedDoc,
      'PREFERENCES'
    );
    const newData = documentRes?.documentId
      ? { ...data, termsAndConditions: { ...documentRes } }
      : { ...data };
    // }
    uploadFile();
    updatePreferences();
    state.reset({
      ...newData,
      logo: state.getValues('logo'),
      isSeqNumberManuallyUpdated: false,
    });
    await setCompanyPreferences();
  };

  useEffect(() => {
    const errorValues = Object.values(state.formState.errors);
    if (errorValues.length > 0) {
      document.getElementsByClassName('Mui-error')[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [state.formState.errors]);

  return (
    <Box sx={{ height: '100%' }}>
      <FormProvider {...state}>
        <form
          onSubmit={state.handleSubmit(onSubmit)}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <HeaderPreferences
            entity={entity}
            setEntity={setEntity}
            handleSubmit={state.handleSubmit(onSubmit)}
          />
          <ContainerPreferences entity={entity} setEntity={setEntity} />
        </form>
      </FormProvider>
    </Box>
  );
}
