import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../../../../components_v2/ui-kit/icon-button';
import { useCommodityTypes } from '../../../../../contexts/CommodityTypeContext';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import LazyLoadedMultiSelect from '../../../../../views/customTags/customTagChip';
import { useThemeResponsive } from '../../../../hooks/useThemeResponsive';
import { SingleFieldInfoCard } from '../../../../SingleFieldInfoCard';
import HyperLink from '../../../../Ui/HyperLink/HyperLink';
import { styles } from '../../Tracking/styles/styles';
import { SingleFieldInfo } from '../components/singleFieldInfo';
import { TagInput } from '@/common/Tag/components/TagInput';
import { TagEntityType } from '@/models/DTOs/ManageTypes/Requests';

export default function TagForm({
  handleCustomerClick,
}: {
  handleCustomerClick: () => void;
}) {
  const { isMobile } = useThemeResponsive();
  const { commodityTypes, addNewCommodityType: updateCommodityTypes } =
    useCommodityTypes();
  const { control, setValue, getValues, formState } = useFormContext();
  const formValues = getValues();
  const { t, ready } = useTranslation();
  return (
    <Grid spacing={1} container>
      <SingleFieldInfoCard
        label={'Customer Name'}
        contentRenderer={() =>
          formValues?.customer?.name ? (
            <HyperLink
              value={formValues?.customer?.name}
              sx={{ cursor: 'initial' }}
              // onClick={handleCustomerClick}
            />
          ) : (
            ''
          )
        }
        styles={{ width: isMobile ? '100%' : '50%', paddingLeft: '8px' }}
      />
      <SingleFieldInfoCard
        label={'Primary Contact'}
        value={formValues?.customer?.primaryContact?.firstname ?? '--'}
        styles={{ xs: isMobile ? 12 : 6, paddingLeft: '8px' }}
      />

      {formValues?.trackingUrl && (
        <Box
          marginTop={'4px'}
          sx={{ marginLeft: '8px', width: '100%', wordBreak: 'break-word' }}
        >
          <SingleFieldInfo
            label={'Tracking Url'}
            contentRenderer={() => (
              <Typography
                align="left"
                sx={styles.urlText}
                onClick={() => {
                  formValues?.trackingUrl &&
                    window.open(formValues?.trackingUrl, '_blank').focus();
                }}
              >
                {formValues?.trackingUrl}
              </Typography>
            )}
          />
          <IconButton
            sx={{ padding: '1px', marginLeft: '4px' }}
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(formValues?.trackingUrl);
            }}
          >
            <IconsComponent iconName="ContentCopy" source="MUIcons" />
          </IconButton>
        </Box>
      )}
      <SingleFieldInfoCard
        label={'Loaded Miles'}
        value={formValues?.loadedMiles ?? '--'}
        styles={{
          width: isMobile ? '100%' : '50%',
          paddingLeft: '8px',
          marginTop: '10px',
        }}
      />
      <SingleFieldInfoCard
        label={'Empty Miles'}
        value={formValues?.emptyMiles ?? '--'}
        styles={{
          width: isMobile ? '100%' : '50%',
          paddingLeft: '8px',
          marginTop: '10px',
        }}
      />
      <SingleFieldInfoCard
        label={'Revenue'}
        value={formValues?.revenue ?? '--'}
        styles={{
          width: isMobile ? '100%' : '50%',
          paddingLeft: '8px',
          marginTop: '10px',
        }}
      />
      <SingleFieldInfoCard
        label={'$/mi'}
        value={formValues?.tripMiles ?? '--'}
        styles={{
          width: isMobile ? '100%' : '50%',
          paddingLeft: '8px',
          marginTop: '10px',
        }}
      />
      {/* <Stack spacing={2}> */}
      <Box width="500px" sx={{ marginLeft: '8px', marginTop: '10px' }}>
        <TagInput
          onChange={(values) => setValue('tags', values, { shouldDirty: true })}
          defaultValue={formValues.tags}
          entityType={TagEntityType.LOAD}
          fetchOptions
        />
      </Box>
    </Grid>
  );
}
