import React from 'react';

const HoldIcon = () => {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 3.5H6.5V2.5C6.5 1.12 5.38 0 4 0C2.62 0 1.5 1.12 1.5 2.5V3.5H1C0.45 3.5 0 3.95 0 4.5V9.5C0 10.05 0.45 10.5 1 10.5H7C7.55 10.5 8 10.05 8 9.5V4.5C8 3.95 7.55 3.5 7 3.5ZM2.5 2.5C2.5 1.67 3.17 1 4 1C4.83 1 5.5 1.67 5.5 2.5V3.5H2.5V2.5ZM7 9.5H1V4.5H7V9.5ZM4 8C4.55 8 5 7.55 5 7C5 6.45 4.55 6 4 6C3.45 6 3 6.45 3 7C3 7.55 3.45 8 4 8Z"
        fill="white"
      />
    </svg>
  );
};

export default HoldIcon;
