import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import { defaultEquipmentTypeOptions } from '../../../../../subPages/settings/Preferences/constants';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import { loadPanelOverviewTab } from '../../../../PendoClassIDs/constants';
import TextField from '../../../../Ui/TextField/TextField';

export default function LoadDetailsForm() {
  const { control, getValues } = useFormContext();
  const equipmentTypes = getValues('equipmentTypes');
  const { hasLoadEditPermission, hasIdEditPermission } = useLoadPermission();
  return (
    <Grid container spacing={2}>
      <TextField
        id={loadPanelOverviewTab + 'LoadID'}
        control={control}
        name="seqNumber"
        label={t('manifestID')}
        required
        sizes={{ xs: 6 }}
        disabled={!hasIdEditPermission}
      />

      {equipmentTypes && (
        <SingleAutocompleteForm
          control={control}
          fieldName="value"
          fieldValue="key"
          name="equipmentTypes"
          label={t('defaultEquipment')}
          options={defaultEquipmentTypeOptions}
          required
          disableClear
          sizes={{ xs: 6 }}
        />
      )}
    </Grid>
  );
}
