import * as yup from 'yup';
import { date, object } from 'yup';
import { GridColDefSelf } from '../../types';
import {
  dateToHumanableDaysFormat,
  formatPhoneNumber,
  getAssociatedTerminalNamesString,
  lastLocationValidationSchema,
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
  phoneValidationSchema,
} from '../../utils/index';
import StorageManager from '../../StorageManager/StorageManager';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PaymentPerLoad } from '../../models';
import { CarrierReceivedPaymentsSchema } from '../invoices/constants';
import { UserDetailsTabs } from '../shared/constants';
import { safetyAlertValidationSchema } from '../users/components/SafetyAlerts/constants';
import { UserCustomStatus } from '../../common/Ui/StatusComponent/StatusComponent';
import { paymentRateCompare } from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewConstant';
import { TagList } from "@/common/Tag/components/TagList";

export const defaultWebUserDetails = {
  phoneData: {},
  emergencyContact: { phoneData: {} },
};

declare module 'yup' {
  class StringSchema {
    // eslint-disable-next-line @typescript-eslint/ban-types
    paymentPerLoadValidation: Function; // could not understand yet, the correct type definition for the method
  }
}

yup.addMethod(
  yup.string,
  'paymentPerLoadValidation',
  function (anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    // VAS third parameter should be investigated, the question is generic type parameters which should be passed to TestFunction
    return this.test('test-name', message, function (value) {
      const { path, createError } = this;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const context = this as any;
      const loadPaymentTerms =
        context.from[context.from.length - 1].value.paymentTerms.loadRateDTO;
      // ...
      const foundSimilarPaymentPerLoad = loadPaymentTerms?.find(
        (payment: PaymentPerLoad) =>
          payment.id !== context.parent.id &&
          payment.payType === context.parent.payType &&
          payment.operationMode === value
      );

      if (foundSimilarPaymentPerLoad) {
        return createError({
          path,
          message:
            'Line item with the selected Pay Type and Operation Mode already exists.',
        });
      }

      return this;
    });
  }
);
export const updatePaymentPerLoadFormBaseValidationSchema = yup.object().shape({
  description: yup
    .string()
    .max(255, 'Description must be at most 255 characters.'),
  payTypeId: yup.string().required('Pay Type is required'),
  expenseCategoryId: yup.string().required('Expense category is required'),
  rate: nullableNumberYup()
    .test('amout', (_, context) => {
      if (
        context.parent.unit === 'PERCENT' &&
        !(context.parent.rate >= 0.0001 && context.parent.rate <= 100)
      ) {
        return context.createError({
          message: 'Value must be between 0.0001 and 100',
          path: context.path,
        });
      }
      return context.schema;
    })
    .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
    .max(999999999, 'Value must be between 0.0001 and 99,999.9999')
    .required('Value must be between 0.0001 and 99,999.9999'),
});

export const tractorPaymentTermsTabValidationSchema = yup
  .object({
    paymentTerms: yup.object().shape({
      loadRateDTO: yup
        .array()
        .of(updatePaymentPerLoadFormBaseValidationSchema)
        .compact(),
    }),
  })
  .required();

export const updatePaymentPerLoadFormValidationSchema =
  updatePaymentPerLoadFormBaseValidationSchema.shape({
    operationMode: yup
      .string()
      //.paymentPerLoadValidation(1, 'Custom validation failure message')
      .required('Operation mode is required'),
    rate: nullableNumberYup()
      .test('amout', (_, context) => {
        if (
          context.parent.unit === 'PERCENT' &&
          !(context.parent.rate >= 0.0001 && context.parent.rate <= 100)
        ) {
          return context.createError({
            message: 'Value must be between 0.0001 and 100',
            path: context.path,
          });
        }
        return context.schema;
      })
      .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
      .max(999999999, 'Value must be between 0.0001 and 99,999.9999')
      .required('Value must be between 0.0001 and 99,999.9999'),
  });

export const terminalValidationSchema = yup.object().shape({
  terminalId: nullableStringYup().required(),
});

const webUserGeneralTabValidationSchema = yup
  .object({
    firstName: nullableStringMaxValidation(50).required(
      'First name is required'
    ),
    lastName: nullableStringMaxValidation(50).required('Last name is required'),
    email: nullableStringMaxValidation(100)
      .email('Email is not valid')
      .required('Email is required'),
    address: lastLocationValidationSchema,
    phoneData: phoneValidationSchema,
    startingYTD: nullableNumberYup().when('isDriver', {
      is: true,
      then: nullableNumberYup().max(
        999999999,
        'Value must be between 0.0001 and 99,999.9999'
      ),
    }),
    emergencyContact: yup.object().shape({
      phoneData: phoneValidationSchema,
      firstName: nullableStringMaxValidation(50),
      lastName: nullableStringMaxValidation(50),
      email: nullableStringYup().email('Email is not valid'),
      addressData: lastLocationValidationSchema,
    }),
    isDriver: yup.boolean(),
    isUser: yup.boolean(),
    roleCode: nullableStringYup().when('isUser', {
      is: true,
      then: nullableStringYup().required('Role field is required'),
    }),
    employmentType: nullableStringYup()
      .nullable(true)
      .when('isDriver', {
        is: true,
        then: yup
          .string()
          .nullable(true)
          .required('Employment Type is required'),
      }),
    operationType: nullableStringYup()
      .nullable(true)
      .when('isDriver', {
        is: true,
        then: yup
          .string()
          .nullable(true)
          .required('Operation Type is required'),
      }),
  })
  .required();

const webUserDriverDetailsTabValidationSchema = yup.object({
  driverRefId: nullableStringMaxValidation(20).required(
    'Driver id is a required'
  ),
  eldDriver: yup
    .object()
    .nullable()
    .when('eldProvider', (eldProvider, schema) => {
      return !eldProvider
        ? schema
        : schema.required('ELD ID is a required field');
    }),
  driverLicense: object().shape({
    validTillDate: date()
      .min(new Date(1900, 0, 1), `Minimum date is 01/01/1900`)
      .nullable()
      .notRequired()
      .typeError('Expiry date is invalid'),
  }),
});
export const driverPaymentTermsTabValidationSchema = yup
  .object({
    paymentTerms: yup.object().shape({
      loadRateDTO: yup
        .array()
        .of(updatePaymentPerLoadFormValidationSchema)
        .compact(),
    }),
  })
  .required();

export const webUserPaymentTermsTabValidationSchema = yup
  .object({
    paymentTerms: yup.object().shape({
      loadRateDTO: yup
        .array()
        .of(updatePaymentPerLoadFormBaseValidationSchema)
        .compact(),
    }),
  })
  .required();

const webUserSafetyAlertsTabValidationSchema = yup
  .object({
    alertsList: yup.array().of(safetyAlertValidationSchema).compact(),
  })
  .required();

export const WEB_USER_VALIDATION_SCHEMA: {
  [property: string]: yup.AnyObjectSchema;
} = {
  [UserDetailsTabs.GeneralWithTerminal]:
    webUserGeneralTabValidationSchema.concat(terminalValidationSchema),
  [UserDetailsTabs.General]: webUserGeneralTabValidationSchema,
  [UserDetailsTabs.DriverDetails]: webUserDriverDetailsTabValidationSchema,
  [UserDetailsTabs.PaymentTerms]: webUserPaymentTermsTabValidationSchema,
  [UserDetailsTabs.SafetyAlerts]: webUserSafetyAlertsTabValidationSchema,
};

export const webUserValidationSchemaDeprecated = yup
  .object({
    firstName: nullableStringYup().required('first name is required'),
    lastName: nullableStringYup().required('last name is required'),
    email: nullableStringYup().email().required(),
    phoneData: phoneValidationSchema,
    emergencyContact: yup.object().shape({
      phoneData: phoneValidationSchema,
    }),
    isDriver: yup.boolean(),
    isUser: yup.boolean(),
    roleCode: nullableStringYup().when('isUser', {
      is: true,
      then: nullableStringYup().required('Role field is required'),
    }),
    // terminalId: nullableStringYup().required(),
    employmentType: nullableStringYup()
      .nullable(true)
      .when('isDriver', {
        is: true,
        then: yup
          .string()
          .nullable(true)
          .required('Employment Type is required'),
      }),
    operationType: nullableStringYup()
      .nullable(true)
      .when('isDriver', {
        is: true,
        then: yup
          .string()
          .nullable(true)
          .required('Operation Type is required'),
      }),
    paymentTerms: yup.object().shape({
      loadRateDTO: yup
        .array()
        .of(updatePaymentPerLoadFormValidationSchema)
        .compact(),
    }),
    alertsList: yup.array().of(safetyAlertValidationSchema).compact(),
  })
  .required();

type rolesMapType = { [key: string]: string };

export const userRolesMap: rolesMapType = {
  AC: 'Accountant',
  AD: 'Admin',
  PD: 'Power Dispatcher',
  DI: 'Financial Dispatcher',
  DR: 'Driver',
  NFD: 'Dispatcher',
  ...StorageManager.getItem('rolesList'),
};

type userTypes = { [key: number]: string };

export const driverStatuses: userTypes = {
  0: 'INACTIVE',
  1: 'ACTIVE',
  2: 'ACTIVE (NON-INVITED)',
  3: 'ACTIVE (INVITED)',
  4: 'ACTIVE (BLOCKED)',
};

export const webUserColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
    sortable: true,
    permanent: true,
    minWidth: 150,
    valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
  },
  {
    flex: 1,
    field: 'status',
    headerName: 'Status',
    minWidth: 210,
    sortable: false,
    renderCell: (params) => <UserCustomStatus status={params.row.status} />,
  },
  {
    flex: 1,
    field: 'roleCode',
    headerName: 'Web User Role',
    sortable: true,
    minWidth: 130,
    valueGetter: (params) => userRolesMap[params.row.roleCode],
  },
  {
    flex: 1,
    field: 'tags',
    headerName: 'Tags',
    sortable: true,
    minWidth: 130,
    renderCell: (params) => <TagList tags={params.row.tags} small />,
  },
  {
    flex: 1,
    field: 'terminals',
    headerName: 'Terminals',
    sortable: false,
    minWidth: 200,
    valueGetter: (params) =>
      getAssociatedTerminalNamesString(params.row.associatedTerminalsList),
  },
  {
    flex: 1,
    field: 'phone',
    headerName: 'Phone',
    sortable: false,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<Date>) => {
      const { row } = params;
      return formatPhoneNumber(row?.phoneData);
    },
  },
  {
    flex: 1,
    field: 'email',
    headerName: 'Email',
    sortable: true,
    minWidth: 250,
  },
  {
    flex: 1,
    field: 'lastLoginDate',
    headerName: 'Last Logged In',
    sortable: true,
    minWidth: 120,
    valueGetter: (params) =>
      dateToHumanableDaysFormat(params.row.lastLoginDate),
  },
];

export const emptyTableInfo = {
  title: 'No web users found :’(',
  body: 'Looks like you need to add some user in to the system. Import or get started with adding Users and Drivers.',
};

export const newPaymentPerLoadFormValidationSchema = yup.object().shape({
  description: yup
    .string()
    .max(255, 'Description must be at most 255 characters.'),
  payTypeId: yup.string().required('Pay Type is required'),
  operationMode: yup.string().required('Operation mode is required'),
  expenseCategoryId: yup.string().required('Expense category is required'),
  rate: nullableNumberYup().required('Rate is required').max(999999999),
});

export const addNewPaymentPerLoadFormValidationSchema = yup.object().shape({
  description: yup
    .string()
    .max(255, 'Description must be at most 255 characters.'),
  payTypeId: yup.string().required('Pay Type is required'),
  operationMode: yup.string().required('Operation mode is required'),
  expenseCategoryId: yup.string().required('Expense category is required'),
  rate: nullableNumberYup()
    .test('amout', (_, context) => {
      if (
        context.parent.unit === 'PERCENT' &&
        !(context.parent.rate >= 0.0001 && context.parent.rate <= 100)
      ) {
        return context.createError({
          message: 'Value must be between 0.0001 and 100',
          path: context.path,
        });
      }
      return context.schema;
    })
    .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
    .max(999999999, 'Value must be between 0.0001 and 99,999.9999')
    .required('Value must be between 0.0001 and 99,999.9999'),
});

export const addNewPaymentPerLoadTractorFormValidationSchema = yup
  .object()
  .shape({
    description: yup
      .string()
      .max(255, 'Description must be at most 255 characters.'),
    payTypeId: yup.string().required('Pay Type is required'),
    expenseCategoryId: yup.string().required('Expense category is required'),
    rate: nullableNumberYup()
      .test('amout', (_, context) => {
        if (
          context.parent.unit === 'PERCENT' &&
          !(context.parent.rate >= 0.0001 && context.parent.rate <= 100)
        ) {
          return context.createError({
            message: 'Value must be between 0.0001 and 100',
            path: context.path,
          });
        }
        return context.schema;
      })
      .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
      .max(999999999, 'Value must be between 0.0001 and 99,999.9999')
      .required('Value must be between 0.0001 and 99,999.9999'),
  });

export const addNewFinanceOverViewFormValidationSchema = yup.object().shape({
  description: yup
    .string()
    .max(255, 'Description must be at most 255 characters.'),
  loadRateTypeId: yup.string().required('Pay Type is required'),
  expenseCategoryId: yup.string().required('Expense category is required'),
  rate: nullableNumberYup().test('amout', (_, context) => {
    if (
      context.parent.unit !== 'PERCENT' &&
      !(context.parent.rate > 0.0001 && context.parent.rate < 999999999)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 99,999.9999',
        path: context.path,
      });
    }
    if (
      context.parent.unit === 'PERCENT' &&
      !(context.parent.rate >= 0.0001 && context.parent.rate <= 100)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 100',
        path: context.path,
      });
    }
    return context.schema;
  }),

  quantity: nullableNumberYup().test('amout', (_, context) => {
    if (paymentRateCompare.includes(context.parent.loadRateType)) {
      return (context.parent.quantity = 1);
    }
    if (
      context.parent.unit !== 'PERCENT' &&
      !(context.parent.rate > 0.0001 && context.parent.rate < 999999999) &&
      !paymentRateCompare.includes(context.parent.loadRateType)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 99,999.9999',
        path: context.path,
      });
    }
    if (
      context.parent.unit === 'PERCENT' &&
      !(context.parent.rate >= 0.0001 && context.parent.rate <= 100) &&
      !paymentRateCompare.includes(context.parent.loadRateType)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 100',
        path: context.path,
      });
    }
    return context.schema;
  }),
});

export const updateNewFinanceOverViewFormValidationSchema = yup.object().shape({
  description: yup
    .string()
    .max(255, 'Description must be at most 255 characters.'),
  loadRateTypeId: yup.string().required('Pay Type is required'),
  expenseCategoryId: yup.string().required('Expense category is required'),
  rate: nullableNumberYup().test('amout', (_, context) => {
    if (
      context.parent.unit !== 'PERCENT' &&
      !(context.parent.rate > 0.0001 && context.parent.rate < 999999999)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 99,999.9999',
        path: context.path,
      });
    }
    if (
      context.parent.unit === 'PERCENT' &&
      !(context.parent.rate >= 0.0001 && context.parent.rate <= 100)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 100',
        path: context.path,
      });
    }
    return context.schema;
  }),

  quantity: nullableNumberYup().test('amout', (_, context) => {
    if (paymentRateCompare.includes(context.parent.loadRateType)) {
      return (context.parent.quantity = 1);
    }
    if (
      context.parent.unit !== 'PERCENT' &&
      !(context.parent.rate > 0.0001 && context.parent.rate < 999999999) &&
      !paymentRateCompare.includes(context.parent.loadRateType)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 99,999.9999',
        path: context.path,
      });
    }
    if (
      context.parent.unit === 'PERCENT' &&
      !(context.parent.rate >= 0.0001 && context.parent.rate <= 100) &&
      !paymentRateCompare.includes(context.parent.loadRateType)
    ) {
      return context.createError({
        message: 'Value must be between 0.0001 and 100',
        path: context.path,
      });
    }
    return context.schema;
  }),
});

export const FinanceOverViewFormValidationSchema = yup
  .object({
    selectRecordList: yup
      .array()
      .of(updateNewFinanceOverViewFormValidationSchema)
      .compact(),
  })
  .required();

export const FinanceCarrierOverViewFormValidationSchema = yup
  .object({
    selectRecordList: yup
      .array()
      .of(updateNewFinanceOverViewFormValidationSchema)
      .compact(),
    receivedPayments: yup.array().of(CarrierReceivedPaymentsSchema).compact(),
    // amountDue: nullableNumberYup().min(0, 'Amount Due must be greater than 0'),
  })
  .required();
