import { Box, Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { loadService } from '../../../../../api';
import { Typography } from '../../../../../components_v2/ui-kit/typography';
import { UpdateLoadModeRequest } from '../../../../../models';
import { formatDate } from '../../../../../ui-kit/components/DateRangePicker/utils/date.utils';
import ThreeDotMenuIcon from '../../../../../ui-kit/components/ThreeDotMenuIcon';
import { getOrganizationId } from '../../../../TimeoffDialog/utils';
import FormDialog from '../../../../Ui/FormDialog';
import ModeForm from '../forms/modeForm';
import { LoadDetails, LoadMetaDataDetails } from '../models';
import { MenuOptions } from './LoadDetails';
import { SingleFieldInfo } from './singleFieldInfo';

export const ModeDetails = observer(
  ({
    loadDetails,
    loadId,
    onUpdated,
  }: {
    loadDetails: LoadDetails;
    loadId: string;
    onUpdated: () => void;
  }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const handleMenuItemClick = (
      action: { icon: string; label: string },
      accountData: any,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      setEditModeDialog(true);
      handleMenuClose();
    };
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const [editModeDialogOpen, setEditModeDialog] = useState(false);
    const onUpdateMode = async (data: LoadMetaDataDetails) => {
      // const updateMode = false;
      // if (data.mode === 'Drayage') {
      //   updateMode = true;
      // }
      setEditModeDialog(false);
      const modeDetails = new UpdateLoadModeRequest({
        id: loadId,
        organizationId: getOrganizationId(),
        metaData: data.mode === 'Drayage' ? { ...data } : { mode: data.mode },
      });
      const response = await loadService.updateLoadModeDetails(modeDetails);
      if (response) {
        onUpdated();
      }
    };
    return (
      <>
        <Box
          sx={{
            margin: '10px 0px',
            background: '#f5f5f5!important',
            padding: '16px',
            borderRadius: '10px',
          }}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            marginBottom={'5px'}
          >
            <SingleFieldInfo
              label={'Mode'}
              contentRenderer={() => (
                <Typography
                  variant="h7"
                  sx={
                    loadDetails?.metaDataDetails?.mode
                      ? {
                          color: 'primary.main',
                        }
                      : {
                          color: 'text.secondary',
                          fontSize: '12px',
                          fontWeight: 400,
                        }
                  }
                >
                  {loadDetails?.metaDataDetails?.mode ?? 'NA'}
                </Typography>
              )}
            />

            <ThreeDotMenuIcon
              styleProps={{ fontSize: '12px', padding: '0px' }}
              menuOptions={MenuOptions}
              open={Boolean(anchorEl)}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Box>
          {loadDetails?.metaDataDetails?.mode === 'Drayage' && (
            <>
              <Stack
                spacing={{ xs: 1, sm: 1 }}
                direction="row"
                useFlexGap
                sx={{ flexWrap: 'wrap', fontSize: '12px' }}
              >
                {loadDetails?.metaDataDetails?.serviceType && (
                  <div>
                    <SingleFieldInfo
                      label={'Service Type'}
                      value={loadDetails?.metaDataDetails?.serviceType}
                    />
                    <span style={{ margin: '0 5px' }}>|</span>
                  </div>
                )}
                {loadDetails?.metaDataDetails?.steamShipLine && (
                  <div>
                    <SingleFieldInfo
                      label={'Steamship Line'}
                      value={loadDetails?.metaDataDetails?.steamShipLine}
                    />
                    <span style={{ margin: '0 5px' }}>|</span>
                  </div>
                )}
                {loadDetails?.metaDataDetails?.containerTerminal && (
                  <div>
                    <SingleFieldInfo
                      label={'Terminal Info'}
                      value={loadDetails?.metaDataDetails?.containerTerminal}
                    />
                    <span style={{ margin: '0 5px' }}>|</span>
                  </div>
                )}
                {loadDetails?.metaDataDetails?.holdCategory && (
                  <div>
                    <SingleFieldInfo
                      label={'Holds Catagory'}
                      value={loadDetails?.metaDataDetails?.holdCategory}
                    />
                    <span style={{ margin: '0 5px' }}>|</span>
                  </div>
                )}
                {loadDetails?.metaDataDetails?.holdType && (
                  <div>
                    <SingleFieldInfo
                      label={'Holds Type'}
                      value={loadDetails?.metaDataDetails?.holdType}
                    />
                    <span style={{ margin: '0 5px' }}>|</span>
                  </div>
                )}
                {loadDetails?.metaDataDetails?.yardLocation && (
                  <div>
                    <SingleFieldInfo
                      label={'Yard Location'}
                      value={loadDetails?.metaDataDetails?.yardLocation}
                    />
                    <span style={{ margin: '0 5px' }}>|</span>
                  </div>
                )}
                {loadDetails?.metaDataDetails?.containerSize && (
                  <SingleFieldInfo
                    label={'Container Size'}
                    value={loadDetails?.metaDataDetails?.containerSize}
                  />
                )}
                {loadDetails?.metaDataDetails?.chassisProvider && (
                  <SingleFieldInfo
                    label={'Chassis Provider'}
                    value={loadDetails?.metaDataDetails?.chassisProvider}
                  />
                )}

                {loadDetails?.metaDataDetails?.erd && (
                  <SingleFieldInfo
                    label={'Earliest Return Date'}
                    value={
                      loadDetails?.metaDataDetails?.erd
                        ? formatDate(loadDetails?.metaDataDetails?.erd)
                        : ''
                    }
                  />
                )}
                {loadDetails?.metaDataDetails?.lastFreeDay && (
                  <SingleFieldInfo
                    label={'Last Free Day'}
                    value={
                      loadDetails?.metaDataDetails?.lastFreeDay
                        ? formatDate(loadDetails?.metaDataDetails?.lastFreeDay)
                        : ''
                    }
                  />
                )}
              </Stack>
              <Stack
                spacing={{ xs: 1, sm: 1 }}
                direction="column"
                useFlexGap
                sx={{ flexWrap: 'wrap', fontSize: '12px', marginTop: '5px' }}
              >
                {loadDetails?.metaDataDetails?.perdiemStartDate && (
                  <SingleFieldInfo
                    label={'Perdiem Start Date'}
                    value={
                      loadDetails?.metaDataDetails?.perdiemStartDate
                        ? formatDate(
                            loadDetails?.metaDataDetails?.perdiemStartDate
                          )
                        : ''
                    }
                  />
                )}
                {loadDetails?.metaDataDetails?.fullOutgateDate && (
                  <SingleFieldInfo
                    label={'Full Outgate Date'}
                    value={
                      loadDetails?.metaDataDetails?.fullOutgateDate
                        ? formatDate(
                            loadDetails?.metaDataDetails?.fullOutgateDate
                          )
                        : ''
                    }
                  />
                )}
              </Stack>
              <Stack
                spacing={{ xs: 1, sm: 1 }}
                direction="row"
                useFlexGap
                sx={{ flexWrap: 'wrap', fontSize: '12px', marginTop: '5px' }}
              >
                {loadDetails?.metaDataDetails?.inPin && (
                  <div>
                    <SingleFieldInfo
                      label={'In Pin'}
                      value={loadDetails?.metaDataDetails?.inPin}
                    />
                    <span style={{ margin: '0 5px' }}>|</span>
                  </div>
                )}
                {loadDetails?.metaDataDetails?.outPin && (
                  <div>
                    <SingleFieldInfo
                      label={'Out Pin'}
                      value={loadDetails?.metaDataDetails?.outPin}
                    />
                  </div>
                )}
              </Stack>
            </>
          )}
        </Box>
        {editModeDialogOpen && (
          <FormDialog
            data={loadDetails.metaDataDetails}
            titleText={'Edit Mode'}
            actionLabel={'Save'}
            open={editModeDialogOpen}
            onAction={onUpdateMode}
            disableActionBtnByDefault={true}
            isDisableResetBtn={true}
            handleClose={() => {
              setEditModeDialog(false);
            }}
            // validationSchema={commodityValidationSchema}
            contentRenderer={() => <ModeForm />}
          ></FormDialog>
        )}
      </>
    );
  }
);
