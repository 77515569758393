import { loadService, tripService, userService } from '../../api';
import { ServiceError } from '../../api/interfaces';

import {
  ByLoadIdRequest,
  CreateLoadStopRequest,
  DeleteLoadStopRequest,
  FindLoadDetailsRequest,
  GetHosLastLocationRequest,
  GetLastHosInfoRequest,
  GetLoadDetailsActionsRequest,
  GetManifestPreviewRequest,
  GetNamesRequest,
  GetUpdatedManifestDataRequest,
  LoadWarningByIdsRequest,
  OptionType,
  PaginatedLoadListQueryParams,
  PaginatedLoadListRequestNew,
  PaginatedLoadListResponse,
  PaginatedTagsListQueryParams,
  TagCreateResponseDTO,
  UpdateLoadStopRequest,
  UpdateManualHosLocationRequest,
} from '../../models';
import { PaginatedNamesList } from '../../models/DTOs/user/User';
import { GridColDefSelf, PaginatedResult } from '../../types';
import {
  fromStringToOptionType,
  loadAndTripPageSize,
  prepareFiltersFromList,
} from '../../utils';
import { getCustomerNamesList } from '../invoices/utils';
import { LoadListWarning } from './models/LoadListWarning';
import { LoadSummary } from './models/LoadSummary';

import { IPayloadIdsType } from '.';
import { IFinanceRevenueShareRespType } from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceRevenueShare';
import { RoutesInfo } from '../../common/LoadTabPanel/tabs/Routes/Models';
import { ILoadSearchGetSolutionResponse } from '../../models/DTOs/Loadsearch/Model';
import { PaginatedTripLightListRequest } from '../../models/DTOs/Trip/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { docType } from '../../utils/Doc';
import { DateTimezoneUtils } from '../../utils/Timezone.utils';
import { getIsBrokerageTrip } from '../../views/trips/services/queryParams.utils';
import {
  propsTypeAddLoadtoManifest,
  propsTypeCreatManifest,
} from './Manifest/CreateManifest';
import {
  IManifestInvoke,
  IManifestInvokeResponse,
  propsTypeCreateManifestForm,
  propsTypeSolutionrequest,
} from './Manifest/CreateManifestForm';
import { DEFAULT_PAGING_NUMBER } from './constants';

let instance: LoadController | null = null;
export default class LoadController {
  static instance(): LoadController {
    if (instance === null) {
      instance = new LoadController();
    }
    return instance;
  }

  static destroy() {
    instance = null;
  }

  total?: number;
  loadsCount?: number;
  listFetching!: boolean;
  lastPage!: boolean;
  loads: LoadSummary[];
  trailerType: OptionType[];
  loadListRequest: PaginatedLoadListRequestNew;
  loadListQueryParams: PaginatedLoadListQueryParams;

  private constructor() {
    this.loads = [];
    this.trailerType = [];
    this.loadListRequest = new PaginatedLoadListRequestNew();
    this.loadListQueryParams = this.setupLoadListQueryParams();
  }

  setupLoadListQueryParams(): PaginatedLoadListQueryParams {
    const queryParams = new PaginatedLoadListQueryParams();
    queryParams.pageNumber = DEFAULT_PAGING_NUMBER;
    queryParams.pageSize = loadAndTripPageSize;
    queryParams.sort = '-createDate';
    return queryParams;
  }

  updateLoadListRequestWithFilters(newFilters, stripId: string) {
    const req = this.loadListRequest;
    const params = this.loadListQueryParams;
    if (newFilters.sort) {
      params.sort = newFilters.sort;
    }
    req.terminalIds = newFilters.terminals?.map(({ id }: { id: string }) => id);
    req.driverIdsFilter = newFilters.driverIdsFilter?.map(
      (optionType) => optionType.id
    );
    req.customerIdsFilter = prepareFiltersFromList(
      newFilters.customerIdsFilter,
      'key'
    );
    req.equipmentTypes = prepareFiltersFromList(
      newFilters.equipmentTypes,
      'key'
    );
    req.tripIdsFilter = prepareFiltersFromList(
      newFilters.tripIdsFilter,
      'value'
    )?.flat();
    req.dispatcherIdsFilter = prepareFiltersFromList(
      newFilters.dispatcherIdsFilter,
      'id'
    );
    req.loadStatusList = prepareFiltersFromList(
      newFilters.loadStatusList,
      'key'
    );
    req.destinationCityStateList = prepareFiltersFromList(
      newFilters.destinationCityStateList,
      'value'
    );
    req.originCityStateList = prepareFiltersFromList(
      newFilters.originCityStateList,
      'value'
    );
    req.originStateList = prepareFiltersFromList(
      newFilters.originStateList,
      'value'
    );
    req.destinationStateList = prepareFiltersFromList(
      newFilters.destinationStateList,
      'value'
    );
    req.tractorIdsFilter = prepareFiltersFromList(
      newFilters.tractorIdsFilter,
      'id'
    );
    req.trailerIdsFilter = prepareFiltersFromList(
      newFilters.trailerIdsFilter,
      'id'
    );
    req.referenceIds = prepareFiltersFromList(
      newFilters.referenceIds,
      'referenceId'
    );

    req.loadSettlementStatusList = prepareFiltersFromList(
      newFilters.loadSettlementStatusList,
      'key'
    );
    req.loadIdsFilter = prepareFiltersFromList(
      newFilters.loadIdsFilter,
      'loadIds'
    )?.flat();
    req.manifestIdsFilter = prepareFiltersFromList(
      newFilters.manifestIdsFilter,
      'loadIds'
    )?.flat();
    req.chassisNumbers = newFilters.chassisNumbers.map(
      (item: OptionType) => item.value
    );
    req.containerNumbers = newFilters.containerNumbers.map(
      (item: OptionType) => item.value
    );
    req.bookingNumbers = newFilters.bookingNumbers?.map(
      (item: OptionType) => item.value
    );
    req.appointmentNumbers = newFilters.appointmentNumbers?.map(
      (item: OptionType) => item.value
    );
    req.loadDirections = newFilters.loadDirections?.map(
      (item: OptionType) => item.key
    );
    req.loadTypes = newFilters.loadTypes?.map((item: OptionType) => item.key);
    req.hasViolation = newFilters.hasViolation?.key;
    req.loadIsInvoiceable = newFilters.loadIsInvoiceable?.key;
    req.calculateDetention = newFilters.calculateDetention?.key;
    req.needToAssign = newFilters.needToAssign?.key;
    req.operationMode = newFilters.operationMode?.key;

    req.pickupFromDate = DateTimezoneUtils.toStartDateISOString(
      newFilters.pickupDateRanges?.dateRange?.[0]
    );

    req.pickupToDate = DateTimezoneUtils.toEndDateISOString(
      newFilters.pickupDateRanges?.dateRange?.[1]
    );

    req.actualPickupFromDate = DateTimezoneUtils.toStartDateISOString(
      newFilters.actualPickupDateRanges?.dateRange?.[0]
    );

    req.actualPickupToDate = DateTimezoneUtils.toEndDateISOString(
      newFilters.actualPickupDateRanges?.dateRange?.[1]
    );

    req.lastFreeDayFromDate = DateTimezoneUtils.toEndDateISOString(
      newFilters.lastFreeDayDateRanges?.dateRange?.[0]
    );

    req.lastFreeDayToDate = DateTimezoneUtils.toStartDateISOString(
      newFilters.lastFreeDayDateRanges?.dateRange?.[1]
    );

    req.appointmentFromDate = DateTimezoneUtils.toStartDateISOString(
      newFilters.appointmentDateRanges?.dateRange?.[0]
    );

    req.appointmentToDate = DateTimezoneUtils.toEndDateISOString(
      newFilters.appointmentDateRanges?.dateRange?.[1]
    );
    req.actualDropOffFromDate = DateTimezoneUtils.toStartDateISOString(
      newFilters.actualAppointmentDateRanges?.dateRange?.[0]
    );

    req.actualDropOffToDate = DateTimezoneUtils.toEndDateISOString(
      newFilters.actualAppointmentDateRanges?.dateRange?.[1]
    );

    req.commodityTypes = prepareFiltersFromList(
      newFilters.commodityTypes,
      'itemName'
    );

    req.tagIds = prepareFiltersFromList(newFilters.tagIds, 'id');
    req.dispatchNotes = newFilters.dispatchNotes;
    req.locationNameList = prepareFiltersFromList(
      newFilters.locationNameList,
      'value'
    )
    req.isRelay = newFilters.isRelay?.key;

    req.carrierIdsFilter = newFilters.carrierIdsFilter?.map(({ id }) => id);
    req.hasBrokerageTrip = getIsBrokerageTrip(newFilters?.brokerageTripFilter);
    req.onHoldState = newFilters?.onHoldState
      ? newFilters.onHoldState.value
      : null;
  }

  async exportLoadList(
    filters,
    columns: GridColDefSelf[]
  ): Promise<docType | null> {
    this.updateLoadListRequestWithFilters(filters);

    this.loadListQueryParams.gridColumnMetadataList = columns.reduce(
      (result: string[], c: GridColDefSelf) => {
        if (c.field === 'warning') return result;
        if (c.field === 'driver') {
          result.push('groupDetails');
        } else {
          result.push(c.field);
        }
        return result;
      },
      []
    );
    this.loadListQueryParams.gridColumnMetadataList.push('onHoldState');

    const data = await loadService.exportLoadListToExcel(
      this.loadListQueryParams,
      this.loadListRequest
    );
    delete this.loadListQueryParams.gridColumnMetadataList;
    if (data instanceof ServiceError) {
      // error handling
    }
    return data;
  }

  async getLoadSummaryById(id: string): Promise<LoadSummary> {
    const request = new ByLoadIdRequest();
    request.loadId = id;
    const load = await this.getLoadLightSummaryById(request);
    await this.setupLoadWarnings([load]);
    return load;
  }

  async getLoadLightSummaryById(id: ByLoadIdRequest): Promise<LoadSummary> {
    const loadData = await loadService.getLoadSummaryById(id);
    return new LoadSummary(loadData);
  }

  async getTripIds(searchString: string, pageNumber: number): Promise<any> {
    const response = await tripService.getPaginatedTripLightList(
      new PaginatedTripLightListRequest({
        seqNumber: searchString,
        pageNumber,
        pageSize: 20,
      })
    );
    if (response instanceof ServiceError) {
      return {
        content: [],
        last: true,
      };
    }
    return {
      ...response,
      content: response?.content.map(({ tripIds, seqNumber }) => ({
        label: seqNumber,
        value: tripIds,
      })),
    };
  }

  deleteLoadId(loadId: string): LoadSummary[] {
    this.loads = this.loads.filter(({ id }) => id !== loadId);
    return this.loads;
  }

  async getTotalRevenue(): Promise<string> {
    const response = await loadService.getTotalRevenue(this.loadListRequest);
    if (!(response instanceof ServiceError)) {
      return response;
    }
    return '';
  }

  async getLoadList(
    filters,
    stripId: string,
    callback: (data: LoadSummary[]) => void,
    isFirstPage = true,
    callbackErr?: () => void
  ): Promise<void> {
    if (!isFirstPage && this.lastPage) return callbackErr?.();
    if (isFirstPage) {
      this.loadListQueryParams.pageNumber = DEFAULT_PAGING_NUMBER;
    }
    this.updateLoadListRequestWithFilters(filters, stripId);
    this.loadListQueryParams.pageNumber += 1;
    this.listFetching = true;
    const data = await loadService.getPaginatedLoadList(
      this.loadListQueryParams,
      this.loadListRequest
    );
    this.listFetching = false;
    if (data instanceof PaginatedLoadListResponse) {
      this.lastPage = data.last;
      this.total = data.totalElements;
      const loadList = data?.content?.map((item: LoadSummary) => {
        return new LoadSummary(item);
      });
      this.loads = isFirstPage ? loadList : [...this.loads, ...loadList];
      callback([...this.loads]);
      this.setupLoadWarning(loadList);
      callback([...this.loads]);
    }
  }

  async getLoadsCount(callback: (data: number | undefined) => void) {
    const loadsCount = await loadService.getLoadsCount();
    this.loadsCount = loadsCount;
    callback(this.loadsCount);
  }

  createLoadStop = async (
    payload: CreateLoadStopRequest,
    callback: () => void
  ): Promise<any> => {
    await loadService.createLoadStop(payload);
    callback();
  };

  updateLoadStop = async (
    payload: UpdateLoadStopRequest,
    callback: () => void
  ): Promise<any> => {
    await loadService.updateLoadStop(payload);
    callback();
  };

  deleteLoadStop = async (
    payload: DeleteLoadStopRequest,
    callback: () => void
  ): Promise<any> => {
    await loadService.deleteLoadStop(payload);
    callback();
  };

  getLoadStatic = async (): Promise<
    PaginatedResult<OptionType> | undefined
  > => {
    if (!this.trailerType.length) {
      const response = await loadService.getLoadStaticData();
      if (!(response instanceof ServiceError)) {
        this.trailerType = response.data?.trailerType || [];
      }
    }
    return { content: this.trailerType, last: true };
  };

  getLoadRoutes = async (
    id: ByLoadIdRequest,
    callback: (routesInfo: RoutesInfo) => void
  ): Promise<any> => {
    const response = await loadService.getLoadRoutes(id);
    callback(new RoutesInfo(response.data || {}));
  };

  getDispatcherSummary = async (
    searchText: string,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatuses?: number[]
  ): Promise<PaginatedNamesList> => {
    const queryData = new GetNamesRequest();
    queryData.name = searchText;
    queryData.pageSize = 25;
    queryData.pageNumber = pageNumber;
    queryData.excludeRoleCodeList = ['DR'];
    queryData.excludeStatuses = excludeStatuses;
    if (terminalIds) queryData.terminalIds = terminalIds;
    const response = await userService.getNames(queryData);
    if (response instanceof ServiceError) {
      // to be handled
      return {};
    } else {
      return response;
    }
  };

  async getContainerSearch(
    searchText: string | undefined,
    pageNumber: number,
    skipLoader?: boolean
  ): Promise<object> {
    const response = await loadService.getContainerSearch(
      searchText,
      pageNumber,
      skipLoader
    );
    if (response instanceof ServiceError) {
      // to be handled
      return {};
    } else {
      const data = response.data;
      data.content = fromStringToOptionType(data.content);
      return data;
    }
  }

  async getChassisSearch(
    searchText: string | undefined,
    pageNumber: number,
    skipLoader?: boolean
  ): Promise<object> {
    const response = await loadService.getChassisSearch(
      searchText,
      pageNumber,
      skipLoader
    );
    if (response instanceof ServiceError) {
      // to be handled
      return {};
    } else {
      const data = response.data;
      data.content = fromStringToOptionType(data.content);
      return data;
    }
  }

  async getAppointmentAndBookingSearch(url: string, requestData: any) {
    const response = await loadService.getAppointmentAndBookingSearch(
      url,
      requestData
    );
    if (response instanceof ServiceError) {
      return {};
    } else {
      const data = response?.data;
      data.content = fromStringToOptionType(data.content);
      return data;
    }
  }
  getCustomerForFilter = async (
    searchText: string,
    pageNumber: number
  ): Promise<object> => {
    const response = await getCustomerNamesList(searchText, pageNumber);
    if (response instanceof ServiceError) {
      // to be handled
      return {};
    } else {
      response.content = response.content.map(
        (c) => ({ key: c.id, value: c.name } as OptionType)
      );
      return response;
    }
  };

  async getLoadWarnings(loads: LoadSummary[]): Promise<any> {
    const response = await loadService.getLoadWarningsByIds(
      new LoadWarningByIdsRequest(loads.map((load: LoadSummary) => load.id))
    );
    if (response instanceof ServiceError) {
      return {};
    } else {
      const warningsMap: { [key: string]: any } = {};
      response?.data?.forEach((w) => {
        warningsMap[w.loadId] = w.warning;
      });
      return warningsMap;
    }
  }

  async setupLoadWarnings(loads: LoadSummary[]) {
    const warnings = await this.getLoadWarnings(loads);
    loads.forEach((load: LoadSummary) => {
      if (warnings?.[load.id]) {
        load.missingDocuments = getMissingDocumentsWarning(warnings[load.id]);
        load.warning = new LoadListWarning(warnings[load.id]);
        load.warning?.setupDetentionTime(
          load.loadStatus,
          warnings?.[load.id]?.loadDetentionTime // here place attr loadDetentionTime from warning response
          // load.loadDetentionTime
        );
      }
    });
  }

  setupLoadWarning(loads: LoadSummary[]) {
    loads.forEach((load: LoadSummary) => {
      if (load.loadWarning) {
        load.missingDocuments = getMissingDocumentsWarning(load.loadWarning);
        load.warning = new LoadListWarning(load.loadWarning);
        load.warning?.setupDetentionTime(
          load.loadStatus,
          load.loadWarning?.loadDetentionTime
        );
      }
    });
  }

  async getLastHosInfo({ ids }: { ids: string[] }): Promise<any> {
    const response = await loadService.getLastHosInfo(
      new GetLastHosInfoRequest({ ids })
    );
    if (response instanceof ServiceError) {
      return {};
    } else {
      return response;
    }
  }

  async getHosLastLocation({ ids }: { ids: string[] }): Promise<any> {
    const response = await loadService.getHosLastLocation(
      new GetHosLastLocationRequest({ ids })
    );
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async updateManualHosLocation(
    requestData: UpdateManualHosLocationRequest
  ): Promise<any> {
    const response = await loadService.updateManualHosLocation(
      new UpdateManualHosLocationRequest(requestData)
    );
    if (response instanceof ServiceError) {
      return {};
    } else {
      return response;
    }
  }

  async getLoadDetailsActions(loadId: string): Promise<any> {
    const response = await loadService.getLoadDetailsActions(
      new GetLoadDetailsActionsRequest({ loadId })
    );
    return response instanceof ServiceError ? null : response;
  }

  async findLoadDuplicateDetails(loadId: string): Promise<any> {
    const response = await loadService.findLoadDuplicateDetails(
      new FindLoadDetailsRequest({ loadId })
    );
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async getManifestPreview(
    loadIds: IPayloadIdsType,
    callback: (data: propsTypeCreateManifestForm) => void
  ) {
    const requestData = new GetManifestPreviewRequest();
    requestData.loadIds = loadIds.loadIds;
    requestData.manifestId = loadIds.manifestIds;
    const previewlist = await loadService.getManifestPreview(requestData);
    previewlist instanceof ServiceError
      ? displayErrorMsg(previewlist)
      : callback(previewlist);
  }
  async creatManifest(
    loadIds: propsTypeCreatManifest,
    callback: (data: any) => void
  ) {
    const responseResult = await loadService.creatManifest(loadIds);
    responseResult instanceof ServiceError
      ? displayErrorMsg(responseResult)
      : callback(responseResult);
  }
  async manifestReCalculation(
    loadIds: propsTypeCreateManifestForm,
    callback: (data: propsTypeCreateManifestForm) => void
  ) {
    const response = await loadService.manifestReCalculation(loadIds);
    response instanceof ServiceError
      ? displayErrorMsg(response)
      : callback(response);
  }
  async manifestInvoke(
    loadIds: Array<IManifestInvokeResponse>,
    callback: (data: IManifestInvoke) => void
  ) {
    const response = await loadService.manifestInvoke(loadIds);
    response instanceof ServiceError
      ? displayErrorMsg(response)
      : callback(response);
  }
  async addLoadToManifest(
    payLoad: propsTypeAddLoadtoManifest,
    callback: (data: LoadSummary | ServiceError) => void
  ) {
    const response = await loadService.addLoadToManifest(payLoad);
    response instanceof ServiceError ? callback(response) : callback(response);
  }
  async getFinanceRevenueShareData(
    loadIds: ByLoadIdRequest,
    callback: (data: IFinanceRevenueShareRespType | null) => void
  ) {
    const response = await loadService.getFinanceRevenueShare(loadIds);
    response instanceof ServiceError
      ? displayErrorMsg(response)
      : callback(response);
  }
  async getSolutionDelay(
    loadIds: propsTypeSolutionrequest
  ): Promise<ILoadSearchGetSolutionResponse | null> {
    const response = await loadService.getSolutionDelay(loadIds);
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }
  async getUpdatedManifestLoad(
    payLoad: GetUpdatedManifestDataRequest,
    callback: (data: LoadSummary[] | null) => void
  ) {
    const response = await loadService.getUpdatedManifestLoad(payLoad);
    response instanceof ServiceError
      ? displayErrorMsg(response)
      : callback(response);
  }
  syncLoads(loads: LoadSummary[]): void {
    this.loads = loads;
  }

  async getPaginatedTagsList(
    requestData: PaginatedTagsListQueryParams,
    callback: (data: PaginatedLoadListResponse | null) => void
  ) {
    const queryParams = new PaginatedTagsListQueryParams();
    queryParams.pageNumber = 1;
    queryParams.pageSize = 0;
    queryParams.sort = '';
    queryParams.searchText = '';
    queryParams.isActive = true;
    // const data =  new PaginatedTagsListQueryParams();

    const tagList = await loadService.getPaginatedTagsList(queryParams);
    tagList instanceof ServiceError
      ? displayErrorMsg(tagList)
      : callback(tagList);
  }

  async createUserDefinedTag(
    payLoad: TagCreateResponseDTO,
    callback: (data: any | null) => void
  ) {
    const createTag = await loadService.createUserDefinedTag(payLoad);
    console.log('TagCreateResponseDTO', createTag);
    createTag instanceof ServiceError
      ? displayErrorMsg(createTag)
      : callback(createTag);
  }
}

const displayErrorMsg = (responseResult: ServiceError) => {
  if (responseResult?.composedError?.error?.response?.data?.errorMessage) {
    RootStoreInstence.setNotificationType({
      type: 'FAILURE',
      message:
        responseResult?.composedError?.error?.response?.data?.errorMessage,
    });
  }
};

export function getMissingDocumentsWarning(w: {
  rateConfirmationWarning: string;
  billOfLadingWarning: string;
  proofOfDeliveryWarning: string;
}): string[] {
  if (!w) return [];
  const dWarnings: string[] = [];
  if (w.rateConfirmationWarning) {
    dWarnings.push('RC');
  }
  if (w.billOfLadingWarning) {
    dWarnings.push('BOL');
  }
  if (w.proofOfDeliveryWarning) {
    dWarnings.push('POD');
  }
  return dWarnings;
}
