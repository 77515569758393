import { SearchOutlined } from '@mui/icons-material';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { SingleSelectFilter } from '../../../common/Ui/SingleSelect';
import { SortIconFilter } from '../../../common/Ui/SortIcon/SortIconFilter';
import { DispatchFiltersName, EDriverDetails } from '../constants/types';
import { t } from 'i18next';
import { Label } from '../../../locales/en/dispatch/label';
import {
  getDispatcherList,
  getDriverNames,
  getEmploymentTypeOptions,
  getOperationModeOptions,
  getOperationTypeOptions,
  getSortOptions,
} from '../utils/filters.utils';
import { tripService } from '@/api';
import { PaginatedTripLightListRequest } from '@/models/DTOs/Trip/Requests';
import { ServiceError } from '@/api/interfaces';
import {
  getLoadOriginDestinations,
  getPaginatedTagsList,
} from '@/subPages/invoices/utils';
import { TagEntityType } from '@/models/DTOs/ManageTypes/Requests';

const defaultAutocompleteConfig = {
  fieldName: 'value',
  fieldValue: 'key',
  immutableModel: true,
};

const getTripIdData = async (searchString: string, pageNumber: number) => {
  const response = await tripService.getPaginatedTripLightList(
    new PaginatedTripLightListRequest({
      seqNumber: searchString,
      pageNumber,
      pageSize: 20,
    })
  );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: response?.content.map(({ tripIds, seqNumber }) => ({
      label: seqNumber,
      value: tripIds,
    })),
  };
};

export const getGanttFilterColumns = ({
  showQuickFilter,
}: {
  showQuickFilter?: boolean;
}) => {
  // const { t, ready } = useTranslation();
  // if (ready)
  return [
    {
      default: showQuickFilter,
      label: t('GanttFiltersSort'),
      labelId: 'SortId',
      name: DispatchFiltersName.sort,
      options: getSortOptions(),
      type: 'Custom',
      customComponent: SingleSelectFilter,
      ...defaultAutocompleteConfig,
      customStyles: {
        // width: '85px',
        '.MuiFormLabel-root': {
          lineHeight: '100%',
        },
      },
      renderValueSxProps: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '13px',
      },
    },
    {
      name: DispatchFiltersName.sortDirection,
      type: 'Custom',
      default: showQuickFilter,
      customComponent: SortIconFilter,
    },

    {
      default: showQuickFilter,
      label: t('GanttFilterPrimaryDispatcher'),
      name: DispatchFiltersName.primaryDispatcher,
      getData: getDispatcherList,
      type: 'MultipleAutocomplete',
      ...defaultAutocompleteConfig,
      customStyles: {
        width: '200px',
      },
      PopperSxProps: {
        minWidth: '270px',
        maxWidth: '400px',
      },
    },
    {
      default: showQuickFilter,
      label: 'Tags',
      name: DispatchFiltersName.tagIds,
      fieldName: 'name',
      getData: (name: string, pageNumber: number) =>
        getPaginatedTagsList(name, pageNumber, [TagEntityType.DRIVER]),
      type: 'MultipleAutocomplete',
      isCustomTag: true,
      customStyles: {
        width: '200px',
      },
      PopperSxProps: {
        minWidth: '270px',
        maxWidth: '400px',
      },
    },
    {
      name: DispatchFiltersName.startDate,
      fieldName: DispatchFiltersName.startDate,
      label: t('GanttFilterStartDate'),
      default: showQuickFilter,
      ...RelativeDateRangeUtils.getSettingsDispatch(),
      width: '150px',
    },
    {
      default: false,
      label: t('GanttFilterAllDrivers'),
      name: DispatchFiltersName.driver,
      getData: getDriverNames,
      type: 'MultipleAutocomplete',
      ...defaultAutocompleteConfig,
    },
    {
      default: false,
      label: t('GanttFilterAllTrips'),
      name: DispatchFiltersName.trips,
      getData: getTripIdData,
      fieldName: 'label',
      type: 'MultipleAutocomplete',
    },
    {
      default: false,
      label: t('GanttFilterHasViolation'),
      name: DispatchFiltersName.hasViolation,
      fieldName: 'label',
      fieldValue: 'value',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      type: 'GridSwitchFilter',
    },
    {
      default: false,
      label: t('GanttFilterOriginRadius'),
      name: DispatchFiltersName.originRadius,
      type: 'TextField',
    },
    {
      default: false,
      label: t('GanttFilterDestinationRadius'),
      name: DispatchFiltersName.destinationRadius,
      type: 'TextField',
    },
    {
      name: DispatchFiltersName.origin,
      fieldName: 'name',
      getData: getLoadOriginDestinations,
      label: t('GanttFilterOrigin'),
      type: 'MultipleAutocomplete',
    },
    {
      name: DispatchFiltersName.destination,
      fieldName: 'name',
      getData: getLoadOriginDestinations,
      label: t('GanttFilterDestination'),
      type: 'MultipleAutocomplete',
    },
    {
      default: false,
      label: t('GanttFilterOperationMode'),
      name: DispatchFiltersName.operationMode,
      options: getOperationModeOptions(),
      // type: 'SingleAutocomplete',
      type: 'ButtonGroup',
      multiple: false,
      ...defaultAutocompleteConfig,
    },
    {
      default: false,
      label: t('GanttFilterEmploymentType'),
      name: DispatchFiltersName.employmentType,
      options: getEmploymentTypeOptions(),
      // type: 'SingleAutocomplete',
      type: 'ButtonGroup',
      multiple: false,
      ...defaultAutocompleteConfig,
    },
    {
      default: false,
      label: t('GanttFilterDriverDetails'),
      name: DispatchFiltersName.driverDetails,
      options: [
        {
          key: EDriverDetails.needLoad,
          value: t('GanttFilterAvailableWithin48h'),
        },
        {
          key: EDriverDetails.timeOff,
          value: t('GanttFilterCurrentTimeOff'),
        },
        {
          key: EDriverDetails.hasViolation,
          value: t('GanttFilterLoadDelay'),
        },
      ],
      type: 'ButtonGroup',
      multiple: true,
      ...defaultAutocompleteConfig,
    },
  ].map((config, idx) => ({
    ...config,
    key: idx,
  }));
};

export const DriverFilter = [
  {
    key: 90,
    default: true,
    label: Label.GanttFilterSearchDrivers,
    name: DispatchFiltersName.driver,
    getData: getDriverNames,
    type: 'MultipleAutocomplete',
    ...defaultAutocompleteConfig,
    customStyles: {
      width: '208px',
      marginTop: '-6px',
    },
    PopperSxProps: {
      minWidth: '270px',
      maxWidth: '270px',
    },
    startAdornment: <SearchOutlined fontSize="small" />,
  },
];
