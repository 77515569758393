import {
  annotateNameAsync,
  IRulesService,
  ServiceError,
} from '@/api/interfaces';
import { ComposedError, httpClient } from '@/axios/axiosInstance';
import { RuleItem } from '@/models/DTOs/Rules/Rules';
import {
  CreateRuleRequest,
  DeleteRuleRequest,
  GetRulesRequest,
} from '@/models/DTOs/Rules/Requests';
import { RootStoreInstence } from "@/store/root-store/rootStateContext";

export class RulesService extends IRulesService {
  @annotateNameAsync
  async getRulesList(
    requestData: GetRulesRequest
  ): Promise<RuleItem[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        'web/load/api/v2/load-rules/list',
        requestData,
        true,
        false
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deleteRule(
    requestData: DeleteRuleRequest
  ): Promise<void | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        'web/load/api/v2/load-rules',
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async updateRule(
    requestData: DeleteRuleRequest
  ): Promise<void | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        'web/load/api/v2/load-rules',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      if ((error as any).response.status === 417) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: (error as any).response.data.message,
        });
      }
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async createRule(
    requestData: CreateRuleRequest
  ): Promise<void | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        'web/load/api/v2/load-rules',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      if ((error as any).response.status === 417) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: (error as any).response.data.message,
        });
      }
      return this.getServiceError(error as ComposedError);
    }
  }
}
