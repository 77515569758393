import { Moment } from 'moment';
import { BrokeredTripOption, QueryParams } from '../../../../models';
import {
  DriverShort,
  TerminalShort,
} from '../../../../models/common/modelsShort';
import { AppointmentConfirmationEnum } from '../../../../subPages/loadsList/CreateLoad/types/requests';
import { Model } from '../../../../types';
import {
  StopSolutionV3NormalStop,
  StopSolutionV3StopType,
  TripStopActivityEnum,
  TripStopStatusEnum,
} from '../../../../ui-kit/components/TripPlan';
import { fromIsoToMoment, getNullableModel } from '../../../../utils';
import { ELoadStatus } from '../../constants/constants';

export class RoutesInfo extends Model {
  revenue: number;
  seqNumber: string;
  terminalName: string;
  loadedMiles: number;
  dispatchSheet: any;
  trips: Trip[];
  stopsNumber: number;
  tripMiles: number;
  cancelledStops: TripStop[];
  loadDetails: any;
  constructor(dto) {
    super(dto);
    this.stopsNumber = dto.numberOfStops;
    this.revenue = dto.revenue;
    this.seqNumber = dto.seqNumber;
    this.terminalName = dto.terminal?.companyName;
    this.loadedMiles = dto.loadedMiles;
    this.dispatchSheet = dto.dispatchSheet;
    this.tripMiles = dto.tripMiles;
    this.trips = dto.trips?.map(
      (trip) => new Trip({ ...trip, loadId: this.id })
    );
    this.loadDetails = dto.loadDetails;
    this.cancelledStops =
      dto.cancelledStops?.map(
        (loadStop) => new TripStop({ ...loadStop, status: 'CANCELLED' })
      ) || [];
  }
}

export class SendEmailDispatchSheetRequest extends QueryParams {
  combinePdfDocuments!: boolean;
  html!: boolean;
  emailSendingRequestDTO!: {
    dispatchSheetRequest?: {
      confirmationSheet: boolean;
      loadId: string;
      organizationId: number;
    };
    carrierRCRequestDTO?: {
      tripId: string;
      organizationId: number;
    };
    cc: string[];
    message: string;
    subject: string;
    to: string[];
  }[];
}

export class Trip extends BrokeredTripOption {
  id: string;
  completed: boolean;
  previousTrip: { id: string; seqNumber: number };
  terminal: TerminalShort | null;
  driverGroup: DriverShort | null;
  tractor: {
    implicit: any;
    name: string;
    id: number;
    explicit: boolean;
  } | null;
  carrier: { name: string; id: number; explicit: boolean } | null;
  trailer: {
    implicit: any;
    name: string;
    id: number;
    explicit: boolean;
  } | null;
  dispatcher: { name: string; id: string } | null;
  seqNumber: number;
  stops: TripStop[];
  tripStatus: ELoadStatus;
  loadId: string;
  // stopList: any[];
  loadDetails: any;

  constructor(dto: any) {
    super();
    this.id = dto?.id;
    this.completed =
      dto.tripStatus === 'LOAD_COMPLETED' || dto.status === 'LOAD_COMPLETED';

    this.previousTrip = dto.previousTrip;
    this.terminal = getNullableModel(TerminalShort, dto.terminal);
    this.driverGroup = getNullableModel(DriverShort, dto.driverGroup);
    this.tractor = dto.tractor;
    this.carrier = dto.carrier;
    this.trailer = dto.trailer;
    this.dispatcher = dto.dispatcher;
    this.seqNumber = dto.seqNumber;
    this.stops = dto.stops.map((s) => new TripStop(s));
    this.tripStatus = dto.status || dto.tripStatus;
    this.loadId = dto.loadId;
    this.loadDetails = dto.loadDetails;
    this.brokerageTrip = dto?.brokerageTrip;
    this.carrierDetails = dto?.carrierDetails;
  }
}

export class RouteTrip extends Trip {
  constructor(dto) {
    super(dto);
  }
}
export class TripStop extends Model implements StopSolutionV3NormalStop {
  type: StopSolutionV3StopType;
  totalMiles: number;
  totalDriveTime: number; // minutes
  estimatedActivityDuration: number; // minutes
  refNumber: string;
  location: {
    locationName: string;
    address: string;
    city: string;
    state: string;
    lat: number;
    lng: number;
  };
  loadStopId: number;
  status: TripStopStatusEnum;
  activityType: TripStopActivityEnum;
  appointmentStartDate: Moment | null;
  appointmentEndDate: Moment | null;
  // @TODO:(AXL2-2736) Nvard/ Anh/ Anoush - refactor noteDetails and notes (in backend also)
  noteDetails: { note: string; userId: string; updateTime: Moment } | null;
  notes: string;
  isDriverAssistRequired: boolean;
  estimatedTimeOfArrival: Moment | null;
  actualTimeOfArrival: Moment | null;
  arrivalDelay: number; // minutes
  dwellTime: number; //minutes
  detentionTime: number; // minutes
  estimatedTimeOfCompletion: Moment | null;
  actualTimeOfCompletion: Moment | null;
  contact: { name: string; email: string; phone: string };
  fixedAppointment: boolean;
  loadDetails: any;
  volume: number;
  weight: number;
  preLoaded?: boolean;
  dropTrailer?: boolean;
  firstStop?: boolean;
  lastStop?: boolean;
  appointmentConfirmation?: AppointmentConfirmationEnum;
  constructor(dto) {
    super(dto);
    this.type = dto.type;
    this.totalMiles = dto.totalMiles;
    this.totalDriveTime = dto.totalDriveTime;
    this.estimatedActivityDuration = dto.estimatedActivityDuration;
    this.refNumber = dto.refNumber;
    this.location = dto.location;
    this.loadStopId = dto.loadStopId;
    this.status = dto.status;
    this.activityType = dto.activityType;
    this.appointmentStartDate = fromIsoToMoment(dto.appointmentStartDate);
    this.appointmentEndDate = fromIsoToMoment(dto.appointmentEndDate);
    this.fixedAppointment = dto.fixedAppointment;
    this.noteDetails = { ...dto.noteDetails, note: dto.notes };
    this.notes = dto.notes;
    this.isDriverAssistRequired = dto.isDriverAssistRequired;
    this.estimatedTimeOfArrival = fromIsoToMoment(dto.estimatedTimeOfArrival);
    this.actualTimeOfArrival = fromIsoToMoment(dto.actualTimeOfArrival);
    this.arrivalDelay = dto.arrivalDelay;
    this.dwellTime = dto.dwellTime;
    this.detentionTime = dto.detentionTime;
    this.estimatedTimeOfCompletion = fromIsoToMoment(
      dto.estimatedTimeOfCompletion
    );
    this.actualTimeOfCompletion = fromIsoToMoment(dto.actualTimeOfCompletion);
    this.contact = dto.contact;
    this.loadDetails = dto.loadDetails;
    this.volume = dto.volume;
    this.weight = dto.weight;
    this.appointmentConfirmation =
      dto?.appointmentConfirmation ?? AppointmentConfirmationEnum.NO;
    this.preLoaded = dto.preLoaded;
    this.dropTrailer = dto.dropTrailer;
    this.firstStop = dto.firstStop;
    this.lastStop = dto.lastStop;
  }
}
