import { ReeferModeEnum, ReeferModeVals } from '../schema/load';
import { StopTypeEnum, StopTypeOptionsEnum } from '../schema/stops';
import { AppointmentConfirmationType } from '../types/requests';
import { ActivityType } from '../types/types';

export const CustomerRequiredDocOptions = [
  { id: 'BOL', label: `Bill of Lading` },
  { id: 'POD', label: 'Proof of Delivery' },
];

export const CustomerRequiredDocOptionLabel = {
  BOL: 'Bill of Lading',
  POD: 'Proof of Delivery',
} as { [key: string]: string };

export const LoadReeferModeOptions: { id: ReeferModeEnum; label: string }[] = [
  { id: ReeferModeEnum.CONTINUOUS, label: ReeferModeVals.CONTINUOUS },
  { id: ReeferModeEnum.START_STOP, label: ReeferModeVals.START_STOP },
  { id: ReeferModeEnum.OFF, label: ReeferModeVals.OFF },
];

export const ChassisAndContainerImportOptions = [
  { id: 'import', label: 'Import' },
  { id: 'export', label: 'Export' },
];

export enum BaseRateTypes {
  FLAT_RATE = 'Flat Rate',
  PER_LOADED_MILE = 'Per Loaded Mile',
}

export const FLAT_RATE_KEY = 'FLAT_RATE';
export const PER_LOADED_MILE_KEY = 'PER_LOADED_MILE';
export const PRICE_PER_MILE_MATRIX = 'PRICE_PER_MILE_MATRIX';
export const PER_LOADED_WEIGHT_KEY = 'PER_WEIGHT';

export enum FINANCE_TYPES {
  PERCENT = 'PERCENT',
  PER_LOAD = 'PER_LOAD',
  PER_LOADED_MILE = 'PER_LOADED_MILE',
  PER_WEIGHT = 'PER_WEIGHT',
  PERCENT_MATRIX = 'PERCENT_MATRIX',
  PRICE_PER_MILE_MATRIX = 'PRICE_PER_MILE_MATRIX',
}

export const PER_LOAD_KEY = 'PER_LOAD';

export const CONTRACT_TYPES = {
  CITY: 'CITY_BASED',
  ZIP: 'ZIP_BASED',
  MILEAGE: 'MILEAGE_BASED',
};

export const ZIP_BASED = CONTRACT_TYPES.ZIP;

export const BaseRateTypeOptions = [
  { label: BaseRateTypes.FLAT_RATE },
  { label: BaseRateTypes.PER_LOADED_MILE },
];

export const AppointmentConfirmationOptions: {
  value: AppointmentConfirmationType;
  label: string;
}[] = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' },
  { value: 'CONFIRMED', label: 'Confirmed' },
];

export const StopTypeOptions: {
  label: StopTypeOptionsEnum;
  value: ActivityType;
}[] = [
  { label: StopTypeOptionsEnum.Pickup, value: StopTypeEnum.PICKUP },
  {
    label: StopTypeOptionsEnum.PickupAndDropOff,
    value: StopTypeEnum.PICKUP_AND_DROPOFF,
  },
  { label: StopTypeOptionsEnum.Relay, value: StopTypeEnum.RELAY },
  { label: StopTypeOptionsEnum.TripStop, value: StopTypeEnum.TRIP_STOP },
  { label: StopTypeOptionsEnum.DropOff, value: StopTypeEnum.DROPOFF },
];
