/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { tripService } from '..';
import {
  ComposedError,
  FormatError,
  httpClient,
} from '../../axios/axiosInstance';
import { IFinanceRevenueShareRespType } from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewModel';
import { getOrganizationId } from '../../common/TimeoffDialog/utils';
import { MarkersData } from '../../common/Ui/Maps/types';
import { getSuccessMsg } from '../../constants/globalNotificationMessages';
import {
  AddLoadCommodity,
  AssignCarrierToLoadDTO,
  AssignDispatcherToLoadRequest,
  AssignDriverToLoadRequest,
  AssignTractorToLoadRequest,
  AssignTrailerToLoadRequest,
  BrokeredUnAssignParams,
  ByLoadIdOnlyRequest,
  ByLoadIdRequest,
  CarrierSearchListParams,
  CreateLoadRequest,
  CreateLoadStopRequest,
  CreateLoadStopResponse,
  DeleteLoadManifestRequest,
  DeleteLoadRequest,
  DeleteLoadResponse,
  DeleteLoadStopRequest,
  FindLoadDetailsRequest,
  GetAllStopsRequest,
  GetAllStopsResponse,
  GetChassisListRequest,
  GetContainerListRequest,
  GetDispatcherInfoForLoadAssignRequest,
  GetDriverInfoForLoadAssignRequest,
  GetHosLastLocationRequest,
  GetInvoiceSummaryListRequest,
  GetLastHosInfoRequest,
  GetLoadAdditionalDetailsRequest,
  GetLoadDetailsActionsRequest,
  GetLoadDriverGroupWarnings,
  GetLoadOriginDestinationRequest,
  GetLoadRefNumberRequest,
  GetLoadRefNumberResponse,
  GetLoadSummaryListRequest,
  GetManifestPreviewRequest,
  GetPrevLoadsRequest,
  GetRemoveLoadManifestDataResp,
  GetTractorInfoForLoadAssignRequest,
  GetTripMapDataRequest,
  GetUpdatedManifestDataRequest,
  ITagsAssignParamsRequest,
  ITagsBulkAssignParamsRequest,
  ITagsBulkAssignPayload,
  LoadDetailsResponse,
  LoadOriginDestinationResponse,
  LoadOverviewRemoveLoads,
  LoadReferenceDetails,
  LoadSummaryListResponse,
  LoadWarningByIdsRequest,
  OnHoldStateLoadRequest,
  OnHoldStateLoadResponse,
  PaginatedLoadListQueryParams,
  PaginatedLoadListRequest,
  PaginatedLoadListRequestNew,
  PaginatedLoadListResponse,
  PaginatedTagsListQueryParams,
  ParsedFileResponse,
  QueryParams,
  ResequenceLoadStopRequest,
  ResequenceLoadStopResponse,
  SplitLoadRequest,
  TagCreateResponseDTO,
  TerminalAssignmentToLoadResponse,
  TripRevenueShare,
  TripStopListCoords,
  TripTerminalRequest,
  UnAssignDriverFromLoadRequest,
  UpdateAllActivitiesPayload,
  UpdateAllActivitiesRequest,
  UpdateLoadAdditionalDetailsRequest,
  UpdateLoadDetailsRequest,
  UpdateLoadModeRequest,
  UpdateLoadReferenceDetailsRequest,
  UpdateLoadRequest,
  UpdateLoadStopRequest,
  UpdateLoadStopResponse,
  UpdateManualHosLocationRequest,
  UpdateStatusPayload,
  UpdateStatusRequest,
  UpdateStatusResponse,
  ValidateLoadReferenceIdRequest,
  ValidateLoadReferenceIdResponse,
  WarningsForLoadAssignRequest,
  assignCarrierToLoadParams,
  deleteCommodityReq,
  GetLoadLocationNamesRequest,
} from '../../models';
import { CarrierMessageResponse } from '../../models/DTOs/Carrier/Requests';
import {
  PaginatedDispatchDriverListResponse,
  PaginatedGetPreviousLoadResponse,
} from '../../models/DTOs/Dispatch/Dispatch';
import { ILoadSearchGetSolutionResponse } from '../../models/DTOs/Loadsearch/Model';
import { GetTripStopRequest } from '../../models/DTOs/Trip/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { assignDocumentToLoad } from '../../subPages/loadsList/LoadDetailsPanel/utils';
import * as CreateManifest from '../../subPages/loadsList/Manifest/CreateManifest';
import * as CreateManifestForm from '../../subPages/loadsList/Manifest/CreateManifestForm';
import { LoadSummary } from '../../subPages/loadsList/models/LoadSummary';
import { PaginatedResult } from '../../types';
import { docType } from '../../utils/Doc';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { ILoadService } from '../interfaces/ILoadService';
import { isAxiosErrorCancel } from './DispatchService';
import {
  CARRIER_PAYMENT_RESOURCES,
  DRIVERS_GROUP_LAST_URL,
  LoadOverviewRemoveLoadsURL,
  TAGS_EBDPOINTS,
  addCommodityUrl,
  addLoadManifestURL,
  assignDispatchTerminalToLoadURL,
  assignDispatcherToLoadURL,
  assignDriverToLoadURL,
  assignTractorToLoadURL,
  assignTrailerToLoadURL,
  createLoadStopURL,
  createLoadURL,
  createManifestURL,
  deleteLoadManifestURL,
  deleteLoadStopURL,
  deleteLoadURL,
  downloadDispatchSheetURL,
  exportLoadListToExcelURL,
  findLoadDetailsURL,
  findLoadDuplicateDetailsURL,
  getAllActivities,
  getChassisNumberSearchURL,
  getContainerNumberSearchURL,
  getDispatcherInfoForLoadAssignURL,
  getDriverInfoForLoadAssignURL,
  getDriverWarningsForLoadAssignURL,
  getFinanceRevenueShareURL,
  getLoadAdditionalDetailsURL,
  getLoadLightListByNameSearchURL,
  getLoadMapURL,
  getLoadMilesById,
  getLoadOriginDestinationListURL,
  getLoadOverviewDetailsURL,
  getLoadRefNumberListURL,
  getLoadRoutesURL,
  getLoadStaticURL,
  getLoadSummaryByIdURL,
  getLoadSummaryListURL,
  getLoadWarningsByIdsURL,
  getLoadsCountURL,
  getManifestPreviewURL,
  getPackageTypesURL,
  getPaginatedLoadListURL,
  getParsedLoadDetailsFileURL,
  getPrevLoadsForDriverAssignPlanURL,
  getSingleDriverInfoForLoadAssignURL,
  getSolutionDelayURL,
  getTotalRevenueURL,
  getTractorInfoForLoadAssignURL,
  getTripListRevenueShareURL,
  getTripMapURL,
  getUpdatedManifestLoadURL,
  loadActionURL,
  logAuditURL,
  manifestInvokeURL,
  manifestReCalculationURL,
  resequenceLoadStopURL,
  splitLoadURL,
  unassignDispatcherFromLoadURL,
  unassignDriverFromLoadURL,
  unassignTractorFromLoadURL,
  unassignTrailerFromLoadURL,
  updateAllActivities,
  updateLoadAdditionalDetailsURL,
  updateLoadDetailsUrl,
  updateLoadDispatcherLineItemsURL,
  updateLoadDriverLineItemsURL,
  updateLoadModeUrl,
  updateLoadReferenceDetailsURL,
  updateLoadStopURL,
  updateLoadTractorLineItemsURL,
  updateLoadURL,
  validateLoadReferenceIdURL,
  getLoadLocationNamesListURL,
} from './requestConstants';
import { TagItem } from '@/models/DTOs/ManageTypes/Requests';

export class LoadService extends ILoadService {
  @annotateNameAsync
  async getLoadSummaryById(id: ByLoadIdRequest) {
    try {
      const result = await httpClient.getRaw(
        getLoadSummaryByIdURL,
        id,
        false,
        true
      );
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadsCount() {
    const requestData = new QueryParams();
    try {
      const response = await httpClient.get(getLoadsCountURL, requestData, {});
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaginatedLoadList(
    queryParams: PaginatedLoadListQueryParams,
    requestData: PaginatedLoadListRequestNew
  ) {
    try {
      const result = await httpClient.put<PaginatedLoadListResponse>(
        getPaginatedLoadListURL,
        queryParams,
        requestData,
        PaginatedLoadListResponse,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async exportLoadListToExcel(
    queryParams: PaginatedLoadListQueryParams,
    requestData: PaginatedLoadListRequestNew
  ) {
    try {
      const result = await httpClient.putRaw(
        exportLoadListToExcelURL,
        queryParams,
        requestData,
        true
      );

      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async downloadDispatchSheet(
    loadId: string,
    url: string = downloadDispatchSheetURL
  ) {
    const request = new ByLoadIdRequest();
    request.loadId = loadId;
    try {
      const result = await httpClient.getRaw(url, request);
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getDriverGroupWarnings(driverTeamId: string) {
    try {
      const request = new GetLoadDriverGroupWarnings();
      request.driverGroupId = driverTeamId;
      const result = await httpClient.putRaw(
        '/web/gantt/api/v2/gantt/getDriverGroupWarning',
        request,
        undefined
      );
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadStaticData() {
    try {
      return await httpClient.getRaw(getLoadStaticURL, new QueryParams());
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadRoutes(id: ByLoadIdRequest) {
    try {
      return await httpClient.getRaw(getLoadRoutesURL, id, false, true);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverInfoForLoadAssign(
    driverGroupId: string,
    loadId: string,
    loadStatus: string,
    prevLoadId: string
  ) {
    try {
      const req = new GetDriverInfoForLoadAssignRequest();
      req.driverGroupId = driverGroupId;
      req.loadId = loadId;
      req.loadStatus = loadStatus;
      req.prevLoadId = prevLoadId;
      return await httpClient.getRaw(getDriverInfoForLoadAssignURL, req);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getWarningsForLoadAssign(payloadData: WarningsForLoadAssignRequest) {
    try {
      return await httpClient.putRaw(
        getDriverWarningsForLoadAssignURL,
        undefined,
        payloadData
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async assignDriverToLoad(requestData: AssignDriverToLoadRequest) {
    try {
      const response = await httpClient.putRaw(
        assignDriverToLoadURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripAssignDriver',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'tripAssignDriver',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateLoadDriverLineItems(requestData) {
    try {
      return await httpClient.putRaw(
        updateLoadDriverLineItemsURL,
        undefined,
        requestData
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateLoadTractorLineItems(requestData) {
    try {
      return await httpClient.putRaw(
        updateLoadTractorLineItemsURL,
        undefined,
        requestData
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateLoadDispatcherLineItems(requestData) {
    try {
      return await httpClient.putRaw(
        updateLoadDispatcherLineItemsURL,
        undefined,
        requestData
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async assignTractorToLoad(requestData: AssignTractorToLoadRequest) {
    try {
      const response = await httpClient.postRaw(
        assignTractorToLoadURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripAssignTractor',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async unassignTrailerFromLoad(requestData: AssignTrailerToLoadRequest) {
    try {
      const response = await httpClient.postRaw(
        unassignTrailerFromLoadURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripUnAssignTrailer',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async unassignTractorFromLoad(requestData: AssignTractorToLoadRequest) {
    try {
      const response = await httpClient.postRaw(
        unassignTractorFromLoadURL,
        undefined,
        requestData
      );
      if (response.data.successfullyUnassigned) {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'tripUnAssignTractor',
        });
      }
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async assignTrailerToLoad(requestData: AssignTrailerToLoadRequest) {
    try {
      const response = await httpClient.postRaw(
        assignTrailerToLoadURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripAssignTrailer',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async unassignDispatcherFromLoad(requestData: AssignDispatcherToLoadRequest) {
    try {
      const response = await httpClient.postRaw(
        unassignDispatcherFromLoadURL,
        undefined,
        requestData
      );
      if (response.data.successfullyUnassigned) {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'tripUnAssignDispatcher',
        });
      }
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async unassignDriverFromLoad(requestData: UnAssignDriverFromLoadRequest) {
    try {
      const response = await httpClient.putRaw(
        unassignDriverFromLoadURL,
        requestData,
        undefined
      );
      if (response.data.successfullyUnassigned) {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'tripUnAssignDriver',
        });
      }
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async assignDispatcherToLoad(requestData: AssignDispatcherToLoadRequest) {
    try {
      const response = await httpClient.postRaw(
        assignDispatcherToLoadURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripAssignDispatcher',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTractorInfoForLoadAssign(
    tractorId: string,
    loadId: string,
    terminalIds?: string[]
  ) {
    try {
      const req = new GetTractorInfoForLoadAssignRequest();
      req.tractorId = tractorId;
      req.loadId = loadId;
      if (terminalIds) req.terminalIds = terminalIds;
      return await httpClient.getRaw(getTractorInfoForLoadAssignURL, req);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDispatcherInfoForLoadAssign(dispatcherId: string, loadId: string) {
    try {
      const req = new GetDispatcherInfoForLoadAssignRequest();
      req.dispatcherId = dispatcherId;
      req.loadId = loadId;
      return await httpClient.getRaw(getDispatcherInfoForLoadAssignURL, req);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getSingleDriverInfoForLoadAssign(driverId: string, loadId: string) {
    try {
      const req = new GetDriverInfoForLoadAssignRequest();
      req.driverId = driverId;
      req.loadId = loadId;
      return await httpClient.getRaw(getSingleDriverInfoForLoadAssignURL, req);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getLoadWarningsByIds(requestData: LoadWarningByIdsRequest) {
    try {
      return await httpClient.putRaw(
        getLoadWarningsByIdsURL,
        undefined,
        requestData,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getContainerSearch(
    searchText: string | undefined,
    pageNumber: number,
    skipLoader?: boolean
  ) {
    const requestData = new GetContainerListRequest(searchText);
    requestData.pageNumber = pageNumber;
    try {
      return await httpClient.getRaw(
        getContainerNumberSearchURL,
        requestData,
        undefined,
        skipLoader
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getChassisSearch(
    searchText: string | undefined,
    pageNumber: number,
    skipLoader?: boolean
  ) {
    const requestData = new GetChassisListRequest(searchText);
    requestData.pageNumber = pageNumber;
    try {
      return await httpClient.getRaw(
        getChassisNumberSearchURL,
        requestData,
        undefined,
        skipLoader
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getAppointmentAndBookingSearch(url: string, requestData: any) {
    try {
      return await httpClient.getRaw(url, requestData, undefined, true);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getParsedLoadDetailsFromFile(
    queryParams: QueryParams,
    file: File,
    loadParse = false
  ) {
    const serviceName = 'rateConDocumentUpload';
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const headers = { accept: 'application/json' };
      const response = await httpClient.post<ParsedFileResponse>(
        getParsedLoadDetailsFileURL,
        queryParams,
        formData,
        ParsedFileResponse,
        true,
        headers
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName,
        message: getSuccessMsg[serviceName](file.name),
      });
      return response;
    } catch (error) {
      if (loadParse) {
        const status = error?.message?.response?.status;

        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName,
          message: 'File not supported for extraction',
        });

        if (status === 417) {
          return new FormatError(
            'Data extraction failed due to the uploaded PDF file was of non-supported format.'
          );
        } else if (status === 400) {
          return new FormatError('Only PDF file format is accepted');
        }
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async assignDispatchTerminalToLoad(
    tripId: string,
    terminalId: string
  ): Promise<TerminalAssignmentToLoadResponse | ServiceError> {
    try {
      const queryParams = new TripTerminalRequest(true);
      queryParams.id = tripId;
      queryParams.terminalId = terminalId;
      const response = await httpClient.putRaw(
        assignDispatchTerminalToLoadURL,
        undefined,
        queryParams,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripAssignTerminal',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadLightListByNameSearch(requestData: PaginatedLoadListRequest) {
    try {
      const result = await httpClient.get<PaginatedLoadListResponse>(
        getLoadLightListByNameSearchURL,
        requestData,
        PaginatedLoadListResponse,
        true,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadOverviewDetails(queryParams: ByLoadIdRequest) {
    try {
      const response = await httpClient.getRaw(
        getLoadOverviewDetailsURL,
        queryParams,
        false,
        true
      );
      return response?.data || {};
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateLoadReferenceDetails(
    requestData: UpdateLoadReferenceDetailsRequest
  ) {
    try {
      const response = await httpClient.put<LoadReferenceDetails>(
        updateLoadReferenceDetailsURL,
        undefined,
        requestData,
        LoadReferenceDetails,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateLoadReferenceDetails',
      });
      return response;
    } catch (error: any) {
      error.error.response.status !== 409 &&
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'updateLoadReferenceDetails',
        });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getLoadAdditionalDetails(queryParams: GetLoadAdditionalDetailsRequest) {
    try {
      const response = await httpClient.getRaw(
        getLoadAdditionalDetailsURL,
        queryParams
      );
      return response?.data || {};
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async findLoadDetails(queryParams: FindLoadDetailsRequest) {
    try {
      const response = await httpClient.getRaw(findLoadDetailsURL, queryParams);
      return response?.data || {};
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
  @annotateNameAsync
  async findLoadDuplicateDetails(queryParams: FindLoadDetailsRequest) {
    try {
      const response = await httpClient.getRaw(
        findLoadDuplicateDetailsURL,
        queryParams
      );
      return response?.data || {};
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateLoadAdditionalDetails(
    requestData: UpdateLoadAdditionalDetailsRequest
  ) {
    try {
      const response = await httpClient.putRaw(
        updateLoadAdditionalDetailsURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateLoadReferenceDetails',
      });
      return response?.data || {};
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'updateLoadReferenceDetails',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async addUpdateLoadCommodityDetails(
    queryParams: ByLoadIdRequest,
    request: AddLoadCommodity
  ) {
    try {
      if (request.id) {
        const response = await httpClient.putRaw(
          addCommodityUrl,
          queryParams,
          request
        );
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'updateCommodityDetails',
        });

        return response;
      }
      const response = await httpClient.postRaw(
        addCommodityUrl,
        queryParams,
        request
      );

      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'addCommodityDetails',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'addCommodityDetails',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async deleteLoadCommodityDetails(queryParams: deleteCommodityReq) {
    try {
      const response = await httpClient.deleteRaw(
        addCommodityUrl,
        queryParams,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteCommodityDetails',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'deleteCommodityDetails',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateLoadModeDetails(request: UpdateLoadModeRequest) {
    try {
      const response = await httpClient.putRaw(
        updateLoadModeUrl,
        undefined,
        request
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateLoadModeDetails',
      });

      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'updateLoadModeDetails',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateLoadDetails(request: UpdateLoadDetailsRequest) {
    try {
      const response = await httpClient.putRaw(
        updateLoadDetailsUrl,
        undefined,
        request
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateLoadDetails',
      });

      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'updateLoadDetails',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
  @annotateNameAsync
  async validateLoadReferenceId(queryParams: ValidateLoadReferenceIdRequest) {
    try {
      return await httpClient.get<ValidateLoadReferenceIdResponse>(
        validateLoadReferenceIdURL,
        queryParams,
        ValidateLoadReferenceIdResponse,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve({ valid: false });
    }
  }

  @annotateNameAsync
  async getPrevLoadsForDriverAssignPlan(
    requestData: GetPrevLoadsRequest
  ): Promise<PaginatedGetPreviousLoadResponse | ServiceError> {
    try {
      const result = await httpClient.get<PaginatedGetPreviousLoadResponse>(
        getPrevLoadsForDriverAssignPlanURL,
        requestData,
        PaginatedDispatchDriverListResponse,
        false,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createLoadWithAssignedDocument(
    payloadData: CreateLoadRequest,
    assignedDocument: File
  ) {
    const serviceName = 'loadCreation';
    try {
      const response = await httpClient.post<LoadDetailsResponse>(
        createLoadURL,
        new QueryParams(true),
        payloadData,
        LoadDetailsResponse,
        false,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName,
        message: getSuccessMsg[serviceName](response.seqNumber),
      });
      if (assignedDocument) {
        await assignDocumentToLoad(
          {
            id: response.id,
            seqNumber: response.seqNumber,
            terminalId: response.terminalId || response.terminal?.id,
          },
          assignedDocument
        );
      }
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName,
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createLoad(payloadData: CreateLoadRequest) {
    const serviceName = 'loadCreation';
    try {
      const response = await httpClient.post<LoadDetailsResponse>(
        createLoadURL,
        new QueryParams(true),
        payloadData,
        LoadDetailsResponse,
        false,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName,
        message: getSuccessMsg[serviceName](response.seqNumber),
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName,
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createLoadStop(payloadData: CreateLoadStopRequest) {
    try {
      return await httpClient.put<CreateLoadStopResponse>(
        createLoadStopURL,
        undefined,
        payloadData,
        CreateLoadStopResponse,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateLoadStop(payloadData: UpdateLoadStopRequest) {
    try {
      return await httpClient.put<UpdateLoadStopResponse>(
        updateLoadStopURL,
        undefined,
        payloadData,
        UpdateLoadStopResponse,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async resequenceLoadStop(payloadData: ResequenceLoadStopRequest) {
    try {
      const response = await httpClient.put<ResequenceLoadStopResponse>(
        resequenceLoadStopURL,
        undefined,
        payloadData,
        ResequenceLoadStopResponse,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripResequience',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async deleteLoadStop(queryParams: DeleteLoadStopRequest) {
    try {
      const response = await httpClient.deleteRaw(
        deleteLoadStopURL,
        queryParams,
        undefined
      );
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getLoadSummaryList(
    requestData: GetLoadSummaryListRequest
  ): Promise<PaginatedResult<LoadSummaryListResponse> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getLoadSummaryListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPackageTypes() {
    try {
      const response = await httpClient.getRaw(getPackageTypesURL, undefined);
      return response.data || [];
    } catch (error) {
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getLoadRefIdList(
    requestData: GetLoadRefNumberRequest
  ): Promise<PaginatedResult<GetLoadRefNumberResponse> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getLoadRefNumberListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadOriginDestinationList(
    requestData: GetLoadOriginDestinationRequest
  ): Promise<PaginatedResult<LoadOriginDestinationResponse> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getLoadOriginDestinationListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadLocationNamesList(
    requestData: GetLoadLocationNamesRequest
  ): Promise<PaginatedResult<string> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getLoadLocationNamesListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateLoad(
    requestData: UpdateLoadRequest[]
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.patchRaw(
        updateLoadURL,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async splitLoad(requestData: SplitLoadRequest): Promise<any | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        splitLoadURL,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLastHosInfo(
    requestData: GetLastHosInfoRequest,
    skipTrackPromise = false
  ): Promise<any> {
    try {
      const response = await httpClient.putRaw(
        DRIVERS_GROUP_LAST_URL.getLastHosInfo,
        undefined,
        requestData,
        false,
        skipTrackPromise
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getIgnoreHosInfo(
    requestData: GetLastHosInfoRequest,
    skipTrackPromise = false
  ): Promise<any> {
    try {
      const response = await httpClient.putRaw(
        DRIVERS_GROUP_LAST_URL.getIgnoreHosInfo + requestData?.organizationId,
        undefined,
        requestData,
        false,
        skipTrackPromise
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getHosLastLocation(
    requestData: GetHosLastLocationRequest
  ): Promise<any> {
    try {
      const response = await httpClient.putRaw(
        DRIVERS_GROUP_LAST_URL.getHosLastLocation,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async updateManualHosLocation(
    requestData: UpdateManualHosLocationRequest
  ): Promise<any> {
    try {
      const response = await httpClient.putRaw(
        DRIVERS_GROUP_LAST_URL.updateManualHosLocation,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateManualHosLocation',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'updateManualHosLocation',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getAllActivities(
    requestData: GetAllStopsRequest
  ): Promise<GetAllStopsResponse | ServiceError> {
    try {
      const response = await httpClient.get<GetAllStopsResponse>(
        getAllActivities,
        requestData,
        GetAllStopsResponse,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateAllActivities(
    requestData: UpdateAllActivitiesRequest,
    payload: UpdateAllActivitiesPayload
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.patchRaw(
        updateAllActivities,
        requestData,
        payload
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadDetailsActions(
    requestData: GetLoadDetailsActionsRequest
  ): Promise<any> {
    try {
      const response = await httpClient.getRaw(
        DRIVERS_GROUP_LAST_URL.getLoadDetailsActions,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateStatus(
    requestData: UpdateStatusRequest,
    payload: UpdateStatusPayload
  ): Promise<UpdateStatusResponse | ServiceError> {
    try {
      const response = await httpClient.put<UpdateStatusResponse>(
        loadActionURL,
        requestData,
        payload,
        UpdateStatusResponse
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteLoad(
    requestData: DeleteLoadRequest
  ): Promise<DeleteLoadResponse | ServiceError> {
    try {
      const response = await httpClient.delete<DeleteLoadResponse>(
        deleteLoadURL,
        requestData,
        undefined,
        UpdateStatusResponse
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async deleteLoadManifest(
    requestData: DeleteLoadManifestRequest
  ): Promise<DeleteLoadResponse | ServiceError> {
    try {
      const response = await httpClient.delete<DeleteLoadResponse>(
        deleteLoadManifestURL,
        requestData,
        undefined,
        UpdateStatusResponse
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadMapData(
    requestData: ByLoadIdOnlyRequest
  ): Promise<MarkersData | ServiceError> {
    try {
      return await httpClient.get<MarkersData>(
        getLoadMapURL,
        requestData,
        undefined,
        true,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTripMapData(
    requestData: GetTripMapDataRequest
  ): Promise<MarkersData | ServiceError> {
    try {
      return await httpClient.get<MarkersData>(
        getTripMapURL,
        requestData,
        undefined,
        true,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getTotalRevenue(
    requestData: PaginatedLoadListRequestNew
  ): Promise<string | ServiceError> {
    try {
      const result = await httpClient.putRaw(
        getTotalRevenueURL,
        new QueryParams(),
        requestData,
        undefined,
        true
      );
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getAuditLogs(id: string) {
    try {
      const response = await httpClient.getRaw(
        `${logAuditURL}?loadId=${id}`,
        undefined
      );
      return response?.data || [];
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getTripListRevenueShare(
    requestData: TripStopListCoords[]
  ): Promise<TripRevenueShare[] | ServiceError> {
    try {
      return await httpClient.put(
        getTripListRevenueShareURL,
        new QueryParams(),
        requestData,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async downloadCarrierRateConfirmationSheet(
    tripId: string,
    carrierId: string
  ): Promise<docType | ServiceError> {
    try {
      const queryParams: any = new QueryParams();
      queryParams.tripId = tripId;
      queryParams.carrierId = carrierId;
      const result = await httpClient.getRaw(
        CARRIER_PAYMENT_RESOURCES.downloadCarrierRateConfirmationSheet,
        queryParams,
        true,
        true
      );
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getManifestPreview(
    requestData: GetManifestPreviewRequest
  ): Promise<CreateManifestForm.propsTypeCreateManifestForm | ServiceError> {
    try {
      return await httpClient.get<CreateManifestForm.propsTypeCreateManifestForm>(
        getManifestPreviewURL,
        requestData,
        undefined,
        true,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async creatManifest(requestData: CreateManifest.propsTypeCreatManifest) {
    try {
      const response = await httpClient.postRaw(
        createManifestURL,
        undefined,
        requestData
      );

      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async manifestReCalculation(
    requestData: CreateManifestForm.propsTypeCreateManifestForm
  ) {
    try {
      const result = await httpClient.putRaw(
        manifestReCalculationURL,
        undefined,
        requestData,
        undefined,
        true
      );
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async removeLoadFromManifest(
    requestData: LoadOverviewRemoveLoads
  ): Promise<GetRemoveLoadManifestDataResp | ServiceError> {
    try {
      const result = await httpClient.put(
        LoadOverviewRemoveLoadsURL,
        requestData,
        undefined,
        undefined,
        true
      );
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async manifestInvoke(
    requestData: Array<CreateManifestForm.IManifestInvokeResponse>
  ) {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await httpClient.postRaw(
        manifestInvokeURL,
        undefined,
        requestData,
        false,
        headers,
        true
      );

      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getSolutionDelay(
    payload: CreateManifestForm.propsTypeSolutionrequest
  ): Promise<ILoadSearchGetSolutionResponse | ServiceError | null> {
    try {
      return await httpClient.putRaw(
        getSolutionDelayURL + `?organizationId=${getOrganizationId()}`,
        undefined,
        payload,
        undefined,
        true
      );
    } catch (error) {
      return null;
    }
  }
  @annotateNameAsync
  async addLoadToManifest(
    payload: CreateManifest.propsTypeAddLoadtoManifest
  ): Promise<LoadSummary | ServiceError | null> {
    try {
      return await httpClient.putRaw(addLoadManifestURL, undefined, payload);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getFinanceRevenueShare(
    requestData: ByLoadIdRequest
  ): Promise<IFinanceRevenueShareRespType | ServiceError> {
    try {
      return await httpClient.get<IFinanceRevenueShareRespType>(
        getFinanceRevenueShareURL,
        requestData,
        undefined,
        true,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getUpdatedManifestLoad(
    payLoad: GetUpdatedManifestDataRequest
  ): Promise<Array<LoadSummary> | ServiceError> {
    try {
      return await httpClient.get<Array<LoadSummary>>(
        getUpdatedManifestLoadURL,
        payLoad,
        undefined,
        true,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async getCarrierList(
    name: string | undefined,
    pageNumber: number
  ): Promise<any> {
    const requestData = new CarrierSearchListParams(name);
    requestData.pageNumber = pageNumber;
    requestData.pageSize = 25;
    requestData.statusFilter = 'ACTIVE';
    requestData.name = name;
    requestData.sort = '+name';
    try {
      return await httpClient.get(
        CARRIER_PAYMENT_RESOURCES.carrierList,
        requestData,
        undefined,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async brokeredUnAssign(requestData: BrokeredUnAssignParams): Promise<any> {
    try {
      return await httpClient.put(
        CARRIER_PAYMENT_RESOURCES.brokeredUnAssign,
        requestData,
        undefined,
        CarrierMessageResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async assignCarrierToLoad(
    requestData: assignCarrierToLoadParams
  ): Promise<any> {
    try {
      const response = await httpClient.put<AssignCarrierToLoadDTO>(
        CARRIER_PAYMENT_RESOURCES.carrierAssign,
        new QueryParams(),
        requestData,
        AssignCarrierToLoadDTO,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripAssignCarrier',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'tripAssignCarrier',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getInvoiceSeqNumberList(
    requestData: GetInvoiceSummaryListRequest
  ): Promise<PaginatedResult<LoadSummaryListResponse> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/invoice/api/v3/invoice/seqnumber/list`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async onHoldStateLoad(
    request: OnHoldStateLoadRequest,
    isManifest: boolean
  ): Promise<ServiceError | OnHoldStateLoadResponse> {
    const getServiceName = () => {
      if (request.onHold) {
        return isManifest ? 'holdStateManifest' : 'holdStateLoad';
      } else {
        return isManifest ? 'releaseStateManifest' : 'releaseStateLoad';
      }
    };

    const successNotification = {
      type: 'SUCCESS',
      serviceName: getServiceName(),
    };
    const errorNotification = {
      type: 'FAILURE',
      serviceName: successNotification.serviceName,
    };
    try {
      const response = await httpClient.put<OnHoldStateLoadResponse>(
        CARRIER_PAYMENT_RESOURCES.onHoldStateLoad,
        request,
        undefined,
        OnHoldStateLoadResponse,
        false
      );
      if (response?.message?.includes('successfully')) {
        RootStoreInstence.setNotificationType(successNotification);
      } else {
        RootStoreInstence.setNotificationType(errorNotification);
      }
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType(errorNotification);
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async validateLoadStopsLocations(
    data: Partial<LoadSummary>
  ): Promise<boolean> {
    try {
      let isApptDateMissing = false;
      let trips: any = [];
      if (data?.loadType) {
        const requestData = new ByLoadIdRequest();
        requestData[data?.loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] =
          data?.id;
        const routes = await this.getLoadRoutes(requestData);
        trips = routes?.data?.trips ?? [];
      } else {
        const trip = new GetTripStopRequest({
          tripId: data?.id!,
          fetchHosData: true,
        });
        const routes = await tripService.getTripStop(trip);
        trips = [routes];
      }
      for (const trip of trips) {
        const stops = trip?.stops ?? [];
        for (let i = 0; i < stops?.length; i++) {
          const stop = trip?.stops?.[i];
          if (!stop?.appointmentEndDate || !stop?.appointmentStartDate) {
            isApptDateMissing = true;
            break;
          }
        }
        if (isApptDateMissing) break;
      }
      return isApptDateMissing;
    } catch (error) {
      return false;
    }
  }

  @annotateNameAsync
  async getPaginatedTagsList(queryParams: PaginatedTagsListQueryParams) {
    try {
      const result = await httpClient.put<PaginatedLoadListResponse>(
        TAGS_EBDPOINTS.GET_TAGS_LIST,
        queryParams,
        undefined,
        PaginatedLoadListResponse,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createUserDefinedTag(payLoad: TagCreateResponseDTO) {
    try {
      const response = await httpClient.postRaw(
        TAGS_EBDPOINTS.TAG_CREATE,
        undefined,
        payLoad
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Successfully created new Tag.',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async loadAssignmentTag(
    payLoad: Array<TagItem>,
    paramsRequest: ITagsAssignParamsRequest
  ) {
    try {
      const response = await httpClient.putRaw(
        'web/load/api/v2/tags/update/tag-assignment',
        paramsRequest,
        payLoad
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: response?.data?.message,
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Failed to update Tags',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async loadBulkAssignmentTag(
    payLoad: ITagsBulkAssignPayload,
    paramsRequest: ITagsBulkAssignParamsRequest
  ) {
    try {
      const response = await httpClient.putRaw(
        '/web/load/api/v2/tags/bulk-update/tag-assignment',
        paramsRequest,
        payLoad
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: response?.data?.message,
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Failed to update Tags',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getLoadTotalMilesById(loadId: string) {
    try {
      const response = await httpClient.getRaw(
        getLoadMilesById,
        new GetLoadAdditionalDetailsRequest({ loadId }),
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
