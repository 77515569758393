import { Box, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { EquipmentImportExport } from '../../common/PendoClassIDs/constants';
import HyperLink from '../../common/Ui/HyperLink/HyperLink';
import { tripLoadCustomTitle } from '../../common/UiUtils/uiUtils';
import { LoadConstants } from '../../locales/en/allLoads/loads/index';
import { LoadViewTranslations } from '../../locales/en/allLoads/loads/modal';
import { TripsConstants } from '../../locales/en/allLoads/trips/index';
import { AssignmentShort } from '../../models/common/modelsShort';
import { GridColDefSelf, StatusTypes } from '../../types';
import {
  fromIsoToMoment,
  getFormattedDateForGrid,
  numberWithThousandSeparator,
} from '../../utils';
import { renderDateTime } from '../../utils/NameAndDateTimeGridField';
import { tripFieldsConfig } from '../../views/trips/constants/constants';
import { WarningIconWithTooltip } from '../invoices/constants';
import { LoadPriorityEnumVals } from './CreateLoad/constants/fieldValues';
import WarningComponent from './components/WarningComponent';
import { LoadSummary } from './models/LoadSummary';
import { TagList } from '@/common/Tag/components/TagList';
export type FOnItemClick = (
  assignment: AssignmentShort,
  params: GridRenderCellParams<any, LoadSummary>
) => void;

const settlementStatuses: StatusTypes = {
  PENDING: LoadConstants.settlementStatusPending,
  IN_REVIEW: LoadConstants.settlementStatusInReview,
  CLOSED: LoadConstants.settlementStatusClosed,
  NONE: '',
};

function renderDispatchTerminal(
  params: GridRenderCellParams<any, LoadSummary>
): string | undefined {
  return params.row.tripConnectionDetails?.reduce(
    (terminalsText: string, trip: any) => {
      if (trip.terminal) {
        const { name } = trip.terminal;
        terminalsText += !terminalsText ? name : `, ${name}`;
      }
      return terminalsText;
    },
    ''
  );
}

const renderTripAssignments = (
  params: GridRenderCellParams<any, LoadSummary>,
  onItemClick: FOnItemClick
) => {
  return params.value?.map((assignment: AssignmentShort, index: number) => (
    <span key={assignment.id + assignment.tripId}>
      {!!index && ', '}
      <HyperLink
        value={assignment.name}
        onClick={(event) => {
          event?.stopPropagation();
          onItemClick(assignment, params);
        }}
      />
    </span>
  ));
};

const renderCarriers = (
  params: GridRenderCellParams,
  onItemClick: FOnItemClick
) => {
  return params.row?.tripConnectionDetails
    ?.filter(
      ({ carrierDetails, brokerageTrip }) => !!brokerageTrip && !!carrierDetails
    )
    ?.map((assignment, index: number) => (
      <span key={assignment.id + assignment.tripId}>
        {!!index && ', '}
        <HyperLink
          value={assignment?.carrierDetails?.name}
          onClick={(event) => {
            event?.stopPropagation();
            onItemClick({ id: assignment?.carrierDetails?.id }, params);
          }}
        />
      </span>
    ));
};

const renderBrokeredTrip = (
  params: GridRenderCellParams,
  onItemClick: FOnItemClick
) => {
  return params.row?.tripConnectionDetails
    ?.filter(({ brokerageTrip }) => !!brokerageTrip)
    ?.map((assignment, index: number) => (
      <span key={assignment.id + assignment.tripId}>
        {!!index && ', '}
        <HyperLink
          value={assignment?.tripSequenceNumber}
          onClick={(event) => {
            event?.stopPropagation();
            onItemClick({ id: assignment.tripId }, params);
          }}
        />
      </span>
    ));
};

// const manifestCustomer = (params: LoadSummary) => {
//   return params.customers?.map((data: { name: string }, index: number) => (
//     <span key={data.name}>
//     {!!index && ', '}
//     <HyperLink value={data.name} />
//     </span>
//   ));

// };
const manifestTerinal = (params: LoadSummary) => {
  return params.terminals?.map(
    (data: { companyName: string }) => data.companyName
  );
};

export enum loadTypeNameEnum {
  MANIFEST = LoadConstants.loadTypeManifest,
  LTL = LoadConstants.loadTypePartial,
  FTL = LoadConstants.loadTypeFTL,
}

export enum ELoadType {
  MANIFEST = 'MANIFEST',
  LTL = 'LTL',
  FTL = 'FTL',
}

export const columns = (
  onItemClick: FOnItemClick,
  timezone?: string
): GridColDefSelf[] => [
  {
    flex: 1,
    minWidth: 60,
    field: 'warning',
    permanent: true,
    headerName: '',
    sortable: false,
    renderCell: (params) => <WarningComponent warning={params.row.warning} />,
  },
  {
    flex: 1,
    minWidth: 135,
    field: 'seqNumber',
    permanent: true,
    headerName: LoadViewTranslations.loadViewLoadId,
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'referenceId',
    headerName: LoadViewTranslations.loadViewReference + ' #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 180,
    field: 'loadStatus',
    headerName: LoadViewTranslations.loadViewLoadStatus,
    sortable: false, // should be opened after BE fixes
    renderCell: (params) => {
      if (params.row.pinned) return null;
      const status = params.value;
      return tripLoadCustomTitle({
        status,
        lastUpdatedStop: params.row.lastUpdatedStop,
        nonInvoiceable: params.row.nonInvoiceable,
        onHold: !!params.row.onHold,
        onHoldNote: params.row.onHoldNote,
      });
    },
  },
  {
    flex: 5,
    minWidth: 135,
    field: 'loadType',
    permanent: false,
    sortable: false,
    headerName: LoadViewTranslations.loadViewLoadType,
    renderCell: (params) => {
      return loadTypeNameEnum[params.row.loadType];
    },
  },
  {
    flex: 6,
    minWidth: 135,
    field: 'manifestBaseDetails',
    sortable: false,
    permanent: false,
    headerName: LoadConstants.loadTypeManifest,
    renderCell: (params) => {
      if (params.row.manifestBaseDetails?.seqNumber) {
        return params.row.manifestBaseDetails.seqNumber;
      }
    },
  },
  // {
  //   field: tripFieldsConfig.onHoldState.fieldName,
  //   headerName: tripFieldsConfig.onHoldState.label,
  //   renderCell: () => {
  //     return null;
  //   },
  // },

  {
    flex: 1,
    minWidth: 90,
    field: 'loadSettlementStatus',
    headerName: TripsConstants.settlementStatus,
    sortable: false,
    valueGetter: (params) =>
      settlementStatuses[params.row.loadSettlementStatus],
  },

  {
    flex: 1,
    minWidth: 200,
    field: 'tags',
    headerName: 'Tags',
    sortable: false,
    renderCell: (params) => {
      return (
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={1}
          sx={{ mt: '5px', mb: '5px' }}
        >
          <TagList small tags={params.row.tags} />
        </Stack>
      );
    },
  },

  {
    flex: 1,
    minWidth: 130,
    maxWidth: 160,
    field: 'revenue',
    headerName: LoadViewTranslations.loadViewRevenue,
    sortable: true,
    align: 'right',
    headerAlign: 'center',
    renderCell: (params) => {
      return (
        <Typography
          variant="body2"
          sx={[
            {
              color: 'text.primary',
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 700,
            },
          ]}
        >
          ${numberWithThousandSeparator(params.value, false, 2)}
        </Typography>
      );
    },
  },
  {
    flex: 1,
    field: 'trips',
    headerName: TripsConstants.tripId,
    sortable: false,
    minWidth: 100,
    renderCell: (params) => renderTripAssignments(params, onItemClick),
  },
  {
    field: tripFieldsConfig.loadPriority.fieldName,
    headerName: tripFieldsConfig.loadPriority.label,
    minWidth: 130,
    renderCell: (params) => (
      <Typography
        variant="body2"
        {...(params?.row?.loadPriority === 'CRITICAL'
          ? { color: 'error' }
          : {})}
      >
        {LoadPriorityEnumVals[params?.row?.loadPriority] ?? ''}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: tripFieldsConfig.brokerageTrip.fieldName,
    headerName: tripFieldsConfig.brokerageTrip.label,
    minWidth: 130,
    renderCell: (params) => renderBrokeredTrip(params, onItemClick),
    sortable: false,
  },
  {
    field: tripFieldsConfig.canBeBrokered.fieldName,
    headerName: tripFieldsConfig.canBeBrokered.label,
    minWidth: 130,
    renderCell: (params) => {
      if (params?.row?.canBeBrokered == null) return '';
      return params?.row?.canBeBrokered ? 'Yes' : 'No';
    },
    sortable: false,
  },
  {
    flex: 1,
    field: 'driverGroups',
    minWidth: 130,
    headerName: LoadViewTranslations.loadViewDriver,
    isHyperLink: false,
    sortable: false,
    renderCell: (params) => renderTripAssignments(params, onItemClick),
  },
  {
    flex: 1,
    minWidth: 130,
    field: tripFieldsConfig.carrierDetails.fieldName,
    headerName: tripFieldsConfig.carrierDetails.label,
    sortable: false,
    renderCell: (params) => renderCarriers(params, onItemClick),
  },
  {
    flex: 1,
    minWidth: 130,
    field: 'dispatchers',
    headerName: LoadViewTranslations.loadViewDispatcher,
    isHyperLink: false,
    sortable: false,
    renderCell: (params) => renderTripAssignments(params, onItemClick),
  },
  {
    flex: 1,
    minWidth: 150,
    field: 'customerName',
    headerName: LoadViewTranslations.loadViewCustomer,
    isHyperLink: false,
    sortable: false,
    renderCell: (params) => {
      if (params.row.pinned) return null;
      return (
        <>
          {!params.row.customerMapped && params.row.customerMapped !== null && (
            <WarningIconWithTooltip
              text={t('customerNotMappedWithFactoring')}
            />
          )}
          {params.row.loadType === 'MANIFEST' ? (
            params.row.customers?.map(
              (
                data: {
                  id: any;
                  name: string;
                },
                index: number
              ) => (
                <span key={data.name}>
                  {!!index && ', '}
                  <HyperLink id={data.id} value={data.name} />
                </span>
              )
            )
          ) : (
            <HyperLink id={params.row.customerId} value={params.value} />
          )}
        </>
      );
    },
  },

  {
    flex: 1,
    minWidth: 100,
    field: 'tractors',
    headerName: LoadViewTranslations.loadViewTractor + ' #',
    sortable: false,
    isHyperLink: true,
    renderCell: (params) => renderTripAssignments(params, onItemClick),
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'trailers',
    headerName: LoadViewTranslations.loadViewTrailer + ' #',
    sortable: false,
    isHyperLink: true,
    renderCell: (params) => renderTripAssignments(params, onItemClick),
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'equipmentTypes',
    headerName: LoadViewTranslations.loadViewRequiredEquipmentType,
    sortable: false,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'sealNumber',
    headerName: LoadViewTranslations.loadViewSeal + ' #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'chassisNumber',
    headerName: LoadViewTranslations.loadViewChassis + ' #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'loadDirection',
    headerName: LoadViewTranslations.loadDirection,
    sortable: true,
    renderCell: (params) => {
      return params.value
        ? params.value in EquipmentImportExport
          ? EquipmentImportExport[params.value]
          : params.value
        : null;
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'bookingNumber',
    headerName: LoadViewTranslations.bookingNumber + ' #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'appointmentNumber',
    headerName: LoadViewTranslations.appointmentNumber + ' #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'containerNumber',
    headerName: LoadViewTranslations.loadViewContainer + ' #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'commodityTypes',
    headerName: LoadViewTranslations.loadViewCommodityType,
    sortable: false,
  },
  {
    flex: 1,
    minWidth: 70,
    field: 'totalWeight',
    headerName: LoadViewTranslations.loadViewWeight,
    sortable: true,
    renderCell: (params) => {
      return params.value && params.value != 0 ? (
        <Box component="span">
          {numberWithThousandSeparator(params.value)} lbs
        </Box>
      ) : null;
    },
  },
  {
    flex: 1,
    minWidth: 70,
    field: 'lastFreeDay',
    headerName: LoadViewTranslations.lastFreeDay,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any>) => {
      if (params.row.pinned) return null;
      return getFormattedDateForGrid(
        fromIsoToMoment(params.value),
        'MM/DD/yyyy'
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'originLocationName',
    headerName: LoadViewTranslations.originLocationName,
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'destinationLocationName',
    headerName: LoadViewTranslations.destinationLocationName,
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'fromLocationName',
    headerName: LoadViewTranslations.loadViewOrigin,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      if (params.row.pinned) return null;
      return params.row.fromLocationName;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'toLocationName',
    headerName: LoadViewTranslations.loadViewDestination,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      if (params.row.pinned) return null;
      return params.row.toLocationName;
    },
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'pickup',
    headerName: LoadViewTranslations.loadViewPickUp,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      if (params.row.pinned) return null;
      return renderDateTime(
        params.row.startDateTime,
        params.row.firstStopTimeZone
      );
    },
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'dropOff',
    headerName: LoadViewTranslations.loadViewDropOff,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      if (params.row.pinned) return null;
      return renderDateTime(
        params.row.endDateTime,
        params.row.lastStopTimeZone
      );
    },
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'actualStartDateTime',
    headerName: LoadViewTranslations.actualStartDateTime,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      if (params.row.pinned) return null;
      return renderDateTime(
        params.row.actualStartDateTime,
        params.row.firstStopTimeZone
      );
    },
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'actualEndDateTime',
    headerName: LoadViewTranslations.actualEndDateTime,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any>) => {
      if (params.row.pinned) return null;
      return renderDateTime(
        params.row.actualEndDateTime,
        params.row.lastStopTimeZone
      );
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'numberOfStops',
    headerName: LoadViewTranslations.loadViewStops,
    sortable: false,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'missingDocuments',
    headerName: LoadViewTranslations.loadViewMissingDocs,
    sortable: false,
    valueGetter: (params) => params.value?.join(', '),
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'dispatchNotes',
    headerName: LoadViewTranslations.loadViewDispatchNotes,
    sortable: false,
  },
  {
    flex: 1,
    field: 'terminal',
    minWidth: 130,
    headerName: LoadViewTranslations.loadViewInvoicingTerminal,
    sortable: false,
    // valueGetter: (params) => params.value?.name,
    renderCell: (params) => {
      return (
        <>
          {params.row.loadType === 'MANIFEST'
            ? manifestTerinal(params.row)
            : params.value?.name}
        </>
      );
    },
  },
  {
    flex: 1,
    minWidth: 130,
    field: 'dispatchTerminals',
    headerName: LoadViewTranslations.loadViewDispatchTerminal,
    sortable: false,
    valueGetter: (params) => renderDispatchTerminal(params),
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'createDate',
    headerName: TripsConstants.createdDateTime,
    sortable: true,
    renderCell: (params) => {
      if (params.row.pinned) return null;
      return renderDateTime(params.value, timezone);
    },
  },
];

export const DEFAULT_PAGING_NUMBER = 0;
