import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import FileUploadUpdated from '../../../../../common/Ui/FileUpload/indexUpdated';
import DatePicker from '../../../../../common/Ui/FormDatePicker/FormDatePicker';
import FormPhoneField from '../../../../../common/Ui/FormPhoneField';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import Switcher from '../../../../../common/Ui/Switcher';
import TextField from '../../../../../common/Ui/TextField/TextField';
import {
  INVOICE_NAME,
  invoiceTermsOptions,
  settlementPeriods,
} from '../../constants';
import SettingBlock from '../Blocks/SettingBlock';
import { SettingBlockItem } from '../Blocks/SettingBlockItem';
function InvoiceTerms() {
  const { control, watch } = useFormContext();
  const isInvoiceTermCustom = watch('invoiceTerm') === 'CUSTOM';
  return (
    <SettingBlockItem
      title="Invoice Terms"
      subTitle="How long do your customers have to pay their invoice?"
    >
      <Grid container spacing={1}>
        <SingleAutocompleteForm
          control={control}
          fieldName="value"
          fieldValue="key"
          name="invoiceTerm"
          label="Invoice Terms"
          options={invoiceTermsOptions}
          disableClear
          sizes={{ xs: isInvoiceTermCustom ? 6 : 12 }}
        />
        {isInvoiceTermCustom && (
          <TextField
            control={control}
            name="invoiceTermValue"
            label="Number of days"
            required
            onlyNumbers
            sizes={{ xs: 6 }}
          />
        )}
      </Grid>
    </SettingBlockItem>
  );
}

function LoadIdPrefix() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Load/Invoice ID Prefix"
      subTitle="Would you like each Load ID to start with anything specific? (E.g. LD1222)"
    >
      <TextField control={control} name="loadPrefix" label="Load ID Prefix" />
    </SettingBlockItem>
  );
}

function ManifestIdPrefix() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Manifest ID Prefix"
      subTitle="Would you like each Manifest ID to start with anything specific? (E.g. MD1222)"
    >
      <TextField
        control={control}
        name="manifestPrefix"
        label="Manifest ID Prefix"
      />
    </SettingBlockItem>
  );
}

function InvoiceCustomPrefix() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Combined/Manual Invoice ID Prefix"
      subTitle="Would you like each Combined/Manual ID to start with anything specific? (E.g. IN1222)"
    >
      <TextField
        control={control}
        name="invoicePrefix"
        label="Combined/Manual Invoice ID Prefix"
      />
    </SettingBlockItem>
  );
}

function NextLoadIdNumber() {
  const { control, setValue } = useFormContext();
  return (
    <SettingBlockItem
      title="Next Manifest/Load/Invoice ID Number"
      subTitle="Which number would you like associated with the next load created?"
    >
      <TextField
        control={control}
        name="seqNumber"
        label="Next Manifest/Load/Invoice ID Number"
        required
        onlyNumbers
        onChangeCallback={(data: any) =>
          setValue('isSeqNumberManuallyUpdated', true)
        }
      />
    </SettingBlockItem>
  );
}

function SettlementStartDate() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Settlement Start Date"
      subTitle="When would you like to begin driver settlements?"
    >
      <DatePicker
        control={control}
        name="settlementDate"
        label="Settlement Start Date"
        required
        styleProps={{ width: '100%' }}
      />
    </SettingBlockItem>
  );
}

function SettlementPeriod() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Settlement Period Length"
      subTitle="What is the length of each driver settlement?"
    >
      <SingleAutocompleteForm
        control={control}
        name="settlementPeriod"
        fieldName="value"
        fieldValue="key"
        label="Settlement Period Length"
        options={settlementPeriods}
        required
      />
    </SettingBlockItem>
  );
}

function InvoicingLogo() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Invoicing logo"
      subTitle="Do you want to use Terminal logo for invoicing?"
    >
      <Switcher
        control={control}
        name="considerTerminalLogo"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

function FinancialItemsAutoRefresh() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Automatic refresh of Invoice and payments"
      subTitle="Would you like to auto update invoices and payments when load is modified?"
    >
      <Switcher
        control={control}
        name="financialItemsAutoRefresh"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}
function ContactName() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem title="Contact Name (Payable To)" subTitle="">
      <TextField
        control={control}
        name="companyDetails.payableToName"
        label="Name"
      />
    </SettingBlockItem>
  );
}

function ContacEmail() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem title="Contact Email (Payable To)" subTitle="">
      <TextField
        control={control}
        name="companyDetails.payableToEmail"
        label="Email"
      />
    </SettingBlockItem>
  );
}

function ContactAddress() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem title="Address (Payable To)" subTitle="">
      <TextField
        control={control}
        required={true}
        name="companyDetails.payableToAddress"
        label="Address"
      />
    </SettingBlockItem>
  );
}
function ContactPhone() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Contact Phone Number (Payable To)"
      subTitle=""
      hasExceptions
    >
      <FormPhoneField
        control={control}
        name="companyDetails.payableToPhoneData"
        label="Phone #"
        phoneNumberCustomStyle={{ flexGrow: 0 }}
        customeStyle={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

function TermAndConditions() {
  const { control, watch } = useFormContext();
  const documentId = watch('termsAndConditions')?.documentId;

  return (
    <SettingBlockItem title="Term and Condition" subTitle="" hasExceptions>
      <Box sx={{ marginLeft: '150px' }}>
        <FileUploadUpdated
          name={`termsAndConditions`}
          uploadBtnText="Add Terms and condition"
          fileId={documentId}
          control={control}
          accept={{ 'application/pdf': [] }}
        />
      </Box>
    </SettingBlockItem>
  );
}
export default function InvoiceSettlement() {
  return (
    <section id={INVOICE_NAME} className="section">
      <SettingBlock
        title="Invoice and Settlement"
        subTitle="These details are reflected on documents such as your customer invoices, driver settlements, and more."
      >
        <InvoiceTerms />
        <LoadIdPrefix />
        <ManifestIdPrefix />
        <InvoiceCustomPrefix />
        <NextLoadIdNumber />
        <SettlementStartDate />
        <SettlementPeriod />
        <InvoicingLogo />
        <FinancialItemsAutoRefresh />
        <ContactName />
        <ContactPhone />
        <ContacEmail />
        <ContactAddress />
        <TermAndConditions />
      </SettingBlock>
    </section>
  );
}
