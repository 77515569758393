export enum EVENT_STATUS {
  AVAILABLE = 'AVAILABLE',

  ASSIGNED = 'ASSIGNED',

  PLANNED = 'PLANNED',
  PLANNED_DISAPPROVE = 'PLANNED_DISAPPROVE',

  DISPATCHED = 'DISPATCHED',

  TRANSIT = 'TRANSIT',

  DELIVERED = 'DELIVERED',

  CANCELLED = 'CANCELLED',

  DETAINED = 'DETAINED',

  GAP = 'GAP',

  COMPLETED = 'COMPLETED',

  EMPTY = 'EMPTY',

  RELAY_START = 'RELAY_START',

  RELAY_END = 'RELAY_END',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_TRANSIT = 'IN_TRANSIT',
  EMPTY_HIDDEN = 'EMPTY_HIDDEN',
  NEED_LOAD = 'NEED_LOAD',
  DEADHEAD = 'DEADHEAD',
  RECOMMENDED = 'RECOMMENDED',
  ENROUTE_TO_PICKUP = 'ENROUTE_TO_PICKUP',
}

export enum EVENT_TYPES {
  GAP = 'GAP',
  PLANNED = 'PLANNED',
  PLANNED_DISAPPROVE = 'PLANNED_DISAPPROVE',
  AVAILABLE = 'AVAILABLE',
  EMPTY = 'EMPTY',
  EMPTYLOAD = 'EMPTYLOAD',
  RELAY_START = 'RELAYSTART',

  RELAY_END = 'RELAYEND',
  IN_TRANSIT = 'IN_TRANSIT',
  TRIP = ' TRIP',
  CHECKBOX = 'CHECKBOX',
  CHECKBOX_CHECKED = 'CHECKBOX_CHECKED',
  EMPTY_HIDDEN = 'EMPTY_HIDDEN',
}

export const UNASSIGNED_RESOURCE = 'UNASSIGNED';
export const DOWNVOTE_PUCK_ID = 'downVoteId';

export const PUCK_SIZES_IN_PX = {
  SIZE_1: 40,
  SIZE_2: 100,
  SIZE_3: 200,
  SIZE_4: 300,
  SIZE_5: 380,
  SIZE_6: 500,
  SIZE_7: 580,
};

export const SELECT_TRIP_RESPONSIVE_SIZES = {
  SIZE_1: 50,
  SIZE_2: 110,
  SIZE_3: 215,
};

export const PUCK_MIN_SIZE_TO_DOWNVOTE = 80;

export const DEFAULT_FONT_SIZE = 13;
export const PUCK_DEFAULT_GAP_SIZE = 20;
export const PUCK_DEFAULT_PADDING_SIZE = 20;
export const STATE_DEFAULT_SIZE = DEFAULT_FONT_SIZE * 2;
export const FONT_SIZE_FOR_PUCK_CONTENT_CALC = DEFAULT_FONT_SIZE - 6;

export const defaultOriginDestinationStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const unassignedDriverRowBackground = '#DFE8F8';
export const resourceInfoHeaderClass = 'resourceinfo-header';
export const PUCK_ASSIGNEMENT_CANCEL_ID = 'puck-assignment-cancel';
