import {
  BulkSettlementCreateRequest,
  ClosePayStatementRequest,
  CombinedNamesListRequest,
  CreateInReviewPayStatementRequest,
  CreateNonTripPaymentRequest,
  DeleteInReviewPayStatementRequest,
  DeleteNonTripPaymentByIdRequest,
  DownloadBulkPayStatement,
  GetBulkUpdateNonTripPaymentsRequest,
  GetPayStatementDetailsByIdRequest,
  GetPayStatementListRequest,
  GetPayStatementPdfRequest,
  GetPaymentsListByIdRequest,
  PaginatedCombinedNamesList,
  PaginatedPayStatementList,
  PayStatementData,
  PayStatementDatesType,
  PayStatementType,
  PayStatementsEntityTypeEnum,
  PayStatementsTypeEnum,
  PaymentDetails,
  PaymentList,
  QueryParams,
  RemoveEditInReviewPaymentsRequest,
  SendSettlementEmailRequest,
  SettlementIdFilterRequest,
} from '../../models/index';
import { PaginatedResult } from '../../types';
import { docType } from '../../utils/Doc';

import { Service } from './Service';
import { ServiceError } from '@/api/interfaces/helperTypes';

export abstract class IPayStatementService extends Service {
  abstract getPayStatementList(
    requestData: GetPayStatementListRequest,
    entityType?: PayStatementsEntityTypeEnum,
    fetchingType?: string
  ): Promise<PaginatedPayStatementList | undefined>;

  abstract getPayStatementDetailsById(
    requestData: GetPayStatementDetailsByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PayStatementData | undefined>;

  abstract bulkUpdateNonTripPayments(
    requestData: GetBulkUpdateNonTripPaymentsRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaginatedPayStatementList | undefined>;

  abstract updateNonTripPayment(
    requestData: CreateNonTripPaymentRequest | undefined,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaymentDetails | ServiceError>;

  abstract getCombinedNameList(
    requestData: CombinedNamesListRequest
  ): Promise<PaginatedCombinedNamesList | undefined>;

  abstract getSettlementIdList(
    requestData: SettlementIdFilterRequest
  ): Promise<PaginatedResult<SettlementIdFilterRequest> | undefined>;

  abstract getNonTripPaymentsListById(
    requestData: GetPaymentsListByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaymentList | undefined>;

  abstract getTripPaymentsListById(
    requestData: GetPaymentsListByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaymentList | undefined>;

  abstract getGrossYearToDateAmountById(
    requestData: GetPaymentsListByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<number | undefined>;

  abstract createNonTripPayment(
    requestData: CreateNonTripPaymentRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaymentDetails | undefined>;

  abstract createBulkSettlements(
    requestData: BulkSettlementCreateRequest
  ): Promise<PaymentDetails | undefined>;

  abstract deleteNonTripPaymentById(
    requestData: DeleteNonTripPaymentByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<number | undefined>;

  abstract createInReviewPayStatement(
    requestData: CreateInReviewPayStatementRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PayStatementData | undefined>;

  abstract bulkCloseReopenPayStatement(
    requestData: ClosePayStatementRequest[],
    payStatementType: PayStatementsTypeEnum,
    disableAlert?: boolean
  ): Promise<PayStatementType[] | undefined>;

  abstract removeEditInReviewPayments(
    requestData: RemoveEditInReviewPaymentsRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PayStatementData | undefined>;

  abstract getPayStatementDates(
    requestData: QueryParams,
    skipLoader?: boolean
  ): Promise<PayStatementDatesType | undefined>;

  abstract deleteInReviewPayStatement(
    requestData: DeleteInReviewPayStatementRequest
  ): Promise<PayStatementData | undefined>;

  abstract getPayStatementPdf(
    queryParams: GetPayStatementPdfRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<docType | undefined>;

  abstract sendEmail(
    requestData: SendSettlementEmailRequest
  ): Promise<string | undefined>;

  abstract downloadBulkPayStatements(
    requestData: DownloadBulkPayStatement
  ): Promise<docType[] | undefined>;
}
