import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { MultipleAutocompleteForm } from '@/common/Ui/MultipleAutocomplete';
import { TagColor, TagEntityType } from '@/models/DTOs/ManageTypes/Requests';
import { SingleAutocompleteForm } from '@/common/Ui/SingleAutocomplete';
import { Tag } from '@/common/Tag';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@/common/Ui/TextField/TextField';
import { FC } from 'react';
import { tagEntities } from '@/common/Tag/constants/tag.constants';

interface Props {
  colors: TagColor[];
  entityType?: TagEntityType;
}

export const TagForm: FC<Props> = ({ colors, entityType }) => {
  const { control } = useFormContext();
  return (
    <>
      <Grid spacing={1} container>
        <TextField
          sizes={{ xs: 6 }}
          name="name"
          required
          control={control}
          label="Tag Name"
        />
        <SingleAutocompleteForm
          name="color"
          label="Color"
          fieldName="label"
          fieldValue="hexCode"
          options={colors}
          control={control}
          sizes={{ xs: 6 }}
          customStyles={{ mt: '3px' }}
          customRenderOption={(props, option: TagColor) => {
            return (
              <MenuItem
                {...props}
                sx={{
                  display: 'flex !important', // Ensures flexbox behavior
                  justifyContent: 'space-between !important',
                  alignItems: 'center',
                  paddingRight: '12px !important',
                  paddingLeft: '12px !important',
                }}
              >
                <Tag
                  color={option.textColor}
                  title={option.label}
                  bgColor={option.hexCode}
                />
                <Typography color="text.secondary" sx={{ fontSize: 12 }}>
                  {option.usageCount} usages
                </Typography>{' '}
              </MenuItem>
            );
          }}
          required
        />
      </Grid>
      <MultipleAutocompleteForm
        options={Object.keys(tagEntities).map((key) => ({
          value: tagEntities[key as TagEntityType],
          key: key,
          disabled: entityType && entityType === key,
        }))}
        fieldName="value"
        name="entityTypes"
        control={control}
        label="Entities"
        variant="standard"
        required
      />
      <TextField name="description" control={control} label="Description" />
    </>
  );
};
