import { useState } from 'react';
import { NoteEntityType } from '@/common/EntityNotes/utils/types';
import { AXELE_PERMISSION_TYPE } from '@/common/Permission';

export const useNotesContent = (entityType: NoteEntityType) => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);

  const handleShowAddNewFormChange = (show: boolean) => () =>
    setShowAddNewForm(show);

  const addNotePermission = (() => {
    switch (entityType) {
      case 'DRIVER':
        return AXELE_PERMISSION_TYPE.USER_PROFILE_EDIT;
      default:
        return AXELE_PERMISSION_TYPE.EQUIPMENT_EDIT;
    }
  })();

  return {
    showAddNewForm,
    handleShowAddNewFormChange,
    addNotePermission,
  };
};
