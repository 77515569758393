import { useFormContext } from 'react-hook-form';
import {
  getAppointmentNumberSearchURL,
  getBookingNumberSearchURL,
} from '../../../api/impl/requestConstants';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { EquipmentImportExportOptions } from '../../../common/PendoClassIDs/constants';
import { statesinCountry } from '../../../common/Ui/AddressField/constants/states';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import Switch from '../../../common/Ui/Switcher';
import { LoadConstants } from '../../../locales/en/allLoads/loads';
import { LoadViewTranslations } from '../../../locales/en/allLoads/loads/modal';
import { TripsConstants } from '../../../locales/en/allLoads/trips';
import { CommonConstants } from '../../../locales/en/common/index';
import { ExpenceModalTranslations } from '../../../locales/en/finance/expenses/modal';
import { GetBookingAndAppointmentListRequest } from '../../../models/DTOs/Loads/Requests';
import {
  getLoadLocationNames,
  getLoadOriginDestinations,
  getLoadReferenceIds,
  getLoadSeqNumbers,
  getPaginatedTagsList,
} from '../../../subPages/invoices/utils';
import LoadController from '../../../subPages/loadsList/LoadController';
import { getCommodityTypes } from '../../../subPages/loadsList/LoadDetailsPanel/utils';
import { loadTypeNameEnum } from '../../../subPages/loadsList/constants';
import TractorController from '../../../subPages/tractors/Controller';
import TrailerController from '../../../subPages/trailers/TrailerController';
import { CreateFiltersMetaDataForm } from '../../../utils/filters';
import { getDriverNamesList } from '../../settings/users/utils';
import {
  getBrokeredYesNoOptions,
  tripFieldsConfig,
} from '../../trips/constants/constants';
import { getCarrierNamesList } from '../../trips/services/api.utils';
import { TagEntityType } from '@/models/DTOs/ManageTypes/Requests';
export const loadStatuses = [
  { key: ELoadStatus.AVAILABLE, value: 'Available' },
  { key: ELoadStatus.ASSIGNMENT_PLANNED, value: 'Planned' },
  { key: ELoadStatus.DISPATCHED, value: 'Dispatched' },
  { key: ELoadStatus.OFFERED_TO_DRIVER, value: 'Assigned' },
  { key: ELoadStatus.IN_TRANSIT, value: 'In-Transit' },
  { key: ELoadStatus.LOAD_COMPLETED, value: 'Completed' },
  { key: ELoadStatus.CANCELLED, value: 'Canceled' },
  { key: ELoadStatus.READY_TO_INVOICE, value: 'Ready to Invoice' },
  { key: ELoadStatus.INVOICED, value: 'Invoiced' },
  { key: ELoadStatus.PAID, value: 'Paid' },
];

export const settlementStatuses = [
  { key: 'PENDING', value: CommonConstants.pending },
  { key: 'IN_REVIEW', value: CommonConstants.inReview },
  { key: 'REVIEWED', value: CommonConstants.reviewed },
  { key: 'CLOSED', value: CommonConstants.closed },
];

const columns = [
  {
    name: 'seqNumber',
    label: ExpenceModalTranslations.loadId,
    show: true,
    disabled: true,
  },
  {
    name: 'referenceId',
    label: ExpenceModalTranslations.refNum,
    show: true,
    disabled: false,
  },
  {
    name: 'loadStatus',
    label: LoadViewTranslations.loadViewLoadStatus,
    show: true,
    disabled: false,
  },
  {
    name: 'loadType',
    label: LoadViewTranslations.loadViewLoadType,
    show: true,
    disabled: false,
  },
  {
    name: 'manifestBaseDetails',
    label: LoadConstants.loadTypeManifest,
    show: true,
    disabled: false,
  },
  {
    name: 'tags',
    label: 'Tags',
    show: true,
    disabled: false,
  },

  {
    name: 'loadSettlementStatus',
    label: CommonConstants.settlement,
    show: true,
    disabled: false,
  },
  {
    name: 'revenue',
    label: LoadViewTranslations.loadViewRevenue,
    show: true,
    disabled: false,
  },
  {
    name: 'trips',
    label: TripsConstants.tripId,
    show: true,
    disabled: false,
  },
  {
    name: tripFieldsConfig.loadPriority.fieldName,
    label: tripFieldsConfig.loadPriority.label,
    show: true,
    disabled: false,
  },
  {
    name: tripFieldsConfig.brokerageTrip.fieldName,
    label: tripFieldsConfig.brokerageTrip.label,
    show: true,
    disabled: false,
  },
  {
    name: tripFieldsConfig.canBeBrokered.fieldName,
    label: tripFieldsConfig.canBeBrokered.label,
    show: true,
    disabled: false,
  },
  {
    name: 'driverGroups',
    label: LoadViewTranslations.loadViewDriver,
    show: true,
    disabled: false,
  },
  {
    name: tripFieldsConfig.carrierDetails.fieldName,
    label: tripFieldsConfig.carrierDetails.label,
    show: true,
    disabled: false,
  },
  {
    name: 'dispatchers',
    label: LoadViewTranslations.loadViewDispatcher,
    show: true,
    disabled: false,
  },
  {
    name: 'terminal',
    label: LoadViewTranslations.loadViewInvoicingTerminal,
    show: true,
    disabled: false,
  },
  {
    name: 'dispatchTerminals',
    label: LoadViewTranslations.loadViewDispatchTerminal,
    show: true,
    disabled: false,
  },
  {
    name: 'customerName',
    label: CommonConstants.customer,
    show: true,
    disabled: false,
  },
  {
    name: 'tractors',
    label: LoadViewTranslations.loadViewTractor + ' #',
    show: true,
    disabled: false,
  },
  {
    name: 'trailers',
    label: LoadViewTranslations.loadViewTrailer + ' #',
    show: true,
    disabled: false,
  },
  {
    name: 'equipmentTypes',
    label: LoadViewTranslations.loadViewRequiredEquipmentType,
    show: true,
    disabled: false,
  },
  {
    name: 'sealNumber',
    label: LoadViewTranslations.loadViewSeal + ' #',
    show: true,
    disabled: false,
  },
  {
    name: 'chassisNumber',
    label: LoadViewTranslations.loadViewChassis + ' #',
    show: true,
    disabled: false,
  },
  {
    name: 'containerNumber',
    label: LoadViewTranslations.loadViewContainer + ' #',
    show: true,
    disabled: false,
  },
  {
    name: 'loadDirection',
    label: LoadViewTranslations.loadDirection,
    show: true,
    disabled: false,
  },
  {
    name: 'bookingNumber',
    label: LoadViewTranslations.bookingNumber + ' #',
    show: true,
    disabled: false,
  },
  {
    name: 'appointmentNumber',
    label: LoadViewTranslations.appointmentNumber + ' #',
    show: true,
    disabled: false,
  },

  {
    name: 'commodityTypes',
    label: LoadViewTranslations.loadViewCommodityType,
    show: true,
    disabled: false,
  },
  {
    name: 'totalWeight',
    label: LoadViewTranslations.loadViewWeight,
    show: true,
    disabled: false,
  },
  {
    name: 'lastFreeDay',
    label: LoadViewTranslations.lastFreeDay,
    show: true,
    disabled: false,
  },
  {
    name: 'originLocationName',
    label: LoadViewTranslations.originLocationName,
    show: true,
    disabled: false,
  },
  {
    name: 'destinationLocationName',
    label: LoadViewTranslations.destinationLocationName,
    show: true,
    disabled: false,
  },
  {
    name: 'fromLocationName',
    label: LoadViewTranslations.loadViewOrigin,
    show: true,
    disabled: false,
  },
  {
    name: 'toLocationName',
    label: LoadViewTranslations.loadViewDestination,
    show: true,
    disabled: false,
  },
  {
    name: 'pickup',
    label: LoadViewTranslations.loadViewPickUp,
    show: true,
    disabled: false,
  },
  {
    name: 'dropOff',
    label: LoadViewTranslations.loadViewDropOff,
    show: true,
    disabled: false,
  },
  {
    name: 'actualStartDateTime',
    label: LoadViewTranslations.actualStartDateTime,
    show: true,
    disabled: false,
  },
  {
    name: 'actualEndDateTime',
    label: LoadViewTranslations.actualEndDateTime,
    show: true,
    disabled: false,
  },
  {
    name: 'numberOfStops',
    label: LoadViewTranslations.loadViewStops,
    show: true,
    disabled: false,
  },
  {
    name: 'missingDocuments',
    label: LoadViewTranslations.loadViewMissingDocs,
    show: true,
    disabled: false,
  },
  {
    name: 'dispatchNotes',
    label: LoadViewTranslations.loadViewDispatchNotes,
    show: true,
    disabled: false,
  },
  {
    name: 'createDate',
    label: TripsConstants.createdDateTime,
    show: true,
    disabled: false,
  },

  // {
  //   name: 'onHoldState',
  //   label: TripsConstants.onHoldState,
  //   show: true,
  //   disabled: false,
  // },
];

export const getLoadFiltersFormConfig = () => {
  const filterConfigs = [
    // this is for upcoming new grid
    {
      key: 1,
      name: 'loadIdsFilter',
      fieldName: 'seqNumber',
      getData: getLoadSeqNumbers,
      label: LoadViewTranslations.loadViewLoadId,
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 1,
      name: 'manifestIdsFilter',
      fieldName: 'seqNumber',
      getData: (searchText: string, pageNumber: number) =>
        getLoadSeqNumbers(searchText, pageNumber, [], [], 'MANIFEST'),
      label: LoadViewTranslations.loadViewManifestID,
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 26,
      name: 'loadTypes',
      options: [
        { value: loadTypeNameEnum.FTL, key: 'FTL' },
        { value: loadTypeNameEnum.LTL, key: 'LTL' },
        { value: loadTypeNameEnum.MANIFEST, key: 'MANIFEST' },
      ],
      label: 'Load Type',
      type: 'ButtonGroup',
      multiple: true, //   type: 'MultipleAutocomplete',
      fieldName: 'value',
      fieldValue: 'key',
    },
    {
      key: 2,
      label: tripFieldsConfig.brokerageTrip.label,
      name: tripFieldsConfig.brokerageTrip.fieldFilterName,
      options: getBrokeredYesNoOptions(),
      type: 'ButtonGroup',
      multiple: true, //   type: 'MultipleAutocomplete',
      fieldName: 'label',
      fieldValue: 'value',
    },

    {
      key: 2,
      name: 'driverIdsFilter',
      fieldName: 'name',
      getData: getDriverNamesList,
      label: LoadViewTranslations.loadViewDriver,
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 3,
      label: tripFieldsConfig.carrierDetails.label,
      name: tripFieldsConfig.carrierDetails.fieldFilterName,
      type: 'MultipleAutocomplete',
      fieldName: 'name',
      getData: getCarrierNamesList,
    },
    {
      key: 3,
      name: 'customerIdsFilter',
      fieldName: 'value',
      getData: LoadController.instance().getCustomerForFilter,
      label: LoadViewTranslations.loadViewCustomer,
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 4,
      name: 'loadStatusList',
      fieldName: 'value',
      options: loadStatuses,
      label: LoadViewTranslations.loadViewLoadStatus,
      type: 'MultipleAutocomplete',
    },
    {
      key: 5,
      name: 'tripIdsFilter',
      fieldName: 'label',
      getData: LoadController.instance().getTripIds,
      label: LoadViewTranslations.loadViewTripId,
      type: 'MultipleAutocomplete',
    },
    {
      key: 6,
      name: 'loadSettlementStatusList',
      fieldName: 'value',
      fieldValue: 'key',
      options: settlementStatuses,
      label: 'Settlement Status',
      type: 'ButtonGroup',
      multiple: true, //   type: 'MultipleAutocomplete',
    },
    {
      key: 7,
      name: 'dispatcherIdsFilter',
      fieldName: 'name',
      getData: LoadController.instance().getDispatcherSummary,
      label: LoadViewTranslations.loadViewDispatcher,
      type: 'MultipleAutocomplete',
    },
    {
      key: 8,
      name: 'referenceIds',
      fieldName: 'referenceId',
      getData: getLoadReferenceIds,
      label: LoadViewTranslations.loadViewReference + ' #',
      type: 'MultipleAutocomplete',
      default: true,
    },
    {
      key: 9,
      name: 'chassisNumbers',
      fieldName: 'value',
      getData: (searchText: string | undefined, pageNumber: number) =>
        LoadController.instance().getChassisSearch(searchText, pageNumber),
      label: LoadViewTranslations.loadViewChassis + ' #',
      type: 'MultipleAutocomplete',
    },
    {
      key: 10,
      name: 'containerNumbers',
      fieldName: 'value',
      getData: (searchText: string | undefined, pageNumber: number) =>
        LoadController.instance().getContainerSearch(searchText, pageNumber),
      label: LoadViewTranslations.loadViewContainer + ' #',
      type: 'MultipleAutocomplete',
    },
    {
      key: 28,
      name: 'loadDirections',
      fieldName: 'label',
      options: EquipmentImportExportOptions,
      label: LoadViewTranslations.loadDirection,
      fieldValue: 'key',
      type: 'ButtonGroup',
      multiple: true,
    },
    {
      key: 27,
      name: 'bookingNumbers',
      fieldName: 'value',
      getData: (searchText: string | undefined, pageNumber: number) => {
        const requestData = new GetBookingAndAppointmentListRequest(
          searchText,
          pageNumber
        );
        return LoadController.instance().getAppointmentAndBookingSearch(
          getBookingNumberSearchURL,
          requestData
        );
      },
      label: LoadViewTranslations.bookingNumber + ' #',
      type: 'MultipleAutocomplete',
    },
    {
      key: 29,
      name: 'appointmentNumbers',
      fieldName: 'value',
      getData: (searchText: string | undefined, pageNumber: number) => {
        const requestData = new GetBookingAndAppointmentListRequest(
          searchText,
          pageNumber
        );
        return LoadController.instance().getAppointmentAndBookingSearch(
          getAppointmentNumberSearchURL,
          requestData
        );
      },
      label: LoadViewTranslations.appointmentNumber + ' #',
      type: 'MultipleAutocomplete',
    },
    {
      key: 11,
      name: 'originCityStateList',
      fieldName: 'name',
      getData: getLoadOriginDestinations,
      label: LoadViewTranslations.loadViewOrigin,
      type: 'MultipleAutocomplete',
    },
    {
      key: 12,
      name: 'destinationCityStateList',
      fieldName: 'name',
      getData: getLoadOriginDestinations,
      label: LoadViewTranslations.loadViewDestination,
      type: 'MultipleAutocomplete',
    },
    {
      key: 13,
      name: 'originStateList',
      fieldName: 'name',
      fieldValue: 'value',
      options: statesinCountry,
      label: LoadViewTranslations.loadViewOriginState,
      type: 'MultipleAutocomplete',
      immutableModel: true,
    },
    {
      key: 14,
      name: 'destinationStateList',
      fieldName: 'name',
      fieldValue: 'value',
      options: statesinCountry,
      label: LoadViewTranslations.loadViewDestinationDState,
      type: 'MultipleAutocomplete',
      immutableModel: true,
    },
    {
      key: 15,
      name: 'equipmentTypes',
      fieldName: 'value',
      label: LoadViewTranslations.loadViewEquipmentType,
      getData: LoadController.instance().getLoadStatic,
      type: 'MultipleAutocomplete',
    },
    {
      key: 16,
      name: 'commodityTypes',
      fieldName: 'itemName',
      getData: async () => ({ content: await getCommodityTypes(), last: true }),
      label: LoadViewTranslations.loadViewCommodityType,
      type: 'MultipleAutocomplete',
    },
    {
      key: 17,
      name: 'dispatchNotes',
      label: LoadViewTranslations.loadViewDispatchNotes,
      type: 'TextField',
    },
    {
      key: 18,
      name: 'tractorIdsFilter',
      fieldName: 'name',
      getData: (searchText: string | undefined, pageNumber: number) =>
        TractorController.instance().getTractorLightListByNameSearch(
          searchText,
          pageNumber,
          [],
          ['INACTIVE']
        ),
      label: LoadViewTranslations.loadViewTractor,
      type: 'MultipleAutocomplete',
    },
    {
      key: 19,
      name: 'trailerIdsFilter',
      fieldName: 'name',
      getData: (searchText: string | undefined, pageNumber: number) =>
        TrailerController.instance().getTrailerLightListByNameSearch(
          searchText,
          pageNumber,
          [],
          ['INACTIVE']
        ),
      label: LoadViewTranslations.loadViewTrailer,
      type: 'MultipleAutocomplete',
    },
    {
      key: 20,
      name: 'loadIsInvoiceable', // check BE part
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: true, value: 'Yes' },
        { key: undefined, value: 'No' },
      ],
      label: 'Invoiceable',
      type: 'GridSwitchFilter',
    },
    {
      key: 21,
      name: 'isRelay',
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: true, value: 'Yes' },
        { key: false, value: 'No' },
      ],
      label: 'Has Relay',
      type: 'GridSwitchFilter',
    },
    {
      key: 22,
      name: 'hasViolation',
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: true, value: 'Yes' },
        { key: undefined, value: 'No' },
      ],
      label: 'Delay Violations',
      type: 'GridSwitchFilter',
    },
    {
      key: 23,
      name: 'calculateDetention',
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: true, value: 'Yes' },
        { key: undefined, value: 'No' },
      ],
      label: 'Detention',
      type: 'GridSwitchFilter',
    },
    {
      key: 24,
      name: 'needToAssign',
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: true, value: 'Yes' },
        { key: undefined, value: 'No' },
      ],
      label: 'Need Drivers Now',
      type: 'GridSwitchFilter',
    },
    {
      key: 2,
      name: tripFieldsConfig.onHoldState.fieldName,
      label: tripFieldsConfig.onHoldState.label,
      options: [
        { value: true, label: 'Yes' },
        { value: null, label: 'No' },
      ],
      type: 'GridSwitchFilter',
      multiple: false,
      fieldName: 'label',
      fieldValue: 'value',
    },
    {
      key: 25,
      name: 'operationMode', // check BE part
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: 'SOLO', value: 'Solo' },
        { key: 'TEAM', value: 'Team' },
      ],
      label: LoadViewTranslations.loadViewDriverOperationMode,
      type: 'SingleAutocomplete',
    },
    {
      key: 23,
      name: 'pickupDateRanges',
      fieldName: 'name',
      label: 'Pickup Appointment Date',
      default: false,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 25,
      name: 'appointmentDateRanges',
      fieldName: 'name',
      label: 'Drop Off Appointment Date',
      default: false,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 34,
      name: 'actualPickupDateRanges',
      fieldName: 'name',
      label: 'Actual Pickup Date',
      default: false,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 35,
      name: 'actualAppointmentDateRanges',
      fieldName: 'name',
      label: 'Actual Drop Off Date',
      default: false,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 33,
      name: 'lastFreeDayDateRanges',
      fieldName: 'name',
      label: 'Last Free Day Date',
      default: false,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 25,
      name: 'tagIds',
      fieldName: 'name',
      getData: (name: string, pageNumber: number) =>
        getPaginatedTagsList(name, pageNumber, [TagEntityType.LOAD]),
      label: LoadViewTranslations.loadTags,
      type: 'MultipleAutocomplete',
      isCustomTag: true,
    },
    {
      key: 32,
      name: 'locationNameList',
      fieldName: 'name',
      getData: getLoadLocationNames,
      label: 'Location Name',
      type: 'MultipleAutocomplete',
    },
  ];
  return filterConfigs.map((item, index) => {
    return {
      ...item,
      key: index + 1,
    };
  });
};

export function LoadColumnsForm() {
  const { control } = useFormContext();
  return (
    <>
      {columns.map((column, i) => (
        <Switch
          key={i}
          label={column.label}
          name={`columnFilters[${column.name}]`}
          control={control}
          disabled={column.disabled}
        />
      ))}
    </>
  );
}

export function LoadMetaDataForm() {
  const { control } = useFormContext();
  return CreateFiltersMetaDataForm(getLoadFiltersFormConfig(), control);
}
