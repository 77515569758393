import { contractServive, invoiceService } from '../../../../api';
import { httpClient } from '../../../../axios/axiosInstance';
import {
  GetBestMatchContractParams,
  // ContractListData,
  // ContractDetailsDTO,
  GetContractOneRequest,
  GetInvoiceLineItemsRequest,
  QueryParams,
} from '../../../../models';
import { LineItem } from '../../../../types';
import { urls } from '../apis';
import {
  FINANCE_TYPES,
  PER_LOADED_WEIGHT_KEY,
} from '../constants/fieldOptions';

class RateFormService {
  async getAccessorialFeeOptions(): Promise<{
    base: LineItem[];
    fees: LineItem[];
  }> {
    const request = new GetInvoiceLineItemsRequest();
    request.isInvoiceItem = true;
    const result = (await invoiceService.getInvoiceLineItems(
      request
    )) as unknown as LineItem[];
    const lineItemOptions = result.filter?.((l) => l.itemType === 'BASE');
    const accessorialOptions = result.filter?.(
      (item) => item.itemType !== 'BASE'
    );
    return { base: lineItemOptions, fees: accessorialOptions };
  }

  async getMatchContract(payLoad: GetBestMatchContractParams) {
    try {
      const res = await contractServive.getBestMatchContract(payLoad);
      return res;
    } catch (error) {
      throw error;
    }
  }
  async getContractDetails(id: string) {
    try {
      const response = await contractServive.getContractById(
        new GetContractOneRequest({
          id: id,
        })
      );
      return response;
    } catch (error) {
      console.log('error: ', error);
      throw error;
    }
  }

  getContactDropdownOptions({
    customers,
    searchText,
  }: {
    customers: Array<any>;
    searchText?: string;
  }): Array<any> {
    if (searchText?.length) {
      const isCustomerNameExists = customers?.find?.(
        (e: any) => e?.name?.toLowerCase?.() === searchText?.toLowerCase?.()
      );
      if (isCustomerNameExists) return customers;
      return [
        {
          type: 'NEW_CUSTOMER',
          name: `Add “${searchText}”`,
          value: searchText,
        },
        ...(customers ?? []),
      ];
    }
    return customers ?? [];
  }

  getQuantityFieldLabel(baseType: string): string {
    if (baseType === PER_LOADED_WEIGHT_KEY) return 'Weight(lbs)';
    return 'Miles(mi)';
  }

  getRateFieldLabel(baseType: string): string {
    if (baseType === PER_LOADED_WEIGHT_KEY) return '$/weight';
    return '$/mile';
  }

  calculateLineItemRateByUnit({
    quantity,
    rate,
    unit,
  }: {
    quantity: number;
    rate: number;
    unit: string;
  }): number {
    let total = Number(quantity) * Number(rate);
    if (
      unit === FINANCE_TYPES.PERCENT ||
      unit === FINANCE_TYPES.PERCENT_MATRIX
    ) {
      total = (rate / 100) * quantity;
    }
    return total;
  }

  getBaseQuantityByType = ({
    type,
    totalMiles,
    totalWeight,
    quantity,
  }: {
    type?: string;
    totalMiles?: string;
    totalWeight?: string;
    quantity?: string;
  }): string => {
    if (type === FINANCE_TYPES.PER_LOADED_MILE) return totalMiles!;
    else if (type === FINANCE_TYPES.PER_WEIGHT) return totalWeight! ?? 0;
    return quantity!;
  };

  calculateQuantityByItemUnit({
    unit,
    baseQuantity,
    baseRate,
    totalMiles,
    totalWeight,
  }: {
    unit?: string;
    baseQuantity?: string;
    baseRate?: string;
    totalMiles?: string;
    totalWeight?: string;
  }): number {
    let qty: string | number = 1;
    if (unit === FINANCE_TYPES.PERCENT || unit === FINANCE_TYPES.PERCENT_MATRIX)
      qty = Number(Number(baseRate) * Number(baseQuantity || 0)).toFixed(2);
    else if (unit === FINANCE_TYPES.PER_LOADED_MILE) {
      qty = Number(totalMiles)?.toFixed?.(2);
    } else if (unit === FINANCE_TYPES.PER_WEIGHT) {
      qty = totalWeight ? Number(totalWeight).toFixed(2) : 1;
    } else if (unit === FINANCE_TYPES.PRICE_PER_MILE_MATRIX) {
      qty = Number(totalMiles)?.toFixed?.(2);
    }
    return Number(qty);
  }

  async fetchFuelSurchargeCurrentRate(feeDetails: GetFuelSurchargeRate) {
    try {
      return await httpClient.get(
        urls.getFuelSurchargeRate,
        feeDetails,
        undefined
      );
    } catch (error) {
      console.log('error: ', error);
      throw error;
    }
  }

  isUnitIsMatrix(unit: string) {
    return ['PERCENT_MATRIX', 'PRICE_PER_MILE_MATRIX'].includes(unit);
  }
}
const rateFormService = new RateFormService();

export class GetFuelSurchargeRate extends QueryParams {
  region: string;
  matrixId: number | string;
  unit: string;
  constructor(data: any) {
    super();
    this.region = data.region;
    this.matrixId = data.matrixId;
    this.unit = data.unit;
  }
}

export { rateFormService };
