import { Type } from 'class-transformer';
import StorageManager from '../../../StorageManager/StorageManager';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { Stop as StopModel } from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { ICoordinates } from '../../../views/loadboards/loadTypes';
import {
  EOperationMode,
  ITripQueryParamsTable,
} from '../../../views/trips/constants/types';
import { LoadStop, UpSertLoadStopRequest } from '../Loads/Requests';
import { QueryParams } from '../commonMixed';
import { TripLocation } from './Trip';
import { ETripSettlementStatus } from './Types';

export class PaginatedTripListRequest
  extends QueryParams
  implements ITripQueryParamsTable
{
  pageNumber!: number;
  pageSize!: number;

  constructor(params: ITripQueryParamsTable) {
    super();
    this.pageNumber = params.pageNumber;
    this.pageSize = params.pageSize;

    Object.assign(this, params);
  }
  tripStatusList?: ELoadStatus[] | undefined;
  driverIdsFilter?: string[] | undefined;
  dispatcherIdsFilter?: string[] | undefined;
  settlementStatus?: ETripSettlementStatus[] | undefined;
  loadIdsFilter?: string[] | undefined;
  manifestIdsFilter?: string[] | undefined;
  tripIdsFilter?: string[] | undefined;
  tractorIdsFilter?: string[] | undefined;
  trailerIdsFilter?: string[] | undefined;
  originCityStateList?: string[] | undefined;
  destinationCityStateList?: string[] | undefined;
  originStateList?: string[] | undefined;
  destinationStateList?: string[] | undefined;
  isRelay?: boolean | undefined;
  chassisNumbers?: string[] | undefined;
  containerNumbers?: string[] | undefined;
  customerIdsFilter?: string[] | undefined;
  referenceIds?: string[] | undefined;
  startFrom?: string | undefined;
  startTo?: string | undefined;
  endFrom?: string | undefined;
  endTo?: string | undefined;
  actualStartFromDate?: string | undefined;
  actualStartToDate?: string | undefined;
  actualEndFromDate?: string | undefined;
  actualEndToDate?: string | undefined;
  loadType?: string | undefined;
  sort?: string | undefined;
  hasViolation?: boolean | undefined;
  calculateDetention?: boolean | undefined;
  needToAssign?: boolean | undefined;
  operationMode?: EOperationMode | undefined;
  terminalIds?: string[] | undefined;
  carrierIdsFilter?: string[] | undefined;
  isBrokerageTrip: boolean | null;
  isEmptyTrip?: boolean | null;
  onHoldState: boolean | null;
  tagIds?: string[] | undefined;
  locationNameList?: string[];
}

export class PaginatedTripLightListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  seqNumber?: string;

  constructor(params: {
    pageNumber: number;
    pageSize: number;
    seqNumber?: string;
  }) {
    super();
    this.pageNumber = params.pageNumber;
    this.pageSize = params.pageSize;
    this.seqNumber = params.seqNumber;
  }
}

export class CreateTripStopRequest {
  tripId: string;
  previousTripStopId: number | null;
  @Type(() => LoadStop)
  stopDTO: LoadStop;
  organizationId: number;

  constructor(data: {
    tripId: string;
    stop: StopModel;
    previousTripStopId: number | null;
  }) {
    this.tripId = data.tripId;
    this.previousTripStopId = data.previousTripStopId;
    this.stopDTO = new LoadStop(data.stop);
    this.stopDTO.id = undefined; // @TODO: Nvard, please have a look on this class, in the case of create it should not have id.
    const userStorage = StorageManager.getUser() || {};
    this.organizationId = userStorage.organizationId;
  }
}
export class UpdateTripStopRequest extends UpSertLoadStopRequest {}
export class DeleteTripStopRequest extends QueryParams {
  tripId: string;
  stopId: number;

  constructor(data: { tripId: string; stopId: number }) {
    super();
    this.tripId = data.tripId;
    this.stopId = data.stopId;
  }
}

export class ResequenceTripStopRequest extends QueryParams {
  tripId: string | null;
  tripStopId: number | null;
  moveUp: boolean;
  applyMoveDeletion: boolean;

  constructor(data: {
    tripId: string;
    tripStopId: number | null;
    moveUp: boolean;
    applyMoveDeletion: boolean;
  }) {
    super();
    this.tripId = data.tripId;
    this.tripStopId = data.tripStopId;
    this.moveUp = data.moveUp;
    this.applyMoveDeletion = data.applyMoveDeletion;
  }
}

export class GetTripStopRequest extends QueryParams {
  tripId!: string;
  fetchHosData?: boolean;
  constructor(params: { tripId: string; fetchHosData?: boolean }) {
    super();
    this.tripId = params.tripId;
    this.fetchHosData = params.fetchHosData;
  }
}
export class ExportTripFilterQueryParams extends QueryParams {
  gridColumnMetadataList!: string[] | null;
  constructor({ gridColumnMetadataList }: any) {
    super(false); //Do not pass query param: organizationId
    this.gridColumnMetadataList = gridColumnMetadataList;
  }
}

export class ExportTripFilterPayload extends QueryParams {
  destinationCityStateList!: string[];
  dispatcherIds!: string[];
  driverIds!: string[];
  endFrom: string;
  endTo: string;
  actualEndFromDate: string;
  actualEndToDate: string;
  loadIds: string[];
  organizationId: number;
  originCityStateList: string[];
  pageNumber: number;
  pageSize: number;
  settlementStatus: string[];
  sort: string;
  startFrom: string;
  startTo: string;
  actualStartFromDate: string;
  actualStartToDate: string;
  tripIds: string[];
  tripStatusList: string[];
  tractorIds: number[];
  trailerIds: number[];
  terminalIds: string[];
  tagIds: string[];
  hasViolation?: boolean;
  calculateDetention?: boolean;
  needToAssign?: boolean;
  operationMode?: EOperationMode;

  constructor(params: any) {
    super();
    Object.assign(this, params);
  }
}

interface IGetRelayStop {
  relayStopId: number;
  tripId: string;
}

export class GetRelayStop extends QueryParams implements IGetRelayStop {
  relayStopId!: number;
  tripId!: string;
  constructor(params: IGetRelayStop) {
    super();
    Object.assign(this, params);
  }
}

class UpdateStopDetail {
  appointmentEndDate!: string | null;
  appointmentStartDate!: string | null;
  estimatedActivityDuration?: string;
  fixedAppointment!: boolean;
  isDriverAssistRequired!: boolean;
  appointmentConfirmation?: 'YES' | 'NO' | 'CONFIRMED';
}

export class UpdateRelayStopPayload extends QueryParams {
  tripId!: string;
  stopDTO!: {
    activityType: string; // "PICKUP",
    connectedRelayStopDetails: UpdateStopDetail;
    contact: {
      countryCode?: string;
      email?: string;
      extension?: string;
      firstname?: string;
      lastname?: string;
      phone?: string;
    };
    id: number;
    location: {
      address: string;
      avgHandlingTime: number;
      axeleId: string;
      center: {
        lat: number;
        lng: number;
      };
      city: string;
      contacts: {
        contactTitle: string;
        countryCode: string;
        email: string;
        extension: string;
        firstname: string;
        isAfterHours: boolean;
        isPrimary: boolean;
        lastname: string;
        middlename: string;
        phone: string;
        prefix: string;
        suffix: string;
      }[];
      geoCoordinates: ICoordinates;
      country: string;
      fullAddress: string;
      id: number | null;
      lat: number;
      lng: number;
      locationName: string;
      notes: string;
      organizationId: number;
      recordDate: string;
      state: string;
      streetAddress: string;
      streetAddress2: string;
      timezone: string;
      zipcode: string;
    };
    noteDetails?: {
      updateTime: string;
      userId: number;
    };
    notes: string;
    originalRelayStopDetails: UpdateStopDetail;
    refNumber?: string | null | number;
    sequenceNumber?: number;
    stopCategory?: string;
    stopStatus?: string;
    timezone?: string;
  };
  organizationId!: number;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
    this.stopDTO.location.organizationId = this.organizationId;
  }
}

export class UpdateRelayStopResponse {
  message!: string;
}

export class GetTripDetailsActionsRequest extends QueryParams {
  tripId?: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class GetTripDetailsActionResponse {
  data!: ELoadStatus[];
}

export class UpdateTripStatusRequest extends QueryParams {
  tripId!: string;
  status!: ELoadStatus;

  constructor(dto: { tripId: string; status: ELoadStatus }) {
    super();
    Object.assign(this, dto);
  }
}

export class UpdateTripStatusPayload {
  completionDate?: string;
  invoiceNotes?: string;
  invoicedDate?: string;
  paidDate?: string;

  constructor(dto?: {
    completionDate?: string;
    invoiceNotes?: string;
    invoicedDate?: string;
    paidDate?: string;
  }) {
    Object.assign(this, dto);
  }
}

export class UpdateTripStatusResponse {
  message!: string;
}

export class DeleteTripRequest extends QueryParams {
  tripId!: string;
  constructor(dto: { tripId: string }) {
    super();
    Object.assign(this, dto);
  }
}

export class TripWarningByIdsRequest extends QueryParams {
  ids: string[];
  constructor(ids: string[]) {
    super();
    this.ids = ids;
  }
}

export class CheckOrAssignAssetRequest extends QueryParams {
  assetDropLocation?: TripLocation;
  assetType!: 'TRACTOR' | 'TRAILER';
  tripId!: string;
  userChoice?: 'DRIVER' | 'LOAD';

  constructor(dto: {
    assetDropLocation?: TripLocation;
    assetType: 'TRACTOR' | 'TRAILER';
    tripId: string;
    userChoice?: 'DRIVER' | 'LOAD' | null;
  }) {
    super();
    Object.assign(this, dto);
  }
}

export class UpdateDispatchRevenueQueryParams extends QueryParams {
  dispatchRevenue!: number;
  tripId!: string;

  constructor(dto: { dispatchRevenue: number; tripId: string }) {
    super();
    Object.assign(this, dto);
  }
}

export class UpdateDispatchRevenueResponse {}

export class GetTripDetailByIdQueryParams extends QueryParams {
  tripId!: string;
  constructor(dto: { tripId: string }) {
    super();
    Object.assign(this, dto);
  }
}
