import { GetContactsRequest, LoadAlertContact } from '@/models';
import { visibilityService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import { useEffect, useState } from 'react';

export const useAddEditLoadAlertForm = (loadId: string) => {
  const [contacts, setContacts] = useState<LoadAlertContact[]>([]);
  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = async () => {
    const request = new GetContactsRequest();
    request.loadId = loadId;
    const result = await visibilityService.getContacts(request);
    if (!(result instanceof ServiceError)) {
      setContacts(result);
    }
    return [];
  };
  return {
    contacts,
  };
};
