import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { getOrganizationId } from '../../common/TimeoffDialog/utils';
import {
  CreateLoadAlertRequest,
  DeleteLoadAlertRequest,
  EditLoadAlertRequest,
  GetContactsRequest,
  GetLoadAlertFlagTypesRequest,
  GetLoadAlertsRequest,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import * as EntityDocumentsSection from '../../views/reports/documents/sections/EntityDocumentsSection';
import { annotateNameAsync } from '../interfaces';
import { IVisibilityService } from '../interfaces/IVisibilityService';

const sendDocumentTurvoDataURL = '/web/lbhub/api/v2/turvo/send-documents';

export class VisibilityService extends IVisibilityService {
  @annotateNameAsync
  async getLoadAlertFlagTypes(requestData: GetLoadAlertFlagTypesRequest) {
    try {
      const response = await httpClient.getRaw(
        'web/load/api/v2/load/alert/get/alert-data',
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deleteLoadAlert(requestData: DeleteLoadAlertRequest) {
    try {
      const response = await httpClient.deleteRaw(
        '/web/alert/api/v2/customer/alert/delete',
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Failed to Delete, Please try again',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getLoadAlerts(requestData: GetLoadAlertsRequest) {
    try {
      const response = await httpClient.getRaw(
        '/web/alert/api/v2/customer/alert/list',
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async createLoadAlert(requestData: CreateLoadAlertRequest) {
    try {
      const response = await httpClient.postRaw(
        'web/alert/api/v2/customer/alert/create',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      if ((error as any).response.status !== 417) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: 'Failed to Create, Please try again',
        });
      }
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getContacts(requestData: GetContactsRequest) {
    try {
      const response = await httpClient.getRaw(
        'web/load/api/v2/details/contacts',
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async editLoadAlert(requestData: EditLoadAlertRequest) {
    try {
      const response = await httpClient.putRaw(
        'web/alert/api/v2/customer/alert/update',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      if ((error as any).response.status !== 417) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: 'Failed to Edit, Please try again',
        });
      }
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async sendDocumentTurvoData(
    requestData: EntityDocumentsSection.ISendDocumentTurvoType
  ) {
    try {
      const response = await httpClient.post<any>(
        sendDocumentTurvoDataURL + `?organizationId=${getOrganizationId()}`,
        undefined,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Documents sent successfully to Turvo.',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Failed to Send Document, Please try again',
      });
      throw error;
    }
  }
}
