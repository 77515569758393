import { t } from 'i18next';
import OptymLogo from '../../_assets/static/svgs/OptymLogo';
import { LOADAI_ELEMENTS } from '../../constants/elementIds';
import { DRIVER_TOOLTIP_ACTION_KEYS } from '../../constants/gantt/columnTooltip.config';
import { ESecondaryDetailsPanelType } from '../../constants/gantt/gantt.const';
import { ScheduleDriverType } from '../../types/DispatchTypes';
import { getDriverWarningsData } from './ganttWarnings.service';
import {
  appendTextToDigit,
  formatedTextFromTwoValues,
  getHourFromMinutes,
  getPercentage,
} from './utility.service';

export const getColumnToolTipProps = (
  data: ScheduleDriverType & { hasBulkOptimizationPermission?: boolean }
) => {
  return {
    title: data?.driverGroupDetails?.groupName,
    closeTooltipOnAction: true,
    subTitles: generateDriverTooltipSubtitles(data),
    tags: data.tags,
    kpis: {
      content: generateDriverKpis(data),
    },
    actions: getDriverTooltiActions({
      hasBulkOptimizationPermission: data?.hasBulkOptimizationPermission,
      driverGroupId: data?.driverGroupDetails?.id,
    }),
    hosDetails: {
      primaryDriverName: data?.driverGroupDetails?.primaryDriverName,
      secondaryDriverName: data?.driverGroupDetails?.secondaryDriverName,
      isOutDated: data?.ignoreHOSRestrictions ? false : data?.isHOSOutdated,
      progress: data?.ignoreHOSRestrictions
        ? null
        : generateDriverHosDetails(data),
      progressSecondary:
        data?.ignoreHOSRestrictions ||
        data?.driverGroupDetails?.groupMode === 'SOLO'
          ? null
          : generateSecondaryDriverHosDetails(data),
      caption: data?.ignoreHOSRestrictions
        ? undefined
        : (data?.lastUpdatedHOSStatus as string),
      captionSecondary: data?.ignoreHOSRestrictionsSecondary
        ? undefined
        : (data?.lastUpdatedHOSStatus as string),
      keyValues: [
        {
          key: t('getColumnToolTipPropsLastLocation'),
          value: formatedTextFromTwoValues(
            data?.driverLocationDetails?.city,
            data?.driverLocationDetails?.state,
            ','
          ),
          valueStyles: { paddingLeft: '2px' },
        },
        {
          key: data.driverLocationDetails?.lastUpdatedTime || 'Updated: -',
        },
      ],
    },
    weekInformation: [
      {
        key: t('getColumnToolTipPropsWeek'),
        value: data?.currentWeek?.length
          ? formatedTextFromTwoValues(
              data?.currentWeek?.[0],
              data?.currentWeek?.[1],
              ' -'
            )
          : '-',
      },
    ],
    footer: getDriverWarningsData(data),
  };
};

const getDriverTooltiActions = ({
  hasBulkOptimizationPermission,
  driverGroupId,
}: {
  hasBulkOptimizationPermission?: boolean;
  driverGroupId?: string;
}) => {
  const actions = [
    {
      id: driverGroupId,
      key: DRIVER_TOOLTIP_ACTION_KEYS.TIMELINE,
      label: t('getDriverTooltiActionsViewTimeline'),
    },
  ] as any;
  if (hasBulkOptimizationPermission)
    actions?.push({
      id: '2',
      btnId: LOADAI_ELEMENTS.DRIVER_TOOLTIP_RECOMMEND_TRIPS,
      key: DRIVER_TOOLTIP_ACTION_KEYS.RECOMMEND_TRIPS,
      label: t('getDriverTooltiActionsRecommendTrips'),
      variant: 'outlined',
      buttonProps: { startIcon: <OptymLogo /> },
    });
  return actions;
};

export const generateDriverTooltipSubtitles = (data: ScheduleDriverType) => {
  const subTitles = [
    {
      key: t('generateDriverTooltipSubtitlesID'),
      type: ESecondaryDetailsPanelType.USER,
      values: [
        {
          id: data?.driverGroupDetails?.driverId,
          text: data?.driverGroupDetails?.primaryDriverRefId || '',
        },
      ],
      additionalValue: data?.driverGroupDetails?.primaryDriverRefId
        ? null
        : '-',
    },
    {
      key: t('generateDriverTooltipSubtitlesTractor'),
      type: ESecondaryDetailsPanelType.TRACTOR,
      values: [
        {
          id: data?.driverGroupDetails?.tractorId,
          text: data?.driverGroupDetails?.tractorNumber || '',
        },
      ],
      additionalValue: data?.driverGroupDetails?.tractorNumber ? null : '-',
    },
    {
      key: t('generateDriverTooltipSubtitlesTrailer'),
      type: ESecondaryDetailsPanelType.TRAILER,
      values: [
        {
          id: data?.driverGroupDetails?.trailerId,
          text: data?.driverGroupDetails?.trailerNumber || '',
        },
      ],
      additionalValue: data?.driverGroupDetails?.trailerNumber ? null : '-',
    },
    {
      key: t('generateDriverTooltipSubtitlesType'),
      additionalValue: formatedTextFromTwoValues(
        data?.driverGroupDetails?.primaryDriverType,
        data?.driverGroupDetails?.primaryDriverOperationType,
        '-'
      ),
    },
    {
      key: t('generateDriverTooltipSubtitlesHome'),
      additionalValue: formatedTextFromTwoValues(
        data?.driverGroupDetails?.homeCity,
        data?.driverGroupDetails?.homeState,
        ','
      ),
    },
    {
      key: t('generateDriverTooltipSubtitlesContact'),
      additionalValue: data?.driverGroupDetails?.email ?? '-',
      subValue: data?.driverGroupDetails?.phoneNumber ? (
        <a href={`tel:${data?.driverGroupDetails?.phoneNumber}`}>
          {data?.driverGroupDetails?.phoneNumber}
        </a>
      ) : (
        ''
      ),
    },
  ];
  if (data?.driverGroupDetails?.secondaryDriverEmail) {
    subTitles.push({
      key: t('generateSecondaryDriverTooltipSubtitlesContact'),
      additionalValue: data?.driverGroupDetails?.secondaryDriverEmail ?? '-',
      subValue: data?.driverGroupDetails?.phoneNumber ? (
        <a href={`tel:${data?.driverGroupDetails?.secondaryDriverPhoneNumber}`}>
          {data?.driverGroupDetails?.secondaryDriverPhoneNumber}
        </a>
      ) : (
        ''
      ),
    });
  }
  return subTitles;
};

export const generateDriverKpis = (data: ScheduleDriverType) => {
  return [
    {
      primaryText: appendTextToDigit({
        val: data?.currentRevenue,
        prefixLabel: '$',
      }),
      secondaryText: t('generateDriverKpisRevenue'),
      primaryTextStyles: { color: '#3B873E' },
    },
    {
      primaryText: appendTextToDigit({
        val: data?.driverPay as number,
        prefixLabel: '$',
      }),
      secondaryText: t('generateDriverKpisDriverPay'),
      primaryTextStyles: { color: '#3B873E' },
    },
    {
      primaryText: appendTextToDigit({
        val: data?.currentMileage as number,
        postfixLabel: ' mi',
      }),
      secondaryText: t('generateDriverKpisMileage'),
      primaryTextStyles: { color: '#2B64CB' },
    },
  ];
};
const labelStyles = { color: '#000' };
export const generateDriverHosDetails = (data: any) => {
  return [
    {
      label: getHourFromMinutes(data?.timeToBreak),
      value: getPercentage(
        data?.timeToBreak,
        data?.hosConstants?.timeToBreakConst
      ),
      caption: t('generateDriverHosDetailsNextBreak'),
      labelStyles,
    },
    {
      label: getHourFromMinutes(data?.driveTimeRemaining),
      value: getPercentage(
        data?.driveTimeRemaining,
        data?.hosConstants?.driveTimeConst
      ),
      caption: t('generateDriverHosDetailsDrive'),
      labelStyles,
    },
    {
      label: getHourFromMinutes(data?.dutyTimeRemaining),
      value: getPercentage(
        data?.dutyTimeRemaining,
        data?.hosConstants?.dutyTimeConst
      ),
      caption: t('generateDriverHosDetailsDuty'),
      labelStyles,
    },
    {
      label: getHourFromMinutes(data?.cycleTimeRemaining),
      value: getPercentage(data?.cycleTimeRemaining, data?.cycleTimeConst),
      caption: t('generateDriverHosDetailsCycle'),
      labelStyles,
    },
  ];
};
export const generateSecondaryDriverHosDetails = (data: any) => {
  return [
    {
      label: getHourFromMinutes(data?.timeToBreakSecondary),
      value: getPercentage(
        data?.timeToBreakSecondary,
        data?.hosConstants?.timeToBreakConst
      ),
      caption: t('generateDriverHosDetailsNextBreak'),
      labelStyles,
    },
    {
      label: getHourFromMinutes(data?.driveTimeRemainingSecondary),
      value: getPercentage(
        data?.driveTimeRemainingSecondary,
        data?.hosConstants?.driveTimeConst
      ),
      caption: t('generateDriverHosDetailsDrive'),
      labelStyles,
    },
    {
      label: getHourFromMinutes(data?.dutyTimeRemainingSecondary),
      value: getPercentage(
        data?.dutyTimeRemainingSecondary,
        data?.hosConstants?.dutyTimeConst
      ),
      caption: t('generateDriverHosDetailsDuty'),
      labelStyles,
    },
    {
      label: getHourFromMinutes(data?.cycleTimeRemainingSecondary),
      value: getPercentage(
        data?.cycleTimeRemainingSecondary,
        data?.cycleTimeConst
      ),
      caption: t('generateDriverHosDetailsCycle'),
      labelStyles,
    },
  ];
};

export const getDriverTooltipSubtitlesWithoutLink = (
  data: ScheduleDriverType
) => {
  return [
    {
      key: 'ID',
      additionalValue: data?.driverGroupDetails?.primaryDriverRefId || '-',
    },
    {
      key: 'Tractor',
      additionalValue: data?.driverGroupDetails?.tractorNumber || '-',
    },
    {
      key: 'Trailer',
      additionalValue: data?.driverGroupDetails?.trailerNumber || '-',
    },
    {
      key: 'Type',
      additionalValue: formatedTextFromTwoValues(
        data?.driverGroupDetails?.primaryDriverType,
        data?.driverGroupDetails?.primaryDriverOperationType,
        '-'
      ),
    },
    {
      key: 'Home',
      additionalValue: formatedTextFromTwoValues(
        data?.driverGroupDetails?.homeCity,
        data?.driverGroupDetails?.homeState,
        ','
      ),
    },
    {
      key: 'Contact',
      additionalValue:
        data?.driverGroupDetails?.email ||
        (!data?.driverGroupDetails?.email && '-'),
      subValue: data?.driverGroupDetails?.phoneNumber ?? '',
    },
  ];
};
