import { TooltipActionContainerStyles, TooltipActionStyles } from '../styles';
import { GanttPuckType, TooltipActionType } from '../types';
import React from 'react';
import { Grid, Button, Typography } from '@mui/material';

interface IActionTemplate {
  actions?: Array<TooltipActionType | any>;
  actionRenderer?: JSX.Element;
  onActionClick?: (data: GanttPuckType) => void;
  containerStyles?: object;
}

const ActionTemplate: React.FC<IActionTemplate> = (props) => {
  const { actions, actionRenderer, onActionClick, containerStyles } = props;
  if (actionRenderer) return actionRenderer;
  if (actions?.length)
    return (
      <Grid
        container
        style={{ ...TooltipActionContainerStyles, ...containerStyles }}
      >
        {actions?.map((action) => (
          <Grid
            id={action?.id}
            key={action?.label}
            item
            xs={action?.gridLevel || 12}
          >
            <Button
              id={action?.btnId}
              variant={action?.variant ?? 'contained'}
              fullWidth
              sx={{ ...TooltipActionStyles, ...action.style }}
              onClick={() => {
                onActionClick?.(action);
              }}
              {...action.buttonProps}
            >
              <Typography sx={action.btnTextStyles}>{action?.label}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  return <></>;
};

export default ActionTemplate;
