import { Box, Typography, SxProps } from '@mui/material';
import React from 'react';

interface IUnAssignedResourceDetail {
  name: string;
  textStyles?: SxProps;
}

function UnAssignedResourceDetail({
  name = 'Unassigned Load',
  textStyles = {},
}: IUnAssignedResourceDetail) {
  return (
    <Box width="100%" textAlign="center">
      <Typography
        variant="body1"
        fontWeight={600}
        color="primary"
        sx={textStyles}
      >
        {name} #
      </Typography>
      <Typography variant="caption" sx={textStyles}>
        {name?.toUpperCase()}
      </Typography>
    </Box>
  );
}

export default UnAssignedResourceDetail;
