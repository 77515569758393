import { GetUpdatedManifestTripListRequest } from 'views/trips/constants/types';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CheckOrAssignAssetResponse,
  CreateTripStopResponse,
  GetRelayStopResponse,
  PaginatedTripLightList,
  PaginatedTripList,
  ResequenceTripStopResponse,
  Trip,
  TripStopResponse,
  TripsRevenueTotalResponse,
} from '../../models';
import {
  CheckOrAssignAssetRequest,
  CreateTripStopRequest,
  DeleteTripRequest,
  DeleteTripStopRequest,
  ExportTripFilterPayload,
  ExportTripFilterQueryParams,
  GetRelayStop,
  GetTripDetailByIdQueryParams,
  GetTripDetailsActionResponse,
  GetTripDetailsActionsRequest,
  GetTripStopRequest,
  PaginatedTripLightListRequest,
  PaginatedTripListRequest,
  ResequenceTripStopRequest,
  TripWarningByIdsRequest,
  UpdateDispatchRevenueQueryParams,
  UpdateDispatchRevenueResponse,
  UpdateRelayStopPayload,
  UpdateRelayStopResponse,
  UpdateTripStatusPayload,
  UpdateTripStatusRequest,
  UpdateTripStatusResponse,
  UpdateTripStopRequest,
} from '../../models/DTOs/Trip/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { docType } from '../../utils/Doc';
import { CreateEmptyTripDTO } from '../../views/dispatch2/components/Trip/emptyTrip/dto/createEmptyTripDto';
import { ITripService, ServiceError, annotateNameAsync } from '../interfaces';
import { isAxiosErrorCancel } from './DispatchService';

const tripBaseApiPath = '/web/load/api/v2/trips';
const tripDetailBaseApiPath = '/web/load/api/v2/trip-details';

const resources = {
  list: `${tripBaseApiPath}/get/list`,
  updatedList: `${tripBaseApiPath}/get/multiple`,
  lightLight: `${tripBaseApiPath}/summary/seqnumber/list`,
  getStop: `${tripDetailBaseApiPath}/trip-routes`,
  createTripStop: `${tripBaseApiPath}/add-stop`,
  updateTripStop: `${tripBaseApiPath}/stops`,
  deleteTripStop: `${tripBaseApiPath}/delete-stop`,
  resequenceTripStopURL: `${tripBaseApiPath}/stops/re-sequence`,
  exportToExcel: `${tripBaseApiPath}/export-to-excel`,
  getRelayStop: `${tripBaseApiPath}/connected/relays`,
  updateRelayStop: `${tripBaseApiPath}/relay/stop`,
  getTripDetailsActions: '/web/load/api/v2/trips/applicable/actions',
  updateStatus: '/web/load/api/ax2/load-trip/actions/trip',
  deleteTrip: `${tripBaseApiPath}/delete-trip`,
  getTripWarnings: `${tripBaseApiPath}/fetch/warnings`,
  getTripDetailById: `${tripBaseApiPath}/get/one`,
  checkOrAssignAsset: `web/load//api/v2/trip-assignment/progress/assets`,
  getTripsRevenueTotal: `${tripBaseApiPath}/footer/amounts`,
  updateDispatchRevenue: `${tripDetailBaseApiPath}/edit/dispatch-revenue`,
  createEmptyTrip: `${tripBaseApiPath}/newEmptytrip`,
};

export class TripService extends ITripService {
  @annotateNameAsync
  async getPaginatedTripList(
    requestData: PaginatedTripListRequest,
    skipLoader?: boolean
  ) {
    try {
      const result = await httpClient.post<PaginatedTripList>(
        resources.list,
        undefined,
        requestData,
        PaginatedTripList,
        true,
        undefined,
        skipLoader
      );
      result.content = result.content
        ? result.content.map((trip) => ({
          ...trip,
          tractorName: trip.assignment?.tractorName,
          trailerName: trip.assignment?.trailerName,
        }))
        : [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaginatedTripLightList(requestData: PaginatedTripLightListRequest) {
    try {
      const result = await httpClient.get<PaginatedTripLightList>(
        resources.lightLight,
        requestData,
        PaginatedTripLightList,
        undefined,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async getTripStop(
    requestData: GetTripStopRequest
  ): Promise<TripStopResponse | ServiceError> {
    try {
      const result = await httpClient.get<TripStopResponse>(
        resources.getStop,
        requestData,
        TripStopResponse,
        undefined,
        true
      );
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createTripStop(payloadData: CreateTripStopRequest) {
    try {
      return await httpClient.put<CreateTripStopResponse>(
        resources.createTripStop,
        undefined,
        payloadData,
        CreateTripStopResponse,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async updateTripStop(payloadData: UpdateTripStopRequest) {
    try {
      return await httpClient.put<{}>(
        resources.updateTripStop,
        undefined,
        payloadData,
        {},
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async deleteTripStop(queryParams: DeleteTripStopRequest) {
    try {
      const response = await httpClient.deleteRaw(
        resources.deleteTripStop,
        queryParams,
        undefined
      );
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async resequenceTripStop(payloadData: ResequenceTripStopRequest) {
    try {
      return await httpClient.put<ResequenceTripStopResponse>(
        resources.resequenceTripStopURL,
        undefined,
        payloadData,
        ResequenceTripStopResponse,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async exportToExcel(
    queryParams: ExportTripFilterQueryParams,
    payload: ExportTripFilterPayload
  ): Promise<docType | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        resources.exportToExcel,
        queryParams,
        payload
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getRelayStop(
    queryParams: GetRelayStop
  ): Promise<GetRelayStopResponse | ServiceError> {
    try {
      return httpClient.get<GetRelayStopResponse>(
        resources.getRelayStop,
        queryParams,
        GetRelayStopResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateRelayStop(
    requestData: UpdateRelayStopPayload
  ): Promise<UpdateRelayStopResponse | ServiceError> {
    try {
      return httpClient.put<UpdateRelayStopResponse>(
        resources.updateRelayStop,
        undefined,
        requestData,
        UpdateRelayStopResponse
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTripDetailsActions(
    requestData: GetTripDetailsActionsRequest
  ): Promise<GetTripDetailsActionResponse | ServiceError> {
    try {
      const response = await httpClient.get<GetTripDetailsActionResponse>(
        resources.getTripDetailsActions,
        requestData,
        GetTripDetailsActionResponse,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateStatus(
    requestData: UpdateTripStatusRequest,
    payload: UpdateTripStatusPayload
  ): Promise<UpdateTripStatusResponse | ServiceError> {
    try {
      const response = await httpClient.put<UpdateTripStatusResponse>(
        resources.updateStatus,
        requestData,
        payload,
        UpdateTripStatusResponse
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTrip(
    requestData: DeleteTripRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        resources.deleteTrip,
        requestData,
        {}
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTripWarningsByIds(requestData: TripWarningByIdsRequest) {
    try {
      return await httpClient.putRaw(
        resources.getTripWarnings,
        undefined,
        requestData,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async checkOrAssignAsset(
    requestData: CheckOrAssignAssetRequest
  ): Promise<CheckOrAssignAssetResponse | ServiceError> {
    try {
      const response = await httpClient.put<CheckOrAssignAssetResponse>(
        resources.checkOrAssignAsset,
        undefined,
        requestData,
        CheckOrAssignAssetResponse
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getTripsRevenueTotal(
    requestData: PaginatedTripListRequest
  ): Promise<TripsRevenueTotalResponse | ServiceError | null> {
    try {
      const result = await httpClient.putRaw(
        resources.getTripsRevenueTotal,
        undefined,
        requestData,
        false,
        true
      );

      return result?.data || null;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateTripDispatchRevenue(
    queryParams: UpdateDispatchRevenueQueryParams
  ): Promise<UpdateDispatchRevenueResponse | ServiceError | null> {
    try {
      const response = await httpClient.put<UpdateDispatchRevenueResponse>(
        resources.updateDispatchRevenue,
        queryParams,
        null,
        UpdateDispatchRevenueResponse,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateTripDispatchRevenue',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'updateTripDispatchRevenue',
        });
      }
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getTripDetailById(
    queryParams: GetTripDetailByIdQueryParams
  ): Promise<Trip | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        resources.getTripDetailById,
        queryParams,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getMultipleTripLists(
    payLoad: GetUpdatedManifestTripListRequest
  ): Promise<Array<Trip> | ServiceError> {
    try {
      return await httpClient.get<Array<Trip>>(
        resources.updatedList,
        payLoad,
        undefined,
        true,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createEmptyTrip(
    payLoad: CreateEmptyTripDTO
  ): Promise<any | ServiceError> {
    try {
      return await httpClient.post(
        resources.createEmptyTrip,
        undefined,
        payLoad,
        CreateEmptyTripDTO,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
