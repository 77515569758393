import * as yup from 'yup';
import { nullableStringYup } from '@/utils';
import { TagEntityType } from "@/models/DTOs/ManageTypes/Requests";

export const tagFormDefaultData = {
  name: '',
  description: '',
  entityTypes: undefined,
  color: '',
};

export const tagsValidationSchema = yup.object().shape({
  name: nullableStringYup()
    .max(25, `Cannot be longer than 25 characters`)
    .required('Tag Name is required'),
  description: nullableStringYup().max(
    255,
    `Cannot be longer than 255 characters`
  ),
  color: nullableStringYup().required('Color is required'),
  entityTypes: yup
    .array()
    .required('Entity Type is required')
    .min(1, 'At least one entity type should be selected'),
});

export const tagEntities: Record<TagEntityType, string> = {
  DRIVER: 'Driver',
  USER: 'User',
  LOAD: 'Load',
};
