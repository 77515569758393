import dayjs from 'dayjs';
import moment from 'moment';
import { QueryParams } from '../../../../models';
import { CustomerRequiredDocOptions } from '../constants/fieldOptions';
import { ReeferModeEnum } from '../schema/load';
import { StopTypeEnum } from '../schema/stops';
import { createLoadService } from '../services/createLoad.service';
import {
  AppointmentConfirmationEnum,
  AppointmentConfirmationType,
  ILoadStop,
} from '../types/requests';
import { ActivityType, ILoadDetails } from '../types/types';
import { CreateLoadContactResDTO } from './template-res.dto';
import { TagItem } from '@/models/DTOs/ManageTypes/Requests';

export interface CreateLoadStopRequestStopDTO {
  relayStop?: RelayStopRequestDTO;
  loadStop?: StopDropRequestDTO;
  tripStop?: StopDropRequestDTO;
}

type NumString = number | string | null;
export interface LineItemResDTO {
  itemId?: string;
  type?: string;
  description?: string;
  quantity?: NumString;
  rate?: number | null;
  amount?: NumString;
  unit?: string;
  contractId?: string | null;
  contractRateId?: string | null;
  isContract?: boolean;
  deduction?: boolean;
}
export interface InvoiceDetailRequestDTO {
  lineItems?: LineItemResDTO[];
  receivedPayments?: [];
  notes?: string;
  messageOnInvoice?: string;
  noteDetails?: {
    userId?: number;
    updateTime?: string;
  };
  invoiceTerm?: string;
  invoiceTermValue?: string | null;
  loadedMiles?: NumString;
}

export enum LoadPriorityEnum {
  None = 'NONE',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Critical = 'CRITICAL',
}

export interface LoadDetailRequestDTO {
  factoringId?: number;
  factoringName?: string;
  factoringNotes?: string;
  messageOnInvoice?: string;
  factoringChargePercent?: string;
  notes?: string;
  referenceId?: string;
  useFactoring?: boolean;
  totalWeight?: number | null;
  commodityData?: ICommodityDataType[];
  trailerTypes?: Array<string>;
  equipmentAdditionalDetails?: IEquipmentAdditionalDetails;
  loadPriority?: LoadPriorityEnum;
  canBeBrokered?: boolean;
}

export interface IEquipmentAdditionalDetails {
  chassisNumber?: string;
  containerNumber?: string;
  lowerLimitTemperature?: number;
  upperLimitTemperature?: number;
  bookingNumber?: number | string;
  appointmentNumber?: number | string;
  loadDirection?: string;
  sealNumber?: string;
  variance?: NumString;
  temperature?: number | null;
  reeferMode?: string;
}

export interface ICommodityDataType {
  id?: string | number | null;
  commodityType?: string;
  commodityTypeId?: string;
  description?: string;
  floorLoad?: boolean;
  height?: number | null;
  itemName?: string;
  length?: number | null;
  packageType?: string | null;
  qty?: number | null;
  stackable?: boolean;
  volume?: number | null;
  weight?: number | null;
  width?: number | null;
}

export const getLoadStopRequestDTO = (
  stop: ILoadStop,
  isManualLoadCreation?: boolean
): CreateLoadStopRequestStopDTO => {
  if (stop.type === StopTypeEnum.RELAY)
    return { relayStop: new RelayStopRequestDTO(stop, isManualLoadCreation) };
  else if (stop.type === StopTypeEnum.TRIP_STOP)
    return { tripStop: new StopDropRequestDTO(stop, isManualLoadCreation) };
  return { loadStop: new StopDropRequestDTO(stop, isManualLoadCreation) };
};

export class RelayStopRequestDTO {
  connectionPoint?: StopConnectionPointDTO;
  pickupConnectionDetails?: IStopPoint;
  dropOffConnectionDetails?: IStopPoint;
  constructor(stop: ILoadStop, isManualLoadCreation = false) {
    this.connectionPoint = {
      id: stop?.location?.locationId
        ? String(stop?.location?.locationId)
        : undefined,
      locationName:
        stop?.location?.locationName ||
        stop.location?.businessName! ||
        stop.location?.address,
      fullAddress: stop?.location?.address,
      address: stop?.location?.address,
      streetAddress: stop?.location?.streetAddress,
      streetAddress2: stop.location?.streetAddress2,
      city: stop?.location?.city,
      state: stop.location?.state,
      zipcode: stop.location?.zipcode,
      lat: stop.location?.center?.lat!,
      lng: stop.location?.center?.lng!,
      center: stop.location?.center!,
      // refNumber: stop?.referenceNumber,
      contacts: stop.contacts,
      geoCoordinates: stop.location?.geoCoordinates,
      // timezone
      notes: stop?.notes,
      avgHandlingTime: stop?.estimatedActivityDuration,
      // recordDate
      country: stop.location?.country,
      activityType: stop?.type === StopTypeEnum.TRIP_STOP ? null : stop?.type,
      timezone: stop.location?.timezone,
    };
    this.pickupConnectionDetails = {
      stopStatus: 'NONE',
      appointmentStartDate:
        appendLoadStopDateTime(
          stop?.appointmentStartDate!,
          stop?.appointmentStartTime!,
          stop.location?.timezone!
        ) || undefined,
      appointmentEndDate:
        appendLoadStopDateTime(
          stop?.appointmentEndDate!,
          stop?.appointmentEndTime!,
          stop.location?.timezone!
        ) || undefined,
      estimatedActivityDuration: stop?.estimatedActivityDuration!,
      driverAssistRequired: Boolean(stop?.isDriverAssistRequired),
      fixedAppointment: !stop?.isEndTimeVisible,
      revenueShare: isNaN(Number(stop?.relayPickupRevenueShare))
        ? null
        : Number(stop?.relayPickupRevenueShare),
      appointmentConfirmation: stop?.appointmentConfirmation!,
    };
    this.dropOffConnectionDetails = {
      stopStatus: 'NONE',
      appointmentStartDate:
        appendLoadStopDateTime(
          stop?.relayDropOffAppointmentStartDate!,
          stop?.relayDropOffAppointmentStartTime!,
          stop.location?.timezone!
        ) || undefined,
      appointmentEndDate:
        appendLoadStopDateTime(
          stop?.relayDropOffAppointmentEndDate!,
          stop?.relayDropOffAppointmentEndTime!,
          stop.location?.timezone!
        ) || undefined,
      estimatedActivityDuration: stop?.relayDropOffEstimatedActivityDuration!,
      driverAssistRequired: Boolean(stop?.isRelayDropOffDriverAssistRequired),
      fixedAppointment: !stop?.isRelayDropOffEndTimeVisible,
      revenueShare: isNaN(Number(stop?.relayDropOffRevenueShare))
        ? null
        : Number(stop?.relayDropOffRevenueShare),
      appointmentConfirmation: stop?.dropOffAppointmentConfirmation!,
    };
    if (stop.location?.timezone && moment.tz.zone(stop.location?.timezone)) {
      this.pickupConnectionDetails.appointmentStartDate =
        convertStopTimeToTimezone(
          stop,
          this.pickupConnectionDetails.appointmentStartDate
        );
      this.pickupConnectionDetails.appointmentEndDate =
        convertStopTimeToTimezone(
          stop,
          this.pickupConnectionDetails.appointmentEndDate
        );
      this.dropOffConnectionDetails.appointmentStartDate =
        convertStopTimeToTimezone(
          stop,
          this.dropOffConnectionDetails.appointmentStartDate
        );
      this.dropOffConnectionDetails.appointmentEndDate =
        convertStopTimeToTimezone(
          stop,
          this.dropOffConnectionDetails.appointmentEndDate
        );
    }
  }
}

interface IStopPoint {
  driverExternalId?: string;
  driverGroupId?: string;
  driverList?: Array<number>;
  driverPicked?: boolean;
  seqNumber?: string;
  dispatcherId?: number;
  dispatcherExternalId?: string;
  tractorId?: number;
  tractorExternalId?: string;
  trailerId?: number;
  trailerExternalId?: string;
  stopStatus?: string;
  appointmentStartDate?: string;
  appointmentEndDate?: string;
  estimatedActivityDuration?: number;
  driverAssistRequired?: boolean;
  fixedAppointment?: boolean;
  actualTimeOfArrival?: string;
  actualTimeOfCompletion?: string;
  revenueShare?: number | null;
  terminalId?: string;
  appointmentConfirmation?: AppointmentConfirmationType;
  dropOffAppointmentConfirmation?: AppointmentConfirmationType;
}

export class StopConnectionPointDTO {
  organizationId?: number;
  axeleId?: string;
  id?: string;
  locationName?: string;
  fullAddress?: string;
  address?: string;
  streetAddress?: string;
  streetAddress2?: string;
  city?: string;
  zipcode?: string;
  state?: string;
  lat?: number;
  lng?: number;
  contacts?: StopContactDTO[];
  center?: {
    lat?: number | null;
    lng?: number | null;
  };
  geoCoordinates?: {
    coordinates: Array<number>;
  }[];
  timezone?: string;
  notes?: string;
  avgHandlingTime?: number;
  recordDate?: string;
  country?: string;
  activityType?: ActivityType | null;
  location?: any;
}

export class StopContactDTO {
  id?: number;
  contactTitle?: string;
  prefix?: string;
  suffix?: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  countryCode?: string;
  extension?: string;
  phone?: string;
  email?: string;
  isPrimary?: boolean;
  isAfterHours?: boolean;
  fullName?: string;
  description?: string;
}

export interface CreateLoadFormContact {
  id?: string;
  contactName?: string | null;
  email?: string | null;
  countryCode?: string | null;
  phone?: string | null;
  extension?: string | null;
  firstname?: string | null;
  lastname?: string | null;
}

export interface CreateLocationRequestDTO {
  id?: number;
  organizationId?: number;
  axeleId?: string;
  locationName?: string;
  fullAddress?: string;
  address?: string;
  streetAddress?: string;
  streetAddress2?: string;
  city?: string;
  zipcode?: string;
  state?: string;
  lat?: number;
  lng?: number;
  contacts?: Array<StopContactDTO>;
  center?: {
    lat?: number | null;
    lng?: number | null;
  };
  geoCoordinates?: {
    coordinates: Array<number>;
  }[];
  timezone?: string;
  notes?: string;
  avgHandlingTime?: number;
  recordDate?: string;
  country?: string;
}

export class StopDropRequestDTO {
  stopStatus?: string;
  refNumber?: string;
  sequenceNumber?: number;
  location?: CreateLocationRequestDTO;
  contact?: CreateLoadContactResDTO;
  contacts?: Array<StopContactDTO>;
  appointmentStartDate?: string;
  appointmentEndDate?: string;
  estimatedActivityDuration?: number;
  isDriverAssistRequired?: boolean;
  notes?: string;
  noteDetails?: {
    userId: number;
    updateTime: string;
  };
  stopCategory?: string;
  timezone?: string;
  activityType?: ActivityType | null;
  fixedAppointment?: boolean;
  stopDetentionTime?: number;
  actualTimeOfArrival?: string;
  actualTimeOfCompletion?: string;
  appointmentConfirmation?: string;
  preLoaded?: boolean;
  dropTrailer?: boolean;

  constructor(stop: ILoadStop, isManualLoadCreation = false) {
    this.stopStatus = 'NONE';
    this.activityType =
      stop?.type === StopTypeEnum.TRIP_STOP ? null : stop?.type;
    this.location = {
      id: stop?.location?.locationId!,
      locationName:
        stop?.location?.locationName! ||
        stop.location?.businessName ||
        stop.location?.address,
      fullAddress: stop?.location?.address,
      address: stop.location?.address,
      streetAddress: stop.location?.streetAddress,
      streetAddress2: stop.location?.streetAddress2,
      city: stop.location?.city,
      state: stop.location?.state,
      zipcode: stop.location?.zipcode,
      lat: stop.location?.center?.lat!,
      lng: stop.location?.center?.lng!,
      contacts: stop.contacts,
      center: stop.location?.center,
      geoCoordinates: stop.location?.geoCoordinates,
      timezone: stop.location?.timezone,
      notes: stop.notes,
      avgHandlingTime: stop?.estimatedActivityDuration,
      country: stop?.location?.country,
    };
    this.contact = {
      firstname: stop.contact?.contactName!,
      email: stop.contact?.email!,
      countryCode: stop.contact?.countryCode!,
      extension: stop.contact?.extension!,
      phone: (stop?.contact as any)?.phoneData?.phone ?? stop?.contact?.phone,
    };
    this.appointmentStartDate =
      appendLoadStopDateTime(
        stop?.appointmentStartDate!,
        stop?.appointmentStartTime!,
        stop.location?.timezone!
      ) || undefined;
    this.appointmentEndDate =
      appendLoadStopDateTime(
        stop?.appointmentEndDate!,
        stop?.appointmentEndTime!,
        stop.location?.timezone!
      ) || undefined;
    this.estimatedActivityDuration = stop?.estimatedActivityDuration;
    this.isDriverAssistRequired = stop?.isDriverAssistRequired;
    this.notes = stop.notes;
    this.refNumber = stop?.referenceNumber;
    this.fixedAppointment = !stop?.isEndTimeVisible;
    this.appointmentConfirmation =
      stop?.appointmentConfirmation ?? AppointmentConfirmationEnum.NO;
    this.preLoaded = stop.preLoaded;
    this.dropTrailer = stop.dropTrailer;
  }
}

export const convertStopTimeToTimezone = (stop: any, date: any) => {
  // conversion of date to timezone if required
  return date;
};

export const appendLoadStopDateTime = (
  date: string,
  time: string,
  timezone: string,
  isManualLoadCreation?: boolean
) => {
  if (!date) return null;
  // const timeSplit = time?.split?.(':');
  // const hours = timeSplit?.[0];
  // const minutes = timeSplit?.[1];
  const time2 = dayjs(time);
  const hours = time2.hour();
  const minutes = time2.minute();

  if (!timezone) {
    return moment(date)
      .set({
        hours: Number(hours ?? 0),
        minutes: Number(minutes ?? 0),
      })
      ?.format();
  }
  const t = moment(date)
    .set({
      hours: Number(hours ?? 0),
      minutes: Number(minutes ?? 0),
    })
    .clone()
    .tz?.(timezone, true)
    ?.format?.();
  return moment(t)?.isValid() ? t : null;
};

export class CreateLoadRequestDTO extends QueryParams {
  stopOnFailure?: boolean;
  byExternalSystemId?: boolean;
  applyAutomation?: boolean;
  data?: {
    externalSystemId?: string;
    driverId?: string;
    dispatcherId?: string;
    customerContactId?: NumString;
    customerId?: number | null;
    contacts?: Array<any>;
    loadCategory?: string;
    loadStatus?: string;
    loadType?: string;
    billOfLadingMandatory?: boolean;
    proofOfDeliveryMandatory?: boolean;
    revenue?: number | string;
    invoice?: InvoiceDetailRequestDTO;
    loadDetails?: LoadDetailRequestDTO;
    terminalId?: string | null;
    stops?: Array<CreateLoadStopRequestStopDTO>;
    tags: Array<TagItem>;
  }[];
  constructor(
    load: ILoadDetails,
    data?: { invoiceTerm?: string; invoiceTermValue?: string }
  ) {
    super();
    this.stopOnFailure = true;
    this.applyAutomation = !!load.applyAutomation;
    this.byExternalSystemId = false;
    this.data = [
      {
        loadStatus: 'AVAILABLE',
        loadType: load?.load?.loadType,
        customerId: load?.customer?.customerId as number,
        customerContactId: load?.customer?.contacts?.find(
          (e) => e?.id === load?.customer?.customerContactId
        )
          ? load?.customer?.customerContactId!
          : null,
        loadCategory: 'LOAD',
        billOfLadingMandatory: Boolean(
          load?.customer?.requiredDocuments?.includes(
            CustomerRequiredDocOptions?.[0]?.id
          )
        ),
        proofOfDeliveryMandatory: Boolean(
          load?.customer?.requiredDocuments?.includes(
            CustomerRequiredDocOptions?.[1]?.id
          )
        ),
        revenue: isNaN(Number(load?.rate?.totalRate))
          ? 0
          : Number(load?.rate?.totalRate),
        invoice: {
          lineItems: createLoadService.getLoadInvoiceLineItems(load.rate!),
          receivedPayments: [],
          notes: load?.rate?.internalNotes,
          invoiceTerm: load.rate?.invoiceTerm || data?.invoiceTerm,
          invoiceTermValue:
            load.rate?.invoiceTermValue || data?.invoiceTermValue,
          noteDetails: {},
          loadedMiles: load?.isTotalMilesManuallyUpdate
            ? load?.totalMiles
            : null,
        },
        loadDetails: {
          factoringId: load?.customer?.factoringId as number,
          factoringNotes: load.rate?.messageOnInvoice,
          // messageOnInvoice: load.rate?.messageOnInvoice,
          factoringName: load.customer?.factoringName,
          factoringChargePercent: load.customer?.factoringChargePercent!,
          useFactoring: Boolean(load.customer?.factoringId),
          notes: load?.load?.notes,
          referenceId: load?.customer?.referenceNumber!,
          totalWeight: load?.load?.weight ? load?.load?.weight : null,
          trailerTypes: load?.load?.equipment,
          equipmentAdditionalDetails: {
            chassisNumber:
              load.load?.equipmentAdditionalDetails?.chassisNumber ?? '',
            containerNumber:
              load.load?.equipmentAdditionalDetails?.containerNumber ?? '',
            sealNumber: load.load?.seal ?? '',
            temperature:
              load.load?.equipmentAdditionalDetails?.reeferMode ===
              ReeferModeEnum.OFF
                ? null
                : load.load?.temp,
            variance:
              load.load?.equipmentAdditionalDetails?.reeferMode ===
              ReeferModeEnum.OFF
                ? null
                : load.load?.tempVariance!,
            reeferMode: load.load?.equipmentAdditionalDetails?.reeferMode,
            loadDirection:
              load.load?.equipmentAdditionalDetails?.importExport! ?? null,
            appointmentNumber: load.load?.equipmentAdditionalDetails
              ?.appointmentNumber as string,
            bookingNumber: load.load?.equipmentAdditionalDetails
              ?.bookingNumber as string,
          },
          loadPriority: load.customer?.loadPriority,
          canBeBrokered: Boolean(load.load?.isBrokered),
          commodityData: load.load?.commodities?.map((e) => ({
            commodityType: e.commodityDetails?.itemCode,
            commodityTypeId: e.commodityDetails?.id,
            description: e?.description,
            height: e.height,
            itemName: e.commodityDetails?.itemName,
            length: e.length,
            packageType: e.package || null,
            qty: e.quantity,
            volume: e.volume,
            weight: e.weight,
            width: e.width,
            floorLoad: false,
            stackable: false,
          })),
        },
        tags: load.load?.tags!,
        terminalId: load?.customer?.invoice?.length
          ? load?.customer?.invoice
          : null,
        stops: load?.stops?.map((e) =>
          getLoadStopRequestDTO(e, load?.isManualLoadCreation)
        ),
      },
    ];
  }
}
