export const popupHeaderStyles = {
  color: '#fff',
  display: 'flex',
  padding: '5px 0',
  alignItems: 'center',
};

export const popupHeaderIconStyles = {
  paddingLeft: '1rem',
  paddingTop: '.5rem',
  pb: 0.5,
};

export const popupHeaderTitleStyles = {
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '150%',
  mt: '0.4rem',
};

export const popupSubheaderStyles = {
  color: '#64B6F7',
  fontWeight: '600',
  fontSize: '13px',
  lineHeight: '150%',
};

export const popupContentStyles = {
  backgroundColor: '#fff',
  width: '100%',
  height: 'auto',
  borderRadius: '8px 8px 0 0',
  color: '#000',
};

export const popupFooterStyles = {
  backgroundColor: '#FFE9E0',
  width: '100%',
  color: '#000',
  borderRadius: '0 0 8px 8px',
  border: 'none',
};

export const popupFooterContentStyles = {
  display: 'flex',
  p: 1.3,
  alignItems: 'center',
  gap: 1,
  pt: 1.4,
  ml: 2,
  border: 'none',
};

export const toolTipStyles = {
  backgroundColor: '#1F2831',
  width: '343px',
  height: '100%',
  paddingRight: 0,
  paddingLeft: 0,
  paddingBottom: 0,
  borderRadius: '8px',
};

export const tooltipArrowStyles = {
  color: '#1F2831',
  fontSize: '22px',
};

export const tooltipBodyContentStyles = {
  fontSize: '12px',
  lineHeight: '100%',
  'letter-spacing': '0.4px',
};

export const contentRootStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 2,
};
export const contentTextStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
};
export const contextInfoStyles = {
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '150%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  letterSpacing: '0.15px',
};

export const commonTypographyStyles = {
  wordBreak: 'break-word',
};

export const TooltipPaperStyles = {
  borderRadius: '8px',
  border: '1px solid #2B64CB',
  maxHeight: 'calc(100% - 50px)',
  // overflorY: 'auto',
};
export const DriverScrollableContainerStyles = {
  maxHeight: '600px',
  overflowY: 'auto',
};

export const CircularProgressTextContainerStyles = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const CircularProgressTextStyles = {
  fontSize: '10px',
  mb: '6px',
  fontFamily: 'Poppins',
  letterSpacing: '0.4px',
};
