import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  BulkSubmitRequest,
  DeleteInvoiceRequest,
  DisconnectFromFactoringRequest,
  DownloadInvoicePreviewRequest,
  DownloadInvoicesAsZipRequest,
  DownloadInvoicesRequest,
  DriverMappingType,
  ExportInvoiceListRequest,
  GetDriverMappingDetailsRequest,
  GetFundingAccountsRequest,
  GetIntegrationsRequest,
  GetInvoiceActionDataRequest,
  GetInvoiceLineItemsRequest,
  GetInvoiceListRequest,
  GetInvoiceNotesRequest,
  GetInvoiceSearchLoadsRequest,
  GetInvoiceWarningsRequest,
  GetloadIdListRequest,
  IntegrationTypeDTO,
  InvoiceSearchLoadsType,
  InvoiceToTurvoRequest,
  InvoiceWarningType,
  OnHoldStateInvoiceRequest,
  OnHoldStateInvoiceResponse,
  PreviewInvoiceRequest,
  RefreshFactoringStatusRequest,
  RefreshLoadAndTripsDriversRequest,
  SendDocumentRequest,
  SendEmailRequest,
  SendFactoringBulkRequest,
  SendFactoringRequest,
  SendFactoringRequestType,
  SendMultipleEmailRequest,
  StatusChangeRequest,
  StatusChangeSingleRequest,
  UpdateInvoiceRequest,
  UpdateInvoiceResponse,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import {
  DriverGroupDetailType,
  FactoringActionReturnType,
  GetInvoiceGroupDetailResponse,
  GetInvoiceNotesResponse,
  InvoiceCreatModel,
  InvoiceModel,
  InvoiceTotalsType,
  InvoiceUpdateModel,
  LineItem,
} from '../../subPages/invoices/models/InvoiceModel';
import { PaginatedResult } from '../../types';
import { docType } from '../../utils/Doc';
import {
  IInvoiceService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';
import { isAxiosErrorCancel } from './DispatchService';

export class InvoiceService extends IInvoiceService {
  @annotateNameAsync
  async getInvoiceList(
    requestData: GetInvoiceListRequest,
    payLoad: GetInvoiceListRequest
  ): Promise<PaginatedResult<InvoiceModel> | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/invoice/api/v3/invoice/get/list`,
        requestData,
        payLoad,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  async createInvoice(
    payLoad: InvoiceCreatModel
  ): Promise<string | ServiceError> {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await httpClient.postRaw(
        `web/invoice/api/v3/invoice`,
        undefined,
        payLoad,
        false,
        headers,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Invoice Created successfully',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: error?.message?.response?.data || 'Failed to Create invoice',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getInvoiceWarningsList(
    requestData: GetInvoiceWarningsRequest
  ): Promise<InvoiceWarningType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/invoice/api/v3/invoice/get/warnings`,
        undefined,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getInvoiceTotals(
    requestData: GetInvoiceListRequest,
    payLoad: GetInvoiceListRequest
  ): Promise<InvoiceTotalsType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/invoice/api/v3/invoice/get/total`,
        requestData,
        payLoad,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getInvoiceLineItems(
    requestData: GetInvoiceLineItemsRequest
  ): Promise<LineItem[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/preference/api/v2/financial/items/getByOrganizationId`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateInvoice(
    data: InvoiceUpdateModel
  ): Promise<InvoiceModel | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/invoice/api/v3/invoice`,
        undefined,
        data,
        false,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceUpdate',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceUpdate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async downloadInvoice(
    requestData: DownloadInvoicesRequest
  ): Promise<docType[] | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/invoice/api/v2/invoice/documents`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceDownload',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceDownload',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async downloadInvoicesAsZip(
    requestData: DownloadInvoicesAsZipRequest
  ): Promise<docType[] | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/invoice/api/v2/invoice/documents/bulk`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceDownload',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceDownload',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async previewInvoice(
    requestData: PreviewInvoiceRequest
  ): Promise<docType | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/invoice/api/v3/invoice`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async previewInvoiceDownload(
    requestData: DownloadInvoicePreviewRequest
  ): Promise<docType | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/invoice/api/v2/invoice/documents/generate`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async sendEmail(
    requestData: SendEmailRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/telegram/api/v2/messages/send`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceEmailSend',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceEmailSend',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async exportInvoiceList(
    requestData: ExportInvoiceListRequest,
    filters: { [p: string]: any },
    columns: string[],
    sort: string
  ): Promise<docType | ServiceError> {
    try {
      const bodyParams = {
        gridColumnMetadataList: [...columns, 'onHoldState'],
        gridFiltersMetadataList: filters,
        sort: sort,
      };
      const response = await httpClient.putRaw(
        `web/invoice/api/v3/invoice/export-to-excel`,
        undefined,
        { ...requestData, ...bodyParams }
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceExport',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceExport',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async statusChange(
    requestData: StatusChangeRequest
  ): Promise<InvoiceModel[] | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/invoice/api/v3/invoice/status/bulk`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceStatusChange',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceStatusChange',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async statusChangeSingle(
    requestData: StatusChangeSingleRequest
  ): Promise<InvoiceModel | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/invoice/api/v3/invoice/status`,
        undefined,
        requestData,
        false,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceStatusChange',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceStatusChange',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async sendFactoring(
    requestData: SendFactoringRequest
  ): Promise<FactoringActionReturnType | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `/web/factoring/api/v2/factorings/invoice`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceFactoringAction',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async sendFactoringBulk(
    requestData: SendFactoringBulkRequest
  ): Promise<FactoringActionReturnType | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(
        `/web/factoring/api/v2/factorings/invoice/list`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceFactoringAction',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async sendDocument(
    requestData: SendDocumentRequest,
    data: SendFactoringRequestType
  ): Promise<FactoringActionReturnType | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `/web/factoring/api/v2/factorings/invoice/documents`,
        undefined,
        { ...requestData, ...data }
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceFactoringAction',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateAtFactoring(
    requestData: SendDocumentRequest,
    data: SendFactoringRequestType
  ): Promise<FactoringActionReturnType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/factoring/api/v2/factorings/invoice`,
        undefined,
        { ...requestData, ...data }
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceFactoringAction',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async bulkSubmit(
    requestData: BulkSubmitRequest
  ): Promise<FactoringActionReturnType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/factoring/api/v2/factorings/invoice/submit`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceFactoringAction',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getFundingAccounts(
    requestData: GetFundingAccountsRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/factoring/api/v2/drivermappings/option/list`,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async refreshFactoringStatus(
    requestData: RefreshFactoringStatusRequest
  ): Promise<FactoringActionReturnType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/factoring/api/v2/factorings/invoice/refreshStatus`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceFactoringAction',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async disconnectFromFactoring(
    requestData: DisconnectFromFactoringRequest
  ): Promise<FactoringActionReturnType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/factoring/api/v2/factorings/invoice/disconnect`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceFactoringAction',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async sendMultipleEmail(
    requestData: SendMultipleEmailRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/telegram/api/v2/messages/send`,
        undefined,
        { ...requestData }
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getIntegrations(
    requestData: GetIntegrationsRequest
  ): Promise<{ integrationsDTO: IntegrationTypeDTO } | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/quickbook/api/v2/integration/qbOnlineDetails`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverMappingDetails(
    requestData: GetDriverMappingDetailsRequest
  ): Promise<Record<string, DriverMappingType> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/factoring/api/v2/drivermappings/mapping/details`,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async getInvoiceNotes(
    requestData: GetInvoiceNotesRequest
  ): Promise<GetInvoiceNotesResponse | ServiceError> {
    try {
      const response = await httpClient.get<GetInvoiceNotesResponse>(
        '/web/invoice/api/v2/invoice/get/notes',
        requestData,
        GetInvoiceNotesResponse,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async loadAndTripsDrivers(
    requestData: RefreshLoadAndTripsDriversRequest
  ): Promise<DriverGroupDetailType[] | ServiceError> {
    try {
      const response = await httpClient.get<DriverGroupDetailType[]>(
        '/web/invoice/api/v2/invoice/get/loadAndTripsDrivers',
        requestData,
        GetInvoiceGroupDetailResponse,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async sendInvoiceToProviderViaEdi(
    requestData: UpdateInvoiceRequest,
    data: InvoiceModel
  ): Promise<UpdateInvoiceResponse | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/lbhub/api/v2/edi/send-invoice?tenderId=${requestData.tenderId}&organizationId=${requestData.organizationId}`,
        undefined,
        { ...data, ...requestData }
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceSentToProvider',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceSentToProvider',
      });
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async sendInvoiceToTurvo(
    requestData: InvoiceToTurvoRequest
  ): Promise<UpdateInvoiceResponse | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/lbhub/api/v2/edi/send-invoice-turvo?loadId=${requestData.loadId}`,
        undefined,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceSentToTurvo',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceSentToProvider',
      });
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async sendInvoiceToBitFreighter(
    requestData: InvoiceToTurvoRequest
  ): Promise<UpdateInvoiceResponse | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/lbhub/api/v2/edi/send-invoice-bitf?loadId=${requestData.loadId}`,
        undefined,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'invoiceSentToBitf',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'invoiceSentToProvider',
      });
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async getInvoiceSearchLoads(
    requestData: GetInvoiceSearchLoadsRequest
  ): Promise<InvoiceSearchLoadsType | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/invoice/api/v3/invoice/search/loads`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async deleteInvoice(payloadData: DeleteInvoiceRequest) {
    try {
      const response = await httpClient.deleteRaw(
        `web/invoice/api/v3/invoice`,
        payloadData,
        null
      );
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
  async getloadIdList(payloadData: GetloadIdListRequest) {
    try {
      const response = await httpClient.putRaw(
        `/web/invoice/api/v2/invoice/get/loadIdList`,
        undefined,
        payloadData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
  async getInvoiceActionData(payloadData: GetInvoiceActionDataRequest) {
    try {
      const response = await httpClient.getRaw(
        `web/invoice/api/v3/invoice/applicable-actions`,
        payloadData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async onHoldStateLoad(
    request: OnHoldStateInvoiceRequest
  ): Promise<OnHoldStateInvoiceResponse | ServiceError> {
    const successNotification = {
      type: 'SUCCESS',
      serviceName: 'holdStateInvoice',
    };
    const errorNotification = {
      type: 'FAILURE',
      serviceName: 'holdStateInvoice',
    };
    try {
      const response = await httpClient.put<OnHoldStateInvoiceResponse>(
        '/web/invoice/api/v2/invoice/onhold-state',
        request,
        undefined,
        OnHoldStateInvoiceResponse,
        false
      );
      if (response?.message?.includes('successfully')) {
        RootStoreInstence.setNotificationType(successNotification);
      } else {
        RootStoreInstence.setNotificationType(errorNotification);
      }
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType(errorNotification);
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
