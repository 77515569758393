import { Box, ButtonGroup, Grid, InputAdornment, Theme } from '@mui/material';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../../components_v2/ui-kit/button';
import { Typography } from '../../../../../components_v2/ui-kit/typography';
import { OptionType } from '../../../../../models';
import { LoadReeferModeOptions } from '../../../../../subPages/loadsList/CreateLoad/constants/fieldOptions';
import { loadPriorityOptions } from '../../../../../subPages/loadsList/CreateLoad/schema';
import { ReeferModeEnum } from '../../../../../subPages/loadsList/CreateLoad/schema/load';
import {
  ReeferModeButtonGroup,
  ReeferModeButtonSelectedStyles,
  ReeferModeButtonStyles,
} from '../../../../../subPages/loadsList/CreateLoad/style';
import { loadTypeOptions } from '../../../../../subPages/loadsList/LoadDetailsPanel/constants/constants';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import { useThemeResponsive } from '../../../../hooks/useThemeResponsive';
import { equipmentFormRequiredFields } from '../../../../LoadSharedComponents/constants';
import { EquipmentFormRequiredFieldsType } from '../../../../LoadSharedComponents/types';
import { getDefaultCommodity } from '../../../../LoadSharedComponents/utils';
import { EquipmentImportExportOptions } from '../../../../PendoClassIDs/constants';
import { MultipleAutocompleteForm } from '../../../../Ui/MultipleAutocomplete';
import Select from '../../../../Ui/Select';
import { SingleAutocompleteForm } from '../../../../Ui/SingleAutocomplete';
import Switch from '../../../../Ui/Switcher';
import TextField from '../../../../Ui/TextField/TextField';
import { LoadOverviewDetails } from '../models';

export default function LoadDetailsForm({
  isViewMode = false,
  requiredFields = { ...equipmentFormRequiredFields },
  equipmentTypeOptions = [],
  load,
}: {
  isViewMode?: boolean;
  requiredFields?: EquipmentFormRequiredFieldsType;
  equipmentTypeOptions: OptionType[];
  load: LoadOverviewDetails;
}) {
  const { control, watch, setValue, formState } = useFormContext();

  const { isMobile } = useThemeResponsive();
  const [trailerTypes, commodityData, reeferMode, mode] = watch([
    'trailerTypes',
    'commodityData',
    'reeferMode',
    'mode',
  ]);
  const chassisContainerSelected =
    trailerTypes && Array.isArray(trailerTypes)
      ? trailerTypes?.find((item) => item.key === 'Chassis_Container')
      : undefined;
  const loadEquipmentTypeOptions = chassisContainerSelected
    ? equipmentTypeOptions?.filter((type) => type.key === 'Chassis_Container')
    : equipmentTypeOptions;
  const reeferSelected =
    // trailerTypes && Array.isArray(trailerTypes)
    trailerTypes?.find((item) => item.key === 'Reefer');
  // : null;
  const { hasIdEditPermission } = useLoadPermission();

  const handleEquipmentChange = (data) => {
    const chassisContainerTypes = data?.filter(
      (item) => item.key === 'Chassis_Container'
    );

    if (chassisContainerTypes?.length) {
      setValue('equipmentTypes', chassisContainerTypes);
    } else {
      setValue('chassisNumber', null);
      setValue('containerNumber', null);
      setValue('loadDirection', null);
      setValue('appointmentNumber', null);
      setValue('bookingNumber', null);
    }
    console.log('reeferSelected', reeferSelected);
    if (!reeferSelected) {
      setValue('reeferMode', null);
      setValue('temperature', null);
      setValue('variance', null);
    }
  };

  const handleTotalWeightChange = (value: string) => {
    if (!value) {
      setValue('commodityData', []);
    } else if (!isNaN(parseInt(value, 10))) {
      if (!commodityData?.length) {
        setValue('commodityData', [
          {
            ...getDefaultCommodity(commodityTypes),
            weight: value,
          },
        ]);
      } else {
        setValue('commodityData', [{ ...commodityData[0], weight: value }]);
      }
    }
  };
  return (
    <Grid container spacing={2}>
      <TextField
        id={'loadAxeleId'}
        control={control}
        name="seqNumber"
        label={t('loadOverViewLoadId')}
        sizes={{ xs: 6 }}
        disabled={!hasIdEditPermission}
      />
      <TextField
        id={'referenceId'}
        control={control}
        name="referenceId"
        label={t('loadOverViewRef')}
        sizes={{ xs: 6 }}
      />
      <TextField
        id={'purchaseOrderNumber'}
        control={control}
        name="purchaseOrderNumber"
        label={t('loadOverViewPo')}
        sizes={{ xs: 6 }}
      />
      <TextField
        id={'bolNumber'}
        control={control}
        name="bolNumber"
        label={t('loadOverViewBol')}
        sizes={{ xs: 6 }}
      />

      <SingleAutocompleteForm
        label={t('loadOverViewPriority')}
        control={control}
        name="loadPriority"
        id="loadPriority"
        fieldName="label"
        fieldValue="value"
        options={loadPriorityOptions}
        wrapperStyles={{ paddingRight: '10px' }}
        disableClear
        sizes={{ xs: 6 }}
        freeSolo={false}
      />

      <Switch
        control={control}
        label={'Eligible to be Brokered'}
        name={'canBeBrokered'}
        sizes={{ xs: 6 }}
      />

      <TextField
        id={'NotesForDispatching'}
        control={control}
        name="notes"
        label={t('TripAddNewLoadOption4NotesForDispatching')}
        sizes={{ xs: 12 }}
        // disabled={isFieldReadOnly}
      />
      {/* Equipment */}
      <MultipleAutocompleteForm
        id={'RequiredEquipmentType'}
        control={control}
        name="trailerTypes"
        fieldName="value"
        options={loadEquipmentTypeOptions}
        label={t('TripAddNewLoadOption4RequiredEquipmentType')}
        required={requiredFields.equipmentTypes}
        variant="standard"
        sizes={{ xs: isMobile ? 12 : 6 }}
        onChangeCb={handleEquipmentChange}
        // disabled={isFieldReadOnly}
      />
      <TextField
        id={'Seal'}
        control={control}
        name="sealNumber"
        label={t('TripAddNewLoadOption4Seal')}
        sizes={{ xs: isMobile ? 12 : 6 }}
        // disabled={isFieldReadOnly}
      />
      {chassisContainerSelected && (
        <TextField
          id={'Chassis'}
          control={control}
          name="chassisNumber"
          label="Chassis #"
          sizes={{ xs: isMobile ? 12 : 6 }}
          // disabled={isFieldReadOnly}
        />
      )}
      {chassisContainerSelected && (
        <TextField
          id={'Container'}
          control={control}
          name="containerNumber"
          label="Container #"
          sizes={{ xs: isMobile ? 12 : 6 }}
          // disabled={isFieldReadOnly}
        />
      )}
      {chassisContainerSelected && (
        <SingleAutocompleteForm
          control={control}
          fieldValue="key"
          fieldName="label"
          name="loadDirection"
          label="Import/Export"
          options={EquipmentImportExportOptions}
          // options={[]}
          wrapperStyles={{ paddingRight: '2px' }}
        />
      )}
      {chassisContainerSelected && (
        <TextField
          id={'Booking '}
          control={control}
          name="bookingNumber"
          label="Booking #"
          sizes={{ xs: isMobile ? 12 : 6 }}
          // disabled={isFieldReadOnly}
        />
      )}
      {chassisContainerSelected && (
        <TextField
          id={'Appointment'}
          control={control}
          name="appointmentNumber"
          label="Appointment #"
          sizes={{ xs: isMobile ? 12 : 6 }}
          // disabled={isFieldReadOnly}
        />
      )}
      {reeferSelected && (
        <Box display={'block'} ml={2}>
          <Typography variant="body2" ml={1}>
            Reefer Mode
          </Typography>
          <ButtonGroup
            variant="outlined"
            aria-label="reefer-mode"
            sx={ReeferModeButtonGroup}
          >
            {LoadReeferModeOptions.map((e) => {
              return (
                <Button
                  key={e.id}
                  onClick={() => {
                    if (e?.id === 'OFF') {
                      setValue('temperature', null);
                      setValue('variance', null);
                    }
                    setValue('reeferMode', e.id, { shouldDirty: true });
                  }}
                  sx={(theme: Theme) =>
                    reeferMode == e.id
                      ? ReeferModeButtonSelectedStyles(theme)
                      : ReeferModeButtonStyles(theme)
                  }
                  variant={reeferMode == e.id ? 'contained' : 'outlined'}
                >
                  {e.label}
                </Button>
              );
            })}
          </ButtonGroup>
          <br />
          {reeferMode === ReeferModeEnum.OFF && (
            <Typography
              variant="caption"
              color={'textSecondary'}
              mt={'auto'}
              mb="auto"
            >
              (Reefer is being used as a container only)
            </Typography>
          )}
        </Box>
      )}
      {reeferSelected && reeferMode !== ReeferModeEnum.OFF && (
        <TextField
          id={'temp'}
          control={control}
          name="temperature"
          label="Temp"
          sizes={{ xs: isMobile ? 12 : 6 }}
          // disabled={isFieldReadOnly}
          InputProps={{
            endAdornment: <InputAdornment position="end">F</InputAdornment>,
          }}
        />
      )}
      {reeferSelected && reeferMode !== ReeferModeEnum.OFF && (
        <TextField
          id="temp-variance"
          label="Set +/-"
          control={control}
          name="variance"
          sizes={{ xs: isMobile ? 12 : 6 }}
          // disabled={isFieldReadOnly}
          InputProps={{
            endAdornment: <InputAdornment position="end">F</InputAdornment>,
          }}
        />
      )}
      <TextField
        id={'Weight'}
        control={control}
        name="totalWeight"
        label={t('TripAddNewLoadOption4Weight')}
        // disabled={isFieldReadOnly || commodityData?.length > 1}
        // onChangeCallback={handleTotalWeightChange}
        onlyNumbers
        sizes={{ xs: isMobile ? 12 : 6 }}
      />
      <Select
        id={'LoadType'}
        control={control}
        name="loadType"
        label={t('TripAddNewLoadOption4LoadType')}
        options={loadTypeOptions}
        fieldName="value"
        fieldValue="key"
        sizes={{ xs: isMobile ? 12 : 6 }}
        disabled={
          // isFieldReadOnly ||
          Boolean(
            load?.loadType === 'LTL' && load?.manifestBaseDetails?.manifestId
          )
        }
        required={requiredFields.loadType}
      />
    </Grid>
  );
}
