import { manageTypesService } from '../../../api';
import {
  CreateManageFormDataType,
  CreateManageTypeRequest,
  CreateTagRequest,
  DeleteManageTypeRequest,
  DeleteTagRequest,
  GetManageTypesRequest,
  GetTagsRequest,
  ManageTypesItem,
  TagItem,
  TagType,
  UnitType,
  UpdateManageFormDataType,
  UpdateManageTypeRequest,
  CreateUpdateTagFormType,
  UpdateTagRequest,
  GetTagColorsRequest,
  DuplicateTagRequest,
} from '../../../models/DTOs/ManageTypes/Requests';
import { ServiceError } from '../../../api/interfaces';
import { getCurrentUserName, setUnitDataForRequest } from './utils';

export class ManageTypeActions {
  static manageTypesEntityListMap: Record<string, ManageTypesItem[]> = {
    Document: [],
    Maintenance: [],
    Financial: [],
    Commodity: [],
    Expense: [],
  };

  static tagsListMap: Record<string, TagItem[]> = {
    Tags: [],
  };

  static async getManageTypesList(routeName: string) {
    const request = new GetManageTypesRequest();
    request.pageNumber = 1;
    request.pageSize = 10000;
    const result = await manageTypesService.getManageTypesList(
      request,
      routeName
    );
    return result instanceof ServiceError ? [] : result.content;
  }

  static async getTagsList() {
    const request = new GetTagsRequest();
    request.pageNumber = 1;
    request.pageSize = 10000;
    const result = await manageTypesService.getTags(request);
    return result instanceof ServiceError ? [] : result.content;
  }

  static async deleteMangeType(id: string, routeName: string) {
    const request = new DeleteManageTypeRequest();
    request.id = id;
    const result = await manageTypesService.deleteManageType(
      request,
      routeName
    );
    return !(result instanceof ServiceError);
  }

  static async createMangeType(
    data: CreateManageFormDataType,
    routeName: string
  ) {
    const request = new CreateManageTypeRequest();
    request.itemName = data.itemName.trim();
    request.description = data.description;
    request.iconName = data.iconName;
    request.createdBy = getCurrentUserName();
    request.deduction = data.deduction;
    request.settlementItem = data.settlementItem;
    request.invoiceItem = data.invoiceItem;
    request.unit = setUnitDataForRequest(
      data.unit as UnitType[] | null | undefined
    );
    const result = await manageTypesService.createManageType(
      request,
      routeName
    );
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async updateMangeType(
    data: UpdateManageFormDataType,
    routeName: string
  ) {
    const request = new UpdateManageTypeRequest();
    request.itemName = data.itemName.trim();
    request.description = data.description;
    request.iconName = data.iconName;
    request.createdBy = getCurrentUserName();
    request.deduction = data.deduction;
    request.settlementItem = data.settlementItem;
    request.invoiceItem = data.invoiceItem;
    request.unit = setUnitDataForRequest(
      data.unit as UnitType[] | null | undefined
    );
    request.id = data.id;
    const result = await manageTypesService.updateManageType(
      request,
      routeName
    );
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async createTag(data: CreateUpdateTagFormType) {
    const request = new CreateTagRequest();
    request.name = data.name.trim();
    request.description = data.description;
    request.tagType = TagType.USER_DEFINED;
    request.color = data.color;
    request.entityTypes = data.entityTypes.map((entityType) => entityType.key);
    request.active = true;
    return await manageTypesService.createTag(request);
  }

  static async deleteTag(id: string) {
    const request = new DeleteTagRequest();
    request.ids = [id];
    const result = await manageTypesService.deleteTag(request);
    return !(result instanceof ServiceError);
  }

  static async duplicateTag(id: string) {
    const request = new DuplicateTagRequest();
    request.id = id;
    const result = await manageTypesService.duplicateTag(request);
    if (!(result instanceof ServiceError)) {
      return result;
    }
  }

  static async updateTag(data: CreateUpdateTagFormType, id: string) {
    const request = new UpdateTagRequest();
    request.id = id;
    request.name = data.name.trim();
    request.description = data.description;
    request.color = data.color;
    request.entityTypes = data.entityTypes.map((entityType) => entityType.key);
    request.active = true;
    request.tagType = TagType.USER_DEFINED;
    return await manageTypesService.updateTag(request);
  }

  static async getTagColors() {
    const request = new GetTagColorsRequest();
    const result = await manageTypesService.getTagColors(request);
    if (result instanceof ServiceError) {
      return [];
    }
    return result;
  }
}
