export const IconButtonContainerStyles = {
  border: '1px solid rgba(43, 100, 203, 0.5)',
  borderRadius: '6px',
  height: '24px',
};

export const resetTextStyles = {
  ml: 1,
  fontSize: '13px',
  color: '#2B64CB',
};
