import TextField from '@/common/Ui/TextField/TextField';
import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import FormPhoneField from '@/common/Ui/FormPhoneField';
import Checkbox from '@/common/Ui/Checkbox';
import { MultipleAutocompleteForm } from '@/common/Ui/MultipleAutocomplete';
import { LoadAlertFlagType } from '@/models';
import { FC } from 'react';
import { SingleAutocompleteForm } from '@/common/Ui/SingleAutocomplete';
import { useAddEditLoadAlertForm } from '@/common/LoadTabPanel/tabs/Alerts/hooks';

interface Props {
  flagTypes: LoadAlertFlagType[];
  loadId: string;
}

export const AddEditLoadAlertForm: FC<Props> = ({ flagTypes, loadId }) => {
  const { control, watch, setValue } = useFormContext();
  const [sendViaEmail, sendViaPhone] = watch(['sendViaEmail', 'sendViaPhone']);
  const { contacts } = useAddEditLoadAlertForm(loadId);
  return (
    <Grid spacing={1} padding={1} container>
      <SingleAutocompleteForm
        options={contacts}
        control={control}
        onChangeCb={(value) => {
          if (value) {
            setValue('phoneData', value.phone);
            setValue('email', value.email);
            setValue('name', value.name);
          }
        }}
        name="contact"
        fieldName="name"
        label="Contact Name"
      />
      <TextField
        control={control}
        name="name"
        label="Name"
        sizes={{ xs: 12 }}
        required
      />
      <FormPhoneField
        control={control}
        name="phoneData"
        label="Phone"
        sizes={{ xs: 12 }}
        required={!!sendViaPhone}
      />
      <TextField
        control={control}
        name="email"
        label="Email"
        sizes={{ xs: 12 }}
        required={!!sendViaEmail}
      />
      <MultipleAutocompleteForm
        control={control}
        name="loadNotifications"
        label="Load Notifications"
        options={flagTypes}
        fieldName="value"
        variant="standard"
        sizes={{ xs: 12 }}
        required
      />
      <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
        <Checkbox
          name="sendViaPhone"
          control={control}
          labelPlacement="end"
          label="Notify via Phone"
        />
        <Checkbox
          name="sendViaEmail"
          control={control}
          labelPlacement="end"
          label="Notify via Email"
        />
      </Box>
    </Grid>
  );
};
