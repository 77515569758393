import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OptionType } from '../../../../models';
import {
  ByLoadIdRequest,
  GetRemoveLoadManifestDataResp,
} from '../../../../models/DTOs/Loads/Requests';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { useStores } from '../../../../store/root.context';
import LoadController from '../../../../subPages/loadsList/LoadController';
import { ELoadType } from '../../../../subPages/loadsList/constants';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch2/constants/types';
import { DirtyDetailsPanelManager } from '../../../DetailsPanel/utils';
import NoData from '../../component/NoData';
import { getTinyUrl } from '../Tracking/constants/helper';
import { LoadDetails } from './components/LoadDetails';
import { ManifestDetails } from './components/ManifestDetails';
import {
  loadOverviewDetailsDefaultData,
  loadReferenceDetailsValidationSchema,
} from './constants';
import { LoadOverviewDetails } from './models';
import { getLoadOverviewDetails } from './utils';

interface IProps {
  load: LoadSummary;
  onUpdated: () => void;
  manifestDeleteForLoad?: (
    id: GetRemoveLoadManifestDataResp,
    status: boolean
  ) => void;
}

export const Overview = observer(
  ({ load, onUpdated, manifestDeleteForLoad }: IProps) => {
    const { trackPromise } = useDetailsPanelStore;
    const state = useForm({
      reValidateMode: 'onSubmit',
      defaultValues: { ...loadOverviewDetailsDefaultData },
      resolver: yupResolver(loadReferenceDetailsValidationSchema),
    }); //@TODO: Slavik - This component uses an internal useForm , needs to be refactored and connected to the main FormProvider!
    const {
      myLoadStore: { setUpdatedLoadId },
    } = useStores();
    const equipmentTypeOptions = useRef<OptionType[]>([]);

    const [httpErrors, setHttpErrors] = useState();
    const [noData, setNoData] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [trackingUrl, setTrackingURL] = useState<string | null>(null);

    const {
      handleSubmit,
      formState: { isDirty, errors },
      reset,
      setError,
      clearErrors,
    } = state;

    useEffect(() => {
      DirtyDetailsPanelManager.panelName = isDirty ? 'Overview' : null;

      DirtyDetailsPanelManager.isDirty = isDirty;
    }, [isDirty]);

    async function getWarnings() {
      await trackPromise(
        LoadController.instance().setupLoadWarnings([load]),
        ESecondaryDetailsPanelType.LOAD
      );
    }
    const getURL = async (key: string) => {
      try {
        const locationURL = window.location.origin;
        const tempUrl = `${locationURL}/tnt?key=${key}`;
        const tinuURLAPI = `${locationURL}/backendpublic/mediator/api/v2/notifications/sms/tinyurl?urlLongURL=${tempUrl}`;

        const res: string = await getTinyUrl(tinuURLAPI);
        setTrackingURL(res);
      } catch (error) {
        console.log(error);
      }
      // setIsLoading(false);
    };

    useEffect(() => {
      if (!load.warning) getWarnings();
      return () => {
        DirtyDetailsPanelManager.panelName = null;
        DirtyDetailsPanelManager.isDirty = false;
      };
    }, []);

    useEffect(() => {
      fetchData();
    }, [load]);
    const fetchData = async () => {
      const request = new ByLoadIdRequest();
      request[load.loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] = load?.id;
      const data = await trackPromise(
        getLoadOverviewDetails(request),
        ESecondaryDetailsPanelType.LOAD
      );
      if (data) {
        const terminal = data.invoiceTerminal;
        if (terminal) {
          const { companyName, primary } = terminal;
          terminal.name = primary ? companyName + ' (Primary)' : companyName;
        }
        if (!equipmentTypeOptions.current?.length) {
          const loadStaticData =
            await LoadController.instance().getLoadStatic();
          equipmentTypeOptions.current = loadStaticData?.content || [];
        }
        if (data.loadDetails?.trailerTypes)
          data.loadDetails.trailerTypes = equipmentTypeOptions?.current?.filter(
            (item) => data.loadDetails?.trailerTypes?.includes(item.key)
          );
        reset(
          new LoadOverviewDetails({
            ...data,
            loadStatus: load?.loadStatus,
            id: load?.id,
          })
        );
        if (data?.hashKey) {
          getURL(data.hashKey);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setNoData(true);
      }
    };

    const handleCancel = () => {
      setIsSaving(false);
      reset();
    };

    useEffect(() => {
      if (errors) {
        httpErrors?.forEach((error) => {
          setError(error.fieldName, {
            type: 'httpError',
            message: error.message,
          });
        });
      }
    }, [httpErrors]);

    if (noData && !isLoading) {
      return <NoData />;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => {
            return `${theme.palette.uncategorized?.card}`;
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            mx: 0,
          }}
        >
          <Box>
            <FormProvider {...state}>
              {load.loadType == ELoadType.MANIFEST ? (
                <ManifestDetails
                  loadStatus={load.loadStatus}
                  loadType={load.loadType}
                  onHold={load.onHold}
                  isLoading={isLoading}
                  onUpdated={() => {
                    onUpdated?.();
                    fetchData();
                  }}
                />
              ) : (
                <LoadDetails
                  load={load as LoadSummary}
                  loadStatus={load.loadStatus}
                  loadType={load.loadType || ''}
                  manifestDeleteForLoad={manifestDeleteForLoad}
                  isLoading={isLoading}
                  trackingUrl={trackingUrl}
                  onHold={load.onHold}
                  onUpdated={() => {
                    onUpdated?.();
                    fetchData();
                  }}
                />
              )}
            </FormProvider>
          </Box>
        </Box>
        {/* {isDirty && (
          <Footer
            sx={{ mb: 3 }}
            isSaving={isSaving}
            onSave={handleSubmit(handleSave)}
            onCancel={handleCancel}
          />
        )} */}
      </Box>
    );
  }
);
