import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { SideBarConstants } from '../../../locales/en/common/sideBar';
import { AXELE_PERMISSION_TYPE } from '../../Permission';
import { selectedItemDTO, selectedSubItemDTO } from './LeftMenu';

export const leftMenuContants: selectedItemDTO[] = [
  {
    id: 'home',
    icon: CottageOutlinedIcon,
    iconName: 'CottageOutlined',
    text: SideBarConstants.home,
    hasChildren: false,
    redirectionPath: '/home',
    permission: [AXELE_PERMISSION_TYPE.DASHBOARD_VIEW],
  },
  {
    id: 'dispatch',
    icon: HeadsetMicOutlinedIcon,
    iconName: 'HeadsetMicOutlined',
    text: SideBarConstants.dispatch,
    hasChildren: false,
    redirectionPath: '/dispatch',
    fullheight: true,
    permission: [AXELE_PERMISSION_TYPE.DISPATCHES_VIEW],
  },
  {
    id: 'loads',
    icon: FactCheckOutlinedIcon,
    iconName: 'FactCheckOutlined',
    text: SideBarConstants.loads,
    hasChildren: true,
    redirectionPath: '/loads',
    permission: [AXELE_PERMISSION_TYPE.LOADS_VIEW],
  },
  {
    id: 'operations',
    icon: BubbleChartOutlinedIcon,
    iconName: 'BubbleChartOutlined',
    text: SideBarConstants.operations,
    hasChildren: true,
    redirectionPath: '/operations',
  },
  {
    id: 'finance',
    icon: MonetizationOnOutlinedIcon,
    iconName: 'MonetizationOnOutlined',
    text: SideBarConstants.finance,
    hasChildren: true,
    redirectionPath: '/finance',
  },
  {
    id: 'safety',
    icon: ConstructionOutlinedIcon,
    iconName: 'ConstructionOutlined',
    text: SideBarConstants.safety,
    hasChildren: true,
    redirectionPath: '/safety',
    permission: [AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_VIEW],
  },
  {
    id: 'reports',
    icon: FeedOutlinedIcon,
    iconName: 'FeedOutlined',
    text: SideBarConstants.reports,
    hasChildren: true,
    redirectionPath: '/reports',
  },
  {
    id: 'settings',
    icon: SettingsOutlinedIcon,
    iconName: 'SettingsOutlined',
    text: SideBarConstants.settings,
    hasChildren: true,
    redirectionPath: '/settings',
  },
];
export const subMenuContants: Record<string, selectedSubItemDTO[]> = {
  operations: [
    {
      id: 'equipment',
      iconName: 'BubbleChartOutlined',
      parent: 'operations',
      text: SideBarConstants.operationsEquipment,
      redirectionPath: '/operations/equipment',
      permission: [AXELE_PERMISSION_TYPE.EQUIPMENT_VIEW],
    },
    {
      id: 'contacts',
      iconName: 'BubbleChartOutlined',
      parent: 'operations',
      text: SideBarConstants.operationsContacts,
      redirectionPath: '/operations/contacts',
      permission: [
        AXELE_PERMISSION_TYPE.CUSTOMERS_VIEW,
        AXELE_PERMISSION_TYPE.CARRIER_VIEW,
        AXELE_PERMISSION_TYPE.VENDORS_VIEW,
        AXELE_PERMISSION_TYPE.LOCATIONS_VIEW,
      ],
    },
    {
      id: 'teams',
      iconName: 'BubbleChartOutlined',
      parent: 'operations',
      text: SideBarConstants.operationsTeamDriver,
      redirectionPath: '/operations/teams',
      permission: [AXELE_PERMISSION_TYPE.DRIVER_GROUP_VIEW],
    },
  ],
  finance: [
    {
      id: 'summary',
      iconName: 'MonetizationOnOutlined',
      parent: 'finance',
      text: SideBarConstants.financeSummary,
      redirectionPath: '/finance/summary',
      permission: [
        AXELE_PERMISSION_TYPE.ACCOUNT_SUMMARY_EXPENSE_VIEW,
        AXELE_PERMISSION_TYPE.EXPENSES_VIEW,
      ],
    },
    {
      id: 'invoices',
      iconName: 'MonetizationOnOutlined',
      parent: 'finance',
      text: SideBarConstants.financeInvoices,
      redirectionPath: '/finance/invoices',
      permission: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICES_VIEW],
    },
    {
      id: 'payments',
      iconName: 'MonetizationOnOutlined',
      parent: 'finance',
      text: SideBarConstants.financePaymentManagement,
      redirectionPath: '/finance/payments',
      permission: [AXELE_PERMISSION_TYPE.PAYMENT_VIEW],
    },
    {
      id: 'expenses',
      iconName: 'MonetizationOnOutlined',
      parent: 'finance',
      text: SideBarConstants.financeExpenses,
      redirectionPath: '/finance/expenses',
      permission: [AXELE_PERMISSION_TYPE.EXPENSES_VIEW],
    },
    {
      id: 'settlement',
      iconName: 'MonetizationOnOutlined',
      parent: 'finance',
      text: SideBarConstants.financeSettlements,
      redirectionPath: '/finance/settlement',
      permission: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_VIEW],
    },
  ],
  safety: [
    {
      id: 'maintenance-history',
      iconName: 'ConstructionOutlined',
      parent: 'safety',
      text: SideBarConstants.safetyMaintenanceHistory,
      redirectionPath: '/safety/maintenance-history',
      fullheight: true,
    },
    {
      id: 'upcoming-events',
      iconName: 'ConstructionOutlined',
      parent: 'safety',
      text: SideBarConstants.safetyUpcomingEvents,
      redirectionPath: '/safety/upcoming-events',
      fullheight: true,
    },
  ],
  reports: [
    {
      id: 'basic-reports',
      iconName: 'FeedOutlined',
      parent: 'reports',
      text: SideBarConstants.reportsBasicReports,
      redirectionPath: '/reports/basic-reports',
      permission: [AXELE_PERMISSION_TYPE.BASIC_REPORTS_VIEW],
    },
    {
      id: 'trend-analysis',
      iconName: 'FeedOutlined',
      parent: 'reports',
      text: SideBarConstants.reportsTrendAnalysis,
      redirectionPath: '/reports/trend-analysis',
      permission: [AXELE_PERMISSION_TYPE.TREND_ANALYSIS_REPORTS_VIEW],
    },
    {
      id: 'ifta-center',
      iconName: 'FeedOutlined',
      parent: 'reports',
      text: SideBarConstants.reportsIftaCenter,
      redirectionPath: '/reports/ifta-center',
      fullheight: true,
      permission: [AXELE_PERMISSION_TYPE.IFTA_VIEW],
    },
    {
      id: 'documents',
      iconName: 'BubbleChartOutlined',
      parent: 'reports',
      text: SideBarConstants.reportsDocument,
      redirectionPath: '/reports/documents',
      permission: [AXELE_PERMISSION_TYPE.DOCUMENTS_VIEW],
    },
    {
      id: 'advanced-report',
      iconName: 'BubbleChartOutlined',
      parent: 'reports',
      text: SideBarConstants.reportadvancedReport,
      redirectionPath: '/reports/advanced-report',
      permission: [AXELE_PERMISSION_TYPE.DOCUMENTS_VIEW],
    },
  ],
  settings: [
    {
      id: 'preferences',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: SideBarConstants.settingsPreferences,
      redirectionPath: '/settings/preferences',
      permission: [AXELE_PERMISSION_TYPE.PREFERENCES_VIEW],
    },
    {
      id: 'contracts',
      iconName: 'BubbleChartOutlined',
      parent: 'settings',
      text: 'Contracts',
      redirectionPath: '/settings/contracts',
      permission: [AXELE_PERMISSION_TYPE.CONTRACT_VIEW],
    },
    {
      id: 'fuel-surcharge-rate-matrix',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: 'Fuel Surcharge Rate Matrix',
      redirectionPath: '/settings/fuel-surcharge-rate-matrix',
      permission: [AXELE_PERMISSION_TYPE.ROLE_VIEW],
    },
    {
      id: 'integrations',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: SideBarConstants.settingsIntegrations,
      redirectionPath: '/settings/integrations',
    },
    {
      id: 'users',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: SideBarConstants.settingsUsers,
      redirectionPath: '/settings/users',
      permission: [AXELE_PERMISSION_TYPE.USER_PROFILES_VIEW],
    },
    {
      id: 'alerts',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: SideBarConstants.settingsAlerts,
      redirectionPath: '/settings/alerts',
      permission: [AXELE_PERMISSION_TYPE.ALERTS_VIEW],
    },
    {
      id: 'terminals',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: SideBarConstants.settingsTerminals,
      redirectionPath: '/settings/terminals',
      permission: [AXELE_PERMISSION_TYPE.TERMINALS_VIEW],
    },
    {
      id: 'manageTypes',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: SideBarConstants.settingsManageTypes,
      redirectionPath: '/settings/manageTypes',
      fullheight: true,
      permission: [AXELE_PERMISSION_TYPE.DEFAULTS_VIEW],
    },
    {
      id: 'import',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: SideBarConstants.settingsImport,
      redirectionPath: '/settings/import',
      permission: [AXELE_PERMISSION_TYPE.IMPORT_DATA],
    },
    {
      id: 'roles',
      iconName: 'SettingsOutlined',
      parent: 'settings',
      text: 'Roles & Permissions',
      redirectionPath: '/settings/roles',
      permission: [AXELE_PERMISSION_TYPE.ROLE_VIEW],
    },
  ],
  loads: [
    {
      id: 'my-loads',
      iconName: 'FactCheckOutlined',
      text: SideBarConstants.myLoads,
      parent: 'loads',
      redirectionPath: '/',
      permission: [AXELE_PERMISSION_TYPE.LOADS_VIEW],
    },
    {
      id: 'trips',
      iconName: 'FactCheckOutlined',
      text: SideBarConstants.myTrips,
      parent: 'loads',
      redirectionPath: '/loads/trips',
      permission: [AXELE_PERMISSION_TYPE.LOADS_VIEW],
    },
    {
      id: 'loadboards',
      iconName: 'DnsOutlined',
      text: SideBarConstants.loadBoards,
      parent: 'loads',
      redirectionPath: '/loads/loadboards',
      permission: [AXELE_PERMISSION_TYPE.LOADSEARCH_VIEW],
    },
    {
      id: 'templates',
      iconName: 'FactCheckOutlined',
      text: SideBarConstants.myTemplates,
      parent: 'loads',
      redirectionPath: '/loads/templates',
      permission: [AXELE_PERMISSION_TYPE.TERMINALS_VIEW],
    },
    {
      id: 'EDITenders',
      iconName: 'DnsOutlined',
      text: SideBarConstants.LoadTenders,
      parent: 'loads',
      redirectionPath: '/loads/EDITenders',
      roleCodes: [],
    },
    {
      id: 'rules',
      iconName: 'DnsOutlined',
      text: SideBarConstants.rules,
      parent: 'loads',
      redirectionPath: '/loads/rules',
      roleCodes: [],
    },
  ],
};
